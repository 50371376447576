import {
  getCarephoneAlarms,
  getCarephoneData,
} from "helpers/backend_helper"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Accordion,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
} from "reactstrap"

//Translation
import { withTranslation } from "react-i18next"
import moment from "moment"
import StatusCardHeader from "../status-card-header"
import Lottie from "react-lottie"
import PubSub from "pubsub-js"
import { getLottieLoadingOptions, getLottieNothingFound } from "utils/general"

const CarephoneServices = props => {
  const [carephone, setCarephone] = useState(null)
  const [accessories, setAccessories] = useState([])
  const [alarms, setAlarms] = useState([])
  const [alarmsLoading, setAlarmsLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const abortRef = useRef(null)

  const [open, setOpen] = useState("")
  const [accordionText, setAccordionText] = useState("Show all device info")

  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getData()
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  // Carephone key changed
  useEffect(() => {
    getData()
  }, [props.carephoneKey])

  // Get carephone data
  const getData = () => {
    if (props.carephoneKey.length > 0 && !dataLoading) {
      setDataLoading(true)
      getCarephoneData(abortRef.current, props.carephoneKey)
        .then(data => {
          //console.log("Carephone data: ", data)
          if (data.key) {
            // Status
            data.status = "Online"
            data.statusTime = data.globalStatusTime

            if (data.connectionValue !== 0) {
              data.status = "Offline"
              data.statusTime = data.connectionStatusTime
            } else if (data.mainsValue !== 0) {
              data.status = "Discharging"
              data.statusTime = data.mainsStatusTime
            }

            // Signal strength
            if (data.signalStrength > 1 && data.signalStrength < 10) {
              data.signalStrengthDesc = "Weak"
            } else if (data.signalStrength >= 10 && data.signalStrength < 15) {
              data.signalStrengthDesc = "Moderate"
            } else if (data.signalStrength >= 15 && data.signalStrength < 20) {
              data.signalStrengthDesc = "Good"
            } else if (data.signalStrength >= 20 && data.signalStrength < 32) {
              data.signalStrengthDesc = "Great"
            } else {
              data.signalStrengthDesc = "Unknown"
            }

            setCarephone(data)

            if (data.accessories != null) {
              try {
                const temp = JSON.parse(data.accessories)
                let key = 0
                temp.forEach(element => {
                  element.key = key++
                  element.status = element.syncValue == 0 ? "Synchronized" : "Not synchronized"
                })
                setAccessories(temp)
              } catch (err) {
                console.log(err)
              }
            }
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setDataLoading(false)
        })
    }

    if (props.suvantoKey?.length > 0 && !alarmsLoading) {
      setAlarmsLoading(true)
      getCarephoneAlarms(abortRef.current, props.suvantoKey)
        .then(result => {
          //console.log("ALARMS: ", result)
          if (result.entries) {
            setAlarms(result.entries)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setAlarmsLoading(false)
        })
    }
  }

  /*
  Battery
  0 - OK
  10 - NO_INFORMATION
  30 - BATTERY_LOW
  */
  const setTypeBattery = b => {
    switch (b) {
      case 0:
        return "dripicons-battery-full text-success"
      case 30:
        return "dripicons-battery-low text-danger"
      default:
        return ""
    }
  }

  const setStatusIcon = s => {
    switch (s) {
      case "UNASSIGNED":
        return "mdi mdi-alert text-danger"
      case "ASSIGNED":
        return "mdi mdi-alert text-warning"
      default:
        return "mdi mdi-check text-success"
    }
  }

  const toggleInfo = id => {
    if (open === id) {
      setOpen("")
      setAccordionText("Show all device info")
    }
    else {
      setOpen(id)
      setAccordionText("Hide info")
    }
  }

  return (
    <>
      <div>
        {carephone && (
          <div className="mt-1">
            <Card style={{ borderRadius: "40px 0 0 0" }}>
              <StatusCardHeader
                service="carephone"
                status={carephone.status}
                battery={null}
                date={carephone.statusTime}
              />
              <CardBody style={{ padding: "0px" }}>
                <Accordion flush open={open} toggle={toggleInfo}>
                  <AccordionItem>
                    <AccordionBody
                      accordionId="1"
                      className="pt-0"
                      style={{ paddingTop: "0px" }}
                    >
                      <Row xs={1} sm={2} lg={3} className="mt-3">
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Gsm")}:</h6>{" "}
                            {carephone.gsm
                              ? carephone.gsm
                              : props.t("Undefined")}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Serial")}:</h6>{" "}
                            {carephone.serial
                              ? carephone.serial
                              : props.t("Undefined")}
                          </div>
                        </Col>
                        {carephone.lastAlarmId > 0 && (
                          <Col>
                            <div className="text-muted mb-4 d-grid">
                              <h6>{props.t("Last alarm")}:</h6>{" "}
                              {props.t("AlarmTitles." + carephone.lastAlarmId)}
                              <span>
                                {moment(carephone.lastAlarmTime).format(
                                  "DD.MM.YYYY HH:mm:ss"
                                )}
                              </span>
                            </div>
                          </Col>
                        )}
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Synced")}:</h6>{" "}
                            {moment(carephone.syncTime).format(
                              "DD.MM.YYYY HH:mm:ss"
                            )}
                          </div>
                        </Col>
                        {/** 
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("ICCID")}:</h6> {carephone.ICCID}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Firmware")}:</h6> {carephone.firmware}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Device type")}:</h6> {carephone.deviceType}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Rebooted")}:</h6> {moment(carephone.lastReboot).format("DD.MM.YYYY HH:mm:ss")}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Operation status")}:</h6> {props.t(carephone.operationalStatusName)}
                          </div>
                        </Col>
                        
                        */}
                      </Row>
                      <hr />
                      <Row xs={1} sm={2} lg={3} className="mt-4">
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Operator")}:</h6>{" "}
                            {carephone.operator && carephone.operator != 0
                              ? carephone.operator
                              : props.t("Undefined")}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Signal strength")}:</h6>{" "}
                            {props.t(carephone.signalStrengthDesc)}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("GSM tower location")}:</h6>
                            {carephone?.cellLocation ? (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={
                                  "https://www.google.com/maps/place/" +
                                  carephone?.cellLocation.latitude +
                                  "," +
                                  carephone?.cellLocation.longitude
                                }
                              >
                                {carephone?.cellLocation.latitude +
                                  ", " +
                                  carephone?.cellLocation.longitude}
                              </a>
                            ) : (
                              <>{props.t("Undefined")}</>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AccordionBody>
                    <AccordionHeader targetId="1">
                      {props.t(accordionText)}
                    </AccordionHeader>
                  </AccordionItem>
                </Accordion>
              </CardBody>
            </Card>
            {/** 
            <Card>
              <CardBody className="border-top">
                <Row xs={1} xl={2}>
                  <Col className="mb-4">
                    <Row xs="auto">
                      <Col>
                        <div className="avatar-xs rounded-circle">
                          <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-20">
                            <i className="mdi mdi-check"/>
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <h6>{props.t("Connection status")}: {carephone.connectionPortalStatusName}</h6>
                        <i>{moment(carephone.connectionPortalStatusTime).format("DD.MM.YYYY HH:mm:ss")}</i>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4">
                    <Row xs="auto">
                      <Col>
                        <div className="avatar-xs rounded-circle">
                          <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-20">
                            <i className="mdi mdi-check"/>
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <h6>{props.t("Mains status")}: {carephone.mainsStatusName}</h6>
                        <i>{moment(carephone.mainsStatusTime).format("DD.MM.YYYY HH:mm:ss")}</i>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4">
                    <Row xs="auto">
                      <Col>
                        <div className="avatar-xs rounded-circle">
                          <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-20">
                            <i className="mdi mdi-check"/>
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <h6>{props.t("Battery status")}: {carephone.accumulatorStatusName}</h6>
                        <i>{moment(carephone.accumulatorStatusTime).format("DD.MM.YYYY HH:mm:ss")}</i>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4">
                    <Row xs="auto">
                      <Col>
                        <div className="avatar-xs rounded-circle">
                          <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-20">
                            <i className="mdi mdi-check"/>
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <h6>{props.t("Accessory batteries")}: {carephone.accessoriesBatteryStatusName}</h6>
                        <i>{moment(carephone.accessoriesBatteryStatusTime).format("DD.MM.YYYY HH:mm:ss")}</i>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4">
                    <Row xs="auto">
                      <Col>
                        <div className="avatar-xs rounded-circle">
                          <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-20">
                            <i className="mdi mdi-check"/>
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <h6>{props.t("Global status")}: {carephone.globalTechStatusName}</h6>
                        <i>{moment(carephone.globalTechStatusTime).format("DD.MM.YYYY HH:mm:ss")}</i>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
*/}
            <Row xs={1} xl={2}>
              <Col className="order-2 order-xl-1">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {props.t("Recent alarms")}
                    </CardTitle>
                    {alarmsLoading && (
                      <Lottie
                        options={getLottieLoadingOptions()}
                        height={100}
                        width={100}
                      />
                    )}
                    {!alarmsLoading && alarms.length > 0 && (
                      <ul className="list-group list-group-flush">
                        {alarms.map(alarm => (
                          <li key={alarm.key} className="list-group-item py-3">
                            <Row>
                              <Col>
                                <div className="d-grid">
                                  <h6>
                                    {props.t("AlarmTitles." + alarm.alarmId)}
                                  </h6>
                                  <span className="text-muted">
                                    {moment(alarm.update).format(
                                      "DD.MM.YYYY HH:mm:ss"
                                    )}
                                  </span>
                                </div>
                              </Col>
                              <Col
                                className="col-auto d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <i
                                  id="battery-life"
                                  className={
                                    setStatusIcon(alarm.status) +
                                    " font-size-18"
                                  }
                                />
                              </Col>
                            </Row>
                          </li>
                        ))}
                      </ul>
                    )}
                    {!alarmsLoading &&
                      (alarms == null || alarms.length == 0) && (
                        <div className="text-center mb-4">
                          <Lottie
                            options={getLottieNothingFound()}
                            height={150}
                            width={150}
                          />
                          <h5 className="text-muted mt-2">
                            {props.t("No alarms found")}
                          </h5>
                        </div>
                      )}
                  </CardBody>
                </Card>
              </Col>
              <Col className="order-1 order-xl-2">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {props.t("Accessories")}
                    </CardTitle>
                    {accessories && accessories.length > 0 ? (
                      <ul className="list-group list-group-flush">
                        {accessories.map(item => (
                          <li key={item.key} className="list-group-item py-3">
                            <Row>
                              <Col>
                                <div className="d-grid">
                                  <h6>{props.t(item.equipmentName)}</h6>
                                  <span className="text-muted">
                                    {props.t(item.status)}{" "}
                                    {moment(item.syncTime).format(
                                      "DD.MM.YYYY HH:mm:ss"
                                    )}
                                  </span>
                                  <span className="text-muted mt-1">
                                    {props.t("Radio ID") + ": " + item.radioId}
                                  </span>
                                </div>
                              </Col>
                              <Col
                                className="col-auto d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <i
                                  id="battery-life"
                                  className={
                                    setTypeBattery(item.batteryValue) +
                                    " font-size-18"
                                  }
                                />
                              </Col>
                            </Row>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="text-center mb-4">
                        <Lottie
                          options={getLottieNothingFound()}
                          height={150}
                          width={150}
                        ></Lottie>
                        <h5 className="text-muted mt-2">
                          {props.t("No accessories")}
                        </h5>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  )
}

CarephoneServices.propTypes = {
  t: PropTypes.any,
  carephoneKey: PropTypes.string,
  suvantoKey: PropTypes.string,
}

export default withTranslation()(CarephoneServices)
