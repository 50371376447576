import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { getCarephoneList } from "helpers/backend_helper"
import { Select, ConfigProvider } from "antd"
import { Col, Row } from "reactstrap"
const { Option } = Select;

const SelectCarephone = props => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchString, setSearchString] = useState("")

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Get carephones for dropdown
  const getData = () => {
    setLoading(true)
    getCarephoneList(abortRef.current).then(result => {
      if (result.statusCode == 200 && result.entries) {
        let opts = []
        result.entries.forEach(entry => {
          let temp = new Object()
          temp.label = entry.serial
          temp.value = entry.key
          //temp.serial = entry.serial
          temp.gsm = entry.gsm
          temp.imei = entry.imei
          temp.iccid = entry.iccid
          opts.push(temp)
        })
        setOptions(opts)
      }
    })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Selection changed
  const handleChange = newSelection => {
    if (props.onChange != null) {
      props.onChange(newSelection)
    }
  }

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      {loading
        ? <p>{props.t("Loading")}</p>
        : <p>{props.t("No entries found")}</p>
      }
    </div>
  )

  // Dropdown visibility, get data at first
  const visibilityChanged = (visible) => {
    if (visible && options.length == 0 && !loading) {
      getData()
    }
  }

  return (
    <div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <div className="config-provider">
          <Select
            showSearch
            loading={loading}
            onDropdownVisibleChange={visibilityChanged}
            value={props.selection}
            mode={props.multiple ? "multiple" : null}
            allowClear={props.clearable != null ? props.clearable : true}
            placeholder={props.t("Select carephone")}
            style={{ width: 100 + "%" }}
            onChange={handleChange}
            //options={options}
            onSearch={s => { setSearchString(s) }}
            filterOption={(inputValue, option) => {
              return (
                option.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                option.value?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                option.gsm?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                option.iccid?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
              );
            }}
          >
            {options.map((item, index) => {
              return (
                <Option key={item.value} value={item.value} label={item.label} gsm={item.gsm} iccid={item.iccid}>
                  <Row type="flex" justify="start">
                    <Col className="col-auto">{item.label}</Col>
                    {searchString && item.gsm?.toLowerCase().indexOf(searchString) >= 0 &&
                      <Col className="col-auto"><strong className="text-primary">GSM: </strong>{item.gsm}</Col>
                    }
                    {searchString && item.iccid?.toLowerCase().indexOf(searchString) >= 0 &&
                      <Col className="col-auto"><strong className="text-primary">ICCID: </strong>{item.iccid}</Col>
                    }
                    {searchString && item.value?.toLowerCase().indexOf(searchString) >= 0 &&
                      <Col className="col-auto"><strong className="text-primary">GUID: </strong>{item.value}</Col>
                    }
                  </Row>
                </Option>
              )
            })}
          </Select>
        </div>
      </ConfigProvider>
    </div>
  )
}

SelectCarephone.propTypes = {
  t: PropTypes.any,
  selection: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
}

export default withTranslation()(SelectCarephone)