import finnish from "../assets/images/flags/finland.JPG"
import swedish from "../assets/images/flags/sweden.JPG"
import usFlag from "../assets/images/flags/uk.JPG"

const languages = {
  fi: {
    label: "Suomi",
    flag: finnish,
  },
  sv: {
    label: "Svenska",
    flag: swedish,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export default languages
