import React, { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"

// Locale/weekday related imports
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"

//Translation
import { withTranslation } from "react-i18next"
import GlobalState from "contexts/GlobalState"

const OfflineHistoryChart = props => {
  const [state] = useContext(GlobalState)
  const [language, setLanguage] = useState("fi")
  const [chartData, setChartData] = useState({
    new: [],
    automatic: [],
    manual: [],
  })

  useEffect(() => {
    setChartData(props.data)
  }, [props.data])

  // Init language
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.startsWith("fi")) {
        setLanguage("fi")
      } else if (currentLanguage.startsWith("sv")) {
        setLanguage("se")
      } else {
        setLanguage("en")
      }
    }
  }, [])

  // Language change
  useEffect(() => {
    switch (state.language) {
      case "fi":
        setLanguage("fi")
        ApexCharts.exec("node-activity-chart", "setLocale", "fi")
        break
      case "en":
        setLanguage("en")
        ApexCharts.exec("node-activity-chart", "setLocale", "en")
        break
      case "sv":
        setLanguage("se")
        ApexCharts.exec("node-activity-chart", "setLocale", "se")
        break
    }
  }, [state.language])

  const checkingSeries = () => {
    return [
      {
        name: props.t("Connection"),
        type: "area",
        data: chartData,
      },
    ]
  }

  const settingOptions = () => {
    return {
      chart: {
        id: "node-activity-chart",
        locales: [fi, en, se],
        defaultLocale: language, //"en",
        stacked: false,
        selection: {
          enabled: false,
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: -17,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: " ",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: "zoom",
        },
      },
      stroke: {
        //width: [0, 0, 0],
        curve: "stepline",
      },
      colors: ["#F8C471", "#82E0AA", "#85C1E9"],
      fill: {
        opacity: [1, 1, 1],
      },
      legend: {
        offsetY: 11,
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        min: 0,
        max: 1,
        tickAmount: 1,
        labels: {
          formatter: function (val, index) {
            return val == 0 ? "Online" : "Offline"
          },
        },
      },
      tooltip: {
        //shared: false,
        //intersect: true,
        x: {
          format: "dd.MM.yyyy HH:mm",
        },
        /*y: {
          formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
            return value
          }
        }*/
      },
      dataLabels: {
        enabled: false,
      },
    }
  }

  return (
    <div>
      <ReactApexChart
        options={settingOptions()}
        series={checkingSeries()}
        type="area"
        height="220"
      />
    </div>
  )
}

OfflineHistoryChart.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
}

export default withTranslation()(OfflineHistoryChart)
