import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import dayjs from "dayjs"
import withRouter from "components/Common/withRouter"
import iconVideocall from "assets/images/icons/videocall.svg"
import iconApp from "assets/images/icons/app.svg"
import iconMedicine from "assets/images/icons/medicineReminder.svg"
import iconGroupvideo from "assets/images/icons/groupvideo.png"
import iconWebpageAlt from "assets/images/icons/webIconAlt.svg"
import { insertReleaseNote, updateReleaseNote } from "helpers/database_helper"
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Alert,
  FormFeedback,
  Label,
  FormGroup,
  ButtonGroup,
  Button,
  Modal,
  ModalBody,
} from "reactstrap"
import ReleaseListItem from "pages/LandingPage/release-list-item"
import { DatePicker, Switch, Steps, TreeSelect } from "antd"
import { useFormik } from "formik"
import * as Yup from "yup"
import { showToast } from "utils/general"

const ReleaseNotesModal = props => {
  const [selection, setSelection] = useState(null)
  const [selectedLang, setSelectedLang] = useState(1)
  const [ongoingInsert, setOngoingInsert] = useState(false)
  const [example, setExample] = useState(null)
  const [error, setError] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [initialValues, setInitialValues] = useState({
    id: 0,
    version: "",
    status: 1,
    type: 1,
    target: 1,
    published: false,
    informed_date: dayjs(),
    release_date: dayjs(),
    what_changes_fi: "",
    what_changes_en: "",
    what_changes_sv: "",
    how_effects_fi: "",
    how_effects_en: "",
    how_effects_sv: "",
    how_use_changes_fi: "",
    how_use_changes_en: "",
    how_use_changes_sv: "",
    changes_user_methods: false,
    changes_pro_ui: false,
    changes_enduser_ui: false,
  })

  // Steps for publication
  const modalSteps = [
    {
      title: props.t("General"),
    },
    {
      title: props.t("Description"),
    },
  ]

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.modal) createRelease()
  }, [props.modal])

  useEffect(() => {
    setSelection(props.selection)
  }, [props.selection])

  useEffect(() => {
    if (selection !== null) {
      setInitialValues(selection)
    }
  }, [selection])

  // Formik validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues.id,
      version: initialValues.version,
      type: initialValues.type,
      status: initialValues.status,
      target: initialValues.target,
      published: initialValues.published,
      informed_date: initialValues.informed_date,
      release_date: initialValues.release_date,
      what_changes_fi: initialValues.what_changes_fi,
      what_changes_en: initialValues.what_changes_en,
      what_changes_sv: initialValues.what_changes_sv,
      how_use_changes_fi: initialValues.how_use_changes_fi,
      how_use_changes_en: initialValues.how_use_changes_en,
      how_use_changes_sv: initialValues.how_use_changes_sv,
      how_effects_fi: initialValues.how_effects_fi,
      how_effects_en: initialValues.how_effects_en,
      how_effects_sv: initialValues.how_effects_sv,
      changes_user_methods: initialValues.changes_user_methods,
      changes_pro_ui: initialValues.changes_pro_ui,
      changes_enduser_ui: initialValues.changes_enduser_ui,
    },
    validationSchema: Yup.object({
      version: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      setError(null)
      console.log("Submit values: ", values)
      if (values.id < 0) insertRelease(values)
      else updateRelease(values)
    },
  })
  // Form values changed, update example
  useEffect(() => {
    handleExample(validation.values)
    // console.log("changed values", validation.values)
  }, [validation.values])

  // Insert release note
  const insertRelease = vals => {
    setOngoingInsert(true)
    insertReleaseNote(abortRef.current, props.productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          props.setCount(1)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // Update release note
  const updateRelease = vals => {
    setOngoingInsert(true)
    updateReleaseNote(abortRef.current, props.productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          props.setCount(1)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // // Create new release draft
  const createRelease = () => {
    let temp = new Object()
    temp.id = -1
    temp.label = "Draft"
    temp.informed_date = dayjs()
    temp.release_date = dayjs()
    temp.published = false
    temp.target = 1
    temp.status = 1
    temp.version = ""
    temp.type = 1
    temp.changes_enduser_ui = false
    temp.changes_pro_ui = false
    temp.changes_user_methods = false
    temp.what_changes_fi = ""
    temp.what_changes_en = ""
    temp.what_changes_sv = ""
    temp.how_use_changes_fi = ""
    temp.how_use_changes_en = ""
    temp.how_use_changes_sv = ""
    temp.how_effects_fi = ""
    temp.how_effects_en = ""
    temp.how_effects_sv = ""

    // setSelection(temp)
    setInitialValues(temp)
    // setSelectedIndex(null)
  }

  // Get matching image
  const getImage = id => {
    switch (id) {
      case 1:
        return iconApp
      case 2:
        return iconMedicine
      case 3:
        return iconVideocall
      case 4:
        return iconWebpageAlt
      case 5:
        return iconGroupvideo
      default:
        return null
    }
  }
  // Get matching target name
  const getTargetName = id => {
    switch (id) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "ReleaseNoteTypes." + id
      default:
        return "Unknown"
    }
  }
  // Handle preview
  const handleExample = note => {
    console.log(note, "changed")
    let niceDate = dayjs(note.informed_date).format("DD.MM.YYYY")
    let temp = new Object()
    temp.id = note.id
    temp.date = niceDate
    temp.title = note.target + " v." + note.version

    if (note.release_date) {
      temp.releaseDate = dayjs(note.release_date).format("DD.MM.YYYY")
    }

    temp.content1_fi = note.what_changes_fi
      ? note.what_changes_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_fi = note.how_use_changes_fi
      ? note.how_use_changes_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_fi = note.how_effects_fi
      ? note.how_effects_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_en = note.what_changes_en
      ? note.what_changes_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_en = note.how_use_changes_en
      ? note.how_use_changes_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_en = note.how_effects_en
      ? note.how_effects_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_sv = note.what_changes_sv
      ? note.what_changes_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_sv = note.how_use_changes_sv
      ? note.how_use_changes_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_sv = note.how_effects_sv
      ? note.how_effects_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""

    temp.changesProUser = note.changes_pro_ui == 1 ? "Yes" : "No"
    temp.changesEndUser = note.changes_enduser_ui == 1 ? "Yes" : "No"
    temp.changesProcedure = note.changes_user_methods == 1 ? "Yes" : "No"
    temp.type = note.type == 1 ? "Hotfix" : "Update"
    temp.version = note.version

    temp.target = getTargetName(parseInt(note.target, 10))
    temp.img = getImage(parseInt(note.target, 10))
    temp.status = parseInt(note.status, 10)

    switch (temp.status) {
      case 1:
        temp.status = "Upcoming"
        break
      case 2:
        temp.status = "Waiting for release"
        break
      case 3:
        temp.status = "Postponed"
        break
      case 4:
        temp.status = "Published"
        break
      default:
        temp.status = "Unknown"
        break
    }

    setExample(temp)
  }
  const changeSteps = value => {
    setCurrentStep(value)
  }
  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  }
  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }
  const steps = modalSteps.map(item => ({
    key: item.title,
    title: item.title,
  }))

  return (
    <>
      <Modal isOpen={initialValues !== null} centered size="lg">
        <ModalBody>
          <div className="p-2">
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
              <Steps
                type="navigation"
                size="small"
                className="pb-3 pt-0"
                current={currentStep}
                items={steps}
                onChange={validation.values.version === "" ? null : changeSteps}
              ></Steps>
              {currentStep == 0 && (
                <div>
                  <Row xs={1} sm={2}>
                    <FormGroup>
                      <Label>{props.t("Service")}</Label>
                      <Input
                        value={validation.values.target + ""}
                        type="select"
                        name="target"
                        onChange={validation.handleChange}
                      >
                        <option value="1">
                          {props.t("ReleaseNoteTypes.1")}
                        </option>
                        <option value="2">
                          {props.t("ReleaseNoteTypes.2")}
                        </option>
                        <option value="3">
                          {props.t("ReleaseNoteTypes.3")}
                        </option>
                        <option value="4">
                          {props.t("ReleaseNoteTypes.4")}
                        </option>
                        <option value="5">
                          {props.t("ReleaseNoteTypes.5")}
                        </option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label>{props.t("Type")}</Label>
                      <Input
                        value={validation.values.type + ""}
                        type="select"
                        name="type"
                        onChange={validation.handleChange}
                      >
                        <option value="1">{props.t("Hotfix")}</option>
                        <option value="2">{props.t("Update")}</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label>{props.t("Status")}</Label>
                      <Input
                        value={validation.values.status + ""}
                        type="select"
                        name="status"
                        onChange={validation.handleChange}
                      >
                        <option value="1">{props.t("Upcoming")}</option>
                        <option value="2">
                          {props.t("Waiting for release")}
                        </option>
                        <option value="3">{props.t("Postponed")}</option>
                        <option value="4">{props.t("Published")}</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label>{props.t("Version")}</Label>
                      <Input
                        name="version"
                        type="text"
                        placeholder={props.t("Required field")}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.version || ""}
                        invalid={
                          !!(validation.touched.version &&
                          validation.errors.version)
                        }
                      ></Input>
                      {validation.touched.version &&
                      validation.errors.version ? (
                        <FormFeedback type="invalid">
                          {validation.errors.version}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <Label>{props.t("Release date")}</Label>
                      <DatePicker
                        style={{ width: "100%" }}
                        format="DD.MM.YYYY"
                        value={validation.values.release_date}
                        onChange={v =>
                          validation.setFieldValue("release_date", v)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>{props.t("Release note release date")}</Label>
                      <DatePicker
                        style={{ width: "100%" }}
                        showTime
                        format="DD.MM.YYYY HH:mm"
                        value={validation.values.informed_date}
                        onChange={v =>
                          validation.setFieldValue("informed_date", v)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>{props.t("Bulletin published")}</Label>
                      <Switch
                        className="ms-2"
                        name="published"
                        checked={validation.values.published}
                        onChange={v => validation.setFieldValue("published", v)}
                        checkedChildren={props.t("Yes")}
                        unCheckedChildren={props.t("No")}
                      ></Switch>
                      {/* <Label>{props.t("Release note published")}</Label> */}
                    </FormGroup>
                  </Row>
                  {example && (
                    <div>
                      <div>
                        {selection?.guid &&
                          props.productionDatabase && (
                            <span className="float-end">
                              <a
                                className=""
                                href={"/release-note/" + selection.guid}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="bx bx-link-external me-1"></i>
                                {props.t("Public link")}
                              </a>
                            </span>
                          )}
                        <span className="h6">
                          {props.t("Bulletin preview")}
                        </span>
                      </div>
                      <Card className="shadow-none border mx-2 my-1">
                        <div className="">
                          <ul className="list-group list-group-flush">
                            <li key="example" className="list-group-item py-2">
                              <ReleaseListItem item={example} />
                            </li>
                          </ul>
                        </div>
                      </Card>
                    </div>
                  )}
                </div>
              )}
              {currentStep == 1 && (
                <>
                  <ButtonGroup size="sm" className="mb-3">
                    <Button
                      color="primary"
                      active={selectedLang === 1}
                      onClick={() => setSelectedLang(1)}
                    >
                      {props.t("Finnish")}
                    </Button>
                    <Button
                      color="primary"
                      active={selectedLang === 2}
                      onClick={() => setSelectedLang(2)}
                    >
                      {props.t("English")}
                    </Button>
                    <Button
                      color="primary"
                      active={selectedLang === 3}
                      onClick={() => setSelectedLang(3)}
                    >
                      {props.t("Swedish")}
                    </Button>
                  </ButtonGroup>
                  {selectedLang === 1 && (
                    <div>
                      <FormGroup>
                        <Label>{props.t("Changes") + " (fi)"}</Label>
                        <Input
                          name="what_changes_fi"
                          placeholder={props.t("Changes")}
                          type="textarea"
                          rows={2}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.what_changes_fi || ""}
                        ></Input>
                      </FormGroup>

                      <FormGroup>
                        <Label>{props.t("Visible in") + " (fi)"}</Label>
                        <Input
                          name="how_use_changes_fi"
                          placeholder={props.t("Visible in")}
                          type="textarea"
                          rows={2}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.how_use_changes_fi || ""}
                        ></Input>
                      </FormGroup>

                      <FormGroup>
                        <Label>{props.t("Affects") + " (fi)"}</Label>
                        <Input
                          name="how_effects_fi"
                          placeholder={props.t("Affects")}
                          type="textarea"
                          rows={2}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.how_effects_fi || ""}
                        ></Input>
                      </FormGroup>
                    </div>
                  )}
                  {selectedLang === 2 && (
                    <div>
                      <FormGroup>
                        <Label>{props.t("Changes") + " (en)"}</Label>
                        <Input
                          name="what_changes_en"
                          placeholder={props.t("Changes")}
                          type="textarea"
                          rows={2}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.what_changes_en || ""}
                        ></Input>
                      </FormGroup>

                      <FormGroup>
                        <Label>{props.t("Visible in") + " (en)"}</Label>
                        <Input
                          name="how_use_changes_en"
                          placeholder={props.t("Visible in")}
                          type="textarea"
                          rows={2}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.how_use_changes_en || ""}
                        ></Input>
                      </FormGroup>

                      <FormGroup>
                        <Label>{props.t("Affects") + " (en)"}</Label>
                        <Input
                          name="how_effects_en"
                          placeholder={props.t("Affects")}
                          type="textarea"
                          rows={2}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.how_effects_en || ""}
                        ></Input>
                      </FormGroup>
                    </div>
                  )}
                  {selectedLang === 3 && (
                    <div>
                      <FormGroup>
                        <Label>{props.t("Changes") + " (sv)"}</Label>
                        <Input
                          name="what_changes_sv"
                          placeholder={props.t("Changes")}
                          type="textarea"
                          rows={2}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.what_changes_sv || ""}
                        ></Input>
                      </FormGroup>

                      <FormGroup>
                        <Label>{props.t("Visible in") + " (sv)"}</Label>
                        <Input
                          name="how_use_changes_sv"
                          placeholder={props.t("Visible in")}
                          type="textarea"
                          rows={2}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.how_use_changes_sv || ""}
                        ></Input>
                      </FormGroup>

                      <FormGroup>
                        <Label>{props.t("Affects") + " (sv)"}</Label>
                        <Input
                          name="how_effects_sv"
                          placeholder={props.t("Affects")}
                          type="textarea"
                          rows={2}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.how_effects_sv || ""}
                        ></Input>
                      </FormGroup>
                    </div>
                  )}
                  <Row xs={1} sm={2} className="mt-4">
                    <Col>
                      <FormGroup>
                        <Label className="me-2" name="changes_enduser_ui">
                          {props.t("User interface for end user")}
                        </Label>
                        <Switch
                          className="ms-2"
                          name="changes_enduser_ui"
                          checked={validation.values.changes_enduser_ui}
                          onChange={v =>
                            validation.setFieldValue("changes_enduser_ui", v)
                          }
                          checkedChildren={props.t("Yes")}
                          unCheckedChildren={props.t("No")}
                          defaultChecked
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>
                          {props.t("User interface for professional user")}
                        </Label>
                        <Switch
                          checkedChildren={props.t("Yes")}
                          unCheckedChildren={props.t("No")}
                          className="ms-2"
                          name="changes_pro_ui"
                          checked={validation.values.changes_pro_ui}
                          onChange={v =>
                            validation.setFieldValue("changes_pro_ui", v)
                          }
                        ></Switch>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Procedural changes")}</Label>
                        <Switch
                          checkedChildren={props.t("Yes")}
                          unCheckedChildren={props.t("No")}
                          className="ms-2"
                          name="changes_user_methods"
                          checked={validation.values.changes_user_methods}
                          onChange={v =>
                            validation.setFieldValue("changes_user_methods", v)
                          }
                        ></Switch>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              {/* {currentStep == 2 && (
                <div>
                  <h6>{props.t("Selected subscribers")}</h6>
                  <TreeSelect
                    treeData={treeData}
                    value={selectedTreeValues}
                    onChange={handleChange}
                    treeCheckable={true}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    // placeholder=""
                    style={{ width: "100%" }}
                    labelInValue={true}
                    multiple={true}
                    treeDefaultExpandAll={true}
                  />
                </div>
              )}
              {currentStep == 3 && <div></div>} */}

              <div
                style={{
                  marginTop: 24,
                }}
              >
                {currentStep == 0 && (
                  <Button
                    color="primary"
                    disabled={ongoingInsert}
                    style={{ minWidth: "160px" }}
                    onClick={() => {
                      validation.submitForm()
                    }}
                  >
                    <i className="bx bxs-save me-1"></i>
                    {props.t("Save")}
                  </Button>
                )}
                <Button
                  color="primary"
                  outline
                  className="ms-2"
                  onClick={() => {
                    props.setSelection(null)
                    props.modal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>

                {currentStep < modalSteps.length - 1 && (
                  <>
                    {currentStep === 1 ? (
                      <Button
                        color="primary"
                        disabled={ongoingInsert}
                        style={{ minWidth: "160px" }}
                        onClick={() => {
                          validation.submitForm()
                        }}
                      >
                        <i className="bx bxs-save me-1"></i>
                        {props.t("Save")}
                      </Button>
                    ) : (
                      <Button
                        className="float-end"
                        color="primary"
                        onClick={() => nextStep()}
                        disabled={validation.values.version == ""}
                      >
                        {props.t("Next")}
                      </Button>
                    )}
                  </>
                )}
                {currentStep > 0 && (
                  <>
                    <Button
                      className="float-end"
                      color="primary"
                      disabled={ongoingInsert}
                      style={{ minWidth: "160px" }}
                      onClick={() => {
                        validation.submitForm()
                        // props.modal(false)
                      }}
                    >
                      <i className="bx bxs-save me-1"></i>
                      {props.t("Save")}
                    </Button>
                    <Button
                      className="float-end"
                      color="primary"
                      style={{
                        margin: "0 8px",
                      }}
                      onClick={() => prevStep()}
                    >
                      {props.t("Previous")}
                    </Button>
                  </>
                )}
              </div>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

ReleaseNotesModal.propTypes = {
  t: PropTypes.any,
  selection: PropTypes.any,
  setSelection: PropTypes.any,
  productionDatabase: PropTypes.any,
  modal: PropTypes.any,
  subscribers: PropTypes.any,
  setCount: PropTypes.func,
}

export default withRouter(withTranslation()(ReleaseNotesModal))
