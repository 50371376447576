/**********************
 * DELETE VIDEO GROUP MODAL
 *********************/
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { showToast } from "utils/general"
import { acDeleteGroup } from "helpers/backend_helper"

const DeleteVideoGroupModal = props => {
  const [ongoingAction, setOngoingAction] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Delete group
  const deleteGroup = () => {
    if (!ongoingAction && props.groupId > 1) {
      setOngoingAction(true)
      acDeleteGroup(abortRef.current, props.groupId, props.production).then(result => {
        if (result.statusCode == 200) {
          showToast(props.t("Deleted"), "success")
          if (props.setShowModal) {
            props.setShowModal(false)
          }
          if (props.refreshGroups) {
            props.refreshGroups()
          }
        }
        // 3167 Group still has users attached.
        // 3168 Group still has groups attached.
        else if (result.error_code == 3167 || result.error_code == 3168) {
          showToast(props.t("Group can't be deleted because of dependencies"), "error")
        }
        else throw new Error("Unexpeced result")
      }).catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      }).finally(() => {
        setOngoingAction(false)
      })
    }
  }

  return (
    <>
      <Modal
        isOpen={props.showModal}
        toggle={() => {
          if (props.setShowModal) {
            props.setShowModal(!props.showModal)
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {props.t("Delete group") + ": " + props.groupName}
          </h5>
          <button
            type="button"
            onClick={() => {
              if (props.setShowModal) {
                props.setShowModal(false)
              }
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {props.t("Are you sure you want to delete the selected group?")}
        </ModalBody>
        <ModalFooter className="p-2 mt-2">
          <Col lg={12} className="mt-2 mb-0  text-truncate">
            <Row>
              <Col xs={12}>
                <span className="float-end">
                  <button
                    type="button"
                    onClick={() => {
                      if (props.setShowModal) {
                        props.setShowModal(false)
                      }
                    }}
                    className="btn btn-outline-primary "
                    data-dismiss="modal"
                  >
                    {props.t("Cancel")}
                  </button>

                  <button
                    disabled={ongoingAction || !props.groupId}
                    className="btn btn-danger ms-2"
                    onClick={() => {
                      deleteGroup()
                    }}
                  >
                    {props.t("Delete")}
                  </button>
                </span>
              </Col>
            </Row>
          </Col>
        </ModalFooter>
      </Modal>
    </>
  )
}

DeleteVideoGroupModal.propTypes = {
  t: PropTypes.any,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  refreshGroups: PropTypes.func,
  groupId: PropTypes.any,
  groupName: PropTypes.string,
  production: PropTypes.bool,
}

export default withTranslation()(DeleteVideoGroupModal)