// Landing page -content
import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Collapse } from "antd"
const { Panel } = Collapse

const InstructionsReporting = props => {
  return (
    <div className="instructions">
      <div className="px-1">
        <h5 className="font-size-15">{props.t("Reporting")}</h5>
        <p className="pt-2">{props.t("ReportingManual." + "0")}</p>
        <p className="pt-2">{props.t("ReportingManual." + "1")}</p>
      </div>
      <Collapse
        bordered={false}
        className="px-0 "
        accordion
        expandIcon={props.ExpandIcon}
        style={{ backgroundColor: "transparent" }}
      >
        <Panel key={0} header={props.t("Search2")}>
          <p>{props.t("ReportingManual." + "2")}</p>
          <p>{props.t("ReportingManual." + "3")}</p>
        </Panel>

        <Panel key={1} header={props.t("Information")}>
          <p>{props.t("ReportingManual." + "4")}</p>
          <p>{props.t("ReportingManual." + "5")}</p>
          <p>{props.t("ReportingManual." + "6")}</p>
        </Panel>
      </Collapse>
    </div>
  )
}

InstructionsReporting.propTypes = {
  t: PropTypes.any,
  ExpandIcon: PropTypes.func,
}

export default withTranslation()(InstructionsReporting)
