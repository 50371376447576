import React, { useState, useEffect, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import PropTypes from "prop-types"

// General imports
import { formatDateFull } from "utils/general"

//Translation
import { withTranslation } from "react-i18next"

const ChartCalls = props => {
  const [stats, setStats] = useState()
  const [list, setList] = useState(props.data.list)

  // Chosen content
  const [subject] = useState(props.t("All calls"))

  // Card states
  const [total, setTotal] = useState(0)
  const [durationA, setDurationA] = useState(0)
  const [durationM, setDurationM] = useState(0)

  const [filteredList, setFilteredList] = useState(list)

  // Updating states
  useEffect(() => {
    if (props.data?.stats && props.data.stats !== stats) {
      console.log("Stats: " + JSON.stringify(props.data))
      setStats(props.data.stats)
      setList(props.data.list)

      if (props.data.list != filteredList) {
        setList(props.data.list)
        setFilteredList(props.data.list)
      }

      // filterData(props.data.list)
      handlingCategory(subject, props.data.list, subject)

      // Set given stats
      setDurationA(props.data.stats.duration_average)
      setTotal(props.data.stats.total)
      setDurationM(props.data.stats.duration_median)
    }
  }, [props.data])

  // Setting CustomersData
  const handlingCategory = (ty, fl, subject) => {
    let result = []
    let resultGroups = []
    let resultCombined = []
    let cus = []
    let gro = []

    cus = fl.map(g => g.client_name)
    let customers = [...new Set(cus)]

    gro = fl.map(g => g.group)
    let groups = [...new Set(gro)]

    customers.forEach(c => {
      let count = fl.filter(f => f.client_name === c)
      let relative = fl.filter(
        f => f.caller_role == "relative" && f.client_name === c
      )
      let group = count[0].group
      let customerData = {
        name: c,
        group: group,
        type: subject,
        time: formatDateFull(props.start) + " - " + formatDateFull(props.end),
        relativeCalls: relative.length,
        amount: count.length,
        data: count,
      }
      result.push(customerData)
    })
    groups.forEach(g => {
      let count = fl.filter(f => f.group === g)
      let relative = fl.filter(
        f => f.caller_role == "relative" && f.group === g
      ).length
      let groupData = {
        name: g,
        type: subject,
        relativeCalls: relative,
        amount: count.length,
      }
      resultGroups.push(groupData)
    })

    resultGroups.forEach(rg => {
      let rest = result.filter(s => s.group == rg.name)
      let combinedData = {
        group: rg.name,
        relativeCalls: rg.relativeCalls,
        amount: rg.amount,
        clients: rest,
      }
      resultCombined.push(combinedData)
    })
  }

  // Function for formatting seconds -> hours, minutes, seconds
  const formatTime = value => {
    let hours = Math.floor(value / 3600)
    let minutes = Math.floor((value - hours * 3600) / 60)
    let seconds = value - hours * 3600 - minutes * 60
    let time = ""

    if (value == 0) {
      time = "-"
    }
    if (value != 0) {
      if (hours < 10) {
        hours = "0" + hours
      }
      if (minutes < 10) {
        minutes = "0" + minutes
      }
      if (seconds < 10) {
        seconds = "0" + seconds
      }
      time = hours + ":" + minutes + ":" + seconds
    }

    return time
  }

  // Calls time
  const timeStats = [
    {
      id: 0,
      value: durationA,
      text: props.t("Average"),
      series: formatTime(durationA),
    },
    {
      id: 1,
      value: durationM,
      text: props.t("Median"),
      series: formatTime(durationM),
    },
  ]

  return (
    <React.Fragment>
      <div id="calls-chart">
        <Row className=" mt-0 mb-2">
          <div className="d-flex flex-wrap">
            <span>
              <h5 className="mt-0">{props.t("Group video calls")}</h5>
            </span>
          </div>
        </Row>
        {stats != [] && (
          <>
            <Row>
              {/* All calls */}
              <Col lg="5" className="">
                <Card style={{ height: 127 + "px" }}>
                  <CardBody>
                    <div className="d-flex ">
                      <div className="avatar-md mt-2 ms-0">
                        <div className="avatar-title bg-light rounded-circle text-primary h1">
                          <i className="bx bx-group"></i>
                        </div>
                      </div>
                      <div className="ms-4 mt-2">
                        <p className="text-muted font-size-14 mb-2">
                          {props.t("Total group video calls")}
                        </p>
                        <h2 className="mb-0">{total}</h2>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* Duration of calls */}
              <Col lg="7">
                <Card style={{ height: 127 + "px" }}>
                  <CardBody>
                    <Col lg="12">
                      <Row>
                        <Col xs="2">
                          <div className="avatar-md mt-2 ms-0">
                            <div className="avatar-title bg-light rounded-circle text-primary h1">
                              <i className="bx bx-time"></i>
                            </div>
                          </div>
                        </Col>
                        <Col xs="9">
                          <CardTitle>
                            {props.t("Group call duration")}
                          </CardTitle>
                          <Row>
                            {timeStats.map(time => (
                              <Col xs="6" key={time.id + "_col"}>
                                <div className="mb-2 mt-1 text-muted">
                                  {time.text}
                                </div>
                                <Row>
                                  <Col xs="6">
                                    <div className="font-size-14 h5">
                                      {time.series}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

ChartCalls.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  start: PropTypes.any,
  end: PropTypes.any,
}

export default withTranslation()(ChartCalls)
