import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { getGroups } from "helpers/backend_helper"
import { Select, ConfigProvider } from "antd"

const SelectGroup = props => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Get dropdown options from server
  const getData = () => {
    setLoading(true)
    getGroups(abortRef.current).then(result => {
      if (result.statusCode === 200 && result.entries) {
        let opts = []
        let counter = 1
        result.entries.forEach(group => {
          let groupObject = new Object()
          groupObject.id = counter++
          groupObject.label = group.name
          groupObject.value = group.name
          opts.push(groupObject)
        })
        setOptions(opts)
      }
      else {
        setOptions([])
        //handleChange(null)
      }
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  // Group selection changed
  const handleChange = newSelection => {
    if (props.onChange != null) {
      props.onChange(newSelection)
    }
  }

  // Dropdown visibility, get data at first
  const visibilityChanged = (visible) => {
    if (visible && options.length == 0 && !loading) {
      getData()
    }
  }

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("No groups found")}</p>
    </div>
  )

  return (
    <div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <div className="config-provider">
        <Select
          showSearch
          loading={loading}
          onDropdownVisibleChange={visibilityChanged}
          value={props.selection}
          mode={props.multiple ? "multiple": null}
          allowClear={props.clearable != null ? props.clearable : true}
          placeholder={props.multiple ? props.t("Select groups") : props.t("Select group")}
          style={{ width: 100 + "%" }}
          onChange={handleChange}
          options={options}
        />
      </div>
      </ConfigProvider>
    </div>
  )
}

SelectGroup.propTypes = {
  t: PropTypes.any,
  selection: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
}

export default withTranslation()(SelectGroup)