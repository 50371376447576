import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
  Form,
  Input,
  Alert,
  FormFeedback,
  Label,
  FormGroup,
  ButtonGroup,
  Button,
  Modal,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { DatePicker, Switch, Popconfirm, Select, ConfigProvider } from "antd"
import dayjs from "dayjs"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  deleteReleaseNote,
  getReleaseNotes,
  insertReleaseNote,
  updateReleaseNote,
} from "helpers/database_helper"
import ReleaseListItem from "pages/LandingPage/release-list-item"
import iconVideocall from "../../../assets/images/icons/videocall.svg"
import iconApp from "../../../assets/images/icons/app.svg"
import iconMedicine from "../../../assets/images/icons/medicineReminder.svg"
import iconGroupvideo from "../../../assets/images/icons/groupvideo.png"
import iconWebpageAlt from "../../../assets/images/icons/webIconAlt.svg"
import Lottie from "react-lottie"
import { getLottieLoadingOptions, showToast } from "utils/general"

const ReleaseNotesPage = props => {
  const [releaseNotes, setReleaseNotes] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [selection, setSelection] = useState(null)
  const [example, setExample] = useState(null)
  const [selectedLang, setSelectedLang] = useState(1)
  const [error, setError] = useState(null)
  const [modal, setModal] = useState(false)

  const [rowCount, setRowCount] = useState(100)
  const [targetFilter, setTargetFilter] = useState("0")
  const [statusFilter, setStatusFilter] = useState("0")
  const [productionDatabase, setProductionDatabase] = useState(true)

  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [ongoingInsert, setOngoingInsert] = useState(false)

  const [initialValues, setInitialValues] = useState({
    id: 0,
    version: "",
    status: 1,
    type: 1,
    target: 1,
    published: false,
    informed_date: dayjs(),
    release_date: dayjs(),
    what_changes_fi: "",
    what_changes_en: "",
    what_changes_sv: "",
    how_effects_fi: "",
    how_effects_en: "",
    how_effects_sv: "",
    how_use_changes_fi: "",
    how_use_changes_en: "",
    how_use_changes_sv: "",
    changes_user_methods: false,
    changes_pro_ui: false,
    changes_enduser_ui: false,
  })

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Release notes") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // DB selection changed, clear everything
  useEffect(() => {
    setSelection(null)
    setReleaseNotes([])
    setSelectedIndex(null)
  }, [productionDatabase])

  // Formik validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues.id,
      guid: initialValues.guid,
      version: initialValues.version,
      type: initialValues.type,
      status: initialValues.status,
      target: initialValues.target,
      published: initialValues.published,
      informed_date: initialValues.informed_date,
      release_date: initialValues.release_date,
      what_changes_fi: initialValues.what_changes_fi,
      what_changes_en: initialValues.what_changes_en,
      what_changes_sv: initialValues.what_changes_sv,
      how_use_changes_fi: initialValues.how_use_changes_fi,
      how_use_changes_en: initialValues.how_use_changes_en,
      how_use_changes_sv: initialValues.how_use_changes_sv,
      how_effects_fi: initialValues.how_effects_fi,
      how_effects_en: initialValues.how_effects_en,
      how_effects_sv: initialValues.how_effects_sv,
      changes_user_methods: initialValues.changes_user_methods,
      changes_pro_ui: initialValues.changes_pro_ui,
      changes_enduser_ui: initialValues.changes_enduser_ui,
    },
    validationSchema: Yup.object({
      version: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      setError(null)
      console.log("Submit values: ", values)
      if (values.id < 0) insertRelease(values)
      else updateRelease(values)
    },
  })

  // Form values changed, update example
  useEffect(() => {
    handleExample(validation.values)
  }, [validation.values])

  // Get release notes
  const fetchReleases = (clearSelection = true) => {
    if (clearSelection) {
      setSelectedIndex(null)
      setSelection(null)
    }

    // Set filters
    let filters = new Object()
    filters.include_unpublished = true
    const targetNum = parseInt(targetFilter, 10)
    if (targetNum > 0) filters.target = targetNum
    const statusNum = parseInt(statusFilter, 10)
    if (statusNum > 0) filters.status = statusNum

    setOngoingSearch(true)
    //console.log("getReleaseNotes: ", productionDatabase, rowCount, filters)
    getReleaseNotes(abortRef.current, productionDatabase, rowCount, filters)
      .then(notes => {
        notes.forEach(note => {
          note.label = props.t(getTargetName(note.target)) + " " + note.version
          note.value = note.id
        })
        setReleaseNotes(notes)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setOngoingSearch(false)
      })
  }

  // Insert release note
  const insertRelease = vals => {
    setOngoingInsert(true)
    insertReleaseNote(abortRef.current, productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          fetchReleases(true)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // Update release note
  const updateRelease = vals => {
    setOngoingInsert(true)
    updateReleaseNote(abortRef.current, productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          fetchReleases(false)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // Delete release note
  const deleteRelease = () => {
    if (selection && selection.id >= 0) {
      setOngoingDelete(true)
      deleteReleaseNote(abortRef.current, productionDatabase, selection.id)
        .then(result => {
          if (result == "OK") {
            showToast(props.t("Deleted"), "success")
            setSelection(null)
            setSelectedIndex(null)
            fetchReleases(true)
          } else {
            console.log("Delete result: ", result)
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingDelete(false)
        })
    } else {
      setSelection(null)
      setSelectedIndex(null)
    }
  }

  // Get matching target name
  const getTargetName = id => {
    switch (id) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "ReleaseNoteTypes." + id
      default:
        return "Unknown"
    }
  }

  // Get matching image
  const getImage = id => {
    switch (id) {
      case 1:
        return iconApp
      case 2:
        return iconMedicine
      case 3:
        return iconVideocall
      case 4:
        return iconWebpageAlt
      case 5:
        return iconGroupvideo
      default:
        return null
    }
  }

  // Selected release changed
  const handleChange = newSelection => {
    //console.log(`Option selected:`, newSelection)
    let index = releaseNotes.findIndex(el => el.id === newSelection)
    if (index >= 0) {
      let copy = JSON.parse(JSON.stringify(releaseNotes[index]))
      copy.release_date = dayjs(copy.release_date)
      copy.informed_date = dayjs(copy.informed_date)

      setSelection(copy)
      setSelectedIndex(newSelection)
      setInitialValues(copy)
    } else {
      setSelection(null)
      setSelectedIndex(null)
    }
  }

  // Handle preview
  const handleExample = note => {
    let niceDate = dayjs(note.informed_date).format("DD.MM.YYYY")
    let temp = new Object()
    temp.id = note.id
    temp.guid = note.guid
    temp.date = niceDate
    temp.title = note.target + " v." + note.version

    if (note.release_date) {
      temp.releaseDate = dayjs(note.release_date).format("DD.MM.YYYY")
    }

    temp.content1_fi = note.what_changes_fi
      ? note.what_changes_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_fi = note.how_use_changes_fi
      ? note.how_use_changes_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_fi = note.how_effects_fi
      ? note.how_effects_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_en = note.what_changes_en
      ? note.what_changes_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_en = note.how_use_changes_en
      ? note.how_use_changes_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_en = note.how_effects_en
      ? note.how_effects_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_sv = note.what_changes_sv
      ? note.what_changes_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_sv = note.how_use_changes_sv
      ? note.how_use_changes_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_sv = note.how_effects_sv
      ? note.how_effects_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""

    temp.changesProUser = note.changes_pro_ui == 1 ? "Yes" : "No"
    temp.changesEndUser = note.changes_enduser_ui == 1 ? "Yes" : "No"
    temp.changesProcedure = note.changes_user_methods == 1 ? "Yes" : "No"
    temp.type = note.type == 1 ? "Hotfix" : "Update"
    temp.version = note.version

    temp.target = getTargetName(parseInt(note.target, 10))
    temp.img = getImage(parseInt(note.target, 10))
    temp.status = parseInt(note.status, 10)

    switch (temp.status) {
      case 1:
        temp.status = "Upcoming"
        break
      case 2:
        temp.status = "Waiting for release"
        break
      case 3:
        temp.status = "Postponed"
        break
      case 4:
        temp.status = "Published"
        break
      default:
        temp.status = "Unknown"
        break
    }

    setExample(temp)
  }

  // Create new release draft
  const createRelease = () => {
    let temp = new Object()
    temp.id = -1
    temp.label = "Draft"
    temp.informed_date = dayjs()
    temp.release_date = dayjs()
    temp.published = false
    temp.target = 1
    temp.status = 1
    temp.version = ""
    temp.type = 1
    temp.changes_enduser_ui = false
    temp.changes_pro_ui = false
    temp.changes_user_methods = false
    temp.what_changes_fi = ""
    temp.what_changes_en = ""
    temp.what_changes_sv = ""
    temp.how_use_changes_fi = ""
    temp.how_use_changes_en = ""
    temp.how_use_changes_sv = ""
    temp.how_effects_fi = ""
    temp.how_effects_en = ""
    temp.how_effects_sv = ""

    setSelection(temp)
    setInitialValues(temp)
    setSelectedIndex(null)
  }

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("No entries found")}</p>
    </div>
  )

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Release notes")} />
          <Row>
            <Col lg={3} xxl={3}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Search parameters")}
                  </CardTitle>

                  <FormGroup>
                    <Label>{props.t("Service")}</Label>
                    <Input
                      value={targetFilter}
                      type="select"
                      name="targetFilter"
                      onChange={v => {
                        setTargetFilter(v.target.value)
                      }}
                    >
                      <option value="0">{props.t("All")}</option>
                      <option value="1">{props.t("ReleaseNoteTypes.1")}</option>
                      <option value="2">{props.t("ReleaseNoteTypes.2")}</option>
                      <option value="3">{props.t("ReleaseNoteTypes.3")}</option>
                      <option value="4">{props.t("ReleaseNoteTypes.4")}</option>
                      <option value="5">{props.t("ReleaseNoteTypes.5")}</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label>{props.t("Status")}</Label>
                    <Input
                      value={statusFilter}
                      type="select"
                      name="statusFilter"
                      onChange={v => {
                        setStatusFilter(v.target.value)
                      }}
                    >
                      <option value="0">{props.t("All")}</option>
                      <option value="1">{props.t("Upcoming")}</option>
                      <option value="2">
                        {props.t("Waiting for release")}
                      </option>
                      <option value="3">{props.t("Postponed")}</option>
                      <option value="4">{props.t("Published")}</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label>{props.t("Rows")}</Label>
                    <Input
                      name="limit"
                      type="number"
                      onChange={v => {
                        if (v.target.value.length > 0)
                          setRowCount(parseInt(v.target.value, 10))
                      }}
                      defaultValue={rowCount}
                    ></Input>
                  </FormGroup>

                  <FormGroup className="mt-4">
                    <Switch
                      className="me-2"
                      name="productionDb"
                      checked={productionDatabase}
                      onChange={v => {
                        setProductionDatabase(v)
                      }}
                    ></Switch>
                    <Label>{props.t("Production database")}</Label>
                  </FormGroup>

                  <Button
                    color="primary"
                    style={{ width: "100%" }}
                    disabled={ongoingSearch}
                    onClick={() => fetchReleases(true)}
                    className="mb-2"
                  >
                    {props.t("Search")}
                  </Button>
                </CardBody>
              </Card>
            </Col>

            <Col style={{ maxWidth: "1080px" }}>
              <Card>
                <CardBody>
                  <Row>
                    {ongoingSearch && (
                      <Col
                        className="d-inline-flex"
                        style={{ alignItems: "center" }}
                      >
                        <Lottie
                          options={getLottieLoadingOptions()}
                          height={36}
                          width={100}
                        />
                      </Col>
                    )}
                    {!ongoingSearch && (
                      <>
                        <Col
                          className="col-auto d-inline-flex"
                          style={{ alignItems: "center" }}
                        >
                          <h5 className="mb-0">
                            {props.t("Results found") +
                              ": " +
                              releaseNotes.length}
                          </h5>
                        </Col>
                        <Col
                          className="d-inline-flex"
                          style={{ alignItems: "center" }}
                        >
                          <ConfigProvider
                            style={{ width: "100%" }}
                            renderEmpty={customizeRenderEmpty}
                          >
                            <div
                              style={{ width: "100%" }}
                              className="config-provider"
                            >
                              <Select
                                showSearch
                                allowClear
                                loading={ongoingSearch}
                                placeholder={props.t("Select release")}
                                style={{ width: "100%" }}
                                value={selectedIndex}
                                onChange={handleChange}
                                options={releaseNotes}
                                filterOption={(inputValue, option) => {
                                  return (
                                    option.label
                                      .toLowerCase()
                                      .indexOf(inputValue.toLowerCase()) >= 0
                                  )
                                }}
                              />
                            </div>
                          </ConfigProvider>
                        </Col>
                      </>
                    )}
                    <Col className="col-auto">
                      <Button
                        className="ms-2"
                        color="primary"
                        style={{ minWidth: "120px" }}
                        onClick={() => {
                          createRelease()
                        }}
                      >
                        {props.t("Create new")}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {selection && (
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {props.t("Release note content")}
                      {selection.guid && productionDatabase && (
                        <>
                          <a
                            className="ms-2 font-size-14"
                            href={"/release-note/" + selection.guid}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {props.t("Public link")}
                          </a>
                        </>
                      )}
                      <Button
                        outline
                        color="primary"
                        size="sm"
                        className="float-end"
                        style={{ borderRadius: "100px" }}
                        onClick={() => {
                          setSelection(null)
                          setSelectedIndex(null)
                        }}
                      >
                        {props.t("Close")}
                      </Button>
                    </CardTitle>
                    <div className="p-1">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          return false
                        }}
                      >
                        {error ? (
                          <Alert color="danger">{props.t(error)}</Alert>
                        ) : null}

                        <Row xs={1} sm={2}>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Service")}</Label>
                              <Input
                                value={validation.values.target + ""}
                                type="select"
                                name="target"
                                onChange={validation.handleChange}
                              >
                                <option value="1">
                                  {props.t("ReleaseNoteTypes.1")}
                                </option>
                                <option value="2">
                                  {props.t("ReleaseNoteTypes.2")}
                                </option>
                                <option value="3">
                                  {props.t("ReleaseNoteTypes.3")}
                                </option>
                                <option value="4">
                                  {props.t("ReleaseNoteTypes.4")}
                                </option>
                                <option value="5">
                                  {props.t("ReleaseNoteTypes.5")}
                                </option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Type")}</Label>
                              <Input
                                value={validation.values.type + ""}
                                type="select"
                                name="type"
                                onChange={validation.handleChange}
                              >
                                <option value="1">{props.t("Hotfix")}</option>
                                <option value="2">{props.t("Update")}</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row xs={1} sm={2}>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Status")}</Label>
                              <Input
                                value={validation.values.status + ""}
                                type="select"
                                name="status"
                                onChange={validation.handleChange}
                              >
                                <option value="1">{props.t("Upcoming")}</option>
                                <option value="2">
                                  {props.t("Waiting for release")}
                                </option>
                                <option value="3">
                                  {props.t("Postponed")}
                                </option>
                                <option value="4">
                                  {props.t("Published")}
                                </option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Version")}</Label>
                              <Input
                                name="version"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.version || ""}
                                invalid={
                                  !!(
                                    validation.touched.version &&
                                    validation.errors.version
                                  )
                                }
                              ></Input>
                              {validation.touched.version &&
                              validation.errors.version ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.version}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row xs={1} sm={2}>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Release date")}</Label>
                              <DatePicker
                                style={{ width: "100%" }}
                                format="DD.MM.YYYY"
                                value={validation.values.release_date}
                                onChange={v =>
                                  validation.setFieldValue("release_date", v)
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>
                                {props.t("Release note release date")}
                              </Label>
                              <DatePicker
                                style={{ width: "100%" }}
                                showTime
                                format="DD.MM.YYYY HH:mm"
                                value={validation.values.informed_date}
                                onChange={v =>
                                  validation.setFieldValue("informed_date", v)
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row xs={1} sm={2} className="mt-2">
                          <Col>
                            <FormGroup>
                              <Switch
                                className="me-2"
                                name="published"
                                checked={validation.values.published}
                                onChange={v =>
                                  validation.setFieldValue("published", v)
                                }
                              ></Switch>
                              <Label>{props.t("Release note published")}</Label>
                            </FormGroup>
                          </Col>
                        </Row>

                        <hr />

                        <ButtonGroup size="sm" className="mb-4 mt-3">
                          <Button
                            color="primary"
                            active={selectedLang === 1}
                            onClick={() => setSelectedLang(1)}
                          >
                            Suomi
                          </Button>
                          <Button
                            color="primary"
                            active={selectedLang === 2}
                            onClick={() => setSelectedLang(2)}
                          >
                            Englanti
                          </Button>
                          <Button
                            color="primary"
                            active={selectedLang === 3}
                            onClick={() => setSelectedLang(3)}
                          >
                            Ruotsi
                          </Button>
                        </ButtonGroup>

                        {selectedLang === 1 && (
                          <div>
                            <FormGroup>
                              <Label>{props.t("Changes") + " (fi)"}</Label>
                              <Input
                                name="what_changes_fi"
                                placeholder={props.t("Changes")}
                                type="textarea"
                                rows={5}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.what_changes_fi || ""}
                              ></Input>
                            </FormGroup>

                            <FormGroup>
                              <Label>{props.t("Visible in") + " (fi)"}</Label>
                              <Input
                                name="how_use_changes_fi"
                                placeholder={props.t("Visible in")}
                                type="textarea"
                                rows={5}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.how_use_changes_fi || ""
                                }
                              ></Input>
                            </FormGroup>

                            <FormGroup>
                              <Label>{props.t("Affects") + " (fi)"}</Label>
                              <Input
                                name="how_effects_fi"
                                placeholder={props.t("Affects")}
                                type="textarea"
                                rows={5}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.how_effects_fi || ""}
                              ></Input>
                            </FormGroup>
                          </div>
                        )}

                        {selectedLang === 2 && (
                          <div>
                            <FormGroup>
                              <Label>{props.t("Changes") + " (en)"}</Label>
                              <Input
                                name="what_changes_en"
                                placeholder={props.t("Changes")}
                                type="textarea"
                                rows={5}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.what_changes_en || ""}
                              ></Input>
                            </FormGroup>

                            <FormGroup>
                              <Label>{props.t("Visible in") + " (en)"}</Label>
                              <Input
                                name="how_use_changes_en"
                                placeholder={props.t("Visible in")}
                                type="textarea"
                                rows={5}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.how_use_changes_en || ""
                                }
                              ></Input>
                            </FormGroup>

                            <FormGroup>
                              <Label>{props.t("Affects") + " (en)"}</Label>
                              <Input
                                name="how_effects_en"
                                placeholder={props.t("Affects")}
                                type="textarea"
                                rows={5}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.how_effects_en || ""}
                              ></Input>
                            </FormGroup>
                          </div>
                        )}

                        {selectedLang === 3 && (
                          <div>
                            <FormGroup>
                              <Label>{props.t("Changes") + " (sv)"}</Label>
                              <Input
                                name="what_changes_sv"
                                placeholder={props.t("Changes")}
                                type="textarea"
                                rows={5}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.what_changes_sv || ""}
                              ></Input>
                            </FormGroup>

                            <FormGroup>
                              <Label>{props.t("Visible in") + " (sv)"}</Label>
                              <Input
                                name="how_use_changes_sv"
                                placeholder={props.t("Visible in")}
                                type="textarea"
                                rows={5}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.how_use_changes_sv || ""
                                }
                              ></Input>
                            </FormGroup>

                            <FormGroup>
                              <Label>{props.t("Affects") + " (sv)"}</Label>
                              <Input
                                name="how_effects_sv"
                                placeholder={props.t("Affects")}
                                type="textarea"
                                rows={5}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.how_effects_sv || ""}
                              ></Input>
                            </FormGroup>
                          </div>
                        )}

                        <Row xs={1} sm={2} className="mt-4">
                          <Col>
                            <FormGroup>
                              <Switch
                                className="me-2"
                                name="changes_enduser_ui"
                                checked={validation.values.changes_enduser_ui}
                                onChange={v =>
                                  validation.setFieldValue(
                                    "changes_enduser_ui",
                                    v
                                  )
                                }
                              ></Switch>
                              <Label>
                                {props.t("User interface for end user")}
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Switch
                                className="me-2"
                                name="changes_pro_ui"
                                checked={validation.values.changes_pro_ui}
                                onChange={v =>
                                  validation.setFieldValue("changes_pro_ui", v)
                                }
                              ></Switch>
                              <Label>
                                {props.t(
                                  "User interface for professional user"
                                )}
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Switch
                                className="me-2"
                                name="changes_user_methods"
                                checked={validation.values.changes_user_methods}
                                onChange={v =>
                                  validation.setFieldValue(
                                    "changes_user_methods",
                                    v
                                  )
                                }
                              ></Switch>
                              <Label>{props.t("Procedural changes")}</Label>
                            </FormGroup>
                          </Col>
                        </Row>

                        {example && (
                          <section>
                            <hr />
                            <div className="">
                              <ul className="list-group list-group-flush">
                                <li
                                  key="example"
                                  className="list-group-item py-3"
                                >
                                  <ReleaseListItem
                                    item={example}
                                    onOpenModal={() => {
                                      setModal(!modal)
                                    }}
                                  />
                                </li>
                              </ul>
                            </div>
                            <hr />
                          </section>
                        )}

                        <div className="mt-4 mb-2 d-flex justify-content-center">
                          {selection.id >= 0 && (
                            <Popconfirm
                              title={props.t("Confirm action")}
                              open={deleteConfirm}
                              okType="danger"
                              okText={props.t("Delete")}
                              cancelText={props.t("Cancel")}
                              onConfirm={() => {
                                deleteRelease()
                                setDeleteConfirm(false)
                              }}
                              onCancel={() => {
                                setDeleteConfirm(false)
                              }}
                            >
                              <Button
                                color="danger"
                                disabled={ongoingDelete}
                                style={{ minWidth: "160px" }}
                                onClick={() => {
                                  setDeleteConfirm(true)
                                }}
                              >
                                {props.t("Delete bulletin")}
                              </Button>
                            </Popconfirm>
                          )}
                          <Button
                            color="primary"
                            disabled={ongoingInsert}
                            className="ms-4"
                            style={{ minWidth: "160px" }}
                            onClick={() => {
                              validation.submitForm()
                            }}
                          >
                            {props.t("Save changes")}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            toggle={() => {
              setModal(!modal)
            }}
            centered
          >
            {example && (
              <div>
                <div className="modal-header">
                  <h5 className="modal-title mt-0">
                    {props.t(example.target) + " v." + example.version}
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h6>{props.t("Changes")}</h6>
                  <p style={{ whiteSpace: "pre-line" }}>
                    {example.content1_fi}
                  </p>
                  <h6 className="mt-4">{props.t("Visible in")}</h6>
                  <p style={{ whiteSpace: "pre-line" }}>
                    {example.content2_fi}
                  </p>
                  <h6 className="mt-4">{props.t("Affects")}</h6>
                  <table className="table" role="presentation">
                    <tbody>
                      <tr>
                        <td>{props.t("User interface for end user")}</td>
                        <td>{props.t(example.changesEndUser)}</td>
                      </tr>
                      <tr>
                        <td>
                          {props.t("User interface for professional user")}
                        </td>
                        <td>{props.t(example.changesProUser)}</td>
                      </tr>
                      <tr>
                        <td>{props.t("Procedural changes")}</td>
                        <td>{props.t(example.changesProcedure)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="mb-0" style={{ whiteSpace: "pre-line" }}>
                    {example.content3_fi}
                  </p>
                  {example.releaseDate != null &&
                    example.releaseDate.length > 0 && (
                      <div>
                        <h6 className="mt-4">
                          {props.t("Release date") + ": " + example.releaseDate}
                        </h6>
                      </div>
                    )}
                </div>
              </div>
            )}
          </Modal>
        </Container>
      </div>
    </>
  )
}

ReleaseNotesPage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ReleaseNotesPage)
