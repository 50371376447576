import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import * as Yup from "yup"
import { useFormik } from "formik"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import {
  getClientsSmsAlarmConfigs,
  updateClientsSmsAlarmConfigs,
} from "helpers/backend_helper"
import { getLottieLoadingOptions, showToast } from "utils/general"
import Lottie from "react-lottie"
import PubSub from "pubsub-js"
import { Switch } from "antd"

const SmsAlarmsConfig = props => {
  const [data, setData] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [alarmList, setAlarmList] = useState([])
  const [currentAlarms, setCurrentAlarms] = useState([])
  const [selectedAlarms, setSelectedAlarms] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("SMS alarms") + " | Suvanto Care"
    abortRef.current = new AbortController()

    // Populate alarm array
    let alarms = []
    for (let i = 1; i < 100; i++) {
      let prop = "AlarmTitles." + i
      let trans = props.t(prop)
      if (trans != prop) {
        let entry = new Object()
        entry.id = i
        entry.name = trans
        entry.service = ""

        switch (i) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            entry.service = props.t("On the Go")
            break
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 16:
          case 23:
          case 25:
            entry.service = props.t("Home")
            break
          default: continue
        }

        alarms.push(entry)
      }
    }
    alarms.sort((a, b) => (a.name > b.name ? 1 : -1))
    setAlarmList(alarms)

    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getData()
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (props.suvantoKey) {
      getData()
    }
  }, [props.suvantoKey])

  // Get data
  const getData = () => {
    if (props.suvantoKey && !loading) {
      setLoading(true)
      getClientsSmsAlarmConfigs(abortRef.current, props.suvantoKey)
        .then(result => {
          //console.log("DATA: ", result)
          if (result.statusCode == 200) {
            setData(result.data)

            let temp = []
            result.data.alarms.forEach(element => {
              let entry = new Object()
              entry.id = element
              entry.name = props.t("AlarmTitles." + element)
              temp.push(entry)
            })
            setCurrentAlarms(temp)
            setSelectedAlarms(result.data.alarms)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      securityService: data?.securityService,
      keyNumber: data?.keyNumber,
      alarmDelayMinutes: data?.alarmDelayMinutes,
      securityWeekdaysStart1: data?.securityWeekdaysStart1,
      securityWeekdaysStart2: data?.securityWeekdaysStart2,
      securityWeekdaysEnd1: data?.securityWeekdaysEnd1,
      securityWeekdaysEnd2: data?.securityWeekdaysEnd2,
      securityWeekendStart1: data?.securityWeekendStart1,
      securityWeekendStart2: data?.securityWeekendStart2,
      securityWeekendEnd1: data?.securityWeekendEnd1,
      securityWeekendEnd2: data?.securityWeekendEnd2,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {

      let temp = JSON.parse(JSON.stringify(values))
      temp.alarms = selectedAlarms
      console.log("Submit values: ", temp)

      setUpdating(true)
      updateClientsSmsAlarmConfigs(abortRef.current, props.suvantoKey, temp)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Information updated"), "success")
            setEditMode(false)
            getData()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdating(false)
        })
    },
  })

  // table columns
  const columns = [
    {
      dataField: "name",
      text: props.t("Alarm"),
      sort: true,
    },
    {
      dataField: "service",
      text: props.t("Service"),
      sort: true,
    },
  ]

  const columnsPreview = [
    {
      dataField: "name",
      text: props.t("Enabled alarms"),
      sort: true,
    },
  ]

  const pageOptions = {
    custom: true,
    sizePerPageList: [{ text: props.t("All"), value: alarmList.length }],
  }

  const defaultSorted = [
    {
      dataField: "service",
      order: "asc",
    },
  ]

  const selectRow = {
    mode: "checkbox",
    selected: selectedAlarms,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedAlarms(prevState => [...prevState, row.id])
      } else {
        setSelectedAlarms(prevState =>
          prevState.filter(prevItem => prevItem !== row.id)
        )
      }
    },
  }

  // Hour options for select
  const hourOptions = [
    { value: 0, label: "00:00" },
    { value: 1, label: "01:00" },
    { value: 2, label: "02:00" },
    { value: 3, label: "03:00" },
    { value: 4, label: "04:00" },
    { value: 5, label: "05:00" },
    { value: 6, label: "06:00" },
    { value: 7, label: "07:00" },
    { value: 8, label: "08:00" },
    { value: 9, label: "09:00" },
    { value: 10, label: "10:00" },
    { value: 11, label: "11:00" },
    { value: 12, label: "12:00" },
    { value: 13, label: "13:00" },
    { value: 14, label: "14:00" },
    { value: 15, label: "15:00" },
    { value: 16, label: "16:00" },
    { value: 17, label: "17:00" },
    { value: 18, label: "18:00" },
    { value: 19, label: "19:00" },
    { value: 20, label: "20:00" },
    { value: 21, label: "21:00" },
    { value: 22, label: "22:00" },
    { value: 23, label: "23:00" },
    { value: 24, label: "24:00" },
  ]

  return (
    <>
      <div>
        {loading && (
          <Lottie
            options={getLottieLoadingOptions()}
            height={100}
            width={100}
          />
        )}
        {data && !loading && (
          <>
            <div className="mt-1">
              <Row xs={1}>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        <Row>
                          <Col>
                            <i className="bx bxs-info-circle text-truncate align-middle me-2"></i>
                            {props.t("Settings")}
                          </Col>

                          <Col className="col-auto">
                            {!editMode && (
                              <Button
                                color="primary"
                                size="sm"
                                className=""
                                onClick={() => {
                                  setEditMode(true)
                                }}
                              >
                                <i className="me-2 bx bxs-edit text-truncate align-middle"></i>
                                <span className="">{props.t("Edit")}</span>
                              </Button>
                            )}
                            {editMode && (
                              <>
                                <Button
                                  color="primary"
                                  size="sm"
                                  className=""
                                  onClick={() => {
                                    setEditMode(false)
                                    validation.resetForm()
                                    setSelectedAlarms(data.alarms)
                                  }}
                                >
                                  <i className="me-2 bx bxs-x-square text-truncate align-middle"></i>
                                  <span className="">{props.t("Cancel")}</span>
                                </Button>
                                <Button
                                  color="success"
                                  size="sm"
                                  disabled={updating}
                                  className="ms-3"
                                  onClick={() => {
                                    validation.submitForm()
                                  }}
                                >
                                  <i className="me-2 bx bxs-save text-truncate align-middle"></i>
                                  <span className="">
                                    {props.t("Save changes")}
                                  </span>
                                </Button>
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardTitle>

                      {!editMode && (
                        <>
                          <Alert color="info">
                            {props.t("SmsAlarmsInfoText")}
                          </Alert>
                          <Row>
                            <Col>
                              <Row>
                                <Col>
                                  <div className="text-muted mb-4">
                                    <h6>{props.t("Status")}:</h6>{" "}
                                    {data?.securityService
                                      ? props.t("Enabled")
                                      : props.t("Disabled")}
                                  </div>
                                </Col>
                              </Row>
                              <Row xs={1} sm={2}>
                                <Col>
                                  <div className="text-muted mb-4">
                                    <h6>{props.t("Key number")}:</h6>{" "}
                                    {data?.keyNumber
                                      ? data.keyNumber
                                      : props.t("Undefined")}
                                  </div>
                                </Col>
                                <Col>
                                  <div className="text-muted mb-4">
                                    <h6>{props.t("Alarm delay")}:</h6>{" "}
                                    {data?.alarmDelayMinutes
                                      ? (data.alarmDelayMinutes + "min")
                                      : props.t("Undefined")}
                                  </div>
                                </Col>
                                <Col>
                                  <div className="text-muted mb-4 d-inline-grid">
                                    <h6>{props.t("Immediate alarms") + ": " + props.t("Mon") + "-" + props.t("Fri")}</h6>
                                    <span>{data?.securityWeekdaysStart1 + ":00 - " + data?.securityWeekdaysEnd1 + ":00"}</span>
                                    <span>{data?.securityWeekdaysStart2 + ":00 - " + data?.securityWeekdaysEnd2 + ":00"}</span>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="text-muted mb-4 d-inline-grid">
                                    <h6>{props.t("Immediate alarms") + ": " + props.t("Sat") + "-" + props.t("Sun")}</h6>
                                    <span>{data?.securityWeekendStart1 + ":00 - " + data?.securityWeekendEnd1 + ":00"}</span>
                                    <span>{data?.securityWeekendStart2 + ":00 - " + data?.securityWeekendEnd2 + ":00"}</span>
                                  </div>
                                </Col>
                              </Row>

                            </Col>
                            <Col>
                              <SimpleBar style={{ maxHeight: "580px" }}>
                                <BootstrapTable
                                  keyField={"id"}
                                  columns={columnsPreview}
                                  data={currentAlarms}
                                  bordered={true}
                                  striped={true}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-start table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                />
                              </SimpleBar>
                            </Col>
                          </Row>
                        </>
                      )}

                      {editMode && (
                        <>
                          <Row>
                            <Col xs={6}>
                              <Form
                                className="form-horizontal"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <Row>
                                  <Col>
                                    <FormGroup className="d-inline-grid">
                                      <Label>{props.t("Status")}</Label>
                                      <Switch
                                        name="securityService"
                                        checkedChildren={props.t("Enabled")}
                                        unCheckedChildren={props.t("Disabled")}
                                        checked={validation.values.securityService}
                                        onChange={v => {
                                          validation.setFieldValue("securityService", v)
                                        }}
                                      ></Switch>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row xs={1} lg={2}>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Key number")}</Label>
                                      <Input
                                        bsSize={"sm"}
                                        name="keyNumber"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.keyNumber || ""}
                                      ></Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Alarm delay")}</Label>
                                      <Input
                                        bsSize={"sm"}
                                        name="alarmDelayMinutes"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values
                                            .alarmDelayMinutes || ""
                                        }
                                      ></Input>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <h6 className="text-primary border-top pt-3 mb-3 mt-2">
                                  {props.t("Immediate alarms") + ": " + props.t("Mon") + "-" + props.t("Fri")}
                                </h6>
                                <Row xs={1} lg={2}>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Start time") + " 1"}</Label>
                                      <Input
                                        bsSize="sm"
                                        name="securityWeekdaysStart1"
                                        type="select"
                                        value={validation.values.securityWeekdaysStart1}
                                        onChange={validation.handleChange}
                                      >
                                        {hourOptions.map(item => (
                                          <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("End time") + " 1"}</Label>
                                      <Input
                                        bsSize="sm"
                                        name="securityWeekdaysEnd1"
                                        type="select"
                                        value={validation.values.securityWeekdaysEnd1}
                                        onChange={validation.handleChange}
                                      >
                                        {hourOptions.map(item => (
                                          <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Start time") + " 2"}</Label>
                                      <Input
                                        bsSize="sm"
                                        name="securityWeekdaysStart2"
                                        type="select"
                                        value={validation.values.securityWeekdaysStart2}
                                        onChange={validation.handleChange}
                                      >
                                        {hourOptions.map(item => (
                                          <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("End time") + " 2"}</Label>
                                      <Input
                                        bsSize="sm"
                                        name="securityWeekdaysEnd2"
                                        type="select"
                                        value={validation.values.securityWeekdaysEnd2}
                                        onChange={validation.handleChange}
                                      >
                                        {hourOptions.map(item => (
                                          <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <h6 className="text-primary border-top pt-3 mb-3 mt-2">
                                  {props.t("Immediate alarms") + ": " + props.t("Sat") + "-" + props.t("Sun")}
                                </h6>
                                <Row xs={1} lg={2}>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Start time") + " 1"}</Label>
                                      <Input
                                        bsSize="sm"
                                        name="securityWeekendStart1"
                                        type="select"
                                        value={validation.values.securityWeekendStart1}
                                        onChange={validation.handleChange}
                                      >
                                        {hourOptions.map(item => (
                                          <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("End time") + " 1"}</Label>
                                      <Input
                                        bsSize="sm"
                                        name="securityWeekendEnd1"
                                        type="select"
                                        value={validation.values.securityWeekendEnd1}
                                        onChange={validation.handleChange}
                                      >
                                        {hourOptions.map(item => (
                                          <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Start time") + " 2"}</Label>
                                      <Input
                                        bsSize="sm"
                                        name="securityWeekendStart2"
                                        type="select"
                                        value={validation.values.securityWeekendStart2}
                                        onChange={validation.handleChange}
                                      >
                                        {hourOptions.map(item => (
                                          <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("End time") + " 2"}</Label>
                                      <Input
                                        bsSize="sm"
                                        name="securityWeekendEnd2"
                                        type="select"
                                        value={validation.values.securityWeekendEnd2}
                                        onChange={validation.handleChange}
                                      >
                                        {hourOptions.map(item => (
                                          <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>

                              </Form>
                            </Col>
                            <Col xs={6}>
                              <SimpleBar style={{ maxHeight: "980px" }}>
                                <PaginationProvider
                                  pagination={paginationFactory(pageOptions)}
                                  keyField="id"
                                  columns={columns}
                                  data={alarmList}
                                >
                                  {({ paginationTableProps }) => (
                                    <ToolkitProvider
                                      keyField="id"
                                      columns={columns}
                                      data={alarmList}
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>
                                          <Row>
                                            <Col xl="12">
                                              <div className="table-responsive table-scroll-window">
                                                <BootstrapTable
                                                  keyField={"id"}
                                                  responsive
                                                  bordered={true}
                                                  striped={true}
                                                  //pagination={sizePerPageList}
                                                  defaultSorted={defaultSorted}
                                                  selectRow={selectRow}
                                                  classes={
                                                    "table align-start table-nowrap"
                                                  }
                                                  headerWrapperClasses={
                                                    "thead-light"
                                                  }
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )}
                                    </ToolkitProvider>
                                  )}
                                </PaginationProvider>
                              </SimpleBar>
                            </Col>
                          </Row>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
    </>
  )
}

SmsAlarmsConfig.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.string,
}

export default withTranslation()(SmsAlarmsConfig)