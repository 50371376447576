import { socket } from "./index"
export const addClientToQueue = () => {
  console.log("emit from component")
  socket.emit("addClientIdToQueue")
}
export const getQueueLength = () => {
  socket.emit("queueLengthToSocket")
}
export const removeUserFromQueue = () => {
  socket.emit("removeUserFromQueue")
}
