import React, { useState, useEffect, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import PropTypes from "prop-types"

//Chart import
import ReactApexChart from "react-apexcharts"

//Translation
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const ChartStats = props => {
  const [stats, setStats] = useState({})
  const [totals, setTotals] = useState({ customers: 0, services: 0 })
  const [totalInactive, setTotalInactive] = useState(0)
  // Filtering customer names from types
  const [customerNamesAtHome, setCustomerNamesAtHome] = useState([])
  const [customerNamesOntheGo, setCustomerNamesOntheGo] = useState([])
  const [customerNamesDose, setCustomerNamesDose] = useState([])
  const [customerNamesSmartlock, setCustomerNamesSmartlock] = useState([])
  const [customerNamesCarephone, setCustomerNamesCarephone] = useState([])
  const [customerNamesHealth, setCustomerNamesHealth] = useState([])
  const [customerNamesVideo, setCustomerNamesVideo] = useState([])
  // Setting search field material
  const [findSearch, setFindSearch] = useState([])
  // Card states
  const [home, setHome] = useState(0)
  const [tracker, setTracker] = useState(0)
  const [carephone, setCarephone] = useState(0)
  const [health, setHealth] = useState(0)
  const [smartlock, setSmartlock] = useState(0)
  const [dose, setDose] = useState(0)
  const [video, setVideo] = useState(0)
  const [totals1, setTotals1] = useState(0)
  // Open "show more"
  const [editIndex, setEditIndex] = useState(null)
  // Example Show only five
  const wantedLenght = 3

  // Updating states
  useEffect(() => {
    if (props.data?.stats && props.data.stats !== stats) {
      console.log("Stats: " + JSON.stringify(props.data))

      // set chosen groups/customers from props reporting.js
      setFindSearch(props.customers)
      groupSizes(props.customers)

      //Setting stats, list
      setStats(props.data.stats)

      // Setting variables for charts
      setHome(props.data.stats.home)
      setVideo(props.data.stats.video)
      setSmartlock(props.data.stats.smartlock)
      setHealth(props.data.stats.health)
      setCarephone(props.data.stats.carephone)
      setTracker(props.data.stats.tracker)
      setDose(props.data.stats.dose)

      //Setting names by service function
      filterCustomersByService(props.data.array)

      let temp = []
      temp.push(props.data.stats.home)
      temp.push(props.data.stats.tracker)
      temp.push(props.data.stats.carephone)
      temp.push(props.data.stats.video)
      temp.push(props.data.stats.health)
      temp.push(props.data.stats.dose)
      temp.push(props.data.stats.smartlock)

      let combined = 0
      temp.forEach(val => {
        combined += val
      })

      let sumInactiveTotal = 0
      props.data.array.forEach(value => {
        sumInactiveTotal +=
          (value.carephone_inactive || 0) +
          (value.tracker_inactive || 0) +
          (value.dose_inactive || 0) +
          (value.home_inactivate || 0) +
          (value.smartlock_inactive || 0) +
          (value.video_inactive || 0) +
          (value.health_inactive || 0)
      })
      setTotalInactive(sumInactiveTotal)

      setTotals({ customers: props.data.stats.total, services: combined })
      setTotals1(combined)

      const inActive = {
        home: 0,
        tracker: 0,
        carephone: 0,
        smartlock: 0,
        health: 0,
        video: 0,
        dose: 0,
      }

      props.data.array.forEach(value => {
        inActive.home += value.home_inactivate
        inActive.tracker += value.tracker_inactive
        inActive.carephone += value.carephone_inactive
        inActive.smartlock += value.smartlock_inactive
        inActive.health += value.health_inactive
        inActive.video += value.video_inactive
        inActive.dose += value.dose_inactive
      })

      // Assuming you use setState or similar to update the state
      props.setInActiveDevices(inActive)
    }
  }, [props.data])

  const getChartOptions = index => {
    let options = {
      chart: { sparkline: { enabled: !0 } },
      dataLabels: { enabled: !1 },
      colors: ["#556ee6"],
      plotOptions: {
        radialBar: {
          hollow: { margin: 0, size: "60%" },
          track: { margin: 0 },
          dataLabels: { show: !1 },
        },
      },
    }
    switch (index) {
      case 0:
        options["colors"][0] = "#0061db" // color: loading-blue-dots
        break
      case 1:
        options["colors"][0] = "#97cd00" //color: suvanto-green
        break
      case 2:
        options["colors"][0] = "#00a0d6" //color: suvanto-blue-3-1
        break
      case 3:
        options["colors"][0] = "#f46a6a" //color: danger
        break
      case 4:
        options["colors"][0] = "#34c38f" //color: success
        break
      case 5:
        options["colors"][0] = "#ff9b00" //color: suvanto-orange
        break
      case 6:
        options["colors"][0] = "#343a40" //color: secondary
        break
      default:
        break
    }

    return options
  }

  // Delete duplicates from names array
  const deleteDuplicated = array => {
    let setNew = Array.from(new Set(array))
    return setNew
  }

  // Stat card values
  const cardValues = [
    {
      id: 0,
      value: home,
      inactive: props.inActiveDevices.home || 0,
      // inactive: 1,
      text: props.t("Home"),
      icon: "bx bx-home",
      color: "warning",
      series: toPercentage(home),
      customers: deleteDuplicated(customerNamesAtHome),
    },
    {
      id: 1,
      value: tracker,
      //inactive: props.inActiveDevices.tracker,
      inactive: props.inActiveDevices.tracker || 0,
      text: props.t("On the Go"),
      icon: "mdi mdi-cellphone-sound",
      color: "primary",
      series: toPercentage(tracker),
      customers: deleteDuplicated(customerNamesOntheGo),
    },
    {
      id: 2,
      value: carephone,
      inactive: props.inActiveDevices.carephone,
      text: props.t("Safety phone"),
      icon: "mdi mdi-phone-ring-outline",
      color: "info",
      series: toPercentage(carephone),
      customers: deleteDuplicated(customerNamesCarephone),
    },
    {
      id: 3,
      value: video,
      inactive: props.inActiveDevices.video,
      text: props.t("Video"),
      color: "danger",
      icon: "mdi mdi-cellphone-link",
      series: toPercentage(video),
      customers: deleteDuplicated(customerNamesVideo),
    },
    {
      id: 4,
      value: health,
      inactive: props.inActiveDevices.health,
      text: props.t("Measurements"),
      icon: "bx bx-stats",
      color: "success",
      series: toPercentage(health),
      customers: deleteDuplicated(customerNamesHealth),
    },
    {
      id: 5,
      value: dose,
      inactive: props.inActiveDevices.dose,
      text: props.t("Medication Reminderer"),
      icon: "mdi mdi-medical-bag",
      color: "pink",
      series: toPercentage(dose),
      customers: deleteDuplicated(customerNamesDose),
    },
    {
      id: 6,
      value: smartlock,
      inactive: props.inActiveDevices.smartlock,
      text: props.t("Smartlock"),
      icon: "mdi mdi-shield-lock-outline",
      color: "secondary",
      series: toPercentage(smartlock),
      customers: deleteDuplicated(customerNamesSmartlock),
    },
  ]

  // For radial bars
  function toPercentage(value) {
    if (value != 0) {
      let result = Math.round((value / totals1) * 100)
      return [result]
    }
    if (value == 0) {
      return [0]
    }
  }

  const [countedGroups, setCountedGroups] = useState(0)

  // Counting objects from groups
  const groupSizes = g => {
    let object = Object.keys(g).length
    setCountedGroups(object)
  }

  // Filtering customer names from types
  const filterCustomersByService = data => {
    data.filter(n => {
      // Finding home services users
      if (n.home === true) {
        setCustomerNamesAtHome(customerNamesAtHome => [
          ...customerNamesAtHome,
          n.name,
        ])
      }
      if (n.tracker === true) {
        setCustomerNamesOntheGo(customerNamesOntheGo => [
          ...customerNamesOntheGo,
          n.name,
        ])
      }
      if (n.carephone === true) {
        setCustomerNamesCarephone(customerNamesCarephone => [
          ...customerNamesCarephone,
          n.name,
        ])
      }
      if (n.dose === true) {
        setCustomerNamesDose(customerNamesDose => [
          ...customerNamesDose,
          n.name,
        ])
      }
      if (n.video === true) {
        setCustomerNamesVideo(customerNamesVideo => [
          ...customerNamesVideo,
          n.name,
        ])
      }
      if (n.health === true) {
        setCustomerNamesHealth(customerNamesHealth => [
          ...customerNamesHealth,
          n.name,
        ])
      }
      if (n.smartlock === true) {
        setCustomerNamesSmartlock(customerNamesSmartlock => [
          ...customerNamesSmartlock,
          n.name,
        ])
      }
    })
  }

  return (
    <React.Fragment>
      <div id="services-chart">
        <Row className=" mt-2 mb-2">
          <div className="d-flex flex-wrap">
            <span>
              <h5 className="mt-1">{props.t("Customers and services")}</h5>
            </span>
          </div>
        </Row>
        <Col className="mt-1" xl="12">
          <Row>
            <Col sm="3">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="mt-4 ms-2" xs="12">
                      <div className="mb-1">
                        {/* TODO: Show only Customers/Group depending whitch is chosen as filter */}
                        {findSearch.lenght == 0 ? (
                          <div></div>
                        ) : (
                          <div className="mt-2 mb-2">
                            <span className="h2">{countedGroups} </span>
                            {countedGroups > 1 ? (
                              <span className="mt-4 ms-2 mb-1 h6 text-muted">
                                {props.t("groups")}
                              </span>
                            ) : (
                              <span className="mt-4 ms-2 mb-1 h6 text-muted">
                                {props.t("group")}
                              </span>
                            )}
                            {/* TODO: Show also customers here? */}
                            {/* Chosen group names */}
                            {/* <div className="mt-2">
                              {findSearch.slice(0, wantedLenght).map(item => (
                                <span
                                  key={"findsearch_" + item.group}
                                  className=" text-muted"
                                >
                                  <span className="font-size-12 me-1 badge badge-soft-primary">
                                    {item.group}{" "}
                                  </span>
                                </span>
                              ))}{" "}
                              {countedGroups > wantedLenght ? (
                                <span className=" font-size-12 badge badge-soft-primary">
                                  {" "}
                                  +{countedGroups - wantedLenght}
                                </span>
                              ) : (
                                <span></span>
                              )}
                            </div> */}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col className="mt-4  ms-2" xs="12">
                      <div className="mt-2 mb-2">
                        <span className="h2">{totals.customers}</span>{" "}
                        {totals.customers > 1 ? (
                          <span className="mt-4  text-muted ms-2 mb-1 h6">
                            {props.t("customers")}
                          </span>
                        ) : (
                          <span className="mt-4  text-muted ms-2 mb-1 h6">
                            {props.t("customer")}
                          </span>
                        )}
                        {/* Customer names */}
                        {/* Todo: Customer names */}
                        {/* <div className="mt-2">
                          <span className="me-1 font-size-13 badge badge-soft-light text-muted ">
                            {itemss}
                          </span>
                          {objects.length > wantedLenght ? (
                            <span className="me-1 font-size-13 badge badge-soft-light text-muted ">
                              {" "}
                              + {objects.length - wantedLenght}{" "}
                              {props.t("muuta")}
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </div> */}
                      </div>
                    </Col>
                    <Col className="mt-4 ms-2" xs="12">
                      <div className="mt-2 mb-2">
                        <span className="h2">{totals.services}</span>{" "}
                        {totals.services > 1 ? (
                          <span className="mt-4  text-muted ms-2 mb-1 h6">
                            {props.t("services")}
                          </span>
                        ) : (
                          <span className="mt-4  text-muted ms-2 mb-1 h6">
                            {props.t("service")}
                          </span>
                        )}
                        {/* Service names */}
                        {/* Todo: Services names */}
                        {/* <div className="mt-2">
                          <span className="text-muted">{itemss}</span>
                          {objects.length > wantedLenght ? (
                            <span className="text-muted">
                              {" "}
                              + {objects.length - wantedLenght}{" "}
                              {props.t("muuta")}
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </div> */}
                      </div>
                    </Col>{" "}
                    <Col className="mt-2 ms-2" xs="12">
                      {totalInactive > 0 ? (
                        <div className="mt-2 mb-2">
                          <span className="h2">{totalInactive}</span>{" "}
                          <span className="mt-4  text-muted ms-2 mb-1 h6">
                            {props.t("devices not in active use")}
                          </span>
                        </div>
                      ) : (
                        <div className="mt-2 mb-2">
                          <span
                            className=" text-muted ms-2 mb-1 h6"
                            style={{
                              display: "inline-block",
                            }}
                          >
                            {props.t("All devices are in active use")}
                          </span>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Col className="mb-3"></Col>
                </CardBody>
              </Card>
            </Col>
            {/* Stats card */}
            {cardValues.map((item, index) => {
              const options2 = getChartOptions(item.id)
              return (
                <Fragment key={"" + index}>
                  {item.value != 0 ? (
                    <Col sm="3">
                      <Card>
                        <CardBody>
                          <CardTitle>{item.text}</CardTitle>
                          {/* If wanted, here goes icon */}
                          <Row>
                            <Col xs="6">
                              <div className="text-center">
                                <h2 className="mt-4">{item.value}</h2>
                              </div>
                            </Col>
                            <Col id={"col_" + item.id} xs="6">
                              <div id="wallet-balance-chart">
                                <ReactApexChart
                                  options={options2}
                                  series={item.series}
                                  type="radialBar"
                                  height={90}
                                  width={90}
                                  className="apex-charts"
                                ></ReactApexChart>
                              </div>
                            </Col>
                          </Row>
                          <Col xs="12">
                            <div className="text-muted text-truncate mt-3">
                              <span>{item.series}%</span>
                              <span> {props.t("of all services")}</span>
                            </div>

                            {item.inactive > 0 && (
                              <div className="text-muted text-truncate mt-1">
                                <span>{item.inactive}</span>
                                <span>
                                  {" "}
                                  {props.t("devices not in active use")}
                                </span>
                              </div>
                            )}
                          </Col>
                          <hr />
                          <Col xs="12">
                            <p className="">
                              {props.t("Customers with service")}:
                            </p>
                            {editIndex !== index && (
                              <div className="text-muted mt-1">
                                {/* Show only 3 first items */}
                                {item.customers
                                  .slice(0, wantedLenght)
                                  .map(names => (
                                    <div
                                      key={"names" + names}
                                      className="me-2 "
                                    >
                                      <Row>
                                        <span className="col-12">
                                          <i className="dripicons-dot text-primary ms-0 me-1"></i>
                                          {names}
                                        </span>
                                      </Row>
                                    </div>
                                  ))}
                                {item.customers.length <= wantedLenght ? (
                                  <div></div>
                                ) : (
                                  <Link
                                    to="#"
                                    className="d-grid text-primary mt-2 text-center"
                                    onClick={() =>
                                      setEditIndex(editIndex =>
                                        editIndex === index ? null : index
                                      )
                                    }
                                  >
                                    {props.t("Show all")}
                                  </Link>
                                )}
                              </div>
                            )}
                            {editIndex === index && (
                              <div className="text-muted mt-3">
                                {/* Show all items */}
                                {item.customers.map(names => (
                                  <div key={"names" + names} className="me-2 ">
                                    <Row>
                                      <span className="col-12">
                                        <i className="dripicons-dot text-primary ms-0 me-1"></i>
                                        {names}
                                      </span>
                                      {/* TODO Here comes number of customers services */}
                                      {/* <span className="col-4 text-sm-end d-none d-sm-block">
                                            %
                                          </span> */}
                                    </Row>
                                  </div>
                                ))}
                                <Link
                                  to="#"
                                  className="d-grid text-primary mt-2 text-center"
                                  onClick={() =>
                                    setEditIndex(editIndex =>
                                      editIndex === index ? null : index
                                    )
                                  }
                                >
                                  {props.t("Hide")}
                                </Link>
                              </div>
                            )}
                          </Col>
                        </CardBody>
                      </Card>
                    </Col>
                  ) : null}
                </Fragment>
              )
            })}
          </Row>
        </Col>
      </div>
    </React.Fragment>
  )
}

ChartStats.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  customers: PropTypes.any,
  inActiveDevices: PropTypes.any,
  setInActiveDevices: PropTypes.func,
}

export default withTranslation()(ChartStats)
