import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Collapse,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useLocation, useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { getVideosWithSettings } from "helpers/backend_helper"
import {
  showToast,
  downloadExcel,
  isTrue,
  stringSorter,
  numberSorter,
  defaultPageSizeOptions,
  searchToURL,
} from "utils/general"
import { Table as AntdTable } from "antd"
import VideoAppSettingsModal from "./settings-modal"
import GlobalState from "contexts/GlobalState"
import { tableDefaultHeight } from "constants/layout"

const VideoAppSettings = props => {
  const [state] = useContext(GlobalState)
  const [isSystemAdmin, setIsSystemAdmin] = useState(false)
  const [activeTab] = useState("1")
  const [clients, setClients] = useState([])
  const [filteredClients, setFilteredClients] = useState([])
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [menu, setMenu] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [title] = useState(props.t("Video app settings"))

  const search = useLocation().search
  const navigate = useNavigate()
  const location = useLocation()

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    const searchParam = new URLSearchParams(search).get("search")
    if (searchParam && searchParam != "null") setSearchText(searchParam.trim())
    abortRef.current = new AbortController()

    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      if (role === "system_admin") {
        setIsSystemAdmin(true)
      }
    }

    getDevices()
    return () => abortRef.current.abort()
  }, [])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, clients])

  // Handle search function
  const handleSearch = () => {
    let searchTextLowercase = searchText.toLowerCase()
    let result = clients.filter(data =>
      data.name?.toLowerCase().includes(searchTextLowercase)
    )
    setFilteredClients(result)
  }

  // Get clients
  const getDevices = () => {
    setOngoingSearch(true)
    getVideosWithSettings(abortRef.current)
      .then(result => {
        //console.log("Video app settings: ", result.clients)
        if (result.statusCode === 200 && result.clients != null) {
          setClients(result.clients)
        } else {
          setClients([])
        }
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setClients([])
        setOngoingSearch(false)
      })
  }

  // Table structure
  const columns = [
    {
      dataIndex: "room_online",
      title: props.t(""),
      sorter: (a, b) => {
        return a.room_online - b.room_online
      },
      render: cell => {
        return cell ? (
          <i className="mdi mdi-cloud-check text-success" />
        ) : (
          <i className="mdi mdi-cloud-off-outline text-danger" />
        )
      },
      align: "center",
      width: "40px",
      ellipsis: true,
    },
    {
      dataIndex: "name",
      title: props.t("Name"),
      defaultSortOrder: "ascend",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "name", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "sync_time",
      title: props.t("Synced"),
      sorter: (a, b, sortOrder) => {
        //return dateSorter(a["sync_time"], b["sync_time"], sortOrder)
        return stringSorter(a, b, "sync_time", sortOrder)
      },
      //render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "language",
      title: props.t("Language"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "language", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "brightness",
      title: props.t("Brightness"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "brightness", sortOrder)
        //return numberSorter(a["brightness"], b["brightness"], sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "volume",
      title: props.t("Volume"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["volume"], b["volume"], sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "automatic_answering",
      title: props.t("Auto answer"),
      align: "center",
      sorter: (a, b) => {
        return a.automatic_answering - b.automatic_answering
      },
      render: (cell, row) => {
        return cell ? (
          //<i className="mdi mdi-check-bold text-primary" />
          <span>{row.automatic_answer_time}s</span>
        ) : (
          <i className="mdi mdi-close-thick text-danger" />
        )
      },
      width: "160px",
      ellipsis: true,
    },
    /** 
    {
      dataIndex: "automatic_answer_time",
      title: props.t("Answer time"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["automatic_answer_time"], b["automatic_answer_time"], sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },*/
    /** 
    {
      dataIndex: "screensaver_clock",
      title: props.t("Screen saver clock"),
      align: "center",
      sorter: (a, b) => {
        return a.screensaver_clock - b.screensaver_clock
      },
      render: cell => {
        return cell == true ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <i className="mdi mdi-close-thick text-danger" />
        )
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "analog_clock",
      title: props.t("Analog clock"),
      align: "center",
      sorter: (a, b) => {
        return a.analog_clock - b.analog_clock
      },
      render: cell => {
        return cell == true ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <i className="mdi mdi-close-thick text-danger" />
        )
      },
      width: "160px",
      ellipsis: true,
    },*/

    {
      dataIndex: "screensaver_clock",
      title: props.t("Screensaver"),
      align: "center",
      render: (cell, row) => {
        if (!cell) return <span>{props.t("Black screen")}</span>
        if (row.analog_clock) return <span>{props.t("Analog clock")}</span>
        else return <span>{props.t("Digital clock")}</span>
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "screensaver_delay",
      title: props.t("Screensaver delay"),
      align: "center",
      render: (cell, row) => {
        return cell
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "screensaver_daytime",
      title: props.t("Sovelluksen päivätila"),
      align: "center",
      /*sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "daily_restart_time", sortOrder)
      },*/
      /*render: (cell, row) => {
        return <span></span>
      },*/
      width: "180px",
      ellipsis: true,
    },
    {
      dataIndex: "daily_restart_time",
      title: props.t("Restart time"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "daily_restart_time", sortOrder)
      },
      width: "170px",
      ellipsis: true,
    },
    {
      dataIndex: "show_pip",
      title: props.t("PiP"),
      align: "center",
      sorter: (a, b) => {
        return a.show_pip - b.show_pip
      },
      render: cell => {
        return cell ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <i className="mdi mdi-close-thick text-danger" />
        )
      },
      width: "120px",
      ellipsis: true,
    },
    ...(isSystemAdmin
      ? [
          {
            dataIndex: "health_button",
            title: "Health",
            align: "center",
            sorter: (a, b) => {
              return a.health_button - b.health_button
            },
            render: cell => {
              return cell ? (
                <i className="mdi mdi-check-bold text-primary" />
              ) : (
                <i className="mdi mdi-close-thick text-danger" />
              )
            },
            width: "120px",
            ellipsis: true,
          },
          {
            dataIndex: "show_debug",
            title: props.t("Debug"),
            align: "center",
            sorter: (a, b) => {
              return a.show_debug - b.show_debug
            },
            render: cell => {
              return cell ? (
                <i className="mdi mdi-check-bold text-primary" />
              ) : (
                <i className="mdi mdi-close-thick text-danger" />
              )
            },
            width: "120px",
            ellipsis: true,
          },
          {
            dataIndex: "assisted_restart",
            title: props.t("Assisted restart"),
            align: "center",
            sorter: (a, b) => {
              return a.assisted_restart - b.assisted_restart
            },
            render: cell => {
              return cell ? (
                <i className="mdi mdi-check-bold text-primary" />
              ) : (
                <i className="mdi mdi-close-thick text-danger" />
              )
            },
            width: "120px",
            ellipsis: true,
          },
          {
            dataIndex: "uses_production",
            title: props.t("Production"),
            align: "center",
            sorter: (a, b) => {
              return a.uses_production - b.uses_production
            },
            render: cell => {
              return cell ? (
                <i className="mdi mdi-check-bold text-primary" />
              ) : (
                <i className="mdi mdi-close-thick text-danger" />
              )
            },
            width: "120px",
            ellipsis: true,
          },
        ]
      : []),
  ]

  // Export values
  const generateExcel = () => {
    const heading = [[props.t("Name"), props.t("Auto answer")]]
    const data = filteredClients.map(elt => [
      elt.name,
      isTrue(elt.automatic_answering),
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  // Table selection changed
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setSelectedRows(newSelectedRows)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Video app settings")} />
          <Card>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane className="show" tabId="1">
                  <Row>
                    <Col>
                      {clients != null && (
                        <React.Fragment>
                          <Row className="mb-1">
                            {" "}
                            <Col className="col-auto pe-0">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <div className="search-box me-2 mb-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      value={searchText}
                                      onBlur={() =>
                                        // Apply search word to URL
                                        searchToURL(
                                          searchText,
                                          location,
                                          navigate
                                        )
                                      }
                                      onChange={event =>
                                        setSearchText(event.target.value)
                                      }
                                      placeholder={props.t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                //className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="primary"
                                disabled={ongoingSearch}
                                onClick={() => {
                                  getDevices()
                                }}
                              >
                                <i className="mdi mdi-refresh me-2" />
                                {props.t("Refresh data")}
                              </Button>
                            </Col>
                            <Col>
                              <span className="float-end ms-2">
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="downloadstatstable"
                                >
                                  {props.t("Download statistics")}
                                </UncontrolledTooltip>
                                <Dropdown
                                  id="printButtonServicesStats"
                                  isOpen={menu}
                                  toggle={() => setMenu(!menu)}
                                  className="download-btn text-center"
                                  type="button"
                                >
                                  <DropdownToggle
                                    id="downloadstatstable"
                                    className="text-muted"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-download" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => generateExcel()}
                                    >
                                      {props.t("Download .xlsx")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </span>
                              <div className="mt-2 float-end">
                                <p>
                                  {filteredClients.length}{" "}
                                  {props.t("found results")}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Collapse isOpen={selectedRowKeys.length > 0}>
                            <span id="selections-text">
                              {selectedRowKeys.length +
                                " " +
                                props.t("selected clients")}
                            </span>
                            <Row className="py-2">
                              <Col>
                                <Button
                                  color="primary"
                                  //disabled={selectedRowKeys.length == 0 || ongoingSearch}
                                  onClick={() => {
                                    setShowModal(!showModal)
                                  }}
                                >
                                  <i className="mdi mdi-cog me-2" />
                                  {props.t("Change settings")}
                                </Button>
                              </Col>
                            </Row>
                          </Collapse>

                          <Row className="mt-2">
                            <Col xl="12">
                              {ongoingSearch ? (
                                <div className="py-5 text-center">
                                  <p className="text-primary">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                    {props.t("Loading")}
                                  </p>
                                </div>
                              ) : (
                                <AntdTable
                                  className="stripedd colored-header narrow-header"
                                  bordered
                                  size="small"
                                  sticky
                                  rowKey={"key"}
                                  columns={columns}
                                  dataSource={filteredClients}
                                  rowSelection={{
                                    selectedRowKeys,
                                    onChange: onSelectChange,
                                  }}
                                  pagination={{
                                    showSizeChanger: true,
                                    defaultPageSize: 50,
                                    pageSizeOptions: defaultPageSizeOptions,
                                  }}
                                  tableLayout="auto"
                                  scroll={{
                                    x: "max-content",
                                    y: tableDefaultHeight,
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>

      <VideoAppSettingsModal
        keys={selectedRowKeys}
        clients={selectedRows}
        showModal={showModal}
        setShowModal={setShowModal}
        isSystemAdmin={isSystemAdmin}
      ></VideoAppSettingsModal>
    </>
  )
}

VideoAppSettings.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(VideoAppSettings)
