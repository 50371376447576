import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Drawer } from "antd"
import {
  deleteMeasuringDevice,
  updateMeasuringDevice,
} from "helpers/backend_helper"
import * as Yup from "yup"
import { useFormik } from "formik"
import SelectOrder from "components/DropdownList/SelectOrder"
import { showToast } from "utils/general"
import withRouter from "components/Common/withRouter"

const MeasureDeviceDrawer = props => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [update, setUpdate] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Update client validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderNumber: props.selectedRow?.orderNumber || "",
      serial: props.selectedRow?.serial || "",
      mac: props.selectedRow?.mac || "",
      batch: props.selectedRow?.batch || "",
      imei: props.selectedRow?.imei || "",
      model: props.selectedRow?.model || "",
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit values: ", values)
      setSuccess("")
      setError("")
      setUpdate(true)

      values.orderNumber = values.orderNumber ? values.orderNumber : ""

      updateMeasuringDevice(abortRef.current, props.selectedRow?.key, values)
        .then(result => {
          setUpdate(false)
          if (result.statusCode == 200) {
            setSuccess("Updated")
            props.getDevices()
          } else {
            setError("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setUpdate(false)
          setError("Error")
        })
    },
  })

  // Delete device
  const deleteDevice = () => {
    if (props.selectedRow && props.selectedRow.key.length > 0) {
      setOngoingDelete(true)
      deleteMeasuringDevice(abortRef.current, props.selectedRow.key)
        .then(result => {
          //console.log("care: ", result.entries)
          if (result.statusCode === 200) {
            showToast(props.t("Deleted"), "success")
            setDeleteModal(false)
            props.getDevices()
            props.setShowDrawer(false)
          } else {
            showToast(props.t("Error"), "error")
          }
          setOngoingDelete(false)
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
          setOngoingDelete(false)
        })
    }
  }

  // Selected order changed
  const orderSelectionChanged = value => {
    validation.setFieldValue("orderNumber", value)
  }

  return (
    <>
      <Drawer
        title={props.t("Measuring device")}
        width={600}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
          setError("")
          setSuccess("")
        }}
        open={props.showDrawer}
        zIndex={1003}
        //bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.t("Settings")}</CardTitle>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <Row xs={2}>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Model")}</Label>
                    <Input
                      bsSize="sm"
                      name="model"
                      type="select"
                      value={validation.values.model}
                      onChange={validation.handleChange}
                    >
                      <option value="-">{props.t("Undefined")}</option>
                      <option value="Beurer BM77">{"Beurer BM77"}</option>
                      <option value="Beurer BM57">{"Beurer BM57"}</option>
                      <option value="CareSens Dual">{"CareSens Dual"}</option>
                      <option value="CareSens Premier N">
                        {"CareSens Premier N"}
                      </option>
                      <option value="Contour Next One">
                        {"Contour Next One"}
                      </option>
                      <option value="Xiaomi Mi Smart Scale 2">
                        {"Xiaomi Mi Smart Scale 2"}
                      </option>
                      <option value="Medisana BS444">{"Medisana BS444"}</option>
                      <option value="Smart One">{"Smart One"}</option>
                      <option value="Jumper Thermometer JPD-FR302">
                        {"Jumper Thermometer JPD-FR302"}
                      </option>
                      <option value="Jumper Oximeter JPD-500F">
                        {"Jumper Oximeter JPD-500F"}
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Serial")}</Label>
                    <Input
                      bsSize="sm"
                      name="serial"
                      type="text"
                      value={validation.values.serial}
                      onChange={validation.handleChange}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{props.t("MAC")}</Label>
                    <Input
                      bsSize="sm"
                      name="mac"
                      type="text"
                      value={validation.values.mac}
                      onChange={validation.handleChange}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{props.t("IMEI")}</Label>
                    <Input
                      bsSize="sm"
                      name="imei"
                      type="text"
                      value={validation.values.imei}
                      onChange={validation.handleChange}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Batch")}</Label>
                    <Input
                      bsSize="sm"
                      name="batch"
                      type="text"
                      value={validation.values.batch}
                      onChange={validation.handleChange}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Order number")}</Label>
                    <SelectOrder
                      selection={validation.values.orderNumber}
                      setSelectedOrder={orderSelectionChanged}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mt-2 mb-3 d-flex justify-content-center">
                <Button
                  color="primary"
                  disabled={update}
                  style={{ minWidth: "150px" }}
                  onClick={() => {
                    validation.submitForm()
                  }}
                >
                  {props.t("Save")}
                </Button>
              </div>
              {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
              {success ? (
                <Alert color="success">{props.t(success)}</Alert>
              ) : null}
            </Form>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.t("Actions")}</CardTitle>
            <ListGroup className="list-group-flush">
              <ListGroupItem className="pb-4">
                <Row>
                  <Col>
                    <div>
                      <h6>{props.t("Deletion")}</h6>
                      <span className="text-muted">
                        {props.t(
                          "The device and all related data will be deleted permanently"
                        )}
                      </span>
                    </div>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      color="danger"
                      style={{ minWidth: "100px" }}
                      onClick={() => {
                        setDeleteModal(true)
                      }}
                    >
                      {props.t("Delete")}
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Drawer>

      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody>
          <p>
            {props.t("Are you sure you want to delete") +
              " " +
              props.selectedRow?.model +
              "?"}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={ongoingDelete}
            color="danger"
            onClick={() => {
              deleteDevice()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

MeasureDeviceDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getDevices: PropTypes.func,
}

export default withRouter(withTranslation()(MeasureDeviceDrawer))
