/**********************
 * DEMO
 * Call appoinments
 *********************/
import PropTypes from "prop-types"
import React, { useContext, useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { TreeSelect } from "antd"
import Breadcrumbs from "components/Common/Breadcrumb"
const { SHOW_ALL } = TreeSelect
import GlobalState from "contexts/GlobalState"
import PubSub from "pubsub-js"
import Calendar from "components/Calendar"
// import { Card  } from "@material-ui/core"
import classnames from "classnames"
// import Timeline from "components/Timeline"
import Timeline2 from "components/Timeline"
import App from "components/Calendar/test-calender"
import CalendarTest from "components/Calendar/test-calender"
import Manual from "components/Manual/Manual"

// TEST
// import { Calendar } from "@fullcalendar/core"
// import timeGridPlugin from "@fullcalendar/timegrid"

const CallAppointments = props => {
  const [title] = useState(props.t("Appointments"))
  const [state] = useContext(GlobalState)
  const [userRole, setUserRole] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [activeTab, setActiveTab] = useState("1")

  //meta title
  document.title = title + " | Suvanto Care"

  const abortRef = useRef(null)

  useEffect(() => {
    abortRef.current = new AbortController()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getData()
    })
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
      if (role === "system_admin") {
        setIsAdmin(true)
        setActiveTab("0")
      }
      if (role === "organisation_admin") {
        setActiveTab("0")
      }
    }
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  const toggleCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={title}
            breadcrumbItem={
              <>
                <div className="d-inline-block">
                  <Manual source={"appointments"}></Manual>
                </div>
              </>
            }
          />

          {/*  <Card
            outline
            color="primary"
            className="appointments-container border"
          >
            <CardHeader className="bg-transparent">
              <h5 className="my-0 text-primary">
                <i className="mdi mdi-block-helper me-3" />
                Muokattu
              </h5>
            </CardHeader>{" "} */}
          <Card className="appointments-container">
            <CardBody>
              {/* <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul
                  className="nav nav-tabs nav-tabs-custom card-header-tabs"
                > */}
              {/* <Nav tabs className="nav-tabs-custom nav-justified"> */}
              {/* NOT IN USE YET */}
              {/* {userRole?.includes("admin") && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "0",
                        })}
                        onClick={() => {
                          toggleCustom("0")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-clock-time-eight-outline"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          {props.t("Assigning appointments")}
                        </span>
                      </NavLink>
                    </NavItem>
                  )} */}
              {/* <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-list"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        {props.t("Timeline")}
                      </span>
                    </NavLink>
                  </NavItem> */}
              {/* 
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-list-alt"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        {props.t("Calendar")}
                      </span>
                    </NavLink>
                  </NavItem> */}
              {/*      <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        toggleCustom("3")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-calendar-alt"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        {/* TODO: Translation 
                        {props.t("Statistics")}
                      </span>
                    </NavLink>
                  </NavItem> */}
              {/* </ul>
              </div>
            </CardHeader> */}
              {/* <CardBody> */}
              {/* NOT IN USE, 
              in case the appointment functions expand 
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="0">
                  <Row> */}
              <Col sm="12">
                <Timeline2 />
              </Col>
              {/* </Row>
                </TabPane>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12"></Col>
                  </Row>
                </TabPane>

                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12"></Col>
                  </Row>
                </TabPane>
              </TabContent> */}
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

CallAppointments.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CallAppointments)
