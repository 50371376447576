import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"

// users
import GlobalState from "../../../contexts/GlobalState"
import withRouter from "components/Common/withRouter"
import { getTranslatedRole } from "utils/general"
import UserRoleDropdown from "./UserRoleDropdown"
import classnames from "classnames"

const ProfileMenu = props => {
  const [state] = useContext(GlobalState)

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [username, setUsername] = useState("Admin")
  const [role, setRole] = useState("")
  const [nameIcon, setNameIcon] = useState()
  const [roles, setRoles] = useState(false)

  useEffect(() => {
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      setUsername(obj.username)

      let role = obj.activeRole ? obj.activeRole : obj.role
      setRole("UserRoles." + getTranslatedRole(role))

      if (obj.firstName !== undefined && obj.lastName !== undefined) {
        let iconName = ""
        iconName = obj.firstName.charAt(0) + obj.lastName.charAt(0)
        setNameIcon(iconName.toUpperCase())
      } else {
        let iconName = ""
        iconName = obj.username.charAt(0)
        setNameIcon(iconName.toUpperCase())
      }
    }
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          /> */}
          <div className="avatar-xs mx-auto d-inline-block header-profile-icon">
            <span
              className={
                "avatar-title rounded-circle bg-light-bg text-darker-primary font-size-16"
              }
            >
              {nameIcon}
            </span>
          </div>

          {/** 
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>*/}

          {/** Testing showing user role below name*/}
          <div
            className="d-none d-xl-inline-block"
            style={{
              textAlign: "left",
              verticalAlign: "middle",
              minHeight: "40px",
            }}
          >
            <div className="d-grid ms-2 me-1">
              <span className="">{username}</span>
              <small className="">{props.t(role)}</small>
            </div>
          </div>

          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-2" />
            <span>{props.t("My profile")}</span>
          </Link>
          <div
            id="user-role-dropdown"
            className={classnames({ active: roles === true })}
          >
            <details className="dropdown-item remove-arrow" open={roles}>
              <summary
                className="dropdown-summary"
                onClick={e => {
                  e.preventDefault()
                  setRoles(!roles)
                }}
              >
                <i className="fab fa-dev text-muted font-size-16 align-middle me-2" />
                <span>{props.t("User Role")}</span>
                <div className="float-end">
                  <i
                    className={`bx ${
                      roles ? "bx-chevron-up" : "bx-chevron-down"
                    }`}
                  ></i>
                </div>
              </summary>
              <div className="roles pt-2">
                <UserRoleDropdown settingRole={setRole}></UserRoleDropdown>
              </div>
            </details>
          </div>
          {/* <div className="nav-item dropdown">
            <Link
              className="dropdown-item"
              onClick={e => {
                e.preventDefault()
                setRoles(!roles)
              }}
              to="/#"
            >
              {props.t("User role")}
              <div className="arrow-down"></div>
            </Link>
            <div
              className={classname("dropdown-menu", {
                show: roles,
              })}
            >
              <Link to="/admin/user-management" className="dropdown-item">
                {props.t("User management")}
              </Link>
            </div>
          </div> */}
          <div className="dropdown-divider mt-0 pt-0" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ProfileMenu))
