import React, { useEffect, useRef, useState } from "react"
import {
  Form,
  Input,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import QRCode from "react-qr-code"
import { getVideoRoomLoginJson } from "helpers/backend_helper"
import Lottie from "react-lottie"
import withRouter from "components/Common/withRouter"
import { getLottieLoadingOptions, showToast } from "utils/general"

const VideoPasswordModal = props => {
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(true)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    setPassword("")
    if (props.roomName != null && props.roomName.length > 0) {
      setLoading(true)
      getVideoRoomLoginJson(abortRef.current, props.roomName)
        .then(result => {
          if (result.statusCode == 200 && result.data) {
            setPassword(JSON.stringify(result.data))
          }
          else {
            showToast(props.t("Error", "error"))
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error", "error"))
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [props.roomName])

  return (
    <>
      <Modal
        isOpen={props.passwordModal}
        toggle={() => {
          props.setPasswordModal(!props.passwordModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Login credentials")}</ModalHeader>
        <ModalBody>
          {loading && (
            <Lottie
              options={getLottieLoadingOptions()}
              height={100}
              width={100}
            />
          )}
          {!loading && (
            <Form className="form-horizontal">
              <FormGroup>
                <Input
                  name="password"
                  type="text"
                  className="text-center"
                  disabled={true}
                  defaultValue={password}
                ></Input>
              </FormGroup>
              <div
                className="mt-4"
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 300,
                  width: "100%",
                }}
              >
                <QRCode
                  size={330}
                  style={{ height: "auto", maxWidth: "100%", width: "100%", backgroundColor: "white", padding: "30px" }}
                  value={password}
                  viewBox={`0 0 330 330`}
                />
              </div>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              props.setPasswordModal(false)
            }}
          >
            {props.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

VideoPasswordModal.propTypes = {
  t: PropTypes.any,
  passwordModal: PropTypes.bool,
  setPasswordModal: PropTypes.any,
  roomName: PropTypes.string,
}

export default withRouter(withTranslation()(VideoPasswordModal))
