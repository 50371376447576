import React, { useState, useEffect } from "react"
import moment from "moment"
import loadingAnim from "common/animations/loading-dots-blue"
import findAnim from "common/animations/finddata"
import nodataAnim from "common/animations/nodata"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fin from "antd/es/locale/fi_FI"
import swe from "antd/es/locale/sv_SE"
import en from "antd/es/locale/en_GB"

// Excel export
import XLSX from "xlsx"
// Autotable PDF
import jsPDF from "jspdf"
import "jspdf-autotable"

// Default Antd table page size options
export const defaultPageSizeOptions = [10, 30, 50, 100, 200, 500]

/**
 * Check if given string is a valid UUID
 * @param {*} str
 * @returns boolean
 */
export function checkIfValidUUID(str) {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return regexExp.test(str)
}

// Get random number
export function randomNumber() {
  const crypto = window.crypto || window.msCrypto
  let array = new Uint32Array(1)
  crypto.getRandomValues(array)
  return array[0]
}

function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0")
}

// Generate password
export function generatePassword(len) {
  const crypto = window.crypto || window.msCrypto
  let array = new Uint8Array((len || 40) / 2)
  crypto.getRandomValues(array)
  return Array.from(array, dec2hex).join("")
}

/**
 * @param {String} dateString
 * @returns Formatted date as "DD.MM.YYYY HH:mm:ss"
 */
export function formatDateFull(date) {
  return moment(date).format("D.M.YYYY HH:mm:ss")
}
export function formatDateHoursMinutes(date) {
  return moment(date).format("D.M.YYYY HH:mm")
}
export function formatDateStringFull(dateString) {
  return moment(dateString).format("DD.MM.YYYY HH:mm:ss")
}
export function formatDate(date) {
  return moment(date).format("DD.MM.YYYY")
}
export function formatDateSimple(date) {
  return moment(date).format("D.M.YYYY")
}
export function formatDateWithWeekDay(date) {
  return moment(date).format("dddd")
}
export function dateFormatter(value) {
  if (!value) return ""
  return moment(value).format("DD.MM.YYYY HH:mm:ss")
}
export function formatDateTime(date) {
  return moment(date).format("HH:mm")
}

// Time formatter
export function timeFormatter(value) {
  let hours = Math.floor(value / 3600) // get hours
  let minutes = Math.floor((value - hours * 3600) / 60) // get minutes
  let seconds = value - hours * 3600 - minutes * 60 //  get seconds
  if (hours < 10) {
    hours = "0" + hours
  }
  if (minutes < 10) {
    minutes = "0" + minutes
  }
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  return value ? hours + ":" + minutes + ":" + seconds : ""
}

// Sort strings
export function stringSorter(a, b, param, sortOrder = "ascend") {
  if (a[param] && b[param]) {
    return a[param].localeCompare(b[param])
  } else if (a[param]) {
    return sortOrder === "ascend" ? -1 : 1
  } else if (b[param]) {
    return sortOrder === "ascend" ? 1 : -1
  }
  return 0
}

// Sort numbers - nulls always last
export function numberSorter(a, b, sortOrder = "ascend") {
  if (a != null && b != null) {
    return a - b
  } else if (a != null) {
    return sortOrder === "ascend" ? -1 : 1
  } else if (b != null) {
    return sortOrder === "ascend" ? 1 : -1
  }
  return 0
}

// Sort dates - nulls and zeros always last
export function dateSorter(a, b, sortOrder = "ascend") {
  if (a && b) {
    return a - b
  } else if (a) {
    return sortOrder === "ascend" ? -1 : 1
  } else if (b) {
    return sortOrder === "ascend" ? 1 : -1
  }
  return 0
}

// Sort dates - nulls and zeros always last
export function dateSorterDateObj(a, b, sortOrder = "ascend") {
  if (a && b) {
    return new Date(a) - new Date(b)
  } else if (a) {
    return sortOrder === "ascend" ? -1 : 1
  } else if (b) {
    return sortOrder === "ascend" ? 1 : -1
  }
  return 0
}

// Sort booleans - nulls always last
export function booleanSorter(a, b, param, sortOrder = "ascend") {
  if (a[param] != null && b[param] != null) {
    return b[param] - a[param]
  } else if (a[param] != null) {
    return sortOrder === "ascend" ? -1 : 1
  } else if (b[param] != null) {
    return sortOrder === "ascend" ? 1 : -1
  }
  return 0
}

/**
 * @returns Options for Lottie
 */
export function getLottieLoadingOptions() {
  return {
    loop: true,
    autoplay: true,
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
}

export function getLottieSearch() {
  return {
    loop: false,
    autoplay: true,
    animationData: findAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
}

export function getLottieNothingFound() {
  return {
    loop: false,
    autoplay: true,
    animationData: nodataAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
}

// Show toast
export function showToast(
  message,
  type = "info",
  timeOut = 8000,
  position = "toast-top-center"
) {
  toastr.options = {
    positionClass: position,
    timeOut: timeOut,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000,
  }
  switch (type) {
    case "success":
      toastr.success(message)
      break
    case "error":
      toastr.error(message)
      break
    case "warning":
      toastr.warning(message)
      break
    default:
      toastr.info(message)
      break
  }
}

// Get locale for antd datepicker etc.
export function getLocale() {
  let language = localStorage.getItem("i18nextLng")
  if (language.indexOf("-") > -1) language = language.split("-")[0]
  switch (language) {
    case "en":
      return en
    case "fi":
      return fin
    case "sv":
      return swe
    default:
      return null
  }
}

// Get locale text: en / fi / sv
export function getLocaleText() {
  let language = localStorage.getItem("i18nextLng")
  if (language.indexOf("-") > -1) language = language.split("-")[0]
  switch (language) {
    case "en":
      return "en"
    case "fi":
      return "fi"
    case "sv":
      return "sv"
    default:
      return undefined
  }
}

// Formatting bool for export files
export function isTrue(value) {
  if (value === true) {
    return "X"
  } else {
    return ""
  }
}

// Download excel
export function downloadExcel(title, heading, data) {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(data, {
    origin: "A2",
    skipHeader: true,
  })
  XLSX.utils.sheet_add_aoa(ws, heading, { origin: "A1" })
  XLSX.utils.book_append_sheet(wb, ws, title)
  XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
  XLSX.writeFile(wb, title + " (" + moment().format("LLL") + ")" + ".xlsx")
}

// Export PDF table
export function exportPDF(title, heading, data) {
  const unit = "pt"
  const size = "A4" // Use A1, A2, A3 or A4
  const orientation = "landscape" // portrait or landscape
  const theme = "grid" //striped,  plain

  const marginLeft = 40
  const doc = new jsPDF(orientation, unit, size, theme)

  doc.setFontSize(14)

  let content = {
    startY: 50,
    head: [heading],
    body: data,
  }

  doc.text(title, marginLeft, 40)
  doc.autoTable(content)
  doc.save(title + " (" + new Date() + ")" + ".pdf")
}

// Delete duplicates from array
export function deleteDuplicated(array) {
  let newArray = array.filter(function (elem, pos) {
    return array.indexOf(elem) == pos
  })
  //console.log("new set:" + newArray)
  return newArray
}

// Delete duplicates from array of objects
export function deleteDuplicatedObject(arr) {
  let newArr = arr.filter(
    (v, i, a) => a.findIndex(v2 => v2.value === v.value) === i
  )
  return newArr
}

// Get translatable role name
export function getTranslatedRole(role) {
  switch (role) {
    case "system_admin":
      return "System Admin"
    case "organisation_admin":
      return "Organisation Admin"
    case "organisation_user":
      return "Organisation User"
    case "private_user":
      return "Private User"
    case "salesperson":
      return "Salesperson"
    case "order_manager":
      return "Order Manager"
    default:
      return ""
  }
}

// Set dev environment according to page URL
export const useDevEnvironment = () => {
  const [devEnvironment, setDevEnvironment] = useState({
    isTestEnvironment: false,
    isBetaEnvironment: false,
    isLocalhostEnvironment: false,
  })

  useEffect(() => {
    const url = window.location.href

    setDevEnvironment({
      isTestEnvironment: url.includes("test.suvanto.care"),
      isBetaEnvironment: url.includes("beta.suvanto.care"),
      isLocalhostEnvironment: url.includes("localhost"),
    })
  }, [])

  return devEnvironment
}

// Battery Status
export function getBatteryStatus(batteryLevel, t) {
  if (batteryLevel < 1) {
    return (
      <div className="font-size-12 text-danger text-nowrap">
        <i className="mdi mdi-alert font-size-14 me-2"></i>
        {t("Battery empty")}
      </div>
    )
  } else if (batteryLevel < 6) {
    return (
      <div className="font-size-12 text-danger text-nowrap">
        <i className="mdi mdi-alert font-size-14 me-2"></i>
        {t("Battery critical")}
      </div>
    )
  } else if (batteryLevel < 30) {
    return (
      <div className="font-size-12 text-danger text-nowrap">
        <i className="mdi mdi-alert font-size-14 me-2"></i>
        {t("Battery low")}
      </div>
    )
  } else {
    return (
      <div className="font-size-12 text-success text-nowrap">
        <i className="mdi mdi-checkbox-marked-circle font-size-14 me-2"></i>
        {t("Battery good")}
      </div>
    )
  }
}

// Sort data by group first, then by name
export const sortData = data => {
  return data.sort((a, b) => {
    if (a.group.toLowerCase() < b.group.toLowerCase()) return -1
    if (a.group.toLowerCase() > b.group.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    return 0
  })
}

// Get operator value based on iccid
export const getOperatorByIccid = iccid => {
  const iccidPrefix = iccid ? iccid.slice(0, 8) : ""
  let operator = ""

  if (iccidPrefix === "89450421") {
    operator = "Telia"
  } else if (iccidPrefix === "89358062") {
    operator = "DNA"
  } else if (iccidPrefix === "89358020") {
    operator = "Elisa"
  }

  return operator
}

// Format operator strings
export const operatorFormatter = operatorText => {
  let badgeClass = ""

  switch (operatorText) {
    case "Elisa":
      badgeClass = "bg-success"
      break
    case "Telia":
      badgeClass = "bg-tertiary"
      break
    case "DNA":
      badgeClass = "bg-primary"
      break
    default:
      badgeClass = "bg-danger"
  }

  return (
    <span className={`badge ${badgeClass} font-size-12`}>{operatorText}</span>
  )
}

// Update url by search input to ?search=
export const searchToURL = (searchText, location, navigate) => {
  const params = new URLSearchParams(location.search)

  if (searchText.trim()) {
    // If search input has values, update URL
    params.set("search", searchText.trim())
  } else if (params.has("search")) {
    // If the search is empty and `search` exists in the URL, remove it
    params.delete("search")
  }

  // Update URL only if it has changed
  navigate(`?${params.toString()}`, { replace: false })
}
