import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import { withTranslation } from "react-i18next"
import i18n from "../../i18n"
import languages from "common/languages"

const LoginLanguageDropdown = () => {
  const [selectedLang, setSelectedLang] = useState("English")
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.indexOf("fi") == 0) {
        setSelectedLang(get(languages, `fi.label`))
      } else {
        /*else if (currentLanguage.indexOf("sv") == 0) {
        setSelectedLang(get(languages, `sv.label`))
        // SETTING SWEDISH
      }*/
        setSelectedLang(get(languages, `en.label`))
      }
    }
  }, [])

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang)
    localStorage.setItem("i18nextLng", lang)
    setSelectedLang(get(languages, `${lang}.label`))
  }

  // Toggle dropdown
  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item text-muted" tag="button">
          <i className="bx bx-globe me-2" />
          {selectedLang}
          <i className="mdi mdi-chevron-down text-dark ms-2" />
        </DropdownToggle>
        <DropdownMenu className="dropdown">
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${
                selectedLang === key ? "active" : "none"
              }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Language"
                className="me-2"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(LoginLanguageDropdown)
