import axios from "axios"
import * as url from "./url_helper"
import { getLocaleText } from "utils/general";

const axiosApi = axios.create({ baseURL: url.API_URL })

axiosApi.defaults.headers.common["AuthKey"] = "ad28e382-8485-4633-92e7-f29fbb0523f1";
axiosApi.defaults.headers.common["Content-Type"] = "application/json";
axiosApi.defaults.headers.common["Accept"] = "application/json";
axiosApi.defaults.withCredentials = true;

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status) {
      if (error.response.status === 401) {
        if (localStorage.getItem("session_id")) {
          console.log("401 - LOGOUT")
          localStorage.removeItem("session_id")
          window.location.reload()
        }
      }
    }
   //Promise.reject(error)
   throw error;
  }
)

// Login
export const suvantoLogin = data => {
  let credentials = data.email + ":" + data.password
  let encoded = btoa(credentials)
  return axiosApi
    .post(
      "",
      { path: url.LOGIN },
      { headers: { Authorization: "Basic " + encoded } }
    )
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response?.status) {
        switch (err.response.status) {
          case 401:
            message = "Invalid credentials"
            break
          case 403:
            message = "Error 403"
            break
          case 500:
          default:
            message = "Error 500"
            //message = err[1]
            break
        }
      }
      throw message
    })
}

// Logout
export async function suvantoLogout() {
  return axiosApi
    .post("", {
      path: url.LOGOUT,
      content: {}
    }, {})
    .then(response => response.data)
}

export async function changePassword(controller, oldPassword, newPassword) {
  return axiosApi
    .post("", {
      path: url.CHANGE_PASSWORD,
      content: { oldPassword: oldPassword, newPassword: newPassword }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateUserProperties(controller, firstName, lastName, gsm, email) {
  return axiosApi
    .post("", {
      path: url.UPDATE_USER_PROPS,
      content: { firstName: firstName, lastName: lastName, gsm: gsm, email: email }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get user info when user is alreary logged in
 */
export async function getUserInfo(controller) {
  return axiosApi
    .post("", { path: url.GET_USER_INFO }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get array of groups
 * @param {boolean} includeAllEntry
 * @param {boolean} removeEmpty
 */
export async function getGroupsList(
  controller,
  includeAllEntry = false,
  removeEmpty = false
) {
  return axiosApi
    .post("", {
      path: url.GET_GROUP_LIST,
      content: { includeAllEntry: includeAllEntry, removeEmpty: removeEmpty },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getGroups(controller) {
  return axiosApi
    .post("", {
      path: url.GET_GROUPS}, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateClientInfo(controller, suvantoKey, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_CLIENT, 
      content: { suvantoKey: suvantoKey, values: values },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteClient(controller, suvantoKey, comments) {
  return axiosApi
    .post("", {
      path: url.DELETE_CLIENT, 
      content: { key: suvantoKey, comments: comments },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function createClient(controller, group, clientName) {
  return axiosApi
    .post("", {
      path: url.CREATE_CLIENT, 
      content: { group: group, clientName: clientName },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteClientData(controller, suvantoKey, clientInfo=false, alarms=false, journal=false, alarmRules=false, scheduledMessages=false, userLinks=false, health=false, home=false, tracker=false) {
  return axiosApi
    .post("", {
      path: url.DELETE_CLIENT_DATA, 
      content: { suvantoKey: suvantoKey, clientInfo: clientInfo, alarms: alarms, journal: journal, alarmRules: alarmRules, scheduledMessages: scheduledMessages, userLinks: userLinks, health: health, home: home, tracker: tracker },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function clientsTransferGroup(controller, clientsArray, group) {
  return axiosApi
    .post("", {
      path: url.CLIENTS_TRANSFER_GROUP, 
      content: { clientsArray: clientsArray, group: group },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getAvailableTags(controller) {
  return axiosApi
    .post("", {
      path: url.GET_AVAILABLE_TAGS, 
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getGroupSettings(controller, group) {
  return axiosApi
    .post("", {
      path: url.GET_GROUP_SETTINGS, 
      content: { group: group },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateGroupSettings(controller, group, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_GROUP_SETTINGS, 
      content: { group: group, values: values },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function setTagToGroups(controller, groupsJSON, tag) {
  return axiosApi
    .post("", {
      path: url.SET_TAG_TO_GROUPS, 
      content: { groupsJSON: groupsJSON, tag: tag },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function removeTagFromGroups(controller, groupsJSON, tag) {
  return axiosApi
    .post("", {
      path: url.REMOVE_TAG_GROUPS, 
      content: { groupsJSON: groupsJSON, tag: tag },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get array of clients, contains only name and id
 */
export async function getClientList(controller, includeVideoIMEI) {
  return axiosApi
    .post("", {
      path: url.GET_CLIENT_LIST,
      content: { includeVideoIMEI: includeVideoIMEI }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get array of clients for table - admin
 */
export async function getClientsTable(controller, groupsArray) {
  return axiosApi
    .post("", { path: url.GET_CLIENT_TABLE, content: { groupsArray: groupsArray } }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get client data
 * @param {string} key
 */
export async function getClientData(controller, key, extras=false) {
  return axiosApi
    .post("", {
      path: url.GET_CLIENT_DATA,
      content: { key: key, includeExtras: extras },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * JOURNAL
 ************************/

/**
 *
 * @param {string} suvantoKey
 * @param {number} from
 * @param {number} to
 * @param {number} maxItems
 * @param {string} typeOf
 * @returns
 */
export async function getJournalEntries(
  controller,
  suvantoKey,
  from,
  to,
  maxItems,
  typeOf,
  includeResponses=false
) {
  return axiosApi
    .post("", {
      path: url.GET_JOURNAL_ENTRIES,
      content: {
        suvantoKey: suvantoKey,
        from: from,
        to: to,
        maxItems: maxItems,
        typeOf: typeOf,
        includeResponses: includeResponses
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Add message to journal
 * @param {String} suvantoKey 
 * @param {String} title 
 * @param {String} message 
 * @param {boolean} showAsAdmin 
 */
 export async function addMessageToJournal(controller, suvantoKey, title, message, showAsAdmin=false) {
  return axiosApi
    .post("", {
      path: url.ADD_JOURNAL_MESSAGE,
      content: {
        suvantoKey: suvantoKey,
        title: title,
        message: message,
        showAsAdmin: showAsAdmin
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get responses to a journal entry
 * @param {String} journalKey 
 */
export async function getJournalResponses(controller, journalKey) {
  return axiosApi
    .post("", {
      path: url.GET_JOURNAL_RESPONSES,
      content: {
        journalKey: journalKey
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Add response to journal entry
 * @param {String} journalKey 
 * @param {String} message 
 */
 export async function addJournalResponse(controller, journalKey, message) {
  return axiosApi
    .post("", {
      path: url.ADD_JOURNAL_RESPONSE,
      content: {
        journalKey: journalKey,
        message: message
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function addVideoMessage(controller, suvantoKey, message, dialogType=1, expires=60, expirationAlarm=false, audioAlert=true) {
  return axiosApi
  .post("", {
    path: url.ADD_VIDEO_MESSAGE,
    content: {
      suvantoKey: suvantoKey,
      message: message,
      dialogType: dialogType,
      expires: expires,
      expirationAlarm: expirationAlarm,
      audioAlert: audioAlert,
    },
  }, {signal: controller.signal})
  .then(response => response.data)
}

export async function addManualMeasurement(controller, suvantoKey, type, date, value1, value2, unit) {
  return axiosApi
  .post("", {
    path: url.ADD_MANUAL_MEASURE,
    content: {
      suvantoKey: suvantoKey,
      measureType: type,
      measureDate: date,
      measureValue: value1,
      measureValue2: value2,
      unit: unit
    },
  }, {signal: controller.signal})
  .then(response => response.data)
}

/**
 * Delete journal entries
 * @param {*} controller 
 * @param {*} suvantoKey 
 * @param {*} entries // Array of journal keys ["<KEY>"]
 * @returns 
 */
export async function deleteJournalEntries(controller, suvantoKey, entries) {
  return axiosApi
    .post("", {
      path: url.DELETE_JOURNAL_ENTRIES,
      content: {
        suvantoKey: suvantoKey,
        entries: entries
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

// Edit journal entry
export async function editJournalEntry(controller, journalKey, message) {
  return axiosApi
    .post("", {
      path: url.EDIT_JOURNAL_ENTRY,
      content: {
        journalKey: journalKey,
        message: message
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * End ongoing visit
 * @param {*} controller 
 * @param {*} journalKey 
 * @returns {"statusCode": 200}
 */
export async function endOngoingVisit(controller, journalKey) {
  return axiosApi
    .post("", {
      path: url.END_ONGOING_VISIT,
      content: {
        journalKey: journalKey,
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get download link for journal attachment
 * @param {*} controller 
 * @param {*} journalKey 
 * @returns {"statusCode": 200, "link": ""}
 */
export async function getJournalFileDownloadLink(controller, journalKey) {
  return axiosApi
    .post("", {
      path: url.GET_JOURNAL_FILE_DOWNLOAD_LINK,
      content: {
        journalKey: journalKey,
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

// Download journal attachment blob
export async function downloadJournalAttachment(controller, journalKey) {
  return axiosApi
    .post("", {
      path: url.DOWNLOAD_JOURNAL_BLOB,
      content: {
        journalKey: journalKey,
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

// Upload journal attachment blob
export async function uploadJournalAttachment(controller, suvantoKey, blob, fileInputName, fileType) {
  return axiosApi
    .post("", {
      path: url.UPLOAD_JOURNAL_BLOB,
      content: {
        suvantoKey: suvantoKey, blob: blob, fileInputName: fileInputName, fileType: fileType
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * ALARMS
 ************************/

/**
 * Get alarms for a client
 * @param {string} suvantoKey
 * @param {number} from
 * @param {number} to
 * @param {boolean} showResolved
 */
 export async function getAlarms(controller, suvantoKey, from, to, showResolved) {
  return axiosApi
    .post("", {
      path: url.GET_ALARMS,
      content: { suvantoKey: suvantoKey, from: from, to: to, showResolved: showResolved },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Ack selected alarms
 * @param {string} alarmArray // Array of alarm keys
 */
 export async function ackAlarms(controller, alarmArray) {
  return axiosApi
    .post("", {
      path: url.ACK_ALARMS,
      content: { alarmArray: alarmArray },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Resolve selected alarms
 * @param {string} alarmArray // Array of alarm keys
 * @param {boolean} falseAlarm
 * @param {string} comment
 * @param {string} reason
 * @param {string} actions
 * @param {boolean} handover
 */
 export async function resolveAlarms(controller, alarmArray, comment, falseAlarm, reason=undefined, actions=undefined, handover=false) {
  return axiosApi
    .post("", {
      path: url.RESOLVE_ALARMS,
      content: { alarmArray: alarmArray, comment: comment, falseAlarm: falseAlarm, reason: reason, actions: actions, handover: handover },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getMonitoringAlarms(controller, includeClosed) {
  return axiosApi
    .post("", {
      path: url.GET_MONITORING_ALARMS,
      content: { includeClosed: includeClosed },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getMonitoringHandoverAlarms(controller, includeClosed) {
  return axiosApi
    .post("", {
      path: url.GET_MONITORING_HANDOVER_ALARMS,
      content: { includeClosed: includeClosed },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Delete selected alarms
 * @param {*} suvantoKey // Client key
 * @param {*} entries // Array of alarm keys
 * @returns 
 */
export async function deleteSelectedAlarms(controller, suvantoKey, entries) {
  return axiosApi
    .post("", {
      path: url.DELETE_ALARMS,
      content: { suvantoKey: suvantoKey, entries: entries },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * HOME DATA
 ************************/

/**
 * Get gateway data with nodes list
 * @param {string} key
 */
export async function getGatewayData(controller, key) {
  return axiosApi
    .post("", {
      path: url.GET_GATEWAY_DATA,
      content: { key: key },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getCombinedActivity(controller, key, from, to) {
  return axiosApi
    .post("", {
      path: url.GET_COMBINED_ACTIVITY,
      content: { gatewayKey: key, from: from, to: to },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getActivityTrend(controller, key, date, days) {
  return axiosApi
    .post("", {
      path: url.GET_ACTIVITY_TREND,
      content: { gatewayKey: key, date: date, days: days },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteHomeActivityHistory(controller, gatewayKey) {
  return axiosApi
    .post("", {
      path: url.DELETE_ACTIVITY_HISTORY,
      content: { gatewayKey: gatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get node data values between given dates
 * @param {string} nodeKey
 */
export async function getNodeValues(controller, nodeKey, from, to) {
  return axiosApi
    .post("", {
      path: url.GET_NODE_VALUES,
      content: { nodeKey: nodeKey, from: from, to: to },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get every nodes data values between given dates
 * @param {string} gatewayKey
 */
 export async function getCombinedNodeValues(controller, gatewayKey, from, to) {
  return axiosApi
    .post("", {
      path: url.GET_COMBINED_NODE_VALUES,
      content: { gatewayKey: gatewayKey, from: from, to: to },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get nodes daily temperature & humidity average values and activity from 14 days
 * @param {string} nodeKey
 */
export async function getNodeLongPeriodData(controller, nodeKey, date=undefined, days=14) {
  return axiosApi
    .post("", {
      path: url.GET_NODE_LONGPERIOD_DATA,
      content: { nodeKey: nodeKey, date: date, days: days },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get home state chart data, past 24 hours
 * 1 = Activity
 * 4 = Bed
 * 5 = Out
 * 6 = WC
 * 7 = Kitchen
 * @param {string} gatewayKey
 */
 export async function getHomeStateChartData(controller, gatewayKey) {
  return axiosApi
    .post("", {
      path: url.GET_STATE_CHART_DATA,
      content: { gatewayKey: gatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * GATEWAY AND NODES
 ************************/

export async function getGateways(controller) {
  return axiosApi
    .post("", {
      path: url.GET_GATEWAYS,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getGateway(controller, gatewayKey) {
  return axiosApi
    .post("", {
      path: url.GET_GATEWAY,
      content: { gatewayKey: gatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getGatewayList(controller) {
  return axiosApi
    .post("", {
      path: url.GET_GATEWAY_LIST,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getNodesList(controller, gatewayKey) {
  return axiosApi
    .post("", {
      path: url.GET_NODE_LIST,
      content: { gatewayKey: gatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function createGateway(controller) {
  return axiosApi
    .post("", {
      path: url.CREATE_GATEWAY,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteGateway(controller, gatewayKey) {
  return axiosApi
    .post("", {
      path: url.DELETE_GATEWAY,
      content: { gatewayKey: gatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function resetGateway(controller, gatewayKey) {
  return axiosApi
    .post("", {
      path: url.RESET_GATEWAY,
      content: { gatewayKey: gatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function rebootGateway(controller, gatewayKey) {
  return axiosApi
    .post("", {
      path: url.REBOOT_GATEWAY,
      content: { gatewayKey: gatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function gatewayStartReverseSsh(controller, gatewayKey) {
  return axiosApi
    .post("", {
      path: url.GATEWAY_REVERSE_SSH,
      content: { gatewayKey: gatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateGateway(controller, gatewayKey, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_GATEWAY,
      content: { gatewayKey: gatewayKey, values: values },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Transfer GW from one client to another
 * @param {*} type // 'existing' | 'new'
 * @param {*} fromSuvantoKey // required
 * @param {*} toSuvantoKey // if type is existing
 * @param {*} group // if type is new
 * @param {*} newClientName // if type is new
 * @returns 
 */
export async function transferGateway(controller, type, fromSuvantoKey, toSuvantoKey=null, group=null, newClientName=null) {
  return axiosApi
    .post("", {
      path: url.TRANSFER_GATEWAY,
      content: { type: type, fromSuvantoKey: fromSuvantoKey, toSuvantoKey: toSuvantoKey, group: group, newClientName: newClientName },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Transfer content from one gateway to another
 * @param {*} currentGatewayKey 
 * @param {*} newGatewayKey 
 * @returns 
 */
export async function transferGatewayContent(controller, currentGatewayKey, newGatewayKey) {
  return axiosApi
    .post("", {
      path: url.TRANSFER_GATEWAY_CONTENT,
      content: { currentGatewayKey: currentGatewayKey, newGatewayKey: newGatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function setGatewayInstallationSerials(controller, serials) {
  return axiosApi
    .post("", {
      path: url.SET_GW_INSTALLATION_SERIALS,
      content: { serials: serials },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function createGatewayNode(controller, gatewayKey) {
  return axiosApi
    .post("", {
      path: url.CREATE_NODE,
      content: { gatewayKey: gatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * @param {*} gatewayKey 
 * @param {*} nodes // Array of node keys
 */
export async function deleteSelectedNodes(controller, gatewayKey, nodes) {
  return axiosApi
    .post("", {
      path: url.DELETE_NODES,
      content: { gatewayKey: gatewayKey, nodes: nodes },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function resetGatewayNodes(controller, gatewayKey) {
  return axiosApi
    .post("", {
      path: url.RESET_NODES,
      content: { gatewayKey: gatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateGatewayNode(controller, nodeKey, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_NODE,
      content: { nodeKey: nodeKey, values: values },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function importGateways(controller, jsonString) {
  return axiosApi
    .post("", {
      path: url.IMPORT_GATEWAYS,
      content: { jsonString: jsonString },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function createVirtualGateway(controller, hostGatewayKey) {
  return axiosApi
    .post("", {
      path: url.CREATE_VIRTUAL_GATEWAY,
      content: { hostGatewayKey: hostGatewayKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * TRACKERS
 ************************/

/**
 * Get array of trackers for admins
 */
 export async function getTrackerList(controller) {
  return axiosApi
    .post("", { path: url.GET_TRACKER_LIST }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get tracker data
 * @param {string} key
 */
export async function getTrackerData(controller, trackerKey) {
  return axiosApi
    .post("", {
      path: url.GET_TRACKER_DATA,
      content: { trackerKey: trackerKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get location array for a tracker
 * @param {string} trackerKey
 */
export async function getTrackerLocations(controller, trackerKey) {
  return axiosApi
    .post("", {
      path: url.GET_TRACKER_LOCATIONS,
      content: { trackerKey: trackerKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get events array for a tracker
 * @param {string} trackerKey
 */
export async function getTrackerEvents(controller, trackerKey) {
  return axiosApi
    .post("", {
      path: url.GET_TRACKER_EVENTS,
      content: { trackerKey: trackerKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function locateTracker(controller, trackerKey) {
  return axiosApi
    .post("", {
      path: url.LOCATE_TRACKER,
      content: { trackerKey: trackerKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function queryTrackerStatus(controller, trackerKey) {
  return axiosApi
    .post("", {
      path: url.QUERY_TRACKER_STATUS,
      content: { trackerKey: trackerKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function setTrackerFall(controller, trackerKey, state) {
  return axiosApi
    .post("", {
      path: url.SET_TRACKER_FALL,
      content: { trackerKey: trackerKey, state: state },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function setTrackerGeofence(controller, trackerKey, radius) {
  return axiosApi
    .post("", {
      path: url.SET_TRACKER_GEOFENCE,
      content: { trackerKey: trackerKey, geofenceRadius: radius },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getTrackersTableData(controller) {
  return axiosApi
    .post("", {
      path: url.GET_TRACKERS,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateTracker(controller, trackerKey, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_TRACKER,
      content: { trackerKey: trackerKey, values: values },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteTracker(controller, trackerKey) {
  return axiosApi
    .post("", {
      path: url.DELETE_TRACKER,
      content: { trackerKey: trackerKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function addTracker(controller, trackerType, group, description) {
  return axiosApi
    .post("", {
      path: url.ADD_TRACKER,
      content: { trackerType: trackerType, group: group, description: description },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getNavigilSettings(controller, deviceId) {
  return axiosApi
    .post("", {
      path: url.GET_NAVIGIL_SETTINGS,
      content: { deviceId: deviceId },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function setNavigilSettings(controller, deviceId, settings) {
  return axiosApi
    .post("", {
      path: url.SET_NAVIGIL_SETTINGS,
      content: { deviceId: deviceId, settings: settings },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateNavigilData(controller, trackerKey) {
  return axiosApi
    .post("", {
      path: url.UPDATE_NAVIGIL_DATA,
      content: { trackerKey: trackerKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * 
 * @param {*} type // 'existing' | 'new'
 * @param {*} fromSuvantoKey // required
 * @param {*} toSuvantoKey // if type is existing
 * @param {*} group // if type is new
 * @param {*} newClientName // if type is new
 * @returns 
 */
export async function transferTracker(controller, type, fromSuvantoKey, toSuvantoKey=null, group=null, newClientName=null) {
  return axiosApi
    .post("", {
      path: url.TRANSFER_TRACKER,
      content: { type: type, fromSuvantoKey: fromSuvantoKey, toSuvantoKey: toSuvantoKey, group: group, newClientName: newClientName },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function importTrackers(controller, json, batch, firmware, serverNumber) {
  return axiosApi
    .post("", {
      path: url.IMPORT_TRACKERS,
      content: { json: json, batch: batch, firmware: firmware, serverNumber: serverNumber },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteTrackerHistory(controller, trackerKey) {
  return axiosApi
    .post("", {
      path: url.DELETE_TRACKER_HISTORY,
      content: { trackerKey: trackerKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * CAREPHONE
 ************************/

/**
 * 
 * @param {*} controller 
 * @returns Carephones for a table
 */
 export async function getCarephones(controller) {
  return axiosApi
    .post("", {
      path: url.GET_CAREPHONES,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * 
 * @param {*} controller 
 * @returns Carephones for a dropdown
 */
export async function getCarephoneList(controller) {
  return axiosApi
    .post("", {
      path: url.GET_CAREPHONE_LIST,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get carephone data
 * @param {string} carephoneKey
 */
export async function getCarephoneData(controller, carephoneKey) {
  return axiosApi
    .post("", {
      path: url.GET_CAREPHONE_DATA,
      content: { carephoneKey: carephoneKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Sync carephone data
 * @param {string} carephoneKey
*/
export async function syncCarephoneData(controller, carephoneKey) {
  return axiosApi
    .post("", {
      path: url.SYNC_CAREPHONE,
      content: { carephoneKey: carephoneKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function syncCarephones(controller) {
  return axiosApi
    .post("", {
      path: url.SYNC_CAREPHONES,
      content: { },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateCarephone(controller, carephoneKey, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_CAREPHONE,
      content: { carephoneKey: carephoneKey, values: values },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteCarephone(controller, carephoneKey) {
  return axiosApi
    .post("", {
      path: url.DELETE_CAREPHONE,
      content: { carephoneKey: carephoneKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get carephone related alarms
 * @param {string} suvantoKey
 */
 export async function getCarephoneAlarms(controller, suvantoKey) {
  return axiosApi
    .post("", {
      path: url.GET_CAREPHONE_ALARMS,
      content: { suvantoKey: suvantoKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * 
 * @param {*} type // 'existing' | 'new'
 * @param {*} fromSuvantoKey // required
 * @param {*} toSuvantoKey // if type is existing
 * @param {*} group // if type is new
 * @param {*} newClientName // if type is new
 * @returns 
 */
export async function transferCarephone(controller, type, fromSuvantoKey, toSuvantoKey=null, group=null, newClientName=null) {
  return axiosApi
    .post("", {
      path: url.TRANSFER_CAREPHONE,
      content: { type: type, fromSuvantoKey: fromSuvantoKey, toSuvantoKey: toSuvantoKey, group: group, newClientName: newClientName },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getCarephoneAlarmLogs(controller, carephoneKey) {
  return axiosApi
    .post("", {
      path: url.GET_CAREPHONE_ALARM_LOGS,
      content: { carephoneKey: carephoneKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * SMARTLOCK
 ************************/

/**
 * Get smartlock data
 * @param {string} smartlockKey
 */
 export async function getSmartlockData(controller, smartlockKey) {
  return axiosApi
    .post("", {
      path: url.GET_SMARTLOCK_DATA,
      content: { key: smartlockKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Request permission to open door
 * @param {string} suvantoKey
 */
 export async function openSmartlock(controller, suvantoKey) {
  return axiosApi
    .post("", {
      path: url.OPEN_SMARTLOCK,
      content: { suvantoKey: suvantoKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getSmartlocks(controller) {
  return axiosApi
    .post("", {
      path: url.GET_SMARTLOCKS,
      content: { },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function syncSmartlock(controller, pindoraId) {
  return axiosApi
    .post("", {
      path: url.SYNC_SMARTLOCK,
      content: { id: pindoraId },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function syncSmartlocks(controller) {
  return axiosApi
    .post("", {
      path: url.SYNC_SMARTLOCKS,
      content: { },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateSmartlock(controller, pindoraId, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_SMARTLOCK,
      content: { pindoraId: pindoraId, values: values},
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteSmartlock(controller, id) {
  return axiosApi
    .post("", {
      path: url.DELETE_SMARTLOCK,
      content: { id: id },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getSmartlockList(controller) {
  return axiosApi
    .post("", {
      path: url.GET_SMARTLOCK_LIST,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * 
 * @param {*} type // 'existing' | 'new'
 * @param {*} fromSuvantoKey // required
 * @param {*} toSuvantoKey // if type is existing
 * @param {*} group // if type is new
 * @param {*} newClientName // if type is new
 * @returns 
 */
export async function transferSmartlock(controller, type, fromSuvantoKey, toSuvantoKey=null, group=null, newClientName=null) {
  return axiosApi
    .post("", {
      path: url.TRANSFER_SMARTLOCK,
      content: { type: type, fromSuvantoKey: fromSuvantoKey, toSuvantoKey: toSuvantoKey, group: group, newClientName: newClientName },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * VIDEO
 ************************/

/**
 * Get video device status info
 * @param {string} suvantoKey
 */
export async function getVideoDeviceStatus(controller, suvantoKey) {
  return axiosApi
    .post("", {
      path: url.GET_VIDEO_STATUS,
      content: { suvantoKey: suvantoKey }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get video call JWT
 * @param {string} suvantoKey
 */
 export async function getVideoCallToken(controller, suvantoKey) {
  const locale = getLocaleText()
  return axiosApi
    .post("", {
      path: url.GET_VIDEOCALL,
      content: { suvantoKey: suvantoKey, locale: locale },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get group call config
 * @param {*} controller 
 * @param {*} useDemo Demo or production platform
 * @param {*} autoInviteList Array of room names for auto invites
 * @returns 
 */
export async function getGroupClientConfig(controller, useDemo=false, autoInviteList=[], vmrName=undefined) {
  const locale = getLocaleText()
  return axiosApi
    .post("", {
      path: url.GET_GROUPVIDEO_CONFIG,
      content: { useDemo: useDemo, autoInviteList: autoInviteList, locale: locale, vmrName: vmrName }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getVideosWithSettings(controller) {
  return axiosApi
    .post("", {
      path: url.GET_VIDEOS_WITH_SETTINGS,
      content: {}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function sendVideoAppSettings(controller, suvantoKeys=[], newSettings={}) {
  return axiosApi
    .post("", {
      path: url.SEND_VIDEO_APP_SETTINGS,
      content: { suvantoKeys: suvantoKeys, newSettings: newSettings }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getVideoClients(controller) {
  return axiosApi
    .post("", {
      path: url.GET_VIDEO_CLIENTS,
      content: {}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function sendVideoBulkMessage(controller, suvantoKeys, message, dialogType=1, expires=60, expirationAlarm=false, audioAlert=true) {
  return axiosApi
  .post("", {
    path: url.SEND_VIDEO_BULK_MESSAGE,
    content: {
      suvantoKeys: suvantoKeys,
      message: message,
      dialogType: dialogType,
      expires: expires,
      expirationAlarm: expirationAlarm,
      audioAlert: audioAlert,
    },
  }, {signal: controller.signal})
  .then(response => response.data)
}

export async function getVideoBulkMessageLogs(controller) {
  return axiosApi
    .post("", {
      path: url.GET_VIDEO_BULK_MESSAGE_LOGS,
      content: {}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getGroupCallClients(controller, useDemo=false) {
  return axiosApi
    .post("", {
      path: url.GET_GROUPCALL_GUESTS,
      content: { useDemo: useDemo }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function GetGroupCallFilters(controller) {
  return axiosApi
    .post("", {
      path: url.GET_GROUPCALL_FILTERS,
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function AddGroupCallFilter(controller, newFilterName, selections) {
  return axiosApi
    .post("", {
      path: url.ADD_GROUPCALL_FILTER,
      content: { newFilterName: newFilterName, selections: selections }
    }, {signal: controller.signal})
    .then(response => response.data)
}

  export async function EditGroupCallFilter(controller, newFilterName, selections, selectedFilter) {
    return axiosApi
      .post("", {
        path: url.EDIT_GROUPCALL_FILTER,
        content: { newFilterName: newFilterName, selections: selections, selectedFilter: selectedFilter }
      }, {signal: controller.signal})
      .then(response => response.data)
  }

  export async function DeleteGroupCallFilter(controller, selectedFilter) {
    return axiosApi
      .post("", {
        path: url.DELETE_GROUPCALL_FILTER,
        content: { selectedFilter: selectedFilter }
      }, {signal: controller.signal})
      .then(response => response.data)
  }

export async function getVideoRooms(controller) {
  return axiosApi
    .post("", {
      path: url.GET_VIDEOROOMS,
      content: {}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateVideoRoom(controller, roomName, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_VIDEO_ROOM,
      content: {roomName: roomName, values: values}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateVideoClient(controller, roomName, newPassword, newClientName, callbackEnabled, feedbackEnabled, groupCallHost, groupCallGuest, idleDisplayMode, callRequestTimeout) {
  return axiosApi
    .post("", {
      path: url.UPDATE_VIDEO_CLIENT,
      content: {roomName: roomName, newPassword: newPassword, newClientName: newClientName, callbackEnabled: callbackEnabled, feedbackEnabled: feedbackEnabled, groupCallHost: groupCallHost, groupCallGuest: groupCallGuest, idleDisplayMode: idleDisplayMode, callRequestTimeout: callRequestTimeout }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteVideoClient(controller, roomName) {
  return axiosApi
    .post("", {
      path: url.DELETE_VIDEO_CLIENT,
      content: {roomNumber: roomName }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function registerVideoClients(controller, newClientCount=1, clientEmail="e164@suvanto.video", clientPassword=undefined, groupCallHost=false, groupCallGuest=false, groupId=undefined, production=true) {
  return axiosApi
    .post("", {
      path: url.REGISTER_VIDEO_CLIENTS,
      content: {newClientCount: newClientCount, clientEmail: clientEmail, clientPassword: clientPassword, groupCallHost: groupCallHost, groupCallGuest: groupCallGuest, groupId: groupId, production: production}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getVideoRoomLoginJson(controller, roomName) {
  return axiosApi
    .post("", {
      path: url.GET_VIDEOROOM_LOGIN,
      content: { roomName: roomName }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getVideoDevices(controller, includeTags, excludeLinks=false) {
  return axiosApi
    .post("", {
      path: url.GET_VIDEODEVICES,
      content: { excludeLinks: excludeLinks, includeTags: includeTags }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getVideoDeviceList(controller) {
  return axiosApi
    .post("", {
      path: url.GET_VIDEO_DEVICE_LIST,
      content: {}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getVideoRoomList(controller) {
  return axiosApi
    .post("", {
      path: url.GET_VIDEO_ROOM_LIST,
      content: {}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function addVideoDevices(controller, serials, orderNumber, ignoreAlarms=false) {
  return axiosApi
    .post("", {
      path: url.ADD_VIDEODEVICES,
      content: {serials: serials, orderNumber: orderNumber, ignoreAlarms: ignoreAlarms}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateVideoDevice(controller, serial, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_VIDEO_DEVICE,
      content: {serial: serial, values: values}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteVideoDevice(controller, serial) {
  return axiosApi
    .post("", {
      path: url.DELETE_VIDEODEVICE,
      content: {entryKey: serial}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function videoSendPush(controller, serial, command) {
  return axiosApi
    .post("", {
      path: url.VIDEO_SEND_PUSH,
      content: {serial: serial, command: command}
    }, {signal: controller.signal})
    .then(response => response.data)
}
export async function getVideoExtras(controller, serial) {
  return axiosApi
    .post("", {
      path: url.VIDEO_GET_EXTRAS,
      content: {serial: serial}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function videoGetClientStatus(controller, roomName) {
  return axiosApi
    .post("", {
      path: url.VIDEO_GET_CLIENT_STATUS,
      content: {videoRoomName: roomName}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function videoForceRefresh(controller, roomName) {
  return axiosApi
    .post("", {
      path: url.VIDEO_FORCE_REFRESH,
      content: {roomName: roomName}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function rebootVideoDevice(controller, serial) {
  return axiosApi
    .post("", {
      path: url.REBOOT_VIDEO_DEVICE,
      content: { serial: serial }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * 
 * @param {*} type // 'existing' | 'new'
 * @param {*} fromSuvantoKey // required
 * @param {*} toSuvantoKey // if type is existing
 * @param {*} group // if type is new
 * @param {*} newClientName // if type is new
 * @returns 
 */
export async function transferVideos(controller, type, fromSuvantoKey, toSuvantoKey=null, group=null, newClientName=null) {
  return axiosApi
    .post("", {
      path: url.TRANSFER_VIDEOS,
      content: { type: type, fromSuvantoKey: fromSuvantoKey, toSuvantoKey: toSuvantoKey, group: group, newClientName: newClientName },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Set ignore alarm state to every video device with the order
 * @param {string} orderNumber 
 * @param {boolean} ignoreAlarms 
 * @returns statusCode 200
 */
export async function setVideoIgnoreAlarms(controller, orderNumber, ignoreAlarms) {
  return axiosApi
    .post("", {
      path: url.VIDEO_SET_IGNORE_ALARMS,
      content: { orderNumber: orderNumber, ignoreAlarms: ignoreAlarms }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getOfflineVideos(controller) {
  return axiosApi
    .post("", {
      path: url.GET_OFFLINE_VIDEOS,
      content: {}
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function handleOfflineVideo(controller, roomName, newStatus, reason) {
  return axiosApi
    .post("", {
      path: url.HANDLE_OFFLINE_VIDEO,
      content: { roomName: roomName, newStatus: newStatus, reason: reason }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getOfflineVideosChart(controller, type, date, filters=undefined, offset=undefined, from=undefined, to=undefined) {
  return axiosApi
    .post("", {
      path: url.GET_OFFLINE_VIDEOS_CHART,
      content: { type: type, date: date, filters: filters, offset: offset, from: from, to: to }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getOfflineVideoHistory(controller, id) {
  return axiosApi
    .post("", {
      path: url.GET_OFFLINE_VIDEO_HISTORY,
      content: { id: id }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getVideoDeviceStatusHistory(controller, serial) {
  return axiosApi
    .post("", {
      path: url.GET_VIDEO_DEVICE_STATUS_HISTORY,
      content: { serial: serial }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getGroupCalendarEvents(controller, from, to, production=true) {
  return axiosApi
    .post("", {
      path: url.GET_GROUP_CALENDAR_EVENTS,
      content: { from: from, to: to, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getVideoCalendarEventsByGroup(controller, groupId, production=false) {
  return axiosApi
    .post("", {
      path: url.GET_VIDEO_CALENDAR_EVENTS_BY_GROUP,
      content: { groupId: groupId, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acCancelCalendarEvent(controller, eventId, production=false) {
  return axiosApi
    .post("", {
      path: url.AC_CANCEL_CALENDAR_EVENT,
      content: { eventId: eventId, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acGetRecordings(controller, groupId, production=false) {
  return axiosApi
    .post("", {
      path: url.AC_GET_RECORDINGS,
      content: { groupId: groupId, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acGetVirtualRoomList(controller, groupId, vmrType, production=false) {
  return axiosApi
    .post("", {
      path: url.AC_GET_VIRTUAL_ROOM_LIST,
      content: { groupId: groupId, vmrType: vmrType, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acCreateUpdateEvent(controller, groupId, title, start, duration, vmrId, imageBase64=undefined, eventId=undefined, production=false, checkOverlap=false) {
  return axiosApi
    .post("", {
      path: url.AC_CREATE_UPDATE_EVENT,
      content: { groupId: groupId, title: title, start: start, duration: duration, vmrId: vmrId, imageBase64: imageBase64, eventId: eventId, production: production, checkOverlap: checkOverlap }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acCreateUpdateRecording(controller, groupId, name, recordingUrl, imageBase64=undefined, id=undefined, production=false) {
  return axiosApi
    .post("", {
      path: url.AC_CREATE_UPDATE_RECORDING,
      content: { groupId: groupId, name: name, url: recordingUrl, imageBase64: imageBase64, id: id, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acCreateUpdateVirtualRoom(controller, groupId, type, name, requireHost=false, opensAt=undefined, closesAt=undefined, imageBase64=undefined, vmrId=undefined, production=false) {
  return axiosApi
    .post("", {
      path: url.AC_CREATE_UPDATE_VIRTUAL_ROOM,
      content: { groupId: groupId, type: type, name: name, requireHost: requireHost, opensAt: opensAt, closesAt: closesAt, imageBase64: imageBase64, vmrId: vmrId, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acDeleteRecording(controller, id, production=false) {
  return axiosApi
    .post("", {
      path: url.AC_DELETE_RECORDING,
      content: { id: id, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acDeleteCalendarEvent(controller, id, production=false) {
  return axiosApi
    .post("", {
      path: url.AC_DELETE_CALENDAR_EVENT,
      content: { id: id, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acDeleteVirtualRoom(controller, vmrId, production=false) {
  return axiosApi
    .post("", {
      path: url.AC_DELETE_VIRTUAL_ROOM,
      content: { vmrId: vmrId, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getACsGroupsTree(controller, production=false) {
  return axiosApi
    .post("", {
      path: url.GET_AC_GROUPS_TREE,
      content: { production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acCreateUpdateGroup(controller, parentGroupId, name, groupType, groupId=undefined, production=true) {
  return axiosApi
    .post("", {
      path: url.AC_CREATE_UPDATE_GROUP,
      content: { parentGroupId: parentGroupId, name: name, groupType: groupType, groupId: groupId, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acDeleteGroup(controller, groupId, production=true) {
  return axiosApi
    .post("", {
      path: url.AC_DELETE_GROUP,
      content: { groupId: groupId, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acGetGroupUsers(controller, groupId, production=true) {
  return axiosApi
    .post("", {
      path: url.AC_GET_GROUP_USERS,
      content: { groupId: groupId, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function acUpdateMultipleGroupUsers(controller, groupId, action, rooms, production=true) {
  return axiosApi
    .post("", {
      path: url.AC_UPDATE_MULTIPLE_GROUP_USERS,
      content: { groupId: groupId, action: action, rooms: rooms, production: production }
    }, {signal: controller.signal})
    .then(response => response.data)
}


/***********************
 * SCHEDULED MESSAGES
 ************************/

export async function getScheduledMessages(controller, suvantoKey) {
  return axiosApi
    .post("", {
      path: url.GET_SCHEDULED_MESSAGES,
      content: { suvantoKey: suvantoKey }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteScheduledMessage(controller, suvantoKey, entryKey) {
  return axiosApi
    .post("", {
      path: url.DELETE_SCHEDULED_MESSAGE,
      content: { suvantoKey: suvantoKey, entryKey: entryKey }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function addOrUpdateScheduledMessage(controller, suvantoKey, values) {
  return axiosApi
    .post("", {
      path: url.ADD_OR_UPDATE_SCHEDULED_MESSAGE,
      content: { suvantoKey: suvantoKey, values: values }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * ALARM CENTER & SMS ALARMS
 ************************/

export async function getClientsAlarmCenterSettings(controller, suvantoKey) {
  return axiosApi
    .post("", {
      path: url.GET_ALARM_CENTER_SETTINGS,
      content: { suvantoKey: suvantoKey }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateClientsAlarmCenterSettings(controller, suvantoKey, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_ALARM_CENTER_SETTINGS,
      content: { suvantoKey: suvantoKey, values: values }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getClientsSmsAlarmConfigs(controller, suvantoKey) {
  return axiosApi
    .post("", {
      path: url.GET_CLIENTS_SMS_ALARM_CONFIGS,
      content: { suvantoKey: suvantoKey }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateClientsSmsAlarmConfigs(controller, suvantoKey, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_CLIENTS_SMS_ALARM_CONFIGS,
      content: { suvantoKey: suvantoKey, values: values }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * DOSE / MEDICINE REMINDER
 ************************/

export async function getDoseConfig(controller, suvantoKey) {
  return axiosApi
    .post("", {
      path: url.GET_DOSE_CONFIG,
      content: { suvantoKey: suvantoKey }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateDoseConfig(controller, suvantoKey, config) {
  return axiosApi
    .post("", {
      path: url.UPDATE_DOSE_CONFIG,
      content: { suvantoKey: suvantoKey, config: config }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getDoses(controller) {
  return axiosApi
    .post("", {
      path: url.GET_DOSES,
      content: { }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function syncDoses(controller) {
  return axiosApi
    .post("", {
      path: url.SYNC_DOSES,
      content: { }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateDose(controller, deviceId, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_DOSE,
      content: { deviceId: deviceId, values: values }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function syncDose(controller, deviceId) {
  return axiosApi
    .post("", {
      path: url.SYNC_DOSE_CONFIG,
      content: { deviceId: deviceId }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function resetDose(controller, deviceId) {
  return axiosApi
    .post("", {
      path: url.RESET_DOSE,
      content: { deviceId: deviceId }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function addDose(controller, suvantoKey, dosecanId, firstName, lastName) {
  return axiosApi
    .post("", {
      path: url.ADD_DOSE,
      content: { suvantoKey: suvantoKey, dosecanId: dosecanId, firstName: firstName, lastName: lastName }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function removeDose(controller, suvantoKey, citizenId) {
  return axiosApi
    .post("", {
      path: url.REMOVE_DOSE,
      content: { suvantoKey: suvantoKey, id: citizenId }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function replaceDose(controller, suvantoKey, alias) {
  return axiosApi
    .post("", {
      path: url.REPLACE_DOSE,
      content: { suvantoKey: suvantoKey, alias: alias }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * HEALTH MEASUREMENTS
 ************************/

export async function getHealthMeasurements(controller, suvantoKey, type, startDate, endDate) {
  return axiosApi
    .post("", {
      path: url.GET_HEALTH_MEASUREMENTS,
      content: { suvantoKey: suvantoKey, type: type, startDate: startDate, endDate: endDate }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * ALARM SETTINGS
 ************************/

export async function getAlarmSettings(controller, suvantoKey) {
  return axiosApi
    .post("", {
      path: url.GET_ALARM_SETTINGS,
      content: { suvantoKey: suvantoKey }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteAlarmSetting(controller, key) {
  return axiosApi
    .post("", {
      path: url.DELETE_ALARM_SETTING,
      content: { key: key }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * 
 * @param {*} controller 
 * @param {*} suvantoKey 
 * @param {*} values Add key to values to update existing entry, else new is created
 * @returns 
 */
export async function addOrUpdateAlarmSetting(controller, suvantoKey, values) {
  return axiosApi
    .post("", {
      path: url.ADD_OR_UPDATE_ALARM_SETTING,
      content: { suvantoKey: suvantoKey, values: values }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * MEASURING DEVICES
 ************************/

export async function getMeasuringDevices(controller) {
  return axiosApi
    .post("", {
      path: url.GET_MEASURING_DEVICES,
      content: { }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteMeasuringDevice(controller, key) {
  return axiosApi
    .post("", {
      path: url.DELETE_MEASURING_DEVICE,
      content: { key: key }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateMeasuringDevice(controller, key, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_MEASURING_DEVICE,
      content: { key: key, values: values }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function addMeasuringDevice(controller) {
  return axiosApi
    .post("", {
      path: url.ADD_MEASURING_DEVICE,
      content: { }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * APP USERS
 ************************/

export async function getApplicationUsers(controller, suvantoKey, groupFilter, filterEnabled) {
  return axiosApi
    .post("", {
      path: url.GET_APP_USERS,
      content: { suvantoKey: suvantoKey, groupFilter: groupFilter, filterEnabled: filterEnabled }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getApplicationUser(controller, userKey) {
  return axiosApi
    .post("", {
      path: url.GET_APP_USER,
      content: { userKey: userKey }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function createApplicationUser(controller, username, group, name, clientKey=null) {
  return axiosApi
    .post("", {
      path: url.CREATE_APP_USER,
      content: { username: username, group: group, name: name, clientKey: clientKey }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateApplicationUser(controller, userKey, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_APP_USER,
      content: { userKey: userKey, values: values }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteApplicationUsers(controller, userArray) {
  return axiosApi
    .post("", {
      path: url.DELETE_APP_USERS,
      content: { userArray: userArray }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function sendAppUserCredentials(controller, userKey, textMessage) {
  return axiosApi
    .post("", {
      path: url.SEND_APP_USER_CREDENTIALS,
      content: { userKey: userKey, textMessage: textMessage }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function linkAppUsersToClient(controller, clientKey, userArray) {
  return axiosApi
    .post("", {
      path: url.LINK_APP_USERS_TO_CLIENT,
      content: { clientKey: clientKey, userArray: userArray }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function unlinkAppUsersFromClient(controller, clientKey, userArray) {
  return axiosApi
    .post("", {
      path: url.UNLINK_APP_USERS_FROM_CLIENT,
      content: { clientKey: clientKey, userArray: userArray }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getAppUsersLinks(controller, userKey, groupArray) {
  return axiosApi
    .post("", {
      path: url.GET_APP_USERS_LINKS,
      content: { userKey: userKey, groupArray: groupArray }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function linkClientsToAppUser(controller, userKey, clientArray) {
  return axiosApi
    .post("", {
      path: url.LINK_CLIENTS_TO_APP_USER,
      content: { userKey: userKey, clientArray: clientArray }
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function unlinkClientsFromAppUser(controller, userKey, clientArray) {
  return axiosApi
    .post("", {
      path: url.UNLINK_CLIENTS_FROM_APP_USER,
      content: { userKey: userKey, clientArray: clientArray }
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * REPORTING
 ************************/

/**
 * Get service stats
 * @param {string} selections
 */
export async function getServiceStats(controller, selections) {
  return axiosApi
    .post("", {
      path: url.GET_SERVICE_STATS,
      content: { selections: selections },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get device stats & unique identifiers
 * @param {string} selections
 */
export async function getDeviceStats(controller, selections) {
  return axiosApi
    .post("", {
      path: url.GET_DEVICE_STATS,
      content: { selections: selections },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get alarm stats from selected groups
 * @param {string} selections
 * @param {Date} from
 * @param {Date} to
 * @returns
 */
export async function getAlarmStats(controller, selections, from, to) {
  return axiosApi
    .post("", {
      path: url.GET_ALARM_STATS,
      content: { selections: selections, from: from, to: to },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get visit stats from selected groups
 * @param {string} selections
 * @param {Date} from
 * @param {Date} to
 * @returns
 */
export async function getVisitStats(controller, selections, from, to) {
  return axiosApi
    .post("", {
      path: url.GET_VISIT_STATS,
      content: { selections: selections, from: from, to: to },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get call stats from selected groups
 * @param {string} selections
 * @param {Date} from
 * @param {Date} to
 * @returns
 */
export async function getCallStats(controller, selections, from, to) {
  return axiosApi
    .post("", {
      path: url.GET_CALL_STATS,
      content: { selections: selections, from: from, to: to },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getGroupCallStats(controller, selections, from, to) {
  return axiosApi
    .post("", {
      path: url.GET_GROUP_CALL_STATS,
      content: { selections: selections, from: from, to: to },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getGroupLicenseStats(controller, groups, tag=undefined) {
  return axiosApi
    .post("", {
      path: url.GET_GROUP_LICENSE_STATS,
      content: { groups: groups, tag: tag },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getGroupCallLicenseStats(controller, groups, tags=undefined) {
  return axiosApi
    .post("", {
      path: url.GET_GROUPCALL_LICENSE_STATS,
      content: { groups: groups, tags: tags },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * Get measurement stats from selected groups
 * @param {string} selections
 * @param {Date} from
 * @param {Date} to
 * @returns
 */
export async function getMeasurementStats(controller, selections, from, to) {
  return axiosApi
    .post("", {
      path: url.GET_MEASUREMENT_STATS,
      content: { selections: selections, from: from, to: to },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/** 
 * Get smartlock open requests
 * @param {string} selections
 */
export async function getOpenRequests(controller, selections, from, to) {
  return axiosApi
    .post("", {
      path: url.GET_OPEN_REQUESTS,
      content: { selections: selections, from: from, to: to },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getClientServiceCounts(controller, selections, tag=undefined) {
  return axiosApi
    .post("", {
      path: url.GET_CLIENT_SERVICE_COUNTS,
      content: { selections: selections, tag: tag },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * ORDERS
 ************************/

 export async function getOrders(controller) {
  return axiosApi
    .post("", {
      path: url.GET_ORDERS,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getOrder(controller, orderNumber) {
  return axiosApi
    .post("", {
      path: url.GET_ORDER,
      content: { orderNumber: orderNumber },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function queryOrderDevices(controller, orderNumber) {
  return axiosApi
    .post("", {
      path: url.QUERY_ORDER_DEVICES,
      content: { orderNumber: orderNumber },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateOrder(controller, orderNumber, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_ORDER,
      content: { orderNumber: orderNumber, values: values },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function createOrder(controller, orderNumber) {
  return axiosApi
    .post("", {
      path: url.CREATE_ORDER,
      content: { orderNumber: orderNumber },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * LOGS
 ************************/

export async function getSystemLogs(controller, logType, startDate, endDate, maxItems, searchExpression=undefined, user=undefined) {
  return axiosApi
    .post("", {
      path: url.GET_SYSTEM_LOGS,
      content: {
        logType: logType,
        startDate: startDate,
        endDate: endDate,
        maxItems: maxItems,
        searchExpression: searchExpression,
        user: user
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function queryFeedback(controller, maxItems=100, read=true) {
  return axiosApi
    .post("", {
      path: url.GET_FEEDBACK_LOGS,
      content: {
        maxItems: maxItems,
        read: read
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function markFeedbackRead(controller, id) {
  return axiosApi
    .post("", {
      path: url.MARK_FEEDBACK_READ,
      content: { id: id },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function queryGatewayLogs(controller, startDate, endDate, maxItems=100) {
  return axiosApi
    .post("", {
      path: url.GET_GATEWAY_LOGS,
      content: {
        startDate: startDate,
        endDate: endDate,
        maxItems: maxItems
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function queryAuditLogs(controller, startDate, endDate, username, ipAddress, event, thing, details, maxItems=500) {
  return axiosApi
    .post("", {
      path: url.GET_AUDIT_LOGS,
      content: {
        startDate: startDate,
        endDate: endDate,
        username: username,
        ipAddress: ipAddress,
        event: event,
        thing: thing,
        details: details,
        maxItems: maxItems
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * SMS
 ************************/

export async function getMessagesWithNumber(controller, gsm, from, to) {
  return axiosApi
    .post("", {
      path: url.GET_SMS_WITH_NUMBER,
      content: {gsmNumber: gsm, from: from, to: to},
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function sendSms(controller, message, recipient, senderName, source, imsi) {
  return axiosApi
    .post("", {
      path: url.SEND_SMS,
      content: {
        message: message,
        recipient: recipient,
        senderName: senderName,
      },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getSupportSms(controller) {
  return axiosApi
    .post("", {
      path: url.GET_SECURITY_SMS,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function querySms(controller, from, to, sender, recipient, messageContent, statusId, maxEntries=500) {
  return axiosApi
    .post("", {
      path: url.QUERY_SMS,
      content: { from: from, to: to, sender: sender, recipient: recipient, messageContent: messageContent, statusId: statusId, maxEntries: maxEntries},
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * ADMIN USERS
 ************************/

export async function getAdminUsers(controller, group, groupMembersOnly=true, fetchUsersGroups=true) {
  return axiosApi
    .post("", {
      path: url.GET_ADMIN_USERS,
      content: { group: group, groupMembersOnly: groupMembersOnly, fetchUsersGroups: fetchUsersGroups },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getManagedAdminUsers(controller, groups) {
  return axiosApi
    .post("", {
      path: url.GET_MANAGED_ADMIN_USERS,
      content: { groups: groups },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getAdminRequests(controller) {
  return axiosApi
    .post("", {
      path: url.GET_ADMIN_REQUESTS,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function newAdminRequest(controller, formValues) {
  return axiosApi
    .post("", {
      path: url.NEW_ADMIN_REQUEST,
      content: { formValues: formValues },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getAdminsInfo(controller, username) {
  return axiosApi
    .post("", {
      path: url.GET_ADMIN_INFO,
      content: { username: username },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function createAdminUser(controller, group, username) {
  return axiosApi
    .post("", {
      path: url.CREATE_ADMIN,
      content: { group: group, username: username },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteAdminUser(controller, username) {
  return axiosApi
    .post("", {
      path: url.DELETE_ADMIN,
      content: { username: username },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function updateAdminUser(controller, username, values) {
  return axiosApi
    .post("", {
      path: url.UPDATE_ADMIN,
      content: { username: username, values: values },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function sendLoginCredentials(controller, username, password) {
  return axiosApi
    .post("", {
      path: url.SEND_LOGIN_CREDENTIALS,
      content: { username: username, password: password },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getAdminsGroups(controller, username) {
  return axiosApi
    .post("", {
      path: url.GET_ADMINS_GROUPS,
      content: { username: username },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getAdminsPrivateClients(controller, username, includeUnlinked) {
  return axiosApi
    .post("", {
      path: url.GET_ADMINS_PRIVATE_CLIENTS,
      content: { username: username, includeUnlinked: includeUnlinked },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function linkPrivateClients(controller, username, clients) {
  return axiosApi
    .post("", {
      path: url.LINK_PRIVATE_CLIENTS,
      content: { username: username, clients: clients },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function unlinkPrivateClients(controller, username, clients) {
  return axiosApi
    .post("", {
      path: url.UNLINK_PRIVATE_CLIENTS,
      content: { username: username, clients: clients },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function disableAdminUser(controller, username) {
  return axiosApi
    .post("", {
      path: url.DISABLE_ADMIN,
      content: { username: username },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * @param {*} group Name of the group
 * @param {*} users Array of usernames
 * @returns statusCode
 */
export async function addAdminsToGroup(controller, group, users) {
  return axiosApi
    .post("", {
      path: url.LINK_ADMIN,
      content: { group: group, users: users },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * @param {*} group Name of the group
 * @param {*} users Array of usernames
 * @returns statusCode
 */
export async function removeAdminsFromGroup(controller, group, users) {
  return axiosApi
    .post("", {
      path: url.UNLINK_ADMIN,
      content: { group: group, users: users },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * @param {*} groups Array of groups
 * @param {*} user Username
 * @returns statusCode
 */
export async function addGroupsToAdmin(controller, user, groups) {
  return axiosApi
    .post("", {
      path: url.LINK_GROUPS_TO_ADMIN,
      content: { user: user, groups: groups },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * @param {*} groups Array of groups
 * @param {*} user Username
 * @returns statusCode
 */
export async function removeGroupsFromAdmin(controller, user, groups) {
  return axiosApi
    .post("", {
      path: url.UNLINK_GROUPS_FROM_ADMIN,
      content: { user: user, groups: groups },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteGroup(controller, group) {
  return axiosApi
    .post("", {
      path: url.DELETE_GROUP,
      content: { group: group },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function createGroup(controller, group) {
  return axiosApi
    .post("", {
      path: url.CREATE_GROUP,
      content: { group: group },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function listAdminGroups(controller) {
  return axiosApi
    .post("", {
      path: url.LIST_ADMIN_GROUPS,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * TAGS
 ************************/

export async function addNewTag(controller, name, parentTag=undefined) {
  return axiosApi
    .post("", {
      path: url.ADD_TAG,
      content: { name: name, parentTag: parentTag },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function renameTag(controller, key, newName) {
  return axiosApi
    .post("", {
      path: url.RENAME_TAG,
      content: { key: key, newName: newName },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteTag(controller, key) {
  return axiosApi
    .post("", {
      path: url.DELETE_TAG,
      content: { key: key },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function getTagsUsers(controller, key) {
  return axiosApi
    .post("", {
      path: url.GET_TAGS_USERS,
      content: { key: key },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * ORGANIZATIONS
 ************************/

export async function getOrganizationList(controller) {
  return axiosApi
    .post("", {
      path: url.GET_ORGANIZATIONS,
      content: {},
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * APPOINTMENTS
 ************************/

/**
 * 
 * @param {*} controller 
 * @param {*} group Group name
 * @param {*} groupBy admins || clients
 * @param {*} from Starting date in milliseconds
 * @param {*} to End date in milliseconds
 * @returns 
 */
export async function getGroupsAppointments(controller, group, groupBy, from, to) {
  return axiosApi
    .post("", {
      path: url.GET_GROUPS_APPOINTMENTS,
      content: { group: group, groupBy: groupBy, from: from, to: to },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function deleteAppointment(controller, appointmentKey) {
  return axiosApi
    .post("", {
      path: url.DELETE_APPOINTMENT,
      content: { appointmentKey: appointmentKey },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function addOrUpdateAppointment(controller, type, adminUser, clientKey, startTime, duration, key=undefined) {
  return axiosApi
    .post("", {
      path: url.ADD_OR_UPDATE_APPOINTMENT,
      content: { appointmentType: type, adminUser: adminUser, clientKey: clientKey, startTime: startTime, duration: duration, key: key },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/**
 * 
 * @param {*} controller 
 * @param {*} data {'array': [{'key': '', 'appointmentType': 'video_call', 'clientKey': '', 'adminUser': '', 'startTime': 0, 'duration': 60}]}
 * @returns 
 */
export async function updateAppointments(controller, data) {
  return axiosApi
    .post("", {
      path: url.UPDATE_APPOINTMENTS,
      content: { data: data },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/***********************
 * MISC
 ************************/

// Sim status query or status change for Telia
export async function teliaSimStatus(controller, imsi, status) {
  return axiosApi
    .post("", {
      path: url.TELIA_SIM_STATUS,
      content: { imsi: imsi, status: status },
    }, {signal: controller.signal})
    .then(response => response.data)
}

// Sim status query or status change for DNA / Elisa
export async function simAction(controller, iccid, newStatus) {
  return axiosApi
    .post("", {
      path: url.SIM_ACTION,
      content: { iccid: iccid, newStatus: newStatus },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function geocodeGetCoordinates(controller, address) {
  return axiosApi
    .post("", {
      path: url.GEOCODE_GET_COORDINATES,
      content: { address: address },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function generateAlarmCenterId(controller, suvantoKey, alarmCenter) {
  return axiosApi
    .post("", {
      path: url.GENERATE_ALARM_CENTER_ID,
      content: { suvantoKey: suvantoKey, alarmCenter: alarmCenter },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function setSupportToUsers(controller, group) {
  return axiosApi
    .post("", {
      path: url.SET_SUPPORT_TO_USERS,
      content: { group: group },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function sendEmail(controller, from, to, cc, bcc, subject, body) {
  return axiosApi
    .post("", {
      path: url.SEND_EMAIL,
      content: { from: from, to: to, cc: cc, bcc: bcc, subject: subject, body: body },
    }, {signal: controller.signal})
    .then(response => response.data)
}

/** WIP
export async function downloadUserManual(controller, file) {
  return axiosApi
    .post("", {
      path: url.DOWNLOAD_USER_MANUAL,
      content: { file: file },
    }, {signal: controller.signal})
    .then(response => response.data)
}

export async function uploadUserManual(controller, file, data) {
  return axiosApi
    .post("", {
      path: url.UPLOAD_USER_MANUAL,
      content: { file: file, data: data },
    }, {signal: controller.signal})
    .then(response => response.data)
}*/
