import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Alert,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  FormGroup,
  FormFeedback,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Collapse,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Table as AntdTable, Popconfirm, Switch, Popover } from "antd"
import {
  showToast,
  stringSorter,
  defaultPageSizeOptions,
  numberSorter,
  booleanSorter,
  dateSorterDateObj,
  dateFormatter,
} from "utils/general"
import {
  createApplicationUser,
  deleteApplicationUsers,
  getApplicationUsers,
  linkAppUsersToClient,
  unlinkAppUsersFromClient,
  getAppUsersLinks,
} from "helpers/backend_helper"
import * as Yup from "yup"
import { useFormik } from "formik"
import AppUserDrawer from "./app-user-drawer"
import PubSub from "pubsub-js"
import SelectGroupFilters from "components/DropdownList/SelectGroupFilters"
import GlobalState from "contexts/GlobalState"
import XLSX from "xlsx"
import TagsTree from "components/DropdownList/TagsTree"
import AppUsersSelected from "./app-users-selected"
import { tableDefaultHeight } from "constants/layout"

const AppUsersConfig = props => {
  const [state] = useContext(GlobalState)
  const [userRole, setUserRole] = useState(null)
  const [group, setGroup] = useState(null)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [ongoingAction, setOngoingAction] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedUsersArr, setSelectedUsersArr] = useState([])
  // const [selectedUsersObject, setSelectedUsersObject] = useState([])
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedTags, setSelectedTags] = useState([])
  const [showOnSide, setShowOnSide] = useState(false)
  const [colSize, setColSize] = useState(12)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [chosenLinkKey, setChosenLinkKey] = useState()
  const [linkCustomers, setLinkCustomers] = useState([])
  const [linkLoading, setLinkLoading] = useState(true)

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
    }
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      // console.log("refresh", event, data)
      setGroup(props.group)
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (props.group) setGroup(props.group)
  }, [props.group])

  useEffect(() => {
    if (group != null) getAppUsers()
  }, [group])

  useEffect(() => {
    const keys = selectedUsers.map(obj => obj.key)
    setSelectedUsersArr(keys)
  }, [selectedUsers])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, users])

  // Reset form when modal changed
  useEffect(() => {
    addValidation.resetForm()
  }, [addModal])

  // Name tags state update from app-users-selected.js
  useEffect(() => {
    // console.log("Selected users updated", selectedUsers)
    if (selectedUsers.length == 0) {
      // Setting table checkboxes empty
      setSelectedRowKeys([])
    }
  }, [selectedUsers])

  // Showing selected users on own card
  // Reset form when modal changed
  useEffect(() => {
    if (showOnSide) {
      setColSize(9)
    } else {
      setColSize(12)
    }
  }, [showOnSide])

  // Get app users for table
  const getAppUsers = () => {
    if (props.suvantoKey) {
      setLoading(true)
      getApplicationUsers(abortRef.current, props.suvantoKey, group, true)
        .then(result => {
          //console.log("Entries: ", result)
          if (result.statusCode == 200 && result.entries) {
            result.entries.sort((a, b) => (a.name < b.name ? 1 : -1)) // Name sort
            result.entries.sort((a, b) => (a.relative > b.relative ? 1 : -1)) // Relatives at the end
            // Sort linked first
            // result.entries.sort((a, b) => (a.relative > b.relative ? 1 : -1)) // Relatives at the end
            result.entries.forEach(element => {
              element.role = props.t(element.role)
            })
            setUsers(result.entries)
            // console.log(result.entries, "entries")
          }
        })
        .catch(err => {
          console.log(err)
          setUsers([])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Link selected users to client
  const linkUsers = () => {
    if (props.suvantoKey && !ongoingAction && selectedUsers.length > 0) {
      setOngoingAction(true)
      // console.log("selectedusers", selectedUsers)
      linkAppUsersToClient(abortRef.current, props.suvantoKey, selectedUsersArr)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setSelectedUsers([])
            getAppUsers()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Unlink selected users from client
  const unlinkUsers = () => {
    if (props.suvantoKey && !ongoingAction && selectedUsersArr.length > 0) {
      setOngoingAction(true)
      unlinkAppUsersFromClient(
        abortRef.current,
        props.suvantoKey,
        selectedUsersArr
      )
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setSelectedUsers([])
            getAppUsers()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Delete selected users
  const deleteUsers = () => {
    if (!ongoingAction && selectedUsersArr.length > 0) {
      setOngoingAction(true)
      deleteApplicationUsers(abortRef.current, selectedUsersArr)
        .then(result => {
          if (result.statusCode == 200) {
            if (result.failed && result.failed.length > 0) {
              showToast(
                props.t(
                  "Unable to delete user, check and remove any existing links"
                ),
                "error",
                15000
              )
            } else {
              showToast(props.t("Deleted"), "success")
              setSelectedUsers([])
              getAppUsers()
            }
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Create user validation
  const addValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      name: "",
      group: props.group,
      linkToCurrent: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required field")),
      username: Yup.string()
        .matches(
          /^[a-z0-9]+[a-z0-9-]*[a-z0-9]+[.][a-z0-9]+[a-z0-9-]*[a-z0-9]$/,
          props.t("Invalid format")
        )
        .min(7, props.t("Too short"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      if (!ongoingAction) {
        let clientKey = null
        if (values.linkToCurrent) clientKey = props.suvantoKey

        setOngoingAction(true)
        createApplicationUser(
          abortRef.current,
          values.username,
          values.group,
          values.name,
          clientKey
        )
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("User created"), "success")
              setAddModal(false)
              addValidation.resetForm()
              getAppUsers()
            } else if (result.statusMessage) {
              showToast(props.t(result.statusMessage), "error")
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingAction(false)
          })
      }
    },
  })

  // Handle search function
  const handleSearch = () => {
    let result = users.filter(
      data =>
        data.name?.toLowerCase().includes(searchText) ||
        data.username?.toLowerCase().includes(searchText) ||
        data.gsm?.toLowerCase().includes(searchText) ||
        data.email?.toLowerCase().includes(searchText)
    )
    setFilteredUsers(result)
  }

  // Popover links content
  const content = (
    <div>
      {linkCustomers.length > 0 && !linkLoading ? (
        <div>
          {linkCustomers.map((l, key) => (
            <p key={"" + key} className="mb-1">
              {l.clientName}
            </p>
          ))}
        </div>
      ) : (
        <div className="py-2 text-center">
          <p className="text-primary">
            <i className="bx bx-loader bx-spin font-size-18 align-middle" />
          </p>
        </div>
      )}
    </div>
  )

  // Table structure
  const columns = [
    {
      dataIndex: "edit",
      align: "center",
      title: props.t("Edit"),
      render: editFormatter,
      width: "100px",
      fixed: "left",
    },
    {
      dataIndex: "linked",
      title: props.t("Linked"),
      align: "center",
      render: linkedFormatter,
      width: "100px",
      defaultSortOrder: "ascend",
      sorter: (a, b, sortOrder) => {
        return booleanSorter(a, b, "linked", sortOrder)
      },
      /*style: (cell, row, rowIndex, colIndex) => {
        if (row.linked == true) {
          return {
            //backgroundColor: "#34C38F",
            color: '#34C38F'
          };
        }
      }*/
    },
    {
      dataIndex: "role",
      title: props.t("Role"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "role", sortOrder)
      },
      render: roleFormatter,
      width: "200px",
    },
    {
      dataIndex: "name",
      title: props.t("Name"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "name", sortOrder)
      },
      width: "200px",
    },
    {
      dataIndex: "username",
      title: props.t("Username"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "username", sortOrder)
      },
      width: "200px",
    },
    {
      dataIndex: "group",
      title: props.t("Group"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "group", sortOrder)
      },
      width: "200px",
    },
    {
      dataIndex: "unit",
      title: props.t("Unit info"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "unit", sortOrder)
      },
      width: "200px",
    },
    {
      dataIndex: "gsm",
      title: props.t("GSM"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "gsm", sortOrder)
      },
      width: "200px",
    },
    {
      dataIndex: "email",
      title: props.t("Email"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "email", sortOrder)
      },
      width: "200px",
    },
    {
      dataIndex: "linkCount",
      title: props.t("Links"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["linkCount"], b["linkCount"], sortOrder)
      },
      hidden: !group,
      render: linkFormatter,
      width: "200px",
    },
    {
      dataIndex: "appVersion",
      title: props.t("App version"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "appVersion", sortOrder)
      },
      hidden: userRole !== "system_admin",
      width: "200px",
    },
    {
      dataIndex: "appOperatingSystem",
      title: props.t("Platform"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "appOperatingSystem", sortOrder)
      },
      hidden: userRole !== "system_admin",
      width: "200px",
    },
    {
      dataIndex: "lastConnected",
      title: props.t("Connected"),
      sorter: (a, b, sortOrder) => {
        return dateSorterDateObj(
          a["lastConnected"],
          b["lastConnected"],
          sortOrder
        )
      },
      render: dateFormatter,
      hidden: userRole !== "system_admin",
      width: "200px",
    },
    {
      dataIndex: "key",
      title: "GUID",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "key", sortOrder)
      },
      hidden: userRole !== "system_admin",
      width: "200px",
    },
  ]

  const onSelectChange = (newSelectedRowKeys, obj) => {
    setSelectedRowKeys(newSelectedRowKeys)

    let rowObject = obj.map(o => ({
      key: o.key,
      name: o.username,
      group: o.group,
    }))

    setSelectedUsers(rowObject)
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    selectedRowKeys,
    onChange: onSelectChange,
  }

  function roleFormatter(cell, row) {
    if (row.relative) {
      //blue color: "#00A0D6",
      return <span className="text-primary">{row.role}</span>
    }
    if (row.securityPerson) {
      return <span className="text-tertiary">{row.role}</span>
      //orange color: "#CF5A5A",
    } else {
      return <span>{row.role}</span>
    }
  }

  function linkedFormatterXlsx(cell) {
    if (cell) {
      return "x"
    } else return <></>
  }

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  function linkedFormatter(cell, row) {
    if (row.linked) {
      return <Input type="checkbox" disabled defaultChecked={true}></Input>
    } else return <></>
  }
  function linkedFormatterXlsx(cell) {
    if (cell) {
      return "x"
    } else return <></>
  }

  // Link formatter
  function settingLinkKey(key) {
    // console.log("link key", key)
    setChosenLinkKey(key)
    if (key != chosenLinkKey) {
      setLinkLoading(true)
      getAppUsersLinks(abortRef.current, key, [])
        .then(result => {
          if (result.statusCode == 200 && result.entries) {
            // console.log("fetch getAppUsersLinks results ", result)
            result.entries.sort((a, b) =>
              a.clientName > b.clientName ? 1 : -1
            )
            setLinkCustomers(result.entries)
          } else {
            showToast(props.t("Error"), "Error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "Error")
        })
        .finally(() => {
          setLinkLoading(false)
          // setUpdate(false)
        })
    }

    // setLinkLoading(false)
  }

  function linkFormatter(cell, row) {
    if (!cell) return ""

    return (
      <Popover
        placement="right"
        content={content}
        title={props.t("Linked customers") + " - " + row.name}
        trigger="click"
      >
        <Button
          id={"btn_" + row.key}
          color="transparent"
          className="px-2 py-1 btn-hover-primary"
          onClick={() => settingLinkKey(row.key)}
        >
          {cell}
        </Button>

        {/* <UncontrolledTooltip placement="right" target={"btn_" + row.key}>
          {props.t("Show linked customers")}
        </UncontrolledTooltip> */}
      </Popover>
    )
  }

  // Group selection changed
  const groupChanged = newGroup => {
    if (newGroup) {
      setGroup(newGroup)
    } else {
      setGroup("")
    }
  }

  // Group selection changed
  const groupChanged2 = newGroup => {
    addValidation.setFieldValue("group", newGroup)
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let title = props.t("Application users")
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Linked"),
        props.t("Role"),
        props.t("Name"),
        props.t("Username"),
        props.t("Group"),
        props.t("GSM"),
        props.t("App version"),
        props.t("Platform"),
        props.t("Connected"),
        props.t("GUID"),
      ],
    ]
    const data = filteredUsers.map(elt => [
      linkedFormatterXlsx(elt.linked),
      elt.role,
      elt.name,
      elt.username,
      elt.group,
      elt.gsm,
      elt.email,
      elt.appVersion,
      elt.appOperatingSystem,
      elt.lastConnected,
      elt.key,
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success")
  }

  return (
    <>
      <Row>
        {/* Side card of chosen users */}
        {showOnSide && (
          <Col sm="3">
            <Collapse isOpen={showOnSide}>
              <div className="wrapper">
                {/*  <div className="parent">
                  <div className="sticky"> */}
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-auto">
                        <CardTitle className="mb-0 mt-0">
                          {props.t("Chosen users")}
                        </CardTitle>
                      </Col>
                      {selectedUsers.length !== 0 && (
                        <Col className="pb-2">
                          {selectedUsers.length == 1 && (
                            <div className="float-end ms-1">
                              <Popconfirm
                                title={props.t("Confirm action")}
                                open={deleteConfirm}
                                okType="danger"
                                okText={props.t("Delete")}
                                cancelText={props.t("Cancel")}
                                onConfirm={() => {
                                  deleteUsers()
                                  setDeleteConfirm(false)
                                }}
                                onCancel={() => {
                                  setDeleteConfirm(false)
                                }}
                              >
                                <Button
                                  id="deleteUser"
                                  color="danger"
                                  disabled={
                                    selectedUsers.length != 1 ||
                                    loading ||
                                    ongoingAction
                                  }
                                  onClick={() => {
                                    setDeleteConfirm(true)
                                  }}
                                >
                                  <i className="mdi mdi-trash-can" />
                                </Button>
                              </Popconfirm>

                              <UncontrolledTooltip
                                target="deleteUser"
                                placement="bottom"
                              >
                                {props.t("Delete selected user")}
                              </UncontrolledTooltip>
                            </div>
                          )}

                          <div className="float-end ms-1">
                            <Button
                              id="unlinkUsers"
                              color="danger"
                              className="ms-0"
                              disabled={
                                selectedUsers.length == 0 ||
                                loading ||
                                ongoingAction
                              }
                              onClick={() => {
                                unlinkUsers()
                              }}
                            >
                              <i className="mdi mdi-link-variant-off" />
                            </Button>
                            <UncontrolledTooltip
                              target="unlinkUsers"
                              placement="bottom"
                            >
                              {props.t("Unlink selected users")}
                            </UncontrolledTooltip>
                          </div>
                          <div className="float-end">
                            <Button
                              color="success"
                              id="linkUsers"
                              disabled={
                                selectedUsers.length == 0 ||
                                loading ||
                                ongoingAction
                              }
                              onClick={() => {
                                linkUsers()
                              }}
                            >
                              <i className="mdi mdi-link-variant-plus" />
                            </Button>
                            <UncontrolledTooltip
                              target="linkUsers"
                              placement="bottom"
                            >
                              {props.t("Link selected users")}
                            </UncontrolledTooltip>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <AppUsersSelected
                      setSelectedUsers={setSelectedUsers}
                      // setSelectedUsersObject={setSelectedUsersObject}
                      selectedUsersObject={selectedUsers}
                      showOnSide={showOnSide}
                    ></AppUsersSelected>
                  </CardBody>
                </Card>
              </div>
            </Collapse>
          </Col>
        )}
        {/* Not in use in v1.0.3*/}
        <Col sm={colSize}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardTitle className="mb-3">
                    {props.t("Application users")}{" "}
                  </CardTitle>
                </Col>
                <Col>
                  <div className="mt-0 float-end">
                    <Dropdown
                      id="download-users-dropdown"
                      style={{ marginTop: "-5px" }}
                      isOpen={menu}
                      toggle={() => setMenu(!menu)}
                      className="download-btn text-center"
                      type="button"
                    >
                      <DropdownToggle
                        id="download-users"
                        className="text-muted"
                        tag="i"
                      >
                        <i className="mdi mdi-download" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {/* TODO: Can not choose directory file right now */}
                        <DropdownItem onClick={() => downloadExcel()}>
                          {props.t("Download .xlsx")}
                        </DropdownItem>
                        {/* <DropdownItem onClick={() => exportPDF()}>
                                      {props.t("Download .pdf")}
                                    </DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="me-3 float-end">
                    <p>
                      {filteredUsers.length} {props.t("users")}
                    </p>
                  </div>
                </Col>
              </Row>
              {users != null && (
                <React.Fragment>
                  <Row>
                    {/* TAGS */}
                    <Col sm="3">
                      <TagsTree
                        loadData={true}
                        expanded={true}
                        selectionChanged={setSelectedTags}
                      />
                    </Col>
                    <Col sm="3">
                      <SelectGroupFilters
                        onChange={groupChanged}
                        multiple={false}
                        selection={group}
                        selectedTags={selectedTags}
                      />
                    </Col>
                    <Col sm="3">
                      <Form
                        className="mt-sm-0 d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          return false
                        }}
                      >
                        <Input
                          type="text"
                          style={{
                            width: "100%",
                            borderRadius: "4px",
                            height: "32px",
                          }}
                          onChange={event =>
                            setSearchText(event.target.value.toLowerCase())
                          }
                          placeholder={props.t("Search") + "..."}
                          prefix={<i className="bx bx-search-alt" />}
                        />
                      </Form>
                    </Col>
                    <Col sm="3">
                      <div className="float-end">
                        <Button
                          // style={{ borderRadius: "100px" }}
                          color="success"
                          onClick={() => {
                            setAddModal(true)
                          }}
                        >
                          <i className="fas fa-user-plus"></i>
                          {!showOnSide && (
                            <span className="d-none d-xl-inline-block ms-2">
                              {props.t("Create a new app user")}
                            </span>
                          )}
                        </Button>
                      </div>
                      {/* TODO: Finish side card */}
                      <Switch
                        id="switchScene"
                        checkedChildren={<i className="bx bx-window"></i>}
                        unCheckedChildren={<i className="bx bx-list-minus"></i>}
                        className="mt-1"
                        checked={showOnSide}
                        onChange={v => setShowOnSide(v)}
                      ></Switch>

                      <UncontrolledTooltip
                        target="switchScene"
                        placement="bottom"
                      >
                        {props.t("Switch view of selected users")}
                      </UncontrolledTooltip>
                    </Col>
                  </Row>

                  <Collapse isOpen={selectedUsers.length > 0 && !showOnSide}>
                    <Row className="py-2">
                      <AppUsersSelected
                        setSelectedUsers={setSelectedUsers}
                        // setSelectedUsersObject={setSelectedUsersObject}
                        selectedUsersObject={selectedUsers}
                        showOnSide={showOnSide}
                      ></AppUsersSelected>
                      <Col className="col-auto">
                        <Button
                          color="success"
                          disabled={
                            selectedUsers.length == 0 ||
                            loading ||
                            ongoingAction
                          }
                          onClick={() => {
                            linkUsers()
                          }}
                        >
                          <i className="mdi mdi-link-variant-plus me-2" />
                          {props.t("Link selected users")}
                        </Button>
                        <Button
                          color="danger"
                          className="ms-3"
                          disabled={
                            selectedUsers.length == 0 ||
                            loading ||
                            ongoingAction
                          }
                          onClick={() => {
                            unlinkUsers()
                          }}
                        >
                          <i className="mdi mdi-link-variant-off me-2" />
                          {props.t("Unlink selected users")}
                        </Button>
                      </Col>
                      <Col className="mt-2">
                        {/* <p>
                                {selectedUsers.length +
                                  " " +
                                  props.t("selected users")}
                              </p> */}
                      </Col>
                      <Col className="col-auto">
                        <Popconfirm
                          title={props.t("Confirm action")}
                          open={deleteConfirm}
                          okType="danger"
                          okText={props.t("Delete")}
                          cancelText={props.t("Cancel")}
                          onConfirm={() => {
                            deleteUsers()
                            setDeleteConfirm(false)
                          }}
                          onCancel={() => {
                            setDeleteConfirm(false)
                          }}
                        >
                          <Button
                            color="danger"
                            disabled={
                              selectedUsers.length != 1 ||
                              loading ||
                              ongoingAction
                            }
                            onClick={() => {
                              setDeleteConfirm(true)
                            }}
                          >
                            <i className="mdi mdi-trash-can me-2" />
                            {props.t("Delete selected user")}
                          </Button>
                        </Popconfirm>
                      </Col>
                    </Row>
                  </Collapse>

                  {/* {!loading && ( */}
                  <>
                    <div className="table-responsive mt-3 mb-2">
                      <AntdTable
                        className="clear-header custom-table-header-width"
                        // style={{ minWidth: "1500px" }}
                        bordered={false}
                        size="small"
                        loading={loading}
                        rowSelection={selectRow}
                        sticky
                        rowKey={"key"}
                        columns={columns}
                        dataSource={filteredUsers}
                        pagination={{
                          showSizeChanger: true,
                          defaultPageSize: 50,
                          pageSizeOptions: defaultPageSizeOptions,
                        }}
                        tableLayout="auto"
                        scroll={{
                          x: "max-content",
                          y: tableDefaultHeight,
                        }}
                      />
                    </div>
                  </>
                  {/* )} */}
                  {/* {loading && (
                    <Lottie
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData: loadingAnim,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      height={100}
                      width={100}
                    ></Lottie>
                  )} */}
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
        <Modal
          isOpen={addModal}
          toggle={() => {
            setAddModal(!addModal)
          }}
          centered
        >
          <ModalHeader>{props.t("Create a new app user")}</ModalHeader>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              return false
            }}
          >
            <ModalBody>
              <Alert color={"primary"}>
                <span>{props.t("UserAllowedChars")}</span>
              </Alert>
              <FormGroup>
                <Label>{props.t("Username")}</Label>
                <Input
                  name="username"
                  type="text"
                  onChange={addValidation.handleChange}
                  onBlur={addValidation.handleBlur}
                  placeholder="E.g. firstname.lastname"
                  value={addValidation.values.username || ""}
                  invalid={
                    !!(
                      addValidation.touched.username &&
                      addValidation.errors.username
                    )
                  }
                ></Input>
                {addValidation.touched.username &&
                addValidation.errors.username ? (
                  <FormFeedback type="invalid">
                    {addValidation.errors.username}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label>{props.t("Name")}</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={addValidation.handleChange}
                  onBlur={addValidation.handleBlur}
                  placeholder="Firstname Lastname"
                  value={addValidation.values.name || ""}
                  invalid={
                    !!(addValidation.touched.name && addValidation.errors.name)
                  }
                ></Input>
                {addValidation.touched.name && addValidation.errors.name ? (
                  <FormFeedback type="invalid">
                    {addValidation.errors.name}
                  </FormFeedback>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label>{props.t("Group")}</Label>
                <SelectGroupFilters
                  onChange={groupChanged2}
                  multiple={false}
                  clearable={false}
                  selection={addValidation.values.group}
                />
              </FormGroup>

              <FormGroup className="mt-4">
                <Switch
                  className="me-2"
                  name="linkToCurrent"
                  checked={addValidation.values.linkToCurrent}
                  onChange={v => {
                    addValidation.setFieldValue("linkToCurrent", v)
                  }}
                ></Switch>
                <Label>{props.t("Link to current client")}</Label>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setAddModal(false)
                }}
              >
                {props.t("Cancel")}
              </Button>
              <Button
                color="success"
                disabled={ongoingAction}
                onClick={() => {
                  addValidation.submitForm()
                }}
              >
                {props.t("Add")}
              </Button>{" "}
            </ModalFooter>
          </Form>
        </Modal>
        <AppUserDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          selectedRow={selectedRow}
          getUsers={getAppUsers}
        />
      </Row>
    </>
  )
}

AppUsersConfig.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.string,
  group: PropTypes.string,
}

export default withTranslation()(AppUsersConfig)
