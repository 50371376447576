/**********************
 * CREATE OR UPDATE VIDEO GROUP MODAL
 *********************/
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import * as Yup from "yup"
import { useFormik } from "formik"
import { acCreateUpdateGroup } from "helpers/backend_helper"
import { showToast } from "utils/general"

const VideoGroupModal = props => {
  const [ongoingAction, setOngoingAction] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupId: props.groupData?.group_id || undefined,
      parentId: props.groupData?.parent_id || undefined,
      name: props.groupData?.name || "",
      groupType: props.groupData?.group_type || "25",
      production: props.production
    },
    validationSchema: Yup.object({
      parentId: Yup.string().required(),
      name: Yup.string()
        .required(props.t("Required field"))
        .min(3, props.t("Too short"))
        .max(36, props.t("Too long")),
    }),
    onSubmit: values => {
      console.log("Submit group: ", values)
      if (!ongoingAction) {
        setOngoingAction(true)
        acCreateUpdateGroup(abortRef.current, values.parentId, values.name, values.groupType, values.groupId, values.production).then(result => {
          if (result.statusCode == 200) {
            if (props.setShowModal) {
              props.setShowModal(false)
            }
            if (props.refreshGroups) {
              props.refreshGroups()
            }
          }
          else throw new Error("Unexpeced result")
        }).catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        }).finally(() => {
          setOngoingAction(false)
        })
      }
    },
  })

  return (
    <>
      <Modal
        isOpen={props.showModal}
        toggle={() => {
          if (props.setShowModal) {
            props.setShowModal(!props.showModal)
          }
        }}
        centered
      >
        <div className="modal-header">
          {validation.values.groupId ? (
            <h5 className="modal-title mt-0">
              {props.t("Edit group")}
            </h5>
          ) : (
            <h5 className="modal-title mt-0">
              {props.t("Add subgroup")}
            </h5>
          )}
          <button
            type="button"
            onClick={() => {
              if (props.setShowModal) {
                props.setShowModal(false)
              }
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              return false
            }}
          >
            <FormGroup>
              <Label>{props.t("Name")}</Label>
              <Input
                name="name"
                type="text"
                value={validation.values.name}
                placeholder={props.t("Name")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  !!(
                    validation.touched.name &&
                    validation.errors.name
                  )
                }
              ></Input>
              {validation.touched.name &&
                validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>{props.t("Type")}</Label>
              <Input
                name="groupType"
                type="select"
                value={validation.values.groupType}
                onChange={validation.handleChange}
              >
                <option value="0">{props.t("Undefined")}</option>
                <option value="1">{props.t("Company")}</option>
                <option value="5">{props.t("Regular group")}</option>
                <option value="9">{props.t("Homecare users")}</option>
                <option value="10">{props.t("Family")}</option>
                <option value="11">{props.t("Family members")}</option>
                <option value="15">{props.t("Visibility list")}</option>
                <option value="16">{props.t("Professionals")}</option>
                <option value="17">{props.t("Service center staff")}</option>
                <option value="19">{props.t("Visibility list for professionals")}</option>
                <option value="20">{props.t("Visibility list for homecare users")}</option>
                <option value="21">{props.t("Visibility list for family members")}</option>
                <option value="22">{props.t("Visibility list for guests")}</option>
                <option value="25">{props.t("Content visibility list")}</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="p-2 mt-2">
          <Col lg={12} className="mt-2 mb-0  text-truncate">
            <Row>
              <Col xs={12}>
                <span className="float-end">
                  <button
                    type="button"
                    onClick={() => {
                      if (props.setShowModal) {
                        props.setShowModal(false)
                      }
                    }}
                    className="btn btn-outline-primary "
                    data-dismiss="modal"
                  >
                    {props.t("Cancel")}
                  </button>

                  <button
                    disabled={ongoingAction || !validation.values.name}
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      validation.submitForm()
                    }}
                  >
                    {props.t("Save")}
                  </button>
                </span>
              </Col>
            </Row>
          </Col>
        </ModalFooter>
      </Modal>
    </>
  )
}

VideoGroupModal.propTypes = {
  t: PropTypes.any,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  refreshGroups: PropTypes.func,
  groupData: PropTypes.any,
  production: PropTypes.bool,
}

export default withTranslation()(VideoGroupModal)