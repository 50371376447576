/***********************
 * ROLE DROPDOWN HEADER
 ************************/

import React, { useEffect, useState, useContext } from "react"
import { Button } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import GlobalState from "../../../contexts/GlobalState"
import withRouter from "components/Common/withRouter"

const UserRoleDropdown = props => {
  const [state, setState] = useContext(GlobalState)
  const [selectedRole, setSelectedRole] = useState("")
  const [roles, setRoles] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    let temp = []
    if (state.authUser) {
      let userObject = JSON.parse(state.authUser)
      if (userObject.role === "system_admin") {
        temp.push({
          label: "UserRoles." + getRoleName("system_admin"),
          value: "system_admin",
        })
        temp.push({
          label: "UserRoles." + getRoleName("organisation_admin"),
          value: "organisation_admin",
        })
        temp.push({
          label: "UserRoles." + getRoleName("organisation_user"),
          value: "organisation_user",
        })
        temp.push({
          label: "UserRoles." + getRoleName("private_user"),
          value: "private_user",
        })
        temp.push({
          label: "UserRoles." + getRoleName("salesperson"),
          value: "salesperson",
        })
        temp.push({
          label: "UserRoles." + getRoleName("order_manager"),
          value: "order_manager",
        })
      } else {
        /*else if (userObject.role === "organisation_admin") {
        temp.push({
          label: "UserRoles." + getRoleName("organisation_admin"),
          value: "organisation_admin",
        })
        temp.push({
          label: "UserRoles." + getRoleName("organisation_user"),
          value: "organisation_user",
        })
      }*/
        temp.push({
          label: "UserRoles." + getRoleName(userObject.role),
          value: userObject.role,
        })
      }

      if (userObject.activeRole) {
        let role = {
          label: "UserRoles." + getRoleName(userObject.activeRole),
          value: userObject.activeRole,
        }
        setSelectedRole(role)
      } else {
        let role = {
          label: "UserRoles." + getRoleName(userObject.role),
          value: userObject.role,
        }
        setSelectedRole(role)
      }
    }
    setRoles(temp)
  }, [])

  const getRoleName = role => {
    switch (role) {
      case "system_admin":
        return "System Admin"
      case "organisation_admin":
        return "Organisation Admin"
      case "organisation_user":
        return "Organisation User"
      case "private_user":
        return "Private User"
      case "salesperson":
        return "Salesperson"
      case "order_manager":
        return "Order Manager"
      default:
        return ""
    }
  }

  const changeRoleAction = role => {
    console.log("New active role is: " + role)
    if (role !== selectedRole.value) {
      if (state.authUser != null) {
        let userObject = JSON.parse(state.authUser)
        userObject.activeRole = role
        setState(state => ({ ...state, authUser: JSON.stringify(userObject) }))
        let newRole = {
          label: "UserRoles." + getRoleName(role),
          value: role,
        }
        props.settingRole(newRole.label)
        setSelectedRole(newRole)
        navigate("/")
      }
    }
  }

  return (
    <>
      {roles?.map(item => (
        <Button
          color="transparent"
          className="role dropdown-item"
          key={item.value + "_dropd"}
          onClick={(e) => {
            e.preventDefault()
            changeRoleAction(item.value)
          }}
        >
          {props.t(item.label)}
        </Button>
      ))}
    </>
  )
}

UserRoleDropdown.propTypes = {
  t: PropTypes.any,
  settingRole: PropTypes.func,
}

export default withRouter(withTranslation()(UserRoleDropdown))
