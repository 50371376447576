import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  Input,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Accordion,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { addMeasuringDevice, getMeasuringDevices } from "helpers/backend_helper"
import "../datatables.scss"
import MeasureDeviceDrawer from "./measure-drawer"
import { showToast, downloadExcel, stringSorter, defaultPageSizeOptions } from "utils/general"
import { Table as AntdTable } from "antd"
import { tableDefaultHeight } from "constants/layout"

const MeasureDeviceConfigs = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [devices, setDevices] = useState([])
  const [filteredDevices, setFilteredDevices] = useState([])
  const [filtersOpen, setFiltersOpen] = useState("")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingCreate, setOngoingCreate] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [statistics, setStatistics] = useState({
    total: 0,
  })

  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [title] = useState(props.t("Measuring devices"))

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    getDevices()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Calculate stats
  useEffect(() => {
    let temp = new Object()
    temp.total = devices.length

    setStatistics(temp)
  }, [devices])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, devices])

  // Handle search function
  const handleSearch = () => {
    let searchTextLowercase = searchText.toLowerCase()
    let result = devices.filter(
      data =>
        data.model?.toLowerCase().includes(searchTextLowercase) ||
        data.serial?.toLowerCase().includes(searchTextLowercase) ||
        data.mac?.toLowerCase().includes(searchTextLowercase) ||
        data.orderNumber?.toLowerCase().includes(searchTextLowercase)
    )
    setFilteredDevices(result)
  }

  // Get devices
  const getDevices = () => {
    setOngoingSearch(true)
    getMeasuringDevices(abortRef.current)
      .then(result => {
        //console.log("care: ", result.entries)
        if (result.statusCode === 200 && result.entries != null) {
          setDevices(result.entries)
        } else {
          setDevices([])
        }
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setDevices([])
        setOngoingSearch(false)
      })
  }

  // Add device
  const addDevice = () => {
    setOngoingCreate(true)
    addMeasuringDevice(abortRef.current)
      .then(result => {
        //console.log("care: ", result.entries)
        if (result.statusCode === 200) {
          showToast(props.t("Success"), "success")
          getDevices()
        } else {
          showToast(props.t("Error"), "error")
        }
        setOngoingCreate(false)
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
        setOngoingCreate(false)
      })
  }

  // Table structure
  const columns = [
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "model",
      title: props.t("Model"),
      defaultSortOrder: 'ascend',
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "model", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "serial",
      title: props.t("Serial"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "serial", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "mac",
      title: props.t("MAC"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "mac", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "imei",
      title: props.t("IMEI"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "imei", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "batch",
      title: props.t("Batch"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "batch", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "orderNumber",
      title: props.t("Order number"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "orderNumber", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "key",
      title: props.t("GUID"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "key", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    AntdTable.SELECTION_COLUMN,
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Model"),
        props.t("Serial"),
        props.t("MAC"),
        props.t("IMEI"),
        props.t("Batch"),
        props.t("Order number"),
        props.t("GUID"),
      ],
    ]
    const data = filteredDevices.map(elt => [
      elt.model,
      elt.serial,
      elt.mac,
      elt.imei,
      elt.batch,
      elt.orderNumber,
      elt.key,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  // Table selection changed
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Measuring devices")} />
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul className="nav nav-tabs nav-tabs-custom card-header-tabs">
                  <NavItem>
                    <NavLink
                      to="#"
                      active={activeTab === "1"}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      {props.t("Table")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "2"}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
                      {props.t("Statistics")}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane className="show" tabId="1">
                  <Row>
                    <Col>
                      {devices != null && (
                        <React.Fragment>
                          <Row className="mb-1">
                            {" "}
                            <Col className="col-auto pe-0">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <div className="search-box me-2 mb-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      onChange={event =>
                                        setSearchText(
                                          event.target.value
                                        )
                                      }
                                      placeholder={props.t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                style={{ borderRadius: "100px" }}
                                color="primary"
                                disabled={ongoingSearch}
                                onClick={() => {
                                  getDevices()
                                }}
                              >
                                <i className="mdi mdi-refresh me-2" />
                                {props.t("Refresh data")}
                              </Button>
                              <Button
                                className="ms-3"
                                disabled={ongoingCreate}
                                style={{ borderRadius: "100px" }}
                                color="success"
                                onClick={() => {
                                  addDevice()
                                }}
                              >
                                <i className="mdi mdi-plus me-2" />
                                {props.t("Create new")}
                              </Button>
                            </Col>
                            <Col>
                              <span className="float-end ms-2">
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="downloadstatstable"
                                >
                                  {props.t("Download statistics")}
                                </UncontrolledTooltip>
                                <Dropdown
                                  id="printButtonServicesStats"
                                  isOpen={menu}
                                  toggle={() => setMenu(!menu)}
                                  className="download-btn text-center"
                                  type="button"
                                >
                                  <DropdownToggle
                                    id="downloadstatstable"
                                    className="text-muted"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-download" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => generateExcel()}
                                    >
                                      {props.t("Download .xlsx")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </span>
                              <div className="mt-2 float-end">
                                <p>
                                  {filteredDevices.length}{" "}
                                  {props.t("found results")}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Accordion
                            flush
                            open={filtersOpen}
                            toggle={id => {
                              id === filtersOpen
                                ? setFiltersOpen("")
                                : setFiltersOpen(id)
                            }}
                          >
                            <AccordionItem>
                              <AccordionBody
                                accordionId="1"
                                className="mb-3"
                                style={{
                                  backgroundColor: "#e8f0fe",
                                  border: "1px solid #ced4da",
                                }}
                              >
                                <Row>
                                  <Col></Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          </Accordion>

                          <Row className="mt-2">
                            <Col xl="12">
                              <AntdTable
                                className="colored-header narrow-header"
                                bordered
                                size="small"
                                sticky
                                rowKey={"key"}
                                columns={columns}
                                dataSource={filteredDevices}
                                rowSelection={{
                                  type: "radio",
                                  fixed: false,
                                  selectedRowKeys: selectedRowKeys,
                                  onChange: onSelectChange,
                                  columnWidth: 0,
                                  renderCell: () => "",
                                }}
                                onRow={(record, rowIndex) => {
                                  return {
                                    onClick: event => {
                                      if (selectedRowKeys.includes(record.key)) {
                                        setSelectedRowKeys([])
                                      }
                                      else {
                                        setSelectedRowKeys([record.key])
                                      }
                                    },
                                  };
                                }}
                                pagination={{
                                  showSizeChanger: true,
                                  defaultPageSize: 50,
                                  pageSizeOptions: defaultPageSizeOptions,
                                }}
                                loading={ongoingSearch}
                                tableLayout="auto"
                                scroll={{
                                  x: "max-content",
                                  y: tableDefaultHeight,
                                }}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane className="show" tabId="2">
                  <Table
                    style={{ tableLayout: "fixed" }}
                    className="table"
                    bordered={false}
                    striped={false}
                    hover={true}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>{props.t("Count")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{props.t("Total")}</th>
                        <td>{statistics.total}</td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

          <MeasureDeviceDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getDevices={getDevices}
          />
        </Container>
      </div>
    </>
  )
}

MeasureDeviceConfigs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(MeasureDeviceConfigs)
