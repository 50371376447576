import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  FormFeedback,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Alert,
  UncontrolledTooltip,
  Collapse,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"
import Lottie from "react-lottie"
import {
  dateFormatter,
  dateSorter,
  defaultPageSizeOptions,
  getLottieLoadingOptions,
  getLottieSearch,
  showToast,
  stringSorter,
} from "utils/general"
import SelectGroupFilters from "components/DropdownList/SelectGroupFilters"
import TagsTree from "components/DropdownList/TagsTree"
import { Table as AntdTable, Switch } from "antd"
import {
  addAdminsToGroup,
  createAdminUser,
  createGroup,
  deleteGroup,
  getAdminUsers,
  removeAdminsFromGroup,
} from "helpers/backend_helper"
import AdminDrawer from "./admin-drawer"
import * as Yup from "yup"
import { useFormik } from "formik"
import XLSX from "xlsx"
import jsPDF from "jspdf"
import "jspdf-autotable"
import classnames from "classnames"
import { tableDefaultHeight } from "constants/layout"

const AdminUsersManagement = props => {
  const [group, setGroup] = useState(null)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [groupAddModal, setGroupAddModal] = useState(false)
  const [groupDeleteModal, setGroupDeleteModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [ongoingAdd, setOngoingAdd] = useState(false)
  const [showLinked, setShowLinked] = useState(true)
  const [fetchUsersGroups, setFetchUsersGroups] = useState(true)
  const [updateCounter, setUpdateCounter] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedTags, setSelectedTags] = useState([])

  const navigate = useNavigate()
  const location = useLocation()

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Admin users") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Set URL dynamically to group
  useEffect(() => {
    if (group) {
      navigate(`?${group}`, { replace: true })
    } else {
      navigate(`?`, { replace: true })
    }
  }, [group, navigate])

  // Set group according to URL
  useEffect(() => {
    const queryString = location.search.slice(1)
    if (queryString) {
      const decodedGroup = decodeURIComponent(queryString) //
      setGroup(decodedGroup)
    } else {
      setGroup(null)
    }
  }, [])

  useEffect(() => {
    //console.log("Group changed -> get users")
    if (group) getAdmins()
  }, [group, showLinked, fetchUsersGroups])

  useEffect(() => {
    //console.log("Group: ", group, ", Loading: ", loading)
    if (showLinked) {
      setSelectedRowKeys([])
      // console.log(group, "groups")
    }
    if (!group || group.length == 0) {
      setFilteredUsers([])
    }
  }, [group])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, users])

  // Handle search function
  const handleSearch = () => {
    let result = users.filter(
      data =>
        data.name?.toLowerCase().includes(searchText) ||
        data.description?.toLowerCase().includes(searchText) ||
        data.groups?.toLowerCase().includes(searchText) ||
        data.gsm?.toLowerCase().includes(searchText) ||
        data.email?.toLowerCase().includes(searchText) ||
        data.fullName?.toLowerCase().includes(searchText)
    )
    // console.log(users, result, searchText, "filtered : resultFiltered")
    setFilteredUsers(result)
  }

  // Get admin users for table
  const getAdmins = () => {
    setLoading(true)
    getAdminUsers(abortRef.current, group, showLinked, fetchUsersGroups)
      .then(result => {
        //console.log("Admins: ", result.entries)
        if (result.statusCode == 200 && result.entries) {
          result.entries.sort((a, b) => (a.name > b.name ? 1 : -1))
          setUsers(result.entries)
        }
      })
      .catch(err => {
        console.log(err)
        setUsers([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Link admins to a group
  const linkAdmins = () => {
    setLoading(true)
    addAdminsToGroup(abortRef.current, group, selectedRowKeys)
      .then(result => {
        //console.log("Link: ", result)
        if (result.statusCode == 200) {
          //setSelectedRowKeys([])
          getAdmins()
          showToast(props.t("Success"), "success", 3000)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Unlink admins from a group
  const unlinkAdmins = () => {
    setLoading(true)
    removeAdminsFromGroup(abortRef.current, group, selectedRowKeys)
      .then(result => {
        //console.log("Unlink: ", result)
        if (result.statusCode == 200) {
          //setSelectedRowKeys([])
          getAdmins()
          showToast(props.t("Success"), "success", 3000)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Reset selections
  const resetSelections = () => {
    setSelectedRowKeys([])
  }

  // Create user validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .matches(
          /^[a-z0-9]+[a-z0-9-]*[a-z0-9]+[.][a-z0-9]+[a-z0-9-]*[a-z0-9]$/,
          props.t("Invalid format")
        )
        .min(7, props.t("Too short"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      if (values.username && group) {
        setOngoingAdd(true)
        createAdminUser(abortRef.current, group, values.username.toLowerCase())
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("Created"), "success")
              setAddModal(false)
              validation.resetForm()
              getAdmins()
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingAdd(false)
          })
      }
    },
  })

  // Create group validation
  const groupValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      group: "",
    },
    validationSchema: Yup.object({
      group: Yup.string()
        .matches(
          /^[A-ZÄÖÅ0-9]+[a-zäöåA-ZÄÖÅ0-9- ]*[a-zäöåA-ZÄÖÅ0-9]$/,
          props.t("Invalid format")
        )
        .min(4, props.t("Too short"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      setOngoingAdd(true)
      createGroup(abortRef.current, values.group)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Created"), "success")
            setGroupAddModal(false)
            groupValidation.resetForm()
            setUpdateCounter(prevCount => prevCount + 1)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAdd(false)
        })
    },
  })

  // Delete selected group
  const groupDelete = () => {
    if (group) {
      setOngoingAdd(true)
      deleteGroup(abortRef.current, group)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Deleted"), "success")
            setGroupDeleteModal(false)
            setGroup(null)
            setUpdateCounter(prevCount => prevCount + 1)
          } else if (result.statusCode == 403) {
            showToast(
              props.t("Operation failed") +
                ", " +
                props.t("reason") +
                ": " +
                result.reason,
              "error"
            )
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAdd(false)
        })
    }
  }

  // Table structure
  const columns = [
    {
      title: props.t("Actions"),
      dataIndex: "actions",
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      title: props.t("Linked"),
      dataIndex: "linked",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.linked - b.linked,
      align: "center",
      render: linkedFormatter,
      width: "100px",
      ellipsis: true,
    },
    {
      title: props.t("Username"),
      dataIndex: "name",
      render: usernameFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "name", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      title: props.t("Name"),
      dataIndex: "fullName",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "fullName", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      title: props.t("Description"),
      dataIndex: "description",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "description", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      title: props.t("Professional"),
      dataIndex: "basicUser",
      sorter: (a, b) => a.basicUser - b.basicUser,
      render: basicFormatter,
      align: "center",
      width: "150px",
      ellipsis: true,
    },
    {
      title: props.t("Groups"),
      dataIndex: "groups",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "groups", sortOrder)
      },
      width: "150px",
      ellipsis: true,
    },
    {
      title: props.t("Email"),
      dataIndex: "email",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "email", sortOrder)
      },
      width: "250px",
      ellipsis: true,
    },
    {
      title: props.t("GSM"),
      dataIndex: "gsm",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "gsm", sortOrder)
      },
      width: "150px",
      ellipsis: true,
    },
    {
      title: props.t("Connected"),
      dataIndex: "lastConnection",
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastConnection"], b["lastConnection"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  function usernameFormatter(cell, row) {
    return (
      <>
        <span className={classnames({ "text-danger": !row.enabled })}>
          {row.name}
        </span>
        {row.videoRoomId && (
          <i className="ms-2 mdi mdi-account-voice text-primary" />
        )}
      </>
    )
  }

  function linkedFormatter(cell, row) {
    if (row.linked) {
      return <Input type="checkbox" disabled defaultChecked={true}></Input>
    } else return <></>
  }

  function basicFormatter(cell, row) {
    if (row.basicUser) {
      return <i className="mdi mdi-check-bold text-primary"></i>
    } else return <>{/* <i className="mdi mdi-close text-light"></i> */}</>
  }

  const rowSelection = {
    selectedRowKeys,
    onSelect: (row, isSelect, rowArray) => {
      if (isSelect) {
        setSelectedRowKeys(prevState => [...prevState, row.name])
      } else {
        setSelectedRowKeys(prevState =>
          prevState.filter(prevItem => prevItem !== row.name)
        )
      }
    },
    onSelectAll: (isSelect, object, rowArray) => {
      if (isSelect) {
        rowArray.map(row =>
          setSelectedRowKeys(prevState => [...prevState, row.name])
        )
      } else {
        setSelectedRowKeys([])
      }
    },
  }

  // Export PDF table
  const exportPDF = () => {
    let message = props.t("PDF file exported succesfully!")
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape
    const theme = "grid" //striped,  plain

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size, theme)

    doc.setFontSize(14)

    const data = filteredUsers.map(elt => [
      elt.name,
      elt.description,
      elt.basicUser,
      elt.groups,
      elt.linked,
      elt.email,
      elt.gsm,
      dateFormatter(elt.lastConnection),
    ])
    console.log(data, "pdf function")

    let content = {
      startY: 50,
      head: [
        [
          props.t("Username"),
          props.t("Description"),
          props.t("Professional"),
          props.t("Groups"),
          props.t("Linked"),
          props.t("Email"),
          props.t("GSM"),
          props.t("Connected"),
        ],
      ],
      body: data,
    }

    doc.text(group, marginLeft, 40)
    doc.autoTable(content)
    doc.save(group + " (" + new Date() + ")" + ".pdf")

    //Show toast
    showToast(message, "success")
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Username"),
        props.t("Description"),
        props.t("Professional"),
        props.t("Groups"),
        props.t("Linked"),
        props.t("Email"),
        props.t("GSM"),
        props.t("Connected"),
      ],
    ]
    const data = filteredUsers.map(elt => [
      elt.name,
      elt.description,
      elt.basicUser,
      elt.groups,
      elt.linked,
      elt.email,
      elt.gsm,
      dateFormatter(elt.lastConnection),
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, group)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, group + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Admin users")} />

          <Row className="mb-4">
            <Col xs="4">
              {/* TAGS */}
              {/* {userRole === "system_admin" && ( */}
              <div className="d-inline-block" style={{ width: "100%" }}>
                <TagsTree
                  loadData={true}
                  expanded={true}
                  selectionChanged={setSelectedTags}
                />
              </div>
              {/* )} */}
            </Col>
            <Col xs="4">
              <SelectGroupFilters
                value={group}
                onChange={setGroup}
                multiple={false}
                selectedTags={selectedTags}
                updateCounter={updateCounter}
              />

              {/* <SelectGroupFilters
                onChange={setGroup}
                multiple={false}
                updateCounter={updateCounter}
              /> */}
            </Col>
            {/* <Col className="col-auto d-flex"> */}
            <Col xs="4">
              <Button
                id="add-button"
                color="success"
                className="square-icon-button-sm"
                disabled={loading}
                onClick={() => {
                  setGroupAddModal(true)
                }}
              >
                <UncontrolledTooltip placement="bottom" target="add-button">
                  {props.t("Create a new group")}
                </UncontrolledTooltip>
                <i className="mdi mdi-account-multiple-plus font-size-18"></i>
              </Button>
              <Button
                id="delete-button"
                color="danger"
                className="square-icon-button-sm ms-3"
                disabled={!group || loading}
                onClick={() => {
                  setGroupDeleteModal(true)
                }}
              >
                <UncontrolledTooltip placement="bottom" target="delete-button">
                  {props.t("Delete group")}
                </UncontrolledTooltip>
                <i className="mdi mdi-trash-can font-size-18"></i>
              </Button>
            </Col>
          </Row>

          {group === null ? (
            <Card>
              <CardBody>
                <div className="text-center my-5">
                  <Lottie
                    options={getLottieSearch()}
                    height={150}
                    width={180}
                  ></Lottie>
                </div>
              </CardBody>
            </Card>
          ) : (
            <div>
              <Card>
                <CardBody>
                  {users != null && (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col className="col-auto pe-0">
                          <Form
                            className="mt-sm-0 d-flex align-items-center"
                            onSubmit={e => {
                              e.preventDefault()
                              return false
                            }}
                          >
                            <div className="search-box me-2 mb-2 d-inline-block float-end">
                              <div className="position-relative">
                                <Input
                                  type="text"
                                  onChange={event =>
                                    setSearchText(
                                      event.target.value.toLowerCase()
                                    )
                                  }
                                  defaultValue={searchText}
                                  placeholder={props.t("Search")}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Form>
                        </Col>
                        <Col className="col-auto">
                          <Button
                            style={{ borderRadius: "100px" }}
                            color="success"
                            onClick={() => {
                              setAddModal(true)
                            }}
                          >
                            <i className="fas fa-user-plus"></i>
                            <span className="d-none d-lg-inline-block ms-2">
                              {props.t("Create a new user")}
                            </span>
                          </Button>
                        </Col>
                        <Col className="mt-2 col-auto">
                          <FormGroup>
                            <Switch
                              //className="me-2"
                              id="showLinkedSwitch"
                              name="showLinked"
                              checked={showLinked}
                              onChange={v => {
                                setShowLinked(v)
                              }}
                              checkedChildren={
                                <div
                                  className="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <i
                                    className="bx bx-link font-size-18"
                                    style={{ marginTop: "2px" }}
                                  />
                                </div>
                              }
                              unCheckedChildren={
                                <div
                                  className="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <i
                                    className="bx bx-link font-size-18"
                                    style={{ marginTop: "2px" }}
                                  />
                                </div>
                              }
                            ></Switch>
                            {/** 
                            <Label>
                              {props.t("Show only linked users")}
                            </Label>*/}
                            <UncontrolledTooltip
                              placement="right"
                              target="showLinkedSwitch"
                            >
                              {props.t("Show only linked users")}
                            </UncontrolledTooltip>
                          </FormGroup>
                        </Col>

                        <Col className="mt-2 col-auto">
                          <FormGroup>
                            <Switch
                              id="fetchUsersGroupsSwitch"
                              name="fetchUsersGroups"
                              checked={fetchUsersGroups}
                              onChange={v => {
                                setFetchUsersGroups(v)
                              }}
                              checkedChildren={
                                <div
                                  className="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <i
                                    className="bx bx-group font-size-18"
                                    style={{ marginTop: "2px" }}
                                  />
                                </div>
                              }
                              unCheckedChildren={
                                <div
                                  className="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <i
                                    className="bx bx-group font-size-18"
                                    style={{ marginTop: "2px" }}
                                  />
                                </div>
                              }
                            ></Switch>
                            <UncontrolledTooltip
                              placement="right"
                              target="fetchUsersGroupsSwitch"
                            >
                              {props.t("Fetch users groups")}
                            </UncontrolledTooltip>
                          </FormGroup>
                        </Col>

                        <Col>
                          <span className="float-end ms-2">
                            <UncontrolledTooltip
                              placement="bottom"
                              target="downloadstatstable"
                            >
                              {props.t("Download statistics")}
                            </UncontrolledTooltip>
                            <Dropdown
                              id="printButtonServicesStats"
                              isOpen={menu}
                              toggle={() => setMenu(!menu)}
                              className="download-btn text-center"
                              type="button"
                            >
                              <DropdownToggle
                                id="downloadstatstable"
                                className="text-muted"
                                tag="i"
                              >
                                <i className="mdi mdi-download" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => downloadExcel()}>
                                  {props.t("Download .xlsx")}
                                </DropdownItem>
                                <DropdownItem onClick={() => exportPDF()}>
                                  {props.t("Download .pdf")}
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                          <span className="mt-2 float-end">
                            <p>
                              {filteredUsers.length} {props.t("users")}
                            </p>
                          </span>
                        </Col>
                      </Row>

                      <Collapse isOpen={selectedRowKeys.length > 0}>
                        <Row className="pb-2">
                          <Col className="col-auto">
                            <Button
                              color="success"
                              disabled={selectedRowKeys.length == 0 || loading}
                              onClick={() => {
                                linkAdmins()
                              }}
                            >
                              <i className="mdi mdi-link-variant-plus me-2" />
                              {props.t("Link selected users")}
                            </Button>
                            <Button
                              color="danger"
                              className="ms-3"
                              disabled={selectedRowKeys.length == 0 || loading}
                              onClick={() => {
                                unlinkAdmins()
                              }}
                            >
                              <i className="mdi mdi-link-variant-off me-2" />
                              {props.t("Unlink selected users")}
                            </Button>
                            <Button
                              color="primary"
                              className="ms-3"
                              onClick={() => {
                                resetSelections()
                              }}
                            >
                              <i className="mdi mdi-select-off me-2" />
                              {props.t("Clear selection")}
                            </Button>
                          </Col>
                          <Col className="mt-2">
                            <p id="selections-text">
                              {selectedRowKeys.length +
                                " " +
                                props.t("selected users")}
                            </p>
                            <UncontrolledTooltip
                              placement="bottom-start"
                              target="selections-text"
                            >
                              {"" + selectedRowKeys.join(", ")}
                            </UncontrolledTooltip>
                          </Col>
                        </Row>
                      </Collapse>

                      {!loading && (
                        <>
                          <div className="mt-1">
                            <AntdTable
                              className="colored-header narrow-header"
                              bordered
                              size="small"
                              sticky
                              rowKey={"name"}
                              columns={columns}
                              dataSource={filteredUsers}
                              rowSelection={rowSelection}
                              pagination={{
                                showSizeChanger: true,
                                defaultPageSize: 100,
                                pageSizeOptions: defaultPageSizeOptions,
                              }}
                              tableLayout="auto"
                              scroll={{
                                x: "max-content",
                                y: tableDefaultHeight,
                              }}
                            />
                          </div>
                        </>
                      )}

                      {loading && (
                        <Lottie
                          options={getLottieLoadingOptions()}
                          height={100}
                          width={100}
                        ></Lottie>
                      )}
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </div>
          )}

          {/**  User add modal */}
          <Modal
            isOpen={addModal}
            toggle={() => {
              setAddModal(!addModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create a new user")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <Alert color={"primary"}>
                  <span>
                    {props.t("User will be linked to currently selected group")}
                  </span>
                </Alert>
                <Alert color={"primary"}>
                  <span>{props.t("UserAllowedChars")}</span>
                </Alert>
                <FormGroup>
                  <Label>{props.t("Username")}</Label>
                  <Input
                    name="username"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    placeholder={"E.g. firstname.lastname"}
                    value={validation.values.username || ""}
                    invalid={
                      !!(
                        validation.touched.username &&
                        validation.errors.username
                      )
                    }
                  ></Input>
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setAddModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingAdd}
                  onClick={() => {
                    validation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>{" "}
              </ModalFooter>
            </Form>
          </Modal>

          {/**  Group add modal */}
          <Modal
            isOpen={groupAddModal}
            toggle={() => {
              setGroupAddModal(!groupAddModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create a new group")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <Alert color={"primary"}>
                  <span>{props.t("GroupAllowedChars")}</span>
                </Alert>
                <FormGroup>
                  <Label>{props.t("Name")}</Label>
                  <Input
                    name="group"
                    type="text"
                    onChange={groupValidation.handleChange}
                    onBlur={groupValidation.handleBlur}
                    value={groupValidation.values.group || ""}
                    invalid={
                      !!(
                        groupValidation.touched.group &&
                        groupValidation.errors.group
                      )
                    }
                  ></Input>
                  {groupValidation.touched.group &&
                  groupValidation.errors.group ? (
                    <FormFeedback type="invalid">
                      {groupValidation.errors.group}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setGroupAddModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingAdd}
                  onClick={() => {
                    groupValidation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>{" "}
              </ModalFooter>
            </Form>
          </Modal>

          {/**  Group delete modal */}
          <Modal
            isOpen={groupDeleteModal}
            toggle={() => {
              setGroupDeleteModal(!groupDeleteModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
            <ModalBody>
              <p>
                {props.t("Are you sure you want to delete") +
                  ": " +
                  group +
                  "?"}
              </p>
              <Alert color={"primary"}>
                <span>
                  {props.t(
                    "Deletion will fail if the group has any users or devices attached to it!"
                  )}
                </span>
              </Alert>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setGroupDeleteModal(false)
                }}
              >
                {props.t("Cancel")}
              </Button>
              <Button
                disabled={ongoingAdd}
                color="danger"
                onClick={() => {
                  groupDelete()
                }}
              >
                {props.t("Delete")}
              </Button>
            </ModalFooter>
          </Modal>

          <AdminDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getAdmins={getAdmins}
          />
        </Container>
      </div>
    </>
  )
}

AdminUsersManagement.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AdminUsersManagement)
