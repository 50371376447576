/***********************
 * ALERTS TABLE
 * (TABLE + IMPORT PDF/XLSX)
 ************************/

import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  Tooltip,
  Input,
} from "reactstrap"
import PropTypes from "prop-types"
import { formatDateFull, showToast, timeFormatter } from "utils/general"

// Datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import "../../datatables.scss"
// Search field
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

//Excel export
import XLSX from "xlsx"

//Autotable PDF
import jsPDF from "jspdf"
import "jspdf-autotable"

//Translation
import { withTranslation } from "react-i18next"

import AlarmFilterReporting from "./alarm-filter-reporting"

const TableAlerts = props => {
  //Table data state
  const [series, setSeries] = useState([])
  const [origSeries, setOrigSeries] = useState([])
  //Dropdown button state
  const [menu, setMenu] = useState(false)
  // Tooltip state for download button
  const [tTdownloadstatstable, setTTdownloadstatstable] = useState(false)
  // Filtered data
  const [filteredData, setFilteredData] = useState(series)
  //
  const [handleValue, setHandleValue] = useState("")

  //Excel and pdf name
  const title = props.t("Alerts")
  // Sort data in table
  const defaultSorted = [
    {
      dataField: "date",
      order: "desc",
    },
  ]
  // Show selected number of rows
  const pageOptions = {
    custom: true,
    sizePerPageList: [
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: props.t("All"), value: filteredData?.length || 0 },
    ],
  }
  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
  ]
  //Setting data state
  useEffect(() => {
    console.log("Data table alerts: ", props.data.list)
    if (props.data.list != filteredData) {
      // format alarmtype for search
      props.data.list.forEach(element => {
        formatEntry(element)
      })
      setOrigSeries(props.data.list)
    }
  }, [props.data.list])

  useEffect(() => {
    // console.log("entries filtered", series)
    if (series != undefined) {
      setFilteredData(series)
      if (handleValue != "") {
        handlingInput(handleValue)
      }
    }
  }, [series])

  // Table structure
  const columns = [
    {
      dataField: "date",
      text: props.t("Time"),
      sort: true,
      formatter: formatDateFull,
    },
    {
      dataField: "group",
      text: props.t("Group"),
      sort: true,
    },
    {
      dataField: "client_name",
      text: props.t("Customer"),
      sort: true,
    },
    {
      dataField: "alarm_type",
      text: props.t("Alarm type"),
      sort: true,
    },
    {
      dataField: "acked_by",
      text: props.t("Handler"),
      sort: true,
    },
    {
      dataField: "closed_by",
      text: props.t("Closed by"),
      sort: true,
    },
    {
      dataField: "ack_time",
      text: props.t("Handling time"),
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: "close_time",
      text: props.t("Closing time"),
      sort: true,
      formatter: timeFormatter,
    },
  ]

  const formatEntry = entry => {
    if (typeof entry.alarm_type === "string") {
      return entry
    } else {
      entry.alarm_type = props.t("AlarmTitles." + entry.alarm_type)
      return entry
    }
  }

  // Export PDF table
  const exportPDF = () => {
    let message = props.t("PDF file exported succesfully!")
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape
    const theme = "grid" //striped,  plain

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size, theme)

    doc.setFontSize(14)

    const data = filteredData.map(elt => [
      formatDateFull(elt.date),
      elt.group,
      elt.client_name,
      elt.alarm_type,
      elt.acked_by,
      elt.closed_by,
      timeFormatter(elt.ack_time),
      timeFormatter(elt.close_time),
    ])

    let content = {
      startY: 50,
      head: [
        [
          props.t("Time"),
          props.t("Group"),
          props.t("Name"),
          props.t("Alarm type"),
          props.t("Handler"),
          props.t("Closed by"),
          props.t("Handling time"),
          props.t("Closing time"),
        ],
      ],
      body: data,
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save(title + ".pdf")
    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Time"),
        props.t("Group"),
        props.t("Name"),
        props.t("Alarm type"),
        props.t("Handler"),
        props.t("Closed by"),
        props.t("Handling time"),
        props.t("Closing time"),
      ],
    ]
    const data = filteredData.map(elt => [
      formatDateFull(elt.date),
      elt.group,
      elt.client_name,
      elt.alarm_type,
      elt.acked_by,
      elt.closed_by,
      timeFormatter(elt.ack_time),
      timeFormatter(elt.close_time),
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  //
  const handlingInput = value => {
    let result = []
    result = series.filter(
      data =>
        data.client_name?.toLowerCase().includes(value) ||
        data.acked_by?.toLowerCase().includes(value) ||
        data.group?.toLowerCase().includes(value) ||
        data.alarm_type?.toLowerCase().includes(value)
    )

    setFilteredData(result)

    // Set original data result if
    if (value == "") {
      setFilteredData(series)
    }
  }

  // Handle search function
  const handleSearch = event => {
    let value = event.target.value.toLowerCase()
    setHandleValue(value)
    handlingInput(value)
  }

  return (
    <React.Fragment>
      <div>
        <Row className=" mt-2 mb-2">
          <div className="d-flex flex-wrap">
            <span>
              <h5 className="mt-1">{props.t("Alarms")}</h5>
            </span>
          </div>
        </Row>
        <Card className="mt-2">
          <CardBody>
            <Col className="col-12">
              <Row>
                <Col lg="4" className="mt-1">
                  <AlarmFilterReporting
                    data={origSeries}
                    setFilteredEntries={setSeries}
                  ></AlarmFilterReporting>
                </Col>
                {filteredData != undefined && (
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    sizePerPage={SizePerPageDropdownStandalone(sizePerPageList)}
                    keyField="id"
                    columns={columns}
                    data={filteredData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={filteredData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Col xl="4" sm="6" className="mt-1">
                              <div className="mt-2">
                                <p>
                                  {filteredData.length}{" "}
                                  {props.t("found results")}
                                </p>
                              </div>
                            </Col>{" "}
                            <Col lg="4" sm="6" className="mt-1">
                              <span className="float-end ms-2">
                                <Tooltip
                                  placement="bottom"
                                  isOpen={tTdownloadstatstable}
                                  target="downloadstatstable"
                                  toggle={() => {
                                    setTTdownloadstatstable(
                                      !tTdownloadstatstable
                                    )
                                  }}
                                >
                                  {props.t("Download")}
                                </Tooltip>
                                <Dropdown
                                  id="printButtonServicesStats"
                                  isOpen={menu}
                                  toggle={() => setMenu(!menu)}
                                  className="download-btn text-center"
                                  type="button"
                                >
                                  <DropdownToggle
                                    id="downloadstatstable"
                                    className="text-muted"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-download" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {/* TODO: Can not choose directory file right now */}
                                    <DropdownItem
                                      onClick={() => downloadExcel()}
                                    >
                                      {props.t("Download .xlsx")}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => exportPDF()}>
                                      {props.t("Download .pdf")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </span>
                              <Form className="mt-sm-0 d-flex float-end">
                                <div className="search-box me-2 mb-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      onChange={event => handleSearch(event)}
                                      placeholder={props.t("Filter")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive mt-2  table-scroll-window">
                                  <BootstrapTable
                                    id="services-table"
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    pagination={sizePerPageList}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-3 mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="">
                                  <SizePerPageDropdownStandalone
                                    pagination={sizePerPageList}
                                    {...paginationProps}
                                  />
                                </div>
                                <Col className="pagination justify-content-end inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                )}
              </Row>{" "}
            </Col>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

TableAlerts.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  data: PropTypes.any,
  setFilteredEntries: PropTypes.any,
}

export default withTranslation()(TableAlerts)
