// Suvanto API
export const PUBLIC_DB_URL =
  "https://europe-west3-suvanto-care.cloudfunctions.net/public-mysql"
export const DB_URL =
  "https://europe-west3-suvanto-care.cloudfunctions.net/react-mysql"
export const DB_TEST_URL =
  "https://europe-west3-suvanto-care.cloudfunctions.net/react-mysql-test"
export const API_URL =
  "https://europe-west3-suvanto-care.cloudfunctions.net/suvanto-front"
// export const API_URL =
//   "https://europe-west3-suvanto-care.cloudfunctions.net/suvanto-front-beta"
export const LOGIN = "/Thingworx/Things/UserSession/Services/Login"
export const LOGOUT = "/Thingworx/Things/UserSession/Services/Logout"
export const GET_USER_INFO =
  "/Thingworx/Things/UserSession/Services/GetUserInfo"
export const CHANGE_PASSWORD =
  "/Thingworx/Things/UserSession/Services/ChangePassword"
export const UPDATE_USER_PROPS =
  "/Thingworx/Things/UserSession/Services/UpdateUserProperties"

// General
export const GET_GROUP_LIST =
  "/Thingworx/Things/SuvantoObjects/Services/GetGroupsListed"
export const GET_CLIENT_LIST =
  "/Thingworx/Things/SuvantoObjects/Services/GetEntriesListJson"
export const GET_CLIENT_TABLE =
  "/Thingworx/Things/SuvantoObjects/Services/GetEntriesTableJson"
export const GET_CLIENT_DATA =
  "/Thingworx/Things/SuvantoObjects/Services/GetEntryByKeyJson"
export const GET_GROUPS = "/Thingworx/Things/UserSession/Services/GetGroups"
export const UPDATE_CLIENT =
  "/Thingworx/Things/SuvantoObjects/Services/UpdateClient"
export const DELETE_CLIENT =
  "/Thingworx/Things/SuvantoObjects/Services/DeleteSuvantoObject"
export const CREATE_CLIENT =
  "/Thingworx/Things/SuvantoObjects/Services/CreateClient"
export const DELETE_CLIENT_DATA =
  "/Thingworx/Things/SuvantoObjects/Services/ClearClientData"
export const CLIENTS_TRANSFER_GROUP =
  "/Thingworx/Things/SuvantoObjects/Services/MoveToGroup"

export const GET_AVAILABLE_TAGS =
  "/Thingworx/Things/GroupTags/Services/GetAvailableTags"
export const GET_GROUP_SETTINGS =
  "/Thingworx/Things/GroupSettings/Services/GetGroupSettingsJson"
export const UPDATE_GROUP_SETTINGS =
  "/Thingworx/Things/GroupSettings/Services/UpdateSettings"
export const SET_TAG_TO_GROUPS =
  "/Thingworx/Things/GroupSettings/Services/SetTagToGroups"
export const REMOVE_TAG_GROUPS =
  "/Thingworx/Things/GroupSettings/Services/RemoveTagFromGroups"

// Journal
export const GET_JOURNAL =
  "/Thingworx/Things/SuvantoJournal/Services/GetJournalEntries"
export const ADD_JOURNAL_MESSAGE =
  "/Thingworx/Things/SuvantoJournal/Services/AddMessage"
export const GET_JOURNAL_RESPONSES =
  "/Thingworx/Things/SuvantoJournal/Services/GetResponsesJson"
export const ADD_JOURNAL_RESPONSE =
  "/Thingworx/Things/SuvantoJournal/Services/AddResponse"
export const GET_JOURNAL_ENTRIES =
  "/Thingworx/Things/SuvantoJournal/Services/GetJournalEntriesJson"
export const ADD_VIDEO_MESSAGE =
  "/Thingworx/Things/SuvantoJournal/Services/SendVideoPushMessageJson"
export const ADD_MANUAL_MEASURE =
  "/Thingworx/Things/HealthData/Services/AddManualMeasurementJson"
export const DELETE_JOURNAL_ENTRIES =
  "/Thingworx/Things/SuvantoJournal/Services/DeleteEntries"
export const EDIT_JOURNAL_ENTRY =
  "/Thingworx/Things/SuvantoJournal/Services/EditMessage"

export const END_ONGOING_VISIT =
  "/Thingworx/Things/SuvantoJournal/Services/EndVisit"
export const GET_JOURNAL_FILE_DOWNLOAD_LINK =
  "/Thingworx/Things/SuvantoJournal/Services/GetFileDownloadLink"
export const DOWNLOAD_JOURNAL_BLOB =
  "/Thingworx/Things/SuvantoJournal/Services/DownloadBlob"
export const UPLOAD_JOURNAL_BLOB =
  "/Thingworx/Things/SuvantoJournal/Services/AddAttachment"

// Alarms
export const GET_ALARMS =
  "/Thingworx/Things/SuvantoAlarms/Services/GetAlarmsJson"
export const ACK_ALARMS = "/Thingworx/Things/SuvantoAlarms/Services/AckAlarms"
export const RESOLVE_ALARMS =
  "/Thingworx/Things/SuvantoAlarms/Services/ResolveAlarms"
export const GET_MONITORING_ALARMS =
  "/Thingworx/Things/SuvantoAlarms/Services/GetMonitoringAlarms"
export const GET_MONITORING_HANDOVER_ALARMS =
  "/Thingworx/Things/SuvantoAlarms/Services/GetHandoverAlarmsJson"
export const DELETE_ALARMS =
  "/Thingworx/Things/SuvantoAlarms/Services/DeleteEntries"

// Home
export const GET_GATEWAY_DATA =
  "/Thingworx/Things/SuvantoGateways/Services/GetEntryByKeyJson"
export const GET_COMBINED_ACTIVITY =
  "/Thingworx/Things/SuvantoActivityProfiles/Services/GetActivityDataJson"
export const GET_NODE_VALUES =
  "/Thingworx/Things/SuvantoNodeValues/Services/GetNodeValues"
export const GET_COMBINED_NODE_VALUES =
  "/Thingworx/Things/SuvantoNodeValues/Services/GetCombinedNodeValues"
export const GET_NODE_LONGPERIOD_DATA =
  "/Thingworx/Things/SuvantoNodeValues/Services/GetLongPeriodData"
export const GET_STATE_CHART_DATA =
  "/Thingworx/Things/SuvantoNodeValues/Services/GetStateChartData"
export const GET_ACTIVITY_TREND =
  "/Thingworx/Things/SuvantoActivityProfiles/Services/GetActivityTrend"
export const DELETE_ACTIVITY_HISTORY =
  "/Thingworx/Things/SuvantoGateways/Services/DeleteGatewayHistory"

// Gateways and nodes
export const GET_GATEWAYS =
  "/Thingworx/Things/SuvantoGateways/Services/GetGatewaysJson"
export const GET_GATEWAY =
  "/Thingworx/Things/SuvantoGateways/Services/GetGatewayJson"
export const GET_NODE_LIST =
  "/Thingworx/Things/SuvantoNodes/Services/GetNodesJson"
export const CREATE_GATEWAY =
  "/Thingworx/Things/SuvantoGateways/Services/CreateNewGateway"
export const DELETE_GATEWAY =
  "/Thingworx/Things/SuvantoGateways/Services/SafeDeleteGatewayByKey"
export const RESET_GATEWAY =
  "/Thingworx/Things/SuvantoGateways/Services/ResetGateway"
export const UPDATE_GATEWAY =
  "/Thingworx/Things/SuvantoGateways/Services/UpdateGatewayJson"
export const REBOOT_GATEWAY =
  "/Thingworx/Things/SuvantoGateways/Services/RebootGatewayBySms"
export const GATEWAY_REVERSE_SSH =
  "/Thingworx/Things/SuvantoGateways/Services/StartReverseSsh"
export const GET_GATEWAY_LIST =
  "/Thingworx/Things/SuvantoGateways/Services/GetGatewayList"
export const TRANSFER_GATEWAY =
  "/Thingworx/Things/SuvantoGateways/Services/TransferGatewayJson"
export const TRANSFER_GATEWAY_CONTENT =
  "/Thingworx/Things/SuvantoGateways/Services/TransferGatewayContent"
export const SET_GW_INSTALLATION_SERIALS =
  "/Thingworx/Things/SuvantoGateways/Services/SetInstallationSerials"
export const CREATE_NODE = "/Thingworx/Things/SuvantoNodes/Services/AddNode"
export const DELETE_NODES =
  "/Thingworx/Things/SuvantoNodes/Services/DeleteSelectedNodes"
export const RESET_NODES = "/Thingworx/Things/SuvantoNodes/Services/ResetNodes"
export const UPDATE_NODE =
  "/Thingworx/Things/SuvantoNodes/Services/UpdateNodeJson"
export const IMPORT_GATEWAYS =
  "/Thingworx/Things/SuvantoGateways/Services/ImportGateways"
export const CREATE_VIRTUAL_GATEWAY =
  "/Thingworx/Things/SuvantoGateways/Services/CreateVirtualGateway"

// Tracker
export const GET_TRACKER_LIST =
  "/Thingworx/Things/GpsTrackers/Services/GetEntriesListJson"
export const GET_TRACKER_DATA =
  "/Thingworx/Things/GpsTrackers/Services/GetTrackerData"
export const GET_TRACKER_LOCATIONS =
  "/Thingworx/Things/TrackerLocations/Services/GetLocationsSorted"
export const GET_TRACKER_EVENTS =
  "/Thingworx/Things/TrackerEvents/Services/GetTrackerEventsJson"
export const GET_TRACKERS =
  "/Thingworx/Things/GpsTrackers/Services/GetTrackersJson"
export const UPDATE_TRACKER =
  "/Thingworx/Things/GpsTrackers/Services/UpdateTrackerJson"
export const DELETE_TRACKER =
  "/Thingworx/Things/GpsTrackers/Services/DeleteTracker"
export const ADD_TRACKER = "/Thingworx/Things/GpsTrackers/Services/AddTracker"
export const TRANSFER_TRACKER =
  "/Thingworx/Things/GpsTrackers/Services/TransferTrackerJson"
export const IMPORT_TRACKERS =
  "/Thingworx/Things/GpsTrackers/Services/ImportTrackersFromJson"
export const DELETE_TRACKER_HISTORY =
  "/Thingworx/Things/GpsTrackers/Services/DeleteTrackerHistory"

export const LOCATE_TRACKER =
  "/Thingworx/Things/GpsTracking/Services/QueryLocationJson"
export const QUERY_TRACKER_STATUS =
  "/Thingworx/Things/GpsTracking/Services/QuerySettingsJson"
export const SET_TRACKER_FALL =
  "/Thingworx/Things/GpsTracking/Services/SetFallDetectionJson"
export const SET_TRACKER_GEOFENCE =
  "/Thingworx/Things/GpsTracking/Services/ChangeGeofence"

export const GET_NAVIGIL_SETTINGS =
  "/Thingworx/Things/Navigil/Services/GetDeviceSettingsJson"
export const SET_NAVIGIL_SETTINGS =
  "/Thingworx/Things/Navigil/Services/SetDeviceSettingsJson"
export const UPDATE_NAVIGIL_DATA =
  "/Thingworx/Things/Navigil/Services/FetchAndUpdateData"

// Carephone
export const GET_CAREPHONES =
  "/Thingworx/Things/Carephones/Services/GetCarephones"
export const GET_CAREPHONE_DATA =
  "/Thingworx/Things/Carephones/Services/GetCarephoneDataJson"
export const SYNC_CAREPHONE =
  "/Thingworx/Things/Carephones/Services/SyncDeviceData"
export const GET_CAREPHONE_ALARMS =
  "/Thingworx/Things/Carephones/Services/GetCarephoneAlarms"
export const DELETE_CAREPHONE =
  "/Thingworx/Things/Carephones/Services/DeleteEntry"
export const UPDATE_CAREPHONE =
  "/Thingworx/Things/Carephones/Services/UpdateCarephone"
export const SYNC_CAREPHONES =
  "/Thingworx/Things/Carephones/Services/SyncDeviceList"
export const GET_CAREPHONE_LIST =
  "/Thingworx/Things/Carephones/Services/GetCarephoneList"
export const TRANSFER_CAREPHONE =
  "/Thingworx/Things/Carephones/Services/TransferCarephoneJson"
export const GET_CAREPHONE_ALARM_LOGS =
  "/Thingworx/Things/Carephones/Services/GetAlarmLogs"

// Smartlock
export const GET_SMARTLOCK_DATA =
  "/Thingworx/Things/Pindoras/Services/GetPindoraJson"
export const OPEN_SMARTLOCK = "/Thingworx/Things/Pindoras/Services/OpenDoor"
export const GET_SMARTLOCKS = "/Thingworx/Things/Pindoras/Services/GetPindoras"
export const SYNC_SMARTLOCK = "/Thingworx/Things/Pindoras/Services/SyncDevice"
export const SYNC_SMARTLOCKS = "/Thingworx/Things/Pindoras/Services/SyncDevices"
export const UPDATE_SMARTLOCK =
  "/Thingworx/Things/Pindoras/Services/UpdateDevice"
export const DELETE_SMARTLOCK =
  "/Thingworx/Things/Pindoras/Services/DeletePindora"
export const GET_SMARTLOCK_LIST =
  "/Thingworx/Things/Pindoras/Services/GetPindoraList"
export const TRANSFER_SMARTLOCK =
  "/Thingworx/Things/Pindoras/Services/TransferPindoraJson"

// Video
export const GET_VIDEO_STATUS =
  "/Thingworx/Things/VideoDevices/Services/GetDeviceStatus"
export const GET_GROUPVIDEO_CONFIG =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/GetGroupClientConfigJson"
export const GET_VIDEOCALL =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/GetVideoCallToken"
export const GET_VIDEOS_WITH_SETTINGS =
  "/Thingworx/Things/VideoDevices/Services/GetVideosWithSettings"
export const SEND_VIDEO_APP_SETTINGS =
  "/Thingworx/Things/VideoDevices/Services/SendVideoAppSettings"
export const GET_VIDEO_CLIENTS =
  "/Thingworx/Things/VideoRooms/Services/GetVideoClients"
export const SEND_VIDEO_BULK_MESSAGE =
  "/Thingworx/Things/SuvantoJournal/Services/SendVideoPushMessages"
export const GET_VIDEO_BULK_MESSAGE_LOGS =
  "/Thingworx/Things/VideoBulkMessages/Services/GetRecentLogs"
export const GET_GROUPCALL_GUESTS =
  "/Thingworx/Things/VideoRooms/Services/GetGroupCallClients"
export const GET_GROUPCALL_FILTERS =
  "/Thingworx/Things/UserFilters/Services/GetGroupCallFilters"
export const ADD_GROUPCALL_FILTER =
  "/Thingworx/Things/UserFilters/Services/AddGroupCallFilter"
export const EDIT_GROUPCALL_FILTER =
  "/Thingworx/Things/UserFilters/Services/EditGroupCallFilter"
export const DELETE_GROUPCALL_FILTER =
  "/Thingworx/Things/UserFilters/Services/DeleteGroupCallFilter"
export const GET_VIDEOROOMS =
  "/Thingworx/Things/VideoRooms/Services/GetVideoRoomsJson"
export const UPDATE_VIDEO_ROOM =
  "/Thingworx/Things/VideoRooms/Services/UpdateRoom"
export const UPDATE_VIDEO_CLIENT =
  "/Thingworx/Things/VideoRooms/Services/UpdateClient"
export const DELETE_VIDEO_CLIENT =
  "/Thingworx/Things/VideoRooms/Services/DeleteRoom"
export const REGISTER_VIDEO_CLIENTS =
  "/Thingworx/Things/VideoRooms/Services/RegisterClients"

export const GET_VIDEOROOM_LOGIN =
  "/Thingworx/Things/VideoRooms/Services/GetLoginJson"
export const GET_VIDEODEVICES =
  "/Thingworx/Things/VideoDevices/Services/GetVideoDevicesJson"
export const ADD_VIDEODEVICES =
  "/Thingworx/Things/VideoDevices/Services/ImportDevices"
export const UPDATE_VIDEO_DEVICE =
  "/Thingworx/Things/VideoDevices/Services/UpdateDevice"
export const DELETE_VIDEODEVICE =
  "/Thingworx/Things/VideoDevices/Services/DeleteVideoDevice"
export const VIDEO_SEND_PUSH =
  "/Thingworx/Things/VideoDevices/Services/SendPushCommand"
export const VIDEO_GET_EXTRAS =
  "/Thingworx/Things/VideoDevices/Services/GetVideoExtras"
export const VIDEO_FORCE_REFRESH =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/ForceRefreshClient"
export const REBOOT_VIDEO_DEVICE =
  "/Thingworx/Things/VideoDevices/Services/RebootDevice"
export const VIDEO_GET_CLIENT_STATUS =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/GetClientStatus"
export const GET_VIDEO_DEVICE_LIST =
  "/Thingworx/Things/VideoDevices/Services/GetVideoDeviceList"
export const GET_VIDEO_ROOM_LIST =
  "/Thingworx/Things/VideoRooms/Services/GetVideoRoomList"
export const TRANSFER_VIDEOS =
  "/Thingworx/Things/VideoDevices/Services/TransferVideoJson"
export const VIDEO_SET_IGNORE_ALARMS =
  "/Thingworx/Things/VideoDevices/Services/SetIgnoreAlarms"

export const GET_OFFLINE_VIDEOS =
  "/Thingworx/Things/VideoRooms/Services/GetOfflineVideos"
export const HANDLE_OFFLINE_VIDEO =
  "/Thingworx/Things/VideoRooms/Services/HandleOfflineTicket"
export const GET_OFFLINE_VIDEOS_CHART =
  "/Thingworx/Things/OfflineVideosStream/Services/GetChartData"
export const GET_OFFLINE_VIDEO_HISTORY =
  "/Thingworx/Things/OfflineVideosStream/Services/GetHistoryData"

export const GET_VIDEO_DEVICE_STATUS_HISTORY =
  "/Thingworx/Things/VideoDeviceStatusStream/Services/GetEntriesForDevice"

export const GET_GROUP_CALENDAR_EVENTS =
  "/Thingworx/Things/VideoRooms/Services/GetGroupCalendarEvents"
export const GET_AC_GROUPS_TREE =
  "/Thingworx/Things/VideoRooms/Services/GetGroupsTree"
export const GET_VIDEO_CALENDAR_EVENTS_BY_GROUP =
  "/Thingworx/Things/VideoRooms/Services/GetCalendarEventsByGroup"
export const AC_CANCEL_CALENDAR_EVENT =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/CancelCalendarEvent"
export const AC_GET_RECORDINGS =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/GetRecordingList"
export const AC_GET_VIRTUAL_ROOM_LIST =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/GetVirtualMeetingRoomList"
export const AC_CREATE_UPDATE_RECORDING =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/CreateOrUpdateRecording"
export const AC_DELETE_RECORDING =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/DeleteRecording"
export const AC_DELETE_CALENDAR_EVENT =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/DeleteCalendarEvent"
export const AC_DELETE_VIRTUAL_ROOM =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/DeleteVirtualMeetingRoom"
export const AC_CREATE_UPDATE_VIRTUAL_ROOM =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/CreateVirtualMeetingRoom"
export const AC_CREATE_UPDATE_EVENT =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/CreateOrUpdateCalendarEvent"

export const AC_CREATE_UPDATE_GROUP =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/CreateOrUpdateGroup"
export const AC_DELETE_GROUP =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/DeleteGroup"
export const AC_GET_GROUP_USERS =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/GetGroupUsers"
export const AC_UPDATE_MULTIPLE_GROUP_USERS =
  "/Thingworx/Things/ArcticConnectWebrtcApi/Services/UpdateMultipleGroupUsers"

// Scheduled messages
export const GET_SCHEDULED_MESSAGES =
  "/Thingworx/Things/ScheduledMessages/Services/GetScheduledMessages"
export const DELETE_SCHEDULED_MESSAGE =
  "/Thingworx/Things/ScheduledMessages/Services/DeleteMessage"
export const ADD_OR_UPDATE_SCHEDULED_MESSAGE =
  "/Thingworx/Things/ScheduledMessages/Services/AddOrUpdateEntry"

// Alarm center & SMS alarms
export const GET_ALARM_CENTER_SETTINGS =
  "/Thingworx/Things/AlarmSelections/Services/GetAlarmCenterSettings"
export const UPDATE_ALARM_CENTER_SETTINGS =
  "/Thingworx/Things/AlarmSelections/Services/UpdateAlarmCenterSettings"
export const GET_CLIENTS_SMS_ALARM_CONFIGS =
  "/Thingworx/Things/SuvantoObjects/Services/GetSmsAlarmConfigs"
export const UPDATE_CLIENTS_SMS_ALARM_CONFIGS =
  "/Thingworx/Things/SuvantoObjects/Services/UpdateSmsAlarmConfigs"

// Dose
export const GET_DOSE_CONFIG =
  "/Thingworx/Things/DoseSystemApi/Services/GetConfigurationJson"
export const UPDATE_DOSE_CONFIG =
  "/Thingworx/Things/DoseSystemApi/Services/UpdateConfigJson"
export const GET_DOSES = "/Thingworx/Things/DoseCans/Services/GetDoses"
export const SYNC_DOSES = "/Thingworx/Things/DoseCans/Services/SyncDevices"
export const UPDATE_DOSE = "/Thingworx/Things/DoseCans/Services/UpdateDose"
export const SYNC_DOSE_CONFIG =
  "/Thingworx/Things/DoseCans/Services/SyncConfiguration"
export const RESET_DOSE = "/Thingworx/Things/DoseCans/Services/ResetEntry"
export const ADD_DOSE =
  "/Thingworx/Things/DoseSystemApi/Services/AddCitizenJson"
export const REMOVE_DOSE =
  "/Thingworx/Things/DoseSystemApi/Services/CloseCitizenJson"
export const REPLACE_DOSE =
  "/Thingworx/Things/DoseSystemApi/Services/AssignNewUnitToCitizenJson"

// Health measurements
export const GET_HEALTH_MEASUREMENTS =
  "/Thingworx/Things/HealthData/Services/GetMeasurementsJson"

// Alarm settings
export const GET_ALARM_SETTINGS =
  "/Thingworx/Things/SuvantoAlarmSetups/Services/GetAlarmSetupsJson"
export const DELETE_ALARM_SETTING =
  "/Thingworx/Things/SuvantoAlarmSetups/Services/DeleteAlarmSetup"
export const ADD_OR_UPDATE_ALARM_SETTING =
  "/Thingworx/Things/SuvantoAlarmSetups/Services/AddOrUpdateSetup"

// Measuring devices
export const GET_MEASURING_DEVICES =
  "/Thingworx/Things/MeasuringDevices/Services/GetMeasuringDevices"
export const DELETE_MEASURING_DEVICE =
  "/Thingworx/Things/MeasuringDevices/Services/DeleteEntry"
export const UPDATE_MEASURING_DEVICE =
  "/Thingworx/Things/MeasuringDevices/Services/UpdateDevice"
export const ADD_MEASURING_DEVICE =
  "/Thingworx/Things/MeasuringDevices/Services/AddDevice"

// App users
export const GET_APP_USERS =
  "/Thingworx/Things/SuvantoUsers/Services/GetAppUsersJson"
export const GET_APP_USER = "/Thingworx/Things/SuvantoUsers/Services/GetAppUser"
export const CREATE_APP_USER =
  "/Thingworx/Things/SuvantoUsers/Services/CreateUser"
export const UPDATE_APP_USER =
  "/Thingworx/Things/SuvantoUsers/Services/UpdateUser"
export const DELETE_APP_USERS =
  "/Thingworx/Things/SuvantoUsers/Services/DeleteUsers"
export const SEND_APP_USER_CREDENTIALS =
  "/Thingworx/Things/SuvantoUsers/Services/SendLoginCredentials"
export const LINK_APP_USERS_TO_CLIENT =
  "/Thingworx/Things/SuvantoObjectUserLinks/Services/LinkUsersToClient"
export const UNLINK_APP_USERS_FROM_CLIENT =
  "/Thingworx/Things/SuvantoObjectUserLinks/Services/UnlinkUsersFromClient"
export const GET_APP_USERS_LINKS =
  "/Thingworx/Things/SuvantoObjectUserLinks/Services/GetUsersLinksJson"
export const LINK_CLIENTS_TO_APP_USER =
  "/Thingworx/Things/SuvantoObjectUserLinks/Services/LinkClientsToUser"
export const UNLINK_CLIENTS_FROM_APP_USER =
  "/Thingworx/Things/SuvantoObjectUserLinks/Services/UnlinkClientsFromUser"

// Reporting
export const GET_SERVICE_STATS =
  "/Thingworx/Things/StatisticsServices/Services/GetServiceStatsJson"
export const GET_DEVICE_STATS =
  "/Thingworx/Things/StatisticsServices/Services/GetDeviceStatsJson"
export const GET_ALARM_STATS =
  "/Thingworx/Things/StatisticsServices/Services/GetAlarmStatsJson"
export const GET_VISIT_STATS =
  "/Thingworx/Things/StatisticsServices/Services/GetVisitStatsJson"
export const GET_CALL_STATS =
  "/Thingworx/Things/StatisticsServices/Services/GetCallStatsJson"
export const GET_MEASUREMENT_STATS =
  "/Thingworx/Things/StatisticsServices/Services/GetMeasurementStatsJson"
export const GET_OPEN_REQUESTS =
  "/Thingworx/Things/StatisticsServices/Services/GetOpenRequests"
export const GET_CLIENT_SERVICE_COUNTS =
  "/Thingworx/Things/StatisticsServices/Services/GetClientServiceStats"
export const GET_GROUP_CALL_STATS =
  "/Thingworx/Things/StatisticsServices/Services/GetGroupCallStatsJson"
export const GET_GROUP_LICENSE_STATS =
  "/Thingworx/Things/StatisticsServices/Services/GetVideoAccountStats"
export const GET_GROUPCALL_LICENSE_STATS =
  "/Thingworx/Things/StatisticsServices/Services/GetVideoLicenseStats"

// Orders
export const GET_ORDERS = "/Thingworx/Things/SuvantoOrders/Services/GetOrders"
export const GET_ORDER = "/Thingworx/Things/SuvantoOrders/Services/GetOrder"
export const QUERY_ORDER_DEVICES =
  "/Thingworx/Things/SuvantoOrders/Services/QueryDevicesJson"
export const UPDATE_ORDER =
  "/Thingworx/Things/SuvantoOrders/Services/UpdateOrderJson"
export const CREATE_ORDER =
  "/Thingworx/Things/SuvantoOrders/Services/CreateOrder"

// Logs
export const GET_SYSTEM_LOGS =
  "/Thingworx/Things/UserSession/Services/GetSystemLogs"
export const GET_FEEDBACK_LOGS =
  "/Thingworx/Things/FeedbackStream/Services/QueryFeedback"
export const MARK_FEEDBACK_READ =
  "/Thingworx/Things/FeedbackStream/Services/MarkEntryAsRead"
export const GET_GATEWAY_LOGS =
  "/Thingworx/Things/GatewayEventStream/Services/QueryEvents"
export const GET_AUDIT_LOGS =
  "/Thingworx/Things/ServerEventStream/Services/QueryLogs"

// SMS
export const GET_SMS_WITH_NUMBER =
  "/Thingworx/Things/SmsMessages/Services/GetMessagesWithNumber"
export const SEND_SMS = "/Thingworx/Things/MessagingPlatform/Services/SendSms"
export const GET_SECURITY_SMS =
  "/Thingworx/Things/SmsMessages/Services/GetSupportMessagesJson"
export const QUERY_SMS =
  "/Thingworx/Things/SmsMessages/Services/QueryMessagesJson"

// Admin users and groups
export const GET_ADMIN_USERS =
  "/Thingworx/Things/SuvantoMashupServices/Services/GetAdminsJson"

export const GET_MANAGED_ADMIN_USERS =
  "/Thingworx/Things/FrontServices/Services/GetManagedAdminUsers"
export const GET_ADMIN_REQUESTS =
  "/Thingworx/Things/AdminRequests/Services/GetPersonalRequests"
export const NEW_ADMIN_REQUEST =
  "/Thingworx/Things/AdminRequests/Services/NewAdminRequest"

export const GET_ADMIN_INFO =
  "/Thingworx/Things/SuvantoMashupServices/Services/GetAdminsInfo"
export const CREATE_ADMIN =
  "/Thingworx/Things/SuvantoMashupServices/Services/CreateAdmin"
export const DELETE_ADMIN =
  "/Thingworx/Things/SuvantoMashupServices/Services/DeleteThingworxUser"
export const LINK_ADMIN =
  "/Thingworx/Things/SuvantoMashupServices/Services/AddAdminsToGroup"
export const UNLINK_ADMIN =
  "/Thingworx/Things/SuvantoMashupServices/Services/RemoveAdminsFromGroup"
export const LINK_GROUPS_TO_ADMIN =
  "/Thingworx/Things/SuvantoMashupServices/Services/AddGroupsToAdmin"
export const UNLINK_GROUPS_FROM_ADMIN =
  "/Thingworx/Things/SuvantoMashupServices/Services/RemoveGroupsFromAdmin"
export const LIST_ADMIN_GROUPS =
  "/Thingworx/Things/FrontServices/Services/GetAdminGroups"

export const UPDATE_ADMIN =
  "/Thingworx/Things/SuvantoMashupServices/Services/UpdateAdmin"
export const DELETE_GROUP =
  "/Thingworx/Things/SuvantoMashupServices/Services/DeleteGroupJson"
export const CREATE_GROUP =
  "/Thingworx/Things/SuvantoMashupServices/Services/CreateGroupJson"
export const SEND_LOGIN_CREDENTIALS =
  "/Thingworx/Things/SuvantoMashupServices/Services/SendLoginCredentialsJson"
export const GET_ADMINS_GROUPS =
  "/Thingworx/Things/SuvantoMashupServices/Services/GetAdminsGroups"
export const GET_ADMINS_PRIVATE_CLIENTS =
  "/Thingworx/Things/SuvantoAdminObjectLinks/Services/GetLinkedClients"
export const LINK_PRIVATE_CLIENTS =
  "/Thingworx/Things/SuvantoAdminObjectLinks/Services/AddLinks"
export const UNLINK_PRIVATE_CLIENTS =
  "/Thingworx/Things/SuvantoAdminObjectLinks/Services/RemoveLinks"
export const DISABLE_ADMIN =
  "/Thingworx/Things/FrontServices/Services/DisableUser"

// Tags
export const ADD_TAG = "/Thingworx/Things/GroupTags/Services/AddTag"
export const RENAME_TAG = "/Thingworx/Things/GroupTags/Services/RenameTag"
export const DELETE_TAG = "/Thingworx/Things/GroupTags/Services/DeleteTag"
export const GET_TAGS_USERS =
  "/Thingworx/Things/GroupTags/Services/GetTagsUsers"

// Organizations
export const GET_ORGANIZATIONS =
  "/Thingworx/Things/OrganizationCodes/Services/GetOrganizations"

// Appointments
export const GET_GROUPS_APPOINTMENTS =
  "/Thingworx/Things/Appointments/Services/GetAppointments"
export const DELETE_APPOINTMENT =
  "/Thingworx/Things/Appointments/Services/DeleteAppointment"
export const ADD_OR_UPDATE_APPOINTMENT =
  "/Thingworx/Things/Appointments/Services/AddOrUpdateAppointment"
export const UPDATE_APPOINTMENTS =
  "/Thingworx/Things/Appointments/Services/UpdateAppointments"

// Misc
export const TELIA_SIM_STATUS =
  "/Thingworx/Things/ExternalSystems/Services/TeliaSimStatusJson"
export const SIM_ACTION = "/Thingworx/Things/FrontServices/Services/SimAction"
export const GEOCODE_GET_COORDINATES =
  "/Thingworx/Things/GoogleCloudPlatform/Services/GeocodeGetCoordinatesJson"
export const GENERATE_ALARM_CENTER_ID =
  "/Thingworx/Things/HelperServices/Services/GenerateAlarmCenterId"
export const SET_SUPPORT_TO_USERS =
  "/Thingworx/Things/HelperServices/Services/SetSupportTypeForGroupMembers"
export const SEND_EMAIL =
  "/Thingworx/Things/SuvantoSupportMail/Services/SendMessage"
export const DOWNLOAD_USER_MANUAL =
  "/Thingworx/Things/FrontServices/Services/DownloadUserManual"
export const UPLOAD_USER_MANUAL =
  "/Thingworx/Things/FrontServices/Services/UploadUserManual"
