/***********************
 * INDEX TAB4 CONTENT
 * (GROUPVIDEOCALLS)
 ************************/

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"

// Locale
import { withTranslation } from "react-i18next"

// Importing components
import TableCalls from "./components/calls-table"
import ChartCalls from "./components/calls-chart"

const GroupVideoCallsTabReporting = props => {
  const [callsData, setCallsData] = useState([])
  // Passing customers data to cards/stats
  const [customers, setCustomers] = useState()

  // Filter view related states
  const [activeTab] = useState("1")

  useEffect(() => {
    if (props.activeTab == 4) {
      //console.log("Group calls data changed: " + JSON.stringify(props.data))
      setCallsData(props.data)
      setCustomers(props.customers)
    }
  }, [props.data, props.activeTab])

  useEffect(() => {
    console.log("group calls active")
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col>
          {activeTab == "1" && props.data !== undefined && (
            <div className="mt-2 mb-1">
              <Row>
                <ChartCalls
                  // selectedType={props.selectedType}
                  customers={customers}
                  data={callsData}
                  start={props.start}
                  end={props.end}
                ></ChartCalls>
              </Row>
              <Row>
                <TableCalls
                  minRows={0}
                  selectedType={props.selectedType}
                  data={callsData}
                />
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

GroupVideoCallsTabReporting.propTypes = {
  data: PropTypes.any,
  customers: PropTypes.any,
  activeTab: PropTypes.any,
  selectedType: PropTypes.any,
  start: PropTypes.any,
  end: PropTypes.any,
}

export default withTranslation()(GroupVideoCallsTabReporting)
