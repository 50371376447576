import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Drawer, Switch } from "antd"
import {
  deleteTracker,
  teliaSimStatus,
  updateNavigilData,
  updateTracker,
} from "helpers/backend_helper"
import * as Yup from "yup"
import { useFormik } from "formik"
import SelectOrder from "components/DropdownList/SelectOrder"
import OnTheGoServices from "pages/Services/on-the-go/on-the-go"
import { showToast } from "utils/general"
import NavigilSettings from "./navigil-configs"
import TrackerSmsTab from "./sms-tab"
import SelectGroup from "components/DropdownList/SelectGroup"
import withRouter from "components/Common/withRouter"
import SimStatusDropdown from "components/Misc/SimStatusDropdown"

const TrackerDrawer = props => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [update, setUpdate] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [ongoingAction, setOngoingAction] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [simDropdownOpen, setSimDropdownOpen] = useState(false)
  const [activeTab, setActiveTab] = useState("1")

  const [renderSmsTab, setRenderSmsTab] = useState(false)
  const [renderClientTab, setRenderClientTab] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Update client validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderNumber: props.selectedRow?.orderNumber || "",
      name: props.selectedRow?.name || "",
      group: props.selectedRow?.group || "",
      gsm: props.selectedRow?.gsm || "",
      imei: props.selectedRow?.imei || "",
      imsi: props.selectedRow?.imsi || "",
      iccid: props.selectedRow?.iccid || "",
      batch: props.selectedRow?.batch || "",
      firmware: props.selectedRow?.firmware || "",
      serial: props.selectedRow?.serial || "",
      serverNumber: props.selectedRow?.serverNumber || "",
      trackerType: props.selectedRow?.type || "",
      trackerId: props.selectedRow?.trackerId || "",
      geofenceDynamicLocation:
        props.selectedRow?.geofenceDynamicLocation || false,
      locationQuerySms: props.selectedRow?.locationQuerySms || false,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      setSuccess("")
      setError("")
      setUpdate(true)

      values.orderNumber = values.orderNumber ? values.orderNumber : ""

      updateTracker(abortRef.current, props.selectedRow?.key, values)
        .then(result => {
          setUpdate(false)
          if (result.statusCode == 200) {
            setSuccess("Updated")
            props.getTrackers()
          } else {
            setError("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setUpdate(false)
          setError("Error")
        })
    },
  })

  // Selected order changed
  const orderSelectionChanged = value => {
    validation.setFieldValue("orderNumber", value)
  }

  // Delete device
  const deleteDevice = () => {
    if (props.selectedRow != null && props.selectedRow.key.length > 0) {
      setOngoingDelete(true)
      deleteTracker(abortRef.current, props.selectedRow.key)
        .then(result => {
          if (result.statusCode === 200) {
            if (result.statusDescription == "OK") {
              setDeleteModal(false)
              showToast(props.t("Deleted"), "success")
              props.getTrackers()
              props.setShowDrawer(false)
            } else {
              showToast(props.t("Unable to delete linked device"), "error")
            }
          } else {
            showToast(props.t("Error"), "error")
          }
          setOngoingDelete(false)
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
          setOngoingDelete(false)
        })
    }
  }

  // SIM status
  const simStatusAction = status => {
    if (props.selectedRow?.imsi && !ongoingAction) {
      setOngoingAction(true)
      teliaSimStatus(abortRef.current, props.selectedRow.imsi, status)
        .then(result => {
          if (result.statusCode === 200) {
            showToast(props.t("Status") + ": " + result.simStatus, "success")
            props.getTrackers()
          }
          else if (result.errorMessage) {
            showToast(props.t("Error") + ": " + result.errorMessage, "error")
          }
          else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Navigil data update
  const updateNavigil = () => {
    if (props.selectedRow?.key && !ongoingAction) {
      setOngoingAction(true)
      updateNavigilData(abortRef.current, props.selectedRow.key)
        .then(result => {
          if (result.statusCode === 200) {
            showToast(props.t("Success"), "success")
            props.getTrackers()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Group selection changed
  const groupChanged = newGroup => {
    validation.setFieldValue("group", newGroup)
  }

  return (
    <>
      <Drawer
        title={
          props.t("Tracker") +
          " " +
          props.selectedRow?.group +
          " - " +
          props.selectedRow?.name
        }
        width={"80%"}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
          setError("")
          setSuccess("")
          setActiveTab("1")
          setRenderSmsTab(false)
          setRenderClientTab(false)
        }}
        open={props.showDrawer}
        zIndex={1003}
      //bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        <div className="d-flex flex-wrap mb-3">
          <ul className="nav icon-tab">
            <NavItem>
              <NavLink
                active={activeTab === "1"}
                onClick={() => {
                  setActiveTab("1")
                }}
              >
                {props.t("Management panel")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === "3"}
                onClick={() => {
                  setActiveTab("3")
                  setRenderSmsTab(true)
                }}
              >
                {props.t("SMS logs")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === "5"}
                onClick={() => {
                  setActiveTab("5")
                  setRenderClientTab(true)
                }}
              >
                {props.t("Client view")}
              </NavLink>
            </NavItem>
            {props.selectedRow?.type == "Navigil" && (
              <NavItem>
                <NavLink
                  active={activeTab === "2"}
                  onClick={() => {
                    setActiveTab("2")
                  }}
                >
                  {props.t("Navigil settings")}
                </NavLink>
              </NavItem>
            )}
          </ul>
        </div>

        <TabContent activeTab={activeTab}>
          <TabPane className="show" tabId="1">
            <Row xs={1} xl={2}>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {props.t("Settings")}
                    </CardTitle>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        return false
                      }}
                    >
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Description")}</Label>
                            <Input
                              bsSize="sm"
                              name="name"
                              type="text"
                              value={validation.values.name}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row xs={1} md={2}>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Group")}</Label>
                            <SelectGroup
                              onChange={groupChanged}
                              multiple={false}
                              clearable={false}
                              selection={validation.values.group}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Type")}</Label>
                            <Input
                              bsSize="sm"
                              name="trackerType"
                              type="select"
                              value={validation.values.trackerType}
                              onChange={validation.handleChange}
                            >
                              <option value="">{props.t("Undefined")}</option>
                              <option value="Farnear">Farnear</option>
                              <option value="MiniFinder">MiniFinder</option>
                              <option value="MiniFinderGPRS">
                                MiniFinderGPRS
                              </option>
                              <option value="MiniFinderSMS">
                                MiniFinderSMS
                              </option>
                              <option value="Navigil">Navigil</option>
                              <option value="Yepzon">Yepzon</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("GSM")}</Label>
                            <Input
                              bsSize="sm"
                              name="gsm"
                              type="text"
                              value={validation.values.gsm}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Serial")}</Label>
                            <Input
                              bsSize="sm"
                              name="serial"
                              type="text"
                              value={validation.values.serial}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("IMEI")}</Label>
                            <Input
                              bsSize="sm"
                              name="imei"
                              type="text"
                              value={validation.values.imei}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("IMSI")}</Label>
                            <Input
                              bsSize="sm"
                              name="imsi"
                              type="text"
                              value={validation.values.imsi}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("ICCID")}</Label>
                            <Input
                              bsSize="sm"
                              name="iccid"
                              type="text"
                              value={validation.values.iccid}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                        {validation.values.trackerType == "Navigil" && (
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Device ID")}</Label>
                              <Input
                                bsSize="sm"
                                name="trackerId"
                                type="text"
                                value={validation.values.trackerId}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                        )}
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Firmware")}</Label>
                            <Input
                              bsSize="sm"
                              name="firmware"
                              type="text"
                              value={validation.values.firmware}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Batch")}</Label>
                            <Input
                              bsSize="sm"
                              name="batch"
                              type="text"
                              value={validation.values.batch}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Server number")}</Label>
                            <Input
                              name="serverNumber"
                              type="select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.serverNumber || ""}
                            >
                              <option value="">{props.t("Undefined")}</option>
                              <option value="31811">31811</option>
                              <option value="+3584573950158">
                                +3584573950158
                              </option>
                              <option value="+358509020154">
                                +358509020154
                              </option>
                              <option value="+61428449770">+61428449770</option>
                              <option value="+4759447471">+4759447471</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        {validation.values.trackerType == "Farnear" && (
                          <Col>
                            <FormGroup className="mt-2">
                              <Label>{props.t("Locate before geofence")}</Label>
                              <Switch
                                className="d-block"
                                checkedChildren={
                                  <i className="mdi mdi-check-bold" />
                                }
                                unCheckedChildren={
                                  <i className="mdi mdi-close-thick" />
                                }
                                name="geofenceDynamicLocation"
                                checked={
                                  validation.values.geofenceDynamicLocation
                                }
                                onChange={v => {
                                  validation.setFieldValue(
                                    "geofenceDynamicLocation",
                                    v
                                  )
                                }}
                              />
                            </FormGroup>
                          </Col>
                        )}
                        {validation.values.trackerType == "MiniFinderGPRS" && (
                          <Col>
                            <FormGroup className="mt-2">
                              <Label>{props.t("Locate via SMS")}</Label>
                              <Switch
                                className="d-block"
                                checkedChildren={
                                  <i className="mdi mdi-check-bold" />
                                }
                                unCheckedChildren={
                                  <i className="mdi mdi-close-thick" />
                                }
                                name="locationQuerySms"
                                checked={validation.values.locationQuerySms}
                                onChange={v => {
                                  validation.setFieldValue(
                                    "locationQuerySms",
                                    v
                                  )
                                }}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Order number")}</Label>
                            <SelectOrder
                              selection={validation.values.orderNumber}
                              setSelectedOrder={orderSelectionChanged}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="mt-2 mb-3 d-flex justify-content-center">
                        <Button
                          color="primary"
                          disabled={update}
                          style={{ minWidth: "150px" }}
                          onClick={() => {
                            validation.submitForm()
                          }}
                        >
                          {props.t("Save")}
                        </Button>
                      </div>
                      {error ? (
                        <Alert color="danger">{props.t(error)}</Alert>
                      ) : null}
                      {success ? (
                        <Alert color="success">{props.t(success)}</Alert>
                      ) : null}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{props.t("Actions")}</CardTitle>
                    <ListGroup className="list-group-flush">
                      {props.selectedRow?.gsm.indexOf("453") == 0 && (
                        <ListGroupItem className="pb-4">
                          <Row>
                            <Col>
                              <div>
                                <h6>
                                  {props.t("SIM - IMSI") +
                                    " (" +
                                    props.selectedRow?.simStatus +
                                    ")"}
                                </h6>
                                <span className="text-muted">
                                  {props.t("Check or change SIM status")}
                                </span>
                              </div>
                            </Col>
                            <Col className="col-auto">
                              <ButtonDropdown
                                isOpen={simDropdownOpen}
                                toggle={() => {
                                  setSimDropdownOpen(!simDropdownOpen)
                                }}
                              >
                                <DropdownToggle
                                  color="primary"
                                  style={{ minWidth: "100px" }}
                                  caret
                                >
                                  {props.t("Select action")}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    disabled={
                                      ongoingAction || !props.selectedRow?.imsi
                                    }
                                    onClick={() => {
                                      simStatusAction(undefined)
                                    }}
                                  >
                                    {props.t("Check")}
                                  </DropdownItem>
                                  <DropdownItem
                                    disabled={
                                      ongoingAction || !props.selectedRow?.imsi
                                    }
                                    onClick={() => {
                                      simStatusAction("Activate")
                                    }}
                                  >
                                    {props.t("Activate")}
                                  </DropdownItem>
                                  <DropdownItem
                                    disabled={
                                      ongoingAction || !props.selectedRow?.imsi
                                    }
                                    onClick={() => {
                                      simStatusAction("Pause")
                                    }}
                                  >
                                    {props.t("Pause")}
                                  </DropdownItem>
                                  <DropdownItem
                                    disabled={
                                      ongoingAction || !props.selectedRow?.imsi
                                    }
                                    onClick={() => {
                                      simStatusAction("Terminate")
                                    }}
                                  >
                                    {props.t("Terminate")}
                                  </DropdownItem>
                                </DropdownMenu>
                              </ButtonDropdown>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      )}

                      {props.selectedRow?.iccid && (
                        <ListGroupItem className="pb-4">
                          <SimStatusDropdown iccid={props.selectedRow.iccid} />
                        </ListGroupItem>
                      )}

                      {props.selectedRow?.type === "Navigil" && (
                        <ListGroupItem className="pb-4">
                          <Row>
                            <Col>
                              <div>
                                <h6>{props.t("Update Navigil data")}</h6>
                                <span className="text-muted">
                                  {props.t(
                                    "Fetch and update device information"
                                  )}
                                </span>
                              </div>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                color="primary"
                                disabled={
                                  ongoingAction || !props.selectedRow?.serial
                                }
                                style={{ minWidth: "100px" }}
                                onClick={() => {
                                  updateNavigil()
                                }}
                              >
                                {props.t("Update")}
                              </Button>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      )}

                      <ListGroupItem className="pb-4">
                        <Row>
                          <Col>
                            <div>
                              <h6>{props.t("Deletion")}</h6>
                              <span className="text-muted">
                                {props.t(
                                  "The device and all related data will be deleted permanently"
                                )}
                              </span>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              color="danger"
                              style={{ minWidth: "100px" }}
                              onClick={() => {
                                setDeleteModal(true)
                              }}
                            >
                              {props.t("Delete")}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>

          <TabPane className="show" tabId="3">
            {props.selectedRow?.key && renderSmsTab && (
              <TrackerSmsTab
                gsm={props.selectedRow?.gsm}
                serverNumber={props.selectedRow?.serverNumber}
              ></TrackerSmsTab>
            )}
          </TabPane>

          <TabPane className="show" tabId="2">
            {props.selectedRow?.key && (
              <NavigilSettings deviceId={props.selectedRow.trackerId} />
            )}
          </TabPane>

          <TabPane className="show" tabId="5">
            {props.selectedRow?.key && renderClientTab && (
              <OnTheGoServices trackerKey={props.selectedRow?.key} />
            )}
          </TabPane>
        </TabContent>
      </Drawer>

      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody>
          <p>
            {props.t("Are you sure you want to delete") +
              " " +
              props.selectedRow?.group +
              " - " +
              props.selectedRow?.name +
              "?"}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={ongoingDelete}
            color="danger"
            onClick={() => {
              deleteDevice()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

TrackerDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getTrackers: PropTypes.func,
}

export default withRouter(withTranslation()(TrackerDrawer))
