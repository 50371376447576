import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  FormFeedback,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Alert,
  CardHeader,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import Lottie from "react-lottie"
import {
  dateFormatter,
  dateSorter,
  defaultPageSizeOptions,
  getLottieLoadingOptions,
  showToast,
  stringSorter,
  downloadExcel,
  exportPDF,
} from "utils/general"
import { default as SelectGroupFilters } from "components/DropdownList/SelectGroupFilters"
import SelectGroup from "components/DropdownList/SelectGroup"
import TagsTree from "components/DropdownList/TagsTree"
import {
  getAdminRequests,
  getManagedAdminUsers,
  newAdminRequest,
} from "helpers/backend_helper"
import AdminUserDrawer from "./usermanagement-drawer"
import * as Yup from "yup"
import { useFormik } from "formik"
import "jspdf-autotable"
import { Table as AntdTable } from "antd"
// import GlobalState from "contexts/GlobalState"
import { tableDefaultHeight } from "constants/layout"

const UserManagement = props => {
  // const [state] = useContext(GlobalState)
  // const [userRole, setUserRole] = useState(null)
  const [group, setGroup] = useState([])
  const [newUsersGroups, setNewUsersGroups] = useState([])
  const [fetchedGroups, setFetchedGroups] = useState()
  const [groupMenuVisible, setGroupMenuVisible] = useState(false)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [requests, setRequests] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [ongoingAdd] = useState(false)
  const [updateCounter] = useState(0)
  const [selectedUsers] = useState([])
  const [ttdownloadstatstable, setTtdownloadstatstable] = useState(false) // Tooltip state for download button
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedTags, setSelectedTags] = useState([])
  const [title] = useState(props.t("User management"))
  const [showOnSide] = useState(false)
  const [colSize, setColSize] = useState(12)
  const [activeTab, setActiveTab] = useState("1")

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()

    // if (state.authUser != null) {
    //   const obj = JSON.parse(state.authUser)
    //   let role = obj.activeRole ? obj.activeRole : obj.role
    //   setUserRole(role)
    // }

    //getRequests()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (!groupMenuVisible && group.toString() != fetchedGroups?.toString()) {
      setFetchedGroups(group)
      getAdmins()
    }
  }, [group, groupMenuVisible])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, users])

  // Name tags state update from app-users-selected.js
  useEffect(() => {
    if (selectedUsers.length == 0) {
      start()
    }
  }, [selectedUsers])

  // Reset form when modal changed
  useEffect(() => {
    if (showOnSide) {
      setColSize(9)
    } else {
      setColSize(12)
    }
  }, [showOnSide])

  // Handle search function
  const handleSearch = () => {
    let result = users.filter(
      data =>
        data.name?.toLowerCase().includes(searchText) ||
        data.description?.toLowerCase().includes(searchText) ||
        data.groups?.toLowerCase().includes(searchText) ||
        data.gsm?.toLowerCase().includes(searchText) ||
        data.email?.toLowerCase().includes(searchText) ||
        data.fullName?.toLowerCase().includes(searchText)
    )
    setFilteredUsers(result)
  }

  // Get admin users for table
  const getAdmins = () => {
    setLoading(true)
    getManagedAdminUsers(abortRef.current, group.toString())
      .then(result => {
        //console.log("Admins: ", result.entries)
        if (result.statusCode == 200 && result.entries) {
          result.entries.sort((a, b) => (a.name > b.name ? 1 : -1))
          // Adding keys to for table
          const addKeys = result.entries.map(obj => {
            if (obj.key == undefined) {
              return { ...obj, key: obj.name }
            }
            return obj
          })
          setUsers(addKeys)
        }
      })
      .catch(err => {
        console.log(err)
        setUsers([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Get support request
  const getRequests = () => {
    //setLoading(true)
    getAdminRequests(abortRef.current)
      .then(result => {
        if (result.statusCode == 200 && result.entries) {
          setRequests(result.entries)
        }
      })
      .catch(err => {
        console.log(err)
        setRequests([])
      })
      .finally(() => {
        //setLoading(false)
      })
  }

  // Create user validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      gsmNumber: "",
      groups: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .matches(
          /^[a-z0-9]+[a-z0-9-]*[a-z0-9]+[.][a-z0-9]+[a-z0-9-]*[a-z0-9]$/,
          props.t("Invalid format")
        )
        .min(7, props.t("Too short"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values, newUsersGroups)
      if (newUsersGroups?.length > 0) {
        let formValues = JSON.parse(JSON.stringify(values))
        formValues.groups = newUsersGroups

        console.log("Final submit: ", formValues)

        newAdminRequest(abortRef.current, formValues)
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("Request sent"), "success")
              setAddModal(false)
              getRequests()
              validation.resetForm()
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            //setOngoingRequest(false)
          })
      }
    },
  })

  // Empty selected users
  const start = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 200)
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Username"),
        props.t("Description"),
        props.t("Professional"),
        props.t("Email"),
        props.t("GSM"),
        props.t("Connected"),
        props.t("Groups"),
      ],
    ]
    const data = filteredUsers.map(elt => [
      elt.name,
      elt.description,
      elt.basicUser,
      elt.email,
      elt.gsm,
      dateFormatter(elt.lastConnection),
      elt.groups,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  const generatePdf = () => {
    const heading = [
      props.t("Username"),
      props.t("Description"),
      props.t("Professional"),
      props.t("Email"),
      props.t("GSM"),
      props.t("Connected"),
      props.t("Groups"),
    ]
    const data = filteredUsers.map(elt => [
      elt.name,
      elt.description,
      elt.basicUser,
      elt.email,
      elt.gsm,
      dateFormatter(elt.lastConnection),
      elt.groups,
    ])

    exportPDF(title, heading, data)
    showToast(props.t("PDF file exported succesfully!"), "success")
  }

  // Table structure
  const columns = [
    {
      title: props.t("Actions"),
      dataIndex: "actions",
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      title: props.t("Username"),
      dataIndex: "name",
      render: usernameFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "name", sortOrder)
      },
      fixed: "left",
      width: "200px",
      ellipsis: true,
    },
    {
      title: props.t("Name"),
      dataIndex: "fullName",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "fullName", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      title: props.t("Description"),
      dataIndex: "description",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "description", sortOrder)
      },
      width: "250px",
      ellipsis: true,
    },
    {
      title: props.t("Professional"),
      dataIndex: "basicUser",
      sorter: (a, b) => a.basicUser - b.basicUser,
      render: basicFormatter,
      align: "center",
      width: "150px",
      ellipsis: true,
    },
    {
      title: props.t("Email"),
      dataIndex: "email",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "email", sortOrder)
      },
      width: "250px",
      ellipsis: true,
    },
    {
      title: props.t("GSM"),
      dataIndex: "gsm",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "gsm", sortOrder)
      },
      width: "150px",
      ellipsis: true,
    },
    {
      title: props.t("Connected"),
      dataIndex: "lastConnection",
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastConnection"], b["lastConnection"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      title: props.t("Groups"),
      dataIndex: "groups",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "groups", sortOrder)
      },
      width: "150px",
      ellipsis: true,
    },
  ]

  const columns2 = [
    {
      dataIndex: "created",
      title: props.t("Requested"),
      width: "160px",
      ellipsis: true,
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["created"], b["created"], sortOrder)
      },
      render: dateFormatter,
    },
    {
      dataIndex: "targetUser",
      title: props.t("Username"),
      width: "160px",
      ellipsis: true,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "targetUser", sortOrder)
      },
    },
    {
      dataIndex: "status",
      title: props.t("Status"),
      width: "160px",
      ellipsis: true,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "status", sortOrder)
      },
      render: (cell, row) => {
        switch (cell) {
          case "open":
            /*if (userRole == "system_admin")
              return (
                <>
                  <i
                    style={{ verticalAlign: "bottom" }}
                    className="bx bxs-calendar-heart font-size-20 text-warning me-2"
                  />
                  <span>{props.t("Pending")}</span>
                  <Button
                    size="sm"
                    color="primary"
                    className="ms-2"
                    onClick={() => {
                      approveRequest(row.key)
                    }}
                  >
                    {props.t("Approve")}
                  </Button>
                </>
              )
            else*/
            return (
              <>
                <i
                  style={{ verticalAlign: "bottom" }}
                  className="bx bxs-calendar-heart font-size-20 text-warning me-2"
                />
                <span>{props.t("Pending")}</span>
              </>
            )
          case "resolved":
            return (
              <>
                <i
                  style={{ verticalAlign: "bottom" }}
                  className="bx bx-check-circle font-size-20 text-success me-2"
                />
                <span>{props.t("Resolved")}</span>
              </>
            )
          case "failed":
            return (
              <>
                <i
                  style={{ verticalAlign: "bottom" }}
                  className="bx bx-error font-size-20 text-danger me-2"
                />
                <span>{props.t("Failed")}</span>
              </>
            )
          default:
            return cell
        }
      },
    },
    {
      dataIndex: "updated",
      title: props.t("Updated"),
      width: "160px",
      ellipsis: true,
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["updated"], b["updated"], sortOrder)
      },
      render: dateFormatter,
    },
  ]

  // Table formatting functions start
  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  function usernameFormatter(cell, row) {
    return (
      <>
        <span>{row.name}</span>
        {row.videoRoomId && (
          <i className="ms-2 mdi mdi-account-voice text-primary" />
        )}
      </>
    )
  }

  function basicFormatter(cell, row) {
    if (row.basicUser) {
      return <i className="mdi mdi-check-bold text-primary"></i>
    } else return <>{/* <i className="mdi mdi-close text-light"></i> */}</>
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={title} />
          <Row className="mb-4">
            <Col xs="4">
              <div className="d-inline-block" style={{ width: "100%" }}>
                <TagsTree
                  loadData={true}
                  expanded={true}
                  selectionChanged={setSelectedTags}
                />
              </div>
            </Col>
            <Col xs="4">
              <SelectGroupFilters
                onChange={setGroup}
                onVisibilityChange={setGroupMenuVisible}
                multiple={true}
                disabled={loading}
                selectedTags={selectedTags}
                updateCounter={updateCounter}
              />
            </Col>
          </Row>

          <div>
            <Row>
              <Col sm={colSize}>
                <Card>
                  <CardHeader className="bg-transparent border-bottom">
                    <div className="d-flex flex-wrap">
                      <ul className="nav nav-tabs nav-tabs-custom card-header-tabs">
                        <NavItem>
                          <NavLink
                            to="#"
                            active={activeTab === "1"}
                            onClick={() => {
                              setActiveTab("1")
                            }}
                          >
                            {props.t("Users")}
                          </NavLink>
                        </NavItem>
                        {/** 
                        <NavItem>
                          <NavLink
                            active={activeTab === "2"}
                            onClick={() => {
                              setActiveTab("2")
                            }}
                          >
                            {props.t("Lisäyspyynnöt")}
                          </NavLink>
                        </NavItem>*/}
                      </ul>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        {/*(loading || !users || users.length == 0) && (
                          <div className="text-center my-5">
                            <Lottie
                              options={{
                                loop: false,
                                autoplay: true,
                                animationData: searchAnim,
                                rendererSettings: {
                                  preserveAspectRatio: "xMidYMid slice",
                                },
                              }}
                              height={150}
                              width={180}
                            ></Lottie>
                          </div>
                        )*/}

                        {
                          /*!loading && users != null && users.length > 0 &&*/ <React.Fragment>
                            <Row>
                              <Col className="col-auto pe-0">
                                <Form
                                  className="mt-sm-0 d-flex align-items-center"
                                  onSubmit={e => {
                                    e.preventDefault()
                                    return false
                                  }}
                                >
                                  <div className="search-box me-2 mb-2 d-inline-block float-end">
                                    <div className="position-relative">
                                      <Input
                                        type="text"
                                        onChange={event =>
                                          setSearchText(
                                            event.target.value.toLowerCase()
                                          )
                                        }
                                        placeholder={props.t("Search")}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Form>
                              </Col>
                              {/**
                              <Col className="col-auto">
                                <Button
                                  style={{ borderRadius: "100px" }}
                                  color="success"
                                  onClick={() => {
                                    setAddModal(true)
                                  }}
                                >
                                  <i className="fas fa-user-plus"></i>
                                  <span className="d-none d-lg-inline-block ms-2">
                                    {props.t("Create a new user")}
                                  </span>
                                </Button>
                              </Col>*/}
                              <Col>
                                <span className="float-end ms-2">
                                  <Tooltip
                                    placement="bottom"
                                    isOpen={ttdownloadstatstable}
                                    target="downloadstatstable"
                                    toggle={() => {
                                      setTtdownloadstatstable(
                                        !ttdownloadstatstable
                                      )
                                    }}
                                  >
                                    {props.t("Download statistics")}
                                  </Tooltip>
                                  <Dropdown
                                    id="printButtonServicesStats"
                                    isOpen={menu}
                                    toggle={() => setMenu(!menu)}
                                    className="download-btn text-center"
                                    type="button"
                                  >
                                    <DropdownToggle
                                      id="downloadstatstable"
                                      className="text-muted"
                                      tag="i"
                                    >
                                      <i className="mdi mdi-download" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => generateExcel()}
                                      >
                                        {props.t("Download .xlsx")}
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => generatePdf()}
                                      >
                                        {props.t("Download .pdf")}
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </span>
                                <span className="mt-2 float-end">
                                  <p>
                                    {filteredUsers.length} {props.t("users")}
                                  </p>
                                </span>
                              </Col>
                            </Row>

                            {!loading && (
                              <>
                                <div className="mt-2">
                                  <AntdTable
                                    className="striped colored-header"
                                    bordered
                                    size="middle"
                                    sticky
                                    rowKey={"name"}
                                    columns={columns}
                                    dataSource={filteredUsers}
                                    pagination={{
                                      showSizeChanger: true,
                                      defaultPageSize: 100,
                                      pageSizeOptions: defaultPageSizeOptions,
                                    }}
                                    tableLayout="auto"
                                    scroll={{
                                      x: "max-content",
                                      y: tableDefaultHeight,
                                    }}
                                  />
                                </div>
                              </>
                            )}
                            {loading && (
                              <Lottie
                                options={getLottieLoadingOptions()}
                                height={100}
                                width={100}
                              ></Lottie>
                            )}
                          </React.Fragment>
                        }
                      </TabPane>
                      <TabPane tabId="2">
                        <AntdTable
                          className="clear-header"
                          size="small"
                          sticky
                          rowKey={"key"}
                          columns={columns2}
                          dataSource={requests}
                          pagination={{
                            showSizeChanger: true,
                            defaultPageSize: 500,
                            pageSizeOptions: defaultPageSizeOptions,
                          }}
                          tableLayout="auto"
                          scroll={{
                            x: "max-content",
                            y: tableDefaultHeight,
                          }}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>{" "}
            </Row>
          </div>

          {/**  User add modal */}
          <Modal
            isOpen={addModal}
            toggle={() => {
              setAddModal(!addModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create a new user")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <Alert color={"primary"}>
                  <span>{props.t("UserAllowedChars")}</span>
                </Alert>
                <FormGroup>
                  <Label>{props.t("Username")}</Label>
                  <Input
                    name="username"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    placeholder={"E.g. firstname.lastname"}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                    }
                  ></Input>
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>{props.t("Users groups")}</Label>
                  <SelectGroup
                    multiple={true}
                    selection={newUsersGroups}
                    onChange={setNewUsersGroups}
                  />
                  <Input hidden invalid={newUsersGroups?.length == 0} />
                  <FormFeedback type="invalid">
                    {props.t("Required field")}
                  </FormFeedback>
                </FormGroup>

                <Row xs={1} sm={2}>
                  <Col>
                    <FormGroup>
                      <Label>{props.t("First name")}</Label>
                      <Input
                        bsSize="sm"
                        name="firstName"
                        type="text"
                        placeholder={props.t("Optional")}
                        value={validation.values.firstName}
                        onChange={validation.handleChange}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{props.t("Last name")}</Label>
                      <Input
                        bsSize="sm"
                        name="lastName"
                        type="text"
                        placeholder={props.t("Optional")}
                        value={validation.values.lastName}
                        onChange={validation.handleChange}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{props.t("GSM")}</Label>
                      <Input
                        bsSize="sm"
                        name="gsmNumber"
                        type="text"
                        placeholder={props.t("Optional")}
                        value={validation.values.gsmNumber}
                        onChange={validation.handleChange}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{props.t("Email")}</Label>
                      <Input
                        bsSize="sm"
                        name="email"
                        type="text"
                        placeholder={props.t("Optional")}
                        value={validation.values.email}
                        onChange={validation.handleChange}
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setAddModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingAdd}
                  onClick={() => {
                    validation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>{" "}
              </ModalFooter>
            </Form>
          </Modal>

          <AdminUserDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getAdmins={getAdmins}
          />
        </Container>
      </div>
    </>
  )
}

UserManagement.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(UserManagement)
