import darkIcon from "../assets/images/themes/dark-icon.svg"
import lightIcon from "../assets/images/themes/light-icon.svg"

const themes = {
  light: {
    label: "ThemeLight",
    icon: lightIcon,
  },
  dark: {
    label: "ThemeDark",
    icon: darkIcon,
  },
  //
  // TODO:set high contrast options
  // contrast: {
  //   label: "ThemeContrast",
  //   icon: contrastIcon,
  // },
  // elisa: {
  //   label: "ThemeContrast",
  //   icon: contrastIcon,
  // },
  // mit: {
  //   label: "ThemeContrast",
  //   icon: contrastIcon,
  // },
}

export default themes
