import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import utc from "dayjs/plugin/utc"
import weekOfYear from "dayjs/plugin/weekOfYear"
import { withTranslation } from "react-i18next"
import { DatePicker, ConfigProvider, Button, Space } from "antd"
import { getLocale } from "utils/general"

// Extend dayjs with necessary plugins
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(weekOfYear)

const { RangePicker } = DatePicker

const CustomRangePicker = props => {
  const locale = getLocale()

  // Memoize range buttons to avoid recalculating on every render
  const rangeButtons = useMemo(
    () => ({
      [props.t("This day")]: [dayjs().startOf("day"), dayjs()],
      [props.t("This Week")]: [dayjs().startOf("week"), dayjs().endOf("week")],
      [props.t("This Month")]: [
        dayjs().startOf("month"),
        dayjs().endOf("month"),
      ],
      [props.t("This Year")]: [dayjs().startOf("year"), dayjs().endOf("year")],
    }),
    [props.t]
  )

  // Effect to handle wheel scroll behavior on date picker dropdown
  useEffect(() => {
    if (props.disabled) return

    const handleScroll = e => e.preventDefault()
    const dropdowns = document.querySelectorAll(".ant-picker-dropdown")

    dropdowns.forEach(el => {
      el.addEventListener("wheel", handleScroll, { passive: false })
    })

    return () => {
      dropdowns.forEach(el => {
        el.removeEventListener("wheel", handleScroll)
      })
    }
  }, [props.disabled])

  // Function to render preset buttons
  const renderFooterButtons = () => (
    <Space wrap>
      {Object.entries(rangeButtons).map(([label, value]) => (
        <Button
          key={label}
          type="default"
          size="small"
          style={{
            borderRadius: "4px",
            margin: "4px",
            padding: "2px 8px",
          }}
          onClick={() => props.rangeChanged(value)}
        >
          {label}
        </Button>
      ))}
    </Space>
  )

  return (
    <React.Fragment>
      <ConfigProvider locale={locale}>
        <RangePicker
          className="range-picker"
          disabled={props.disabled}
          value={props.value}
          showTime={props.showTime ?? true}
          allowClear={props.allowClear ?? false}
          onChange={props.rangeChanged}
          format={props.format ?? "DD.MM.YYYY HH:mm:ss"}
          picker="date"
          renderExtraFooter={renderFooterButtons}
        />
      </ConfigProvider>
    </React.Fragment>
  )
}

CustomRangePicker.propTypes = {
  t: PropTypes.any,
  value: PropTypes.array,
  rangeChanged: PropTypes.func,
  disabled: PropTypes.bool,
  showTime: PropTypes.bool,
  allowClear: PropTypes.bool,
  format: PropTypes.string,
}

export default withTranslation()(CustomRangePicker)
