import React, { useEffect, useRef, useState } from "react"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledAccordion,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import * as Yup from "yup"
import { useFormik } from "formik"
import { getLottieLoadingOptions, getLottieNothingFound, showToast } from "utils/general"
import { Slider, Switch } from "antd"
import Box from "@mui/material/Box"
import {
  geocodeGetCoordinates,
  getNavigilSettings,
  setNavigilSettings,
} from "helpers/backend_helper"
import Lottie from "react-lottie"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import DefaultMap from "components/GoogleMap/map"
import withRouter from "components/Common/withRouter"

const NavigilSettings = props => {
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [fetching, setFetching] = useState(false)

  const [settings, setSettings] = useState(null)
  const [beaconModal, setBeaconModal] = useState(false)
  const [contactModal, setContactModal] = useState(false)

  const [funcModes, setFuncModes] = useState([])
  const [languages, setLanguages] = useState([])
  const [profiles, setProfiles] = useState([])
  const [updateIntervals, setUpdateIntervals] = useState([])
  const [userModes, setUserModes] = useState([])
  const [timezones, setTimezones] = useState([])
  const [callCenters, setCallCenters] = useState([])
  const [callList, setCallList] = useState([])
  const [homeBeacons, setHomeBeacons] = useState([])
  const [geofences, setGeofences] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.deviceId) {
      //console.log("ID changed: ", props.deviceId)
      //getSettings()
    }
  }, [props.deviceId])

  // Get Navigil settings
  const getSettings = () => {
    if (!loading && props.deviceId) {
      setLoading(true)
      getNavigilSettings(abortRef.current, props.deviceId)
        .then(result => {
          //console.log("SETTINGS: ", result.settings)
          if (result.statusCode == 200 && result.settings) {
            setSettings(result.settings)

            if (result.settings?.deviceCallList?.callList) {
              setCallList(result.settings.deviceCallList.callList)
            }

            if (result.settings?.homeBeacons) {
              setHomeBeacons(result.settings.homeBeacons)
            }

            if (result.settings?.geofences) {
              let temp = JSON.parse(JSON.stringify(result.settings.geofences))
              temp.forEach(element => {
                element.coordinates = JSON.stringify(element.coordinates)
              })

              setGeofences(temp)
            }

            if (
              result.settings?.generalSettings?.functionalityMode?.available
            ) {
              setFuncModes(
                result.settings.generalSettings.functionalityMode.available
              )
            }

            if (result.settings?.generalSettings?.language?.available) {
              setLanguages(result.settings.generalSettings.language.available)
            }

            if (result.settings?.generalSettings?.profile?.available) {
              setProfiles(result.settings.generalSettings.profile.available)
            }

            if (result.settings?.generalSettings?.timezone?.available) {
              setTimezones(result.settings.generalSettings.timezone.available)
            }

            if (result.settings?.generalSettings?.userMode?.available) {
              setUserModes(result.settings.generalSettings.userMode.available)
            }

            if (
              result.settings?.generalSettings?.statusUpdateInterval?.available
            ) {
              setUpdateIntervals(
                result.settings.generalSettings.statusUpdateInterval.available
              )
            }

            if (result.settings?.deviceCallList?.availableCallCenters) {
              let centers = [
                { name: props.t("No selection"), number: "", id: 0 },
              ]
              result.settings.deviceCallList.availableCallCenters.forEach(
                cc => {
                  centers.push(cc)
                }
              )
              setCallCenters(centers)
            }
          } else {
            setSettings(null)
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
          setSettings(null)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Get coordinates
  const getCoordinates = address => {
    if (address && !fetching) {
      setFetching(true)
      geocodeGetCoordinates(abortRef.current, address)
        .then(result => {
          console.log("COORDS: ", result)
          if (result.statusCode == 200 && result.coordinates) {
            result.coordinates.lat = result.coordinates.lat.toFixed(6)
            result.coordinates.lng = result.coordinates.lng.toFixed(6)

            beaconValidation.setFieldValue(
              "latitude",
              "" + result.coordinates.lat
            )
            beaconValidation.setFieldValue(
              "longitude",
              "" + result.coordinates.lng
            )
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setFetching(false)
        })
    }
  }

  // Set default settings
  const setDefaultValues = () => {
    if (settings) {
      validation.setFieldValue("dataRetentionDays", 90)
      validation.setFieldValue("locationAllowed", true)
      validation.setFieldValue("emergencyLocationAllowed", true)
      validation.setFieldValue("volume", 8)
      validation.setFieldValue("functionalityMode", 1)
      validation.setFieldValue("language", 1)
      validation.setFieldValue("profile", 136)
      validation.setFieldValue("timezone", 20)
      validation.setFieldValue("userMode", 2)
      validation.setFieldValue("statusUpdateInterval", 360)
      validation.setFieldValue("answerMachineAvoidance", true)
      validation.setFieldValue("whitelistingEnabled", false)
      validation.setFieldValue("ringingTimeout", 60)
      validation.setFieldValue("callCenterId", 38)
    }
  }

  // Update validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: settings?.assisteeData?.firstName || "",
      lastName: settings?.assisteeData?.lastName || "",
      dataRetentionDays: settings?.devicePermissions?.dataRetentionDays || 30,
      locationAllowed: settings?.devicePermissions?.locationAllowed || false,
      emergencyLocationAllowed:
        settings?.devicePermissions?.emergencyLocationAllowed || false,
      volume: settings?.generalSettings?.volume || 50,
      functionalityMode:
        settings?.generalSettings?.functionalityMode?.currentValue || 1,
      language: settings?.generalSettings?.language?.currentValue || 1,
      profile: settings?.generalSettings?.profile?.currentValue || 1,
      timezone: settings?.generalSettings?.timezone?.currentValue || 1,
      userMode: settings?.generalSettings?.userMode?.currentValue || 1,
      statusUpdateInterval:
        settings?.generalSettings?.statusUpdateInterval?.currentValue || 360,
      //trackingEnabled: settings?.generalSettings?.trackingEnabled || false,
      answerMachineAvoidance:
        settings?.deviceCallList?.answerMachineAvoidance || false,
      ringingTimeout: settings?.deviceCallList?.ringingTimeout || 10,
      whitelistingEnabled:
        settings?.deviceCallList?.whitelistingEnabled || false,
      callCenterId: settings?.deviceCallList?.callCenterId || 0,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      let temp = new Object()
      temp.assisteeData = {}
      temp.assisteeData.firstName = values.firstName
      temp.assisteeData.lastName = values.lastName

      temp.companyData = {}
      temp.companyData.companyId = 711
      temp.companyData.groupId = 101619

      temp.devicePermissions = {}
      temp.devicePermissions.dataRetentionDays =
        values.dataRetentionDays === "number"
          ? values.dataRetentionDays
          : parseInt(values.dataRetentionDays, 10)
      temp.devicePermissions.locationAllowed = values.locationAllowed
      temp.devicePermissions.emergencyLocationAllowed =
        values.emergencyLocationAllowed

      temp.generalSettings = {}
      temp.generalSettings.volume =
        values.volume === "number" ? values.volume : parseInt(values.volume, 10)
      temp.generalSettings.functionalityMode =
        values.functionalityMode === "number"
          ? values.functionalityMode
          : parseInt(values.functionalityMode, 10)
      temp.generalSettings.language =
        values.language === "number"
          ? values.language
          : parseInt(values.language, 10)
      temp.generalSettings.profile =
        values.profile === "number"
          ? values.profile
          : parseInt(values.profile, 10)
      temp.generalSettings.timezone =
        values.timezone === "number"
          ? values.timezone
          : parseInt(values.timezone, 10)
      temp.generalSettings.statusUpdateInterval =
        values.statusUpdateInterval === "number"
          ? values.statusUpdateInterval
          : parseInt(values.statusUpdateInterval, 10)
      temp.generalSettings.userMode =
        values.userMode === "number"
          ? values.userMode
          : parseInt(values.userMode, 10)

      temp.deviceCallList = {}
      temp.deviceCallList.answerMachineAvoidance = values.answerMachineAvoidance
      temp.deviceCallList.ringingTimeout =
        values.ringingTimeout === "number"
          ? values.ringingTimeout
          : parseInt(values.ringingTimeout, 10)
      temp.deviceCallList.whitelistingEnabled = values.whitelistingEnabled
      temp.deviceCallList.callCenterId =
        values.callCenterId === "number"
          ? values.callCenterId
          : parseInt(values.callCenterId, 10)
      temp.deviceCallList.callList = callList
      temp.homeBeacons = homeBeacons

      temp.notifications = {}
      temp.notifications.manDown = {
        call: [{ receiver: "sosNumber", name: "", userId: 17821 }],
      }

      if (!updating) {
        setUpdating(true)
        setNavigilSettings(abortRef.current, props.deviceId, temp)
          .then(result => {
            if (result.statusCode == 200) {
              getSettings()
              showToast(props.t("Updated"), "success")
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setUpdating(false)
          })
      }
    },
  })

  // Beacon validation
  const beaconValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      address: "",
      mac: "",
      latitude: "61.489390",
      longitude: "23.780688",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required field")),
      address: Yup.string().required(props.t("Required field")),
      mac: Yup.string()
        .matches(
          /^([0-9A-Fa-f]{2}:){5}([0-9A-Fa-f]{2})$/,
          props.t("Invalid format")
        )
        .required(props.t("Required field")),
      latitude: Yup.string()
        .required(props.t("Required field"))
        .test(
          "lat",
          "Invalid value",
          val => !isNaN(val) && Math.abs(val) <= 90
        ),
      longitude: Yup.string()
        .required(props.t("Required field"))
        .test(
          "lng",
          "Invalid value",
          val => !isNaN(val) && Math.abs(val) <= 180
        ),
    }),
    onSubmit: values => {
      console.log("New beacon: ", values)
      setHomeBeacons(prevState => [...prevState, values])
      setBeaconModal(false)
    },
  })

  // Contact validation
  const contactValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      number: "",
      email: "",
      helpRequest: true,
      hiddenContact: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required field")),
      number: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("New contact: ", values)
      setCallList(prevState => [...prevState, values])
      setContactModal(false)
    },
  })

  // Location changed in map
  const mapLocationChanged = latLng => {
    console.log("mapLocationChanged", latLng)
    beaconValidation.setFieldValue("latitude", latLng.lat.toFixed(6))
    beaconValidation.setFieldValue("longitude", latLng.lng.toFixed(6))
  }

  // Table structures
  const callColumns = [
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "number",
      text: props.t("Phone number"),
      sort: true,
    },
    {
      dataField: "helpRequest",
      text: props.t("Help request"),
      sort: true,
    },
    {
      dataField: "hiddenContact",
      text: props.t("Hidden contact"),
      sort: true,
    },
    {
      dataField: "actions",
      isDummy: true,
      text: props.t("Actions"),
      sort: true,
      align: "center",
      formatter: contactActionsFormatter,
    },
  ]

  function contactActionsFormatter(cell, row) {
    return (
      <>
        <Button
          color="danger"
          outline
          size="sm"
          onClick={() => {
            setCallList(prevState =>
              prevState.filter(prevItem => prevItem.name !== row.name)
            )
          }}
        >
          <i className="mdi mdi-trash-can" />
        </Button>
      </>
    )
  }

  const beaconColumns = [
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "mac",
      text: props.t("MAC"),
      sort: true,
    },
    {
      dataField: "address",
      text: props.t("Address"),
      sort: true,
    },
    {
      dataField: "latitude",
      text: props.t("Latitude"),
      sort: true,
    },
    {
      dataField: "longitude",
      text: props.t("Longitude"),
      sort: true,
    },
    {
      dataField: "actions",
      isDummy: true,
      text: props.t("Actions"),
      sort: true,
      align: "center",
      formatter: beaconActionsFormatter,
    },
  ]

  function beaconActionsFormatter(cell, row) {
    return (
      <>
        <Button
          color="danger"
          outline
          size="sm"
          onClick={() => {
            setHomeBeacons(prevState =>
              prevState.filter(prevItem => prevItem.mac !== row.mac)
            )
          }}
        >
          <i className="mdi mdi-trash-can" />
        </Button>
      </>
    )
  }

  const geofenceColumns = [
    {
      dataField: "geofenceAreaName",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "type",
      text: props.t("Type"),
      sort: true,
    },
    {
      dataField: "radius",
      text: props.t("Radius"),
      sort: true,
    },
    {
      dataField: "coordinates",
      text: props.t("Coordinates"),
      sort: true,
    },
  ]

  return (
    <>
      <div className="mb-3">
        <Button
          color="primary"
          disabled={loading || updating || !props.deviceId}
          onClick={() => {
            getSettings()
          }}
        >
          <i className="me-2 bx bxs-download text-truncate align-middle"></i>
          {props.t("Get settings")}
        </Button>

        <Button
          color="primary"
          className="ms-3"
          disabled={loading || updating || !settings}
          onClick={() => {
            setDefaultValues()
          }}
        >
          <i className="me-2 bx bxs-cog text-truncate align-middle"></i>
          {props.t("Set default values")}
        </Button>

        <Button
          color="success"
          className="ms-3"
          disabled={loading || updating || !settings}
          onClick={() => {
            validation.submitForm()
          }}
        >
          <i className="me-2 bx bxs-save text-truncate align-middle"></i>
          {props.t("Save changes")}
        </Button>

        {/** 
        <Button color="primary" className="ms-2" disabled>
          {props.t("Fetch ID")}
        </Button>*/}
      </div>

      {!props.deviceId && (
        <Alert color="danger">{props.t("Missing device ID")}</Alert>
      )}

      {loading && (
        <Lottie
          options={getLottieLoadingOptions()}
          height={100}
          width={100}
        ></Lottie>
      )}

      {!loading && !settings && (
        <Card>
          <CardBody>
            <div className="text-center mb-4">
              <Lottie
                options={getLottieNothingFound()}
                height={150}
                width={150}
              />
            </div>
          </CardBody>
        </Card>
      )}

      {!loading && settings && (
        <Card>
          <CardBody className="p-0">
            <UncontrolledAccordion flush defaultOpen={["1"]} stayOpen>
              <AccordionItem>
                <AccordionHeader targetId="1">
                  {props.t("User")}
                </AccordionHeader>
                <AccordionBody
                  accordionId="1"
                  className="pt-0"
                  style={{ paddingTop: "0px" }}
                >
                  <Row xs={2} md={3}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("First name")}</Label>
                        <Input
                          bsSize="sm"
                          name="firstName"
                          type="text"
                          value={validation.values.firstName}
                          onChange={validation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Last name")}</Label>
                        <Input
                          bsSize="sm"
                          name="lastName"
                          type="text"
                          value={validation.values.lastName}
                          onChange={validation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>

            <hr className="m-0" />

            <UncontrolledAccordion flush defaultOpen={["2"]} stayOpen>
              <AccordionItem>
                <AccordionHeader targetId="2">
                  {props.t("Locationing info")}
                </AccordionHeader>
                <AccordionBody
                  accordionId="2"
                  className="pt-0"
                  style={{ paddingTop: "0px" }}
                >
                  <Row xs={2} md={3}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Data retention")}</Label>
                        <Input
                          bsSize="sm"
                          name="dataRetentionDays"
                          type="select"
                          value={validation.values.dataRetentionDays}
                          onChange={validation.handleChange}
                        >
                          <option value="30">
                            {30 + " " + props.t("days")}
                          </option>
                          <option value="90">
                            {90 + " " + props.t("days")}
                          </option>
                          <option value="180">
                            {180 + " " + props.t("days")}
                          </option>
                          <option value="360">
                            {360 + " " + props.t("days")}
                          </option>
                          <option value="720">
                            {720 + " " + props.t("days")}
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Location allowed")}</Label>
                        <Switch
                          className="d-block"
                          name="locationAllowed"
                          checked={validation.values.locationAllowed}
                          onChange={v => {
                            validation.setFieldValue("locationAllowed", v)
                          }}
                          checkedChildren={<i className="mdi mdi-check-bold" />}
                          unCheckedChildren={
                            <i className="mdi mdi-close-thick" />
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Emergency location allowed")}</Label>
                        <Switch
                          className="d-block"
                          name="emergencyLocationAllowed"
                          checked={validation.values.emergencyLocationAllowed}
                          onChange={v => {
                            validation.setFieldValue(
                              "emergencyLocationAllowed",
                              v
                            )
                          }}
                          checkedChildren={<i className="mdi mdi-check-bold" />}
                          unCheckedChildren={
                            <i className="mdi mdi-close-thick" />
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>

            <hr className="m-0" />
            <UncontrolledAccordion flush defaultOpen={["3"]} stayOpen>
              <AccordionItem>
                <AccordionHeader targetId="3">
                  {props.t("General settings")}
                </AccordionHeader>
                <AccordionBody
                  accordionId="3"
                  className="pt-0"
                  style={{ paddingTop: "0px" }}
                >
                  <Row xs={2} md={3}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Functionality mode")}</Label>
                        <Input
                          bsSize="sm"
                          name="functionalityMode"
                          type="select"
                          value={validation.values.functionalityMode}
                          onChange={validation.handleChange}
                        >
                          {funcModes.map(item => (
                            <option key={item.id} value={item.id}>
                              {props.t(item.langKey)}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Profile")}</Label>
                        <Input
                          bsSize="sm"
                          name="profile"
                          type="select"
                          value={validation.values.profile}
                          onChange={validation.handleChange}
                        >
                          {profiles.map(item => (
                            <option key={item.id} value={item.id}>
                              {props.t(item.profileName)}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Language")}</Label>
                        <Input
                          bsSize="sm"
                          name="language"
                          type="select"
                          value={validation.values.language}
                          onChange={validation.handleChange}
                        >
                          {languages.map(item => (
                            <option key={item.id} value={item.id}>
                              {props.t(item.langKey)}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Timezone")}</Label>
                        <Input
                          bsSize="sm"
                          name="timezone"
                          type="select"
                          value={validation.values.timezone}
                          onChange={validation.handleChange}
                        >
                          {timezones.map(item => (
                            <option key={item.id} value={item.id}>
                              {props.t(item.langKey)}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Status update interval")}</Label>
                        <Input
                          bsSize="sm"
                          name="statusUpdateInterval"
                          type="select"
                          value={validation.values.statusUpdateInterval}
                          onChange={validation.handleChange}
                        >
                          {updateIntervals.map(item => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("User mode")}</Label>
                        <Input
                          bsSize="sm"
                          name="userMode"
                          type="select"
                          value={validation.values.userMode}
                          onChange={validation.handleChange}
                        >
                          {userModes.map(item => (
                            <option key={item.id} value={item.id}>
                              {props.t(item.langKey)}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label>{props.t("Volume")}</Label>
                    <div className="pb-2 px-2" style={{ maxWidth: "600px" }}>
                      <Box sx={{ width: "100%" }}>
                        <Slider
                          min={1}
                          max={10}
                          marks={{
                            1: { label: "1" },
                            10: { label: "10" },
                          }}
                          value={validation.values.volume}
                          onChange={value => {
                            validation.setFieldValue("volume", value)
                          }}
                        />
                      </Box>
                    </div>
                  </FormGroup>
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>

            <hr className="m-0" />
            <UncontrolledAccordion flush defaultOpen={["4"]} stayOpen>
              <AccordionItem>
                <AccordionHeader targetId="4">
                  {props.t("Contacts")}
                </AccordionHeader>
                <AccordionBody
                  accordionId="4"
                  className="pt-0"
                  style={{ paddingTop: "0px" }}
                >
                  <Row xs={2} md={3}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Ringing timeout")}</Label>
                        <Input
                          bsSize="sm"
                          name="ringingTimeout"
                          type="select"
                          value={validation.values.ringingTimeout}
                          onChange={validation.handleChange}
                        >
                          <option value="20">{"20s"}</option>
                          <option value="30">{"30s"}</option>
                          <option value="60">{"60s"}</option>
                          <option value="90">{"90s"}</option>
                          <option value="120">{"120s"}</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Answer machine avoidance")}</Label>
                        <Switch
                          className="d-block"
                          name="answerMachineAvoidance"
                          checked={validation.values.answerMachineAvoidance}
                          onChange={v => {
                            validation.setFieldValue(
                              "answerMachineAvoidance",
                              v
                            )
                          }}
                          checkedChildren={<i className="mdi mdi-check-bold" />}
                          unCheckedChildren={
                            <i className="mdi mdi-close-thick" />
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Whitelisting enabled")}</Label>
                        <Switch
                          className="d-block"
                          name="whitelistingEnabled"
                          checked={validation.values.whitelistingEnabled}
                          onChange={v => {
                            validation.setFieldValue("whitelistingEnabled", v)
                          }}
                          checkedChildren={<i className="mdi mdi-check-bold" />}
                          unCheckedChildren={
                            <i className="mdi mdi-close-thick" />
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Call center")}</Label>
                        <Input
                          bsSize="sm"
                          name="callCenterId"
                          type="select"
                          value={validation.values.callCenterId}
                          onChange={validation.handleChange}
                        >
                          {callCenters.map(item => (
                            <option key={item.id} value={item.id}>
                              {item.name + ": " + item.number}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      pageStartIndex: 1,
                      totalSize: callList.length,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="number"
                        columns={callColumns}
                        data={callList}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-1">
                              <Col className="col-auto">
                                <Button
                                  size="sm"
                                  style={{ borderRadius: "100px" }}
                                  color="success"
                                  onClick={() => {
                                    setContactModal(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus me-2" />
                                  {props.t("Add")}
                                </Button>
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    id="call-table"
                                    keyField={"number"}
                                    responsive
                                    bordered={true}
                                    striped={true}
                                    hover={true}
                                    defaultSorted={[
                                      {
                                        dataField: "name",
                                        order: "asc",
                                      },
                                    ]}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    headerClasses="header-class"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>

            <hr className="m-0" />
            <UncontrolledAccordion flush defaultOpen={["5"]} stayOpen>
              <AccordionItem>
                <AccordionHeader targetId="5">
                  {props.t("Home beacons")}
                </AccordionHeader>
                <AccordionBody
                  accordionId="5"
                  className="pt-0"
                  style={{ paddingTop: "0px" }}
                >
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      pageStartIndex: 1,
                      totalSize: homeBeacons.length,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="mac"
                        columns={beaconColumns}
                        data={homeBeacons}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-1">
                              <Col className="col-auto">
                                <Button
                                  size="sm"
                                  style={{ borderRadius: "100px" }}
                                  color="success"
                                  onClick={() => {
                                    setBeaconModal(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus me-2" />
                                  {props.t("Add")}
                                </Button>
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    id="beacons-table"
                                    keyField={"mac"}
                                    responsive
                                    bordered={true}
                                    striped={true}
                                    hover={true}
                                    defaultSorted={[
                                      {
                                        dataField: "mac",
                                        order: "asc",
                                      },
                                    ]}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    headerClasses="header-class"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>

            <hr className="m-0" />
            <UncontrolledAccordion flush defaultOpen={["6"]} stayOpen>
              <AccordionItem>
                <AccordionHeader targetId="6">
                  {props.t("Geofences")}
                </AccordionHeader>
                <AccordionBody
                  accordionId="6"
                  className="pt-0"
                  style={{ paddingTop: "0px" }}
                >
                  <PaginationProvider
                    pagination={paginationFactory({
                      custom: true,
                      pageStartIndex: 1,
                      totalSize: geofences.length,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={geofenceColumns}
                        data={geofences}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mt-2">
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    id="geofence-table"
                                    keyField={"id"}
                                    responsive
                                    bordered={true}
                                    striped={true}
                                    hover={true}
                                    defaultSorted={[
                                      {
                                        dataField: "geofenceAreaName",
                                        order: "asc",
                                      },
                                    ]}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    headerClasses="header-class"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>
          </CardBody>
        </Card>
      )}

      <Modal
        isOpen={contactModal}
        toggle={() => {
          setContactModal(!contactModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Contact")}</ModalHeader>
        <ModalBody>
          <Row xs={1} lg={2}>
            <Col>
              <FormGroup>
                <Label>{props.t("Name")}</Label>
                <Input
                  bsSize="sm"
                  name="name"
                  type="text"
                  placeholder="Maija Meikäläinen"
                  value={contactValidation.values.name}
                  onChange={contactValidation.handleChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{props.t("Number")}</Label>
                <Input
                  bsSize="sm"
                  name="number"
                  type="text"
                  placeholder="358501234567"
                  value={contactValidation.values.number}
                  onChange={contactValidation.handleChange}
                />
              </FormGroup>
            </Col>
            {/** 
            <Col>
              <FormGroup>
                <Label>{props.t("Email")}</Label>
                <Input
                  bsSize="sm"
                  name="email"
                  type="text"
                  placeholder="email@example.com"
                  value={contactValidation.values.email}
                  onChange={contactValidation.handleChange}
                />
              </FormGroup>
            </Col>
            */}
            <Col>
              <FormGroup>
                <Label>{props.t("Help request")}</Label>
                <Switch
                  className="d-block"
                  name="helpRequest"
                  checked={contactValidation.values.helpRequest}
                  onChange={v => {
                    contactValidation.setFieldValue("helpRequest", v)
                  }}
                  checkedChildren={<i className="mdi mdi-check-bold" />}
                  unCheckedChildren={<i className="mdi mdi-close-thick" />}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{props.t("Hidden contact")}</Label>
                <Switch
                  className="d-block"
                  name="hiddenContact"
                  checked={contactValidation.values.hiddenContact}
                  onChange={v => {
                    contactValidation.setFieldValue("hiddenContact", v)
                  }}
                  checkedChildren={<i className="mdi mdi-check-bold" />}
                  unCheckedChildren={<i className="mdi mdi-close-thick" />}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setContactModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            color="success"
            onClick={() => {
              contactValidation.submitForm()
            }}
          >
            {props.t("Add")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={beaconModal}
        toggle={() => {
          setBeaconModal(!beaconModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Home beacon")}</ModalHeader>
        <ModalBody>
          <Row xs={1} lg={2}>
            <Col>
              <FormGroup>
                <Label>{props.t("Name")}</Label>
                <Input
                  bsSize="sm"
                  name="name"
                  type="text"
                  placeholder="Olohuone"
                  value={beaconValidation.values.name}
                  onChange={beaconValidation.handleChange}
                  invalid={
                    !!(beaconValidation.touched.name &&
                    beaconValidation.errors.name)
                  }
                />
                {beaconValidation.touched.name &&
                beaconValidation.errors.name ? (
                  <FormFeedback type="invalid">
                    {beaconValidation.errors.name}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{props.t("MAC")}</Label>
                <Input
                  bsSize="sm"
                  name="mac"
                  type="text"
                  placeholder="11:22:33:AA:BB:0F"
                  value={beaconValidation.values.mac}
                  onChange={beaconValidation.handleChange}
                  invalid={
                    !!(beaconValidation.touched.mac && beaconValidation.errors.mac)
                  }
                />
                {beaconValidation.touched.mac && beaconValidation.errors.mac ? (
                  <FormFeedback type="invalid">
                    {beaconValidation.errors.mac}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{props.t("Address")}</Label>
                <Input
                  bsSize="sm"
                  name="address"
                  type="text"
                  placeholder="Tivolikuja 1"
                  value={beaconValidation.values.address}
                  onChange={beaconValidation.handleChange}
                  invalid={
                    !!(beaconValidation.touched.address &&
                    beaconValidation.errors.address)
                  }
                />
                {beaconValidation.touched.address &&
                beaconValidation.errors.address ? (
                  <FormFeedback type="invalid">
                    {beaconValidation.errors.address}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col style={{ alignSelf: "center" }}>
              <Button
                size="sm"
                style={{ width: "100%" }}
                color="primary"
                disabled={fetching || !beaconValidation.values.address}
                onClick={() => {
                  getCoordinates(beaconValidation.values.address)
                }}
              >
                {props.t("Get coordinates")}
              </Button>
            </Col>
            <Col>
              <FormGroup>
                <Label>{props.t("Latitude")}</Label>
                <Input
                  bsSize="sm"
                  name="latitude"
                  type="text"
                  placeholder="60.188209"
                  value={beaconValidation.values.latitude}
                  onChange={beaconValidation.handleChange}
                  invalid={
                    !!(beaconValidation.touched.latitude &&
                    beaconValidation.errors.latitude)
                  }
                />
                {beaconValidation.touched.latitude &&
                beaconValidation.errors.latitude ? (
                  <FormFeedback type="invalid">
                    {beaconValidation.errors.latitude}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{props.t("Longitude")}</Label>
                <Input
                  bsSize="sm"
                  name="longitude"
                  type="text"
                  placeholder="24.940707"
                  value={beaconValidation.values.longitude}
                  onChange={beaconValidation.handleChange}
                  invalid={
                    !!(beaconValidation.touched.longitude &&
                    beaconValidation.errors.longitude)
                  }
                />
                {beaconValidation.touched.longitude &&
                beaconValidation.errors.longitude ? (
                  <FormFeedback type="invalid">
                    {beaconValidation.errors.longitude}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ height: "260px" }}>
            <Col>
              <DefaultMap
                zoom={10}
                lat={beaconValidation.values.latitude}
                lng={beaconValidation.values.longitude}
                infoHeader={props.t("Address")}
                infoText={beaconValidation.values.address}
                markerEdit={true}
                markerChanged={mapLocationChanged}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setBeaconModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            color="success"
            onClick={() => {
              beaconValidation.submitForm()
            }}
          >
            {props.t("Add")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

NavigilSettings.propTypes = {
  t: PropTypes.any,
  selectedRow: PropTypes.any,
  deviceId: PropTypes.string,
}

export default withRouter(withTranslation()(NavigilSettings))
