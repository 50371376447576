import React from "react"

import { Container, Row, Col } from "reactstrap"
import packageInfo from "../../../package.json"

//Page URL
import { useDevEnvironment } from "../../utils/general"

const Footer = () => {
  // Dev environment used?
  const {
    isTestEnvironment = false,
    isBetaEnvironment = false,
    isLocalhostEnvironment = false,
  } = useDevEnvironment()

  const isDevEnvironment =
    isTestEnvironment || isBetaEnvironment || isLocalhostEnvironment

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col xs={8}>
              <div className="text-sm d-none d-sm-block">
                <p>
                  {/* Show only version number for prod */}
                  {"v." + packageInfo.version}
                  {/* Show also build number in dev */}
                  {isDevEnvironment && " build " + packageInfo.build}
                </p>
              </div>
            </Col>
            <Col xs={4}>
              <div className="text-sm-end d-none d-sm-block">
                {new Date().getFullYear()} | Suvanto Care
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
