import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import { withTranslation } from "react-i18next"
import { nanoid } from "nanoid"
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap"
import Select from "react-select"
import CustomModal from "./components/CustomModal"
import fi from "@fullcalendar/core/locales/fi"
import en from "@fullcalendar/core/locales/en-gb"
import se from "@fullcalendar/core/locales/sv"
import events from "./events"
// import "./custom.css"
import { getGroupsAppointments } from "helpers/backend_helper"
import moment from "moment"

const CalendarTest = props => {
  const [weekendsVisible, setWeekendsVisible] = useState(true)
  const [currentEvents, setCurrentEvents] = useState([])
  const [modal, setModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const calendarRef = useRef(null)
  const abortRef = useRef(null)

  const [title, setTitle] = useState("")

  const [type, setType] = useState("")
  const [start, setStart] = useState(new Date())
  const [end, setEnd] = useState(new Date())
  const [language, setLanguage] = useState(fi)
  const [appointmentsData, setAppointmentsData] = useState()
  const [groups, setGroups] = useState([])

  // Get appointments
  const getAppointments = () => {
    getGroupsAppointments(
      abortRef.current,
      "DemoCustomer",
      "admins",
      moment().startOf("year"),
      moment().endOf("year")
    )
      .then(result => {
        if (result.statusCode == 200 && result) {
          setGroups(result.groups)
          let opts = []
          result.items.forEach(p => {
            let groupObject = new Object()
            groupObject.id = p.id
            groupObject.title = p.title
            groupObject.groupId = p.group
            groupObject.start = new Date(p.start_time)
            groupObject.end = new Date(p.end_time)
            // groupObject.end = moment(p.scheduledDate).add(p.duration, "minutes")
            groupObject.duration = p.duration
            // groupObject.className = p.key != null ? "confirm" : "assign"
            // groupObject.repetitionType = p.repetitionType
            // groupObject.expirationDate = p.expirationDate
            groupObject.type = p.type
            // groupObject.interval = p.interval
            opts.push(groupObject)
          })
          console.log(opts)
          setAppointmentsData(opts)
          console.log(result)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})
  }
  useEffect(() => {
    // const user = JSON.parse(state.authUser)
    // let key = user.username
    // setUserName(key)
    abortRef.current = new AbortController()
    getAppointments()
    return () => abortRef.current.abort()
  }, [])

  const handleCloseModal = () => {
    handleClose()
    setModal(false)
  }

  const handleDateClick = arg => {
    // bind with an arrow function
    // console.log(arg.dateStr);
  }

  const handleDateSelect = selectInfo => {
    if (
      selectInfo.view.type === "timeGridWeek" ||
      selectInfo.view.type === "timeGridDay"
    ) {
      selectInfo.view.calendar.unselect()
      setState({ selectInfo, state: "create" })
      setStart(selectInfo.start)
      setEnd(selectInfo.end)
      setModal(true)
    }
  }

  const renderEventContent = eventInfo => {
    return (
      <div>
        <i
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {eventInfo.event.title}
        </i>
      </div>
    )
  }

  const handleEventClick = clickInfo => {
    console.log(clickInfo)
    setState({ clickInfo, state: "update" })
    setTitle(clickInfo.event.title)
    setType(clickInfo.event.type)
    setTitle(clickInfo.event.title)
    setTitle(clickInfo.event.title)
    setStart(clickInfo.event.start)
    setEnd(clickInfo.event.end)
    setModal(true)
  }

  const handleEvents = events => {
    setCurrentEvents(events)
  }

  const handleEventDrop = checkInfo => {
    setState({ checkInfo, state: "drop" })
    setConfirmModal(true)
  }

  const handleEventResize = checkInfo => {
    setState({ checkInfo, state: "resize" })
    setConfirmModal(true)
  }

  const handleEdit = () => {
    state.clickInfo.event.setStart(start)
    state.clickInfo.event.setEnd(end)
    state.clickInfo.event.mutate({
      standardProps: { title },
    })
    handleClose()
  }

  const handleSubmit = () => {
    const newEvent = {
      id: nanoid(),
      title,
      start: state.selectInfo?.startStr || start.toISOString(),
      end: state.selectInfo?.endStr || end.toISOString(),
      allDay: state.selectInfo?.allDay || false,
    }

    let calendarApi = calendarRef.current.getApi()
    calendarApi.addEvent(newEvent)
    handleClose()
  }

  const handleDelete = () => {
    state.clickInfo.event.remove()
    handleClose()
  }

  const handleClose = () => {
    setTitle("")
    setStart(new Date())
    setEnd(new Date())
    setState({})
    setModal(false)
  }

  const [state, setState] = useState({})

  const [departments, setDepartments] = useState([
    { value: "1", label: "All" },
    { value: "2", label: "BPA Technical" },
    { value: "3", label: "Aqua 2 Cleaning" },
  ])

  const onFilter = element => {
    console.log(element.value)
  }

  return (
    <div className="">
      <Row>
        <Col md="2"></Col>
        <Col md={12}>
          {/* <Container style={{ height: "80vh" }}> */}
          <div className="px-4 py-3">
            <FullCalendar
              locales={[fi, se, en]}
              locale={language}
              ref={calendarRef}
              // customButtons={{
              //   myCustomButton: {
              //     text: "custom!",
              //     click: function () {
              //       alert("clicked the custom button!")
              //     },
              //   },
              // }}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                // left: "myCustomButton prev,today,next",
                left: "prev,today,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
              }}
              buttonText={{
                today: props.t("Today"),
                month: props.t("month"),
                week: props.t("week"),
                day: props.t("day"),
                list: props.t("list"),
              }}
              displayEventTime={true}
              initialView="timeGridWeek"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={weekendsVisible}
              // select={handleDateSelect}
              eventContent={renderEventContent}
              // eventClick={handleEventClick}
              events={appointmentsData}
              // eventColor="#378006"
              eventsSet={() => handleEvents(events)}
              eventDrop={handleEventDrop}
              // eventResize={handleEventResize}
              // dateClick={handleDateClick}
            />
          </div>
          {/* </Container> */}
        </Col>
      </Row>

      <CustomModal
        title={state.state === "update" ? "Update Event" : "Add Event"}
        isOpen={modal}
        toggle={handleCloseModal}
        onCancel={handleCloseModal}
        onSubmit={state.clickInfo ? handleEdit : handleSubmit}
        submitText={state.clickInfo ? "Update" : "Save"}
        onDelete={state.clickInfo && handleDelete}
        deleteText="Delete"
      >
        <Row>
          <Col sm="6">
            <Label for="exampleEmail">{props.t("Customer")}</Label>
            <p>{title}</p>
          </Col>
          <Col sm="6">
            <Label for="exampleEmail">{props.t("Customer")}</Label>
            <p>{type}</p>
          </Col>
          <Col sm="6"></Col>
          <Col sm="6"></Col>
        </Row>
        <FormGroup>
          {/* <Input
            type="text"
            name="title"
            placeholder="with a placeholder"
            value={title}
            onChange={e => setTitle(e.target.value)}
          /> */}
        </FormGroup>
      </CustomModal>

      <CustomModal
        title={state.state === "resize" ? "Resize Event" : "Drop Event"}
        isOpen={confirmModal}
        toggle={() => {
          state.checkInfo.revert()
          setConfirmModal(false)
        }}
        onCancel={() => {
          state.checkInfo.revert()
          setConfirmModal(false)
        }}
        cancelText="Cancel"
        onSubmit={() => setConfirmModal(false)}
        submitText={"OK"}
      >
        Do you want to {state.state} this event?
      </CustomModal>
    </div>
  )
}

CalendarTest.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CalendarTest)
