import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  FormFeedback,
  Label,
  Alert,
  UncontrolledTooltip,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"
import Lottie from "react-lottie"
import {
  getLottieLoadingOptions,
  getLottieSearch,
  showToast,
} from "utils/general"
import SelectGroup from "components/DropdownList/SelectGroup"
import {
  createGroup,
  deleteGroup,
  getAvailableTags,
  getGroupSettings,
  setSupportToUsers,
  updateGroupSettings,
} from "helpers/backend_helper"
import * as Yup from "yup"
import { useFormik } from "formik"
import TagsTree from "components/DropdownList/TagsTree"
import TagManager from "components/Modals/TagManager"
import PubSub from "pubsub-js"
import SelectAdminGroup from "components/DropdownList/SelectAdminGroup"

const GroupsManagement = props => {
  const [group, setGroup] = useState(null)
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [groupSettings, setGroupSettings] = useState(null)

  const [groupAddModal, setGroupAddModal] = useState(false)
  const [groupDeleteModal, setGroupDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [ongoingAdd, setOngoingAdd] = useState(false)
  const [updateCounter, setUpdateCounter] = useState(0)

  const [showTagModal, setShowTagModal] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Groups") + " | Suvanto Care"
    abortRef.current = new AbortController()
    getTags()

    // Refresh tags
    const token = PubSub.subscribe("tags-refresh", (event, data) => {
      getTags()
    })

    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  // Set URL dynamically to group
  useEffect(() => {
    if (group) {
      navigate(`?${group}`, { replace: true })
    } else {
      navigate(`?`, { replace: true })
    }
  }, [group, navigate])

  // Set group according to URL
  useEffect(() => {
    const queryString = location.search.slice(1)
    if (queryString) {
      const decodedGroup = decodeURIComponent(queryString)
      setGroup(decodedGroup)
    } else {
      setGroup(null)
    }
  }, [])

  // Group changed
  useEffect(() => {
    if (group) {
      getSettings()
    }
  }, [group])

  // Get tags
  const getTags = () => {
    getAvailableTags(abortRef.current)
      .then(result => {
        if (result.statusCode == 200) {
          //console.log("Tags: ", result.tags)
          result.tags.sort((a, b) => (a.title > b.title ? 1 : -1))
          result.tags.forEach(tag => {
            tag.children.sort((a, b) => (a.title > b.title ? 1 : -1))
          })
          setTags(result.tags)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Get group settings
  const getSettings = () => {
    setLoading(true)
    setSelectedTags([])
    setGroupSettings(null)
    getGroupSettings(abortRef.current, group)
      .then(result => {
        if (result.statusCode == 200) {
          //console.log("Settings: ", result.data)
          setGroupSettings(result.data)
          setSelectedTags(result.data.tags)
        } else throw new Error("Unexpected result")
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
        setGroup(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Update validation
  const updateValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      group: groupSettings?.group || "",
      supportType: groupSettings?.supportType || "0",
      themeId: groupSettings?.themeId || 0,
      adminGroup: groupSettings?.adminGroup || undefined,
      dataRetentionReadonly: groupSettings?.dataRetentionReadonly || false,
      defaultPersonalJournalOnly:
        groupSettings?.defaultPersonalJournalOnly || false,
    },
    validationSchema: Yup.object({
      supportType: Yup.number().required(props.t("Required field")),
    }),
    onSubmit: values => {
      let temp = JSON.parse(JSON.stringify(values))
      temp.supportType = parseInt(values.supportType, 10)
      if (typeof values.themeId == "string")
        temp.themeId = parseInt(values.themeId, 10)
      temp.tags = selectedTags
      temp.adminGroup = values.adminGroup ?? ""
      // console.log("Submit: ", temp)

      if (group && !updating) {
        setUpdating(true)
        updateGroupSettings(abortRef.current, group, temp)
          .then(result => {
            if (result.statusCode == 200) {
              getSettings()
              showToast(props.t("Updated"), "success")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setUpdating(false)
          })
      }
    },
  })

  // Create group validation
  const groupValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      group: "",
    },
    validationSchema: Yup.object({
      group: Yup.string()
        .matches(
          /^[A-ZÄÖÅ0-9]+[a-zäöåA-ZÄÖÅ0-9- ]*[a-zäöåA-ZÄÖÅ0-9]$/,
          props.t("Invalid format")
        )
        .min(4, props.t("Too short"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      setOngoingAdd(true)
      createGroup(abortRef.current, values.group)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Created"), "success")
            setGroupAddModal(false)
            groupValidation.resetForm()
            setUpdateCounter(prevCount => prevCount + 1)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAdd(false)
        })
    },
  })

  // Delete selected group
  const groupDelete = () => {
    if (group) {
      setOngoingAdd(true)
      deleteGroup(abortRef.current, group)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Deleted"), "success")
            setGroupDeleteModal(false)
            setGroup(null)
            setUpdateCounter(prevCount => prevCount + 1)
          } else if (result.statusCode == 403) {
            showToast(
              props.t("Operation failed") +
                ", " +
                props.t("reason") +
                ": " +
                result.reason,
              "error"
            )
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAdd(false)
        })
    }
  }

  // Set support type to all group members
  const setSupport = () => {
    if (group && !updating) {
      setUpdating(true)
      setSupportToUsers(abortRef.current, group)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdating(false)
        })
    }
  }

  // Admin group selection changed
  const adminGroupChanged = newSelection => {
    updateValidation.setFieldValue("adminGroup", newSelection)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Groups")} />

          <Row className="mb-4">
            <Col>
              <SelectGroup
                value={group}
                onChange={setGroup}
                multiple={false}
                updateCounter={updateCounter}
              />
            </Col>
            <Col className="col-auto d-flex">
              <Button
                id="add-button"
                color="success"
                className="square-icon-button-sm"
                disabled={loading}
                onClick={() => {
                  setGroupAddModal(true)
                }}
              >
                <UncontrolledTooltip placement="bottom" target="add-button">
                  {props.t("Create a new group")}
                </UncontrolledTooltip>
                <i className="mdi mdi-account-multiple-plus font-size-18"></i>
              </Button>
              <Button
                id="delete-button"
                color="danger"
                className="square-icon-button-sm ms-3"
                disabled={!group || loading}
                onClick={() => {
                  setGroupDeleteModal(true)
                }}
              >
                <UncontrolledTooltip placement="bottom" target="delete-button">
                  {props.t("Delete group")}
                </UncontrolledTooltip>
                <i className="mdi mdi-trash-can font-size-18"></i>
              </Button>
            </Col>
          </Row>

          {!group && !loading && (
            <Card>
              <CardBody>
                <div className="text-center my-5">
                  <Lottie
                    options={getLottieSearch()}
                    height={150}
                    width={180}
                  ></Lottie>
                </div>
              </CardBody>
            </Card>
          )}

          {loading && (
            <Card>
              <CardBody>
                <div className="text-center my-5">
                  <Lottie
                    options={getLottieLoadingOptions()}
                    height={100}
                    width={100}
                  ></Lottie>
                </div>
              </CardBody>
            </Card>
          )}

          {group && groupSettings && !loading && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {props.t("Settings")}
                    </CardTitle>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        return false
                      }}
                    >
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Tags")}</Label>
                            <TagsTree
                              data={tags}
                              selected={groupSettings.tags}
                              selectionChanged={setSelectedTags}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Button
                        size="sm"
                        className="mb-4"
                        color="secondary"
                        onClick={() => {
                          setShowTagModal(!showTagModal)
                        }}
                      >
                        {props.t("Tag manager")}
                      </Button>

                      {/** 
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Organizations")}</Label>
                            <SelectOrganization loadData={true} multiple={true} clearable={true}/>
                          </FormGroup>
                        </Col>
                      </Row>
                      */}

                      <Row xs={1} sm={2}>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Support")}</Label>
                            <Input
                              //bsSize="sm"
                              name="supportType"
                              type="select"
                              value={updateValidation.values.supportType}
                              onChange={updateValidation.handleChange}
                            >
                              <option value="0">{props.t("Default")}</option>
                              <option value="3">
                                {props.t("Suvanto extended support")}
                              </option>
                              <option value="1">{"Elisa"}</option>
                              <option value="4">
                                {props.t("Elisa extended support")}
                              </option>
                              <option value="2">{"2M-IT"}</option>
                              <option value="5">
                                {props.t("2M-IT extended support")}
                              </option>
                              <option value="6">
                                {props.t("Istekki-Pirha")}
                              </option>
                              <option value="7">
                                {props.t("Istekki-PSHVA")}
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Theme")}</Label>
                            <Input
                              //bsSize="sm"
                              name="themeId"
                              type="select"
                              value={updateValidation.values.themeId}
                              onChange={updateValidation.handleChange}
                            >
                              <option value={0}>{props.t("Default")}</option>
                              {/* HOX: Changed for testing reasons */}
                              <option value={10}>{props.t("Test")}</option>
                            </Input>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label>{props.t("Admin group")}</Label>
                            <SelectAdminGroup
                              selection={updateValidation.values.adminGroup}
                              onChange={adminGroupChanged}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Data retention period")}</Label>
                            <Input
                              name="dataRetentionReadonly"
                              type="select"
                              value={
                                updateValidation.values.dataRetentionReadonly
                              }
                              onChange={updateValidation.handleChange}
                            >
                              <option value={false}>
                                {props.t("Editable")}
                              </option>
                              <option value={true}>
                                {props.t("Read only")}
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label id="defaultPersonalJournalOnlyTitle">
                              {props.t("Show only personal journal entries")}
                            </Label>
                            <Input
                              name="defaultPersonalJournalOnly"
                              type="select"
                              value={
                                updateValidation.values
                                  .defaultPersonalJournalOnly
                              }
                              onChange={updateValidation.handleChange}
                            >
                              <option value={false}>{props.t("Off")}</option>
                              <option value={true}>{props.t("On")}</option>
                              <UncontrolledTooltip
                                placement="top"
                                target="defaultPersonalJournalOnlyTitle"
                              >
                                {props.t("Default setting for new app users")}
                              </UncontrolledTooltip>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="mt-2 mb-3 d-flex justify-content-center">
                        <Button
                          color="primary"
                          disabled={updating}
                          style={{ minWidth: "150px" }}
                          onClick={() => {
                            updateValidation.submitForm()
                          }}
                        >
                          {props.t("Save")}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{props.t("Actions")}</CardTitle>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem className="pb-4">
                        <Row>
                          <Col>
                            <div>
                              <h6>{props.t("Set support type")}</h6>
                              <span className="text-muted">
                                {props.t(
                                  "Set groups support type to all members of this group"
                                )}
                              </span>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              color="primary"
                              disabled={updating}
                              style={{ minWidth: "100px" }}
                              onClick={() => {
                                setSupport()
                              }}
                            >
                              {props.t("Set")}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <TagManager isOpen={showTagModal} stateChanged={setShowTagModal} />

          <Modal
            isOpen={groupAddModal}
            toggle={() => {
              setGroupAddModal(!groupAddModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create a new group")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <Alert color={"primary"}>
                  <span>{props.t("GroupAllowedChars")}</span>
                </Alert>
                <FormGroup>
                  <Label>{props.t("Name")}</Label>
                  <Input
                    name="group"
                    type="text"
                    onChange={groupValidation.handleChange}
                    onBlur={groupValidation.handleBlur}
                    value={groupValidation.values.group || ""}
                    invalid={
                      !!(
                        groupValidation.touched.group &&
                        groupValidation.errors.group
                      )
                    }
                  ></Input>
                  {groupValidation.touched.group &&
                  groupValidation.errors.group ? (
                    <FormFeedback type="invalid">
                      {groupValidation.errors.group}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setGroupAddModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingAdd}
                  onClick={() => {
                    groupValidation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>{" "}
              </ModalFooter>
            </Form>
          </Modal>

          <Modal
            isOpen={groupDeleteModal}
            toggle={() => {
              setGroupDeleteModal(!groupDeleteModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
            <ModalBody>
              <p>
                {props.t("Are you sure you want to delete") +
                  ": " +
                  group +
                  "?"}
              </p>
              <Alert color={"primary"}>
                <span>
                  {props.t(
                    "Deletion will fail if the group has any users or devices attached to it!"
                  )}
                </span>
              </Alert>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setGroupDeleteModal(false)
                }}
              >
                {props.t("Cancel")}
              </Button>
              <Button
                disabled={ongoingAdd}
                color="danger"
                onClick={() => {
                  groupDelete()
                }}
              >
                {props.t("Delete")}
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </>
  )
}

GroupsManagement.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(GroupsManagement)
