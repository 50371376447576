import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Card } from "reactstrap"
import { withTranslation } from "react-i18next"
import { formatDateFull } from "utils/general"

const VideoMessageStatus = props => {
  const [message] = useState(props.message)
  // Shows latest message answers
  const [openMessageIndex, setOpenMessageIndex] = useState(0)

  const toggleMessage = index => {
    if (openMessageIndex === index) {
      setOpenMessageIndex(null)
    } else {
      setOpenMessageIndex(index)
    }
  }

  const formatAnswer = answer => {
    switch (answer) {
      case -1:
        return (
          <span className="text-danger">
            <i className="mdi mdi-message-alert-outline me-2"></i>
            <em>{props.t("Error sending message")}</em>
          </span>
        )
      case 3:
        return (
          <span className="text-success">
            <i className="mdi mdi-reply me-2"></i>
            {props.t("OK")}
          </span>
        )
      case 4:
        return (
          <span className="text-success">
            <i className="mdi mdi-reply me-2"></i>
            {props.t("Yes")}
          </span>
        )
      case 5:
        return (
          <span className="text-success">
            <i className="mdi mdi-reply me-2"></i>
            {props.t("No")}
          </span>
        )
      case 6:
        return (
          <span className="text-muted">
            <i className="mdi mdi-message-off-outline me-2"></i>
            <em>{props.t("Message expired")}</em>
          </span>
        )
      default:
        return (
          <span className="text-info">
            <i className="mdi mdi-send me-2"></i>
            <em>{props.t("Message sent")}</em>
          </span>
        )
    }
  }

  return (
    <>
      {message.length > 0 ? (
        <>
          {message.map((message, key) => (
            <div key={""+key} className="">
              <Card className="p-3 mb-3 shadow-none">
                <div className="font-size-17 font-weight-lighter mb-1">
                  {message.message}
                </div>
                <div className="mb-4">{formatDateFull(message.sent_at)}</div>
                <Button
                  color="transparent"
                  className="px-0 py-1 mb-0"
                  style={{ cursor: "pointer" }}
                  id={`arrow-${key}`}
                  onClick={() => toggleMessage(key)}
                >
                  <h6 className="text-start mb-0">
                    <span className="float-end" style={{ cursor: "pointer" }}>
                      <i
                        className={`mdi ${
                          openMessageIndex === key
                            ? "mdi-chevron-up"
                            : "mdi-chevron-down"
                        }`}
                      ></i>
                    </span>
                    {props.t("Answers")} ({message.response_count}/
                    {message.recipients_count})
                  </h6>
                </Button>
                {openMessageIndex === key &&
                  message.recipients?.map((r, index) => (
                    <div key={""+index} className="mt-1">
                      <div>
                        {r.name}
                        <span className="float-end font-size-13">
                          {formatAnswer(r.status)}
                        </span>
                      </div>
                    </div>
                  ))}
              </Card>
            </div>
          ))}
        </>
      ) : (
        <div className="text-center p-4">
          <p className="text-muted">
            {props.t("No messages found")}
          </p>
        </div>
      )}
    </>
  )
}

VideoMessageStatus.propTypes = {
  t: PropTypes.any,
  message: PropTypes.any,
}

export default withTranslation()(VideoMessageStatus)
