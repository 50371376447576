import PropTypes from "prop-types"
import React, { useState, useEffect, useContext, useRef } from "react"
import { Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import GlobalState from "../../contexts/GlobalState"
import withRouter from "components/Common/withRouter"

const Navbar = props => {
  const [state] = useContext(GlobalState)
  const [isAdmin, setIsAdmin] = useState(false)
  const [showDevMenu, setShowDevMenu] = useState(false)
  const [showSalesMenu, setShowSalesMenu] = useState(false)
  const [showVideoMenu, setShowVideoMenu] = useState(false)
  const [showGroupCall, setShowGroupCall] = useState(false)
  const [showGroupMessage, setShowGroupMessage] = useState(false)
  const [showVideoAppSettings, setShowVideoAppSettings] = useState(false)
  const [showVideoSystem, setShowVideoSystem] = useState(false)
  const [showMonitoringMenu, setShowMonitoringMenu] = useState(false)
  const [showAlarmMonitoring, setShowAlarmMonitoring] = useState(false)
  const [showOfflineVideos, setShowOfflineVideos] = useState(false)
  const [showReporting, setShowReporting] = useState(false)
  const [showCustomers] = useState(true)
  const [showAppointments, setShowAppointments] = useState(false)
  const [showAdminManagement, setShowAdminManagement] = useState(false)
  const [role, setRole] = useState("")
  const [devtools, setDevtools] = useState(false)
  const [videomenu, setVideomenu] = useState(false)
  const [salesmenu, setSalesmenu] = useState(false)
  const [devices, setDevices] = useState(false)
  const [logs, setLogs] = useState(false)
  const [messaging, setMessaging] = useState(false)
  const [exports, setExports] = useState(false)
  const [monitoringmenu, setMonitoringmenu] = useState(false)
  const [tests, setTests] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    setMenuStates()
  }, [])

  useEffect(() => {
    setMenuStates()
  }, [state.authUser])

  useEffect(() => {
    let matchingMenuItem = null
    let ul = document.getElementById("navigation")
    let items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (const element of items) {
      // console.log(window.location.pathname, items[i].pathname)
      // Original
      if (window.location.pathname === element.pathname) {
        // if (window.location.pathname.includes(items[i].pathname)) {
        matchingMenuItem = element
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  // Set menu visibility states
  const setMenuStates = () => {
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setRole(role)

      // Reset states first
      setIsAdmin(false)
      setShowDevMenu(false)
      setShowSalesMenu(false)
      setShowMonitoringMenu(false)
      setShowAlarmMonitoring(false)
      setShowOfflineVideos(false)
      setShowReporting(false)
      setShowVideoMenu(false)
      setShowVideoSystem(false)
      setShowAppointments(false)
      setShowAdminManagement(false)
      setShowGroupCall(false)
      setShowGroupMessage(false)
      setShowVideoAppSettings(false)

      // Allow by role
      if (role == "system_admin") {
        setIsAdmin(true)
        setShowDevMenu(true)
        setShowSalesMenu(true)
        setShowAlarmMonitoring(true)
        setShowReporting(true)

        setShowOfflineVideos(true)
        setShowMonitoringMenu(true)
        setShowVideoMenu(true)
        setShowVideoSystem(true)
        //setShowAppointments(true)
        setShowGroupMessage(true)
        setShowVideoAppSettings(true)
      }
      if (role == "salesperson") {
        setShowSalesMenu(true)
        setShowReporting(true)
      }
      if (role == "order_manager") {
        setShowSalesMenu(true)
        setShowReporting(true)
      }
      if (role == "organisation_admin") {
        setShowReporting(true)
      }

      if (obj.groupVideoId != null && obj.groupVideoId.length > 0) {
        setShowGroupCall(true)
        setShowVideoMenu(true)
      }
      if (obj.access_permissions?.alarm_monitoring) {
        setShowAlarmMonitoring(true)
        setShowMonitoringMenu(true)
      }

      if (obj.access_permissions?.offline_video_monitoring) {
        setShowOfflineVideos(true)
        setShowMonitoringMenu(true)
      }

      if (obj.access_permissions?.video_system) {
        setShowVideoSystem(true)
        setShowVideoMenu(true)
      }

      if (obj.access_permissions?.appointments) {
        setShowAppointments(true)
      }

      if (obj.access_permissions?.groupMessages) {
        setShowGroupMessage(true)
        setShowVideoMenu(true)
      }

      if (obj.access_permissions?.admin_management) {
        setShowDevMenu(true)
        setShowAdminManagement(true)
      }
    }
  }

  const removeActivation = items => {
    for (const element of items) {
      let item = element
      const parent = element.parentElement
      if (item?.classList?.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList?.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  // Close dropdown when clicking away
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDevtools(false)
        setSalesmenu(false)
        setMonitoringmenu(false)
        setVideomenu(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  return (
    <React.Fragment>
      <div ref={dropdownRef} className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav ">
                <li className="nav-item text-truncate">
                  <Link
                    className="nav-link d-flex"
                    style={{ alignItems: "center" }}
                    to="/main"
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Front page")}
                  </Link>
                </li>
                {/*<li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <i className="bx bxs-dashboard me-2"></i>
                    {props.t("Dashboard")}
                  </Link>
                </li>*/}

                {showCustomers && (
                  <li className="nav-item text-truncate">
                    <Link
                      className="nav-link d-flex"
                      style={{ alignItems: "center" }}
                      to="/clients"
                    >
                      <i className="bx bxs-user me-2"></i>
                      {props.t("Customer management")}
                    </Link>
                  </li>
                )}

                {/**showGroupCall && (
                  <li className="nav-item text-truncate">
                    <Link className="nav-link d-flex" style={{ alignItems: "center" }} to="/group-call">
                      <i className="bx bxs-group me-2"></i>
                      {props.t("Group video call")}
                    </Link>
                  </li>
                )*/}

                {showVideoMenu && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-truncate d-flex"
                      style={{ alignItems: "center" }}
                      onClick={e => {
                        e.preventDefault()
                        setVideomenu(!videomenu)
                      }}
                      to="/#"
                    >
                      <i className="bx bxs-video me-2"></i>
                      {props.t("Group video calls")}
                      <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname("dropdown-menu", {
                        show: videomenu,
                      })}
                    >
                      {showGroupCall && (
                        <Link to="/group-call" className="dropdown-item">
                          {props.t("Group video call")}
                        </Link>
                      )}
                      {showVideoSystem && (
                        <Link
                          to="/config/video-system"
                          className="dropdown-item"
                        >
                          {props.t("Content events")}
                        </Link>
                      )}
                      {showGroupMessage && (
                        <Link
                          to="/video/group-messages"
                          className="dropdown-item"
                        >
                          {props.t("Group messages")}
                        </Link>
                      )}
                      {showGroupMessage && (
                        <Link
                          to="/video/app-settings"
                          className="dropdown-item"
                        >
                          {props.t("Video app settings")}
                        </Link>
                      )}
                    </div>
                  </li>
                )}

                {showMonitoringMenu && (
                  <li className="nav-item dropdown ">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-truncate d-flex"
                      style={{ alignItems: "center" }}
                      onClick={e => {
                        e.preventDefault()
                        setMonitoringmenu(!monitoringmenu)
                      }}
                      to="/#"
                    >
                      <i className="mdi mdi-monitor-eye me-2"></i>
                      {props.t("Monitoring")}
                      <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname("dropdown-menu", {
                        show: monitoringmenu,
                      })}
                    >
                      {showAlarmMonitoring && (
                        <Link className="dropdown-item" to="/monitoring/alarms">
                          {props.t("Alarm monitoring")}
                        </Link>
                      )}
                      {showOfflineVideos && (
                        <Link
                          className="dropdown-item"
                          to="/monitoring/offline-videos"
                        >
                          {props.t("Offline videos")}
                        </Link>
                      )}
                    </div>
                  </li>
                )}

                {showReporting && (
                  <li className="nav-item text-truncate">
                    <Link
                      className="nav-link d-flex"
                      style={{ alignItems: "center" }}
                      to="/reporting"
                    >
                      <i className="bx bxs-report me-2"></i>
                      {props.t("Reports")}
                    </Link>
                  </li>
                )}

                {showSalesMenu && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-truncate d-flex"
                      style={{ alignItems: "center" }}
                      onClick={e => {
                        e.preventDefault()
                        setSalesmenu(!salesmenu)
                      }}
                      to="/#"
                    >
                      <i className="bx bxs-dollar-circle me-2"></i>
                      {props.t("Sales")}
                      <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname("dropdown-menu", {
                        show: salesmenu,
                      })}
                    >
                      <Link
                        to="/sales/service-monitoring"
                        className="dropdown-item"
                      >
                        {props.t("Customers and services")}
                      </Link>
                      <Link to="/sales/orders" className="dropdown-item">
                        {props.t("Orders")}
                      </Link>
                    </div>
                  </li>
                )}

                {showAppointments && (
                  <li className="nav-item text-truncate">
                    <Link
                      className="nav-link d-flex"
                      style={{ alignItems: "center" }}
                      to="/appointments"
                    >
                      <i className="bx bxs-calendar me-2"></i>
                      {props.t("Appointments")}
                    </Link>
                  </li>
                )}

                {showDevMenu && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-truncate d-flex"
                      style={{ alignItems: "center" }}
                      onClick={e => {
                        e.preventDefault()
                        setDevtools(!devtools)
                      }}
                      to="/#"
                    >
                      <i className="bx bxs-cog me-2"></i>
                      {props.t("Admin tools")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", { show: devtools })}
                    >
                      {showAdminManagement && (
                        <Link
                          to="/admin/user-management"
                          className="dropdown-item"
                        >
                          {props.t("User management")}
                        </Link>
                      )}

                      {isAdmin && (
                        <>
                          <div className="nav-item dropdown">
                            <Link
                              className="dropdown-item"
                              onClick={e => {
                                e.preventDefault()
                                setDevices(!devices)
                              }}
                              to="/#"
                            >
                              {props.t("Devices")}
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: devices,
                              })}
                            >
                              <Link
                                to="/config/gateways"
                                className="dropdown-item"
                              >
                                {props.t("Gateways")}
                              </Link>
                              <Link
                                to="/config/trackers"
                                className="dropdown-item"
                              >
                                {props.t("DevMenu.Trackers")}
                              </Link>
                              <Link
                                to="/config/video-devices"
                                className="dropdown-item"
                              >
                                {props.t("Video devices")}
                              </Link>
                              <Link
                                to="/config/video-numbers"
                                className="dropdown-item"
                              >
                                {props.t("Video numbers")}
                              </Link>
                              <Link
                                to="/config/carephones"
                                className="dropdown-item"
                              >
                                {props.t("Carephones")}
                              </Link>
                              <Link
                                to="/config/pindoras"
                                className="dropdown-item"
                              >
                                {props.t("Smartlocks")}
                              </Link>
                              {/** 
                              <Link
                                to="/config/doses"
                                className="dropdown-item"
                              >
                                {props.t("Doses")}
                              </Link>*/}
                              <Link
                                to="/config/measuringdevices"
                                className="dropdown-item"
                              >
                                {props.t("Measuring devices")}
                              </Link>
                            </div>
                          </div>

                          <div className="nav-item dropdown">
                            <Link
                              className="dropdown-item"
                              onClick={e => {
                                e.preventDefault()
                                setLogs(!logs)
                              }}
                              to="/#"
                            >
                              {props.t("Logs")}
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: logs,
                              })}
                            >
                              <Link to="/logs/system" className="dropdown-item">
                                {props.t("System logs")}
                              </Link>
                              <Link
                                to="/logs/gateway"
                                className="dropdown-item"
                              >
                                {props.t("Gateway logs")}
                              </Link>
                              <Link to="/logs/sms" className="dropdown-item">
                                {props.t("SMS logs")}
                              </Link>
                              {/** 
                              <Link
                                to="/logs/feedback"
                                className="dropdown-item"
                              >
                                {props.t("Feedback")}
                              </Link>*/}
                              <Link
                                to="/logs/audit-logs"
                                className="dropdown-item"
                              >
                                {props.t("Audit logs")}
                              </Link>
                            </div>
                          </div>

                          <div className="nav-item dropdown">
                            <Link
                              className="dropdown-item"
                              onClick={e => {
                                e.preventDefault()
                                setMessaging(!messaging)
                              }}
                              to="/#"
                            >
                              {props.t("Messaging")}
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: messaging,
                              })}
                            >
                              <Link
                                to="/messaging/releases"
                                className="dropdown-item"
                              >
                                {props.t("Release notes")}
                              </Link>
                              <Link
                                to="/messaging/errorbulletins"
                                className="dropdown-item"
                              >
                                {props.t("Error bulletins")}
                              </Link>
                              <Link
                                to="/messaging/email-releases"
                                className="dropdown-item"
                              >
                                {props.t("Email bulletins")}
                              </Link>
                              {/** 
                              <span className="dropdown-item disabled">
                                <i className="bx bxs-lock me-2"></i>
                                {props.t("Direct messaging")}
                              </span>*/}
                            </div>
                          </div>
                          <div className="nav-item dropdown">
                            <Link
                              className="dropdown-item"
                              onClick={e => {
                                e.preventDefault()
                                setExports(!exports)
                              }}
                              to="/#"
                            >
                              {props.t("Reporting")}
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: exports,
                              })}
                            >
                              <Link
                                to="/reporting/support-tickets"
                                className="dropdown-item"
                              >
                                {props.t("Support tickets") + " SLA"}
                              </Link>
                            </div>
                          </div>
                          <Link to="/config/admins" className="dropdown-item">
                            {props.t("Admin users")}
                          </Link>
                          <Link to="/config/groups" className="dropdown-item">
                            {props.t("Groups")}
                          </Link>
                          <Link to="/config/clients" className="dropdown-item">
                            {props.t("Clients")}
                          </Link>

                          <div className="nav-item dropdown">
                            <Link
                              className="dropdown-item"
                              onClick={e => {
                                e.preventDefault()
                                setTests(!tests)
                              }}
                              to="/#"
                            >
                              {props.t("Other")}
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: tests,
                              })}
                            >
                              {!showAppointments && (
                                <Link
                                  to="/appointments"
                                  className="dropdown-item"
                                >
                                  {props.t("Appointments")}
                                </Link>
                              )}
                              <Link
                                to="/admin/user-management"
                                className="dropdown-item"
                              >
                                {props.t("User management")}
                              </Link>
                              <Link
                                to="/instructions"
                                className="dropdown-item"
                              >
                                {props.t("Instructions")}
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </li>
                )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Navbar))
