import React, { useEffect, useState, useContext, useRef } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
  CardTitle,
  Container,
  NavItem,
  NavLink,
  TabContent,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
  Dropdown,
  TabPane,
  Button,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import moment from "moment"
import Lottie from "react-lottie"

// Entries related imports
import classnames from "classnames"
import img1 from "../../assets/images/small/img-6.jpg"
import iconVideocall from "../../assets/images/icons/videocall.svg"
import iconApp from "../../assets/images/icons/app.svg"
import iconMedicine from "../../assets/images/icons/medicineReminder.svg"
import iconGroupvideo from "../../assets/images/icons/groupvideo.png"
import iconWebpageAlt from "../../assets/images/icons/webIconAlt.svg"

//Translation
import { withTranslation } from "react-i18next"

import "assets/scss/datatables.scss"

import Breadcrumbs from "components/Common/Breadcrumb"
import { getReleaseNotes, getStatusReports } from "helpers/database_helper"
import GlobalState from "contexts/GlobalState"
import ReleaseListItem from "./release-list-item"
import StatusReportItem from "./status-report-item"
import Manual from "components/Manual/Manual"

import {
  getLocaleText,
  getLottieLoadingOptions,
  showToast,
} from "utils/general"

const LandingPage = props => {
  const [cogOpen, setCogOpen] = useState(false)

  const [state] = useContext(GlobalState)

  // Entries related
  const [activeTab, setActiveTab] = useState("1")
  const [firstName, setFirstName] = useState("")
  const [supportType, setSupportType] = useState(null)

  const [releaseNotes, setReleaseNotes] = useState([])
  const [releaseNotesLoading, setReleaseNotesLoading] = useState(true)
  const [releaseNotesNone, setReleaseNotesNone] = useState(false)

  const [statusReports, setStatusReports] = useState([])
  const [statusReportsLoading, setStatusReportsLoading] = useState(true)
  const [statusReportsNone, setStatusReportsNone] = useState(false)
  const [ongoingIssues, setOngoingIssues] = useState(false)
  const [resolvedIssues, setResolvedIssues] = useState(false)

  const [supportEmail, setSupportEmail] = useState("")
  const [supportGsm, setSupportGsm] = useState("040 6577 343")
  const [supportGsm2, setSupportGsm2] = useState()
  const [showFaq, setShowFaq] = useState(false)
  const [secureSupportMail, setSecureSupportMail] = useState(
    "https://secmail.com/send/NDVSnGhL"
  )

  // Check if card is null/"true"/"false" in localStorage
  const [welcomeCardOpen, setWelcomeCardOpen] = useState(
    localStorage.getItem("welcomeCard") !== "false"
  )

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Front page") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Get support type from user
  useEffect(() => {
    if (state.authUser != null) {
      const user = JSON.parse(state.authUser)
      const name = user.firstName
      setFirstName(name)
      setSupportType(user.supportType)
      if (user.supportType != null) {
        let prefix, domain
        switch (user.supportType) {
          // Elisa
          case 1:
            prefix = "digihoiva.tuki"
            domain = "elisa.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("050 3571 727")
            setShowFaq(false)
            setSecureSupportMail(null)
            break
          // 2M-IT
          case 2:
            prefix = "servicedesk"
            domain = "2m-it.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm(null)
            setShowFaq(false)
            setSecureSupportMail(null)
            break
          // Laajennettu tuki
          case 3:
            prefix = "tuki"
            domain = "suvantocare.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("040 6577 343")
            setSupportGsm2("040 6577 378")
            setShowFaq(true)
            setSecureSupportMail("https://secmail.com/send/NDVSnGhL")
            break
          // Elisa laajennettu tuki
          case 4:
            prefix = "digihoiva.tuki"
            domain = "elisa.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("050 3571 727")
            setSupportGsm2("040 6577 378")
            setShowFaq(false)
            setSecureSupportMail(null)
            break
          // 2M-IT laajennettu, ei oikeita tietoja vielä
          case 5:
            prefix = "sovellustuki"
            domain = "2m-it.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("010 198 015")
            setShowFaq(false)
            setSecureSupportMail(null)
            break
          // Istekki-Pirha
          case 6:
            prefix = "1144.kayttotuki"
            domain = "pirha.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("03 3117 1144")
            setShowFaq(false)
            setSecureSupportMail(null)
            break
          // Istekki-PSHVA
          case 7:
            prefix = "asiakaspalvelut"
            domain = "istekki.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("017 173 900")
            setShowFaq(false)
            setSecureSupportMail(null)
            break
          default:
            prefix = "tuki"
            domain = "suvantocare.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("040 6577 343")
            setShowFaq(true)
            setSecureSupportMail("https://secmail.com/send/NDVSnGhL")
            break
        }
      }
    }
  }, [])

  // Release notes
  useEffect(() => {
    getReleaseNotes(abortRef.current, true, 5, null)
      .then(notes => {
        let array = []
        notes.forEach(note => {
          let niceDate = moment(note.informed_date).format("DD.MM.YYYY")
          let temp = new Object()
          temp.guid = note.guid
          temp.id = note.id
          temp.date = niceDate
          temp.title = note.target + " v." + note.version
          temp.type = note.type == 1 ? "Hotfix" : "Update"
          temp.version = note.version

          if (note.release_date) {
            temp.releaseDate = moment(note.release_date).format("DD.MM.YYYY")
          }

          switch (note.target) {
            case 1:
              temp.target = "ReleaseNoteTypes.1" // Suvanto Care app
              temp.img = iconApp
              break
            case 2:
              temp.target = "ReleaseNoteTypes.2" // Suvanto Health
              temp.img = iconMedicine
              break
            case 3:
              temp.target = "ReleaseNoteTypes.3" // Suvanto Video
              temp.img = iconVideocall
              break
            case 4:
              temp.target = "ReleaseNoteTypes.4" // Suvanto Care webpage
              temp.img = iconWebpageAlt
              break
            case 5:
              temp.target = "ReleaseNoteTypes.5" // Group video call
              temp.img = iconGroupvideo
              break
            /*case 6:
              temp.target = "Medicine reminder"
              temp.img = iconMedicine
              break*/
            default:
              temp.target = "Unknown"
              break
          }

          switch (note.status) {
            case 1:
              temp.status = "Upcoming"
              break
            case 2:
              temp.status = "Waiting for release"
              break
            case 3:
              temp.status = "Postponed"
              break
            case 4:
              temp.status = "Published"
              break
            default:
              temp.status = "Unknown"
              break
          }

          array.push(temp)
        })
        setReleaseNotes(array)
        setReleaseNotesLoading(false)
        if (notes.length == 0) setReleaseNotesNone(true)
      })
      .catch(err => {
        console.log("Release notes error: ", err)
        setReleaseNotesLoading(false)
        setReleaseNotesNone(true)
      })
  }, [])

  // Status reports
  useEffect(() => {
    getStatusReports(abortRef.current, true, 10)
      .then(reports => {
        let issues = false
        let resolved = false
        let array = []
        reports.forEach(rep => {
          let niceDate = moment(rep.start_time).format("DD.MM.YYYY HH:mm")
          if (rep.end_time) {
            let endDate = new Date(rep.end_time)
            if (endDate > 0) {
              let endDateNice = moment(rep.end_time).format("DD.MM.YYYY HH:mm")
              niceDate = niceDate + " - " + endDateNice
            }
          }

          let temp = new Object()
          temp.guid = rep.guid
          temp.id = rep.id
          temp.date = niceDate
          temp.title = rep.header_fi

          let lang = getLocaleText() ?? "en"
          switch (lang) {
            case "en":
              if (rep.header_en) temp.title = rep.header_en
              break
            case "sv":
              if (rep.header_sv) temp.title = rep.header_sv
              break
          }

          let targets = []
          let split = rep.target_groups.split(",")
          split.forEach(target => {
            switch (target) {
              case "1":
                targets.push("Suvanto Care app")
                break
              case "2":
                targets.push("Video call")
                break
              case "3":
                targets.push("Group video call")
                break
              case "4":
                targets.push("Carephone")
                break
              case "5":
                targets.push("Medicine reminder")
                break
              case "6":
                targets.push("Suvanto On the Go")
                break
              case "7":
                targets.push("Suvanto Home")
                break
              case "8":
                targets.push("Smartlock")
                break
              case "9":
                targets.push("Suvanto Care webpage")
                break
            }
          })
          temp.targets = targets

          switch (rep.current_status) {
            case 1:
              issues = true
              temp.status = "Service outage"
              temp.img = img1
              temp.color = "#f46a6a"
              break
            case 2:
              issues = true
              temp.status = "Service disruption"
              temp.img = img1
              temp.color = "#f1b44c"
              break
            case 3:
              resolved = true
              temp.status = "Service operational"
              temp.img = img1
              temp.color = "#34c38f"
              break
          }

          array.push(temp)
        })

        setStatusReports(array)
        setOngoingIssues(issues)
        setResolvedIssues(resolved)

        // Show disturbance notices automatically if ongoing issues
        if (issues) toggle("2")

        setStatusReportsLoading(false)
        if (reports.length == 0) setStatusReportsNone(true)
      })
      .catch(err => {
        console.log("Status reports error: ", err)
        setStatusReportsLoading(false)
        setStatusReportsNone(true)
      })
  }, [])

  // Change card view
  const changeView = i => {
    switch (i) {
      case 1: {
        let value = JSON.stringify(!welcomeCardOpen)
        setWelcomeCardOpen(prevState => !prevState)
        localStorage.setItem("welcomeCard", value)
        return
      }
      case 2:
        // TODO: rest of cards modify
        setWelcomeCardOpen(prevState => !prevState)
        return
    }
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const typeInstructions = t => {
    // console.log(t, "supporttype")
    switch (t) {
      case 1:
        return "file-digihoiva"
      case 4:
        return "file-digihoiva"
      case 6:
        return "file-istekki"
      case 7:
        return "file-istekki"
      default:
        return "file"
    }
  }
  const onButtonClick = t => {
    let type = typeInstructions(t)
    // console.log(type, "type")
    fetch("/files/" + type + ".pdf").then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob)
        let alink = document.createElement("a")
        alink.href = fileURL
        alink.download = props.t("Instructions") + ".pdf"
        alink.click()
      })
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            className="breadcrumb m-0 justify-content-center"
            title={props.t("Front page")}
            breadcrumbItem={
              <>
                <div className="d-inline-block mb-0">
                  <Dropdown
                    isOpen={cogOpen}
                    toggle={() => setCogOpen(!cogOpen)}
                  >
                    <DropdownToggle
                      id="cog-button"
                      className="btn nav-btn d-inline-flex me-1"
                      tag="a"
                      style={{ padding: "6px 8px" }}
                    >
                      <i className="bx bx-cog font-size-16"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end py-2 px-3">
                      <h6 className="pb-2 pt-1">{props.t("Modify view")}</h6>
                      <div className="pb-1 text-muted">
                        <input
                          type="checkbox"
                          id="welcomecard"
                          autoComplete="off"
                          className="form-check-input  me-2"
                          defaultChecked={!!welcomeCardOpen}
                          onChange={() => {
                            changeView(1)
                          }}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="welcomecard"
                        >
                          <p className="mb-2">{props.t("Welcome card")}</p>
                        </label>
                      </div>
                      {/* <div className="pb-1 text-muted">
                        <input
                          disabled={true}
                          type="checkbox"
                          id="welcomecard"
                          className="form-check-input me-2 "
                          defaultChecked={true}
                          // onChange={() => {
                          //   changeView(2)
                          // }}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="welcomecard"
                        >
                          <p className="mb-2">{props.t("Support card")}</p>
                        </label>
                      </div>
                      <div className="pb-1 text-muted">
                        <input
                          disabled={true}
                          type="checkbox"
                          id="welcomecard"
                          className="form-check-input me-2 "
                          defaultChecked={true}
                          // onChange={() => {
                          //   changeView(3)
                          // }}
                        ></input>
                        <label
                          className="form-check-label "
                          htmlFor="welcomecard"
                        >
                          <p className="mb-2">{props.t("Bulletins card")}</p>
                        </label>
                      </div> */}
                    </DropdownMenu>
                  </Dropdown>
                  <UncontrolledTooltip placement="bottom" target="cog-button">
                    {props.t("View settings")}
                  </UncontrolledTooltip>
                </div>

                <div className="d-inline-block">
                  <Manual source="landing-page"></Manual>
                </div>
              </>
            }
          />
          <Col lg="12">
            <Row>
              <Col lg="6">
                <Collapse isOpen={welcomeCardOpen}>
                  <Card>
                    <div className="p-3 ms-2">
                      <h4 className="text-primary mt-1 mb-3">
                        {props.t("Hi")}, {firstName}!
                      </h4>
                      <button
                        type="button"
                        onClick={() => {
                          changeView(1)
                          showToast(
                            props.t(
                              "You can set the card to be visible from the settings button on the right"
                            ),
                            "info"
                          )
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <p className="my-1">{props.t("WhatsNew1")}.</p>
                    </div>
                  </Card>
                </Collapse>

                {/* SUPPORT CARD */}
                <Card>
                  <CardBody>
                    <CardTitle>{props.t("Instructions")}</CardTitle>
                    <Row>
                      <Col lg="12">
                        <p className="mb-0 mt-2 pt-1 text-muted">
                          {supportType == 6 || supportType == 7 ? (
                            <>{props.t("WhatsNew4")}.</>
                          ) : (
                            <>{props.t("WhatsNew3")}.</>
                          )}{" "}
                          {props.t("WhatsNew2")}.
                          {/* Oikeasta ylälaidasta <i className="bx bx-book-content mx-1"></i>
                          -painikkeesta voit tarkastella sivukohtaisia ohjeita. */}
                        </p>
                        <div className="py-2">
                          {supportType == 6 || supportType == 7 ? (
                            <a
                              href={"https://suvanto.help/istekki"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <li>{props.t("Help portal")}</li>
                            </a>
                          ) : (
                            <a
                              href={"https://suvanto.info"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <li>{props.t("Instructional videos")}</li>
                            </a>
                          )}
                        </div>
                        <div className="py-2 mt-1">
                          <Button
                            type="button"
                            color="primary"
                            // UPDATE: Update new instructions
                            onClick={() => onButtonClick(supportType)}
                            className="btn btn-primary px-3"
                          >
                            <i className="mdi mdi-file-download-outline me-2" />
                            {props.t("Download manual")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    {/* <hr className="text-gray" /> */}
                    <CardTitle>{props.t("Support")}</CardTitle>

                    <div>
                      {supportType !== 6 && supportType !== 7 ? (
                        // Other support
                        <p className="text-muted">
                          {props.t("Support description 1")}{" "}
                          {(supportType === 3 || supportType === 4) && (
                            <>{props.t("Support description 4")}</>
                          )}
                          {(supportType === 0 || supportType === 1) && (
                            <>{props.t("Support description 3")}</>
                          )}
                        </p>
                      ) : (
                        // Istekki support
                        <>
                          <p className="text-muted">
                            {props.t("Support description Istekki")}
                          </p>
                          <p className="text-muted">
                            {props.t("Support description Istekki 2")}
                          </p>
                        </>
                      )}

                      {showFaq && (
                        <p className="text-muted">
                          {props.t("Support description 2")}{" "}
                          <span className="text-primary">
                            <a
                              href={
                                "https://www.suvantocare.fi/kotona-asumisen-tukipalvelut/"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {props.t("this link")}
                            </a>
                          </span>
                          {"."}
                        </p>
                      )}
                      <Row>
                        <Col sm="8">
                          <div>
                            {supportType === 3 ||
                            supportType === 4 ||
                            supportType === 5 ? (
                              <p className="mt-3">
                                {props.t(
                                  "Extended support contact information"
                                )}
                              </p>
                            ) : (
                              <>
                                {supportType != 6 && supportType != 7 ? (
                                  // Other support
                                  <p className="mt-3">
                                    {props.t("Support contact information")}
                                  </p>
                                ) : (
                                  // Istekki support
                                  <></>
                                )}
                              </>
                            )}

                            <div className="text-muted">
                              {supportEmail != null && (
                                <p className="mb-1">
                                  <i className="mdi mdi-email align-middle text-primary me-1" />{" "}
                                  {supportEmail}
                                </p>
                              )}
                              {supportGsm != undefined && (
                                <p className="mb-1">
                                  <i className="mdi mdi-phone align-middle text-primary me-1" />{" "}
                                  {supportGsm}{" "}
                                  {supportType != 6 && supportType != 7 && (
                                    <span>{props.t("Support gsm 1")}</span>
                                  )}
                                </p>
                              )}
                              {supportGsm2 != undefined && (
                                <p className="mb-1">
                                  <i className="mdi mdi-phone-plus align-middle text-primary me-1" />{" "}
                                  {supportGsm2} {props.t("Support gsm 2")}
                                </p>
                              )}
                            </div>
                          </div>
                        </Col>
                        {secureSupportMail != null && (
                          <Col sm="4">
                            <div>
                              <p className="mt-3">
                                {props.t("Secure support email")}
                              </p>

                              <div className="text-muted">
                                <a
                                  href={secureSupportMail}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-secondary "
                                  >
                                    {props.t("Navigate")}
                                    <i className="bx bxs-send font-size-12 align-middle ms-2 "></i>
                                  </button>
                                </a>
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6">
                <Card>
                  <CardHeader className="bg-transparent border-bottom">
                    <div className="d-flex flex-wrap">
                      <div className="me-2">
                        <h5 className="card-title mt-1 mb-0">
                          {props.t("Bulletins")}
                        </h5>
                      </div>
                      <ul className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto">
                        <NavItem>
                          <NavLink
                            to="#"
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggle("1")
                            }}
                          >
                            {props.t("Releases")}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggle("2")
                            }}
                          >
                            {ongoingIssues && (
                              <span className="mdi mdi-alert-circle text-danger me-1" />
                            )}
                            {!ongoingIssues && resolvedIssues && (
                              <span className="mdi mdi-alert-circle text-success me-1" />
                            )}
                            {props.t("Error bulletins")}
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                  </CardHeader>

                  <CardBody className="pt-2">
                    <SimpleBar style={{ maxHeight: "100vh" }}>
                      <div>
                        {/* RELEASE NOTES */}
                        <TabContent activeTab={activeTab}>
                          <TabPane className="show" tabId="1">
                            {releaseNotesLoading && (
                              <Col lg={12} style={{ height: "100%" }}>
                                <Lottie
                                  options={getLottieLoadingOptions()}
                                  height={100}
                                  width={100}
                                />
                              </Col>
                            )}
                            {releaseNotesNone && (
                              <Col
                                className="mt-5 mb-5"
                                lg={12}
                                style={{ height: "100%" }}
                              >
                                <div className="text-center">
                                  <h6 className="text-muted">
                                    {props.t("No releases found")}
                                  </h6>
                                </div>
                              </Col>
                            )}
                            <ul className="list-group list-group-flush">
                              {releaseNotes.map(item => (
                                <li
                                  key={item.id}
                                  className="list-group-item pt-3 pb-2"
                                >
                                  <ReleaseListItem item={item} />
                                </li>
                              ))}
                            </ul>
                          </TabPane>

                          {/* STATUS REPORTS */}
                          <TabPane className="show" tabId="2">
                            {statusReportsLoading && (
                              <Col lg={12} style={{ height: "100%" }}>
                                <Lottie
                                  options={getLottieLoadingOptions()}
                                  height={100}
                                  width={100}
                                />
                              </Col>
                            )}
                            {statusReportsNone && (
                              <Col
                                className="mt-5 mb-5"
                                lg={12}
                                style={{ height: "100%" }}
                              >
                                <div className="text-center">
                                  <h6 className="text-muted">
                                    {props.t("No service issues found")}
                                  </h6>
                                </div>
                              </Col>
                            )}
                            <ul className="list-group list-group-flush">
                              {statusReports.map(item => (
                                <li
                                  key={item.id}
                                  className="list-group-item pt-3 pb-2"
                                >
                                  <StatusReportItem item={item} />
                                </li>
                              ))}
                            </ul>
                          </TabPane>
                        </TabContent>
                      </div>
                    </SimpleBar>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

LandingPage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(LandingPage)
