import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  NavItem,
  NavLink,
  TabContent,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabPane,
  CardHeader,
  Accordion,
  UncontrolledTooltip,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useLocation } from "react-router-dom"
import { withTranslation } from "react-i18next"
import dayjs from "dayjs"
import { getSupportSms, querySms } from "helpers/backend_helper"
import CustomRangePicker from "components/DatePickers/RangePicker"
import {
  dateFormatter,
  dateSorter,
  defaultPageSizeOptions,
  downloadExcel,
  showToast,
  stringSorter,
} from "utils/general"
import { Table as AntdTable } from "antd"
import { tableDefaultHeight } from "constants/layout"

const SmsLogs = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [supportMessages, setSupportMessages] = useState([])
  const [messages, setMessages] = useState([])
  const [filteredMessages, setFilteredMessages] = useState([])
  const [filtersOpen, setFiltersOpen] = useState("1")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [recipient, setRecipient] = useState("")
  const [sender, setSender] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  const [maxRows, setMaxRows] = useState("100")
  const [dateRange, setDateRange] = useState(null)
  const [first, setFirst] = useState(true)
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [title] = useState(props.t("SMS logs"))

  const search = useLocation().search
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    setDateRange([dayjs().subtract(1, "days"), dayjs()])
    const rec = new URLSearchParams(search).get("recipient")
    if (rec) {
      setRecipient(rec.trim())
    }
    return () => abortRef.current.abort()
  }, [])

  // Get messages on page load
  useEffect(() => {
    if (dateRange && first) {
      setFirst(false)
      getMessages(dateRange[0].valueOf(), dateRange[1].valueOf())
    }
  }, [dateRange])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, messages])

  // Handle search function
  const handleSearch = () => {
    let result = messages.filter(
      data =>
        data.message?.toLowerCase().includes(searchText) ||
        data.recipient?.toLowerCase().includes(searchText) ||
        data.sender?.toLowerCase().includes(searchText) ||
        data.source?.toLowerCase().includes(searchText) ||
        data.status?.toLowerCase().includes(searchText)
    )
    setFilteredMessages(result)
  }

  // Get messages
  const getMessages = (startMs, endMs) => {
    if (!ongoingSearch) {
      //console.log("GET SMS: ", startMs, endMs, recipient)
      setOngoingSearch(true)
      querySms(
        abortRef.current,
        startMs,
        endMs,
        sender,
        recipient,
        undefined,
        statusFilter.length > 0 ? parseInt(statusFilter, 10) : undefined,
        maxRows.length > 0 ? parseInt(maxRows, 10) : 100,
      )
        .then(result => {
          //console.log("care: ", result.entries)
          if (result.statusCode === 200 && result.entries != null) {
            setMessages(result.entries)
          } else {
            setMessages([])
          }
          setOngoingSearch(false)
        })
        .catch(err => {
          console.log(err)
          setMessages([])
          setOngoingSearch(false)
        })
    }
  }

  // Get support message
  const getSupportMessages = () => {
    if (!ongoingSearch) {
      setOngoingSearch(true)
      getSupportSms(abortRef.current)
        .then(result => {
          //console.log("care: ", result.entries)
          if (result.statusCode === 200 && result.entries != null) {
            setSupportMessages(result.entries)
          } else {
            setSupportMessages([])
          }
          setOngoingSearch(false)
        })
        .catch(err => {
          console.log(err)
          setSupportMessages([])
          setOngoingSearch(false)
        })
    }
  }

  // Table structure
  const columns = [
    {
      dataIndex: "statusTime",
      title: props.t("Updated"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["statusTime"], b["statusTime"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "status",
      title: props.t("Status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "status", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "message",
      title: props.t("Message"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "message", sortOrder)
      },
      width: "400px",
      ellipsis: true,
    },
    {
      dataIndex: "sender",
      title: props.t("Sender"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "sender", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "recipient",
      title: props.t("Recipient"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "recipient", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "source",
      title: props.t("Source"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "source", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
  ]

  const columns2 = [
    {
      dataIndex: "statusTime",
      title: props.t("Status updated"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["statusTime"], b["statusTime"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "message",
      title: props.t("Message"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "message", sortOrder)
      },
      width: "400px",
      ellipsis: true,
    },
    {
      dataIndex: "sender",
      title: props.t("Sender"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "sender", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
  ]

  const onDateSelection = value => {
    setDateRange(value)
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Updated"),
        props.t("Status"),
        props.t("Message"),
        props.t("Sender"),
        props.t("Recipient"),
        props.t("Source"),
      ],
    ]
    const data = filteredMessages.map(elt => [
      dateFormatter(elt.statusTime),
      elt.status,
      elt.message,
      elt.sender,
      elt.recipient,
      elt.source,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("SMS logs")} />
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul className="nav nav-tabs nav-tabs-custom card-header-tabs">
                  <NavItem>
                    <NavLink
                      to="#"
                      active={activeTab === "1"}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      {props.t("Search")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "2"}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
                      {props.t("Security mail")}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane className="show" tabId="1">
                  <Row>
                    <Col>
                      {messages != null && (
                        <React.Fragment>
                          <Row className="mb-1">
                            {" "}
                            <Col className="col-auto pe-0">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <div className="search-box me-2 mb-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      onChange={event =>
                                        setSearchText(
                                          event.target.value.toLowerCase()
                                        )
                                      }
                                      placeholder={props.t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                className="round-icon-button-sm"
                                id="filter-button"
                                color="primary"
                                outline
                                onClick={() => {
                                  filtersOpen.length > 0
                                    ? setFiltersOpen("")
                                    : setFiltersOpen("1")
                                }}
                              >
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="filter-button"
                                >
                                  {props.t("Filters")}
                                </UncontrolledTooltip>
                                {filtersOpen ? (
                                  <i className="mdi mdi-filter-minus"></i>
                                ) : (
                                  <i className="mdi mdi-filter-menu"></i>
                                )}
                              </Button>
                            </Col>
                            <Col>
                              <CustomRangePicker
                                value={dateRange}
                                rangeChanged={onDateSelection}
                                showTime={true}
                                allowClear={false}
                              />
                            </Col>
                            <Col className="col-auto">
                              <Button
                                //className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="primary"
                                disabled={ongoingSearch}
                                onClick={() => {
                                  getMessages(
                                    dateRange[0].valueOf(),
                                    dateRange[1].valueOf()
                                  )
                                }}
                              >
                                <i className="mdi mdi-check-bold me-2" />
                                {props.t("Apply")}
                              </Button>
                              <Button
                                className="ms-3 round-icon-button"
                                id="calendar-button"
                                color="primary"
                                disabled={ongoingSearch}
                                onClick={() => {
                                  setDateRange([dateRange[0], dayjs()])
                                  getMessages(
                                    dateRange[0].valueOf(),
                                    dayjs().valueOf()
                                  )
                                }}
                              >
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="calendar-button"
                                >
                                  {props.t("Refresh time")}
                                </UncontrolledTooltip>
                                <i className="mdi mdi-calendar-refresh" />
                              </Button>
                            </Col>
                            <Col>
                              <span className="float-end ms-2">
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="downloadstatstable"
                                >
                                  {props.t("Download")}
                                </UncontrolledTooltip>
                                <Dropdown
                                  id="printButtonServicesStats"
                                  isOpen={menu}
                                  toggle={() => setMenu(!menu)}
                                  className="download-btn text-center"
                                  type="button"
                                >
                                  <DropdownToggle
                                    id="downloadstatstable"
                                    className="text-muted"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-download" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => generateExcel()}
                                    >
                                      {props.t("Download .xlsx")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </span>
                              <div className="mt-2 float-end">
                                <p>
                                  {filteredMessages.length}{" "}
                                  {props.t("results found")}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Accordion
                            flush
                            open={filtersOpen}
                            toggle={id => {
                              id === filtersOpen
                                ? setFiltersOpen("")
                                : setFiltersOpen(id)
                            }}
                          >
                            <AccordionItem>
                              <AccordionBody
                                accordionId="1"
                                style={{
                                  backgroundColor: "#e8f0fe",
                                  border: "1px solid #ced4da",
                                }}
                              >
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Max rows")}</Label>
                                      <Input
                                        type="number"
                                        name="maxRows"
                                        value={maxRows}
                                        onChange={v => {
                                          setMaxRows(v.target.value)
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("SMS status")}</Label>
                                      <Input
                                        type="select"
                                        name="statusFilter"
                                        value={statusFilter}
                                        onChange={v => {
                                          setStatusFilter(v.target.value)
                                        }}
                                      >
                                        <option value="">
                                          {props.t("All")}
                                        </option>
                                        <option value="-1">
                                          {props.t("Received")}
                                        </option>
                                        <option value="0">
                                          {props.t("Sent")}
                                        </option>
                                        <option value="1">
                                          {props.t("Delivered")}
                                        </option>
                                        <option value="3">
                                          {props.t("Deleted")}
                                        </option>
                                        <option value="5">
                                          {props.t("Expired")}
                                        </option>
                                        <option value="6">
                                          {props.t("Rejected")}
                                        </option>
                                        <option value="7">
                                          {props.t("Failed")}
                                        </option>
                                        <option value="11">
                                          {props.t("Unknown")}
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Recipient")}</Label>
                                      <Input
                                        type="text"
                                        name="recipient"
                                        value={recipient}
                                        onChange={v => {
                                          setRecipient(v.target.value)
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Sender")}</Label>
                                      <Input
                                        type="text"
                                        name="sender"
                                        value={sender}
                                        onChange={v => {
                                          setSender(v.target.value)
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          </Accordion>

                          <Row className="mt-2">
                            <Col xl="12">
                              <AntdTable
                                className="colored-header narrow-header"
                                bordered
                                size="small"
                                sticky
                                rowKey={"key"}
                                columns={columns}
                                dataSource={filteredMessages}
                                pagination={{
                                  showSizeChanger: true,
                                  defaultPageSize: 50,
                                  pageSizeOptions: defaultPageSizeOptions,
                                }}
                                loading={ongoingSearch}
                                tableLayout="auto"
                                scroll={{
                                  x: "max-content",
                                  y: tableDefaultHeight,
                                }}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane className="show" tabId="2">
                  <React.Fragment>
                    <Row className="mb-1">
                      <Col className="col-auto">
                        <Button
                          style={{ borderRadius: "100px" }}
                          color="primary"
                          disabled={ongoingSearch}
                          onClick={() => {
                            getSupportMessages()
                          }}
                        >
                          <i className="mdi mdi-refresh me-2" />
                          {props.t("Refresh data")}
                        </Button>
                      </Col>
                      <Col>
                        <div className="mt-2 float-end">
                          <p>
                            {supportMessages.length} {props.t("results found")}
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col xl="12">
                        <AntdTable
                          className="striped colored-header"
                          bordered
                          size="small"
                          sticky
                          rowKey={"key"}
                          columns={columns2}
                          dataSource={supportMessages}
                          pagination={{
                            showSizeChanger: true,
                            defaultPageSize: 50,
                            pageSizeOptions: defaultPageSizeOptions,
                          }}
                          loading={ongoingSearch}
                          tableLayout="auto"
                          scroll={{
                            x: "max-content",
                            y: tableDefaultHeight,
                          }}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

SmsLogs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SmsLogs)
