/***********************
 * NODE CHART HUMIDITY (HISTORY)
 * (+ IMPORT PDF/XLSX)
 ************************/

import React, { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"

// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"

//Translation
import { withTranslation } from "react-i18next"
import GlobalState from "contexts/GlobalState"

const NodeChartHumHis = props => {
  const [state] = useContext(GlobalState)
  const [language, setLanguage] = useState("fi")
  const [chartDataHumAvg, setChartDataHumAvg] = useState(["", 0])
  const [chartDataHumMin, setChartDataHumMin] = useState(["", 0])
  const [chartDataHumMax, setChartDataHumMax] = useState(["", 0])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (props.showLoading != undefined) setIsLoading(props.showLoading)
  }, [props.showLoading])

  useEffect(() => {
    if (props.data != undefined) {
      setChartDataHumAvg(props.data[0])
      setChartDataHumMin(props.data[1])
      setChartDataHumMax(props.data[2])
    }
    //setIsLoading(false)
  }, [props.data])

  // Init language
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.startsWith("fi")) {
        setLanguage("fi")
      } else if (currentLanguage.startsWith("sv")) {
        setLanguage("se")
      } else {
        setLanguage("en")
      }
    }
  }, [])
  // Language change
  useEffect(() => {
    switch (state.language) {
      case "fi":
        setLanguage("fi")
        ApexCharts.exec("node-humhistory-chart", "setLocale", "fi")
        break
      case "en":
        setLanguage("en")
        ApexCharts.exec("node-humhistory-chart", "setLocale", "en")
        break
      case "sv":
        setLanguage("se")
        ApexCharts.exec("node-humhistory-chart", "setLocale", "se")
        break
    }
  }, [state.language])

  const series = [
    {
      name: props.t("Average humidity"),
      type: "line",
      data: chartDataHumAvg,
    },
    {
      name: props.t("Min humidity"),
      type: "line",
      data: chartDataHumMin,
    },
    {
      name: props.t("Max humidity"),
      type: "line",
      data: chartDataHumMax,
    },
  ]
  const options = {
    chart: {
      id: "node-humhistory-chart",
      locales: [fi, en, se],
      defaultLocale: language, //"en",
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.1,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
    },
    stroke: {
      width: [3, 1, 1],
      curve: "smooth",
    },

    colors: ["#00bcf5", "#7abcf7", "#093177", "#34c38f", "#00a0d6", "#f46a6a"],

    // For example showing the average
    // annotations: {
    //   yaxis: [
    //     {
    //       y: 10,
    //       borderColor: "#00E396",
    //       label: {
    //         borderColor: "#00E396",
    //         style: {
    //           color: "#fff",
    //           background: "#00E396",
    //         },
    //         text: "Y-axis annotation on 10",
    //       },
    //     },
    //   ],
    // },

    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " " + props.t("%")
          }
          return y
        },
      },
      x: {
        format: "dd.MM.yyyy",
      },
    },
  }

  return (
    <div>
      {isLoading ? (
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Link to="#" className="text-primary">
                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                {props.t("Loading")}
              </Link>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          <div id="line-chart" dir="ltr">
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height="300"
            />
          </div>
        </div>
      )}
    </div>
  )
}

NodeChartHumHis.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  showLoading: PropTypes.bool,
}

export default withTranslation()(NodeChartHumHis)
