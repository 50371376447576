import React, { useEffect, useState, useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"
import { withTranslation } from "react-i18next"

//components
import Navbar from "./Navbar"
import Header from "./Header"
import Footer from "./Footer"
import { useIdleTimer } from "react-idle-timer"
import GlobalState from "../../contexts/GlobalState"

const Layout = props => {
  const [state] = useContext(GlobalState)
  const [timeout, setTimeout] = useState(1000 * 60 * 29) // 29 minutes before modal
  const [promptBeforeIdle] = useState(1000 * 60) // 1 minute after modal
  const [modal, setModal] = useState(false)
  const [remaining, setRemaining] = useState(0)
  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  // Set body atttributes and scroll to top
  useEffect(() => {
    if (document.body) document.body.setAttribute("data-topbar", "dark")
    if (document.body) document.body.setAttribute("data-layout", "horizontal")
    if (document.body) document.body.setAttribute("data-layout-size", "fluid") // boxed/scrollable/fluid
    if (document.body)
      document.body.setAttribute("data-layout-scrollable", false)
    window.scrollTo(0, 0)
  }, [])

  // Hide menu on path change
  useEffect(() => {
    setIsMenuOpened(false)
  }, [location.pathname])

  // Menu toggle
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened)
  }

  // MODAL
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const onPrompt = () => {
    // Show modal prompt
    console.log("Modal prompt")
    setRemaining(promptBeforeIdle)
    setModal(true)
    removeBodyCss()
  }

  const onIdle = () => {
    // Close modal prompt, Log out user
    console.log("User is idle: logout")
    setModal(false)
    navigate("/logout")
  }

  const onActive = event => {
    // Close Modal Prompt
    setModal(false)
    setRemaining(0)
  }

  const { reset, getRemainingTime, isPrompted } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    timeout,
    promptBeforeIdle,
    crossTab: true,
  })

  useEffect(() => {
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      //console.log(role, "role")
      if (role == "system_admin") {
        setTimeout(1000 * 60 * 59) // 59 minutes before modal
        reset()
      }
    }
  }, [])

  useEffect(() => {
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let themeId = obj.themeId
      // Setting different themes
      // if (themeId != undefined) {
      //   document.body.setAttribute("data-style", themeId)
      //   console.log("change theme", themeId)
      // TEST THEME
      // Different themes, now only test-theme
      if (themeId == 10) {
        document.body.setAttribute("data-style", themeId)
        console.log("change theme test", themeId)
        // TEST
      } else {
        document.body.setAttribute("data-style", 0)
      }
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted])

  // Logout
  const logOut = () => {
    console.log("user logout")
    navigate("/logout")
  }

  // TODO: setting opened navbar to closed
  // const unactivateNavbar = () => {
  //   console.log("unactivate")
  // }

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          //theme={topbarTheme}
          leftMenu={isMenuOpened}
          toggleLeftmenu={openMenu}
        />
        <Navbar
          leftMenu={isMenuOpened}
          // deactivate={TODO: send deactivate command}
        />
        <div
          className="main-content"
          // onClick={TODO: set deactivated}
        >
          {props.children}
          <Modal isOpen={modal} toggle={() => {}} centered>
            <div className="modal-header">
              <h5 className="modal-title mt-0">{props.t("Session timeout")}</h5>
            </div>
            <div className="modal-body">
              <p>
                {props.t("Session timeout description")}
                {" " + remaining + " "}
                {props.t("Timeout seconds")}
              </p>
              <p className="mb-3">
                {props.t("Do you want to continue session?")}
              </p>
              <button
                type="button"
                onClick={() => {
                  setModal(false)
                  reset()
                }}
                className="btn btn-primary "
                data-dismiss="modal"
              >
                {props.t("Stay logged in")}
              </button>
              <button
                type="button"
                onClick={() => {
                  setModal(false)
                  logOut()
                }}
                className="btn btn-outline-primary ms-3"
                data-dismiss="modal"
              >
                {props.t("Log out")}
                <i className="ms-1 mdi mdi-logout"></i>
              </button>
            </div>
          </Modal>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Layout))
