/**********************
 * Disables body scroll when needed
 * Used in: Timeline (index)
 *********************/
import { useRef } from "react"

const safeDocument = typeof document !== "undefined" ? document : {}

/**
 * Usage:
 * const [blockScroll, allowScroll] = useScrollBlock();
 */
export default () => {
  const scrollBlocked = useRef()
  const { body } = safeDocument
  const blockScroll = () => {
    if (!body?.style || scrollBlocked.current) return

    // Save the current scroll position
    const scrollPosition = body.scrollTop || document.documentElement.scrollTop

    // Calculate the scrollbar width
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth

    console.log(scrollbarWidth, "width")

    // Apply styles to prevent scrolling and add padding to the right
    body.style.overflow = "hidden"
    body.style.paddingRight = `${scrollbarWidth}px`

    // Restore the scroll position
    body.scrollTop = scrollPosition

    scrollBlocked.current = true
  }

  const allowScroll = () => {
    if (!body?.style || !scrollBlocked.current) return

    // Restore default body styles
    body.style.overflow = ""
    body.style.paddingRight = ""

    scrollBlocked.current = false
  }

  return [blockScroll, allowScroll]
}
