import React, { useEffect, useState, useRef, useContext } from "react"
import {
  Alert,
  Card,
  CardBody,
  Button,
  FormGroup,
  Form,
  Input,
  Modal,
  ButtonGroup,
  Label,
  Col,
  Row,
  UncontrolledTooltip,
  TabContent,
  TabPane,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardSubtitle,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  NavLink,
  NavItem,
  Collapse,
  CardTitle,
  Nav,
  CardFooter,
} from "reactstrap"
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import classnames from "classnames"
import * as Yup from "yup"
import { useFormik } from "formik"
import BatteryChart from "./bat-chart"

import { Switch } from "antd"
import RangeCalendarServices from "../../filter-calendar-services"

import NodeChartActivity from "../node-charts/node-chart-activity"
import NodeChartTemp from "../node-charts/node-chart-temp"
import NodeChartHum from "../node-charts/node-chart-hum"

import NodeChartBatteryHistory from "../node-charts/node-chart-battery-history"
import NodeChartActivityHistory from "../node-charts/node-chart-activity-history"
import NodeChartHumHistory from "../node-charts/node-chart-hum-history"
import NodeChartTempHistory from "../node-charts/node-chart-temp-history"

import { showToast } from "utils/general" // Validation
import {
  updateGatewayNode,
  getNodeValues,
  getNodeLongPeriodData,
  getCombinedActivity,
} from "helpers/backend_helper"

//Formatting date
import dayjs from "dayjs"

import duration from "dayjs/plugin/duration"
dayjs.extend(duration)
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(relativeTime)

import GlobalState from "contexts/GlobalState"
import CompareNodesModal from "./compare-nodes-modal"

const NodeCards = props => {
  const [state] = useContext(GlobalState)
  const [userRole, setUserRole] = useState(null)
  const [data, setData] = useState([])
  const [modal, setModal] = useState(false)
  const [chosen, setChosen] = useState([])
  const [settingsModal, setSettingsModal] = useState(false)
  const [chosenNodeSettings, setChosenNodeSettings] = useState([])

  const [rangePicker] = useState(false)

  const [gatewayKey, setGatewayKey] = useState()

  const [dataLengthDays, setDataLengthDays] = useState(14)

  // Node modify
  const [nodeError, setNodeError] = useState("")
  const [nodeSuccess, setNodeSuccess] = useState("")
  const [update, setUpdate] = useState(false)

  // Compare two nodes
  const [compareNodes, setCompareNodes] = useState([])
  const [compareLength, setCompareLength] = useState(0)
  const [compareNode1, setCompareNode1] = useState()
  const [compareNode2, setCompareNode2] = useState()
  const [compare, setCompare] = useState(false)
  const [compareModal, setCompareModal] = useState(false)

  //Chosen history data
  const [historicalActivityType, setHistoricalActivityType] = useState("")
  const [historicalTooltipUnit, setHistoricalTooltipUnit] = useState("")
  const [historicalDataLoading, setHistoricalDataLoading] = useState(true)
  const [allActivityData, setAllActivityData] = useState(["", 0])

  // node values
  const [activityData, setActivityData] = useState(["", 0])
  const [tempData, setTempData] = useState(["", 0])
  const [humData, setHumData] = useState(["", 0])

  // // Multiple nodes
  // // 1
  // const [activityData1, setActivityData1] = useState(["", 0])
  // const [tempData1, setTempData1] = useState(["", 0])
  // const [humData1, setHumData1] = useState(["", 0])
  // // 2
  // const [activityData2, setActivityData2] = useState(["", 0])
  // const [tempData2, setTempData2] = useState(["", 0])
  // const [humData2, setHumData2] = useState(["", 0])

  // HIstory
  const [chartDataActivityAvg, setChartDataActivityAvg] = useState([0])
  const [chartDataTemp, setChartDataTemp] = useState([
    ["", 0],
    ["", 0],
    ["", 0],
  ])
  const [chartDataHum, setChartDataHum] = useState([
    ["", 0],
    ["", 0],
    ["", 0],
  ])
  // node stats battery
  const [chartDataBat, setChartDataBat] = useState([
    ["", 0],
    ["", 0],
    ["", 0],
  ])

  // dates
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  // Loading
  const [loading, setLoading] = useState(true)

  // Selected date range
  const [historyDays, setHistoryDays] = useState(14)
  // Compare node to all activity
  const [showAll, setShowAll] = useState(false)
  // Custom menu toggle
  const [activeDropdown, setActiveDropdown] = useState(false)
  const [menu, setMenu] = useState(false)
  //
  const regex = /\./g

  // New window
  const abortRef = useRef(null)
  useEffect(() => {
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
    }
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (gatewayKey != undefined) {
      searchAllData(gatewayKey)
    }
  }, [gatewayKey])

  useEffect(() => {
    if (props.data != undefined || props.data != []) {
      setData(props.data)
    }
  }, [props.data])

  useEffect(() => {
    if (props.historyDays != undefined) {
      setDataLengthDays(props.historyDays)
    }
  }, [props.historyDays])

  useEffect(() => {
    if (props.settingAll) {
      setShowAll(props.settingAll)
    }
  }, [props.settingAll])

  useEffect(() => {
    if (props.gatewayKey != undefined) {
      setGatewayKey(props.gatewayKey)
    }
  }, [props.gatewayKey])

  useEffect(() => {
    if (props.compare != undefined) {
      setCompare(props.compare)
      if (!props.compare) {
        setCompareNodes([])
        setCompareLength(0)
        setCompareNode1()
        setCompareNode2()
      }
    }
  }, [props.compare])

  useEffect(() => {
    if (historyDays !== undefined && chosen !== undefined) {
      getNodeHistoricalData(chosen.key)
    }
  }, [historyDays])

  // Date changed - update daily chart only
  useEffect(() => {
    if (startDate != undefined && endDate != undefined) {
      getNodeValuesData(chosen.key)
      getNodeHistoricalData(chosen.key)
      if (showAll) {
        searchAllData(gatewayKey)
      }
    }
  }, [endDate])

  useEffect(() => {
    if (
      props.settingHistoryDays != undefined &&
      props.settingHistoryDays != historyDays
    ) {
      setHistoryDays(props.settingHistoryDays)
    }
  }, [props.settingHistoryDays])

  useEffect(() => {
    if (showAll) {
      searchAllData(gatewayKey)
    }
  }, [showAll])

  // Setting empty chosen nodes for settings
  useEffect(() => {
    if (!settingsModal) {
      setChosenNodeSettings([])
    }
  }, [settingsModal])

  // Compare nodes
  // useEffect(() => {
  //   if (compareNode1 != undefined) {
  //     getCompareNode1(compareNode1)
  //   }
  // }, [compareNode1])
  // useEffect(() => {
  //   if (compareNode2 != undefined) {
  //     getCompareNode2(compareNode2)
  //   }
  // }, [compareNode2])

  // Update node validation
  const nodeValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: chosenNodeSettings?.description || "",
      trackingTarget: chosenNodeSettings?.useCase || "",
      powerOnLevel: chosenNodeSettings?.powerOnLevel || 0,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit values: ", values)

      setNodeSuccess("")
      setNodeError("")

      let temp = JSON.parse(JSON.stringify(values))
      temp.powerOnLevel = parseInt(values.powerOnLevel, 10)
      temp.nodeId = parseInt(values.nodeId, 10)

      if (chosenNodeSettings?.key && !update) {
        setUpdate(true)
        updateGatewayNode(abortRef.current, chosenNodeSettings.key, temp)
          .then(result => {
            console.log(result)
            if (result.statusCode == 200) {
              setSettingsModal(false)
              props.getNodes(props.t("Updated"))
            } else {
              setNodeError("Error")
            }
          })
          .catch(err => {
            console.log(err)
            setNodeError("Error")
          })
          .finally(() => {
            setUpdate(false)
          })
      }
    },
  })

  // Button group for historical data
  const buttonGroupJSX = []
  buttonGroupJSX.push(
    <div key="buttong" className="">
      <ButtonGroup size="sm" className="">
        {dataLengthDays >= 14 && (
          <Button
            color="primary"
            active={historyDays === 14}
            onClick={() => setHistoryDays(14)}
            style={{
              paddingLeft: 15 + "px",
              paddingRight: 15 + "px",
            }}
          >
            {props.t("14 days")}
          </Button>
        )}
        {dataLengthDays >= 30 && (
          <Button
            color="primary"
            active={historyDays === 30}
            onClick={() => setHistoryDays(30)}
            style={{
              paddingLeft: 15 + "px",
              paddingRight: 15 + "px",
            }}
          >
            {props.t("30 days")}
          </Button>
        )}
        {dataLengthDays >= 60 && (
          <Button
            color="primary"
            active={historyDays === 60}
            onClick={() => setHistoryDays(60)}
            style={{
              paddingLeft: 15 + "px",
              paddingRight: 15 + "px",
            }}
          >
            {props.t("60 days")}
          </Button>
        )}
      </ButtonGroup>
      {/* </Col> */}
    </div>
  )

  // Search only all activity data
  const searchAllData = k => {
    getCombinedActivity(abortRef.current, k, startDate, endDate)
      .then(data => {
        console.log("NODE CARD ACT: ", data)
        if (data.entries) {
          let activities = data.entries?.map(item => [item.date, item.activity])
          let filterEmpty = activities.filter(f => f[1] != 0)
          setAllActivityData(filterEmpty)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Get nodes 24h data
  const getNodeValuesData = key => {
    if (key != null && key != undefined && endDate != undefined) {
      setLoading(true)
      getNodeValues(abortRef.current, key, startDate, endDate)
        .then(data => {
          console.log("Node values data: ", data.entries)
          if (data?.entries?.length > 0) {
            console.log("data.entrier populated:")
            setTempData(
              data.entries?.map(item => [item.date, item.temperature])
            )
            setHumData(data.entries?.map(item => [item.date, item.humidity]))
            setActivityData(
              data.entries?.map(item => [item.date, item.activity])
            )
          } else {
            console.log("data.entrier was empty:")
            setActivityData([])
            setTempData([])
            setHumData([])
          }
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Get comparenode 1 data
  // const getCompareNode1 = () => {
  //   if (compareNode1 !== undefined) {
  //     setLoading(true)
  //     getNodeValues(abortRef.current, compareNode1, startDate, endDate)
  //       .then(data => {
  //         console.log("Node values data (compare): ", data)
  //         if (data.entries != undefined && data.entries.length > 0) {
  //           setTempData1(
  //             data.entries?.map(item => [item.date, item.temperature])
  //           )
  //           setHumData1(data.entries?.map(item => [item.date, item.humidity]))
  //           setActivityData1(
  //             data.entries?.map(item => [item.date, item.activity])
  //           )
  //         } else {
  //           setActivityData1([])
  //           setTempData1([])
  //           setHumData1([])
  //         }
  //       })
  //       .catch(err => {
  //         console.log(err)
  //         setLoading(false)
  //       })
  //       .finally(() => {
  //         setLoading(false)
  //       })
  //   }
  // }

  // Get comparenode 2 data
  // const getCompareNode2 = () => {
  //   if (compareNode2 !== undefined) {
  //     getNodeValues(abortRef.current, compareNode2, startDate, endDate)
  //       .then(data => {
  //         console.log("Node values data (compare2): ", data)
  //         if (data.entries != undefined && data.entries.length > 0) {
  //           setTempData2(
  //             data.entries?.map(item => [item.date, item.temperature])
  //           )
  //           setHumData2(data.entries?.map(item => [item.date, item.humidity]))
  //           setActivityData2(
  //             data.entries?.map(item => [item.date, item.activity])
  //           )
  //         } else {
  //           setActivityData2([])
  //           setTempData2([])
  //           setHumData2([])
  //         }
  //       })
  //       .catch(err => {
  //         console.log(err)
  //         setLoading(false)
  //       })
  //       .finally(() => {
  //         setLoading(false)
  //       })
  //   }
  // }
  const getNodeHistoricalData = chosenNode => {
    if (chosenNode != undefined && endDate != undefined) {
      setHistoricalDataLoading(true)
      getNodeLongPeriodData(abortRef.current, chosenNode, endDate, historyDays)
        .then(data => {
          console.log("Node long period data: ", data)
          if (data.entries != undefined && data.entries.length > 0) {
            if (data.activityType == "counter") {
              setHistoricalActivityType("Usage")
              setHistoricalTooltipUnit("times")
            } else if (data.activityType == "minutes") {
              setHistoricalActivityType("Activity")
              setHistoricalTooltipUnit("minutes")
            }

            // Set temperature history data
            const tempAvg = data.entries?.map(item => [
              item.date,
              item.temperature.avg,
            ])
            const tempMin = data.entries?.map(item => [
              item.date,
              item.temperature.min,
            ])
            const tempMax = data.entries?.map(item => [
              item.date,
              item.temperature.max,
            ])
            const temp = [tempAvg, tempMin, tempMax]
            setChartDataTemp(temp)

            // Set humidity history data
            const humAvg = data.entries?.map(item => [
              item.date,
              item.humidity.avg,
            ])
            const humMin = data.entries?.map(item => [
              item.date,
              item.humidity.min,
            ])
            const humMax = data.entries?.map(item => [
              item.date,
              item.humidity.max,
            ])
            const hum = [humAvg, humMin, humMax]
            setChartDataHum(hum)

            // Set battery history data
            const batAvg = data.entries?.map(item => [
              item.date,
              item.battery.avg / 100,
            ])
            const batMin = data.entries?.map(item => [
              item.date,
              item.battery.min / 100,
            ])
            const batMax = data.entries?.map(item => [
              item.date,
              item.battery.max / 100,
            ])
            const bat = [batAvg, batMin, batMax]
            setChartDataBat(bat)

            // Set activity history data
            setChartDataActivityAvg(
              data.entries?.map(item => [item.date, item.activity.value])
            )
          } else {
            setChartDataActivityAvg([])
            setChartDataBat([])
            setChartDataHum([])
            setChartDataTemp([])
          }
          setHistoricalDataLoading(false)
        })
        .catch(err => {
          console.log(err)
          setHistoricalDataLoading(false)
        })
    }
  }

  // Node card styling
  const getDeviceCardStyle = node => {
    if (node.connectionIssue) return { borderColor: "#fcd2d2" }
    else return { borderColor: "#eff2f7" }
    //else return { border: "0px", /*borderTop: "1px solid #DDD", borderRadius: 0*/}
  }
  // Icons status, devicetype
  const setDeviceAvatar = a => {
    // console.log(a, "setting avatar")
    switch (a) {
      case "Gateway":
        return "avatar-title rounded-circle bg-soft bg-gateway text-gateway font-size-20"
      case "Activity":
        return "avatar-title rounded-circle bg-soft bg-activity text-activity font-size-20"
      case "Dining":
        return "avatar-title rounded-circle bg-soft bg-dining text-dining font-size-18"
      case "Sleep":
        return "avatar-title rounded-circle bg-soft bg-bed text-bed font-size-20"
      case "Outside Door":
        return "avatar-title rounded-circle bg-soft bg-door text-door font-size-16"
      case "WC":
        return "avatar-title rounded-circle bg-soft bg-wc text-wc font-size-20"
      case "Fridge":
        return "avatar-title rounded-circle bg-soft bg-dining text-dining font-size-22"
      case "Microwave":
        return "avatar-title rounded-circle bg-soft bg-dining text-dining font-size-22"
      case "unknown":
        return ""
      default:
        return "avatar-title rounded-circle bg-soft bg-activity text-activity font-size-20"
    }
  }

  //** good / moderate / weak / unknown / mains */
  // Icons status, devicetype
  // NOT IN USE
  // const setTypeBattery = b => {
  //   switch (b) {
  //     case "good":
  //       return "dripicons-battery-full text-success"
  //     case "moderate":
  //       return "dripicons-battery-medium text-success"
  //     case "weak":
  //       return "dripicons-battery-low text-danger"
  //     case "empty":
  //       return "dripicons-battery-empty text-danger"
  //     case "unknown":
  //       return ""
  //     case "mains":
  //       return "mdi mdi-power-plug-outline"
  //     default:
  //       return ""
  //   }
  // }

  // Battery tooltip descriptions
  const getBatteryTooltipDescription = b => {
    switch (b) {
      case "good":
        return (
          <div className="text-muted mt-4">
            <h6 className="text-muted">{props.t("Battery status")}</h6>
            <b className="text-success font-size-16 text-uppercase mt-2">
              {props.t("Good")}
            </b>
          </div>
        )
      case "moderate":
        return (
          <div className="text-muted mt-4">
            <h6 className="text-muted">{props.t("Battery status")}</h6>
            <b className="text-warning font-size-16 text-uppercase mt-2">
              {props.t("Moderate")}
            </b>
          </div>
        )
      case "weak":
        return (
          <div className="text-muted mt-4">
            <h6 className="text-muted">{props.t("Battery status")}</h6>
            <b className="text-danger font-size-16 text-uppercase mt-2">
              {props.t("Weak")}
            </b>
          </div>
        )
      case "unknown":
        return props.t("Battery status") + ": " + props.t("Unknown")
      case "mains":
        return props.t("Mains")
      default:
        return ""
    }
  }

  //** Activity / Electric / Door / Bed / unknown */
  // Icons status, devicetype
  const setDeviceType = d => {
    switch (d) {
      case "Gateway":
        return "bx bx-home-circle"
      case "Activity":
        return "mdi mdi-home-account"
      case "Fridge":
        return "mdi mdi-fridge-outline"
      case "Dining":
        return "mdi mdi-silverware-fork-knife"
      case "Microwave":
        return "mdi mdi-microwave"
      case "WC":
        return "mdi mdi-toilet"
      case "Outside Door":
        return "fas fa-door-open"
      case "Sleep":
        return "bx bxs-bed"
      case "unknown":
        return ""
      default:
        return "bx bx-home-circle"
    }
  }

  // Chosen node data + modal show
  const settingChosenNode = a => {
    getNodeValuesData(a.key)
    getNodeHistoricalData(a.key)
    setChosen(a)
    setModal(true)
  }

  // Chosen node settings
  const settingsNode = a => {
    setChosenNodeSettings(a)
    setSettingsModal(true)
  }

  // Time since last activity
  const getLastActivityDiff = date => {
    if (date === null) {
      return "-"
    }

    let current = dayjs()
    let prev = dayjs(date)
    let duration = dayjs.duration(current.diff(prev))

    if (duration.months() > 0) {
      return duration.months() + " " + props.t("mo") + " "
    } else if (duration.days() > 0) {
      return duration.days() + " " + props.t("d") + " "
    } else if (duration.hours() > 0) {
      return duration.hours() + " " + props.t("h") + " "
    } else {
      return duration.minutes() + " " + "min " + " "
    }
  }
  // Calculating trend in usage
  const calculateTrend = (yesterday, average) => {
    if (yesterday || average !== undefined) {
      let first = yesterday - parseFloat(average)
      let second = first / parseFloat(average)
      let res = second * 100
      let minus = Math.abs(res.toFixed(1))
      return res > 0 ? (
        // <span className="badge rounded-pill badge-soft-primary font-size-10">
        <span className="badge badge-soft-success ms-2">
          <i className="mdi mdi-arrow-up" />
          {res.toFixed(1).toString().replace(regex, ",")}%
        </span>
      ) : (
        <span className="badge badge-soft-danger ms-2">
          <i className="mdi mdi-arrow-down " />
          {minus.toString().replace(regex, ",")}%
        </span>
      )
    } else {
      return <></>
    }
  }

  // TODO: DOWNLOAD
  const downloadExcelNode = type => {
    //Excel and pdf name
    let titleExport = props.t("Node activity")
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    let datas
    if (type == "1day") {
      datas = activityData
    }
    if (type == "history") {
      datas = chartDataActivityAvg
    }

    const Heading = [[props.t("Time"), props.t("Activity")]]
    const data = datas.map(elt => [timeFormatter(elt[0]), elt[1]])

    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, titleExport)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, titleExport + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success")
  }

  const menuToggle = (t, c) => {
    if (t === "click") {
      setActiveDropdown(true)
      setMenu(!menu)
    }
    if (t === "hover") {
      if (!activeDropdown) {
        setMenu(c)
      }
    }
  }
  // Setting keys from deleted messages
  const handleChange = event => {
    let item = event.target.value
    let arr = compareNodes

    if (arr.includes(item) === true) {
      arr = arr.filter(x => x !== item)
      // console.log(arr)
    } else {
      arr.push(item)
    }
    handlingKeys(arr)
  }
  // Adding to
  const handlingKeys = keys => {
    console.log(keys)
    setCompareNodes(keys)
    // compareNodes.length does no update
    setCompareLength(keys.length)
  }
  const comparingNodes = (first, second) => {
    setCompareNode1(first)
    setCompareNode2(second)
  }
  const setModalCompare = bool => {
    setCompareModal(bool)
  }

  return (
    <>
      {data?.length == 0 ? (
        <></>
      ) : (
        <>
          {data?.map((item, index) => (
            <Col xl={4} md={6} key={index + item}>
              <Card className="shadow-none" style={getDeviceCardStyle(item)}>
                <Collapse isOpen={compare}>
                  {compare && (
                    <>
                      <CardTitle className="py-2 px-2 m-0">
                        <Col lg="12">
                          {compareNodes.includes(item.key) && (
                            <Button
                              outline
                              disabled={compareLength < 2}
                              className="float-end me-0"
                              color="primary"
                              size="sm"
                              onClick={() => {
                                comparingNodes(compareNodes[0], compareNodes[1])
                                setCompareModal(true)
                              }}
                            >
                              {props.t("Show measurements")}
                            </Button>
                          )}

                          <div className="form-check form-check-primary ">
                            <input
                              disabled={
                                compareLength == 2 &&
                                compareNodes.includes(item.key) == false
                              }
                              type="checkbox"
                              className="form-check-input"
                              id={item.key + "_customCheckcolor5"}
                              value={item.key}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label text-muted h6"
                              htmlFor={item.key + "_customCheckcolor5"}
                            >
                              {props.t("Compare measurements")} ({compareLength}
                              /2)
                            </label>
                          </div>
                        </Col>
                      </CardTitle>
                    </>
                  )}
                </Collapse>
                {item.connectionIssue && (
                  <CardBody
                    className="py-2"
                    style={{
                      borderBottom: "1px solid #fcd2d2",
                      backgroundColor: "#fde1e1",
                    }}
                  >
                    <CardSubtitle style={{ color: "#924040" }}>
                      <div className="d-flex">
                        <div>
                          <i
                            className={"mdi mdi-signal-off font-size-20 me-2"}
                          ></i>
                          <span
                            style={{ fontWeight: 500 }}
                            className="alert-heading font-size-14"
                          >
                            {props.t("Connection issue")}
                          </span>
                        </div>
                      </div>
                    </CardSubtitle>
                  </CardBody>
                )}
                <CardBody
                  style={
                    item.connectionIssue
                      ? { height: "169px" }
                      : { height: "213px" }
                  }
                >
                  <Row>
                    <Col xs="1">
                      <div className="">
                        <div className="avatar-xs mx-lg-auto mb-1 float-start float-lg-none rounded-circle">
                          <span className={setDeviceAvatar(item.useCase)}>
                            <i className={setDeviceType(item.useCase)}></i>
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs="7">
                      <h5 className="mt-1 ms-4 mb-0">
                        {item.description}
                        <div id={"actTT" + item.key} className="text-muted">
                          {item.open === false && (
                            <>
                              <div className="font-size-10 text-muted ">
                                {props.t("Opened")}{" "}
                                {getLastActivityDiff(item.lastActivity)}{" "}
                                {props.t("ago")}
                              </div>
                            </>
                          )}
                          {item.open === true && (
                            // Door open
                            <span className="badge badge-soft-danger font-size-12">
                              {props.t("Door open")}
                            </span>
                          )}
                          {item.active === false && (
                            <>
                              <div className="font-size-10 text-muted ">
                                {props.t("Active")}{" "}
                                {getLastActivityDiff(item.lastActivity)}{" "}
                                {props.t("ago")}
                              </div>
                            </>
                          )}
                          {item.active === true && (
                            // Device in use
                            <span className="badge badge-soft-primary font-size-12">
                              {props.t("In use")}
                            </span>
                          )}
                          {item.deviceType == "Activity" && (
                            <div className="font-size-10 text-muted">
                              {props.t("Active")}{" "}
                              {getLastActivityDiff(item.lastActivity)}{" "}
                              {props.t("ago")}
                            </div>
                          )}
                          {/** good / moderate / weak / unknown / mains */}
                          {/* <i
                          id={"batTT" + item.key}
                          className={
                            setTypeBattery(item.batteryStatus) + " font-size-18"
                          }
                        />
                        <UncontrolledTooltip
                          placement="bottom"
                          target={"batTT" + item.key}
                        >
                          {getBatteryTooltipDescription(item.batteryStatus)}
                        </UncontrolledTooltip> */}
                        </div>
                        <UncontrolledTooltip
                          placement="left"
                          target={"actTT" + item.key}
                        >
                          {props.t("Latest activity")}
                        </UncontrolledTooltip>
                      </h5>
                    </Col>
                    <Col xs="4">
                      <span className="float-end mt-0 text-truncate">
                        {item.key !== true && (
                          <>
                            {userRole.includes("admin") ||
                            userRole == "salesperson" ? (
                              <>
                                <Button
                                  id={"edit-button" + item.key}
                                  size="sm"
                                  color="primary"
                                  onClick={() => {
                                    settingsNode(item)
                                  }}
                                >
                                  <i className="bx bx-cog" />
                                </Button>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"edit-button" + item.key}
                                >
                                  {props.t("Settings")}
                                </UncontrolledTooltip>{" "}
                              </>
                            ) : (
                              <></>
                            )}
                            <Button
                              className="ms-1"
                              size="sm"
                              outline
                              color="primary"
                              onClick={() => {
                                settingChosenNode(item)
                              }}
                            >
                              <i className="bx bx-line-chart font-size-12" />
                              <div className="ms-1 d-none d-md-inline-block">
                                {props.t("Measurements")}
                              </div>
                            </Button>
                          </>
                        )}
                      </span>
                    </Col>
                    <Col lg="12" className="mt-3">
                      <Row>
                        {dayjs().diff(dayjs(item.connected), "days") < 1 ? (
                          <>
                            {item.usage ? (
                              <Col xs="6">
                                {item.connectionIssue !== true && (
                                  <>
                                    <h6 className="mt-2">{props.t("Usage")}</h6>
                                    <Row className="text-muted">
                                      <Col xs="7" className="mt-1">
                                        {props.t("Today")}
                                      </Col>
                                      {item.usage.today ? (
                                        <Col xs="5">
                                          <h4>{item.usage.today}</h4>
                                        </Col>
                                      ) : (
                                        <Col xs="5">
                                          <b className="h4 mt-2 mb-2">-</b>
                                        </Col>
                                      )}
                                    </Row>

                                    <Row className=" text-muted  mt-2">
                                      <Col xs="7">{props.t("Yesterday")}</Col>
                                      {item.usage.yesterday ? (
                                        <Col
                                          xs="5"
                                          className="pe-0 text-truncate"
                                          style={{ overflow: "visible" }}
                                        >
                                          <b>{item.usage.yesterday}</b>
                                          <span id={"span_" + item.key}>
                                            {calculateTrend(
                                              item.usage.yesterday,
                                              item.usage.average
                                            )}
                                          </span>
                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={"span_" + item.key}
                                          >
                                            {props.t(
                                              "Compared to average usage"
                                            )}
                                          </UncontrolledTooltip>
                                        </Col>
                                      ) : (
                                        <Col xs="5" className="pe-0">
                                          <b>-</b>
                                        </Col>
                                      )}
                                    </Row>

                                    <Row className="text-muted ">
                                      <Col xs="7">
                                        <p className="mb-1">
                                          {props.t("Average")}
                                        </p>
                                      </Col>
                                      {item.usage.average ? (
                                        <Col xs="5">
                                          <b>
                                            {item.usage.average
                                              .toString()
                                              .replace(regex, ",")}
                                          </b>
                                        </Col>
                                      ) : (
                                        <Col xs="7">
                                          <b>-</b>
                                        </Col>
                                      )}
                                    </Row>
                                  </>
                                )}
                              </Col>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            <Col xs="6">
                              <h6 className="mt-1 text-muted mb-3">
                                {props.t("Last connection")}
                              </h6>
                              <b className="font-size-14 text-danger">
                                {dayjs().diff(dayjs(item.connected), "days")}{" "}
                                {props.t("days")} {props.t("ago")}
                              </b>
                            </Col>
                            <Col xs="6">
                              <h6 className="mt-1 text-muted mb-3">
                                {props.t("Last battery status")}
                              </h6>
                              <b
                                className={
                                  "font-size-14 text-uppercase text-" +
                                  classnames({
                                    danger: item.batteryStatus == "weak",
                                    warning: item.batteryStatus == "moderate",
                                    success: item.batteryStatus == "good",
                                    primary: item.batteryStatus == "mains",
                                  })
                                }
                              >
                                {props.t(item.batteryStatus)}
                              </b>
                            </Col>
                          </>
                        )}
                        <Col xs="1"></Col>
                        {/* <Col xs={item.usage ? "6" : "12"}> */}
                        {item.batteryStatus !== "mains" &&
                        item.batteryStatus !== "unknown" &&
                        !item.connectionIssue ? (
                          <>
                            <Col xs="5">
                              <BatteryChart
                                t={props.t}
                                value={item.battery}
                                showText={
                                  item.usage ||
                                  dayjs().diff(dayjs(item.connected), "days") >
                                    1
                                }
                                status={item.batteryStatus}
                              ></BatteryChart>
                            </Col>
                            {dayjs().diff(dayjs(item.connected), "days") > 1 ? (
                              <></>
                            ) : (
                              <>
                                {item.usage ? (
                                  <></>
                                ) : (
                                  <Col xs="6">
                                    <div>
                                      {getBatteryTooltipDescription(
                                        item.batteryStatus
                                      )}
                                    </div>
                                  </Col>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {item.batteryStatus === "mains" &&
                              item.connectionIssue === false && (
                                <Col xs="5">
                                  <div
                                    style={{
                                      width: 170,
                                      height: 110,
                                      display: "flex",
                                      flexDirection: "column",

                                      alignItems: "center",
                                      // opacity: 0.8,
                                    }}
                                  >
                                    <h6 className="text-muted mt-2 mb-2">
                                      {props.t("In mains stream")}
                                    </h6>
                                    <div
                                      style={{
                                        width: 60,
                                        height: 60,
                                      }}
                                    >
                                      <CircularProgressbarWithChildren
                                        value={100}
                                        strokeWidth={6}
                                        styles={buildStyles({
                                          pathColor: "#34c38f",
                                        })}
                                      >
                                        <i
                                          className={
                                            "mdi mdi-power-plug-outline display-6 text-success"
                                          }
                                        ></i>
                                      </CircularProgressbarWithChildren>
                                    </div>
                                  </div>
                                </Col>
                              )}
                            {item.batteryStatus === "unknown" && (
                              <Col xs="5">
                                <h6 className="text-muted mt-2 mb-2">
                                  {props.t("Battery status")}
                                </h6>
                                <p className="text-muted">
                                  {props.t("Unknown")}
                                </p>
                              </Col>
                            )}
                          </>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="bg-soft bg-light">
                  <div className="d-flex mt-1 flex-wrap">
                    {item.temperature != undefined && (
                      <div className="me-3 text-muted" id={"tempTT" + item.key}>
                        <i className="bx bxs-thermometer me-1 text-muted" />
                        {item.temperature}°C
                        <UncontrolledTooltip
                          placement="bottom"
                          target={"tempTT" + item.key}
                        >
                          {props.t("Temperature")}
                        </UncontrolledTooltip>
                      </div>
                    )}
                    {item.humidity != undefined && (
                      <div className="text-muted me-3" id={"humTT" + item.key}>
                        <i className="bx bx-droplet me-1 text-muted" />
                        {item.humidity}%
                        <UncontrolledTooltip
                          placement="bottom"
                          target={"humTT" + item.key}
                        >
                          {props.t("Humidity")}
                        </UncontrolledTooltip>
                      </div>
                    )}
                    {item.key == true && (
                      <Col xs="12" className="">
                        <div className="list-inline-item mt-0 me-4">
                          <span className="text-muted me-1">
                            {props.t("Temperature")}:
                          </span>{" "}
                          <span className="h5">{stats.temperature}</span>
                          <span className="h5">°C</span>
                        </div>
                        <div className="list-inline-item me-5">
                          <span className="text-muted me-1">
                            {props.t("Humidity")}:
                          </span>{" "}
                          <span className="h5">{stats.humidity}</span>
                          <span className="h5">%</span>
                        </div>
                        <div className="list-inline-item me-5">
                          <span className="text-muted me-1">
                            {props.t("Last activity")}:
                          </span>{" "}
                          <span className="h5">
                            {getLastActivityDiff(item.lastActivity)}
                          </span>
                        </div>
                      </Col>
                    )}
                    {item.usage ? (
                      <>
                        {item.usage.highest !== undefined && (
                          <div
                            className="text-muted me-3"
                            id={"high" + item.key}
                          >
                            <i className="mdi mdi-arrow-up-bold me-1 text-muted" />
                            {/* TODO: translate krt */}
                            {item.usage.highest} {props.t("krt")}
                            <UncontrolledTooltip
                              placement="bottom"
                              target={"high" + item.key}
                            >
                              {props.t("Highest usage in a day")}
                            </UncontrolledTooltip>
                          </div>
                        )}
                        {item.usage.lowest !== undefined && (
                          <div
                            className="text-muted me-3"
                            id={"lowest" + item.key}
                          >
                            <i className="mdi mdi-arrow-down-bold me-1 text-muted" />
                            {item.usage.lowest} {props.t("krt")}
                            <UncontrolledTooltip
                              placement="bottom"
                              target={"lowest" + item.key}
                            >
                              {props.t("Lowest usage in a day")}
                            </UncontrolledTooltip>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {item.id ? (
                      <div className="text-muted me-3" id={"id" + item.id}>
                        <i className="mdi mdi-identifier me-1 text-muted" />
                        {item.id}
                        <UncontrolledTooltip
                          placement="bottom"
                          target={"id" + item.id}
                        >
                          {props.t("Device id")}
                        </UncontrolledTooltip>
                      </div>
                    ) : null}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          ))}
        </>
      )}
      {/* Compare two nodes modal */}
      <Modal
        size="xl"
        centered
        // toggle={() => {
        //   setCompareModal(false)
        // }}
        isOpen={compareModal}
      >
        <ModalHeader>
          {props.t("Compare measurements")}
          <button
            type="button"
            onClick={() => {
              setCompareModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>

        <CompareNodesModal
          node1={compareNode1}
          node2={compareNode2}
          user={abortRef.current}
        ></CompareNodesModal>
        <ModalFooter className="">
          <Button
            color="primary"
            className="float-end"
            outline
            onClick={() => {
              setCompareModal(false)
            }}
          >
            {props.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
      {/* Node settings modal */}
      <Modal
        size="md"
        isOpen={settingsModal}
        toggle={() => {
          setSettingsModal(!settingsModal)
        }}
        centered
      >
        <ModalHeader>
          {props.t("Settings")}
          <button
            type="button"
            onClick={() => {
              setSettingsModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>

        <ModalBody>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              return false
            }}
          >
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label>{props.t("Description")}</Label>
                  <Input
                    name="description"
                    type="text"
                    value={nodeValidation.values.description}
                    onChange={nodeValidation.handleChange}
                  ></Input>
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label>{props.t("Tracking target")}</Label>
                  <Input
                    name="trackingTarget"
                    type="select"
                    value={nodeValidation.values.trackingTarget}
                    onChange={nodeValidation.handleChange}
                  >
                    <option value="">{props.t("Undefined")}</option>
                    <option value="Sleep">{props.t("Sleep")}</option>
                    <option value="WC">{props.t("Toilet")}</option>
                    <option value="Dining">{props.t("Dining")}</option>
                    <option value="Outside Door">
                      {props.t("Outside door")}
                    </option>
                    <option value="Living Room">
                      {props.t("Living room")}
                    </option>
                    <option value="Lobby">{props.t("Lobby")}</option>
                    <option value="Coffee">{props.t("Coffee machine")}</option>
                    <option value="Television">{props.t("Television")}</option>
                    <option value="Backdoor">{props.t("Backdoor")}</option>
                    <option value="Microwave">{props.t("Microwave")}</option>
                    <option value="Fridge">{props.t("Fridge")}</option>
                    <option value="Balcony">{props.t("Balcony")}</option>
                    <option value="Corridor">{props.t("Corridor")}</option>
                  </Input>
                </FormGroup>
              </Col>
              {chosenNodeSettings.deviceType == "Electric" && (
                <Col lg={6}>
                  <FormGroup>
                    <Label>{props.t("Power on level")}</Label>
                    <Row>
                      <Col xs={10}>
                        <Input
                          name="powerOnLevel"
                          type="number"
                          value={nodeValidation.values.powerOnLevel}
                          onChange={nodeValidation.handleChange}
                        ></Input>
                      </Col>
                      <Col xs={2} className="mt-2 ms-0 p-0">
                        W
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              )}
            </Row>

            <ModalFooter className="pt-3 pb-0">
              <Button
                outline
                className="px-3"
                color="primary"
                disabled={update}
                onClick={() => {
                  setSettingsModal(!settingsModal)
                }}
              >
                {props.t("Cancel")}
              </Button>
              <Button
                className="px-3"
                color="primary"
                disabled={update}
                onClick={() => {
                  nodeValidation.submitForm()
                }}
              >
                {props.t("Save")}
              </Button>
            </ModalFooter>
            {nodeError ? (
              <Alert color="danger">{props.t(nodeError)}</Alert>
            ) : null}
            {nodeSuccess ? (
              <Alert color="success">{props.t(nodeSuccess)}</Alert>
            ) : null}
          </Form>
        </ModalBody>
      </Modal>
      {/* Chosen node measurements modal */}
      <Modal
        size="xl"
        isOpen={modal}
        toggle={() => {
          setModal(!modal)
        }}
        style={{ width: "1000px" }}
        centered
      >
        <ModalHeader className="py-2">
          {/* {chosen.description} */}
          <div className="d-flex">
            <Dropdown
              className="d-lg-block navbar-collapse "
              isOpen={menu}
              onMouseEnter={() => menuToggle("hover", true)}
              onMouseLeave={() => menuToggle("hover", false)}
              toggle={() => menuToggle("click")}
            >
              <DropdownToggle className="btn" caret tag="button">
                <span className="mt-0 mb-0 h5">
                  {chosen.description}
                  <i className="mdi mdi-chevron-down text-muted ms-1" />
                </span>
              </DropdownToggle>
              <DropdownMenu className="">
                {data?.map((item, index) => (
                  <DropdownItem
                    className=""
                    key={index + item}
                    onClick={() => {
                      settingChosenNode(item)
                    }}
                  >
                    {item.description}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <button
            type="button"
            onClick={() => {
              setModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12" className="text-center">
              <RangeCalendarServices
                startDefault={historyDays}
                rangePicker={rangePicker}
                setCalendarStartDate={setStartDate}
                setCalendarEndDate={setEndDate}
              />
            </Col>
          </Row>
          <ModalNode
            t={props.t}
            data={chosen}
            tempData={tempData}
            humData={humData}
            activityData={activityData}
            loading={loading}
            startDate={startDate}
            endDate={endDate}
            chartDataBat={chartDataBat}
            historicalDataLoading={historicalDataLoading}
            chartDataTemp={chartDataTemp}
            chartDataActivityAvg={chartDataActivityAvg}
            chartDataHum={chartDataHum}
            settingHistoryDays={setHistoryDays}
            settingAll={setShowAll}
            allActivityData={allActivityData}
            historicalTooltipUnit={historicalTooltipUnit}
            daysLength={props.historyDays}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="float-end"
            outline
            onClick={() => {
              setModal(false)
            }}
          >
            {props.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const ModalNode = props => {
  // Node activity
  const [node, setNode] = useState()
  const [chart, setChart] = useState(2)
  const [activitySeriesName, setActivitySeriesName] = useState("Activity")
  const [activityTooltipUnit, setActivityTooltipUnit] = useState()
  const [activityAnnotation, setActivityAnnotation] = useState()
  const [seriesName, setSeriesName] = useState("Activity level")
  const [enableAllCharts, setEnableAllCharts] = useState(true)
  const [verticalActiveTab, setVerticalActiveTab] = useState("1")
  const [historyDays, setHistoryDays] = useState(14)
  const [switch1, setSwitch1] = useState(false)
  // Node changed - update both charts
  const settingChartInfo = data => {
    if (data != undefined) {
      setActivitySeriesName(data.deviceType)
      setActivityTooltipUnit("%")
      setActivityAnnotation()

      // Check allowed chart types based on device type
      if (data != undefined) {
        if (data.deviceType == "Electric") {
          setEnableAllCharts(false)
          setActivityTooltipUnit("W")
          setSeriesName("Power usage")
          if (data.powerOnLevel) {
            setActivityAnnotation({
              value: data.powerOnLevel,
              label: props.t("Power on level"),
            })
          }
          if (!data.powerOnLevel) {
            setActivityAnnotation({
              value: undefined,
            })
          }
        }
        if (data.deviceType == "Bed") {
          setActivityTooltipUnit("in use")
          setSeriesName("Bed active")
        }
        if (data.deviceType == "Door") {
          setActivityTooltipUnit("time(s)")
          setSeriesName("Door opened")
        }
      }
    }
  }

  useEffect(() => {
    if (props.data?.length !== 0 && props.data !== node) {
      let data = props.data
      setNode(props.data)
      settingChartInfo(data)
    }
  }, [props.data])
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setVerticalActiveTab(tab)
    }
  }
  useEffect(() => {
    if (historyDays !== undefined) {
      props.settingHistoryDays(historyDays)
    }
  }, [historyDays])

  // Button group for historical data
  const buttonGroupJSX = []
  buttonGroupJSX.push(
    <div key="buttong" className="text-center py-2">
      <ButtonGroup size="sm">
        {props.daysLength >= 14 && (
          <Button
            color="primary"
            active={historyDays === 14}
            onClick={() => setHistoryDays(14)}
            style={{
              paddingLeft: 15 + "px",
              paddingRight: 15 + "px",
            }}
          >
            {props.t("14 days")}
          </Button>
        )}
        {props.daysLength >= 30 && (
          <Button
            color="primary"
            active={historyDays === 30}
            onClick={() => setHistoryDays(30)}
            style={{
              paddingLeft: 15 + "px",
              paddingRight: 15 + "px",
            }}
          >
            {props.t("30 days")}
          </Button>
        )}
        {props.daysLength >= 60 && (
          <Button
            color="primary"
            active={historyDays === 60}
            onClick={() => setHistoryDays(60)}
            style={{
              paddingLeft: 15 + "px",
              paddingRight: 15 + "px",
            }}
          >
            {props.t("60 days")}
          </Button>
        )}
      </ButtonGroup>
    </div>
  )

  const onChange = checked => {
    if (checked) {
      props.settingAll(checked)
      setChart(1)
    }
    if (!checked) {
      props.settingAll(checked)
      setChart(2)
    }
    setSwitch1(checked)
  }

  return (
    <div key={props.index + "_modal"}>
      {props.loading ? (
        <>
          {props.data !== undefined && (
            <>
              <Col xs="12">
                <div
                  className="text-center text-primary py-5"
                  style={{ height: "600xp" }}
                >
                  <Link to="#" className="py-4">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    {props.t("Loading")}
                  </Link>
                </div>
              </Col>
            </>
          )}
        </>
      ) : (
        <>
          <h5>{props.t(props.data.description)}</h5>
          <Nav tabs className="nav-tabs-custom nav-justified mb-4 mt-2">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: verticalActiveTab === "1",
                })}
                onClick={() => {
                  toggleVertical("1")
                }}
              >
                <span className="d-none d-sm-block">
                  <i className="mdi mdi-walk"></i> {props.t("Activity")}
                </span>
              </NavLink>
            </NavItem>
            {enableAllCharts && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: verticalActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleVertical("2")
                  }}
                >
                  <i className="bx bxs-thermometer me-1" />
                  {props.t("Temperature")}
                </NavLink>
              </NavItem>
            )}

            {enableAllCharts && (
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: verticalActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleVertical("3")
                  }}
                >
                  <i className="bx bx-droplet me-1" />
                  {props.t("Humidity")}
                </NavLink>
              </NavItem>
            )}

            {enableAllCharts && (
              <>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    <i className="mdi mdi-battery-high me-1" />
                    {props.t("Battery")}
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
          <TabContent
            activeTab={verticalActiveTab}
            className="text-muted mt-md-0"
          >
            {props.activityData.length > 0 && (
              <TabPane tabId="1">
                <Switch
                  size="sm"
                  className="me-2 mt-0"
                  name="allChart"
                  checked={switch1}
                  onChange={onChange}
                  checkedChildren={<i className="mdi mdi-check-bold" />}
                  unCheckedChildren={<i className="mdi mdi-close-thick" />}
                />
                <Label>{props.t("Compare to all activities")}</Label>
                <NodeChartActivity
                  chart={chart}
                  serie={activitySeriesName}
                  startDate={props.startDate}
                  endDate={props.endDate}
                  data={props.activityData}
                  showLoading={props.loading}
                  tooltipUnit={activityTooltipUnit}
                  annotations={activityAnnotation}
                  allActivityData={props.allActivityData}
                ></NodeChartActivity>
                {props.chartDataActivityAvg.length > 0 && (
                  <>
                    {buttonGroupJSX}
                    <NodeChartActivityHistory
                      avg={props.chartDataActivityAvg}
                      seriesName={props.historicalActivityType}
                      tooltipUnit={props.historicalTooltipUnit}
                      showLoading={props.historicalDataLoading}
                    />
                  </>
                )}
              </TabPane>
            )}
            {props.tempData.length > 0 && enableAllCharts && (
              <TabPane tabId="2">
                <NodeChartTemp
                  startDate={props.startDate}
                  endDate={props.endDate}
                  data={props.tempData}
                  showLoading={props.loading}
                ></NodeChartTemp>
                {props.chartDataTemp.length > 0 && (
                  <>
                    {buttonGroupJSX}
                    <NodeChartTempHistory
                      data={props.chartDataTemp}
                      showLoading={props.historicalDataLoading}
                    />
                  </>
                )}
              </TabPane>
            )}
            {props.humData.length > 0 && enableAllCharts && (
              <TabPane tabId="3">
                <NodeChartHum
                  startDate={props.startDate}
                  endDate={props.endDate}
                  data={props.humData}
                  showLoading={props.loading}
                ></NodeChartHum>
                {props.chartDataHum.length > 0 && (
                  <div>
                    {buttonGroupJSX}
                    <NodeChartHumHistory
                      data={props.chartDataHum}
                      showLoading={props.historicalDataLoading}
                    />
                  </div>
                )}
              </TabPane>
            )}
            {props.chartDataBat.length > 0 && enableAllCharts && (
              <TabPane tabId="4">
                {buttonGroupJSX}
                <NodeChartBatteryHistory
                  startDate={props.startDate}
                  endDate={props.endDate}
                  data={props.chartDataBat}
                  showLoading={props.loading}
                />
              </TabPane>
            )}
          </TabContent>
        </>
      )}
    </div>
  )
}

NodeCards.propTypes = {
  data: PropTypes.any,
  t: PropTypes.any,
  settingHistoryDays: PropTypes.any,
  settingAll: PropTypes.any,
  gatewayKey: PropTypes.any,
  historyDays: PropTypes.any,
  compare: PropTypes.any,
  getNodes: PropTypes.any,
}
ModalNode.propTypes = {
  data: PropTypes.any,
  t: PropTypes.any,
  index: PropTypes.any,
  humData: PropTypes.any,
  tempData: PropTypes.any,
  activityData: PropTypes.any,
  loading: PropTypes.bool,
  chartDataBat: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  historicalDataLoading: PropTypes.any,
  chartDataHum: PropTypes.any,
  chartDataActivityAvg: PropTypes.any,
  chartDataTemp: PropTypes.any,
  historicalTooltipUnit: PropTypes.any,
  historicalActivityType: PropTypes.any,
  settingHistoryDays: PropTypes.any,
  settingAll: PropTypes.any,
  allActivityData: PropTypes.any,
  daysLength: PropTypes.any,
}

export default withTranslation()(NodeCards)
