import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  FormFeedback,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Divider, InputNumber, TimePicker, ConfigProvider, Select, Steps, Slider } from "antd"
import { sendVideoAppSettings } from "helpers/backend_helper"
import { getLocale, showToast } from "utils/general"
import withRouter from "components/Common/withRouter"
import { isEqual } from "lodash"
import { Chip } from "@material-ui/core"
import SimpleBar from "simplebar-react"
import moment from "moment"

const VideoAppSettingsModal = props => {
  const locale = getLocale()
  const [update, setUpdate] = useState(false)
  const [changedValues, setChangedValues] = useState({})
  const [changedProps, setChangedProps] = useState(0)
  const [changesTable, setChangesTable] = useState([])
  const [changedValuesString, setChangedValuesString] = useState("")
  const [displayedNames, setDisplayedNames] = useState([])
  const [remainingNames, setRemainingNames] = useState([])
  const [showAll, setShowAll] = useState(false)

  const [dimUnit, setDimUnit] = useState("minutes")
  const [screensaverContent, setScreensaverContent] = useState("")
  const [restartTime, setRestartTime] = useState(null)
  const [daytimeRange, setDaytimeRange] = useState([null, null])
  const [gridCols] = useState([4, 8])
  const [currentStep, setCurrentStep] = useState(0)
  const modalSteps = [
    {
      title: props.t("Settings"),
    },
    {
      title: props.t("Confirm"),
    },
  ]

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (!props.showModal) {
      resetFormValues()
    }
    else {
      setCurrentStep(0)
    }
  }, [props.showModal])

  useEffect(() => {
    if (props.clients.length > 0) {
      let names = showAll ? props.clients : props.clients.slice(0, 5)
      setDisplayedNames(names)
      let remaining = props.clients.length - 5
      setRemainingNames(remaining)
    }
  }, [showAll, props.clients])

  // Settings validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      settings_user_name: "",
      settings_user_password: "",
      //webDemoUser: "",
      //webDemoPass: "",

      usesDimMinutes: null,
      dimMinutes: null,
      dimSeconds: null,

      clockEnabled: null,
      analogClockEnabled: null,
      galleryMode: null,

      automaticPickup: "",
      answerTime: null,

      applicationPipShown: "",
      showHealthButton: "",
      showDebugStats: "",

      usesProduction: "",
      uses_assisted_restart: "",

      restartTimeHour: null,
      restartTimeMinute: null,
      restartTimeSecond: null,

      morningTimeHour: null,
      morningTimeMinute: null,
      nightTimeHour: null,
      nightTimeMinute: null,

      language: "",
      brightness: null,
      volume: null
    },
    validationSchema: Yup.object({
      settings_user_name: Yup.string()
        .email(props.t("Invalid format")),
      //settings_user_password: Yup.string().min(12, props.t("Too short"))
    }),
    onSubmit: values => {
      console.log("Submit values: ", values)
      console.log("Changed values: ", changedValues, props.keys)

      if (props.keys && changedProps > 0 && !update) {
        setUpdate(true)
        sendVideoAppSettings(abortRef.current, props.keys.join(), changedValues)
          .then(result => {
            console.log("Result: ", result)
            if (result.statusCode == 200) {
              showToast(props.t("Command sent"), "success")
              resetFormValues()
              props.setShowModal(false)
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            showToast(props.t("Error"), "error")
            console.log(err)
          })
          .finally(() => {
            setUpdate(false)
          })
      }
    },
  })

  useEffect(() => {
    // Get changed values compared to initial
    const initialFormValues = JSON.parse(
      JSON.stringify(validation.initialValues)
    )
    let temp = {}
    for (const key in validation.values) {
      if (!isEqual(initialFormValues[key], validation.values[key])) {
        temp[key] = validation.values[key]
      }
    }
    setChangedValues(temp)
    setChangedValuesString(JSON.stringify(temp))
    setChangedProps(Object.keys(temp).length)

    // Confirmation table content
    let table = []

    if (temp.settings_user_name) table.push({ "loc": props.t("Username"), "value": temp.settings_user_name })
    if (temp.settings_user_password) table.push({ "loc": props.t("Password"), "value": temp.settings_user_password })

    if (temp.automaticPickup != null) table.push({ "loc": props.t("Auto answer"), "value": temp.automaticPickup ? props.t("Enabled") : props.t("Disabled") })
    if (temp.answerTime != null) table.push({ "loc": props.t("Answer time"), "value": temp.answerTime + "s" })

    if (daytimeRange?.[0]) table.push({ "loc": props.t("Screensaver active"), "value": daytimeRange[0].format('HH:mm') + " - " + daytimeRange[1].format('HH:mm') })
    if (restartTime) table.push({ "loc": props.t("Restart time"), "value": restartTime.format('HH:mm') })

    if (screensaverContent) {
      switch (screensaverContent) {
        case "nothing": table.push({ "loc": props.t("Screensaver"), "value": props.t("Nothing") }); break;
        case "analog": table.push({ "loc": props.t("Screensaver"), "value": props.t("Analog clock") }); break;
        case "digital": table.push({ "loc": props.t("Screensaver"), "value": props.t("Digital clock") }); break;
      }
    }

    if (temp.usesDimMinutes != null) {
      if (temp.usesDimMinutes) {
        table.push({ "loc": props.t("Screensaver delay"), "value": temp.dimMinutes + "min" })
      }
      else {
        table.push({ "loc": props.t("Screensaver delay"), "value": temp.dimSeconds + "s" })
      }
    }

    if (temp.language != null) {
      switch (temp.language) {
        case "fi": table.push({ "loc": props.t("Language"), "value": props.t("Finnish") }); break;
        case "en": table.push({ "loc": props.t("Language"), "value": props.t("English") }); break;
        case "sv": table.push({ "loc": props.t("Language"), "value": props.t("Swedish") }); break;
      }
    }

    if (temp.volume != null) table.push({ "loc": props.t("Volume"), "value": temp.volume + "%" })
    if (temp.brightness != null) table.push({ "loc": props.t("Screen brightness"), "value": temp.brightness + "%" })

    if (temp.applicationPipShown != null) table.push({ "loc": "PiP", "value": temp.applicationPipShown ? props.t("Enabled") : props.t("Disabled") })
    if (temp.showHealthButton != null) table.push({ "loc": "Health", "value": temp.showHealthButton ? props.t("Enabled") : props.t("Disabled") })
    if (temp.showDebugStats != null) table.push({ "loc": "Debug", "value": temp.showDebugStats ? props.t("Enabled") : props.t("Disabled") })
    if (temp.uses_assisted_restart != null) table.push({ "loc": props.t("Assisted restart"), "value": temp.uses_assisted_restart ? props.t("Enabled") : props.t("Disabled") })
    if (temp.usesProduction != null) table.push({ "loc": props.t("Platform"), "value": temp.usesProduction ? props.t("Production") : props.t("Demo") })

    table.sort((a, b) => { return a.loc.localeCompare(b.loc) })
    setChangesTable(table)

  }, [validation.values])

  // Toggle show all recipients
  const toggleShowAll = () => {
    setShowAll(!showAll)
  }

  // Reset form
  const resetFormValues = () => {
    validation.resetForm()
    setScreensaverContent("")
    setRestartTime(null)
    setDaytimeRange([null, null])
  }

  return (
    <>
      <Modal
        isOpen={props.showModal}
        toggle={() => {
          props.setShowModal(!props.showModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Change settings")}</ModalHeader>
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            return false
          }}
        >
          <ModalBody>
            <Steps
              type="navigation"
              size="small"
              className="pb-3 pt-0"
              //onChange={(v) => {changedProps > 0 ? setCurrentStep(v) : null}}
              current={currentStep}
              items={modalSteps}
            ></Steps>

            {currentStep == 0 && (
              <SimpleBar className="pe-3" style={{ maxHeight: "50vh" }}>

                {props.keys.length == 1 && props.isSystemAdmin && <>
                  <Divider
                    orientation="left"
                    className="font-size-14 mt-1"
                    orientationMargin="0"
                  >
                    {props.t("User")}
                  </Divider>
                  <Row xs={1}>
                    <Col>
                      <FormGroup row>
                        <Label sm={gridCols[0]}>{props.t("Username")}</Label>
                        <Col sm={gridCols[1]}>
                          <Input
                            //bsSize="sm"
                            name="settings_user_name"
                            type="text"
                            placeholder={props.t("room-number@suvanto.video")}
                            value={validation.values.settings_user_name}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(validation.touched.settings_user_name &&
                                validation.errors.settings_user_name)
                            }
                          ></Input>
                          {validation.touched.settings_user_name &&
                            validation.errors.settings_user_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.settings_user_name}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Label sm={gridCols[0]}>{props.t("Password")}</Label>
                        <Col sm={gridCols[1]}>
                          <Input
                            //bsSize="sm"
                            name="settings_user_password"
                            type="text"
                            placeholder={props.t("Password")}
                            value={validation.values.settings_user_password}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(validation.touched.settings_user_password &&
                                validation.errors.settings_user_password)
                            }
                          ></Input>
                          {validation.touched.settings_user_password &&
                            validation.errors.settings_user_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.settings_user_password}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Col>
                    {/** 
                    <Col>
                      <FormGroup>
                        <Input
                          //bsSize="sm"
                          name="webDemoUser"
                          type="text"
                          placeholder={props.t("Username") + " (Demo)"}
                          value={validation.values.webDemoUser}
                          onChange={validation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          //bsSize="sm"
                          name="webDemoPass"
                          type="text"
                          placeholder={props.t("Password") + " (Demo)"}
                          value={validation.values.webDemoPass}
                          onChange={validation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>*/}
                  </Row>
                </>}

                <Divider
                  orientation="left"
                  className="font-size-14 mt-2"
                  orientationMargin="0"
                >
                  {props.t("General")}
                </Divider>
                <Row xs={1}>
                  <Col>
                    <FormGroup row>
                      <Label sm={gridCols[0]}>{props.t("Language")}</Label>
                      <Col sm={gridCols[1]}>
                        <Input
                          //bsSize="sm"
                          name="language"
                          type="select"
                          value={validation.values.language}
                          onChange={(v) => {
                            validation.setFieldValue("language", v.target.value)
                          }}
                        >
                          <option value="">{props.t("Undefined")}</option>
                          <option value="fi">
                            {props.t("Finnish")}
                          </option>
                          <option value="en">
                            {props.t("English")}
                          </option>
                          <option value="sv">
                            {props.t("Swedish")}
                          </option>
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Label sm={gridCols[0]}>{props.t("Volume")}</Label>
                      <Col sm={gridCols[1]}>
                        <Slider
                          min={1}
                          max={100}
                          onChange={(v) => { validation.setFieldValue("volume", v) }}
                          value={typeof validation.values.volume === 'number' ? validation.values.volume : 0}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Label sm={gridCols[0]}>{props.t("Screen brightness")}</Label>
                      <Col sm={gridCols[1]}>
                        <Slider
                          min={1}
                          max={100}
                          onChange={(v) => { validation.setFieldValue("brightness", v) }}
                          value={typeof validation.values.brightness === 'number' ? validation.values.brightness : 0}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Divider
                  orientation="left"
                  className="font-size-14 mt-4"
                  orientationMargin="0"
                >
                  {props.t("Auto answer")}
                </Divider>
                <Row xs={1}>
                  <Col>
                    <FormGroup row>
                      <Label sm={gridCols[0]}>{props.t("Status")}</Label>
                      <Col sm={gridCols[1]}>
                        <Input
                          //bsSize="sm"
                          name="automaticPickup"
                          type="select"
                          value={validation.values.automaticPickup}
                          onChange={(v) => {
                            switch (v.target.value) {
                              case "true": validation.setFieldValue("automaticPickup", true); break;
                              case "false": validation.setFieldValue("automaticPickup", false); break;
                              default: validation.setFieldValue("automaticPickup", ""); break;
                            }
                          }}
                        >
                          <option value="">{props.t("Undefined")}</option>
                          <option value="true">
                            {props.t("Enabled")}
                          </option>
                          <option value="false">
                            {props.t("Disabled")}
                          </option>
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Label sm={gridCols[0]}>{props.t("Answer time")}</Label>
                      <Col sm={gridCols[1]}>
                        <InputNumber
                          //size="small"
                          style={{ width: "100%" }}
                          min={1}
                          max={120}
                          addonAfter={props.t("s")}
                          value={validation.values.answerTime}
                          onChange={(newVal) => { validation.setFieldValue("answerTime", newVal) }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Divider
                  orientation="left"
                  className="font-size-14 mt-2"
                  orientationMargin="0"
                >
                  {props.t("Screensaver")}
                </Divider>
                <Row xs={1}>
                  <Col>
                    <FormGroup row>
                      <Label sm={gridCols[0]}>{props.t("Content")}</Label>
                      <Col sm={gridCols[1]}>
                        <Input
                          //bsSize="sm"
                          type="select"
                          value={screensaverContent}
                          onChange={(v) => {
                            setScreensaverContent(v.target.value)
                            switch (v.target.value) {
                              case "nothing":
                                validation.setFieldValue("clockEnabled", false);
                                validation.setFieldValue("analogClockEnabled", false);
                                break;
                              case "analog":
                                validation.setFieldValue("clockEnabled", true);
                                validation.setFieldValue("analogClockEnabled", true);
                                break;
                              case "digital":
                                validation.setFieldValue("clockEnabled", true);
                                validation.setFieldValue("analogClockEnabled", false);
                                break;
                              default:
                                validation.setFieldValue("clockEnabled", null);
                                validation.setFieldValue("analogClockEnabled", null);
                                break;
                            }
                          }}
                        >
                          <option value="">{props.t("Undefined")}</option>
                          <option value="nothing">{props.t("Nothing")}</option>
                          <option value="analog">{props.t("Analog clock")}</option>
                          <option value="digital">{props.t("Digital clock")}</option>
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Label sm={gridCols[0]}>{props.t("Delay")}</Label>
                      <Col sm={gridCols[1]}>
                        <InputNumber
                          //className="me-2"
                          style={{ width: "100%" }}
                          min={1}
                          max={60}
                          addonAfter={<Select value={dimUnit} style={{ minWidth: "80px" }} onChange={(v) => { setDimUnit(v); validation.setFieldValue("usesDimMinutes", null); validation.setFieldValue("dimSeconds", null); validation.setFieldValue("dimMinutes", null); }}>
                            <Select.Option value="minutes">min</Select.Option>
                            <Select.Option value="seconds">s</Select.Option>
                          </Select>}
                          value={dimUnit == "seconds" ? validation.values.dimSeconds : validation.values.dimMinutes}
                          onChange={(newVal) => {
                            if (dimUnit == "seconds") {
                              validation.setFieldValue("dimSeconds", newVal)
                              validation.setFieldValue("usesDimMinutes", false)
                            }
                            else if (dimUnit == "minutes") {
                              validation.setFieldValue("dimMinutes", newVal)
                              validation.setFieldValue("usesDimMinutes", true)
                            }
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Label sm={gridCols[0]}>{props.t("Active period")}</Label>
                      <Col sm={gridCols[1]}>
                        <ConfigProvider locale={locale}>
                          <TimePicker.RangePicker
                            //className="me-2"
                            style={{ width: "100%" }}
                            value={daytimeRange}
                            onChange={(v) => {
                              setDaytimeRange(v)
                              if (v != null) {
                                let mins = moment(v[0]).get("minutes")
                                let hours = moment(v[0]).get("hours")
                                validation.setFieldValue("morningTimeHour", hours)
                                validation.setFieldValue("morningTimeMinute", mins)

                                mins = moment(v[1]).get("minutes")
                                hours = moment(v[1]).get("hours")
                                validation.setFieldValue("nightTimeHour", hours)
                                validation.setFieldValue("nightTimeMinute", mins)
                              }
                              else {
                                validation.setFieldValue("morningTimeHour", null)
                                validation.setFieldValue("morningTimeMinute", null)
                                validation.setFieldValue("nightTimeHour", null)
                                validation.setFieldValue("nightTimeMinute", null)
                              }
                            }}
                            format={"HH:mm"}
                          />
                        </ConfigProvider>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                {props.isSystemAdmin && <>
                  <Divider
                    orientation="left"
                    className="font-size-14 mt-2"
                    orientationMargin="0"
                  >
                    {props.t("Other")}
                  </Divider>
                  <Row xs={1}>
                    <Col>
                      <FormGroup row>
                        <Label sm={gridCols[0]}>{props.t("Restart time")}</Label>
                        <Col sm={gridCols[1]}>
                          <ConfigProvider locale={locale}>
                            <TimePicker
                              style={{ width: "100%" }}
                              value={restartTime}
                              onChange={(v) => {
                                setRestartTime(v)
                                if (v != null) {
                                  let mins = moment(v).get("minutes")
                                  let hours = moment(v).get("hours")
                                  validation.setFieldValue("restartTimeHour", hours)
                                  validation.setFieldValue("restartTimeMinute", mins)
                                }
                                else {
                                  validation.setFieldValue("restartTimeHour", null)
                                  validation.setFieldValue("restartTimeMinute", null)
                                }
                              }}
                              format={"HH:mm"}
                            />
                          </ConfigProvider>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Label sm={gridCols[0]}>{props.t("PiP")}</Label>
                        <Col sm={gridCols[1]}>
                          <Input
                            //bsSize="sm"
                            name="applicationPipShown"
                            type="select"
                            value={validation.values.applicationPipShown}
                            onChange={(v) => {
                              switch (v.target.value) {
                                case "true": validation.setFieldValue("applicationPipShown", true); break;
                                case "false": validation.setFieldValue("applicationPipShown", false); break;
                                default: validation.setFieldValue("applicationPipShown", ""); break;
                              }
                            }}
                          >
                            <option value="">{props.t("Undefined")}</option>
                            <option value="true">{props.t("Enabled")}</option>
                            <option value="false">{props.t("Disabled")}</option>
                          </Input>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Label sm={gridCols[0]}>{"Health"}</Label>
                        <Col sm={gridCols[1]}>
                          <Input
                            //bsSize="sm"
                            name="showHealthButton"
                            type="select"
                            value={validation.values.showHealthButton}
                            onChange={(v) => {
                              switch (v.target.value) {
                                case "true": validation.setFieldValue("showHealthButton", true); break;
                                case "false": validation.setFieldValue("showHealthButton", false); break;
                                default: validation.setFieldValue("showHealthButton", ""); break;
                              }
                            }}
                          >
                            <option value="">{props.t("Undefined")}</option>
                            <option value="true">{props.t("Enabled")}</option>
                            <option value="false">{props.t("Disabled")}</option>
                          </Input>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Label sm={gridCols[0]}>{props.t("Debug")}</Label>
                        <Col sm={gridCols[1]}>
                          <Input
                            //bsSize="sm"
                            name="showDebugStats"
                            type="select"
                            value={validation.values.showDebugStats}
                            onChange={(v) => {
                              switch (v.target.value) {
                                case "true": validation.setFieldValue("showDebugStats", true); break;
                                case "false": validation.setFieldValue("showDebugStats", false); break;
                                default: validation.setFieldValue("showDebugStats", ""); break;
                              }
                            }}
                          >
                            <option value="">{props.t("Undefined")}</option>
                            <option value={true}>{props.t("Enabled")}</option>
                            <option value={false}>{props.t("Disabled")}</option>
                          </Input>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Label sm={gridCols[0]}>{props.t("Assisted restart")}</Label>
                        <Col sm={gridCols[1]}>
                          <Input
                            //bsSize="sm"
                            name="uses_assisted_restart"
                            type="select"
                            value={validation.values.uses_assisted_restart}
                            onChange={(v) => {
                              switch (v.target.value) {
                                case "true": validation.setFieldValue("uses_assisted_restart", true); break;
                                case "false": validation.setFieldValue("uses_assisted_restart", false); break;
                                default: validation.setFieldValue("uses_assisted_restart", ""); break;
                              }
                            }}
                          >
                            <option value="">{props.t("Undefined")}</option>
                            <option value="true">{props.t("Enabled")}</option>
                            <option value="false">{props.t("Disabled")}</option>
                          </Input>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Label sm={gridCols[0]}>{props.t("Platform")}</Label>
                        <Col sm={gridCols[1]}>
                          <Input
                            //bsSize="sm"
                            name="usesProduction"
                            type="select"
                            value={validation.values.usesProduction}
                            onChange={(v) => {
                              switch (v.target.value) {
                                case "true": validation.setFieldValue("usesProduction", true); break;
                                case "false": validation.setFieldValue("usesProduction", false); break;
                                default: validation.setFieldValue("usesProduction", ""); break;
                              }
                            }}
                          >
                            <option value="">{props.t("Undefined")}</option>
                            <option value="true">{props.t("Production")}</option>
                            <option value="false">{props.t("Demo")}</option>
                          </Input>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </>}
              </SimpleBar>
            )}

            {currentStep == 1 && (
              <>
                <Divider
                  orientation="left"
                  className="font-size-14 mt-1"
                  orientationMargin="0"
                >
                  {props.t("Changes")}
                </Divider>

                <SimpleBar className="pe-3" style={{ maxHeight: "50vh" }}>
                  <Table
                    style={{ tableLayout: "fixed" }}
                    className="table"
                    bordered={false}
                    striped={false}
                    hover={true}
                  >
                    <tbody>
                      {changesTable.map((item, index) => {
                        return <tr key={"change_" + index}>
                          <th scope="row">{item.loc}</th>
                          <td>{item.value}</td>
                        </tr>
                      })}
                    </tbody>
                  </Table>
                  <Alert color="info" className="mt-2" style={{ overflowWrap: "anywhere" }}>
                    <h6 className="alert-heading">
                      Debugging
                    </h6>
                    <div>{changedValuesString}</div>
                  </Alert>
                </SimpleBar>

                <Divider
                  orientation="left"
                  className="font-size-14 mt-2"
                  orientationMargin="0"
                >
                  {props.t("Receivers")}
                </Divider>
                <div>
                  {displayedNames.map((name, index) => (
                    <Chip
                      size="small"
                      key={""+index}
                      className="mt-0 mb-2 font-size-12 me-1"
                      label={name.name}
                    ></Chip>
                  ))}
                  {remainingNames > 0 && !showAll && (
                    <Chip
                      size="small"
                      className="mt-0 mb-2 font-size-12 me-1"
                      label={`+ ${remainingNames} ${props.t("others")}`}
                    />
                  )}
                  {remainingNames > 0 && (
                    <button onClick={toggleShowAll} className="btn btn-link btn-sm">
                      {showAll ? props.t("Hide") : props.t("Show all")}
                    </button>
                  )}
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter className="">
            <div className="float-end">
              <button
                type="button"
                onClick={() => {
                  props.setShowModal(false)
                }}
                className="btn btn-outline-primary "
                data-dismiss="modal"
              >
                {props.t("Cancel")}
              </button>

              {currentStep == 0 && <>
                <button
                  className="btn btn-outline-danger ms-2"
                  onClick={() => {
                    resetFormValues()
                  }}
                >
                  {props.t("Reset form")}
                </button>
                <button
                  disabled={changedProps == 0}
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    setCurrentStep(1)
                  }}
                >
                  {props.t("Next")}
                </button>

              </>}

              {currentStep == 1 && <>
                <button
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    setCurrentStep(0)
                  }}
                >
                  {props.t("Previous")}
                </button>
                <button
                  disabled={props.keys?.length == 0 || changedProps == 0}
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    validation.submitForm()
                  }}
                >
                  {props.t("Send")}
                </button>
              </>}

            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}

VideoAppSettingsModal.propTypes = {
  t: PropTypes.any,
  keys: PropTypes.any,
  clients: PropTypes.any,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  isSystemAdmin: PropTypes.bool,
}

export default withRouter(withTranslation()(VideoAppSettingsModal))
