import PropTypes from "prop-types"
import React, { useContext, useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  Col,
  CardTitle,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Alert,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap"

import { isEqual } from "lodash"

import * as Yup from "yup"
import { useFormik } from "formik"
import {
  geocodeGetCoordinates,
  getClientData,
  updateClientInfo,
} from "../../helpers/backend_helper"
import GlobalState from "contexts/GlobalState"
import PubSub from "pubsub-js"
import Lottie from "react-lottie"
import { Switch } from "antd"
import { getLottieLoadingOptions, showToast } from "utils/general"
import SelectGroup from "components/DropdownList/SelectGroup"
import DefaultMap from "components/GoogleMap/map"

const ClientInfo = props => {
  const [state] = useContext(GlobalState)
  const [clientData, setClientData] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [userRole, setUserRole] = useState(null)
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [geolocating, setGeolocating] = useState(false)
  const [contacts, setContacts] = useState([])
  const [contactsEdit, setContactsEdit] = useState([])
  const [mapModal, setMapModal] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getData()
    })
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
      if (role === "system_admin") {
        setIsAdmin(true)
      }
    }
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (props.suvantoKey) {
      getData()
    }
  }, [props.suvantoKey])

  useEffect(() => {
    if (props.suvantoKey) {
      setContactsEdit(contacts)
    }
  }, [editMode])

  // Get client data
  const getData = () => {
    if (props.suvantoKey) {
      setLoading(true)
      getClientData(abortRef.current, props.suvantoKey, false)
        .then(result => {
          if (result.key) {
            //console.log("CLIENT DATA: ", result)

            if (result.timezoneOffset != null) {
              if (result.timezoneOffset < 0)
                result.timezoneOffsetString = "UTC" + result.timezoneOffset
              else result.timezoneOffsetString = "UTC+" + result.timezoneOffset
            }
            setClientData(result)

            let tempContacts = []
            if (result.contacts) {
              let split = result.contacts.split(",")
              split.forEach(element => {
                if (element.indexOf("+") > 0) {
                  let splat = element.split("+")
                  let temp = new Object()
                  temp.name = splat[0].trim()
                  temp.gsm = "+" + splat[1].trim()
                  tempContacts.push(temp)
                } else {
                  let temp = new Object()
                  temp.name = element.trim()
                  temp.gsm = ""
                  tempContacts.push(temp)
                }
              })
            }
            setContacts(tempContacts)
          }
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
    }
  }

  // Filter unchanged values
  const filterUnchangedValues = (originalValues, newValues) => {
    const changedValues = {}

    for (const key in newValues) {
      if (!isEqual(originalValues[key], newValues[key])) {
        changedValues[key] = newValues[key]
      }
    }
    return changedValues
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: clientData?.name || "",
      group: clientData?.group || "",
      gsmNumber: clientData?.gsmNumber || "",
      address: clientData?.address || "",
      ssnNumber: clientData?.ssn || "",
      timezoneOffset: clientData?.timezoneOffset || "0",
      language: clientData?.language || "fi",
      contacts: clientData?.contacts || "",
      displayName: clientData?.displayName || "",
      appOtherInfo: clientData?.appOtherInfo || "",
      information: clientData?.information || "",
      storeAlarmsPeriod: clientData?.alarmRetentionPeriod || "0",
      storeHealthDataPeriod: clientData?.healthRetentionPeriod || "0",
      storeVideoDataPeriod: clientData?.videoRetentionPeriod || "0",
      away: clientData?.away || false,
      timezoneCheckEnabled: clientData?.timezoneCheckEnabled || false,
      latitude: clientData?.coordinates?.latitude || "",
      longitude: clientData?.coordinates?.longitude || "",
      videoDisplayName: clientData?.video?.displayName || "",
      storeTrackerDataPeriod: clientData?.tracker?.dataRetention || "0",
      storeHomeDataPeriod: clientData?.home?.dataRetention || "14",
      restStartHour: clientData?.home?.restStartHour || "20",
      learningPeriod: clientData?.home?.learningPeriod || "14",
    },
    validationSchema: Yup.object({
      displayName: Yup.string().matches(
        /^[a-zA-Z0-9\s\-()!?\&.,+#äöåøæÄÖÅÆØ]*$/,
        props.t("ContainsNotAllowedCharacters")
      ),
      videoDisplayName: Yup.string().matches(
        /^[a-zA-Z0-9\s\-()!?\&.,+#äöåøæÄÖÅÆØ]*$/,
        props.t("ContainsNotAllowedCharacters")
      ),
      name: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s\-()!?\&.,+#äöåøæÄÖÅÆØ]*$/,
          props.t("ContainsNotAllowedCharacters")
        )
        .min(3, props.t("Too short"))
        .required(props.t("Required field")),
      group: Yup.string().required(props.t("Required field")),
    }),

    onSubmit: values => {
      console.log("Submit: ", values)

      const initialFormValues = JSON.parse(
        JSON.stringify(validation.initialValues)
      )

      // Filter out unchanged values
      const changedValues = filterUnchangedValues(initialFormValues, values)
      //console.log("Filtered changed: ", changedValues)
      let temp = { ...changedValues }

      // let temp = JSON.parse(JSON.stringify(values))
      if (temp.timezoneOffset) {
        temp.timezoneOffset = parseInt(values.timezoneOffset, 10)
      }
      if (temp.storeAlarmsPeriod) {
        temp.storeAlarmsPeriod = parseInt(values.storeAlarmsPeriod, 10)
      }
      if (temp.storeHealthDataPeriod) {
        temp.storeHealthDataPeriod = parseInt(values.storeHealthDataPeriod, 10)
      }
      if (temp.storeVideoDataPeriod) {
        temp.storeVideoDataPeriod = parseInt(values.storeVideoDataPeriod, 10)
      }
      if (temp.storeTrackerDataPeriod != null && temp.storeTrackerDataPeriod)
        temp.storeTrackerDataPeriod = parseInt(
          values.storeTrackerDataPeriod,
          10
        )
      if (temp.storeHomeDataPeriod != null && temp.storeHomeDataPeriod)
        temp.storeHomeDataPeriod = parseInt(values.storeHomeDataPeriod, 10)
      //if (temp.restStartHour != null) temp.restStartHour = parseInt(values.restStartHour, 10)
      if (temp.learningPeriod != null && temp.learningPeriod)
        temp.learningPeriod = parseInt(values.learningPeriod, 10)

      if (temp.latitude || temp.longitude) {
        let coords = new Object()
        let lat = parseFloat(values.latitude)
        let lng = parseFloat(values.longitude)
        coords.latitude = isNaN(lat) ? 0 : lat
        coords.longitude = isNaN(lng) ? 0 : lng
        coords.elevation = 0
        temp.coordinates = coords
      }

      //if (!isAdmin) temp.information = null

      if (contactsEdit !== contacts) {
        console.log("Contacts changed:", contactsEdit, contacts)
        // Contacts
        let contactString = ""
        contactsEdit.forEach(element => {
          let con = element.name.trim() + " " + element.gsm.trim()
          if (contactString.length == 0) contactString = con
          else contactString = contactString + "," + con
        })
        temp.contacts = contactString
      }

      //console.log("CONTS: ", contactsEdit, contactString)
      console.log("Final Submit: ", temp)

      if (temp.name) {
        props.setClientName(temp.name)
      }

      if (clientData?.key) {
        setUpdating(true)
        updateClientInfo(abortRef.current, clientData.key, temp)
          .then(result => {
            console.log(result)
            setUpdating(false)
            if (result.statusCode == 200) {
              setEditMode(false)
              getData()
              showToast(props.t("Information updated"), "success")
              // Update SelectClientmagement.js getClients TODO: Delete
              // props.refreshClients()
            } else {
              showToast(props.t("Failed to update information"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            setUpdating(false)
            showToast(props.t("Failed to update information"), "error")
          })
      }
    },
  })

  // New contact validation
  const contactValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      gsm: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required field")),
      gsm: Yup.string().matches(
        /^(\+)[1-9]\d{7,32}$/,
        props.t("Invalid format")
      ),
      // Not required as the button is disabled if field is empty.
      // Gives unnecessary feedback if the field is cleared.
      // .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)

      let temp = new Object()
      temp.name = values.name
      temp.gsm = values.gsm

      setContactsEdit(prevState => [...prevState, temp])
      contactValidation.resetForm()
    },
  })

  // Get coordinates
  const getCoordinates = () => {
    if (validation.values.address && !geolocating) {
      setGeolocating(true)
      geocodeGetCoordinates(abortRef.current, validation.values.address)
        .then(result => {
          //console.log("COORDS: ", result)
          if (result.statusCode == 200 && result.coordinates) {
            validation.setFieldValue("latitude", "" + result.coordinates.lat)
            validation.setFieldValue("longitude", "" + result.coordinates.lng)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setGeolocating(false)
        })
    }
  }

  // Location changed in map
  const mapLocationChanged = latLng => {
    console.log("mapLocationChanged", latLng)
    validation.setFieldValue("latitude", latLng.lat.toFixed(6))
    validation.setFieldValue("longitude", latLng.lng.toFixed(6))
  }

  // Get translatable language
  const translateLang = lang => {
    switch (lang) {
      case "fi":
        return "Finnish"
      case "en":
        return "English"
      case "sv":
        return "Swedish"
      default:
        return "Undefined"
    }
  }

  // Group selection changed
  const groupChanged = newGroup => {
    validation.setFieldValue("group", newGroup)
  }

  return (
    <>
      {loading && (
        <Lottie options={getLottieLoadingOptions()} height={100} width={100} />
      )}

      {clientData && !loading && (
        <>
          <Card className="mt-3">
            <CardBody>
              <CardTitle className="mb-4">
                <Row>
                  <Col className="d-flex" style={{ alignItems: "center" }}>
                    <i className="bx bxs-user-circle text-truncate align-middle me-2"></i>
                    {props.t("Personal information")}
                  </Col>

                  <Col className="col-auto">
                    {!editMode && (
                      <Button
                        color="primary"
                        size="sm"
                        className=""
                        onClick={() => {
                          setEditMode(true)
                        }}
                      >
                        <i className="me-2 bx bxs-edit text-truncate align-middle"></i>
                        <span className="">{props.t("Edit")}</span>
                      </Button>
                    )}
                    {editMode && (
                      <>
                        <Button
                          color="primary"
                          size="sm"
                          className=""
                          onClick={() => {
                            setEditMode(false)
                            validation.resetForm()
                            contactValidation.resetForm()
                          }}
                        >
                          <i className="me-2 bx bxs-x-square text-truncate align-middle"></i>
                          <span className="">{props.t("Cancel")}</span>
                        </Button>
                        <Button
                          color="success"
                          size="sm"
                          disabled={updating}
                          className="ms-3"
                          onClick={() => {
                            validation.submitForm()
                          }}
                        >
                          <i className="me-2 bx bxs-save text-truncate align-middle"></i>
                          <span className="">{props.t("Save changes")}</span>
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </CardTitle>

              {!editMode && (
                <>
                  <Row>
                    <Col xs={12} lg={8}>
                      <Row xs={1} sm={2} className="mt-3">
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Name")}:</h6> {clientData.name}
                          </div>
                        </Col>
                        {userRole != "private_user" && (
                          <Col>
                            <div className="text-muted mb-4">
                              <h6>{props.t("Group")}:</h6> {clientData.group}
                            </div>
                          </Col>
                        )}
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("GSM")}:</h6>{" "}
                            {clientData.gsmNumber
                              ? "+" + clientData.gsmNumber
                              : props.t("Undefined")}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("SSN")}:</h6>{" "}
                            {clientData.ssn
                              ? clientData.ssn
                              : props.t("Undefined")}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Address")}:</h6>{" "}
                            {clientData.address
                              ? clientData.address
                              : props.t("Undefined")}
                            {clientData.coordinates &&
                              clientData.coordinates.latitude != 0 && (
                                <div>
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={
                                      "https://www.google.com/maps/place/" +
                                      clientData.coordinates.latitude +
                                      "," +
                                      clientData.coordinates.longitude
                                    }
                                  >
                                    {clientData.coordinates?.latitude +
                                      ", " +
                                      clientData.coordinates?.longitude}
                                  </a>
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Timezone")}:</h6>{" "}
                            {clientData.timezoneOffsetString
                              ? clientData.timezoneOffsetString
                              : props.t("Undefined")}
                            {clientData.timezoneCheckEnabled && (
                              <p>{props.t("Automatic")}</p>
                            )}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Language")}:</h6>{" "}
                            {props.t(translateLang(clientData.language))}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("App display name")}:</h6>{" "}
                            {clientData.displayName
                              ? clientData.displayName
                              : props.t("Undefined")}
                          </div>
                        </Col>
                        {clientData?.videoCallRoomName && (
                          <Col>
                            <div className="text-muted mb-4">
                              <h6>{props.t("Video call display name")}:</h6>{" "}
                              {clientData?.video?.displayName
                                ? clientData.video.displayName
                                : props.t("Undefined")}
                            </div>
                          </Col>
                        )}
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>
                              {props.t("Additional notes") +
                                ", " +
                                props.t("visible in app")}
                              :
                            </h6>{" "}
                            {clientData.appOtherInfo
                              ? clientData.appOtherInfo
                              : props.t("Undefined")}
                          </div>
                        </Col>
                        {(isAdmin || userRole === "organisation_admin") && (
                          <Col>
                            <div className="text-muted mb-4">
                              <h6>{props.t("Admin notes")}:</h6>{" "}
                              {clientData.information
                                ? clientData.information
                                : props.t("Undefined")}
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col xs={12} lg={4}>
                      {clientData?.coordinates?.latitude != null &&
                      clientData?.coordinates?.latitude != 0 &&
                      clientData?.coordinates?.longitude != null &&
                      clientData?.coordinates?.longitude != 0 ? (
                        <div
                          className="border shadow"
                          style={{ height: "350px" }}
                        >
                          <DefaultMap
                            zoom={16}
                            lat={clientData.coordinates.latitude}
                            lng={clientData.coordinates.longitude}
                            //iconUrl={"./../../assets/images/icons/ic_marker_home_156.png"}
                            //iconScale={56}
                            infoHeader={props.t("Address")}
                            infoText={clientData.address}
                          />
                        </div>
                      ) : (
                        <div className="border shadow text-center bg-primary bg-soft p-4">
                          <div className="avatar-md mx-auto">
                            <div className="avatar-title bg-white rounded-circle text-primary h1">
                              <i className="mdi mdi-map-search"></i>
                            </div>
                          </div>
                          <h6 className="text-primary mt-3">
                            {props.t("No location info found")}
                          </h6>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <hr />
                  <CardTitle
                    className="d-flex"
                    style={{ alignItems: "center" }}
                  >
                    <i className="bx bxs-group text-truncate align-middle me-2"></i>
                    {props.t("Contacts")}
                  </CardTitle>
                  {contacts.length > 0 ? (
                    <ul className="list-group list-group-flush">
                      {contacts.map((c, index) => {
                        return (
                          <li key={"" + index} className="list-group-item py-3">
                            <Row>
                              <Col className="col-auto">
                                <span>{index + 1 + ". " + c.name}</span>
                              </Col>
                              <Col>
                                <a
                                  href={"tel:" + c.gsm}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {c.gsm}
                                </a>
                              </Col>
                            </Row>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    <p className="mt-3 mb-4 text-muted">
                      {props.t("Undefined")}
                    </p>
                  )}

                  <hr />
                  <CardTitle
                    className="mb-4 d-flex"
                    style={{ alignItems: "center" }}
                  >
                    <i className="bx bxs-box text-truncate align-middle me-2"></i>
                    {props.t("Other")}
                  </CardTitle>
                  <Row xs={1} sm={2} className="mt-3">
                    <Col>
                      <div className="text-muted mb-4">
                        <h6>{props.t("Alarms retention period")}:</h6>{" "}
                        {clientData.alarmRetentionPeriod
                          ? clientData.alarmRetentionPeriod +
                            " " +
                            props.t("days")
                          : props.t("Forever")}
                      </div>
                    </Col>
                    <Col>
                      <div className="text-muted mb-4">
                        <h6>{props.t("Health data retention period")}:</h6>{" "}
                        {clientData.healthRetentionPeriod
                          ? clientData.healthRetentionPeriod +
                            " " +
                            props.t("days")
                          : props.t("Forever")}
                      </div>
                    </Col>
                    <Col>
                      <div className="text-muted mb-4">
                        <h6>{props.t("Video logs retention period")}:</h6>{" "}
                        {clientData.videoRetentionPeriod
                          ? clientData.videoRetentionPeriod +
                            " " +
                            props.t("days")
                          : props.t("Forever")}
                      </div>
                    </Col>

                    {clientData?.trackerKey && (
                      <Col>
                        <div className="text-muted mb-4">
                          <h6>{props.t("Tracker data retention period")}:</h6>{" "}
                          {clientData?.tracker?.dataRetention
                            ? clientData.tracker.dataRetention +
                              " " +
                              props.t("days")
                            : props.t("Forever")}
                        </div>
                      </Col>
                    )}

                    {clientData?.gatewayKey && (
                      <>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>
                              {props.t("Home activity data retention period")}:
                            </h6>{" "}
                            {clientData?.home?.dataRetention
                              ? clientData.home.dataRetention +
                                " " +
                                props.t("days")
                              : props.t("Forever")}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Home activity learning period")}:</h6>{" "}
                            {clientData?.home?.learningPeriod
                              ? clientData.home.learningPeriod +
                                " " +
                                props.t("days")
                              : props.t("Undefined")}
                          </div>
                        </Col>

                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Rest period starting at")}:</h6>{" "}
                            {clientData?.home?.restStartHourFormatted
                              ? clientData.home.restStartHourFormatted
                              : props.t("Undefined")}
                          </div>
                        </Col>
                      </>
                    )}

                    <Col>
                      <div className="text-muted mb-4">
                        <h6>{props.t("Away from home")}:</h6>{" "}
                        {clientData.away ? props.t("Yes") : props.t("No")}
                      </div>
                    </Col>
                  </Row>

                  {/**isAdmin && (
                  <>
                    <hr className="mb-4" />
                    <Row>
                      <Col>
                        <div className="text-muted mb-4">
                          <h6>{props.t("GUID")}:</h6> {clientData?.key}
                        </div>
                      </Col>
                    </Row>
                  </>
                )*/}
                </>
              )}

              {editMode && (
                <>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      return false
                    }}
                  >
                    <Row xs={1} sm={2} className="mt-3">
                      <Col>
                        <FormGroup>
                          <Label>{props.t("Name")}</Label>
                          <Input
                            name="name"
                            type="text"
                            value={validation.values.name}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(
                                validation.touched.name &&
                                validation.errors.name
                              )
                            }
                          ></Input>
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {userRole != "private_user" &&
                        userRole != "organisation_user" && (
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Group")}</Label>
                              <SelectGroup
                                onChange={groupChanged}
                                multiple={false}
                                clearable={false}
                                selection={validation.values.group}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      <Col>
                        <FormGroup>
                          <Label>{props.t("GSM")}</Label>
                          <div style={{ position: "relative" }}>
                            <span
                              style={{
                                position: "absolute",
                                top: "52%",
                                left: "10px",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                              }}
                            >
                              +
                            </span>
                            <Input
                              name="gsmNumber"
                              type="text"
                              value={validation.values.gsmNumber}
                              onChange={validation.handleChange}
                              style={{
                                paddingLeft: "18px",
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{props.t("SSN")}</Label>
                          <Input
                            name="ssnNumber"
                            type="text"
                            value={validation.values.ssnNumber}
                            onChange={validation.handleChange}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{props.t("Address")}</Label>
                          <Input
                            name="address"
                            type="text"
                            value={validation.values.address}
                            onChange={validation.handleChange}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Latitude")}</Label>
                              <Input
                                name="latitude"
                                placeholder="E.g. 66.503563"
                                type="text"
                                value={validation.values.latitude}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Longitude")}</Label>
                              <Input
                                name="longitude"
                                placeholder="E.g. 25.725005"
                                type="text"
                                value={validation.values.longitude}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-auto">
                            <Button
                              size="sm"
                              color="primary"
                              disabled={
                                geolocating ||
                                updating ||
                                !validation.values.address
                              }
                              onClick={() => {
                                getCoordinates()
                              }}
                            >
                              {props.t("Get coordinates")}
                            </Button>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              size="sm"
                              color="primary"
                              disabled={geolocating || updating}
                              onClick={() => {
                                setMapModal(true)
                              }}
                            >
                              {props.t("Select from map")}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{props.t("Timezone")}</Label>
                          <Input
                            name="timezoneOffset"
                            type="select"
                            value={validation.values.timezoneOffset}
                            onChange={validation.handleChange}
                          >
                            <option value="-12">{props.t("UTC-12")}</option>
                            <option value="-11">{props.t("UTC-11")}</option>
                            <option value="-10">{props.t("UTC-10")}</option>
                            <option value="-9">{props.t("UTC-9")}</option>
                            <option value="-8">{props.t("UTC-8")}</option>
                            <option value="-7">{props.t("UTC-7")}</option>
                            <option value="-6">{props.t("UTC-6")}</option>
                            <option value="-5">{props.t("UTC-5")}</option>
                            <option value="-4">{props.t("UTC-4")}</option>
                            <option value="-3">{props.t("UTC-3")}</option>
                            <option value="-2">{props.t("UTC-2")}</option>
                            <option value="-1">{props.t("UTC-1")}</option>
                            <option value="0">{props.t("UTC")}</option>
                            <option value="1">{props.t("UTC+1")}</option>
                            <option value="2">{props.t("UTC+2")}</option>
                            <option value="3">{props.t("UTC+3")}</option>
                            <option value="4">{props.t("UTC+4")}</option>
                            <option value="5">{props.t("UTC+5")}</option>
                            <option value="6">{props.t("UTC+6")}</option>
                            <option value="7">{props.t("UTC+7")}</option>
                            <option value="8">{props.t("UTC+8")}</option>
                            <option value="9">{props.t("UTC+9")}</option>
                            <option value="10">{props.t("UTC+10")}</option>
                            <option value="11">{props.t("UTC+11")}</option>
                            <option value="12">{props.t("UTC+12")}</option>
                            <option value="13">{props.t("UTC+13")}</option>
                            <option value="14">{props.t("UTC+14")}</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Switch
                            className="me-2"
                            name="timezoneCheckEnabled"
                            checked={validation.values.timezoneCheckEnabled}
                            onChange={v =>
                              validation.setFieldValue(
                                "timezoneCheckEnabled",
                                v
                              )
                            }
                          />
                          <Label>{props.t("Automatic")}</Label>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{props.t("Language")}</Label>
                          <Input
                            name="language"
                            type="select"
                            value={validation.values.language}
                            onChange={validation.handleChange}
                          >
                            <option value="fi">{props.t("Finnish")}</option>
                            <option value="en">{props.t("English")}</option>
                            <option value="sv">{props.t("Swedish")}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      {/** 
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Contacts")}</Label>
                        <Input
                          name="contacts"
                          type="text"
                          placeholder="Esim. Maija +3581234567, Matti +3587654321"
                          value={validation.values.contacts}
                          onChange={validation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>
                    */}
                      <Col>
                        <FormGroup>
                          <Label>{props.t("App display name")}</Label>
                          <Input
                            name="displayName"
                            type="text"
                            value={validation.values.displayName}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(
                                validation.touched.displayName &&
                                validation.errors.displayName
                              )
                            }
                          ></Input>
                          {validation.touched.displayName &&
                          validation.errors.displayName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.displayName}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      {clientData?.videoCallRoomName && (
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Video call display name")}</Label>
                            <Input
                              name="videoDisplayName"
                              type="text"
                              value={validation.values.videoDisplayName}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                !!(
                                  validation.touched.videoDisplayName &&
                                  validation.errors.videoDisplayName
                                )
                              }
                            ></Input>
                            {validation.touched.videoDisplayName &&
                            validation.errors.videoDisplayName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.videoDisplayName}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      )}

                      <Col>
                        <FormGroup>
                          <Label>{props.t("Additional info")}</Label>
                          <Input
                            name="appOtherInfo"
                            type="textarea"
                            rows={1}
                            value={validation.values.appOtherInfo}
                            onChange={validation.handleChange}
                          ></Input>
                        </FormGroup>
                      </Col>
                      {(isAdmin || userRole === "organisation_admin") && (
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Admin notes")}</Label>
                            <Input
                              name="information"
                              type="textarea"
                              rows={1}
                              value={validation.values.information}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>

                    <hr />
                    <Row>
                      <Col className="col-auto">
                        <FormGroup>
                          <Label>{props.t("Name of the contact")}</Label>
                          <Input
                            name="name"
                            type="text"
                            placeholder="Maija Meikäläinen"
                            style={{ minWidth: "200px" }}
                            value={contactValidation.values.name}
                            onChange={contactValidation.handleChange}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col className="col-auto">
                        <FormGroup>
                          <Label>{props.t("Contact person's GSM")}</Label>
                          <Input
                            name="gsm"
                            type="text"
                            placeholder="+358401234567"
                            style={{ minWidth: "200px" }}
                            value={contactValidation.values.gsm}
                            onChange={contactValidation.handleChange}
                            invalid={
                              !!(
                                contactValidation.touched.gsm &&
                                contactValidation.errors.gsm
                              )
                            }
                          ></Input>
                          {contactValidation.touched.gsm &&
                          contactValidation.errors.gsm ? (
                            <FormFeedback type="invalid">
                              {contactValidation.errors.gsm}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <Button
                          style={{ marginTop: "28px" }}
                          color="success"
                          disabled={
                            !contactValidation.values.name ||
                            !contactValidation.values.gsm
                          }
                          onClick={() => {
                            contactValidation.submitForm()
                          }}
                        >
                          {props.t("Add")}
                        </Button>
                      </Col>
                    </Row>

                    {contactsEdit.length > 0 ? (
                      <ul className="list-group list-group-flush">
                        {contactsEdit.map((c, index) => {
                          return (
                            <li
                              key={"" + index}
                              className="list-group-item py-3"
                            >
                              <Row>
                                <Col className="col-auto">
                                  <span>{index + 1 + ". " + c.name}</span>
                                </Col>
                                <Col>
                                  <a
                                    href={"tel:" + c.gsm}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {c.gsm}
                                  </a>
                                </Col>
                                <Col>
                                  <Button
                                    className="float-end"
                                    size="sm"
                                    outline
                                    color="danger"
                                    onClick={() => {
                                      setContactsEdit(prevState =>
                                        prevState.filter(
                                          prevItem =>
                                            prevItem.name !== c.name ||
                                            prevItem.gsm !== c.gsm
                                        )
                                      )
                                    }}
                                  >
                                    {props.t("Delete")}
                                  </Button>
                                </Col>
                              </Row>
                            </li>
                          )
                        })}
                      </ul>
                    ) : (
                      <></>
                    )}

                    <hr />
                    {!clientData?.dataRetentionReadonly && (
                      <Alert color="info">
                        {props.t("DataRetentionWarning")}
                      </Alert>
                    )}
                    <Row xs={1} sm={2} className="mt-3">
                      {!clientData?.dataRetentionReadonly && (
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Alarms retention period")}</Label>
                            <Input
                              name="storeAlarmsPeriod"
                              type="number"
                              value={validation.values.storeAlarmsPeriod}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                      )}
                      {!clientData?.dataRetentionReadonly && (
                        <Col>
                          <FormGroup>
                            <Label>
                              {props.t("Health data retention period")}
                            </Label>
                            <Input
                              name="storeHealthDataPeriod"
                              type="number"
                              value={validation.values.storeHealthDataPeriod}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                      )}
                      {!clientData?.dataRetentionReadonly && (
                        <Col>
                          <FormGroup>
                            <Label>
                              {props.t("Video logs retention period")}
                            </Label>
                            <Input
                              name="storeVideoDataPeriod"
                              type="number"
                              value={validation.values.storeVideoDataPeriod}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                      )}

                      {clientData?.trackerKey && (
                        <>
                          {!clientData?.dataRetentionReadonly && (
                            <Col>
                              <FormGroup>
                                <Label>
                                  {props.t("Tracker data retention period")}
                                </Label>
                                <Input
                                  name="storeTrackerDataPeriod"
                                  type="number"
                                  value={
                                    validation.values.storeTrackerDataPeriod
                                  }
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                          )}
                        </>
                      )}

                      {clientData?.gatewayKey && (
                        <>
                          {!clientData?.dataRetentionReadonly && (
                            <Col>
                              <FormGroup>
                                <Label>
                                  {props.t(
                                    "Home activity data retention period"
                                  )}
                                </Label>
                                <Input
                                  name="storeHomeDataPeriod"
                                  type="number"
                                  value={validation.values.storeHomeDataPeriod}
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                          )}
                          {!clientData?.dataRetentionReadonly && (
                            <Col>
                              <FormGroup>
                                <Label>
                                  {props.t("Home activity learning period")}
                                </Label>
                                <Input
                                  name="learningPeriod"
                                  type="number"
                                  value={validation.values.learningPeriod}
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                          )}
                          {!clientData?.dataRetentionReadonly && (
                            <Col>
                              <FormGroup>
                                <Label>
                                  {props.t("Rest period starting at")}
                                </Label>
                                <Input
                                  name="restStartHour"
                                  type="select"
                                  value={validation.values.restStartHour}
                                  onChange={validation.handleChange}
                                >
                                  <option value={0}>{"00:00"}</option>
                                  <option value={1}>{"01:00"}</option>
                                  <option value={2}>{"02:00"}</option>
                                  <option value={3}>{"03:00"}</option>
                                  <option value={4}>{"04:00"}</option>
                                  <option value={5}>{"05:00"}</option>
                                  <option value={6}>{"06:00"}</option>
                                  <option value={7}>{"07:00"}</option>
                                  <option value={8}>{"08:00"}</option>
                                  <option value={9}>{"09:00"}</option>
                                  <option value={10}>{"10:00"}</option>
                                  <option value={11}>{"11:00"}</option>
                                  <option value={12}>{"12:00"}</option>
                                  <option value={13}>{"13:00"}</option>
                                  <option value={14}>{"14:00"}</option>
                                  <option value={15}>{"15:00"}</option>
                                  <option value={16}>{"16:00"}</option>
                                  <option value={17}>{"17:00"}</option>
                                  <option value={18}>{"18:00"}</option>
                                  <option value={19}>{"19:00"}</option>
                                  <option value={20}>{"20:00"}</option>
                                  <option value={21}>{"21:00"}</option>
                                  <option value={22}>{"22:00"}</option>
                                  <option value={23}>{"23:00"}</option>
                                  <option value={24}>{"24:00"}</option>
                                </Input>
                              </FormGroup>
                            </Col>
                          )}
                        </>
                      )}

                      <Col>
                        <Label>{props.t("Away from home")}</Label>
                        <FormGroup className="mt-2">
                          <Switch
                            className=""
                            name="away"
                            checked={validation.values.away}
                            onChange={v => validation.setFieldValue("away", v)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </CardBody>
          </Card>
        </>
      )}

      <Modal
        isOpen={mapModal}
        toggle={() => {
          setMapModal(!mapModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Location")}</ModalHeader>
        <ModalBody>
          <Alert color="info">
            {props.t(
              "Change the location by dragging the marker or clicking a spot on the map"
            )}
          </Alert>
          <div style={{ height: "360px" }}>
            <DefaultMap
              zoom={10}
              lat={validation.values.latitude}
              lng={validation.values.longitude}
              markerEdit={true}
              markerChanged={mapLocationChanged}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setMapModal(false)
            }}
          >
            {props.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

ClientInfo.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.any,
  setClientName: PropTypes.any,
}

export default withTranslation()(ClientInfo)
