import React, { useState } from "react"
import { Card, CardBody, Container, Row, Col, Button, Label } from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import FileUploadModal from "components/Modals/FileUpload"
import { showToast } from "utils/general"
// import { showToast, handleFileChange, handleUpload } from "utils/general"

const Instructions = props => {
  const [instructionsList] = useState([
    { id: 1, title: ` TEST1` },
    { id: 2, title: ` TEST2` },
    { id: 3, title: ` TEST3` },
    { id: 4, title: ` TEST4` },
  ])

  // File upload
  const [modalFile, setModalFile] = useState(false)
  const [selectedInstructionId, setSelectedInstructionId] = useState(null)
  const [selectedFile, setSelectedFile] = useState([])
  const [selectedDataBase64, setSelectedDataBase64] = useState("")
  const [action, setAction] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState({})

  // Function to toggle the modal
  const toggleFileModal = (instructionId = null) => {
    setSelectedInstructionId(instructionId)
    setModalFile(!modalFile)
  }

  // File upload
  const uploadAttachment = file => {
    if (!action) {
      setAction(true)

      const { name } = file

      // Test upload
      console.log("Simulating upload:")
      console.log("Base64 Data: ", selectedDataBase64)
      console.log("File Name: ", name)

      // Test update file name witohut uploading file
      setUploadedFiles(prev => ({
        ...prev,
        [selectedInstructionId]: name,
      }))

      showToast(props.t("File uploaded successfully"), "success")
      setModalFile(false)
      setSelectedDataBase64("")
      setSelectedFile([])
      setAction(false)
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Instructions")} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pt-1">
                  {instructionsList.map(instruction => (
                    <div key={instruction.id} style={{ marginTop: "30px" }}>
                      <Label className="mb-3">
                        {" "}
                        {props.t("User interface")} {props.t("Instructions")}
                        {instruction.title}
                      </Label>
                      {/* TEST Display the uploaded file name if available, otherwise show default TEST*/}
                      <p>
                        {uploadedFiles[instruction.id]
                          ? uploadedFiles[instruction.id]
                          : "Currently uploaded file.pdf"}
                      </p>
                      <Button
                        color="primary"
                        onClick={() => {
                          toggleFileModal(instruction.id)
                        }}
                      >
                        {props.t("Change")}
                      </Button>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* File Upload Modal */}
      <FileUploadModal
        isOpen={modalFile}
        toggle={toggleFileModal}
        onUpload={uploadAttachment}
        t={props.t}
        fileTypeCategory="instructions"
        maxFiles={1}
        maxSize={10485760} // 10MB
        multiple={false}
        loadingFile={false}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        setSelectedDataBase64={setSelectedDataBase64}
      />
    </>
  )
}

Instructions.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Instructions)
