import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalFooter, Button, Form, Row, Card, Col } from "reactstrap"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { showToast } from "utils/general"

const FileUploadModal = props => {
  const {
    isOpen,
    toggle,
    onUpload,
    fileTypeCategory,
    maxFiles,
    maxSize,
    multiple,
    selectedFile,
    setSelectedFile,
    setSelectedDataBase64,
  } = props

  const [uploadMessage, setUploadMessage] = useState("")
  const [loadingFile, setLoadingFile] = useState(false)

  useEffect(() => {
    if (selectedFile.length == 0) {
      setUploadMessage("")
    }
  }, [selectedFile])

  // Clear the modal if its closed
  useEffect(() => {
    if (!isOpen) {
      setSelectedFile([])
      setSelectedDataBase64("")
      setUploadMessage("")
      setLoadingFile(false)
    }
  }, [isOpen])

  // Accepted file types
  const getAcceptedFileTypes = () => {
    switch (fileTypeCategory) {
      case "journal":
        return {
          "image/png": [".png"],
          "image/bmp": [".bmp"],
          "image/jpeg": [".jpeg", ".jpg"],
          "image/gif": [".gif"],
          "text/plain": [".txt"],
          "application/rtf": [".rtf"],
          "application/pdf": [".pdf"],
          "application/msword": [".doc"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
          "application/vnd.oasis.opendocument.text": [".odt"],
          "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
          "video/mpeg": [".mpeg"],
          "video/mp4": [".mp4"],
          "video/x-msvideo": [".avi"],
        }
      case "instructions":
        return {
          "application/pdf": [".pdf"],
        }
      default:
        return {}
    }
  }

  const acceptedFileTypes = getAcceptedFileTypes()

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    console.log(
      "size ",
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    )
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // Dropzone
  function handleAcceptedFiles(files) {
    setUploadMessage("")
    setLoadingFile(true)
    console.log(files, "files")

    files.forEach(file => {
      const reader = new FileReader()
      let maxSizeInBytes = 0
      // console.log(file.type, "filetype")
      if (file.type.includes("video")) {
        maxSizeInBytes = 20 * 1024 * 1024 // 20 Mt
      } else {
        maxSizeInBytes = 5 * 1024 * 1024 // 5 Mt
      }
      // console.log(maxSizeInBytes)
      if (file.size > maxSizeInBytes) {
        setUploadMessage(props.t("File is too large"))
      }
      reader.onabort = () => console.log("file reading was aborted")
      reader.onerror = () => console.log("file reading has failed")
      reader.onload = () => {
        let dataUrl = reader.result
        //console.log(dataUrl)
        let index = dataUrl.indexOf("base64,")
        if (index >= 0) {
          dataUrl = dataUrl.substring(index + 7)
        }
        setSelectedDataBase64(dataUrl)
      }
      reader.readAsDataURL(file)
    })

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setSelectedFile(files)
    setLoadingFile(false)
  }

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0 mb-0">
          {props.t("Add an attachment")}
        </h5>

        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Row className="mt-2">
        <div className="px-5 pt-2">
          <Form>
            <Dropzone
              accept={acceptedFileTypes}
              maxFiles={maxFiles}
              multiple={multiple}
              maxSize={maxSize}
              onDropAccepted={handleAcceptedFiles}
              onDropRejected={fileRejections => {
                let errMessage = fileRejections[0].errors[0].code
                if (errMessage === "file-too-large") {
                  errMessage = "File is too large"
                }
                showToast(props.t(errMessage), "error")
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <>
                  {selectedFile.length === 0 && (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message needsclick">
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h5>
                            {props.t("Drop file here or click to upload")}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Dropzone>

            {loadingFile && (
              <div className="p-4">
                {/* You can replace this with your actual loading indicator */}
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">{props.t("Loading...")}</span>
                </div>
              </div>
            )}
            <div className="dropzone-previews py-1" id="file-previews">
              {selectedFile.length > 0 && (
                <span className="float-end p-2">
                  <Button
                    className="p-2 mt-2"
                    color="transparent"
                    onClick={() => {
                      setSelectedFile([])
                    }}
                  >
                    <i className="bx bx-trash font-size-16 text-danger" />
                  </Button>
                </span>
              )}
              {uploadMessage !== "" && (
                <span className="text-danger h6">{props.t(uploadMessage)}</span>
              )}
              {selectedFile?.map((f, i) => {
                return (
                  <Card
                    outline
                    color={uploadMessage === "" ? "" : "danger"}
                    className={
                      "py-1 mb-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    }
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          {/* Icons based on file type */}
                          {f.type.includes("video") && (
                            <i className="mdi mdi-file-video display-7 text-muted ps-2"></i>
                          )}
                          {f.type.includes("application/vnd.ms-excel") ||
                          f.type.includes(
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          ) ? (
                            <i className="mdi mdi-file-excel display-7 text-muted ps-2"></i>
                          ) : null}
                          {f.type.includes("audio") && (
                            <i className="mdi mdi-file-music display-7 text-muted ps-2"></i>
                          )}
                          {f.type.includes("application/msword") ||
                          f.type.includes(
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          ) ? (
                            <i className="bx bxs-file-doc display-7 text-muted ps-2"></i>
                          ) : null}
                          {f.type.includes("image") && (
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={f.preview}
                            />
                          )}
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )
              })}
            </div>
            {selectedFile.length === 0 && (
              <p className="text-muted mt-0 pt-0">
                {props.t(
                  "The maximum file size is 20Mt for video files and 5Mt for other file types"
                )}
                .
              </p>
            )}
            {selectedFile.length > 0 && uploadMessage !== "" && (
              <p className="text-danger mt-0 pt-0">
                {props.t(
                  "The maximum file size is 20Mt for video files and 5Mt for other file types"
                )}
              </p>
            )}
          </Form>
        </div>
      </Row>
      <ModalFooter className="mt-2">
        <span className="float-end">
          <button
            type="button"
            onClick={toggle}
            className="btn btn-outline-primary"
          >
            {props.t("Cancel")}
          </button>

          <button
            disabled={selectedFile.length === 0 || uploadMessage !== ""}
            className="btn btn-primary ms-2"
            onClick={() => {
              onUpload(selectedFile[0])
            }}
          >
            {props.t("Save")}
          </button>
        </span>
      </ModalFooter>
    </Modal>
  )
}

FileUploadModal.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.any,
  toggle: PropTypes.any,
  onUpload: PropTypes.any,
  fileTypeCategory: PropTypes.any,
  maxFiles: PropTypes.any,
  maxSize: PropTypes.any,
  multiple: PropTypes.any,
  selectedFile: PropTypes.any,
  setSelectedFile: PropTypes.any,
  setSelectedDataBase64: PropTypes.any,
}

export default FileUploadModal
