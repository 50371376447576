import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Chip from "@material-ui/core/Chip"
import { withTranslation } from "react-i18next"
import moment from "moment"
import withRouter from "components/Common/withRouter"
import iconVideocall from "assets/images/icons/videocall.svg"
import iconApp from "assets/images/icons/app.svg"
import iconMedicine from "assets/images/icons/medicineReminder.svg"
import iconGroupvideo from "assets/images/icons/groupvideo.png"
import iconWebpageAlt from "assets/images/icons/webIconAlt.svg"
import { Button, Card, UncontrolledTooltip } from "reactstrap"
import { Popconfirm } from "antd"
import { Link } from "react-router-dom"

const ReleaseNotesItem = props => {
  const [example, setExample] = useState()
  const [item, setItem] = useState()

  useEffect(() => {
    if (props.item) {
      setItem(props.item)
      handleExample(props.item)
    }
  }, [props.item])

  // Get matching target name
  const getTargetName = id => {
    switch (id) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "ReleaseNoteTypes." + id
      default:
        return "Unknown"
    }
  }

  // Get matching image
  const getImage = id => {
    switch (id) {
      case 1:
        return iconApp
      case 2:
        return iconMedicine
      case 3:
        return iconVideocall
      case 4:
        return iconWebpageAlt
      case 5:
        return iconGroupvideo
      default:
        return null
    }
  }

  const handleExample = note => {
    let niceDate = moment(note.informed_date).format("DD.MM.YYYY")
    let temp = new Object()
    temp.id = note.id
    temp.date = niceDate
    temp.title = note.target + " v." + note.version

    if (note.release_date) {
      temp.releaseDate = moment(note.release_date).format("DD.MM.YYYY")
    }

    temp.content1_fi = note.what_changes_fi
      ? note.what_changes_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_fi = note.how_use_changes_fi
      ? note.how_use_changes_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_fi = note.how_effects_fi
      ? note.how_effects_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_en = note.what_changes_en
      ? note.what_changes_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_en = note.how_use_changes_en
      ? note.how_use_changes_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_en = note.how_effects_en
      ? note.how_effects_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_sv = note.what_changes_sv
      ? note.what_changes_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_sv = note.how_use_changes_sv
      ? note.how_use_changes_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_sv = note.how_effects_sv
      ? note.how_effects_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""

    temp.changesProUser = note.changes_pro_ui == 1 ? "Yes" : "No"
    temp.changesEndUser = note.changes_enduser_ui == 1 ? "Yes" : "No"
    temp.changesProcedure = note.changes_user_methods == 1 ? "Yes" : "No"
    temp.type = note.type == 1 ? "Hotfix" : "Update"
    temp.version = note.version

    temp.target = getTargetName(parseInt(note.target, 10))
    temp.img = getImage(parseInt(note.target, 10))
    temp.status = parseInt(note.status, 10)

    switch (temp.status) {
      case 1:
        temp.status = "Upcoming"
        break
      case 2:
        temp.status = "Waiting for release"
        break
      case 3:
        temp.status = "Postponed"
        break
      case 4:
        temp.status = "Published"
        break
      default:
        temp.status = "Unknown"
        break
    }
    setExample(temp)
  }

  const sendValues = () => {
    props.settingPreview(item.guid)
    props.settingContentLink(item.id)
  }

  const sendValuesEmail = () => {
    props.settingSelectedGuid(props.item.guid)
    props.settingContentLink(props.item.id)
  }
  return (
    <>
      {example && (
        <Card className="shadow-none border p-3 mb-2">
          <div className="d-flex-email">
            <div className="me-3 d-none d-md-block ">
              <img
                src={example.img}
                alt=""
                className="avatar-xs rounded pb-1 pt-1 "
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className="align-self-center overflow-hidden me-auto ms-2">
              <div className="d-flex-email">
                <div className="h5 font-size-16 text-truncate mb-1 me-2 ">
                  <Link
                    to="#"
                    onClick={() => sendValues()}
                  >
                    <span className="h6 font-size-14">
                      {props.t(example.target) + " v." + example.version}
                    </span>
                  </Link>
                </div>
                <div className="text-muted me-3">{example.date}</div>
                <div>
                  <Chip
                    size="small"
                    key="chip"
                    className="font-size-11 me-2"
                    label={props.t(example.type)}
                  />
                  <Chip
                    size="small"
                    key="chip2"
                    className="font-size-11 me-2"
                    label={props.t(example.status)}
                  />
                </div>
              </div>
            </div>
            {/* TODO: subs ? */}
            {/* <div className="pt-1">
              <small className="text-muted">{props.t("Subscribers")}</small>
            </div> */}
            <div className="float-end mt-1 d-md-block ">
              <Button
                size="sm"
                id={"send-btn" + props.item.id}
                color="transparent"
                className="mx-1"
                onClick={() => {
                  sendValuesEmail()
                }}
              >
                <i className="bx bx-mail-send text-primary font-size-14"></i>
              </Button>
              <UncontrolledTooltip
                placement="right"
                target={"send-btn" + props.item.id}
              >
                {props.t("Send")}
              </UncontrolledTooltip>
              <Button
                size="sm"
                id={"edit-btn" + props.item.id}
                color="transparent"
                className="mx-1"
                onClick={() => props.settingSelectedId(props.item.id)}
              >
                <i className="bx bx-edit-alt text-primary font-size-14"></i>
              </Button>
              <UncontrolledTooltip
                placement="right"
                target={"edit-btn" + props.item.id}
              >
                {props.t("Edit")}
              </UncontrolledTooltip>
              <Popconfirm
                title={props.t("Confirm action")}
                open={props.deleteConfirm}
                okType="danger"
                okText={props.t("Delete")}
                cancelText={props.t("Cancel")}
                onConfirm={() => {
                  props.deleteRelease(props.item.id)
                  props.setDeleteConfirm(false)
                }}
                onCancel={() => {
                  props.setDeleteConfirm(false)
                }}
              >
                <Button
                  size="sm"
                  id={"delete-btn" + props.item.id}
                  color="transparent"
                  className="mx-1"
                  onClick={() => {
                    props.setDeleteConfirm(true)
                  }}
                >
                  <i className="bx bx-trash text-danger font-size-14"></i>
                </Button>
              </Popconfirm>
              <UncontrolledTooltip
                placement="right"
                target={"delete-btn" + props.item.id}
              >
                {props.t("Delete")}
              </UncontrolledTooltip>
            </div>
          </div>
        </Card>
      )}
    </>
  )
}

ReleaseNotesItem.propTypes = {
  t: PropTypes.any,
  item: PropTypes.any,
  onOpenModal: PropTypes.any,
  settingPreview: PropTypes.any,
  settingSelectedId: PropTypes.any,
  settingSelectedGuid: PropTypes.any,
  deleteConfirm: PropTypes.any,
  setDeleteConfirm: PropTypes.any,
  deleteRelease: PropTypes.any,
  settingContentLink: PropTypes.any,
}

export default withRouter(withTranslation()(ReleaseNotesItem))
