import React, { useEffect, useState } from "react"
import Chart from "react-apexcharts"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

const BatteryChart = props => {
  const [value, setValue] = useState(0)
  const [status, setStatus] = useState()

  // Counting percentage of battery level
  const settingToRadial = v => {
    let max = 3
    let min = 2.6

    if (v > max) {
      max = v
    }
    let diff = max - min
    let value = ((v - min) / diff) * 100

    if (value <= 0) {
      value = 0
    }
    // console.log(value)
    return [parseInt(value)]
  }

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value)
    }
  }, [props.value])

  useEffect(() => {
    if (props.status == "good") {
      setStatus("#34c38f")
    }
    if (props.status == "moderate") {
      setStatus("#ffd000")
    }
    if (props.status == "weak") {
      setStatus("#f46a6a")
    }
  }, [props.status])

  const optionsRadial = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "70%",
          background: "transparent",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 1,
            opacity: 0.35,
          },
        },
        // style: {
        //   fontFamily: "Helvetica, Arial, sans-serif",
        // },

        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -10,
            show: true,
            color: status,
            fontSize: "10px",
            fontFamily: "'Poppins', sans-serif",
          },
          value: {
            offsetY: -7,
            formatter: function (val) {
              return val
            },
            color: status,
            fontSize: "20px",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "500",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "solid",
      colors: [status],
      // gradient: {
      //   shade: "dark",
      //   type: "horizontal",
      //   shadeIntensity: 0.5,
      //   gradientToColors: ["#d15d4f"],
      //   inverseColors: true,
      //   opacityFrom: 1,
      //   opacityTo: 1,
      //   stops: [0, 100],
      // },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["%"],
  }

  return (
    <>
      {status !== undefined ? (
        <div
          style={{
            width: 170,
            height: 110,
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
          }}
        >
          {props.showText ? (
            <div className="text-center text-muted mt-2">
              <h6 className="mb-0 ">{props.t("Battery status")}</h6>
            </div>
          ) : (
            <div className="mt-2"></div>
          )}
          <div
            style={{
              width: 100,
              height: 100,
            }}
          >
            {/* TODO: SET DYNAMIC COLOR */}
            <Chart
              options={optionsRadial}
              series={settingToRadial(value)}
              type="radialBar"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

BatteryChart.propTypes = {
  t: PropTypes.any,
  value: PropTypes.any,
  showText: PropTypes.any,
  status: PropTypes.any,
}

export default withTranslation()(BatteryChart)
