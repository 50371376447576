/***********************
 * INDEX TAB4 CONTENT (VISITS)
 ************************/

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  Tooltip,
} from "reactstrap"

// Datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import "../datatables.scss"

//
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

//Excel export
import XLSX from "xlsx"

//Autotable PDF
import jsPDF from "jspdf"
import "jspdf-autotable"

//Locale
import { withTranslation } from "react-i18next"
import { dateFormatter, showToast } from "utils/general"

const VisitsTabReporting = props => {
  const [visitsData, setVisitsData] = useState({})
  const [itemsLength, setItemsLength] = useState([0])

  // Set new state data when props.data changes
  useEffect(() => {
    if (props.activeTab == 5) {
      console.log("Visits data changed: " + JSON.stringify(props.data))
      setVisitsData(props.data)

      //Table + chart
      console.log("Data table visits: " + JSON.stringify(props.data.list))
      setSeries(props.data.list)
      setDurationA(props.data.stats.duration_average)
      setDurationM(props.data.stats.duration_median)
      if (props.data != visitsData) {
        setItemsLength(props.data.list.length)
      }
    }
  }, [props.data])

  //Table data state
  const [series, setSeries] = useState([])
  // Card states
  const [durationA, setDurationA] = useState(0)
  const [durationM, setDurationM] = useState(0)
  //Dropdown button state
  const [menu, setMenu] = useState(false)
  // Tooltip state for download button
  const [tTdownloadstatstable, setTTdownloadstatstable] = useState(false)

  //Excel and pdf name
  const title = props.t("Visits")
  // Converting time from seconds to readable
  function timeFormatter(value) {
    if (!value) {
      return ""
    }

    let hours = Math.floor(value / 3600) // Get hours
    let minutes = Math.floor((value - hours * 3600) / 60) // Get minutes
    let seconds = value - hours * 3600 - minutes * 60 // Get seconds

    // Pad with leading zeros
    hours = hours < 10 ? "0" + hours : hours
    minutes = minutes < 10 ? "0" + minutes : minutes
    seconds = seconds < 10 ? "0" + seconds : seconds

    return `${hours}:${minutes}:${seconds}`
  }

  // Table structure
  const columns = [
    {
      dataField: "date",
      text: props.t("Time"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "client_name",
      text: props.t("Customers name"),
      sort: true,
    },
    {
      dataField: "group",
      text: props.t("Group"),
      sort: true,
    },
    {
      dataField: "visitor",
      text: props.t("Visitor"),
      sort: true,
    },
    {
      dataField: "duration",
      text: props.t("Duration"),
      sort: true,
      formatter: timeFormatter,
    },
  ]

  // Function for formatting seconds -> hours, minutes, seconds
  const formatTime = value => {
    let hours = Math.floor(value / 3600)
    let minutes = Math.floor((value - hours * 3600) / 60)
    let seconds = value - hours * 3600 - minutes * 60

    if (hours != 0) {
      hours = hours + "h "
    }
    if (minutes != 0) {
      minutes = minutes + "min "
    }
    if (hours == 0) {
      hours = ""
    }
    if (minutes == 0) {
      minutes = ""
    }
    return hours + minutes + seconds + "s"
  }
  //Times
  const timeStats = [
    {
      id: 0,
      value: durationA,
      text: props.t("Average"),
      series: formatTime(durationA),
    },
    {
      id: 1,
      value: durationM,
      text: props.t("Median"),
      series: formatTime(durationM),
    },
  ]

  // Export PDF table
  const exportPDF = () => {
    let message = props.t("PDF file exported succesfully!")
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape
    const theme = "grid" //striped,  plain

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size, theme)

    doc.setFontSize(14)

    const data = series.map(elt => [
      dateFormatter(elt.date),
      elt.client_name,
      elt.group,
      elt.visitor,
      timeFormatter(elt.duration),
    ])

    let content = {
      startY: 50,
      head: [
        [
          props.t("Time"),
          props.t("Customers name"),
          props.t("Group"),
          props.t("Visitor"),
          props.t("Duration"),
        ],
      ],
      body: data,
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save(title + ".pdf")

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Time"),
        props.t("Customers name"),
        props.t("Group"),
        props.t("Visitor"),
        props.t("Duration"),
      ],
    ]
    const data = series.map(elt => [
      dateFormatter(elt.date),
      elt.client_name,
      elt.group,
      elt.visitor,
      timeFormatter(elt.duration),
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  const defaultSorted = [
    {
      dataField: "date",
      order: "desc",
    },
  ]

  const pageOptions = {
    custom: true,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: props.t("All"), value: series.length },
    ],
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
  ]
  const { SearchBar } = Search

  return (
    <>
      {/* Setting filter for view */}
      <Row className=" mt-2 mb-2">
        <div className="d-flex flex-wrap">
          <span>
            <h5 className="mt-1">{props.t("Visits")}</h5>
          </span>
        </div>
      </Row>
      <Row>
        <Col lg="3">
          <Card>
            <CardBody>
              <Col className="mt-2 mb-4 text-center" xs="12">
                <div className="mb-1 ">
                  <div className="mb-4">
                    <i className="mdi mdi-home-map-marker text-primary display-6" />
                  </div>
                  <div className="mt-2 mb-2">
                    <h3>{itemsLength}</h3>
                    {itemsLength > 1 ? (
                      <p className="">{props.t("made visits")}</p>
                    ) : (
                      <p className="">{props.t("made visit")}</p>
                    )}
                  </div>
                </div>
              </Col>{" "}
              <Col className="mt-3 mb-2" xs="12">
                {/* Duration of all calls */}
                <div className="mt-5 mb-3">
                  <span className="font-size-14 h5">
                    {props.t("Visit duration")}
                  </span>
                </div>

                {timeStats.map(time => (
                  <div key={time.id + "_div"}>
                    <div className="py-2">
                      <div>
                        {time.text}
                        <span className="font-size-14 h5 float-end">
                          {time.series}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
            </CardBody>
          </Card>
        </Col>{" "}
        <Col lg="9">
          <Card className="">
            <CardBody>
              <Row>
                <Col className="col-12">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    sizePerPage={SizePerPageDropdownStandalone(sizePerPageList)}
                    keyField="id"
                    columns={columns}
                    data={series}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={series}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Col lg="12">
                              <Row className="mb-0">
                                <Col xl="4" sm="6"></Col>
                                <Col lg="8" sm="6">
                                  <span className="float-end mt-1">
                                    <Tooltip
                                      placement="bottom"
                                      isOpen={tTdownloadstatstable}
                                      target="downloadstatstable"
                                      toggle={() => {
                                        setTTdownloadstatstable(
                                          !tTdownloadstatstable
                                        )
                                      }}
                                    >
                                      {props.t("Download statistics")}
                                    </Tooltip>
                                    <Dropdown
                                      id="printButtonServicesStats"
                                      isOpen={menu}
                                      toggle={() => setMenu(!menu)}
                                      className="download-btn text-center"
                                      type="button"
                                    >
                                      <DropdownToggle
                                        id="downloadstatstable"
                                        className="text-muted"
                                        tag="i"
                                      >
                                        <i className="mdi mdi-download " />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() => downloadExcel()}
                                        >
                                          {props.t("Download .xlsx")}
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => exportPDF()}
                                        >
                                          {props.t("Download .pdf")}
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <Form className="mt-sm-0 float-sm-end d-flex align-items-center">
                                    <div className="search-box me-4">
                                      <div className="position-relative">
                                        <SearchBar
                                          placeholder={props.t(" ")}
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search search-icon" />
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                              </Row>
                            </Col>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive table-scroll-window">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    pagination={sizePerPageList}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-3 mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="">
                                  <SizePerPageDropdownStandalone
                                    pagination={sizePerPageList}
                                    {...paginationProps}
                                  />
                                </div>
                                <Col className="pagination justify-content-end inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

VisitsTabReporting.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  success: PropTypes.any,
  t: PropTypes.any,
  data: PropTypes.any,
  activeTab: PropTypes.any,
  setStartDate: PropTypes.any,
  setEndDate: PropTypes.any,
}

export default withTranslation()(VisitsTabReporting)
