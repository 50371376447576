import React, { useEffect, useState } from "react"
import { Col, Table } from "reactstrap"
import PropTypes from "prop-types"
// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"

//Translation
import { withTranslation } from "react-i18next"
import { formatDateHoursMinutes } from "utils/general"

//Simple bar
import SimpleBar from "simplebar-react"
import withRouter from "components/Common/withRouter"

const MeasureList = props => {
  const [seriesData, setSeriesData] = useState([])
  const [type, setType] = useState()
  const [listBp, setListBp] = useState(false)
  const [bp, setBp] = useState([])
  const [peakExpiratoryFlow, setPeakExpiratoryFlow] = useState([])

  useEffect(() => {
    if (props.data !== undefined) {
      setSeriesData(props.data)
    }
  }, [props.data])

  useEffect(() => {
    if (props.type !== type && props.type !== undefined && props.type !== "") {
      setType(props.type)
      setListBp(false)

      if (props.type === "1") {
        setListBp(true)
      }
    }
  }, [props.type])

  useEffect(() => {
    if (props.bloodPressure !== undefined && props.bloodPressure.length > 0) {
      setBp(props.bloodPressure)
    }
  }, [props.bloodPressure])

  // Fetch peakExpiratoryFlow data and tranform the structure
  useEffect(() => {
    if (props.peakExpiratoryFlow && props.peakExpiratoryFlow.length > 0) {
      const groupedData = props.peakExpiratoryFlow.reduce((acc, entry) => {
        const dateEntry = acc.find(item => item.date === entry.date)
        if (dateEntry) {
          dateEntry.data.push({
            measureType: entry.measureType,
            value: entry.value,
            deviceType: entry.deviceType,
            key: entry.key,
            manual: entry.manual,
            measureId: entry.measureId,
            unit: entry.unit,
          })
        } else {
          acc.push({
            date: entry.date,
            data: [
              {
                measureType: entry.measureType,
                value: entry.value,
                deviceType: entry.deviceType,
                key: entry.key,
                manual: entry.manual,
                measureId: entry.measureId,
                unit: entry.unit,
              },
            ],
          })
        }
        return acc
      }, [])

      setPeakExpiratoryFlow(groupedData)
    }
  }, [props.peakExpiratoryFlow])

  return (
    <>
      {seriesData.length || bp ? (
        <Col lg="12">
          {listBp === true ? (
            <SimpleBar style={{ maxHeight: "370px", marginTop: "-15px" }}>
              <Table
                className="table px-2"
                bordered={false}
                striped={false}
                hover={true}
              >
                <thead>
                  <tr>
                    <th className="h6 text-muted ">{props.t("Time")}</th>
                    <th className="h6 text-muted ">{props.t("Systolic")}</th>
                    <th className="h6 text-muted ">{props.t("Diastolic")}</th>
                    <th className="h6 text-muted ">{props.t("Pulse")}</th>
                  </tr>
                </thead>
                <tbody>
                  {bp?.map((el, index) => (
                    <tr key={index + el.date}>
                      <td className="h6 ">{formatDateHoursMinutes(el.date)}</td>
                      <td className="h6 ">
                        {
                          el.data.find(
                            v => v.measureType === "systolicBloodPressure"
                          )?.value
                        }
                      </td>
                      <td className="h6 ">
                        {
                          el.data.find(
                            v => v.measureType === "diastolicBloodPressure"
                          )?.value
                        }
                      </td>
                      <td className="h6 ">
                        {el.data.find(v => v.measureType === "pulse")?.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </SimpleBar>
          ) : null}

          {listBp === false && props.type !== "6" ? (
            <SimpleBar style={{ maxHeight: "370px", marginTop: "-15px" }}>
              <Table
                className="table px-2"
                bordered={false}
                striped={false}
                hover={true}
              >
                <thead>
                  <tr>
                    <th className="h6 text-muted ">{props.t("Time")}</th>
                    <th className="h6 text-muted ">{props.t("Value")}</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data?.map((el, index) => (
                    <tr key={index + el.deviceType}>
                      <td className="h6 ">{formatDateHoursMinutes(el.date)}</td>
                      <td className="h6 ">
                        {el.value} {el.unit}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </SimpleBar>
          ) : null}

          {props.type === "6" ? (
            <SimpleBar style={{ maxHeight: "370px", marginTop: "-15px" }}>
              <Table
                className="table px-2"
                bordered={false}
                striped={false}
                hover={true}
              >
                <thead>
                  <tr>
                    <th className="h6 text-muted ">{props.t("Time")}</th>
                    <th className="h6 text-muted ">{props.t("PEF")}</th>
                    <th className="h6 text-muted ">{props.t("FEV1")}</th>
                  </tr>
                </thead>
                <tbody>
                  {peakExpiratoryFlow?.map((el, index) => (
                    <tr key={index + el.date}>
                      <td className="h6 ">{formatDateHoursMinutes(el.date)}</td>
                      <td className="h6 ">
                        {
                          el.data.find(
                            v => v.measureType === "peakExpiratoryFlow"
                          )?.value
                        }
                      </td>
                      <td className="h6 ">
                        {el.data?.find(v => v.measureType === "FEV1")?.value ??
                          ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </SimpleBar>
          ) : null}
        </Col>
      ) : (
        <></>
      )}
    </>
  )
}

MeasureList.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  type: PropTypes.any,
  bloodPressure: PropTypes.any,
  peakExpiratoryFlow: PropTypes.any,
}

export default withRouter(withTranslation()(MeasureList))
