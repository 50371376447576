// TODO: Translate
import React, { useEffect, useRef, useState } from "react"
import {
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UncontrolledTooltip,
  Collapse,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Switch, Table, Select, Divider } from "antd"
import {
  booleanSorter,
  downloadExcel,
  showToast,
  stringSorter,
} from "utils/general"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  deleteNewsletterSubscribers,
  getNewsletterSubscribers,
  insertNewsletterSub,
  updateNewsletterSub,
} from "helpers/database_helper"

const { Option } = Select

const RecipientLists = props => {
  const [subs, setSubs] = useState([])
  const [filteredSubs, setFilteredSubs] = useState([])
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState(null)
  const [modal, setModal] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filterVisible, setFilterVisible] = useState(false)
  const [searchName, setSearchName] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [searchTags, setSearchTags] = useState([])
  const [searchGroupname, setSearchGroupname] = useState([])
  const [searchType, setSearchType] = useState("0")
  const [subValues, setSubValues] = useState({
    name: "",
    email: "",
    tags: "",
    groupname: "",
    release_notes: false,
    status_reports: false,
  })

  const abortRef = useRef(null)

  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    setSubs(props.subscribers)
    setLoading(false)
  }, [props.subscribers])

  useEffect(() => {
    setFilteredSubs(subs)
  }, [subs])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchName, searchEmail, searchGroupname, searchType])

  // Handle search function
  const handleSearch = () => {
    let searchNameLowercase = searchName.toLowerCase()
    let searchEmailLowercase = searchEmail.toLowerCase()
    let result = subs.filter(
      data =>
        (searchTags.length === 0 ||
          searchTags.some(tag => data.tags?.includes(tag))) &&
        (searchGroupname.length === 0 ||
          searchGroupname.some(group => data.groupname?.includes(group))) &&
        data.name.toLowerCase().includes(searchNameLowercase) &&
        data.email.toLowerCase().includes(searchEmailLowercase) &&
        (searchType == 0 ||
          (searchType === "1" && data.release_notes === 1) ||
          (searchType === "2" && data.status_reports === 1))
    )
    setFilteredSubs(result)
  }

  const settingNewSelection = () => {
    let newEntry = {
      name: "",
      email: "",
      tags: "",
      groupname: "",
      release_notes: 0,
      status_reports: 0,
    }
    setSelected(null)
    setSubValues(newEntry)
    toggleModal()
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: subValues.name,
      email: subValues.email,
      tags: subValues.tags,
      groupname: subValues.groupname,
      release_notes: subValues.release_notes,
      status_reports: subValues.status_reports,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required field")),
      email: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      if (selected) {
        let formattedValuesUpdate = {
          id: selected.id,
          name: values.name,
          tags: values.tags,
          groupname: values.groupname,
          release_notes: values.release_notes ? 1 : 0,
          status_reports: values.status_reports ? 1 : 0,
        }
        // console.log("Submit values: ", selected.id, formattedValuesUpdate)
        updateSub(formattedValuesUpdate)
      } else {
        let formattedValues = {
          ...values,
          release_notes: values.release_notes ? 1 : 0,
          status_reports: values.status_reports ? 1 : 0,
        }
        // console.log("Submit values: ", formattedValues)
        insertSub(formattedValues)
      }
    },
  })

  const fetchSubs = () => {
    setLoading(true)
    getNewsletterSubscribers(abortRef.current, false, {})
      .then(subs => {
        // console.log("Subscribers: ", subs)
        setSubs(subs)
        props.updatingSubs(subs)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  const toggleConfirmDeleteModal = () => {
    setConfirmDeleteModal(!confirmDeleteModal)
  }

  const insertSub = values => {
    insertNewsletterSub(abortRef.current, false, values)
      .then(result => {
        if (result === "OK") {
          showToast(props.t("Updated"), "success")
          fetchSubs()
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setLoading(false)
        setModal(false)
        settingNewSelection()
      })
  }

  const updateSub = values => {
    updateNewsletterSub(abortRef.current, false, values)
      .then(result => {
        if (result === "OK") {
          showToast(props.t("Updated"), "success")
          fetchSubs()
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setLoading(false)
        setModal(false)
        settingNewSelection()
      })
  }

  const confirmDeleteSubs = () => {
    setConfirmDeleteModal(true)
  }

  const deleteSubs = () => {
    if (selectedRowKeys.length === 0) {
      showToast(props.t("No recipients selected"), "warning")
      return
    }

    setLoading(true)
    deleteNewsletterSubscribers(abortRef.current, false, selectedRowKeys.join())
      .then(result => {
        if (result === "OK") {
          showToast(props.t("Deleted"), "success")
          fetchSubs()
        } else {
          console.log("Delete: ", result)
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setLoading(false)
        setSelectedRowKeys([])
        setConfirmDeleteModal(false)
      })
  }

  const handleTableChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const handleTagsChange = value => {
    const filteredValues = value.filter(val => val.trim() !== "")
    const tagsString = filteredValues.join(",")
    validation.setFieldValue("tags", tagsString)
  }

  const handleGroupnameChange = value => {
    const filteredValues = value.filter(val => val.trim() !== "")
    const groupnameString = filteredValues.join(",")
    validation.setFieldValue("groupname", groupnameString)
  }

  function boolFormatterXlsx(cell) {
    if (cell == true) {
      return "x"
    } else return <></>
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Name"),
        props.t("Email"),
        props.t("Tags"),
        props.t("Group name"),
        props.t("Release notes"),
        props.t("Error bulletins"),
      ],
    ]
    const data = filteredSubs.map(elt => [
      elt.name,
      elt.email,
      elt.tags,
      elt.groupname,
      boolFormatterXlsx(elt.release_notes),
      boolFormatterXlsx(elt.status_reports),
    ])
    downloadExcel(props.t("Mailing list"), heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  // Separate tags
  const allTags = [
    ...new Set(
      subs
        .flatMap(sub =>
          sub.tags ? sub.tags.split(",").map(tag => tag.trim()) : []
        )
        .filter(tag => tag)
    ),
  ]

  // Separate group names
  const allGroupnames = [
    ...new Set(
      subs
        .flatMap(sub =>
          sub.groupname
            ? sub.groupname.split(",").map(group => group.trim())
            : []
        )
        .filter(group => group)
    ),
  ]

  const columns = [
    {
      title: props.t("Name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "name", sortOrder)
      },
    },
    {
      title: props.t("Email"),
      dataIndex: "email",
      key: "email",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "email", sortOrder)
      },
    },
    {
      title: props.t("Tags"),
      dataIndex: "tags",
      key: "tags",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "tags", sortOrder)
      },
    },
    {
      title: props.t("Group name"),
      dataIndex: "groupname",
      key: "groupname",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "groupname", sortOrder)
      },
    },
    {
      title: props.t("Release notes"),
      dataIndex: "release_notes",
      key: "release_notes",
      render: cell => {
        return cell == true ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <></>
        )
      },
      sorter: (a, b, sortOrder) => {
        return booleanSorter(a, b, "release_notes", sortOrder)
      },
    },
    {
      title: props.t("Error bulletins"),
      dataIndex: "status_reports",
      key: "status_reports",
      render: cell => {
        return cell == true ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <></>
        )
      },
      sorter: (a, b, sortOrder) => {
        return booleanSorter(a, b, "status_reports", sortOrder)
      },
    },
    {
      title: props.t("Actions"),
      key: "actions",
      render: (text, record) => (
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            setSelected(record)
            setSubValues(record)
            toggleModal()
          }}
        >
          {props.t("Edit")}
        </Button>
      ),
    },
  ]

  return (
    <>
      <div className="table-btn-subs">
        <Button
          color="success"
          className="mb-3 mt-0 py-2 me-2 bulletin-new-btn"
          onClick={settingNewSelection}
        >
          <i className="mdi mdi-account-plus font-size-14 me-2"></i>
          {props.t("New recipient")}
        </Button>
        {selectedRowKeys.length > 0 && (
          <Button
            color="danger"
            className="mb-3 mt-0 py-2 me-2"
            onClick={confirmDeleteSubs}
            disabled={selectedRowKeys.length === 0}
          >
            <i className="mdi mdi-delete font-size-14 me-2"></i>
            {props.t("Delete selected")}
          </Button>
        )}
        <Button
          color="primary"
          className="mb-3 mt-0 py-2 me-2"
          onClick={() => setFilterVisible(!filterVisible)}
        >
          <i className="mdi mdi-filter-outline font-size-14 me-2"></i>
          {props.t("Filter")}
        </Button>
        <Button
          color="primary"
          className="mb-3 mt-0 py-2 me-2"
          onClick={() => generateExcel()}
        >
          <i className="mdi mdi-download font-size-14 me-2"></i>
          {props.t("Download")} .xlsx
        </Button>
        <Button
          color="primary"
          className="mb-3 mt-0 py-2 me-2"
          onClick={fetchSubs}
        >
          <i className="mdi mdi-refresh font-size-14 me-2"></i>
          {props.t("Refresh")}
        </Button>
        <div className="table-container-subs">
          <Collapse isOpen={filterVisible}>
            <Row className="mb-1">
              <Col md="2">
                <FormGroup>
                  <Label className="mb-0 font-size-13">{props.t("Name")}</Label>
                  <div className="search-box square wide me-2 d-inline-block ">
                    <div className="position-relative">
                      <Input
                        type="text"
                        onChange={event =>
                          setSearchName(event.target.value.toLowerCase())
                        }
                        // placeholder={props.t("Search")}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label className="mb-0 font-size-13">
                    {props.t("Email")}
                  </Label>

                  <div className="search-box square wide me-2 d-inline-block ">
                    <div className="position-relative">
                      <Input
                        type="text"
                        onChange={event =>
                          setSearchEmail(event.target.value.toLowerCase())
                        }
                        // placeholder={props.t("Search")}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label className="mb-0 font-size-13">{props.t("Tags")}</Label>
                  <Select
                    style={{ width: "100%" }}
                    value={searchTags}
                    onChange={value => setSearchTags(value)}
                    mode="multiple"
                  >
                    {allTags.map(tag => (
                      <Option key={tag} value={tag}>
                        {tag}
                      </Option>
                    ))}
                  </Select>
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label className="mb-0 font-size-13">
                    {props.t("Group name")}
                  </Label>
                  <Select
                    style={{ width: "100%" }}
                    value={searchGroupname}
                    onChange={value => setSearchGroupname(value)}
                    mode="multiple"
                  >
                    {allGroupnames.map(name => (
                      <Option key={name} value={name}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label className="mb-0 font-size-13">
                    {props.t("Bulletin type")}
                  </Label>
                  <Select
                    style={{ width: "100%" }}
                    value={searchType}
                    defaultValue="0"
                    onChange={value => setSearchType(value)}
                  >
                    <Option key="0" value="0">
                      {props.t("All")}
                    </Option>
                    <Option key="1" value="1">
                      {props.t("Release notes")}
                    </Option>
                    <Option key="2" value="2">
                      {props.t("Error bulletins")}
                    </Option>
                  </Select>
                </FormGroup>
              </Col>
            </Row>
          </Collapse>
          <Table
            size="small"
            rowSelection={{
              type: "checkbox",
              selectedRowKeys,
              onChange: handleTableChange,
            }}
            columns={columns}
            dataSource={filteredSubs}
            rowKey="id"
            loading={loading}
          />
        </div>
        <Modal isOpen={modal} size="lg" centered toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {subValues && selected
              ? subValues.name
              : props.t("Add a new recipient")}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row xs={1} sm={2}>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Name")}</Label>
                    <Input
                      value={validation.values.name}
                      name="name"
                      placeholder={props.t("Required field")}
                      onChange={validation.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Email")}</Label>
                    <Input
                      value={validation.values.email}
                      name="email"
                      placeholder={props.t("Required field")}
                      onChange={validation.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>
                      {props.t("Tags")}
                      <div id="info-circle" className=" d-md-inline-block">
                        <Button
                          className="pz-2 py-0"
                          size="sm"
                          style={{ cursor: "default" }}
                          color="tranparent"
                          onClick={() => {}}
                        >
                          <i className="bx bx-info-circle font-size-10" />
                        </Button>
                      </div>
                      <UncontrolledTooltip
                        placement="right"
                        target="info-circle"
                      >
                        {props.t("Choose or create a new tag")}
                      </UncontrolledTooltip>
                    </Label>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder={props.t("Add tags")}
                      value={
                        validation.values.tags
                          ? validation.values.tags.split(",")
                          : []
                      }
                      onChange={handleTagsChange}
                      tokenSeparators={[","]}
                    >
                      {allTags.map(tag => (
                        <Option key={tag} value={tag}>
                          {tag}
                        </Option>
                      ))}
                    </Select>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>
                      {props.t("Group name")}{" "}
                      <div
                        id="info-circle-groupname"
                        className=" d-md-inline-block"
                      >
                        <Button
                          className="pz-2 py-0"
                          size="sm"
                          style={{ cursor: "default" }}
                          color="tranparent"
                          onClick={() => {}}
                        >
                          <i className="bx bx-info-circle font-size-10" />
                        </Button>
                      </div>
                      <UncontrolledTooltip
                        placement="right"
                        target="info-circle-groupname"
                      >
                        {props.t("Choose or create a new group name")}
                      </UncontrolledTooltip>
                    </Label>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder={props.t("Add group names")}
                      value={
                        validation.values.groupname
                          ? validation.values.groupname.split(",")
                          : []
                      }
                      onChange={handleGroupnameChange}
                      tokenSeparators={[","]}
                    >
                      {allGroupnames.map(group => (
                        <Option key={group} value={group}>
                          {group}
                        </Option>
                      ))}
                    </Select>
                  </FormGroup>
                </Col>
              </Row>

              <Row xs={1} sm={4}>
                <Divider
                  orientationMargin="5"
                  orientation="left"
                  className="font-size-13 text-muted mt-1"
                >
                  {props.t("Subscriptions")}
                </Divider>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Release notes")}</Label>
                    <Switch
                      className="ms-2"
                      name="release_notes"
                      checked={validation.values.release_notes}
                      onChange={v =>
                        validation.setFieldValue("release_notes", v)
                      }
                      checkedChildren={<i className="mdi mdi-check"></i>}
                      unCheckedChildren={<i className="mdi mdi-close"></i>}
                    ></Switch>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Error bulletins")}</Label>
                    <Switch
                      className="ms-2"
                      name="status_reports"
                      checked={validation.values.status_reports}
                      onChange={v =>
                        validation.setFieldValue("status_reports", v)
                      }
                      checkedChildren={<i className="mdi mdi-check"></i>}
                      unCheckedChildren={<i className="mdi mdi-close"></i>}
                    ></Switch>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={toggleModal}>
              {props.t("Cancel")}
            </Button>
            <Button color="primary" onClick={validation.handleSubmit}>
              {props.t("Save")}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={confirmDeleteModal}
          centered
          toggle={toggleConfirmDeleteModal}
        >
          <ModalHeader toggle={toggleConfirmDeleteModal}>
            {props.t("Confirm deletion")}
          </ModalHeader>
          <ModalBody>
            <p>
              {props.t(
                "Are you sure you want to delete the following recipients"
              )}
              ?
            </p>
            <ul>
              {subs
                .filter(sub => selectedRowKeys.includes(sub.id))
                .map(sub => (
                  <li key={sub.id}>{sub.name}</li>
                ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              disabled={loading}
              onClick={toggleConfirmDeleteModal}
            >
              {props.t("Cancel")}
            </Button>
            <Button color="danger" disabled={loading} onClick={deleteSubs}>
              {props.t("Delete")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
}

RecipientLists.propTypes = {
  t: PropTypes.any,
  subscribers: PropTypes.any,
  updatingSubs: PropTypes.any,
}

export default withTranslation()(RecipientLists)
