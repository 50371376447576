/***********************
 * REPORTING (LIST)
 ************************/

const itemsTogether = [
  {
    id: 1,
    text: "Customers and services",
    value: 63,
  },
  {
    id: 2,
    text: "Alerts",
    value: 63,
  },
  {
    id: 3,
    text: "Calls",
    value: 63,
  },
  {
    id: 4,
    text: "Group video calls",
    value: 63,
  },
  {
    id: 5,
    text: "Visits",
    value: 63,
  },
  {
    id: 6,
    text: "Remote measurements",
    value: 63,
  },
  {
    id: 7,
    text: "Device identifications",
    value: 63,
  },
  {
    id: 8,
    text: "Open requests",
    value: 63,
  },
  {
    id: 9,
    text: "Group call licenses",
    value: 63,
  },
]
export default itemsTogether
