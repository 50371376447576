import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Drawer, Popconfirm, Switch, Table as AntdTable } from "antd"
import {
  createGatewayNode,
  createVirtualGateway,
  deleteGateway,
  deleteSelectedNodes,
  gatewayStartReverseSsh,
  getGateway,
  getNodesList,
  rebootGateway,
  resetGateway,
  resetGatewayNodes,
  teliaSimStatus,
  transferGatewayContent,
  updateGateway,
  updateGatewayNode,
} from "helpers/backend_helper"
import * as Yup from "yup"
import { useFormik } from "formik"
import SelectOrder from "components/DropdownList/SelectOrder"
import {
  dateFormatter,
  dateSorter,
  defaultPageSizeOptions,
  getLottieLoadingOptions,
  getLottieNothingFound,
  numberSorter,
  showToast,
  stringSorter,
} from "utils/general"
import classnames from "classnames"
import Lottie from "react-lottie"
import withRouter from "components/Common/withRouter"
import SimStatusDropdown from "components/Misc/SimStatusDropdown"
import { tableDefaultHeight } from "constants/layout"

const GatewayDrawer = props => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [gatewayError, setGatewayError] = useState("")
  const [gatewaySuccess, setGatewaySuccess] = useState("")
  const [nodeError, setNodeError] = useState("")
  const [nodeSuccess, setNodeSuccess] = useState("")
  const [update, setUpdate] = useState(false)
  const [nodes, setNodes] = useState([])
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [simDropdownOpen, setSimDropdownOpen] = useState(false)
  const [ongoingAction, setOngoingAction] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedNode, setSelectedNode] = useState(null)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [resetConfirm, setResetConfirm] = useState(false)
  const [gatewayResetConfirm, setGatewayResetConfirm] = useState(false)
  const [rebootConfirm, setRebootConfirm] = useState(false)
  const [sshConfirm, setSshConfirm] = useState(false)
  const [transferConfirm, setTransferConfirm] = useState(false)
  const [transferGatewayKey, setTransferGatewayKey] = useState("")
  const [renderNodeTab, setRenderNodeTab] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.gatewayKey && props.showDrawer) {
      getData()
      getNodes()
    }
  }, [props.gatewayKey, props.showDrawer])

  // Get gateway data
  const getData = () => {
    if (!loading && props.gatewayKey) {
      setLoading(true)
      getGateway(abortRef.current, props.gatewayKey)
        .then(result => {
          if (result.statusCode === 200 && result.data) {
            setData(result.data)
          } else {
            setData(null)
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
          setData(null)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Get nodes
  const getNodes = () => {
    if (!ongoingSearch && props.gatewayKey) {
      setOngoingSearch(true)
      getNodesList(abortRef.current, props.gatewayKey)
        .then(result => {
          //console.log("care: ", result.entries)
          if (result.statusCode === 200 && result.entries != null) {
            setNodes(result.entries)
          } else {
            setNodes([])
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
          setNodes([])
        })
        .finally(() => {
          setOngoingSearch(false)
        })
    }
  }

  // Add node
  const addNode = () => {
    if (!ongoingAction && data?.key) {
      setOngoingAction(true)
      createGatewayNode(abortRef.current, data.key)
        .then(result => {
          if (result.statusCode === 200) {
            getNodes()
            showToast(props.t("Success"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Delete selected nodes
  const deleteNodes = () => {
    if (data?.key.length > 0 && selectedRowKeys.length > 0 && !ongoingAction) {
      setOngoingAction(true)
      deleteSelectedNodes(abortRef.current, data.key, selectedRowKeys)
        .then(result => {
          if (result.statusCode === 200) {
            setSelectedRowKeys([])
            setSelectedNode(null)
            getNodes()
            showToast(props.t("Deleted"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Reset all nodes
  const resetNodes = () => {
    if (data?.key.length > 0 && !ongoingAction) {
      setOngoingAction(true)
      resetGatewayNodes(abortRef.current, data.key)
        .then(result => {
          if (result.statusCode === 200) {
            setSelectedNode(null)
            getNodes()
            showToast(props.t("Success"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Delete gateway
  const deleteGW = () => {
    if (data?.key.length > 0 && !ongoingAction) {
      setOngoingAction(true)
      deleteGateway(abortRef.current, data.key)
        .then(result => {
          //console.log("care: ", result.entries)
          if (result.statusCode === 200) {
            setDeleteModal(false)
            showToast(props.t("Deleted"), "success")
            props.setShowDrawer(false)
            if (props.getGateways) props.getGateways()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Reset gateway
  const resetGW = () => {
    if (data?.key.length > 0 && !ongoingAction) {
      setOngoingAction(true)
      resetGateway(abortRef.current, data.key)
        .then(result => {
          if (result.statusCode === 200) {
            showToast(props.t("Success"), "success")
            getNodes()
            if (props.getGateways) props.getGateways()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Reboot gateway
  const rebootGW = () => {
    if (data?.key.length > 0 && !ongoingAction) {
      setOngoingAction(true)
      rebootGateway(abortRef.current, data.key)
        .then(result => {
          if (result.statusCode === 200) {
            showToast(props.t("Success"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Start reverse SSH
  const startReverseSsh = () => {
    if (data?.key.length > 0 && !ongoingAction) {
      setOngoingAction(true)
      gatewayStartReverseSsh(abortRef.current, data.key)
        .then(result => {
          if (result.statusCode === 200) {
            showToast(props.t("Success"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Transfer content
  const transferGW = () => {
    if (!ongoingAction && data?.key.length > 0 && transferGatewayKey) {
      setOngoingAction(true)
      transferGatewayContent(abortRef.current, data.key, transferGatewayKey)
        .then(result => {
          if (result.statusCode === 200) {
            showToast(props.t("Success"), "success")
            setTransferGatewayKey("")
            getNodes()
            if (props.getGateways) props.getGateways()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // SIM status
  const simStatusAction = status => {
    if (data?.imsi && !ongoingAction) {
      setOngoingAction(true)
      teliaSimStatus(abortRef.current, data.imsi, status)
        .then(result => {
          if (result.statusCode === 200) {
            showToast(props.t("Status") + ": " + result.simStatus, "success")
            if (props.getGateways) props.getGateways()
          }
          else if (result.errorMessage) {
            showToast(props.t("Error") + ": " + result.errorMessage, "error")
          } 
          else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Add virtual GW
  const addVirtualGW = () => {
    if (!ongoingAction && data) {
      setOngoingAction(true)
      createVirtualGateway(abortRef.current, data.key)
        .then(result => {
          if (result.statusCode === 200) {
            showToast(props.t("Success"), "success")
            if (props.getGateways) props.getGateways()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Update gateway validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: data?.description || "",
      serial: data?.serial || "",
      gsm: data?.gsm || "",
      imei: data?.imei || "",
      imsi: data?.imsi || "",
      iccid: data?.iccid || "",
      zigbeeEpId: data?.zigbeeEpId || "",
      wifiPass: data?.wifiPass || "",
      orderNumber: data?.orderNumber || "",
      hostKey: data?.hostKey || "",
      configured: data?.configured || false,
      coordinatorUpdate: data?.coordinatorUpdate || false,
      sshConnection: data?.sshConnection || false,
      remoteCommand: data?.remoteCommand || false,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit values: ", values)
      if (!update) {
        setGatewaySuccess("")
        setGatewayError("")
        setUpdate(true)

        let temp = JSON.parse(JSON.stringify(values))
        temp.serial = parseInt(values.serial, 10)
        temp.zigbeeEpId = parseInt(values.zigbeeEpId, 10)
        temp.orderNumber = temp.orderNumber ? temp.orderNumber : ""

        updateGateway(abortRef.current, data?.key, temp)
          .then(result => {
            console.log(result)
            setGatewaySuccess("Updated")
            getData()
            if (props.getGateways) props.getGateways()
          })
          .catch(err => {
            console.log(err)
            setGatewayError("Error")
          })
          .finally(() => {
            setUpdate(false)
          })
      }
    },
  })

  // Update node validation
  const nodeValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: selectedNode?.description || "",
      nodeId: selectedNode?.nodeId || 0,
      version: selectedNode?.version || "",
      deviceType: selectedNode?.deviceType || "",
      trackingTarget: selectedNode?.trackingTarget || "",
      configured: selectedNode?.configured || false,
      powerOnLevel: selectedNode?.powerOnLevel || 0,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit values: ", values)

      setNodeSuccess("")
      setNodeError("")

      let temp = JSON.parse(JSON.stringify(values))
      temp.powerOnLevel = parseInt(values.powerOnLevel, 10)
      temp.nodeId = parseInt(values.nodeId, 10)

      if (selectedNode?.key && !update) {
        setUpdate(true)
        updateGatewayNode(abortRef.current, selectedNode.key, temp)
          .then(result => {
            console.log(result)
            if (result.statusCode == 200) {
              setNodeSuccess("Updated")
              getNodes()
            } else {
              setNodeError("Error")
            }
          })
          .catch(err => {
            console.log(err)
            setNodeErrors("Error")
          })
          .finally(() => {
            setUpdate(false)
          })
      }
    },
  })

  // Table structure
  const columns = [
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "nodeId",
      title: props.t("Node ID"),
      defaultSortOrder: "ascend",
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["nodeId"], b["nodeId"], sortOrder)
      },
      fixed: "left",
      width: "90px",
      ellipsis: true,
    },
    {
      dataIndex: "lastConnected",
      title: props.t("Connected"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastConnected"], b["lastConnected"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "description",
      title: props.t("Description"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "description", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "configured",
      title: props.t("Configured"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return a.configured - b.configured
      },
      render: cell => {
        return cell ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <i className="mdi mdi-close text-danger" />
        )
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "deviceType",
      title: props.t("Device type"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "deviceType", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "trackingTarget",
      title: props.t("Tracking target"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "trackingTarget", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "lastActivity",
      title: props.t("Last activity"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastActivity"], b["lastActivity"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "voltage",
      title: props.t("Voltage"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["voltage"], b["voltage"], sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "temperature",
      title: props.t("Temperature"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["temperature"], b["temperature"], sortOrder)
      },
      width: "110px",
      ellipsis: true,
    },
    {
      dataIndex: "humidity",
      title: props.t("Humidity"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["humidity"], b["humidity"], sortOrder)
      },
      width: "130px",
      ellipsis: true,
    },
    {
      dataIndex: "doorBedStatus",
      title: props.t("Door / bed status"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return a.doorBedStatus - b.doorBedStatus
      },
      render: (cell, row) => {
        if (row.deviceType == "Door") {
          return cell ? (
            <span className="badge badge-soft-success font-size-12">
              {props.t("StateOpen")}
            </span>
          ) : (
            <span className="badge badge-soft-gray font-size-12">
              {props.t("StateClosed")}
            </span>
          )
        } else if (row.deviceType == "Bed") {
          return cell ? (
            <span className="badge badge-soft-success font-size-12">
              {props.t("In use")}
            </span>
          ) : (
            <span className="badge badge-soft-gray font-size-12">
              {props.t("Not in use")}
            </span>
          )
        } else return <></>
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "powerLevel",
      title: props.t("Power level"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["powerLevel"], b["powerLevel"], sortOrder)
      },
      width: "150px",
      ellipsis: true,
    },
    {
      dataIndex: "powerOnLevel",
      title: props.t("Power on level"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["powerOnLevel"], b["powerOnLevel"], sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "version",
      title: props.t("Version"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "version", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "key",
      title: props.t("GUID"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "key", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setNodeSuccess("")
            setNodeError("")
            setSelectedNode(row)
            let editElement = document.getElementById("node-edit")
            if (editElement) editElement.scrollIntoView()
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  // Selected order changed
  const orderSelectionChanged = value => {
    validation.setFieldValue("orderNumber", value)
  }

  const rowSelection = {
    selectedRowKeys,
    onSelect: (row, isSelect, rowArray) => {
      if (isSelect) {
        setSelectedRowKeys(prevState => [...prevState, row.key])
      } else {
        setSelectedRowKeys(prevState =>
          prevState.filter(prevItem => prevItem !== row.key)
        )
      }
    },
    onSelectAll: (isSelect, object, rowArray) => {
      if (isSelect) {
        rowArray.map(row =>
          setSelectedRowKeys(prevState => [...prevState, row.key])
        )
      } else {
        setSelectedRowKeys([])
      }
    },
  }

  return (
    <>
      <Drawer
        title={props.t("Gateway") + " " + data?.description}
        width={"80%"}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
          setGatewayError("")
          setGatewaySuccess("")
          setSelectedNode(null)
          //setSelectedNodes([])
          setSelectedRowKeys([])
          setNodeError("")
          setNodeSuccess("")
          setTransferGatewayKey("")
          setActiveTab("1")
          setRenderNodeTab(false)
          setData(null)
        }}
        open={props.showDrawer}
        zIndex={1003}
      //bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        {loading && !data && (
          <Lottie
            options={getLottieLoadingOptions()}
            height={100}
            width={100}
          />
        )}

        {!loading && !data && (
          <Card>
            <CardBody>
              <div className="text-center mb-4">
                <Lottie
                  options={getLottieNothingFound()}
                  height={150}
                  width={150}
                />
                <h5 className="text-muted mt-2">{props.t("No data found")}</h5>
              </div>
            </CardBody>
          </Card>
        )}

        {data && (
          <>
            <div className="d-flex flex-wrap mb-3">
              <ul className="nav icon-tab">
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      setActiveTab("1")
                    }}
                  >
                    {props.t("Information and settings")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      setActiveTab("2")
                      setRenderNodeTab(true)
                    }}
                  >
                    {props.t("Nodes")}
                  </NavLink>
                </NavItem>
              </ul>
            </div>

            <TabContent activeTab={activeTab} className="">
              <TabPane className="show" tabId="1">
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          {props.t("Settings")}
                        </CardTitle>
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            return false
                          }}
                        >
                          <Row xs={2}>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("Description")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="description"
                                  type="text"
                                  value={validation.values.description}
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("Serial")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="serial"
                                  type="number"
                                  value={validation.values.serial}
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("GSM")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="gsm"
                                  type="text"
                                  value={validation.values.gsm}
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("ICCID")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="iccid"
                                  type="text"
                                  value={validation.values.iccid}
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("IMSI")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="imsi"
                                  type="text"
                                  value={validation.values.imsi}
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("IMEI")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="imei"
                                  type="text"
                                  value={validation.values.imei}
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("Zigbee EPID")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="zigbeeEpId"
                                  type="number"
                                  value={validation.values.zigbeeEpId}
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("WiFi password")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="wifiPass"
                                  type="text"
                                  value={validation.values.wifiPass}
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                          </Row>

                          {data?.virtual && (
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Host key")}</Label>
                                  <Input
                                    bsSize="sm"
                                    name="hostKey"
                                    type="text"
                                    value={validation.values.hostKey}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                            </Row>
                          )}

                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("Order number")}</Label>
                                <SelectOrder
                                  selection={validation.values.orderNumber}
                                  setSelectedOrder={orderSelectionChanged}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row sm={2}>
                            <Col>
                              <FormGroup className="mt-2">
                                <Switch
                                  className="me-2"
                                  name="configured"
                                  checked={validation.values.configured}
                                  onChange={v => {
                                    validation.setFieldValue("configured", v)
                                  }}
                                ></Switch>
                                <Label>{props.t("Configured")}</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup className="mt-2">
                                <Switch
                                  className="me-2"
                                  name="sshConnection"
                                  checked={validation.values.sshConnection}
                                  onChange={v => {
                                    validation.setFieldValue("sshConnection", v)
                                  }}
                                ></Switch>
                                <Label>{props.t("SSH connection")}</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup className="mt-2">
                                <Switch
                                  className="me-2"
                                  name="coordinatorUpdate"
                                  checked={validation.values.coordinatorUpdate}
                                  onChange={v => {
                                    validation.setFieldValue(
                                      "coordinatorUpdate",
                                      v
                                    )
                                  }}
                                ></Switch>
                                <Label>{props.t("Coordinator update")}</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup className="mt-2">
                                <Switch
                                  className="me-2"
                                  name="remoteCommand"
                                  checked={validation.values.remoteCommand}
                                  onChange={v => {
                                    validation.setFieldValue("remoteCommand", v)
                                  }}
                                ></Switch>
                                <Label>{props.t("Remote command")}</Label>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div className="mt-2 mb-3 d-flex justify-content-center">
                            <Button
                              color="primary"
                              disabled={update}
                              style={{ minWidth: "150px" }}
                              onClick={() => {
                                validation.submitForm()
                              }}
                            >
                              {props.t("Save")}
                            </Button>
                          </div>
                          {gatewayError ? (
                            <Alert color="danger">
                              {props.t(gatewayError)}
                            </Alert>
                          ) : null}
                          {gatewaySuccess ? (
                            <Alert color="success">
                              {props.t(gatewaySuccess)}
                            </Alert>
                          ) : null}
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          {props.t("Actions")}
                        </CardTitle>
                        <ListGroup className="list-group-flush">
                          {!data?.virtual && (
                            <ListGroupItem className="pb-4">
                              <Row>
                                <Col>
                                  <div>
                                    <h6>{props.t("Add virtual gateway")}</h6>
                                    <span className="text-muted">
                                      {props.t(
                                        "Currently selected gateway will be set as the host gateway"
                                      )}
                                    </span>
                                  </div>
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    color="success"
                                    style={{ minWidth: "100px" }}
                                    disabled={ongoingAction}
                                    onClick={() => {
                                      addVirtualGW()
                                    }}
                                  >
                                    {props.t("Add")}
                                  </Button>
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}

                          {data?.gsm.indexOf("453") == 0 && (
                            <ListGroupItem className="pb-4">
                              <Row>
                                <Col>
                                  <div>
                                    <h6>
                                      {props.t("SIM - IMSI") +
                                        " (" +
                                        data?.simStatus +
                                        ")"}
                                    </h6>
                                    <span className="text-muted">
                                      {props.t("Check or change SIM status")}
                                    </span>
                                  </div>
                                </Col>
                                <Col className="col-auto">
                                  <ButtonDropdown
                                    isOpen={simDropdownOpen}
                                    toggle={() => {
                                      setSimDropdownOpen(!simDropdownOpen)
                                    }}
                                  >
                                    <DropdownToggle
                                      color="primary"
                                      style={{ minWidth: "100px" }}
                                      caret
                                    >
                                      {props.t("Select action")}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        disabled={ongoingAction || !data?.imsi}
                                        onClick={() => {
                                          simStatusAction(undefined)
                                        }}
                                      >
                                        {props.t("Check")}
                                      </DropdownItem>
                                      <DropdownItem
                                        disabled={ongoingAction || !data?.imsi}
                                        onClick={() => {
                                          simStatusAction("Activate")
                                        }}
                                      >
                                        {props.t("Activate")}
                                      </DropdownItem>
                                      <DropdownItem
                                        disabled={ongoingAction || !data?.imsi}
                                        onClick={() => {
                                          simStatusAction("Pause")
                                        }}
                                      >
                                        {props.t("Pause")}
                                      </DropdownItem>
                                      <DropdownItem
                                        disabled={ongoingAction || !data?.imsi}
                                        onClick={() => {
                                          simStatusAction("Terminate")
                                        }}
                                      >
                                        {props.t("Terminate")}
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </ButtonDropdown>
                                </Col>
                              </Row>
                            </ListGroupItem>
                          )}

                          {data?.iccid && (
                            <ListGroupItem className="pb-4">
                              <SimStatusDropdown iccid={data.iccid} />
                            </ListGroupItem>
                          )}

                          <ListGroupItem className="pb-4">
                            <Row>
                              <Col>
                                <div>
                                  <h6>{props.t("Start reverse SSH")}</h6>
                                  <span className="text-muted">
                                    {props.t("Send command via SMS")}
                                  </span>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <Popconfirm
                                  title={props.t("Confirm action")}
                                  open={sshConfirm}
                                  okType="primary"
                                  okText={props.t("Send")}
                                  cancelText={props.t("Cancel")}
                                  onConfirm={() => {
                                    startReverseSsh()
                                    setSshConfirm(false)
                                  }}
                                  onCancel={() => {
                                    setSshConfirm(false)
                                  }}
                                >
                                  <Button
                                    color="primary"
                                    style={{ minWidth: "100px" }}
                                    disabled={ongoingAction}
                                    onClick={() => {
                                      setSshConfirm(true)
                                    }}
                                  >
                                    {props.t("Send")}
                                  </Button>
                                </Popconfirm>
                              </Col>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem className="pb-4">
                            <Row>
                              <Col>
                                <div>
                                  <h6>{props.t("Reboot")}</h6>
                                  <span className="text-muted">
                                    {props.t("Send command via SMS")}
                                  </span>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <Popconfirm
                                  title={props.t("Confirm action")}
                                  open={rebootConfirm}
                                  okType="primary"
                                  okText={props.t("Send")}
                                  cancelText={props.t("Cancel")}
                                  onConfirm={() => {
                                    rebootGW()
                                    setRebootConfirm(false)
                                  }}
                                  onCancel={() => {
                                    setRebootConfirm(false)
                                  }}
                                >
                                  <Button
                                    color="primary"
                                    style={{ minWidth: "100px" }}
                                    disabled={ongoingAction}
                                    onClick={() => {
                                      setRebootConfirm(true)
                                    }}
                                  >
                                    {props.t("Send")}
                                  </Button>
                                </Popconfirm>
                              </Col>
                            </Row>
                          </ListGroupItem>
                          {/** 
                          <ListGroupItem className="pb-4">
                            <Row>
                              <Col>
                                <div>
                                  <h6>{props.t("Lock") + " IMSI-IMEI"}</h6>
                                  <span className="text-muted">
                                    {props.t("")}
                                  </span>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <Button color="primary" style={{minWidth: "100px"}} disabled onClick={() => {}}>
                                  {props.t("Lock")}
                                </Button>
                              </Col>
                            </Row>
                          </ListGroupItem>
                          */}

                          <ListGroupItem className="pb-4">
                            <Row>
                              <Col>
                                <div>
                                  <h6>{props.t("Transfer content")}</h6>
                                  <span className="text-muted">
                                    {props.t(
                                      "Move nodes and settings to another gateway"
                                    )}
                                  </span>
                                  <Input
                                    style={{ maxWidth: "300px" }}
                                    className="mt-2"
                                    bsSize="sm"
                                    name="transferGatewayKey"
                                    type="text"
                                    placeholder="GW GUID"
                                    value={transferGatewayKey}
                                    onChange={v => {
                                      setTransferGatewayKey(v.target.value)
                                    }}
                                  ></Input>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <Popconfirm
                                  title={props.t("Confirm action")}
                                  open={transferConfirm}
                                  okType="primary"
                                  okText={props.t("Transfer")}
                                  cancelText={props.t("Cancel")}
                                  onConfirm={() => {
                                    transferGW()
                                    setTransferConfirm(false)
                                  }}
                                  onCancel={() => {
                                    setTransferConfirm(false)
                                  }}
                                >
                                  <Button
                                    color="primary"
                                    style={{ minWidth: "100px" }}
                                    disabled={
                                      ongoingAction || !transferGatewayKey
                                    }
                                    onClick={() => {
                                      setTransferConfirm(true)
                                    }}
                                  >
                                    {props.t("Transfer")}
                                  </Button>
                                </Popconfirm>
                              </Col>
                            </Row>
                          </ListGroupItem>

                          <ListGroupItem className="pb-4">
                            <Row>
                              <Col>
                                <div>
                                  <h6>{props.t("Reset device")}</h6>
                                  <span className="text-muted">
                                    {props.t(
                                      "Resets gateway values to default and pauses Telia SIM. Nodes have to be deleted beforehand."
                                    )}
                                  </span>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <Popconfirm
                                  title={props.t("Confirm action")}
                                  open={gatewayResetConfirm}
                                  okType="danger"
                                  okText={props.t("ResetCommand")}
                                  cancelText={props.t("Cancel")}
                                  onConfirm={() => {
                                    resetGW()
                                    setGatewayResetConfirm(false)
                                  }}
                                  onCancel={() => {
                                    setGatewayResetConfirm(false)
                                  }}
                                >
                                  <Button
                                    color="danger"
                                    style={{ minWidth: "100px" }}
                                    disabled={ongoingAction}
                                    onClick={() => {
                                      setGatewayResetConfirm(true)
                                    }}
                                  >
                                    {props.t("ResetCommand")}
                                  </Button>
                                </Popconfirm>
                              </Col>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem className="pb-4">
                            <Row>
                              <Col>
                                <div>
                                  <h6>{props.t("Deletion")}</h6>
                                  <span className="text-muted">
                                    {props.t(
                                      "The device and all related data will be deleted permanently"
                                    )}
                                  </span>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  color="danger"
                                  style={{ minWidth: "100px" }}
                                  onClick={() => {
                                    setDeleteModal(true)
                                  }}
                                >
                                  {props.t("Delete")}
                                </Button>
                              </Col>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>

              <TabPane className="show" tabId="2">
                {renderNodeTab && (
                  <>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                            <React.Fragment>
                              <Row className="mb-1">
                                <Col className="col-auto">
                                  <Button
                                    style={{ borderRadius: "100px" }}
                                    color="primary"
                                    disabled={ongoingSearch}
                                    onClick={() => {
                                      getNodes()
                                    }}
                                  >
                                    <i className="mdi mdi-refresh me-2" />
                                    {props.t("Refresh")}
                                  </Button>
                                  <Button
                                    className="ms-3"
                                    style={{ borderRadius: "100px" }}
                                    color="success"
                                    disabled={ongoingAction}
                                    onClick={() => {
                                      addNode()
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-2" />
                                    {props.t("Create new")}
                                  </Button>

                                  <Popconfirm
                                    title={props.t("Confirm action")}
                                    open={deleteConfirm}
                                    okType="danger"
                                    okText={props.t("Delete")}
                                    cancelText={props.t("Cancel")}
                                    onConfirm={() => {
                                      deleteNodes()
                                      setDeleteConfirm(false)
                                    }}
                                    onCancel={() => {
                                      setDeleteConfirm(false)
                                    }}
                                  >
                                    <Button
                                      className="ms-3"
                                      style={{ borderRadius: "100px" }}
                                      color="danger"
                                      disabled={
                                        selectedRowKeys.length == 0 ||
                                        ongoingAction
                                      }
                                      onClick={() => {
                                        setDeleteConfirm(true)
                                      }}
                                    >
                                      <i className="mdi mdi-delete me-2" />
                                      {props.t("Delete selected")}
                                    </Button>
                                  </Popconfirm>

                                  <Popconfirm
                                    title={props.t("Confirm action")}
                                    open={resetConfirm}
                                    okType="danger"
                                    okText={props.t("ResetCommand")}
                                    cancelText={props.t("Cancel")}
                                    onConfirm={() => {
                                      resetNodes()
                                      setResetConfirm(false)
                                    }}
                                    onCancel={() => {
                                      setResetConfirm(false)
                                    }}
                                  >
                                    <Button
                                      className="ms-3"
                                      style={{ borderRadius: "100px" }}
                                      color="danger"
                                      disabled={
                                        ongoingAction || nodes.length == 0
                                      }
                                      onClick={() => {
                                        setResetConfirm(true)
                                      }}
                                    >
                                      <i className="bx bx-reset me-2" />
                                      {props.t("Reset all")}
                                    </Button>
                                  </Popconfirm>
                                </Col>
                                <Col>
                                  <div className="mt-2 float-end">
                                    <p>
                                      {nodes.length} {props.t("found results")}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mt-2">
                                <Col xl="12">
                                  {ongoingSearch ? (
                                    <div className="py-5 text-center">
                                      <p className="text-primary">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                        {props.t("Loading")}
                                      </p>
                                    </div>
                                  ) : (
                                    <AntdTable
                                      className="colored-header narrow-header"
                                      bordered
                                      size="small"
                                      rowKey={"key"}
                                      columns={columns}
                                      dataSource={nodes}
                                      rowSelection={rowSelection}
                                      pagination={{
                                        showSizeChanger: true,
                                        defaultPageSize: 50,
                                        pageSizeOptions: defaultPageSizeOptions,
                                      }}
                                      tableLayout="auto"
                                      scroll={{
                                        x: "max-content",
                                        y: tableDefaultHeight,
                                      }}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </React.Fragment>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card
                      id="node-edit"
                      style={{
                        visibility: selectedNode ? "visible" : "hidden",
                      }}
                    >
                      <CardBody>
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            return false
                          }}
                        >
                          <Row xs={1} sm={2} lg={3}>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("Description")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="description"
                                  type="text"
                                  value={nodeValidation.values.description}
                                  onChange={nodeValidation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("Node ID")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="nodeId"
                                  type="number"
                                  value={nodeValidation.values.nodeId}
                                  onChange={nodeValidation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("Device type")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="deviceType"
                                  type="select"
                                  value={nodeValidation.values.deviceType}
                                  onChange={nodeValidation.handleChange}
                                >
                                  <option value="">
                                    {props.t("Undefined")}
                                  </option>
                                  <option value="Activity">
                                    {props.t("Activity")}
                                  </option>
                                  <option value="Electric">
                                    {props.t("Electric")}
                                  </option>
                                  <option value="Door">
                                    {props.t("Door")}
                                  </option>
                                  <option value="Bed">{props.t("Bed")}</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("Tracking target")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="trackingTarget"
                                  type="select"
                                  value={nodeValidation.values.trackingTarget}
                                  onChange={nodeValidation.handleChange}
                                >
                                  <option value="">
                                    {props.t("Undefined")}
                                  </option>
                                  <option value="Sleep">
                                    {props.t("Sleep")}
                                  </option>
                                  <option value="WC">
                                    {props.t("Toilet")}
                                  </option>
                                  <option value="Dining">
                                    {props.t("Dining")}
                                  </option>
                                  <option value="Outside Door">
                                    {props.t("Outside door")}
                                  </option>
                                  <option value="Living Room">
                                    {props.t("Living room")}
                                  </option>
                                  <option value="Lobby">
                                    {props.t("Lobby")}
                                  </option>
                                  <option value="Coffee">
                                    {props.t("Coffee machine")}
                                  </option>
                                  <option value="Television">
                                    {props.t("Television")}
                                  </option>
                                  <option value="Backdoor">
                                    {props.t("Backdoor")}
                                  </option>
                                  <option value="Microwave">
                                    {props.t("Microwave")}
                                  </option>
                                  <option value="Fridge">
                                    {props.t("Fridge")}
                                  </option>
                                  <option value="Balcony">
                                    {props.t("Balcony")}
                                  </option>
                                  <option value="Corridor">
                                    {props.t("Corridor")}
                                  </option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("Version")}</Label>
                                <Input
                                  bsSize="sm"
                                  name="version"
                                  type="text"
                                  value={nodeValidation.values.version}
                                  onChange={nodeValidation.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>{props.t("Configured")}</Label>
                                <Switch
                                  className="d-block"
                                  name="configured"
                                  checked={nodeValidation.values.configured}
                                  onChange={v => {
                                    nodeValidation.setFieldValue(
                                      "configured",
                                      v
                                    )
                                  }}
                                  checkedChildren={
                                    <i className="mdi mdi-check-bold" />
                                  }
                                  unCheckedChildren={
                                    <i className="mdi mdi-close-thick" />
                                  }
                                />
                              </FormGroup>
                            </Col>
                            {nodeValidation.values.deviceType == "Electric" && (
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Power on level")}</Label>
                                  <Input
                                    bsSize="sm"
                                    name="powerOnLevel"
                                    type="number"
                                    value={nodeValidation.values.powerOnLevel}
                                    onChange={nodeValidation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                            )}
                          </Row>

                          <div className="mt-2 mb-3 d-flex justify-content-center">
                            <Button
                              color="primary"
                              disabled={update}
                              style={{ minWidth: "150px" }}
                              onClick={() => {
                                nodeValidation.submitForm()
                              }}
                            >
                              {props.t("Save")}
                            </Button>
                          </div>
                          {nodeError ? (
                            <Alert color="danger">{props.t(nodeError)}</Alert>
                          ) : null}
                          {nodeSuccess ? (
                            <Alert color="success">
                              {props.t(nodeSuccess)}
                            </Alert>
                          ) : null}
                        </Form>
                      </CardBody>
                    </Card>
                  </>
                )}
              </TabPane>
            </TabContent>
          </>
        )}
      </Drawer>

      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody>
          <p>
            {props.t("Are you sure you want to delete") +
              " " +
              data?.description +
              "?"}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={ongoingAction}
            color="danger"
            onClick={() => {
              deleteGW()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

GatewayDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  gatewayKey: PropTypes.string,
  getGateways: PropTypes.func,
}

export default withRouter(withTranslation()(GatewayDrawer))
