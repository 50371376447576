import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Drawer } from "antd"
import {
  deleteCarephone,
  syncCarephoneData,
  updateCarephone,
} from "helpers/backend_helper"
import * as Yup from "yup"
import { useFormik } from "formik"
import SelectOrder from "components/DropdownList/SelectOrder"
import CarephoneServices from "pages/Services/carephone/carephone"
import { showToast } from "utils/general"
import withRouter from "components/Common/withRouter"

const CarephoneDrawer = props => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [update, setUpdate] = useState(false)
  const [ongoingSync, setOngoingSync] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Update client validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderNumber: props.selectedRow?.orderNumber || "",
      callbackTime: props.selectedRow?.callbackTime || "15",
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      setSuccess("")
      setError("")
      setUpdate(true)

      let temp = new Object()
      temp.orderNumber = values.orderNumber ? values.orderNumber : ""
      temp.callbackTime = parseInt(values.callbackTime, 10)

      //console.log("Send values: ", temp)

      updateCarephone(abortRef.current, props.selectedRow?.key, temp)
        .then(result => {
          setUpdate(false)
          if (result.statusCode == 200) {
            setSuccess("Updated")
            props.getCarephones()
          } else {
            setError("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setUpdate(false)
          setError("Error")
        })
    },
  })

  // Sync device data with Neat
  const syncDevice = () => {
    setOngoingSync(true)
    syncCarephoneData(abortRef.current, props.selectedRow?.key)
      .then(result => {
        setOngoingSync(false)
        if (result.statusCode == 200) {
          props.getCarephones()
          showToast(props.t("Success"), "success")
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        setOngoingSync(false)
        showToast(props.t("Error"), "error")
      })
  }

  // Delete device
  const deleteDevice = () => {
    if (
      !ongoingDelete &&
      props.selectedRow != null &&
      props.selectedRow.key.length > 0
    ) {
      setOngoingDelete(true)
      deleteCarephone(abortRef.current, props.selectedRow.key)
        .then(result => {
          if (result.statusCode == 200) {
            setDeleteModal(false)
            showToast(props.t("Deleted"), "success")
            props.setShowDrawer(false)
            props.getCarephones()
          } else {
            showToast(props.t("Error"), "error")
          }
          setOngoingDelete(false)
        })
        .catch(err => {
          setOngoingDelete(false)
          showToast(props.t("Error"), "error")
        })
    }
  }

  // Selected order changed
  const orderSelectionChanged = value => {
    validation.setFieldValue("orderNumber", value)
  }

  return (
    <>
      <Drawer
        title={props.t("Carephone") + " " + props.selectedRow?.serial}
        width={"80%"}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
          setError("")
          setSuccess("")
        }}
        open={props.showDrawer}
        zIndex={1003}
        // //bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        <div className="d-flex flex-wrap mb-3">
          <ul className="nav icon-tab">
            <NavItem>
              <NavLink
                active={activeTab === "1"}
                onClick={() => {
                  setActiveTab("1")
                }}
              >
                {props.t("Management panel")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === "2"}
                onClick={() => {
                  setActiveTab("2")
                }}
              >
                {props.t("Client view")}
              </NavLink>
            </NavItem>
          </ul>
        </div>

        <TabContent activeTab={activeTab}>
          <TabPane className="show" tabId="1">
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {props.t("Settings")}
                    </CardTitle>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        return false
                      }}
                    >
                      <Row xs={1}>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Callback time")}</Label>
                            <Input
                              bsSize="sm"
                              name="callbackTime"
                              type="number"
                              value={validation.values.callbackTime}
                              onChange={validation.handleChange}
                            ></Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Order number")}</Label>
                            <SelectOrder
                              selection={validation.values.orderNumber}
                              setSelectedOrder={orderSelectionChanged}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="mt-2 mb-3 d-flex justify-content-center">
                        <Button
                          color="primary"
                          disabled={update}
                          style={{ minWidth: "150px" }}
                          onClick={() => {
                            validation.submitForm()
                          }}
                        >
                          {props.t("Save")}
                        </Button>
                      </div>
                      {error ? (
                        <Alert color="danger">{props.t(error)}</Alert>
                      ) : null}
                      {success ? (
                        <Alert color="success">{props.t(success)}</Alert>
                      ) : null}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{props.t("Actions")}</CardTitle>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem className="pb-4">
                        <Row>
                          <Col>
                            <div>
                              <h6>{props.t("Sync device")}</h6>
                              <span className="text-muted">
                                {props.t("Get latest device data from API")}
                              </span>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              color="primary"
                              style={{ minWidth: "100px" }}
                              disabled={ongoingSync}
                              onClick={() => {
                                syncDevice()
                              }}
                            >
                              {props.t("Send")}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="pb-4">
                        <Row>
                          <Col>
                            <div>
                              <h6>{props.t("Deletion")}</h6>
                              <span className="text-muted">
                                {props.t(
                                  "The device and all related data will be deleted permanently"
                                )}
                              </span>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              color="danger"
                              style={{ minWidth: "100px" }}
                              onClick={() => {
                                setDeleteModal(true)
                              }}
                            >
                              {props.t("Delete")}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane className="show" tabId="2">
            {props.selectedRow?.key && (
              <CarephoneServices
                carephoneKey={props.selectedRow?.key}
                suvantoKey={
                  props.selectedRow?.links.length > 0
                    ? props.selectedRow.links[0].key
                    : null
                }
              />
            )}
          </TabPane>
        </TabContent>
      </Drawer>

      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody>
          <p>
            {props.t(
              "Are you sure you want to delete carephone with serial number"
            ) +
              " " +
              props.selectedRow?.serial +
              "?"}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={ongoingDelete}
            color="danger"
            onClick={() => {
              deleteDevice()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

CarephoneDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getCarephones: PropTypes.func,
}

export default withRouter(withTranslation()(CarephoneDrawer))
