import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  getHealthMeasurements,
  addManualMeasurement,
} from "helpers/backend_helper"
import { getLocale, showToast } from "utils/general"
import dayjs from "dayjs"
import {
  Alert,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Modal,
  Button,
  Input,
  NavItem,
  NavLink,
  Row,
  FormGroup,
  TabContent,
  TabPane,
  ModalFooter,
  ModalBody,
  UncontrolledTooltip,
  Form,
} from "reactstrap"
import { Link } from "react-router-dom"
import MeasureChart from "./components/measurement-chart"
import MeasureList from "./components/measurement-list"
import classnames from "classnames"
const { RangePicker } = DatePicker
//  antd
import { Switch, DatePicker, InputNumber, ConfigProvider } from "antd"
// Locale/weekday related imports
import "dayjs/locale/fi"
import "dayjs/locale/se"
import "dayjs/locale/en-gb"
// Formik Validation
import { useFormik } from "formik"

const MeasurementServices = props => {
  const [updating, setUpdating] = useState(false)

  const [journalKey, setJournalKey] = useState()
  const [refresh, setRefresh] = useState(true)
  // New entry modal
  const [modalMessage, setModalMessage] = useState(false)

  // Validation
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  // ActiveTab
  const [activeTab, setActiveTab] = useState("1")

  // Search button
  const [ongoingSearch, setOngoingSearch] = useState(false)

  // Date
  const [defaultStartDate] = useState(
    dayjs(dayjs().startOf("day")).add(-14, "days")
  )
  const [defaultEndDate, setDefaultEndDate] = useState(dayjs())
  const [start, setStart] = useState(defaultStartDate)
  const [end, setEnd] = useState(defaultEndDate)

  // Types of measurements
  const [bloodPressure, setBloodPressure] = useState([])
  const [weight, setWeight] = useState([])
  const [pef, setPef] = useState([])
  const [bgluk, setBgluk] = useState([])
  const [temp, setTemp] = useState([])
  const [spo2, setSpo2] = useState([])
  // Types of measurements list
  const [bloodPressureList, setBloodPressureList] = useState([])
  const [weightList, setWeightList] = useState([])
  const [pefList, setPefList] = useState([])
  const [bglukList, setBglukList] = useState([])
  const [tempList, setTempList] = useState([])
  const [spo2List, setSpo2List] = useState([])
  // Length
  const [bloodPressureL, setBloodPressureL] = useState(0)
  const [weightL, setWeightL] = useState(0)
  const [pefL, setPefL] = useState(0)
  const [bglukL, setBglukL] = useState(0)
  const [tempL, setTempL] = useState(0)
  const [spo2L, setSpo2L] = useState(0)

  // Measurement entry
  const [newType, setNewType] = useState("bloodPressure")
  const [type, setType] = useState("")
  const [newDate, setNewDate] = useState(dayjs())
  const [newValue1, setNewValue1] = useState()
  const [newValue2, setNewValue2] = useState()
  const [newValue3, setNewValue3] = useState()
  const [newUnit, setNewUnit] = useState("mmHg")
  const [newUnit2, setNewUnit2] = useState("bpm")
  const [newTitle, setNewTitle] = useState("Systolic and diastolic pressure")
  const [newTitle2, setNewTitle2] = useState("Pulse")
  const [newMin, setNewMin] = useState(50)
  const [newMax, setNewMax] = useState(250)
  const [newMin2, setNewMin2] = useState(1)
  const [newMax2, setNewMax2] = useState(150)
  const [newMin3, setNewMin3] = useState(30)
  const [newMax3, setNewMax3] = useState(200)

  // Disable button when saving
  const [saveDisable, setSaveDisable] = useState(false)

  const locale = getLocale()
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    getMeasures()
    return () => abortRef.current.abort()
  }, [])

  // Get measurements from server
  const getMeasures = newEnd => {
    if (props.suvantoKey && !updating) {
      setUpdating(true)
      /** TYPE LIST - empty or null to get all types
       * bloodSugar
       * perfusion
       * oxygenSaturation
       * temperature
       * diastolicBloodPressure
       * systolicBloodPressure
       * pulse
       * weight
       * peakExpiratoryFlow
       * FEV1
       */
      let endTime = dayjs()
      if (newEnd !== undefined) {
        endTime = newEnd
      }
      if (newEnd == undefined) {
        endTime = end
      }

      getHealthMeasurements(
        abortRef.current,
        props.suvantoKey,
        "",
        start,
        endTime
      )
        .then(result => {
          console.log("Measures: ", result)
          if (result.statusCode == 200 && result.entries) {
            result.entries.sort((a, b) => (a.date < b.date ? 1 : -1))
            settingCategories(result.entries)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdating(false)
          setOngoingSearch(false)
        })
    }
  }
  useEffect(() => {
    if (props.suvantoKey !== undefined) {
      setJournalKey(props.suvantoKey)
    }
  }, [props.suvantoKey])

  const settingCategories = data => {
    setBloodPressure([])
    setBgluk([])
    setPef([])
    setSpo2([])
    setTemp([])
    setWeight([])

    if (data.length != 0) {
      // RR+p
      let bpp = data.filter(
        d =>
          d.measureType === "systolicBloodPressure" ||
          d.measureType === "diastolicBloodPressure" ||
          d.measureType === "pulse"
      )
      let bpMeasure = bpp
      let res = Object.values(
        bpMeasure.reduce((acc, cur) => {
          if (!acc[cur.date]) acc[cur.date] = { date: cur.date, data: [] }
          acc[cur.date].data.push(cur)
          return acc
        }, {})
      )
      setBloodPressureList(res)
      setBloodPressureL(res.length)
      let bppLength = data.filter(d => d.deviceType === "bloodPressure").length

      let sys = data.filter(
        d => d.measureType === "systolicBloodPressure"
      ).length
      let dia = data.filter(
        d => d.measureType === "diastolicBloodPressure"
      ).length

      // Temperature
      let tempLength = data.filter(d => d.measureType === "temperature").length
      setTempL(tempLength)
      // Weight
      let scaleLength = data.filter(d => d.measureType === "weight").length
      setWeightL(scaleLength)
      // SpO2
      let oxiLength = data.filter(
        d => d.measureType == "oxygenSaturation"
      ).length
      setSpo2L(oxiLength)
      // B-gluk
      let glucLength = data.filter(d => d.measureType === "bloodSugar").length
      setBglukL(glucLength)
      // PEF
      let pefLength = data.filter(
        d => d.measureType === "peakExpiratoryFlow"
      ).length
      setPefL(pefLength)

      // Formatting datas
      if (bppLength > 0) {
        let systolic = data.filter(
          d => d.measureType === "systolicBloodPressure"
        )
        let datas1 = systolic.map(item => [item.date, item.value])

        let diastolic = data.filter(
          d => d.measureType === "diastolicBloodPressure"
        )
        let datas2 = diastolic.map(item => [item.date, item.value])
        let pulse = data.filter(d => d.measureType == "pulse")
        let datas3 = pulse.map(item => [item.date, item.value])

        let set1 = [
          {
            name: props.t("Systolic"),
            data: datas1,
            type: setDiagramType(sys),
          },
        ]
        let set2 = [
          {
            name: props.t("Diastolic"),
            type: setDiagramType(dia),
            data: datas2,
          },
        ]
        let set3 = [
          {
            name: props.t("Pulse"),
            type: "line",
            data: datas3,
          },
        ]
        setBloodPressure([...set1, ...set2, ...set3])
      }
      if (tempLength > 0) {
        let temp = data.filter(d => d.measureType == "temperature")
        setTempList(temp)

        let datas6 = temp.map(item => [item.date, item.value])
        setTemp([
          {
            name: props.t("Temperature"),
            data: datas6,
            type: setDiagramType(tempLength),
          },
        ])
      }
      if (scaleLength > 0) {
        let weight = data.filter(d => d.measureType == "weight")
        setWeightList(weight)

        let datas4 = weight.map(item => [item.date, item.value])
        setWeight([
          {
            name: props.t("Weight"),
            data: datas4,
            type: setDiagramType(scaleLength),
          },
        ])
      }
      if (oxiLength > 0) {
        let spo2 = data.filter(d => d.measureType == "oxygenSaturation")
        setSpo2List(spo2)

        let datas5 = spo2.map(item => [item.date, item.value])
        setSpo2([
          {
            name: props.t("Oxygen saturation"),
            data: datas5,
            type: setDiagramType(oxiLength),
          },
        ])
      }
      if (glucLength > 0) {
        let bgluk = data.filter(d => d.measureType == "bloodSugar")
        setBglukList(bgluk)

        let datas3 = bgluk.map(item => [item.date, item.value])
        setBgluk([
          {
            name: props.t("Blood sugar"),
            data: datas3,
            type: setDiagramType(glucLength),
          },
        ])
      }
      if (pefLength > 0) {
        let pef = data.filter(
          d =>
            d.measureType === "peakExpiratoryFlow" || d.measureType === "FEV1"
        )
        setPefList(pef)

        let datas7 = pef.map(item => [item.date, item.value, item.measureType])

        setPef([
          {
            name: props.t("Peak expiratory flow"),
            data: datas7
              .filter(item => item[2] === "peakExpiratoryFlow")
              .map(([date, value]) => [date, value]),
            type: setDiagramType(pefLength),
          },
          {
            name: props.t("FEV1"),
            data: datas7
              .filter(item => item[2] === "FEV1")
              .map(([date, value]) => [date, value]),
            type: setDiagramType(pefLength),
          },
        ])
      }
    }
  }

  const setDiagramType = t => {
    if (t > 2) {
      return "area"
    } else {
      return "line"
    }
  }

  const onDateSelection = value => {
    if (value != null) {
      let start = new Date(value[0])
      let startUnix = start.getTime()

      let end = new Date(value[1])
      let endUnix = end.getTime()

      setStart(startUnix)
      setEnd(endUnix)
    }
  }

  const updateLog = () => {
    setOngoingSearch(true)
    getMeasures()
  }

  const load = (
    <div className="text-center py-5 my-3">
      <Link to="#" className="text-primary">
        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
        {props.t("Loading")}
      </Link>
    </div>
  )

  const presets = {
    day: () => [dayjs().subtract(1, "day").startOf("day"), dayjs()],
    week: () => [dayjs().subtract(1, "week").startOf("day"), dayjs()],
    twoWeeks: () => [dayjs().subtract(2, "weeks").startOf("day"), dayjs()],
    month: () => [dayjs().subtract(1, "month").startOf("day"), dayjs()],
    year: () => [dayjs().subtract(1, "year").startOf("day"), dayjs()],
  }

  const rangeButtons = {
    [props.t("day")]: presets.day(),
    [props.t("7 days")]: presets.week(),
    [props.t("14 days")]: presets.twoWeeks(),
    [props.t("month")]: presets.month(),
    [props.t("year")]: presets.year(),
  }

  // User can not pick a date further than end of the day
  const disabledDate = current => {
    const from = dayjs().subtract(dayjs(), "days")
    return (
      current &&
      (current > dayjs().endOf("day") || current < from.startOf("day"))
    )
  }

  //  ** START OF MODAL FUNCTIONS ** //

  const settingRefresh = () => {
    setRefresh(!refresh)
  }

  const toggleTab = t => {
    setActiveTab(t)

    switch (t) {
      case "1":
        handleChangeType("bloodPressure")
        break
      case "2":
        handleChangeType("bloodSugar")
        break
      case "3":
        handleChangeType("weight")
        break
      case "4":
        handleChangeType("oxygenSaturation")
        break
      case "5":
        handleChangeType("temperature")
        break
      case "6":
        handleChangeType("peakExpiratoryFlow")
        break
    }
  }

  const settingDate = e => {
    let date = e.valueOf()
    setNewDate(date)
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const toggleMessageModal = () => {
    removeBodyCss()
    setModalMessage(!modalMessage)
  }

  const handleChangeType = e => {
    setNewType(e)

    switch (e) {
      case "bloodPressure":
      case "pulse":
        setNewUnit("mmHg")
        setNewUnit2("bpm")
        setValue1()
        setValue2()
        setValue3()
        setNewTitle("Systolic and diastolic pressure")
        setNewTitle2("Pulse")
        setNewMin(81)
        setNewMax(200)
        setNewMin2(40)
        setNewMax2(80)
        setNewMin3(30)
        setNewMax3(200)
        break

      case "oxygenSaturation":
        setNewUnit("%")
        setValue1(100)
        setNewValue2(null)
        setNewTitle("Oxygen saturation")
        setNewMin(70)
        setNewMax(100)
        break

      case "temperature":
        setNewUnit("°C")
        setValue1(37)
        setNewValue2(null)
        setNewTitle("Temperature")
        setNewMin(30)
        setNewMax(45)
        break

      case "bloodSugar":
        setNewUnit("mmol/l")
        setValue1(6)
        setNewValue2(null)
        setNewTitle("Blood sugar")
        setNewMin(0)
        setNewMax(50)
        break

      case "weight":
        setNewUnit("kg")
        setValue1(70)
        setNewValue2(null)
        setNewTitle("Weight")
        setNewMin(0)
        setNewMax(300)
        break

      case "peakExpiratoryFlow":
        setNewUnit("l/min")
        setNewUnit2("l/s")
        setValue1(500)
        setValue2(4)
        setNewTitle("PEF")
        setNewTitle2("FEV1" + " " + "(" + props.t("optional") + ")")
        setNewMin(1)
        setNewMax(1000)
        setNewMin2(0.5)
        setNewMax2(10)
        break

      default:
        break
    }
  }

  const setValue1 = e => {
    setNewValue1(e)
  }
  const setValue2 = e => {
    setNewValue2(e)
  }
  const setValue3 = e => {
    setNewValue3(e)
  }

  // Reset messages when modal closed
  useEffect(() => {
    setSuccessMessage(null)
    setErrorMessage(null)
  }, [modalMessage])

  const submitPulseAfterNormal = useRef(false)

  // Handle saving measurements button
  const handleSave = () => {
    // Normal measurement submission
    if (
      (!isNaN(newValue1) && newValue1 !== 0 && newValue1 !== null) ||
      (!isNaN(newValue2) && newValue2 !== 0 && newValue2 !== null)
    ) {
      setType(newType)
      validationMeasurement.submitForm()

      // If pulse value is entered, send flag to resubmit data for pulse
      if (
        !isNaN(newValue3) &&
        newValue3 !== 0 &&
        newValue3 !== null &&
        newValue3 !== undefined
      ) {
        submitPulseAfterNormal.current = true
      }

      // No bloodpressure submitted but pulse was
    } else if (!isNaN(newValue3) && newValue2 !== 0) {
      setNewValue2(null)
      setNewValue1(newValue3)
      setType("pulse")
      setNewUnit("bpm")
      // Submit only pulse
      validationMeasurement.submitForm()
    }
  }

  const validationMeasurement = useFormik({
    enableReinitialize: true,
    initialValues: {
      newValue1: newValue1,
      newValue2: newValue2,
      newValue3: newValue3,
    },
    onSubmit: values => {
      setErrorMessage(null)
      setSuccessMessage(null)
      setSaveDisable(true)

      addManualMeasurement(
        abortRef.current,
        journalKey,
        type,
        newDate,
        values.newValue1,
        values.newValue2,
        newUnit
      )
        .then(result => {
          if (result.statusCode != null && result.statusCode == 200) {
            setSuccessMessage("Success")
            removeBodyCss()
            setModalMessage(false)
            if (refresh && !submitPulseAfterNormal.current) {
              // Refresh data compleatly by remounting component
              setTimeout(() => {
                if (newType == "bloodPressure" || newType == "pulse") {
                  props.handleRemount()
                } else {
                  setDefaultEndDate(dayjs())
                  getMeasures(dayjs())
                }
              }, 2000)
            }
          } else {
            setErrorMessage("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setErrorMessage("Error")
        })
        .finally(() => {
          setSaveDisable(false)

          // Pulse was submitted with bloodPressure
          if (submitPulseAfterNormal.current) {
            // Set values for pulse
            setNewValue2(null)
            setNewValue1(newValue3)
            setType("pulse")
            setNewUnit("bpm")

            // Small timeout to avoid conflicts with async operations
            setTimeout(() => {
              // Re-trigger the form submission
              validationMeasurement.submitForm()
              submitPulseAfterNormal.current = false
              setType(newType)
              toggleMessageModal()
            }, 50)
          }
        })
    },
  })

  // User can not pick a date further dayjs
  const disabledDateModal = current => {
    return current && current > dayjs().startOf("minutes")
  }

  // Clear measure values when opening modal to avoid confusion caused by double submitting blodopressure and pulse
  useEffect(() => {
    if (modalMessage && (newType == "bloodPressure" || newType == "pulse)")) {
      setNewValue1(null)
      setNewValue2(null)
      setNewValue3(null)
    }
  }, [modalMessage])

  //*** END OF MODAL FUNCTIONS ***//

  return (
    <>
      <div>
        <Card className="mt-1">
          <ul className="nav nav-tabs nav-tabs-custom justify-content-center pt-2">
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                {props.t("Blood pressure and pulse")}
                {bloodPressureL !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">
                    {bloodPressureL}
                  </span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                {props.t("Blood sugar")}
                {bglukL !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">
                    {bglukL}
                  </span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3")
                }}
              >
                {props.t("Weight")}
                {weightL !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">
                    {weightL}
                  </span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "4",
                })}
                onClick={() => {
                  toggleTab("4")
                }}
              >
                {props.t("Oxygen saturation")}
                {spo2L !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">{spo2L}</span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "5",
                })}
                onClick={() => {
                  toggleTab("5")
                }}
              >
                {props.t("Temperature")}
                {tempL !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">{tempL}</span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "6",
                })}
                onClick={() => {
                  toggleTab("6")
                }}
              >
                {props.t("Peak expiratory flow")}
                {pefL !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">{pefL}</span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
          </ul>
          <CardBody>
            <div className="ms-2">
              <ConfigProvider locale={locale}>
                <RangePicker
                  defaultValue={[defaultStartDate, defaultEndDate]}
                  disabledDate={disabledDate}
                  allowClear={false}
                  onChange={onDateSelection}
                  format="DD.MM.YYYY"
                  presets={{ ...rangeButtons }}
                />
              </ConfigProvider>
              <span>
                <Button
                  className="ms-2"
                  color="primary"
                  disabled={ongoingSearch}
                  onClick={() => {
                    updateLog()
                  }}
                >
                  <i className="bx bx-search" />
                  <span className="d-none d-sm-inline-block ms-2">
                    {props.t("Search")}
                  </span>
                </Button>
              </span>
              <span className="float-end">
                <Button
                  type="button"
                  color="success"
                  onClick={() => toggleMessageModal()}
                  className="btn btn-primary mb-2 "
                >
                  <i className="mdi mdi-clipboard-plus-outline" />
                  <span className="d-none d-sm-inline-block ms-2">
                    {props.t("Add measurement")}
                  </span>
                </Button>
              </span>
            </div>
          </CardBody>

          {/* Save remote measurement */}
          <Modal
            isOpen={modalMessage}
            toggle={() => {
              toggleMessageModal()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {props.t("Add entry")}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalMessage(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <Form
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <div>
                  <div className="mt-2">
                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <h6>{props.t("Measurement type")}</h6>
                          <Input
                            name="type"
                            type="select"
                            defaultValue={newType}
                            onChange={e => handleChangeType(e.target.value)}
                          >
                            <option value={"bloodPressure"}>
                              {props.t("Blood pressure and pulse")}
                            </option>
                            {/* <option value={"pulse"}>{props.t("Pulse")}</option> */}
                            <option value={"oxygenSaturation"}>
                              {props.t("Oxygen saturation")}
                            </option>
                            <option value={"temperature"}>
                              {props.t("Temperature")}
                            </option>
                            <option value={"bloodSugar"}>
                              {props.t("Blood sugar")}
                            </option>
                            <option value={"weight"}>
                              {props.t("Weight")}
                            </option>
                            <option value={"peakExpiratoryFlow"}>
                              {props.t("Peak expiratory flow (PEF)")}
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs={6}>
                        <h6>{props.t("Time of measurement")}</h6>
                        <DatePicker
                          showTime={true}
                          defaultValue={dayjs(newDate)}
                          format="DD.MM.YYYY HH:mm"
                          style={{ height: "36.5px" }}
                          onChange={settingDate}
                          disabledDate={disabledDateModal}
                        />
                      </Col>
                    </Row>
                  </div>
                  {errorMessage ? (
                    <Alert color="danger">{props.t(errorMessage)}</Alert>
                  ) : null}
                  {successMessage ? (
                    <Alert color="success">{props.t(successMessage)}</Alert>
                  ) : null}
                  {(newType === "bloodPressure" || newType === "pulse") && (
                    <Alert color="info">
                      {props.t("measurementTypesInfo")}
                    </Alert>
                  )}

                  {/* Primary input */}
                  {newType !== "" && (
                    <div className="mt-4">
                      <Row>
                        <Col xs={6}>
                          <h6>{props.t(newTitle)}</h6>
                          <div className="d-flex align-items-center">
                            <InputNumber
                              step={
                                newType === "bloodSugar" ||
                                newType === "weight" ||
                                newType === "temperature"
                                  ? 0.1
                                  : 1
                              }
                              type="number"
                              name="newValue1"
                              defaultValue={newValue1}
                              style={{ width: "70px" }}
                              size="small"
                              min={newMin}
                              max={newMax}
                              onChange={setValue1}
                              onBlur={validationMeasurement.handleBlur}
                              value={
                                validationMeasurement.values.newValue1 || ""
                              }
                              formatter={
                                value =>
                                  newType !== "bloodSugar" &&
                                  newType !== "weight" &&
                                  newType !== "temperature" &&
                                  value
                                    ? value.replace(/\.[^\.]*$/, "") // Remove decimals
                                    : value.replace(/^(\d+\.\d?).*$/, "$1") // Allow one decimal
                              }
                              parser={
                                value =>
                                  newType !== "bloodSugar" &&
                                  newType !== "weight" &&
                                  newType !== "temperature"
                                    ? value.replace(/\.[^\.]*$/, "") // Remove decimals
                                    : value.replace(/^(\d+\.\d?).*$/, "$1") // Allow one decimal
                              }
                              invalid={
                                validationMeasurement.touched.newValue1 &&
                                validationMeasurement.errors.newValue1
                              }
                            />
                            {validationMeasurement.touched.newValue1 &&
                            validationMeasurement.errors.newValue1 ? (
                              <FormFeedback type="invalid">
                                {validationMeasurement.errors.newValue1}
                              </FormFeedback>
                            ) : null}

                            {/* Secondary input */}
                            {(newType === "bloodPressure" ||
                              newType === "pulse") && (
                              <>
                                <span className="ms-2 me-2 font-size-16 text-muted">
                                  /
                                </span>
                                <InputNumber
                                  name="newValue2"
                                  defaultValue={newValue2}
                                  style={{ width: "70px" }}
                                  size="small"
                                  min={newMin2}
                                  max={newMax2}
                                  onChange={setValue2}
                                  onBlur={validationMeasurement.handleBlur}
                                  value={
                                    validationMeasurement.values.newValue2 || ""
                                  }
                                  formatter={
                                    value =>
                                      newType !== "bloodSugar" &&
                                      newType !== "weight" &&
                                      newType !== "temperature" &&
                                      value
                                        ? value.replace(/\.[^\.]*$/, "") // Remove decimals
                                        : value.replace(/^(\d+\.\d?).*$/, "$1") // Allow one decimal
                                  }
                                  parser={
                                    value =>
                                      newType !== "bloodSugar" &&
                                      newType !== "weight" &&
                                      newType !== "temperature"
                                        ? value.replace(/\.[^\.]*$/, "") // Remove decimals
                                        : value.replace(/^(\d+\.\d?).*$/, "$1") // Allow one decimal
                                  }
                                  invalid={
                                    validationMeasurement.touched.newValue2 &&
                                    validationMeasurement.errors.newValue2
                                  }
                                />
                                {validationMeasurement.touched.newValue2 &&
                                validationMeasurement.errors.newValue2 ? (
                                  <FormFeedback type="invalid">
                                    {validationMeasurement.errors.newValue2}
                                  </FormFeedback>
                                ) : null}
                              </>
                            )}
                            <span className="mt-1 text-muted ms-2">
                              {newUnit}
                            </span>
                          </div>
                        </Col>

                        {/* Pulse input */}
                        {(newType === "bloodPressure" ||
                          newType === "pulse") && (
                          <Col xs={6}>
                            <h6>{props.t(newTitle2)}</h6>
                            <div className="d-flex align-items-center">
                              <InputNumber
                                type="number"
                                name="newValue3"
                                defaultValue={newValue3}
                                style={{ width: "70px" }}
                                size="small"
                                min={newMin3}
                                max={newMax3}
                                onChange={setNewValue3}
                                onBlur={validationMeasurement.handleBlur}
                                value={
                                  validationMeasurement.values.newValue3 || ""
                                }
                                formatter={
                                  value => value.replace(/\.[^\.]*$/, "") // Remove decimals
                                }
                                parser={
                                  value => value.replace(/\.[^\.]*$/, "") // Remove decimals
                                }
                                invalid={
                                  validationMeasurement.touched.newValue3 &&
                                  validationMeasurement.errors.newValue3
                                }
                              />
                              {validationMeasurement.touched.newValue3 &&
                              validationMeasurement.errors.newValue3 ? (
                                <FormFeedback type="invalid">
                                  {validationMeasurement.errors.newValue3}
                                </FormFeedback>
                              ) : null}
                              <span className="mt-1 text-muted ms-2">
                                {newUnit2}
                              </span>
                            </div>
                          </Col>
                        )}

                        {/* FEV1 input */}
                        {newType === "peakExpiratoryFlow" && (
                          <Col xs={6}>
                            <h6>{props.t(newTitle2)}</h6>
                            <div className="d-flex align-items-center">
                              <InputNumber
                                step={0.1}
                                type="number"
                                name="newValue2"
                                defaultValue={newValue2}
                                style={{ width: "70px" }}
                                size="small"
                                min={newMin2}
                                max={newMax2}
                                onChange={setNewValue2}
                                onBlur={validationMeasurement.handleBlur}
                                value={
                                  validationMeasurement.values.newValue2 || ""
                                }
                                formatter={
                                  value => value.replace(/\.[^\.]*$/, "") // Remove decimals
                                }
                                parser={
                                  value => value.replace(/\.[^\.]*$/, "") // Remove decimals
                                }
                                invalid={
                                  validationMeasurement.touched.newValue2 &&
                                  validationMeasurement.errors.newValue2
                                }
                              />
                              {validationMeasurement.touched.newValue2 &&
                              validationMeasurement.errors.newValue2 ? (
                                <FormFeedback type="invalid">
                                  {validationMeasurement.errors.newValue2}
                                </FormFeedback>
                              ) : null}
                              <span className="mt-1 text-muted ms-2">
                                {newUnit2}
                              </span>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter className="p-2 mt-4">
                <Col lg={12} className="mt-2 mb-0 text-truncate">
                  <Row>
                    <Col xs={6}>
                      <FormGroup className="ms-2 mt-2">
                        <Switch
                          name="refresh-switch"
                          className="me-1 ms-1"
                          id="refresh-button"
                          checked={refresh}
                          onChange={settingRefresh}
                          checkedChildren={
                            <i className="mdi mdi mdi-sync align-middle font-size-14" />
                          }
                          unCheckedChildren={
                            <i className="mdi mdi-sync-off align-middle font-size-14" />
                          }
                        ></Switch>
                      </FormGroup>
                      <UncontrolledTooltip
                        placement="right"
                        target="refresh-button"
                      >
                        {props.t("Refresh entries on save")}
                      </UncontrolledTooltip>
                    </Col>
                    <Col xs={6}>
                      <span className="float-end">
                        <button
                          type="button"
                          onClick={() => {
                            toggleMessageModal()
                          }}
                          className="btn btn-outline-primary "
                          data-dismiss="modal"
                        >
                          {props.t("Cancel")}
                        </button>

                        <button
                          disabled={
                            saveDisable ||
                            (newValue1 == null &&
                              newValue2 == null &&
                              newValue3 == null)
                          }
                          className="btn btn-primary ms-2"
                          onClick={() => handleSave()}
                        >
                          {props.t("Save entry")}
                        </button>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </ModalFooter>
            </Form>
          </Modal>

          <TabContent className="py-0 px-3" activeTab={activeTab}>
            {/* bloodPressure */}
            <TabPane tabId="1">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {bloodPressure.length !== 0 ? (
                    <div>
                      <Row>
                        <Col lg={8}>
                          <MeasureChart
                            data={bloodPressure}
                            type={activeTab}
                            tooltip={""}
                            max={250}
                            start={start}
                            end={end}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasureList
                            bloodPressure={bloodPressureList}
                            type={activeTab}
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5 text-center">
                      {props.t("No blood pressure or pulse measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
            {/* bloodSugar */}
            <TabPane tabId="2">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {bgluk.length !== 0 ? (
                    <div>
                      <Row className="">
                        <Col lg={8}>
                          <MeasureChart
                            data={bgluk}
                            type={activeTab}
                            tooltip={"mmol/l"}
                            max={20}
                            start={start}
                            end={end}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasureList data={bglukList} type={activeTab} />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5  text-center">
                      {props.t("No blood sugar measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
            {/* weight */}
            <TabPane tabId="3">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {weight != [] && weight.length !== 0 ? (
                    <div>
                      <Row>
                        <Col lg={8}>
                          <MeasureChart
                            data={weight}
                            type={activeTab}
                            max={120}
                            tooltip={"kg"}
                            start={start}
                            end={end}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasureList data={weightList} type={activeTab} />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5  text-center">
                      {props.t("No weight measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
            {/* oxygenSaturation */}
            <TabPane tabId="4">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {spo2.length !== 0 ? (
                    <div>
                      <Row>
                        <Col lg={8}>
                          <MeasureChart
                            data={spo2}
                            type={activeTab}
                            min={70}
                            max={100}
                            tooltip={"%"}
                            start={start}
                            end={end}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasureList data={spo2List} type={activeTab} />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5 text-center">
                      {props.t("No oxygen saturation measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
            {/* temperature */}
            <TabPane tabId="5">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {temp.length !== 0 ? (
                    <div>
                      <Row>
                        <Col lg={8}>
                          <MeasureChart
                            data={temp}
                            type={activeTab}
                            min={30}
                            max={45}
                            tooltip={"°C"}
                            start={start}
                            end={end}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasureList data={tempList} type={activeTab} />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5 text-center">
                      {props.t("No temperature measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
            {/* peakExpiratoryFlow */}
            <TabPane tabId="6">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {pef.length !== 0 ? (
                    <div>
                      <Row>
                        <Col lg={8}>
                          <MeasureChart
                            data={pef}
                            type={activeTab}
                            max={1000}
                            start={start}
                            end={end}
                            tooltip={""}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasureList
                            peakExpiratoryFlow={pefList}
                            type={activeTab}
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5 text-center">
                      {props.t("No PEF measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
          </TabContent>
        </Card>
      </div>
    </>
  )
}

MeasurementServices.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.string,
  handleRemount: PropTypes.any,
}

export default withTranslation()(MeasurementServices)
