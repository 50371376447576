/***********************
 * INDEX TAB9 CONTENT
 * (GROUPVIDEOLICENSES)
 ************************/

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"

// Locale
import { withTranslation } from "react-i18next"

// Importing components
import TableGroupLicenses from "./components/licenses-table"
import ChartLicenses from "./components/licenses-chart"

const GroupVideoLicensesTabReporting = props => {
  const [callsData, setCallsData] = useState([])

  // Filter view related states
  const [activeTab] = useState("1")

  useEffect(() => {
    if (props.activeTab == 9) {
      //console.log("Group calls data changed: " + JSON.stringify(props.data))
      setCallsData(props.data)
    }
  }, [props.data, props.activeTab])

  return (
    <React.Fragment>
      <Row>
        <Col>
          {activeTab == "1" && props.data !== undefined && (
            <div className="mt-2 mb-1">
              <Row>
                <ChartLicenses data={callsData} />
              </Row>
              <Row>
                <TableGroupLicenses
                  minRows={0}
                  selectedType={props.selectedType}
                  data={callsData}
                />
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

GroupVideoLicensesTabReporting.propTypes = {
  data: PropTypes.any,
  activeTab: PropTypes.any,
  selectedType: PropTypes.any,
}

export default withTranslation()(GroupVideoLicensesTabReporting)
