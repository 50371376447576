import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  CardImg,
} from "reactstrap"

//Translation
import { withTranslation } from "react-i18next"
import moment from "moment"
import pindoraImage2 from "../../../assets/images/devices/pindora-door.jpg"
import {
  getSmartlockData,
  openSmartlock,
  getJournalEntries,
} from "helpers/backend_helper"
import Lottie from "react-lottie"
import StatusCardHeader from "../status-card-header"
import PubSub from "pubsub-js"
import { getLottieLoadingOptions, getLottieNothingFound, showToast } from "utils/general"

const SmartlockServices = props => {
  const abortRef = useRef(null)
  const [smartlock, setSmartlock] = useState(null)
  const [events, setEvents] = useState([])
  const [openRequestLoading, setOpenRequestLoading] = useState(false)
  const [eventsLoading, setEventsLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [openRequestModal, setOpenRequestModal] = useState(false)
  const [open, setOpen] = useState("")
  const [accordionText, setAccordionText] = useState("Show all device info")

  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getData()
      getEvents()
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  // Smartlock key changed
  useEffect(() => {
    getData()
  }, [props.smartlockKey])

  useEffect(() => {
    getEvents()
  }, [props.suvantoKey])

  // Get smartlock data
  const getData = () => {
    if (props.smartlockKey && !dataLoading) {
      setDataLoading(true)
      getSmartlockData(abortRef.current, props.smartlockKey)
        .then(result => {
          console.log(result)
          if (result.statusCode == 200 && result.data) {
            result.data.wifiTimeNice = moment(result.data.wifiChanged).format(
              "DD.MM.YYYY HH:mm:ss"
            )
            result.data.wifiDescription = "Unknown"
            if (result.data.wifiSignalStrength < -80)
              result.data.wifiDescription = "Weak"
            else if (result.data.wifiSignalStrength < -65)
              result.data.wifiDescription = "Moderate"
            else if (result.data.wifiSignalStrength < -50)
              result.data.wifiDescription = "Good"
            else result.data.wifiDescription = "Great"

            result.data.doorTimeNice = moment(result.data.magnetChanged).format(
              "DD.MM.YYYY HH:mm:ss"
            )
            result.data.doorState = "Unknown"
            if (result.data.magnetState == 1)
              result.data.doorState = "StateClosed"
            else if (result.data.magnetState == 0)
              result.data.doorState = "StateOpen"

            setSmartlock(result.data)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setDataLoading(false)
        })
    }
  }

  // Get door events
  const getEvents = () => {
    if (props.suvantoKey && !eventsLoading) {
      setEventsLoading(true)
      let to = new Date()
      let from = moment().subtract(1, "month")
      getJournalEntries(
        abortRef.current,
        props.suvantoKey,
        from,
        to,
        10,
        "smartlock",
        true
      )
        .then(data => {
          //console.log("EVENTS: ", data)
          if (data.entries) {
            let responses = []
            data.entries.forEach(element => {
              if (element.responses.length > 0) {
                element.responses.forEach(resp => {
                  responses.push(resp)
                })
              }
            })
            data.entries = [...data.entries, ...responses]
            data.entries.sort((a, b) => (a.date < b.date ? 1 : -1))

            data.entries.forEach(element => {
              element.dateNice = moment(element.date).format(
                "DD.MM.YYYY HH:mm:ss"
              )

              switch (element.subType) {
                case "open_permission":
                  element.message = props.t("Open permission granted")
                  break
                case "door_open":
                  element.message = props.t("Door opened")
                  break
                case "door_close":
                  element.message = props.t("Door closed")
                  break
              }
            })
            setEvents(data.entries)
          } else {
            setEvents([])
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setEventsLoading(false)
        })
    }
  }

  // Open door
  const openDoor = () => {
    setOpenRequestModal(false)
    setOpenRequestLoading(true)
    openSmartlock(abortRef.current, props.suvantoKey)
      .then(result => {
        //console.log("DOOR RESULT: ", result)
        setOpenRequestLoading(false)
        if (result.status == "permission_granted") {
          showToast(props.t("Smartlock knock"), "success", 60000, "toast-bottom-center")
        } else {
          showToast(props.t("Smartlock error"), "error", 10000, "toast-bottom-center")
        }
      })
      .catch(err => {
        console.log(err)
        setOpenRequestLoading(false)
      })
  }

  const toggleInfo = id => {
    if (open === id) {
      setOpen("")
      setAccordionText("Show all device info")
    }
    else {
      setOpen(id)
      setAccordionText("Hide info")
    }
  }

  return (
    <>
      <Modal
        isOpen={openRequestModal}
        toggle={() => {
          setOpenRequestModal(!openRequestModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Open door")}</ModalHeader>
        <ModalBody>
          <p>{props.t("Smartlock open description")}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              openDoor()
            }}
          >
            {props.t("Open door")}
          </Button>
          <Button
            color="primary"
            outline
            onClick={() => {
              setOpenRequestModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
      <div>
        {smartlock && (
          <div className="mt-1">
            <Card style={{ borderRadius: "40px 0 0 0" }}>
              <StatusCardHeader
                service="smartlock"
                status={smartlock.status}
                battery={smartlock.batteryValue}
                date={smartlock.lastSeenAt}
              />
              <CardBody style={{ padding: "0px" }}>
                <Accordion flush open={open} toggle={toggleInfo}>
                  <AccordionItem>
                    <AccordionBody
                      accordionId="1"
                      className="pt-0"
                      style={{ paddingTop: "0px" }}
                    >
                      <Row xs={1} sm={2} lg={3} className="mt-3">
                        <Col>
                          <div className="text-muted mb-4 d-grid">
                            <h6>{props.t("Door state")}:</h6>{" "}
                            {props.t(smartlock.doorState)}
                            <span>{smartlock.doorTimeNice}</span>
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4 d-grid">
                            <h6>{props.t("Wifi signal strength")}:</h6>{" "}
                            {props.t(smartlock.wifiDescription)}
                            <span>{smartlock.wifiTimeNice}</span>
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Last open request")}:</h6>{" "}
                            {moment(smartlock.lastOpenPermission).format(
                              "DD.MM.YYYY HH:mm:ss"
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AccordionBody>
                    <AccordionHeader targetId="1">
                      {props.t(accordionText)}
                    </AccordionHeader>
                  </AccordionItem>
                </Accordion>
              </CardBody>
            </Card>

            <Row xs={1} xl={2}>
              <Col className="order-2 order-xl-1">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {props.t("Recent events")}
                    </CardTitle>
                    {eventsLoading && (
                      <Lottie
                        options={getLottieLoadingOptions()}
                        height={100}
                        width={100}
                      />
                    )}
                    {!eventsLoading && events.length > 0 && (
                      <ul className="list-group list-group-flush">
                        {events.map(event => (
                          <li key={event.key} className="list-group-item py-3">
                            <Row>
                              <Col>
                                <div>
                                  <h6>{event.fullName}</h6>
                                  <span className="text-muted">
                                    {event.dateNice}
                                  </span>
                                </div>
                              </Col>
                              <Col
                                className="col-auto d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <span>{event.message}</span>
                              </Col>
                            </Row>
                          </li>
                        ))}
                      </ul>
                    )}
                    {!eventsLoading &&
                      (events == null || events.length == 0) && (
                        <div className="text-center mb-4">
                          <Lottie
                            options={getLottieNothingFound()}
                            height={150}
                            width={150}
                          />
                          <h5 className="text-muted mt-2">
                            {props.t("No events found")}
                          </h5>
                        </div>
                      )}
                  </CardBody>
                </Card>
              </Col>
              <Col className="order-1 order-xl-2">
                <Card>
                  <CardBody className="p-0">
                    <CardImg
                      top
                      width="100%"
                      height={"200px"}
                      style={{ objectFit: "cover", opacity: "0.5" }}
                      src={pindoraImage2}
                    ></CardImg>
                    <div
                      className="text-center mb-3"
                      style={{ padding: "20px" }}
                    >
                      <h5 className="text-muted mt-3">
                        {props.t("Smartlock open description")}
                      </h5>
                      {!openRequestLoading && (
                        <Button
                          className="mt-3"
                          type="button"
                          style={{ minWidth: "180px" }}
                          onClick={() => setOpenRequestModal(true)}
                        >
                          {props.t("Open door")}
                        </Button>
                      )}
                      {openRequestLoading && (
                        <Lottie
                          options={getLottieLoadingOptions()}
                          height={100}
                          width={100}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  )
}

SmartlockServices.propTypes = {
  t: PropTypes.any,
  smartlockKey: PropTypes.string,
  suvantoKey: PropTypes.string,
}

export default withTranslation()(SmartlockServices)
