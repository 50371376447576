/***********************
 * SMALL DIAGRAM ACTIVITY
 ************************/

import React, { useEffect, useState, useContext } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Col, Row, CardBody } from "reactstrap"

// Locale/weekday related imports
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"

import GlobalState from "contexts/GlobalState"

//Translation
import { withTranslation } from "react-i18next"

const SmallDiagramActivity = props => {
  const [chartData, setChartData] = useState([])
  const [chartDataDoor, setChartDataDoor] = useState([])
  const [chartDataMicro, setChartDataMicro] = useState([])
  const [chartDataWc, setChartDataWc] = useState([])
  const [chartDataBed, setChartDataBed] = useState([])

  // Language
  const [state] = useContext(GlobalState)
  const [language, setLanguage] = useState("fi")

  // Loading state
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    if (props.data != 0 && props.data != undefined) {
      let filterEmpty1 = props.data
      setChartData(filterEmpty1)
      let filterEmpty = props.doorData.filter(f => f[1] != 0)
      setChartDataDoor(filterEmpty)

      let filterEmpty2 = props.bedData
      let a = filterEmpty2.map(item =>
        item[1] > 0 ? [item[0], (item[1] = 10)] : [item[0], item[1]]
      )
      setChartDataBed(a)
      let filterEmpty3 = props.microData.filter(f => f[1] != 0)
      setChartDataMicro(filterEmpty3)

      let filterEmpty4 = props.toilet.filter(f => f[1] != 0)
      setChartDataWc(filterEmpty4)
    }
    setIsLoading(false)
  }, [props.data, props.doorData, props.bedData, props.toilet])

  // Init language
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.startsWith("fi")) {
        setLanguage("fi")
      } else if (currentLanguage.startsWith("sv")) {
        setLanguage("se")
      } else {
        setLanguage("en")
      }
    }
  }, [])

  // Language change
  useEffect(() => {
    switch (state.language) {
      case "fi":
        setLanguage("fi")
        ApexCharts.exec("small-chart", "setLocale", "fi")
        break
      case "en":
        setLanguage("en")
        ApexCharts.exec("small-chart", "setLocale", "en")
        break
      case "sv":
        setLanguage("se")
        ApexCharts.exec("small-chart", "setLocale", "se")
        break
    }
  }, [state.language])

  const series = [
    {
      name: props.t("Bed"),
      data: chartDataBed,
      type: "area",
    },
    {
      name: props.t("All activity"),
      data: chartData,
      type: "area",
    },

    {
      name: props.t("Outside door opened"),
      data: chartDataDoor,
      type: "scatter",
    },

    {
      name: props.t("Micro"),
      data: chartDataMicro,
      type: "scatter",
    },

    {
      name: props.t("WC"),
      data: chartDataWc,
      type: "scatter",
    },
    // NOT IN USE
    // {
    //   name: props.t("Bathroom visit"),
    //   data: chartDataWc,
    //   type: "scatter",
    // },
  ]
  const settingToolbar = t => {
    let toolbar = {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
        customIcons: [],
      },
      export: {
        csv: {
          filename: undefined,
          columnDelimiter: " ",
          headerCategory: "category",
          headerValue: "value",
          dateFormatter(timestamp) {
            return new Date(timestamp).toDateString()
          },
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        },
      },
      autoSelected: "zoom",
    }
    if (t == "home") {
      return toolbar
    } else {
      return "false"
    }
  }

  const [options] = useState({
    chart: {
      id: "small-chart",
      locales: [fi, en, se],
      defaultLocale: language,
      toolbar: settingToolbar(props.type),
      stacked: false,
    },
    dataLabels: { enabled: !1 },
    stroke: { curve: "smooth", width: [2, 2, 5, 5] },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    markers: {
      size: [5, 3, 6, 6],
      colors: ["#3783cc00", "#48d0f800", "#8dd169", "#7871c4", "#ff9b00"],
      strokeColors: [
        "#3783cc00",
        "#48d0f8b00",
        "#8dd169",
        "#7871c4",
        "#ff9b00",
      ],
      // strokeWidth: 0.1,
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
    },
    legend: {
      show: true,
    },
    tooltip: {
      followCursor: true,
      shared: false,
      intersect: true,
      x: {
        format: "dd.MM.yyyy HH:mm",
      },
      custom: function (opts) {
        const desc = opts.ctx.w.config.series[opts.seriesIndex].name
        // console.log(desc)

        const value = opts.series[opts.seriesIndex][opts.dataPointIndex]

        if (desc == props.t("Bed")) {
          if (value > 0) {
            return (
              '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
              "<span>" +
              desc +
              " " +
              props.t("in use") +
              "</span>" +
              "</div>"
            )
          }
          if (value == 0) {
            return (
              '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
              "<span>" +
              desc +
              " " +
              props.t("not in use") +
              "</span>" +
              "</div>"
            )
          }
        }
        if (desc == props.t("All activity")) {
          return (
            '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
            "<span>" +
            desc +
            " " +
            value +
            " " +
            "%" +
            "</span>" +
            "</div>"
          )
        }
        if (desc == props.t("WC")) {
          return (
            '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
            "<span>" +
            props.t("Activity in WC") +
            " " +
            value +
            " " +
            "%" +
            "</span>" +
            "</div>"
          )
        }
        if (desc == props.t("Micro")) {
          return (
            '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
            "<span>" +
            desc +
            " " +
            props.t("in use") +
            "</span>" +
            "</div>"
          )
        }
        if (desc == props.t("Outside door opened")) {
          if (value == 1) {
            return (
              '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
              "<span>" +
              desc +
              "</span>" +
              "</div>"
            )
          } else {
            return (
              '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
              "<span>" +
              desc +
              " " +
              value +
              " " +
              props.t("times") +
              "</span>" +
              "</div>"
            )
          }
        }
      },
    },
    colors: ["#a6a6a688", "#48d0f8", "#8dd169", "#7871c4", "#ff9b00"], //"#3783cc""#f5d663", Oranssi->wc"#f5a14a"
    fill: {
      opacity: [1, 1, 1, 1],
      type: ["solid", "gradient", "solid", "solid"],
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        opacityFrom: 1,
        opacityTo: 0.75,
        stops: [0, 100, 100, 10],
      },
      solid: {
        color: "#ff9b00",
        width: 32,
        height: 32,
      },
    },
  })

  return (
    <div>
      {isLoading ? (
        <Row>
          <Col xs="12">
            <CardBody className="text-center" style={{ height: props.size }}>
              <div className="py-5">
                <Link to="#" className="text-primary">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  {props.t("Loading")}
                </Link>
              </div>
            </CardBody>
          </Col>
        </Row>
      ) : (
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={props.size}
        />
      )}
    </div>
  )
}

SmallDiagramActivity.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  doorData: PropTypes.any,
  microData: PropTypes.any,
  bedData: PropTypes.any,
  size: PropTypes.number,
  toilet: PropTypes.any,
  type: PropTypes.string,
}

export default withTranslation()(SmallDiagramActivity)
