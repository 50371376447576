/**
 * Functions for tables
 */

import React from "react"
import { Link } from "react-router-dom";

// Format clients into rows if clickable links
export function clientLinksFormatter(cell, row) {
  if (row.links && row.links.length > 0) {
    return (
      <div className="d-grid">
        {row.links?.map((entry, key) => (
          <Link key={""+key} to={"/clients/" + entry.key} target="_blank">
            <span>{entry.name}</span>
          </Link>
        ))}
      </div>
    )
  }
}