import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

const ChartLicenses = props => {
  const [stats, setStats] = useState({"hosts": 0, "guests": 0})

  // Updating states
  useEffect(() => {
    if (props.data != null) {
      let temp = {
        "hosts": props.data.hosts,
        "guests": props.data.guests,
      }
      setStats(temp)
    }
  }, [props.data])

  return (
    <React.Fragment>
      <div id="calls-chart">
        <Row className=" mt-0 mb-2">
          <div className="d-flex flex-wrap">
            <span>
              <h5 className="mt-0">{props.t("Group call licenses")}</h5>
            </span>
          </div>
        </Row>
        <Row>
          {/* Group hosts */}
          <Col sm="10" md="9" lg="6" xxl="5" className="">
            <Card style={{ height: 127 + "px" }}>
              <CardBody>
                <div className="d-flex ">
                  <div className="avatar-md mt-2 ms-0">
                    <div className="avatar-title bg-light rounded-circle text-primary h1">
                      <i className="mdi mdi-account-voice"></i>
                    </div>
                  </div>
                  <div className="ms-4 mt-2">
                    <p className="text-muted font-size-14 mb-2">
                      {props.t("Total group call hosts")}
                    </p>
                    <h2 className="mb-0">{stats.hosts}</h2>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* Group guests */}
          <Col sm="10" md="9" lg="6" xxl="5" className="">
            <Card style={{ height: 127 + "px" }}>
              <CardBody>
                <div className="d-flex ">
                  <div className="avatar-md mt-2 ms-0">
                    <div className="avatar-title bg-light rounded-circle text-primary h1">
                      <i className="bx bx-group"></i>
                    </div>
                  </div>
                  <div className="ms-4 mt-2">
                    <p className="text-muted font-size-14 mb-2">
                      {props.t("Total group call guests")}
                    </p>
                    <h2 className="mb-0">{stats.guests}</h2>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

ChartLicenses.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
}

export default withTranslation()(ChartLicenses)