/***********************
 * NODE ACTIVITY CHART
 * (+ IMPORT PDF/XLSX)
 ************************/

import React, { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import "../../../../assets/scss/custom/charts/custom-chart.css"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"

// Locale/weekday related imports
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"

//Translation
import { withTranslation } from "react-i18next"
import GlobalState from "contexts/GlobalState"

const NodeChartActivity = props => {
  const [state] = useContext(GlobalState)
  const [language, setLanguage] = useState("fi")
  const [chartData, setChartData] = useState()
  const [activityData, setActivityData] = useState(["", 0])
  const [chartAnnotations, setChartAnnotations] = useState({})
  const [tooltip, setTooltip] = useState([])
  const [allData, setAllData] = useState(["", 0])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setChartData(props.data)
  }, [props.data])

  useEffect(() => {
    setAllData(props.allActivityData)
  }, [props.allActivityData])

  useEffect(() => {
    if (chartData !== undefined) {
      if (props.serie !== "Bed") {
        let first = props.data[0]
        let last = props.data[props.data.length - 1]
        let filterEmpty = props.data.filter(f => f[1] != 0)
        filterEmpty.push(first, last)
        setActivityData(filterEmpty)
      }
      if (props.serie === "Bed") {
        let a = props.data.map(item => {
          const newValue = item[1] > 0 ? 10 : item[1]
          return [item[0], newValue]
        })
        setActivityData(a)
      }
    }
  }, [chartData])

  useEffect(() => {
    if (props.showLoading != undefined) setIsLoading(props.showLoading)
  }, [props.showLoading])

  useEffect(() => {
    setTooltip(props.tooltipUnit)
  }, [props.tooltipUnit])

  useEffect(() => {
    if (props.annotations != undefined) {
      if (props.annotations.value != undefined) {
        setChartAnnotations({
          yaxis: [
            {
              min: 0,
              max: 35,
              y: props.annotations.value,
              borderColor: "#ff9b00",
              strokeDashArray: 4,
              label: {
                borderColor: "#ff9b00",
                style: { color: "#fff", background: "#ff9b00" },
                text: props.annotations.label,
              },
            },
          ],
        })
      }
    } else {
      setChartAnnotations({
        yaxis: [
          {
            min: 0,
            max: 20,
            y: 0,
            borderColor: "#FFFFFF",
            strokeDashArray: 1,
            label: {
              borderColor: "#FFFFFF",
              style: { color: "#FFFFFF", background: "#FFFFFF" },
              text: "",
            },
          },
        ],
      })
    }
  }, [props.annotations])

  //DatePicker language update
  // const getLanguage = () => {
  //   let language = localStorage.getItem("i18nextLng")
  //   if (language.indexOf("-") > -1) language = language.split("-")[0]
  //   switch (language) {
  //     case "en":
  //       return en
  //     case "fi":
  //       return fin
  //     case "sv":
  //       return swe
  //     default:
  //       return null
  //   }
  // }

  // Init language
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.startsWith("fi")) {
        setLanguage("fi")
      } else if (currentLanguage.startsWith("sv")) {
        setLanguage("se")
      } else {
        setLanguage("en")
      }
    }
  }, [])

  const settingYAxis = () => {
    if (props.chart == 2 && props.serie !== undefined) {
      switch (props.serie) {
        case "Door":
          return { min: 0, max: 10 }
        case "Bed":
          return { min: 0, max: 20 }
        case "Electric":
          return { min: 0, max: 500 }
        default:
          return { min: 0, max: 20 }
      }
    }

    if (props.chart == 1 || props.chart == 2) {
      // Default for chart 1 and unmatched cases in chart 2
      return { min: 0, max: 20 }
    }
  }

  // Language change
  useEffect(() => {
    switch (state.language) {
      case "fi":
        setLanguage("fi")
        ApexCharts.exec("node-activity-chart", "setLocale", "fi")
        break
      case "en":
        setLanguage("en")
        ApexCharts.exec("node-activity-chart", "setLocale", "en")
        break
      case "sv":
        setLanguage("se")
        ApexCharts.exec("node-activity-chart", "setLocale", "se")
        break
    }
  }, [state.language])

  const checkingSeries = s => {
    // Added a filter
    const validActivityData = activityData.filter(
      item => item[1] != null && item[1] !== undefined
    )

    const series1 = [
      {
        name: props.t(props.serie),
        type: "column",
        data: validActivityData,
      },
      {
        name: props.t("All activity"),
        data: allData,
        type: "area",
      },
    ]
    const series2 = [
      {
        name: props.t(props.serie),
        type: "column",
        data: validActivityData,
      },
    ]

    if (s == 1) {
      return series1
    }
    if (s == 2) {
      return series2
    }
  }

  const settingOptions = (s, type) => {
    const options = {
      chart: {
        id: "node-activity-chart",
        locales: [fi, en, se],
        defaultLocale: language, //"en",
        stacked: false,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: -17,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: " ",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: "zoom",
        },
      },
      stroke: {
        width: [0, 1, 0],
        curve: "smooth",
      },
      colors: ["#00a0d6", "#8ce2ff"],
      annotations: chartAnnotations,
      fill: {
        opacity: [0.85, 0.9, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      markers: {
        size: [0, 1],
        shape: ["circle", "circle", "circle"],
        fillColor: ["#ffffff00", "#8ce2ff", "#ffffff00"],
        strokeColor: ["#ffffff00", "#8ce2ff", "#ffffff00"],
      },
      legend: {
        offsetY: 11,
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: settingYAxis(),
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: false,
          format: "dd.MM.yyyy HH:mm",
        },
        custom: function (opts) {
          const desc = opts.ctx.w.config.series[opts.seriesIndex].name
          const value = opts.series[opts.seriesIndex][opts.dataPointIndex]
          if (desc == props.t("Bed")) {
            if (value > 0) {
              return (
                '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
                "<span>" +
                desc +
                " " +
                props.t("in use") +
                "</span>" +
                "</div>"
              )
            }
            if (value == 0) {
              return (
                '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
                "<span>" +
                desc +
                " " +
                props.t("not in use") +
                "</span>" +
                "</div>"
              )
            }
          }
          if (desc == props.t("Activity") || props.t("All activity")) {
            return (
              '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
              "<span>" +
              desc +
              " " +
              value +
              " " +
              "%" +
              "</span>" +
              "</div>"
            )
          }
          if (desc.includes(props.t("Electric"))) {
            return (
              '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
              "<span>" +
              desc +
              " " +
              props.t("W") +
              "</span>" +
              "</div>"
            )
          }
          if (desc.includes(props.t("Door"))) {
            if (value == 1) {
              return (
                '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
                "<span>" +
                props.t("Opened") +
                "</span>" +
                " " +
                value +
                " " +
                props.t("time") +
                "</span>" +
                "</div>"
              )
            } else {
              return (
                '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
                "<span>" +
                props.t("Opened") +
                "</span>" +
                " " +
                value +
                " " +
                props.t("times") +
                "</span>" +
                "</div>"
              )
            }
          } else {
            return (
              '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
              "<span>" +
              value +
              "</span>" +
              "</div>"
            )
          }
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    }

    const options2 = {
      chart: {
        id: "node-activity-chart",
        locales: [fi, en, se],
        defaultLocale: language, //"en",
        stacked: false,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: -17,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: " ",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: "zoom",
        },
      },
      stroke: {
        width: [0, 0, 2],
        curve: "smooth",
      },
      colors: ["#00a0d6"],
      annotations: chartAnnotations,
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      markers: {
        size: 0,
      },
      legend: {
        offsetY: 11,
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: settingYAxis(),
      tooltip: {
        shared: false,
        intersect: true,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " " + props.t(tooltip)
            }
          },
        },
        x: {
          show: false,
          format: "dd.MM.yyyy HH:mm",
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    }
    if (s == 1 || type == "Bed") {
      return options
    }
    if (s == 2) {
      return options2
    }
  }

  return (
    <div>
      {isLoading ? (
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Link to="#" className="text-primary">
                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                {props.t("Loading")}
              </Link>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          {activityData == undefined && activityData != [] ? (
            <div>
              <div>
                <Link to="#" className="text-primary">
                  {props.t("No activities")}
                </Link>
              </div>
            </div>
          ) : (
            <ReactApexChart
              options={settingOptions(props.chart, props.serie)}
              series={checkingSeries(props.chart)}
              type="line"
              height="300"
            />
          )}
        </div>
      )}
    </div>
  )
}

NodeChartActivity.propTypes = {
  t: PropTypes.any,
  chart: PropTypes.any,
  data: PropTypes.any,
  tooltipUnit: PropTypes.any,
  showLoading: PropTypes.bool,
  annotations: PropTypes.any,
  serie: PropTypes.string,
  allActivityData: PropTypes.any,
}

export default withTranslation()(NodeChartActivity)
