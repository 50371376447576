/***********************
 * CALENDAR COMPONENT (RANGE, CUSTOMER SETTINGS)
 ************************/

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

//Formatting date
import dayjs from "dayjs"

//Translation
import { withTranslation } from "react-i18next"

//DatePicker ANTD
import { DatePicker, ConfigProvider } from "antd"
const { RangePicker } = DatePicker

// Locale/weekday related imports
import "dayjs/locale/fi"
import "dayjs/locale/se"
import "dayjs/locale/en-gb"
import { Col, Button } from "reactstrap"
import { getLocale } from "utils/general"

const RangeCalendarServices = props => {
  const [defaultValueStart] = useState(props.range)
  const [rangePicker, setRangePicker] = useState(props.rangePicker ?? true)
  // const [now, setNow] = useState()
  const [startTime, setStartTime] = useState()
  const [defaultValueEnd] = useState(dayjs().isAfter(dayjs()))
  const [endTime, setEndTime] = useState()
  const [dateAndTime, setDateAndTime] = useState(null)
  const [chosenDate, setChosenDate] = useState(dayjs())
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const locale = getLocale()

  useEffect(() => {
    if (dateAndTime == null && defaultValueStart != undefined) {
      let timeStart = new Date(defaultValueStart)
      let timeEnd = new Date(defaultValueEnd)
      let unixStart = timeStart.getTime()
      let unixEnd = timeEnd.getTime()
      props.setCalendarStartDate(unixStart)
      props.setCalendarEndDate(unixEnd)
    }
    if (defaultValueStart == undefined) {
      onDateSelection(dayjs())
    }
  }, [])

  useEffect(() => {
    if (props.range == undefined) {
      setRangePicker(false)
    }
  }, [props.range])

  const rangeButtons = {
    [props.t("This day")]: [dayjs().startOf("day"), dayjs()],
    [props.t("This Week")]: [dayjs().startOf("week"), dayjs().endOf("week")],
    [props.t("This Month")]: [dayjs().startOf("month"), dayjs().endOf("month")],
  }

  const settingDate = v => {
    let date = null
    if (v == "back") {
      date = dayjs(chosenDate).add(-1, "d")
      onDateSelection(date)
    }
    if (v == "forward") {
      date = dayjs(chosenDate).add(1, "d")
      onDateSelection(date)
    }
  }

  useEffect(() => {
    if (dayjs().isSame(chosenDate, "day")) {
      setButtonDisabled(true)
    }
    if (dayjs().isSame(chosenDate, "day") == false) {
      setButtonDisabled(false)
    }
  }, [chosenDate])

  // Prevent scrolling the year
  useEffect(() => {
    if (!props.disabled) {
      const handleScroll = e => e.preventDefault()
      const dropdowns = document.querySelectorAll(".ant-picker-dropdown")

      dropdowns.forEach(el => {
        el.addEventListener("wheel", handleScroll, { passive: false })
      })

      return () => {
        dropdowns.forEach(el => {
          el.removeEventListener("wheel", handleScroll)
        })
      }
    }
  }, [props.disabled])

  // User can not pick a date further than end of the day
  const disabledDate = current => {
    // console.log("disabled date", current)
    const from = dayjs().add(-(props.startDefault - 1), "days")
    return (
      current &&
      (current > dayjs().endOf("day") || current < from.startOf("day"))
    )
  }

  const onDateSelectionRange = value => {
    setDateAndTime(value)
    if (value != null) {
      let start = new Date(value[0])
      let end = new Date(value[1])

      let startUnix = start.getTime()
      let endUnix = end.getTime()
      props.setCalendarStartDate(startUnix)
      props.setCalendarEndDate(endUnix)

      setStartTime(start)
      setEndTime(end)
    }
  }
  const onDateSelection = value => {
    setChosenDate(value)
    // console.log(value)
    // setDateAndTime(value)
    if (value != null) {
      let start = new Date(value.startOf("day"))
      let end = new Date(value.endOf("day"))

      let startUnix = start.getTime()
      let endUnix = end.getTime()
      props.setCalendarStartDate(startUnix)
      props.setCalendarEndDate(endUnix)

      setStartTime(start)
      setEndTime(end)
    }
  }

  return (
    <React.Fragment>
      <div>
        <Col className={` ${props.placeholder ? "placeholder" : ""}`}>
          <ConfigProvider locale={locale}>
            {rangePicker ? (
              <RangePicker
                defaultValue={[defaultValueStart, defaultValueEnd]}
                showTime
                style={{
                  width: "100%",
                }}
                // TODO: Set disabled dates from historyDays
                // disabledDate={disabledDate}
                onChange={onDateSelectionRange}
                format="DD.MM.YYYY HH:mm"
                ranges={{ ...rangeButtons }}
              />
            ) : (
              <>
                <Col className="px-0">
                  <Button
                    onClick={() => {
                      settingDate("back")
                    }}
                    size="sm"
                    // disabled={buttonDisabled}
                    color="clear"
                    className="mt-0 py-0"
                    style={{ border: "none" }}
                  >
                    <i className="bx bx-chevron-left mt-1  text-primary font-size-20"></i>
                  </Button>
                  <DatePicker
                    // size="large"
                    className="mt-0"
                    defaultValue={dayjs()}
                    disabledDate={disabledDate}
                    showToday
                    allowClear={false}
                    onChange={onDateSelection}
                    format="DD.MM.YYYY"
                    value={chosenDate}
                  />
                  <Button
                    onClick={() => {
                      settingDate("forward")
                    }}
                    disabled={buttonDisabled}
                    size="sm"
                    color="clear"
                    className="mt-0 py-0"
                    style={{ border: "none" }}
                  >
                    <i className="bx bx-chevron-right mt-1 text-primary font-size-20"></i>
                  </Button>
                </Col>
              </>
            )}
          </ConfigProvider>
        </Col>
      </div>
    </React.Fragment>
  )
}

RangeCalendarServices.propTypes = {
  t: PropTypes.any,
  placeholder: PropTypes.any,
  setCalendarStartDate: PropTypes.any,
  setCalendarEndDate: PropTypes.any,
  range: PropTypes.any,
  rangePicker: PropTypes.bool,
  startDefault: PropTypes.any,
  disabled: PropTypes.bool,
}
export default withTranslation()(RangeCalendarServices)
