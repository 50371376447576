/***********************
 * DEVICE IDENTIFICATIONS
 ************************/

import React, { useState, useEffect, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import PropTypes from "prop-types"

//Chart import
import ReactApexChart from "react-apexcharts"

//Translation
import { withTranslation } from "react-i18next"

const ChartDI = props => {
  const [stats, setStats] = useState({})

  //Totals
  const [total, setTotal] = useState(0)

  // Types
  const [gateways, setGateways] = useState([])
  const [trackers, setTrackers] = useState([])
  const [nodes, setNodes] = useState([])
  const [doses, setDoses] = useState([])
  const [measuringDevices, setMeasuringDevices] = useState([])
  const [videos, setVideos] = useState([])
  const [carephones, setCarephones] = useState([])
  const [smartlocks, setSmartlocks] = useState([])
  const [externalSpeakers, setExternalSpeakers] = useState([])

  // Filtering customer names from types
  const [customerG, setCustomerG] = useState([])
  const [customerT, setCustomerT] = useState([])
  const [customerN, setCustomerN] = useState([])
  const [customerD, setCustomerD] = useState([])
  const [customerMD, setCustomerMD] = useState([])
  const [customerV, setCustomerV] = useState([])
  const [customerC, setCustomerC] = useState([])
  const [customerS] = useState([])
  const [customerF, setCustomerF] = useState([])

  // Updating states
  useEffect(() => {
    if (props.data?.stats && props.data.stats !== stats) {
      console.log("Stats: " + JSON.stringify(props.data.stats))

      //Setting stats, list
      setStats(props.data.stats)

      // Setting variables for charts
      setGateways(props.data.stats.gateways)
      setTrackers(props.data.stats.trackers)
      setNodes(props.data.stats.nodes)
      setDoses(props.data.stats.doses)
      setMeasuringDevices(props.data.stats.measuringDevices)
      setVideos(props.data.stats.videos)
      setCarephones(props.data.stats.carephones)
      setSmartlocks(props.data.stats.smartlocks)
      setExternalSpeakers(props.data.stats.externalSpeakers)

      //counting totals
      setTotal(
        props.data.stats.gateways +
          props.data.stats.trackers +
          props.data.stats.nodes +
          props.data.stats.doses +
          props.data.stats.measuringDevices +
          props.data.stats.videos +
          props.data.stats.carephones +
          props.data.stats.smartlocks +
          props.data.stats.externalSpeakers
      )

      //Setting names by service function
      filterCustomersByService(props.data.list)
    }
  }, [props.data])

  // Chart options
  const getChartOptions = index => {
    let options = {
      chart: { sparkline: { enabled: !0 } },
      dataLabels: { enabled: !1 },
      colors: ["#556ee6"],
      plotOptions: {
        radialBar: {
          hollow: { margin: 0, size: "60%" },
          track: { margin: 0 },
          dataLabels: { show: !1 },
        },
      },
    }
    switch (index) {
      case 0:
        options["colors"][0] = "#0061db" // color: loading-blue-dots
        break
      case 1:
        options["colors"][0] = "#97cd00" //color: suvanto-green
        break
      case 2:
        options["colors"][0] = "#00a0d6" //color: suvanto-blue-3-1
        break
      case 3:
        options["colors"][0] = "#f46a6a" //color: danger
        break
      case 4:
        options["colors"][0] = "#34c38f" //color: success
        break
      case 5:
        options["colors"][0] = "#ff9b00" //color: suvanto-orange
        break
      case 6:
        options["colors"][0] = "#343a40" //color: secondary
        break
      case 7:
        options["colors"][0] = "#0a9396" //color: chart-color-6
        break
      case 8:
        options["colors"][0] = "#0a9396" //color: chart-color-6
        break
      default:
        break
    }

    return options
  }

  // Delete duplicates from names array
  const deleteDuplicated = array => {
    let setNew = Array.from(new Set(array))
    return setNew
  }

  // Stat card values
  const cardValues = [
    {
      id: 0,
      value: gateways,
      text: props.t("Gateways"),
      icon: "bx bx-home",
      color: "warning",
      series: toPercentage(gateways),
      customers: deleteDuplicated(customerG),
    },
    {
      id: 1,
      value: nodes,
      text: props.t("Nodes"),
      icon: "mdi mdi-phone-ring-outline",
      color: "info",
      series: toPercentage(nodes),
      customers: deleteDuplicated(customerN),
    },
    {
      id: 2,
      value: trackers,
      text: props.t("Trackers"),
      icon: "mdi mdi-cellphone-sound",
      color: "primary",
      series: toPercentage(trackers),
      customers: deleteDuplicated(customerT),
    },
    {
      id: 3,
      value: doses,
      text: props.t("Medication reminders"),
      color: "danger",
      icon: "mdi mdi-cellphone-link",
      series: toPercentage(doses),
      customers: deleteDuplicated(customerD),
    },
    {
      id: 4,
      value: measuringDevices,
      text: props.t("Measuring devices"),
      icon: "bx bx-stats",
      color: "success",
      series: toPercentage(measuringDevices),
      customers: deleteDuplicated(customerMD),
    },
    {
      id: 5,
      value: videos,
      text: props.t("Videos"),
      icon: "mdi mdi-medical-bag",
      color: "pink",
      series: toPercentage(videos),
      customers: deleteDuplicated(customerV),
    },
    {
      id: 6,
      value: carephones,
      text: props.t("Safety phones"),
      icon: "mdi mdi-shield-lock-outline",
      color: "secondary",
      series: toPercentage(carephones),
      customers: deleteDuplicated(customerC),
    },

    {
      id: 7,
      value: smartlocks,
      text: props.t("Smartlocks"),
      icon: "mdi mdi-lock-check",
      color: "chart-color-6",
      series: toPercentage(smartlocks),
      customers: deleteDuplicated(customerS),
    },
    {
      id: 8,
      value: externalSpeakers,
      text: props.t("External speaker"),
      icon: "mdi mdi-medical-bag",
      color: "pink",
      series: toPercentage(externalSpeakers),
      customers: deleteDuplicated(customerF),
    },
  ]

  // For radial bars
  function toPercentage(value) {
    if (value != 0) {
      let result = ((value / total) * 100).toFixed(1)
      return [result]
    }
    if (value == 0) {
      return [0]
    }
  }

  // Filtering customer names from types
  const filterCustomersByService = data => {
    data.filter(n => {
      // Finding home services users
      if (n.gateways === true) {
        setCustomerG(customerG => [...customerG, n.clientName])
      }
      if (n.trackers === true) {
        setCustomerT(customerT => [...customerT, n.clientName])
      }
      if (n.nodes === true) {
        setCustomerN(customerN => [...customerN, n.clientName])
      }
      if (n.doses === true) {
        setCustomerD(customerD => [...customerD, n.clientName])
      }
      if (n.measuringDevices === true) {
        setCustomerMD(customerMD => [...customerMD, n.clientName])
      }
      if (n.videos === true) {
        setCustomerV(customerV => [...customerV, n.clientName])
      }
      if (n.carephones === true) {
        setCustomerC(customerC => [...customerC, n.clientName])
      }
      if (n.smartlocks === true) {
        setCustomerC(customerC => [...customerC, n.clientName])
      }
      if (n.externalSpeakers === true) {
        setCustomerF(customerF => [...customerF, n.clientName])
      }
    })
  }

  return (
    <React.Fragment>
      <div id="services-chart">
        <Row className=" mt-2 mb-2">
          <div className="d-flex flex-wrap">
            <span>
              <h5 className="mt-1">{props.t("Device identifications")}</h5>
            </span>
          </div>
        </Row>
        <Col className="mt-1" xl="12">
          <Row>
            <Col sm="3">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="mt-2 mb-1 text-center" xs="12">
                      <div className="">
                        <div className="mb-4 text-primary display-6">
                          <i className="bx bxs-devices" />
                        </div>
                        <div className="mt-2 mb-0">
                          <h3>{total}</h3>
                          {total !== 1 ? (
                            <p className="">
                              {props.t("device identifications")}
                            </p>
                          ) : (
                            <p className="">
                              {props.t("device identification")}
                            </p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {cardValues.map((item, index) => {
              const options2 = getChartOptions(item.id)
              return (
                <Fragment key={"" + index}>
                  {item.value != 0 ? (
                    <Col sm="3">
                      <Card>
                        <CardBody>
                          <CardTitle>{item.text}</CardTitle>
                          <Row>
                            <Col xs="6">
                              <div className="text-center">
                                <h2 className="mt-4">{item.value}</h2>
                              </div>
                            </Col>
                            <Col id={"col_" + item.id} xs="6">
                              <div id="wallet-balance-chart">
                                <ReactApexChart
                                  options={options2}
                                  series={item.series}
                                  type="radialBar"
                                  height={90}
                                  width={90}
                                  className="apex-charts"
                                ></ReactApexChart>
                              </div>
                            </Col>{" "}
                          </Row>
                          <Col xs="12">
                            <div className="text-muted text-truncate mt-3">
                              <span>{item.series}%</span>
                              <span>
                                {" "}
                                {props.t("of all device identifications")}
                              </span>
                            </div>
                          </Col>
                          {/* <hr />
                          <Col xs="12">
                            <p className="">
                              {props.t("Customers with device identifications")}
                              :
                            </p>
                            {editIndex !== index && (
                              <div className="text-muted mt-1">
                                {item.customers.slice(0, 3).map(names => (
                                  <div key={"names" + names} className="me-2 ">
                                    <Row>
                                      <span className="col-12">
                                        <i className="dripicons-dot text-primary ms-0 me-1"></i>
                                        {names}
                                      </span>
                                    </Row>
                                  </div>
                                ))}
                                {item.customers.length <= wantedLenght ? (
                                  <div></div>
                                ) : (
                                  <div
                                    role="button"
                                    className="text-primary mt-2 text-center"
                                    onClick={() =>
                                      setEditIndex(editIndex =>
                                        editIndex === index ? null : index
                                      )
                                    }
                                  >
                                    {props.t("Show all")}
                                  </div>
                                )}
                              </div>
                            )}
                            {editIndex === index && (
                              <div className="text-muted mt-3">
                                {item.customers.map(names => (
                                  <div key={"names" + names} className="me-2 ">
                                    <Row>
                                      <span className="col-12">
                                        <i className="dripicons-dot text-primary ms-0 me-1"></i>
                                        {names}
                                      </span>
                                    </Row>
                                  </div>
                                ))}
                                <div
                                  role="button"
                                  className="text-primary mt-2 text-center"
                                  onClick={() =>
                                    setEditIndex(editIndex =>
                                      editIndex === index ? null : index
                                    )
                                  }
                                >
                                  {props.t("Hide")}
                                </div>
                              </div>
                            )}
                          </Col> */}
                        </CardBody>
                      </Card>
                    </Col>
                  ) : null}
                </Fragment>
              )
            })}
          </Row>
        </Col>
      </div>
    </React.Fragment>
  )
}

ChartDI.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
}

export default withTranslation()(ChartDI)
