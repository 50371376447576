/*********************************
 * CLIENT SELECT
 * USED IN: index Appointments
 *********************************/

import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { getClientList } from "../../helpers/backend_helper"
import { Select, ConfigProvider } from "antd"

const SelectClient = props => {
  const [customize] = useState(true)
  const [options, setOptions] = useState([])
  const [disabled, setDisabled] = useState(true)
  const [placement] = useState("bottomLeft")
  // const [searchString, setSearchString] = useState("")

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    // getClients()
    return () => {
      abortRef.current.abort()
    }
  }, [])

  useEffect(() => {
    getClients(props.selectedGroup)
  }, [props.selectedGroup])

  // Get clients list
  const getClients = groupSelected => {
    getClientList(abortRef.current)
      .then(data => {
        console.log(data)
        if (data.array != null) {
          console.log(data.array)
          let selection = null
          let opts = []
          let counter = 1
          let filterData = data.array
          if (groupSelected != undefined) {
            filterData = data.array.filter(d => d.name == groupSelected)
          }
          filterData.forEach(group => {
            console.log(group, "group")
            let groupObject = new Object()
            groupObject.id = counter++
            groupObject.label = group.name
            groupObject.value = group.name

            groupObject.options = []

            group.clients.sort((a, b) => (a.name > b.name ? 1 : -1))
            group.clients.forEach(client => {
              let clientObject = new Object()
              clientObject.label = client.name
              clientObject.value = client.id

              groupObject.options.push(clientObject)
            })
            opts.push(groupObject)
          })
          console.log(opts, "opts")
          setOptions(opts)
          handleChange(selection)
          setDisabled(false)
        }
      })
      .catch(e => {
        console.log(e)
        setDisabled(false)
      })
  }

  // Selection changed
  const handleChange = newSelection => {
    // console.log(newSelection, "newselection")
    // setSearchString("")
    if (props.onChange != null) {
      props.onChange(newSelection)
    }
  }

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3 text-center">
      <p>{props.t("No customers found")}</p>
    </div>
  )

  return (
    <div>
      <ConfigProvider renderEmpty={customize && customizeRenderEmpty}>
        <div className="config-provider ">
          <Select
            disabled={disabled}
            showSearch
            allowClear={true}
            placement={placement}
            popupMatchSelectWidth={true}
            style={{
              width: 100 + "%",
            }}
            onChange={handleChange}
            options={options}
            // onSearch={s => {
            //   setSearchString(s.toLowerCase())
            // }}
            filterOption={(inputValue, option) => {
              return (
                option.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                0
              )
            }}
          ></Select>
        </div>
      </ConfigProvider>
    </div>
  )
}

SelectClient.propTypes = {
  t: PropTypes.any,
  onChange: PropTypes.func,
  selectedGroup: PropTypes.any,
}

export default withTranslation()(SelectClient)
