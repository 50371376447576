import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SocketContext from "contexts/SocketContext/context"
import { initSockets } from "../../sockets"
//       ^ initSockets is shown later on
const SocketProvider = props => {
  const [value, setValue] = useState({
    queueLength: 0,
    positionInLine: 0,
  })
  useEffect(() => initSockets({ setValue }), [initSockets])
  // Note, we are passing setValue ^ to initSockets
  return (
    <SocketContext.Provider value={value}>
      {props.children}
    </SocketContext.Provider>
  )
}

SocketProvider.propTypes = {
  children: PropTypes.any,
}

export default SocketProvider
