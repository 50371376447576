/***********************
 * NODE ACTIVITY HISTORY CHART
 * (+ IMPORT PDF/XLSX)
 ************************/

import React, { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import {
  Col,
  Row,
  Form,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import { Link } from "react-router-dom"

//Classnames
import classnames from "classnames"

// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"

//Translation
import { withTranslation } from "react-i18next"
import GlobalState from "contexts/GlobalState"

const NodeChartActivityHistory = props => {
  const [state] = useContext(GlobalState)
  const [language, setLanguage] = useState("fi")
  const [tooltip, setTooltip] = useState([])
  const [chartDataActivityAvg, setChartDataActivityAvg] = useState(["", 0])
  // Loading state
  const [isLoading, setIsLoading] = useState(true)
  // empty array
  const [empty] = useState(false)
  // chart
  const [type, setType] = useState("line")
  // HOX tooltip does not work if type is set otherwise
  const [optionTypes, setOptionTypes] = useState()

  useEffect(() => {
    if (props.avg != undefined) {
      setChartDataActivityAvg(props.avg)
    }
  }, [props.avg])

  useEffect(() => {
    if (props.showLoading != undefined) {
      setIsLoading(props.showLoading)
    }
  }, [props.showLoading])

  useEffect(() => {
    if (props.tooltipUnit != undefined) {
      let tt = props.t(props.tooltipUnit)
      setTooltip(tt)
    }
  }, [props.tooltipUnit])
  // Init language
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.startsWith("fi")) {
        setLanguage("fi")
      } else if (currentLanguage.startsWith("sv")) {
        setLanguage("se")
      } else {
        setLanguage("en")
      }
    }
  }, [])
  // Language change
  useEffect(() => {
    switch (state.language) {
      case "fi":
        setLanguage("fi")
        ApexCharts.exec("node-activity-chart", "setLocale", "fi")
        break
      case "en":
        setLanguage("en")
        ApexCharts.exec("node-activity-chart", "setLocale", "en")
        break
      case "sv":
        setLanguage("se")
        ApexCharts.exec("node-activity-chart", "setLocale", "se")
        break
    }
  }, [state.language])

  useEffect(() => {
    if (tooltip != undefined) {
      setOptionTypes(optionsLine)
    }
  }, [tooltip])

  const series = [
    {
      name: props.t("Measurements"),
      type: type,
      data: chartDataActivityAvg,
    },
  ]
  const optionsLine = {
    chart: {
      id: "node-his-chart-line",
      locales: [fi, en, se],
      defaultLocale: language, //"en",
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.1,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
    },
    stroke: {
      width: [3],
      curve: "smooth",
    },
    colors: ["#00a0d6"],
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " " + tooltip
          }
        },
      },
      x: {
        format: "dd.MM.yyyy",
      },
    },
  }

  const optionsColumn = {
    chart: {
      id: "node-his-chart-column",
      locales: [fi, en, se],
      defaultLocale: language, //"en",
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "45%",
          endingShape: "rounded",
        },
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#00a0d6"],
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y + " " + tooltip
          }
          return y
        },
      },
      x: {
        format: "dd.MM.yyyy",
      },
    },
  }
  const optionsArea = {
    chart: {
      id: "node-his-chart-area",
      locales: [fi, en, se],
      defaultLocale: language, //"en",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: props.t("Node chart"),
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: props.t("Node chart"),
          },
          png: {
            filename: props.t("Node chart"),
          },
        },
        autoSelected: "zoom",
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#00a0d6"],
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
    },
    // dataLabels: {
    //   enabled: false,
    // },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y + " " + tooltip
          }
          return y
        },
      },
      x: {
        format: "dd.MM.yyyy",
      },
    },
  }

  // Changing chart type
  const changeType = t => {
    if (t != type) {
      setType(t)

      if (t == "line") {
        setOptionTypes(optionsLine)
      }
      if (t == "column") {
        setOptionTypes(optionsColumn)
      }
      if (t == "area") {
        setOptionTypes(optionsArea)
      }
    }
  }

  return (
    <div>
      {isLoading ? (
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Link to="#" className="text-primary">
                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                {props.t("Loading")}
              </Link>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          {empty ? (
            <div>
              <div className="text-center my-5">
                <Link to="#" className="text-primary">
                  {props.t("No activities")}
                </Link>
              </div>
            </div>
          ) : (
            <div className="">
              <span
                className="float-end"
                style={{
                  zIndex: 1,
                  position: "relative",
                  marginTop: "-40px",
                  marginRight: "0px",
                }}
              >
                <Form>
                  <Nav className="product-view-nav" pills>
                    <NavItem>
                      <NavLink
                        to="#"
                        id="line-chart-link"
                        className={classnames({
                          active: type === "line",
                        })}
                        onClick={() => {
                          changeType("line")
                        }}
                      >
                        <i className="mdi mdi-chart-bell-curve align-middle font-size-18" />
                        <UncontrolledTooltip
                          placement="bottom"
                          target={"line-chart-link"}
                        >
                          {props.t("Line chart")}
                        </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        id="bar-chart-link"
                        className={classnames({
                          active: type === "bar",
                        })}
                        onClick={() => {
                          changeType("bar")
                        }}
                      >
                        <i className="mdi mdi-chart-bar align-middle font-size-18" />
                        <UncontrolledTooltip
                          placement="bottom"
                          target={"bar-chart-link"}
                        >
                          {props.t("Bar chart")}
                        </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        id="area-chart-link"
                        className={classnames({
                          active: type === "area",
                        })}
                        onClick={() => {
                          changeType("area")
                        }}
                      >
                        <i className="mdi mdi-chart-areaspline-variant font-size-18 align-middle" />
                        <UncontrolledTooltip
                          placement="bottom"
                          target={"area-chart-link"}
                        >
                          {props.t("Area chart")}
                        </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Form>
              </span>
              {tooltip != undefined && (
                <ReactApexChart
                  options={optionTypes}
                  series={series}
                  type={type}
                  height="300"
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

NodeChartActivityHistory.propTypes = {
  t: PropTypes.any,
  avg: PropTypes.any,
  tooltipUnit: PropTypes.any,
  showLoading: PropTypes.bool,
}

export default withTranslation()(NodeChartActivityHistory)
