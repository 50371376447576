import { simAction } from "../../helpers/backend_helper"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { showToast } from "utils/general"

const SimStatusDropdown = props => {
  const [simDropdownOpen, setSimDropdownOpen] = useState(false)
  const [sendingCommand, setSendingCommand] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // SIM status DNA / Elisa
  const simStatusAction = status => {
    if (props.iccid && !sendingCommand) {
      setSendingCommand(true)
      simAction(abortRef.current, props.iccid, status)
        .then(result => {
          if (result.statusCode === 200) {
            showToast(props.t("Status") + ": " + result.simStatus, "success")
          } else if (result.errorMessage) {
            showToast(props.t("Error") + ": " + result.errorMessage, "error")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setSendingCommand(false)
        })
    }
  }

  return (
    <>
      <Row>
        <Col>
          <div>
            <h6>{"SIM - ICCID"}</h6>
            <span className="text-muted">
              {props.t("Check or change SIM status")}
            </span>
          </div>
        </Col>
        <Col className="col-auto">
          <ButtonDropdown
            isOpen={simDropdownOpen}
            toggle={() => {
              setSimDropdownOpen(!simDropdownOpen)
            }}
          >
            <DropdownToggle color="primary" style={{ minWidth: "100px" }} caret>
              {props.t("Select action")}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                disabled={sendingCommand}
                onClick={() => {
                  simStatusAction(undefined)
                }}
              >
                {props.t("Check")}
              </DropdownItem>

              <DropdownItem
                disabled={sendingCommand}
                onClick={() => {
                  simStatusAction("Activate")
                }}
              >
                {props.t("Activate")}
              </DropdownItem>
              <DropdownItem
                disabled={sendingCommand}
                onClick={() => {
                  simStatusAction("Pause")
                }}
              >
                {props.t("Pause")}
              </DropdownItem>
              <DropdownItem
                disabled={sendingCommand}
                onClick={() => {
                  simStatusAction("Terminate")
                }}
              >
                {props.t("Terminate")}
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
      </Row>
    </>
  )
}

SimStatusDropdown.propTypes = {
  t: PropTypes.any,
  iccid: PropTypes.string,
}

export default withTranslation()(SimStatusDropdown)
