import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Button,
  Accordion,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import dayjs from "dayjs"
import { queryGatewayLogs } from "helpers/backend_helper"
import {
  dateFormatter,
  dateSorter,
  defaultPageSizeOptions,
  showToast,
  stringSorter,
  downloadExcel,
} from "utils/general"
import CustomRangePicker from "components/DatePickers/RangePicker"
import { Table as AntdTable } from "antd"
import { tableDefaultHeight } from "constants/layout"

const GatewayLogs = props => {
  const [logs, setLogs] = useState([])
  const [filteredLogs, setFilteredLogs] = useState([])
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [filtersOpen, setFiltersOpen] = useState("")
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(1, "days"),
    dayjs(),
  ])
  const [title] = useState(props.t("Gateway logs"))
  const [menu, setMenu] = useState(false) //Dropdown button state

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    getLogs()
  }, [dateRange])

  // Get gateway event logs
  const getLogs = () => {
    if (!ongoingSearch) {
      setOngoingSearch(true)
      queryGatewayLogs(
        abortRef.current,
        dateRange[0].valueOf(),
        dateRange[1].valueOf(),
        500,
      )
        .then(result => {
          //console.log("Logs: ", result.entries)
          if (result.statusCode == 200 && result.entries) {
            setLogs(result.entries)
          } else {
            setLogs([])
            showToast(props.t("Error"), "error")
          }
        })
        .catch(e => {
          console.log(e)
          setLogs([])
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingSearch(false)
        })
    }
  }

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, logs])

  // Handle search function
  const handleSearch = () => {
    let result = logs.filter(
      data =>
        data.name?.toLowerCase().includes(searchText) ||
        data.event?.toLowerCase().includes(searchText) ||
        data.key?.toLowerCase().includes(searchText)
    )
    setFilteredLogs(result)
  }

  const columns = [
    {
      dataIndex: "timestamp",
      title: props.t("Time"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["timestamp"], b["timestamp"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "event",
      title: props.t("Event"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "event", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "name",
      title: props.t("Name"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "name", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "key",
      title: props.t("GUID"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "key", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
  ]

  const onDateSelection = value => {
    setDateRange(value)
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [props.t("Time"), props.t("Event"), props.t("Name"), props.t("GUID")],
    ]
    const data = filteredLogs.map(elt => [
      dateFormatter(elt.timestamp),
      elt.event,
      elt.name,
      elt.key,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Gateway logs")} />

          <Card>
            <CardBody>
              <Accordion
                flush
                open={filtersOpen}
                toggle={id => {
                  id === filtersOpen ? setFiltersOpen("") : setFiltersOpen(id)
                }}
              >
                <AccordionItem>
                  <AccordionBody
                    accordionId="1"
                    className="mb-3"
                    style={{
                      backgroundColor: "#e8f0fe",
                      border: "1px solid #ced4da",
                    }}
                  >
                    <Row>
                      <Col></Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
              <Row>
                <Col>
                  {logs != null && (
                    <React.Fragment>
                      <Row className="mb-1">
                        {" "}
                        <Col className="col-auto pe-0">
                          <Form
                            className="mt-sm-0 d-flex align-items-center"
                            onSubmit={e => {
                              e.preventDefault()
                              return false
                            }}
                          >
                            <div className="search-box me-2 mb-2 d-inline-block float-end">
                              <div className="position-relative">
                                <Input
                                  type="text"
                                  onChange={event =>
                                    setSearchText(
                                      event.target.value.toLowerCase()
                                    )
                                  }
                                  placeholder={props.t("Search")}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Form>
                        </Col>
                        <Col>
                          <CustomRangePicker
                            value={dateRange}
                            rangeChanged={onDateSelection}
                            showTime={true}
                            allowClear={false}
                          />
                        </Col>
                        <Col className="col-auto">
                          <Button
                            //className="ms-3"
                            style={{ borderRadius: "100px" }}
                            color="primary"
                            disabled={ongoingSearch}
                            onClick={() => {
                              //getLogs()
                              setDateRange([dateRange[0], dayjs()])
                            }}
                          >
                            <i className="mdi mdi-refresh me-2" />
                            {props.t("Refresh data")}
                          </Button>
                        </Col>
                        <Col>
                          <span className="float-end ms-2">
                            <UncontrolledTooltip
                              placement="bottom"
                              target="downloadstatstable"
                            >
                              {props.t("Download")}
                            </UncontrolledTooltip>
                            <Dropdown
                              id="printButtonServicesStats"
                              isOpen={menu}
                              toggle={() => setMenu(!menu)}
                              className="download-btn text-center"
                              type="button"
                            >
                              <DropdownToggle
                                id="downloadstatstable"
                                className="text-muted"
                                tag="i"
                              >
                                <i className="mdi mdi-download" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => generateExcel()}>
                                  {props.t("Download .xlsx")}
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                          <div className="mt-2 float-end">
                            <p>
                              {filteredLogs.length} {props.t("results found")}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xl="12">
                          <AntdTable
                            className="colored-header narrow-header"
                            bordered
                            size="small"
                            sticky
                            rowKey={"id"}
                            columns={columns}
                            dataSource={filteredLogs}
                            pagination={{
                              showSizeChanger: true,
                              defaultPageSize: 50,
                              pageSizeOptions: defaultPageSizeOptions,
                            }}
                            loading={ongoingSearch}
                            tableLayout="auto"
                            scroll={{
                              x: "max-content",
                              y: tableDefaultHeight,
                            }}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

GatewayLogs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(GatewayLogs)
