import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  Alert,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Drawer, Popconfirm, Switch } from "antd"
import {
  deleteVideoDevice,
  getVideoExtras,
  rebootVideoDevice,
  updateVideoDevice,
  videoForceRefresh,
  videoGetClientStatus,
  videoSendPush,
} from "helpers/backend_helper"
import SelectOrder from "components/DropdownList/SelectOrder"
import { showToast } from "utils/general"
import withRouter from "components/Common/withRouter"
import SimStatusDropdown from "components/Misc/SimStatusDropdown"

const VideoDeviceDrawer = props => {
  const [updateError, setUpdateError] = useState("")
  const [updateSuccess, setUpdateSuccess] = useState("")
  const [update, setUpdate] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [clearBtConfirm, setClearBtConfirm] = useState(false)

  const [command, setCommand] = useState("RestartVideo")
  const [sendingCommand, setSendingCommand] = useState(false)
  const [statusOngoing, setStatusOngoing] = useState(false)
  const [refreshOngoing, setRefreshOngoing] = useState(false)
  const [extras, setExtras] = useState("")
  const [loadingExtras, setLoadingExtras] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Clear extras
  useEffect(() => {
    setExtras("")
  }, [props.selectedRow])

  // Update validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      iccid: props.selectedRow?.iccid || "",
      imei: props.selectedRow?.imei || "",
      teamViewerId: props.selectedRow?.teamViewerId || "",
      orderNumber: props.selectedRow?.orderNumber || "",
      ignoreAlarms: props.selectedRow?.ignoreAlarms || false,
      debugging: props.selectedRow?.debugging || false,
    },
    validationSchema: Yup.object({
      orderNumber: Yup.string(),
      iccid: Yup.string(),
      teamViewerId: Yup.string(),
    }),
    onSubmit: values => {
      console.log("Submit values: ", values)
      setUpdateSuccess("")
      setUpdateError("")
      setUpdate(true)

      values.orderNumber = values.orderNumber ? values.orderNumber : ""

      updateVideoDevice(abortRef.current, props.selectedRow?.serial, values)
        .then(result => {
          console.log(result)
          setUpdate(false)
          setUpdateSuccess(props.t("Updated"))
          props.getDevices()
        })
        .catch(err => {
          console.log(err)
          setUpdate(false)
          setUpdateError(props.t("Error"))
        })
    },
  })

  // Fetch extras
  const fetchExtras = () => {
    if (props.selectedRow && !loadingExtras) {
      setLoadingExtras(true)
      getVideoExtras(abortRef.current, props.selectedRow?.serial)
        .then(result => {
          //console.log(result)
          if (result.statusCode == 200 && result.data) {
            try {
              let resArray = []
              //let temp = ""
              let jsonObj = JSON.parse(result.data)
              for (let prop in jsonObj) {
                if (jsonObj.hasOwnProperty(prop)) {
                  if (
                    typeof jsonObj[prop] !== "object" &&
                    jsonObj[prop] !== null
                  ) {
                    if (
                      typeof jsonObj[prop] === "string" &&
                      jsonObj[prop].length > 0 &&
                      jsonObj[prop].startsWith("{")
                    ) {
                      try {
                        let row = prop + ": " + jsonObj[prop]
                        resArray.push(row)

                        /*let innerJsonObj = JSON.parse(jsonObj[prop])
                        for (let innerProp in innerJsonObj) {
                          if (
                            innerJsonObj.hasOwnProperty(innerProp) &&
                            typeof innerJsonObj[innerProp] !== "object" &&
                            innerJsonObj[innerProp] !== null
                          ) {
                            temp =
                              temp +
                              "" +
                              innerProp +
                              ": " +
                              innerJsonObj[innerProp] +
                              "\n";

                            let row = innerProp + ": " + innerJsonObj[innerProp]
                            resArray.push(row)
                          }
                        }*/
                      } catch (err) {}
                    } else {
                      //temp = temp + "" + prop + ": " + jsonObj[prop] + "\n"
                      let row = prop + ": " + jsonObj[prop]
                      resArray.push(row)
                    }
                  }
                }
              }

              resArray.sort((a, b) => {
                return a.localeCompare(b)
              })
              setExtras(resArray.join("\n"))
              //setExtras(temp)
            } catch (err) {}
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoadingExtras(false)
        })
    }
  }

  // Delete device
  const deleteDevice = () => {
    if (
      props.selectedRow.serial != null &&
      props.selectedRow.serial.length > 0
    ) {
      setOngoingDelete(true)
      deleteVideoDevice(abortRef.current, props.selectedRow.serial)
        .then(result => {
          if (result.statusCode === 200) {
            setDeleteModal(false)
            showToast(props.t("Deleted"), "success")
            props.getDevices()
            props.setShowDrawer(false)
          } else {
            showToast(props.t("Error"), "error")
          }
          setOngoingDelete(false)
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
          setOngoingDelete(false)
        })
    }
  }

  // Clear BT device list
  const clearBluetooths = () => {
    if (!update && !ongoingDelete) {
      let values = { bluetoothDeviceList: "" }
      updateVideoDevice(abortRef.current, props.selectedRow?.serial, values)
        .then(result => {
          props.getDevices()
          showToast(props.t("Cleared"), "success")
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdate(false)
        })
    }
  }

  // Send push command
  const sendPush = () => {
    if (props.selectedRow?.pushToken) {
      setSendingCommand(true)
      videoSendPush(abortRef.current, props.selectedRow?.serial, command)
        .then(result => {
          console.log("Push result: ", result)
          if (result.statusCode == 200) {
            showToast(props.t("Command sent"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
          console.log(err)
        })
        .finally(() => {
          setSendingCommand(false)
        })
    }
  }

  // Get client status from AC
  const getClientStatus = () => {
    if (props.selectedRow?.roomName) {
      setStatusOngoing(true)
      videoGetClientStatus(abortRef.current, props.selectedRow?.roomName)
        .then(result => {
          console.log(result)
          showToast(props.t("Sent"), "success")
          setStatusOngoing(false)
          props.getDevices()
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
          setStatusOngoing(false)
        })
    }
  }

  // Reboot video device
  const rebootDevice = () => {
    if (props.selectedRow?.serial) {
      setSendingCommand(true)
      rebootVideoDevice(abortRef.current, props.selectedRow.serial)
        .then(result => {
          console.log(result)
          if (result.statusCode == 200) {
            showToast(props.t("Sent"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setSendingCommand(false)
        })
    }
  }

  // Force client refresh
  const forceRefreshClient = () => {
    if (props.selectedRow?.roomName) {
      setRefreshOngoing(true)
      videoForceRefresh(abortRef.current, props.selectedRow.roomName)
        .then(result => {
          console.log(result)
          showToast(props.t("Sent"), "success")
          setRefreshOngoing(false)
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
          setRefreshOngoing(false)
        })
    }
  }

  // Selected order changed
  const orderSelectionChanged = value => {
    validation.setFieldValue("orderNumber", value)
  }

  return (
    <>
      <Drawer
        rootClassName="custom"
        title={props.t("Device") + " " + props.selectedRow?.serial}
        // width={"700px"}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
          setUpdateError("")
          setUpdateSuccess("")
        }}
        open={props.showDrawer}
        zIndex={1003}
        //bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        <Row xs={1}>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">{props.t("Actions")}</CardTitle>
                <ListGroup className="list-group-flush">
                  <ListGroupItem className="pb-4">
                    <Row>
                      <Col>
                        <div>
                          <h6>{props.t("Send push command")}</h6>
                          <Input
                            bsSize="sm"
                            className="mt-2"
                            type="select"
                            name="pushCommand"
                            style={{ maxWidth: "300px" }}
                            value={command}
                            onChange={v => {
                              setCommand(v.target.value)
                            }}
                          >
                            <option value="Connect">
                              {props.t("Connect")}
                            </option>
                            <option value="StartVideo">
                              {props.t("Start Video app")}
                            </option>
                            <option value="RestartVideo">
                              {props.t("Restart Video app")}
                            </option>
                            <option value="ShutdownVideo">
                              {props.t("Shutdown Video app")}
                            </option>
                            <option value="BringVideoFront">
                              {props.t("Bring Video app to front")}
                            </option>
                            {/** 
                            <option value="NavigateHealth">
                              {props.t("Navigate from Video to Health")}
                            </option>
                            <option value="StartHealth">
                              {props.t("Start Health app")}
                            </option>
                            <option value="RestartHealth">
                              {props.t("Restart Health app")}
                            </option>
                            <option value="NavigateVideo">
                              {props.t("Navigate from Health to Video")}
                            </option>*/}
                            <option value="StartLauncher">
                              {props.t("Start launcher app")}
                            </option>
                            <option value="StartTeamViewer">
                              {props.t("Start TeamViewer Host")}
                            </option>
                            <option value="StartMonitor">
                              {props.t("Start Monitor app")}
                            </option>
                            <option value="OpenSettings">
                              {props.t("Open settings")}
                            </option>
                          </Input>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          color="primary"
                          disabled={
                            sendingCommand || !props.selectedRow?.pushToken
                          }
                          style={{ minWidth: "100px" }}
                          onClick={() => {
                            sendPush()
                          }}
                        >
                          {props.t("Send")}
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="py-4">
                    <Row>
                      <Col>
                        <div>
                          <h6>{props.t("Refresh video status")}</h6>
                          <span className="text-muted">
                            {props.t("Query current video status from API")}
                          </span>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          color="primary"
                          style={{ minWidth: "100px" }}
                          onClick={() => {
                            getClientStatus()
                          }}
                          disabled={
                            !props.selectedRow?.roomName || statusOngoing
                          }
                        >
                          {props.t("Send")}
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="py-4">
                    <Row>
                      <Col>
                        <div>
                          <h6>{props.t("Page refresh")}</h6>
                          <span className="text-muted">
                            {props.t(
                              "Force the video client to refresh the webpage"
                            )}
                          </span>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          color="primary"
                          style={{ minWidth: "100px" }}
                          onClick={() => {
                            forceRefreshClient()
                          }}
                          disabled={
                            !props.selectedRow?.roomName || refreshOngoing
                          }
                        >
                          {props.t("Refresh")}
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  {(props.selectedRow?.serial.startsWith("PP") || props.selectedRow?.serial.startsWith("EH") ) && (
                    <ListGroupItem className="py-4">
                      <Row>
                        <Col>
                          <div>
                            <h6>{props.t("Reboot")}</h6>
                            <span className="text-muted">
                              {props.t("Send a reboot command to the device")}
                            </span>
                          </div>
                        </Col>
                        <Col className="col-auto">
                          <Button
                            color="primary"
                            style={{ minWidth: "100px" }}
                            onClick={() => {
                              rebootDevice()
                            }}
                            disabled={sendingCommand}
                          >
                            {props.t("Send")}
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )}

                  {props.selectedRow?.iccid && (
                    <ListGroupItem className="pb-4">
                      <SimStatusDropdown iccid={props.selectedRow.iccid} />
                    </ListGroupItem>
                  )}

                  <ListGroupItem className="pb-4">
                    <Row>
                      <Col>
                        <div>
                          <h6>{props.t("Clear Bluetooth list")}</h6>
                          <span className="text-muted">
                            {props.t(
                              "List of connected Bluetooth devices will be cleared"
                            )}
                          </span>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <Popconfirm
                          title={props.t("Confirm action")}
                          open={clearBtConfirm}
                          okType="danger"
                          okText={props.t("Clear")}
                          cancelText={props.t("Cancel")}
                          onConfirm={() => {
                            clearBluetooths()
                            setClearBtConfirm(false)
                          }}
                          onCancel={() => {
                            setClearBtConfirm(false)
                          }}
                        >
                          <Button
                            color="danger"
                            style={{ minWidth: "100px" }}
                            disabled={update || ongoingDelete}
                            onClick={() => {
                              setClearBtConfirm(true)
                            }}
                          >
                            {props.t("Clear")}
                          </Button>
                        </Popconfirm>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="pb-4">
                    <Row>
                      <Col>
                        <div>
                          <h6>{props.t("Deletion")}</h6>
                          <span className="text-muted">
                            {props.t(
                              "The device and all related data will be deleted permanently"
                            )}
                          </span>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          color="danger"
                          style={{ minWidth: "100px" }}
                          onClick={() => {
                            setDeleteModal(true)
                          }}
                        >
                          {props.t("Delete")}
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  {props.t("Update information")}
                </CardTitle>
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    return false
                  }}
                >
                  <Row xs={1} lg={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("ICCID")}</Label>
                        <Input
                          bsSize="sm"
                          name="iccid"
                          type="text"
                          value={validation.values.iccid}
                          onChange={validation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("IMEI")}</Label>
                        <Input
                          bsSize="sm"
                          name="imei"
                          type="text"
                          value={validation.values.imei}
                          onChange={validation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("TeamViewer ID")}</Label>
                        <Input
                          bsSize="sm"
                          name="teamViewerId"
                          type="text"
                          value={validation.values.teamViewerId}
                          onChange={validation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Order number")}</Label>
                        <SelectOrder
                          selection={validation.values.orderNumber}
                          setSelectedOrder={orderSelectionChanged}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup className="mt-2">
                        <Switch
                          className="me-2"
                          name="ignoreAlarms"
                          checked={validation.values.ignoreAlarms}
                          onChange={v => {
                            validation.setFieldValue("ignoreAlarms", v)
                          }}
                        ></Switch>
                        <Label>{props.t("Ignore alarms")}</Label>
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup className="mt-2">
                        <Switch
                          className="me-2"
                          name="debugging"
                          checked={validation.values.debugging}
                          onChange={v => {
                            validation.setFieldValue("debugging", v)
                          }}
                        ></Switch>
                        <Label>{props.t("Debugging")}</Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="mt-2 mb-3 d-flex justify-content-center">
                    <Button
                      color="primary"
                      disabled={update}
                      style={{ minWidth: "150px" }}
                      onClick={() => {
                        validation.submitForm()
                      }}
                    >
                      {props.t("Save")}
                    </Button>
                  </div>
                  {updateError ? (
                    <Alert color="danger">{updateError}</Alert>
                  ) : null}
                  {updateSuccess ? (
                    <Alert color="success">{updateSuccess}</Alert>
                  ) : null}
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  <Row>
                    <Col>{props.t("Additional info")}</Col>
                    <Col className="col-auto">
                      <Button
                        color="primary"
                        size="sm"
                        disabled={loadingExtras}
                        className=""
                        onClick={() => {
                          fetchExtras()
                        }}
                      >
                        <i className="me-2 mdi mdi-refresh text-truncate align-middle"></i>
                        <span className="">{props.t("Fetch")}</span>
                      </Button>
                    </Col>
                  </Row>
                </CardTitle>
                <FormGroup>
                  <Input
                    name="extras"
                    type="textarea"
                    rows={30}
                    disabled={true}
                    value={extras}
                  ></Input>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Drawer>

      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody>
          <p>
            {props.t("Are you sure you want to delete") +
              " " +
              props.selectedRow?.serial +
              "?"}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={ongoingDelete}
            color="danger"
            onClick={() => {
              deleteDevice()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

VideoDeviceDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getDevices: PropTypes.func,
}

export default withRouter(withTranslation()(VideoDeviceDrawer))
