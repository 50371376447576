import React, { useEffect, useState } from "react"
import { Row, Col, Button } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

// Datatable related plugins
import "../../Reporting/datatables.scss"
import { Table as AntdTable } from "antd"
import { dateFormatter, defaultPageSizeOptions } from "utils/general"
import { tableDefaultHeight } from "constants/layout"

const MonitoringAlarmTable = props => {
  const [alarms, setAlarms] = useState([])

  //Setting data state
  useEffect(() => {
    setAlarms(props.data)
    //console.log(props.data)
  }, [props.data])

  const actionsFormatter = (cell, row) => (
    <Button
      color="primary"
      outline
      size="sm"
      id={row.id}
      onClick={() => {
        props.setShowDrawer(true)
        props.setSelectedRow(row)
      }}
    >
      {props.t("Show")}
    </Button>
  )

  // Table structure
  const columns = [
    {
      dataIndex: "action",
      title: "", //props.t("Actions"),
      // isDummyField: true,
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { width: "150px", textAlign: "center" }
      },
      render: actionsFormatter,
    },
    /*{
      dataField: "status",
      text: props.t("Status"),
      sort: true,
      formatter: statusFormatter,
    },*/
    {
      dataIndex: "description",
      title: props.t("Alarm"),
      // sort: true,
      sorter: (a, b) => {
        return props.t(a.description) < props.t(b.description) ? 1 : -1
      },
      render: descriptionFormatter,
    },

    {
      dataIndex: "client",
      title: props.t("Customer"),
      // sort: true,
      sorter: (a, b) => {
        return a.client < b.client ? 1 : -1
      },
    },
    {
      dataIndex: "updated_at",
      title: props.t("Time"),
      // sort: true,
      sorter: (a, b) => {
        return a.updated_at - b.updated_at
      },
      render: dateFormatter,
    },
    /*{
      dataField: "acked_by",
      text: props.t("Handler"),
      sort: true,
    },
    {
      dataField: "closed_by",
      text: props.t("Closed by"),
      sort: true,
      // formatter: timeFormatter,
    },
    {
      dataField: "ack_time",
      text: props.t("Handling time"),
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: "close_time",
      text: props.t("Closing time"),
      sort: true,
      formatter: timeFormatter,
    },*/
  ]

  function descriptionFormatter(cell, row) {
    //return props.t(value)
    if (row.status == "CLOSED") {
      return (
        <>
          <i
            style={{ verticalAlign: "bottom" }}
            className="bx bx-check-circle font-size-20 text-success me-2"
          />
          <span>{props.t(cell)}</span>
        </>
      )
    } else if (row.status == "ASSIGNED") {
      return (
        <>
          <i
            style={{ verticalAlign: "bottom" }}
            className="bx bxs-calendar-heart font-size-20 text-warning me-2"
          />
          <span>{props.t(cell)}</span>
        </>
      )
    } else {
      return (
        <>
          <i
            style={{ verticalAlign: "bottom" }}
            className="bx bxs-calendar-heart font-size-20 text-danger me-2"
          />
          <span>{props.t(cell)}</span>
        </>
      )
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          {alarms != undefined && (
            <AntdTable
              className="clear-header"
              bordered={false}
              size="middle"
              loading={props.loading}
              sticky
              rowKey={"key"}
              columns={columns}
              dataSource={alarms}
              pagination={{
                showSizeChanger: true,
                defaultPageSize: 50,
                pageSizeOptions: defaultPageSizeOptions,
              }}
              tableLayout="auto"
              scroll={{
                x: "max-content",
                y: tableDefaultHeight,
              }}
            />
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

MonitoringAlarmTable.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  setShowDrawer: PropTypes.func,
  setSelectedRow: PropTypes.func,
  loading: PropTypes.bool,
}

export default withTranslation()(MonitoringAlarmTable)
