import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { getTrackerList } from "helpers/backend_helper"
import { Select, ConfigProvider } from "antd"
import { Col, Row } from "reactstrap"
const { Option, OptGroup } = Select;

const SelectTracker = props => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchString, setSearchString] = useState("")

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Get trackers for dropdown
  const getData = () => {
    setLoading(true)
    getTrackerList(abortRef.current).then(data => {
      if (data.array != null) {
        let opts = []
        let counter = 1
        data.array.forEach(group => {
          let groupObject = new Object()
          groupObject.id = counter++
          groupObject.label = group.name
          groupObject.value = group.name
          groupObject.gsm = ""
          groupObject.imei = ""
          groupObject.imsi = ""
          groupObject.options = []

          group.trackers.sort((a, b) => (a.name > b.name ? 1 : -1))
          group.trackers.forEach(tracker => {
            let trackerObject = new Object()
            trackerObject.label = tracker.name
            trackerObject.value = tracker.id
            trackerObject.gsm = tracker.gsm ? tracker.gsm : ""
            trackerObject.imei = tracker.imei ? tracker.imei : ""
            trackerObject.imsi = tracker.imsi ? tracker.imsi : ""

            groupObject.options.push(trackerObject)
          })
          if (groupObject.options.length > 0) {
            opts.push(groupObject)
          }
        })
        setOptions(opts)
      }
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  // Selection changed
  const handleChange = newSelection => {
    if (props.onChange != null) {
      props.onChange(newSelection)
    }
  }

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      {loading
        ? <p>{props.t("Loading")}</p>
        : <p>{props.t("No entries found")}</p>
      }
    </div>
  )

  // Dropdown visibility, get data at first
  const visibilityChanged = (visible) => {
    if (visible && options.length == 0 && !loading) {
      getData()
    }
  }

  return (
    <div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <div className="config-provider">
        <Select
          showSearch 
          loading={loading}
          onDropdownVisibleChange={visibilityChanged}
          value={props.selection}
          mode={props.multiple ? "multiple": null}
          allowClear={props.clearable != null ? props.clearable : true}
          placeholder={props.t("Select tracker")}
          style={{ width: 100 + "%" }}
          onChange={handleChange}
          //options={options}
          onSearch={s => {setSearchString(s)}}
          filterOption={(inputValue, option) => {
            return (
              option.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
              option.value?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
              option.gsm?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
              option.imei?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
              option.imsi?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            );
          }}
        >
          {options.map((item, index) => { 
            return (
              <OptGroup key={item.value} value={item.value} label={item.label}>
                {item.options.map((tracker, index2) => { 
                  return (
                    <Option key={tracker.value} value={tracker.value} label={tracker.label} gsm={tracker.gsm} imsi={tracker.imsi} imei={tracker.imei}>
                      <Row type="flex" justify="start">
                        <Col className="col-auto">{tracker.label}</Col>
                        {searchString && tracker.gsm?.toLowerCase().indexOf(searchString) >= 0 && 
                          <Col className="col-auto"><strong className="text-primary">GSM: </strong>{tracker.gsm}</Col>
                        }
                        {searchString && tracker.imei?.toLowerCase().indexOf(searchString) >= 0 && 
                          <Col className="col-auto"><strong className="text-primary">IMEI: </strong>{tracker.imei}</Col>
                        }
                        {searchString && tracker.imsi?.toLowerCase().indexOf(searchString) >= 0 && 
                          <Col className="col-auto"><strong className="text-primary">IMSI: </strong>{tracker.imsi}</Col>
                        }
                        {searchString && tracker.value?.toLowerCase().indexOf(searchString) >= 0 && 
                          <Col className="col-auto"><strong className="text-primary">GUID: </strong>{tracker.value}</Col>
                        }
                        </Row>
                    </Option>
                  )
                })}
              </OptGroup>
            )
          })}
        </Select>
      </div>
      </ConfigProvider>
    </div>
  )
}

SelectTracker.propTypes = {
  t: PropTypes.any,
  selection: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
}

export default withTranslation()(SelectTracker)