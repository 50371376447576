import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { getNodeValues } from "helpers/backend_helper"
import RangeCalendarServices from "../../filter-calendar-services"

const CompareNodesModal = props => {
  const [node1, setNode1] = useState()
  const [node2, setNode2] = useState()
  const [start, setStart] = useState()
  const [end, setEnd] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // abortRef.current = new AbortController()
    console.log(
      props.node1,
      props.node2,
      end,
      start
      // props.user,
      // abortRef.current
    )
    // return () => abortRef.current.abort()
  }, [])
  // First node
  useEffect(() => {
    if (props.node1 !== undefined) {
      setNode1(props.node1)
    }
  }, [props.node1])

  // Second node
  useEffect(() => {
    if (props.node2 !== undefined) {
      setNode2(props.node2)
    }
  }, [props.node2])

  useEffect(() => {
    if (end != undefined) {
      getNodeValues(props.user, node1, start, end)
        .then(data => {
          console.log("Node1 values data: ", data)
          // if (data.entries != undefined && data.entries.length > 0) {
          //   setTempData(
          //     data.entries?.map(item => [item.date, item.temperature])
          //   )
          //   setHumData(data.entries?.map(item => [item.date, item.humidity]))
          //   setActivityData(
          //     data.entries?.map(item => [item.date, item.activity])
          //   )
          // } else {
          //   setActivityData([])
          //   setTempData([])
          //   setHumData([])
          // }
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [end])

  useEffect(() => {
    if (end != undefined) {
      getNodeValues(props.user, node2, start, end)
        .then(data => {
          console.log("Node2 values data: ", data)
          // if (data.entries != undefined && data.entries.length > 0) {
          //   setTempData(
          //     data.entries?.map(item => [item.date, item.temperature])
          //   )
          //   setHumData(data.entries?.map(item => [item.date, item.humidity]))
          //   setActivityData(
          //     data.entries?.map(item => [item.date, item.activity])
          //   )
          // } else {
          //   setActivityData([])
          //   setTempData([])
          //   setHumData([])
          // }
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [end])

  return (
    <>
      <ModalBody>
        {loading ? (
          <div className="py-5 text-center">
            <p className="text-primary">
              <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
              {props.t("Loading")}
            </p>
          </div>
        ) : (
          <div>
            <div>
              <RangeCalendarServices
                // startDefault={historyDays}
                rangePicker={undefined}
                setCalendarStartDate={setStart}
                setCalendarEndDate={setEnd}
              />
            </div>{" "}
            Demo results
          </div>
        )}
      </ModalBody>
    </>
  )
}

CompareNodesModal.propTypes = {
  t: PropTypes.any,
  node1: PropTypes.any,
  node2: PropTypes.any,
  user: PropTypes.any,
}

export default withTranslation()(CompareNodesModal)
