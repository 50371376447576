import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"
import { dateFormatter, defaultPageSizeOptions, numberSorter, showToast, stringSorter } from "utils/general"
import { getVideoDeviceStatusHistory } from "helpers/backend_helper"
import { Table as AntdTable, Select, Switch } from "antd"
import ReactApexChart from "react-apexcharts"
import moment from "moment"

// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"


const VideoDeviceHistory = props => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [connectionBreakChart, setConnectionBreakChart] = useState([])
  const [batteryChartData, setBatteryChartData] = useState([])
  const [memoryChartData, setMemoryChartData] = useState([])
  const [currentChartData, setCurrentChartData] = useState([])
  const [signalStrengthChartData, setSignalStrengthChartData] = useState([])
  const [selectedChart, setSelectedChart] = useState("battery")
  const [showConnection, setShowConnection] = useState(true)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.serial) {
      getDeviceData()
    }
  }, [props.serial])

  // Get devices history data
  const getDeviceData = () => {
    if (props.serial && !loading) {
      setLoading(true)
      getVideoDeviceStatusHistory(abortRef.current, props.serial).then(result => {
        if (result.statusCode == 200) {
          setData(result.entries)

          let batChart = [], memChart = [], currentChart = [], signalChart = []
          let breakChart = [], prev, bigGap = false
          result.entries.forEach(element => {

            // Detect bigger breaks between entries for connection chart
            if (prev) {
              let ts = moment(element.timestamp)
              let diff = ts.diff(moment(prev), "minutes")
              if (diff > 40) {
                bigGap = true
                let temp = []
                temp.push(prev)
                temp.push(1)
                breakChart.push(temp)
              }
              else if (bigGap) {
                bigGap = false
                let temp = []
                temp.push(element.timestamp)
                temp.push(0)
                breakChart.push(temp)
              }
            }
            else {
              let temp = []
              temp.push(element.timestamp)
              temp.push(0)
              breakChart.push(temp)
            }
            prev = element.timestamp

            // Battery
            let temp = []
            temp.push(element.timestamp)
            temp.push(element.batteryLevel)
            batChart.push(temp)

            // Memory
            temp = []
            temp.push(element.timestamp)
            temp.push(element.availableMemory)
            memChart.push(temp)

            // Battery charge average current
            temp = []
            temp.push(element.timestamp)
            temp.push(element.batteryCurrentAverage)
            currentChart.push(temp)

            // Cell signal strength
            let cellStr = parseInt(element.cellSignalStrength, 10)
            if (!isNaN(parseFloat(cellStr))) {
              temp = []
              temp.push(element.timestamp)
              temp.push(cellStr)
              signalChart.push(temp)
            }
          });

          let temp = []
          temp.push(prev)
          temp.push(0)
          breakChart.push(temp)

          setConnectionBreakChart(breakChart)
          setBatteryChartData(batChart)
          setMemoryChartData(memChart)
          setCurrentChartData(currentChart)
          setSignalStrengthChartData(signalChart)
        }
      })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const columns = [
    {
      dataIndex: "timestamp",
      title: props.t("Time"),
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        return a.timestamp - b.timestamp
      },
      render: dateFormatter,
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "batteryLevel",
      title: props.t("Battery"),
      sorter: (a, b) => {
        return numberSorter(a["batteryLevel"], b["batteryLevel"])
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "isCharging",
      title: props.t("Charging"),
      align: "center",
      sorter: (a, b) => {
        return a.isCharging - b.isCharging
      },
      render: cell => {
        return cell ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <></>
        )
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "chargingSource",
      title: props.t("Charging source"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "chargingSource", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "batteryCurrentAverage",
      title: props.t("Current avg"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(
          a["batteryCurrentAverage"],
          b["batteryCurrentAverage"],
          sortOrder
        )
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "availableMemory",
      title: props.t("Available memory"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(
          a["availableMemory"],
          b["availableMemory"],
          sortOrder
        )
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "networkType",
      title: props.t("Network"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "networkType", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "cellInfo",
      title: props.t("Cell info"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "cellInfo", sortOrder)
      },
      width: "250px",
      ellipsis: true,
    },
    {
      dataIndex: "cellSignalStrength",
      title: props.t("Cell strength"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "cellSignalStrength", sortOrder)
      },
      width: "220px",
      ellipsis: true,
    },
    {
      dataIndex: "event",
      title: props.t("Event"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "event", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
  ]

  const options = {
    chart: {
      id: "video-history-chart",
      locales: [fi, en, se],
      defaultLocale: "fi",
      zoom: {
        enabled: true,
      },
      selection: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
    },
    stroke: {
      width: [3, 1],
      //curve: "smooth",
      curve: ['smooth', 'stepline']
    },
    colors: ["#F8C471", "#85C1E9", "#85C1E9"],
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: showConnection ? [
      {},
      {
        show: false,
        opposite: true,
        max: 1,
        min: 0,
      }
    ] : [{}],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        format: "dd.MM.yyyy HH:mm",
      },
    },
  }

  // Return series data for selected chart type
  const checkingSeries = () => {
    let series = []
    if (selectedChart == "battery") {
      series.push(
        {
          name: props.t("Battery"),
          type: "line",
          data: batteryChartData,
        }
      )
    }
    if (selectedChart == "memory") {
      series.push(
        {
          name: props.t("Available memory"),
          type: "line",
          data: memoryChartData,
        }
      )
    }
    if (selectedChart == "current") {
      series.push(
        {
          name: props.t("Current avg"),
          type: "line",
          data: currentChartData,
        }
      )
    }
    if (selectedChart == "signal") {
      series.push(
        {
          name: props.t("Signal strength"),
          type: "line",
          data: signalStrengthChartData,
        }
      )
    }
    if (showConnection) {
      series.push(
        {
          name: props.t("Connection issue"),
          type: "area",
          data: connectionBreakChart,
        }
      )
    }
    return series
  }

  return (
    <>
      <Row className="mb-2">
        <Col className="col-auto">
          <Button
            disabled={loading}
            size="sm"
            color="primary"
            onClick={() => { getDeviceData() }}
          >
            {props.t("Refresh data")}
          </Button>
        </Col>
        <Col className="d-flex">
          {loading &&
            <span className="text-primary" style={{ alignSelf: "center" }}>
              <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
              {props.t("Loading")}
            </span>
          }
        </Col>
        <Col className="col-auto d-inline-flex">
          <div className="d-flex mt-1 me-1">
            <Switch
              className="ms-2"
              id="connection_switch"
              name="connection_switch"
              checkedChildren={<i className="mdi mdi-cloud-off-outline" />}
              unCheckedChildren={<i className="mdi mdi-cloud-off-outline" />}
              checked={showConnection}
              onChange={v => setShowConnection(v)}
            />
            <UncontrolledTooltip placement="bottom" target="connection_switch">
              {props.t("Show connection breaks")}
            </UncontrolledTooltip>
          </div>
        </Col>
        <Col className="col-auto">
          <Select
            defaultValue={"battery"}
            style={{ minWidth: "150px" }}
            onChange={(newValue) => {
              setSelectedChart(newValue)
            }}
            options={[
              { value: "battery", label: props.t("Battery") },
              { value: "current", label: props.t("Current avg") },
              { value: "memory", label: props.t("Available memory") },
              { value: "signal", label: props.t("Signal strength") },
            ]}
            virtual={false}
          />
        </Col>
      </Row>

      <ReactApexChart
        options={options}
        series={checkingSeries()}
        type="line"
        height="280"
      />

      <AntdTable
        className="colored-header narrow-header"
        size="small"
        sticky
        rowKey={"timestamp"}
        columns={columns}
        dataSource={data}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 100,
          pageSizeOptions: defaultPageSizeOptions,
        }}
        tableLayout="auto"
        scroll={{
          x: "max-content",
          y: "30vh",
        }}
      />

    </>
  )
}

VideoDeviceHistory.propTypes = {
  t: PropTypes.any,
  serial: PropTypes.string,
}

export default withRouter(withTranslation()(VideoDeviceHistory))