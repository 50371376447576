import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
  UncontrolledTooltip,
  FormFeedback,
  CardHeader,
  Progress,
  ButtonGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { withTranslation } from "react-i18next"
import dayjs from "dayjs"
import XLSX from "xlsx"
import {
  createOrder,
  getOrder,
  queryOrderDevices,
  updateOrder,
} from "helpers/backend_helper"
import SelectOrder from "components/DropdownList/SelectOrder"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import * as Yup from "yup"
import { useFormik } from "formik"
import { ConfigProvider, DatePicker, Switch } from "antd"
import GlobalState from "contexts/GlobalState"

import Lottie from "react-lottie"
import {
  showToast,
  getLocale,
  formatDateFull,
  formatDate,
  getLottieLoadingOptions,
} from "utils/general"

const OrderManagement = props => {
  const [state] = useContext(GlobalState)
  const [userRole, setUserRole] = useState(null)
  const [order, setOrder] = useState(null)
  const [orders, setOrders] = useState([])
  const [filteredOrders, setFilteredOrders] = useState([])
  const [devices, setDevices] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [addModal, setAddModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [loading, setLoading] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [realTimeDevices, setRealTimeDevices] = useState(true)
  const [trackingNumbers, setTrackingNumbers] = useState([])
  const [changeLogs, setChangeLogs] = useState([])
  const [updateCounter, setUpdateCounter] = useState(0)
  const [hideClients, setHideClients] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [visibleCards, setVisibleCards] = useState(12)
  const [selectedStatus, setSelectedStatus] = useState(-1)
  const [searchText, setSearchText] = useState("")
  const [openDownload, setOpenDownload] = useState(false)
  const [title] = useState(props.t("Orders"))

  const locale = getLocale()
  const navigate = useNavigate()
  const location = useLocation()

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
      if (role === "system_admin") {
        setHideClients(false)
      }
    }
    return () => abortRef.current.abort()
  }, [])

  // Update selectedOrder in URL
  useEffect(() => {
    if (selectedOrder) {
      navigate(`?id=${selectedOrder}`, { replace: false })
    } else {
      navigate(``, { replace: false })
    }
  }, [selectedOrder, navigate])

  // Set selectedOrder according to URL
  useEffect(() => {
    const orderParam = new URLSearchParams(location.search).get("id")
    setSelectedOrder(orderParam || null)
  }, [])

  // Set your selectedOrder to null when back button is pressed
  useEffect(() => {
    const handlePopState = () => {
      setSelectedOrder(null)
      navigate(window.location.pathname, { replace: true })
    }

    window.addEventListener("popstate", handlePopState)

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("popstate", handlePopState)
    }
  }, [])

  useEffect(() => {
    let res = orders
    if (searchText)
      res = res.filter(
        data =>
          data.customer?.toLowerCase().includes(searchText) ||
          data.billingCustomer?.toLowerCase().includes(searchText)
      )

    if (selectedStatus >= 0)
      res = res.filter(data => data.status == selectedStatus)

    setFilteredOrders(res)
  }, [selectedStatus, orders, searchText])

  useEffect(() => {
    setCurrentPage(1)
    if (selectedOrder) {
      fetchOrderData()
    } else {
      setOrder(null)
    }
  }, [selectedOrder])

  useEffect(() => {
    setCurrentPage(1)
    if (selectedOrder) {
      if (realTimeDevices) fetchCurrentDevices()
      else setSavedDevices(order.devices)
    }
  }, [realTimeDevices])

  // Refresh data
  useEffect(() => {
    if (updateCounter && updateCounter > 0) {
      fetchOrderData()
    }
  }, [updateCounter])

  // Fetch order data from server
  const fetchOrderData = () => {
    setLoading(true)
    getOrder(abortRef.current, selectedOrder)
      .then(result => {
        if (result.statusCode == 200) {
          console.log(result, realTimeDevices)
          if (!realTimeDevices) {
            setSavedDevices(result.data.devices)
          } else {
            fetchCurrentDevices()
          }

          if (result.data.trackingNumber) {
            setTrackingNumbers(result.data.trackingNumber.split("\n"))
          } else {
            setTrackingNumbers([])
          }

          if (result.data.statusLogs) {
            try {
              let arr = JSON.parse(result.data.statusLogs)
              arr.forEach(element => {
                switch (element.status) {
                  case "0":
                    element.statusDescription = "Created"
                    break
                  case "1":
                    element.statusDescription = "Processing"
                    break
                  case "2":
                    element.statusDescription = "Sent"
                    break
                }
              })
              setChangeLogs(arr)
              //console.log("LOGS: ", arr)
            } catch (err) {}
          } else {
            setChangeLogs([])
          }

          switch (result.data.status) {
            case "0":
              result.data.statusDescription = "Created"
              break
            case "1":
              result.data.statusDescription = "Processing"
              break
            case "2":
              result.data.statusDescription = "Sent"
              break
          }

          if (result.data.confirmationDate)
            result.data.confirmationDate = dayjs(result.data.confirmationDate)
          if (result.data.shipmentDate)
            result.data.shipmentDate = dayjs(result.data.shipmentDate)

          if (result.data.confirmationDate)
            result.data.confirmationDateNice = dayjs(
              result.data.confirmationDate
            ).format("DD.MM.YYYY")
          if (result.data.shipmentDate)
            result.data.shipmentDateNice = dayjs(
              result.data.shipmentDate
            ).format("DD.MM.YYYY")

          setOrder(result.data)
        } else {
          setOrder(null)
        }
        setLoading(false)
      })
      .catch(err => {
        setOrder(null)
        setLoading(false)
      })
  }

  // Fetch selected orders devices from server
  const fetchCurrentDevices = () => {
    queryOrderDevices(abortRef.current, selectedOrder)
      .then(result => {
        if (result.statusCode == 200) {
          let counter = 0
          result.devices.forEach(element => {
            element.id = counter++
          })

          setDevices(result.devices)
          //console.log("Devices: ", result.devices)
        } else {
          setDevices([])
        }
      })
      .catch(err => {
        setDevices([])
      })
  }

  // Set devices table content from saved devices property
  const setSavedDevices = deviceString => {
    try {
      let devs = JSON.parse(deviceString)
      setDevices(devs)
    } catch (err) {
      console.log(err)
      setDevices([])
    }
  }

  // Create validation
  const createValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderNumber: "",
    },
    validationSchema: Yup.object({
      orderNumber: Yup.string()
        .matches(/^MT\d{5}$/, props.t("Invalid format"))
        .max(7, props.t("Too long"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      createOrder(abortRef.current, values.orderNumber)
        .then(result => {
          if (result.statusCode == 200) {
            console.log(result)
            showToast(props.t("Success"), "success")
            createValidation.setFieldValue("orderNumber", "")
            setAddModal(false)
            setUpdateCounter(updateCounter + 1)
          } else {
            console.log(result)
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
    },
  })

  // Update validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderNumber: order?.orderNumber || "",
      customer: order?.customer || "",
      customerOrderNumber: order?.customerOrderNumber || "",
      billingCustomer: order?.billingCustomer || "",
      confirmationDate: order?.confirmationDate || "",
      shipmentDate: order?.shipmentDate || "",
      status: order?.status || "",
      trackingNumber: order?.trackingNumber || "",
      additionalInfo: order?.additionalInfo || "",
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit: ", values)

      let temp = JSON.parse(JSON.stringify(values))
      temp.confirmationDate = values.confirmationDate
        ? values.confirmationDate.valueOf()
        : 0
      temp.shipmentDate = values.shipmentDate
        ? values.shipmentDate.valueOf()
        : 0

      setUpdating(true)
      updateOrder(abortRef.current, values.orderNumber, temp)
        .then(result => {
          //console.log(result)
          setUpdating(false)
          if (result.statusCode == 200) {
            fetchOrderData()
            setEditMode(false)
            setUpdateCounter(updateCounter + 1)
            showToast(props.t("Information updated"), "success")
          } else {
            showToast(props.t("Failed to update information"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          setUpdating(false)
          showToast(props.t("Failed to update information"), "error")
        })
    },
  })

  const defaultSorted = [
    {
      dataField: "deviceType",
      order: "asc",
    },
  ]

  // Show selected number of rows
  const pageOptions = {
    custom: true,
    pageStartIndex: 1,
    totalSize: devices.length,
    page: currentPage,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPage(1)
    },
  }

  // Table structure
  const columns = [
    {
      dataField: "deviceType",
      text: props.t("Device type"),
      sort: true,
    },
    /*{
      dataField: "model",
      text: props.t("Model"),
      sort: true,
    },*/
    {
      dataField: "deviceId",
      text: props.t("Device identifications"),
      sort: true,
    },
    {
      dataField: "client",
      text: props.t("Client"),
      sort: true,
      hidden: hideClients,
    },
  ]

  // remove "S/N: "
  const removePart = d => {
    let str = d.replace("S/N: ", "")
    return str
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Device type"),
        props.t("Device identifications"),
        props.t("Client"),
      ],
    ]
    const data = devices.map(elt => [
      elt.deviceType,
      removePart(elt.deviceId),
      elt.client,
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(
      wb,
      title + " (" + dayjs().format("DD.MM.YYYY, HH.mm") + ")" + ".xlsx"
    )

    //Show toast
    showToast(message, "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Orders")}
            breadcrumbItem={
              <Button
                color="primary"
                style={{
                  borderRadius: "100px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                }}
                disabled={refreshing}
                onClick={() => {
                  setUpdateCounter(updateCounter + 1)
                  setRefreshing(true)
                  setTimeout(() => {
                    setRefreshing(false)
                  }, 5000)
                }}
              >
                <i className="mdi mdi-refresh me-2" />
                {props.t("Refresh")}
              </Button>
            }
          />
          <Row className="mb-4">
            <Col>
              <SelectOrder
                selection={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                updateCounter={updateCounter}
                showStatus={true}
                datasetChanged={setOrders}
              />
            </Col>
            {(userRole === "system_admin" || userRole === "order_manager") && (
              <Col className="col-auto d-flex">
                <Button
                  id="add-button"
                  color="success"
                  className="square-icon-button-sm"
                  onClick={() => {
                    setAddModal(true)
                  }}
                >
                  <UncontrolledTooltip placement="bottom" target="add-button">
                    {props.t("Create new")}
                  </UncontrolledTooltip>
                  <i className="mdi mdi-file-plus-outline font-size-18"></i>
                </Button>
              </Col>
            )}
          </Row>

          {loading && (
            <>
              <Lottie
                options={getLottieLoadingOptions()}
                height={100}
                width={100}
              ></Lottie>
            </>
          )}

          {!order && !loading && (
            <>
              <Row className="mb-3">
                <Col className="d-flex" style={{ alignItems: "center" }}>
                  <span className="h5 mb-0">{props.t("Latest")}</span>
                </Col>
                <Col className="col-auto">
                  <ButtonGroup>
                    <Button
                      className="button-group-first"
                      color="primary"
                      active={selectedStatus === -1}
                      onClick={() => setSelectedStatus(-1)}
                    >
                      {props.t("All")}
                    </Button>
                    <Button
                      color="primary"
                      active={selectedStatus === 0}
                      onClick={() => setSelectedStatus(0)}
                    >
                      {props.t("Created")}
                    </Button>
                    <Button
                      color="primary"
                      active={selectedStatus === 1}
                      onClick={() => setSelectedStatus(1)}
                    >
                      {props.t("Processing")}
                    </Button>
                    <Button
                      className="button-group-last"
                      color="primary"
                      active={selectedStatus === 2}
                      onClick={() => setSelectedStatus(2)}
                    >
                      {props.t("Sent")}
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col className="col-auto">
                  <div className="search-box d-inline-block">
                    <div className="position-relative">
                      <Input
                        type="text"
                        value={searchText}
                        onChange={event =>
                          setSearchText(event.target.value.toLowerCase())
                        }
                        placeholder={props.t("Search")}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row xs={1} sm={2} lg={3} xxl={4}>
                {filteredOrders.slice(0, visibleCards).map((item, index) => (
                  <Col key={item.value + "_card"}>
                    <Card className="group-cards">
                      <CardHeader className="bg-transparent">
                        <Link
                          to="#"
                          className="card-link"
                          onClick={() => {
                            setSelectedOrder(item.value)
                          }}
                        >
                          {item.label}
                        </Link>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <div className="d-flex justify-content-center">
                              <div className="avatar-sm">
                                {item.status == 0 && (
                                  <div className="avatar-title bg-light rounded-circle text-muted font-size-20">
                                    <i className="bx bx-pencil"></i>
                                  </div>
                                )}
                                {item.status == 1 && (
                                  <div className="avatar-title avatar-soft-primary rounded-circle font-size-20">
                                    <i className="bx bx-package"></i>
                                  </div>
                                )}
                                {item.status == 2 && (
                                  <div className="avatar-title avatar-soft-success rounded-circle font-size-20">
                                    <i className="bx bx-mail-send"></i>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="text-center mt-2">
                              <p className="text-muted mb-0">
                                {props.t("Status")}
                              </p>
                              <h5 className="mt-1">
                                {props.t(item.statusName)}
                              </h5>
                            </div>
                          </Col>
                          <Col>
                            <div className="d-flex justify-content-center">
                              <div className="avatar-sm">
                                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <i className="bx bx-calendar"></i>
                                </div>
                              </div>
                            </div>
                            <div className="text-center mt-2">
                              <p className="text-muted mb-0">
                                {props.t("Updated")}
                              </p>
                              <h5 className="mt-1">
                                {formatDate(item.statusChanged)}
                              </h5>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Row className="text-center">
                <Col>
                  {filteredOrders.length >= visibleCards && (
                    <>
                      <div className="mb-4 text-muted progress-box">
                        <p className="mb-1">
                          {props.t("Showing")} {visibleCards}{" "}
                          <span className="font-size-10">/</span>{" "}
                          {filteredOrders.length}
                        </p>
                        <Progress
                          color="primary"
                          className="progress-sm"
                          value={visibleCards}
                          max={filteredOrders.length}
                        ></Progress>
                      </div>

                      <div className="progress-box">
                        <Button
                          color="primary"
                          style={{
                            minWidth: "200px",
                            borderRadius: "25px",
                          }}
                          className=""
                          onClick={() => {
                            setVisibleCards(visibleCards + 12)
                          }}
                        >
                          <div className="mb-0 mt-0">
                            {props.t("Show more")}
                          </div>
                        </Button>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}

          {order && !loading && (
            <div>
              <Row>
                <Col lg={5}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        <Row>
                          <Col>
                            <i className="bx bxs-user-circle text-truncate align-middle me-2"></i>
                            {props.t("Information")}
                          </Col>
                          {(userRole === "system_admin" ||
                            userRole === "order_manager" ||
                            userRole === "salesperson") && (
                            <Col className="col-auto">
                              {!editMode && (
                                <Button
                                  color="primary"
                                  size="sm"
                                  className=""
                                  onClick={() => {
                                    setEditMode(true)
                                  }}
                                >
                                  <i className="me-2 bx bxs-edit text-truncate align-middle"></i>
                                  <span className="">{props.t("Edit")}</span>
                                </Button>
                              )}
                              {editMode && (
                                <>
                                  <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => {
                                      setEditMode(false)
                                    }}
                                  >
                                    <i className="me-2 bx bxs-x-square text-truncate align-middle"></i>
                                    <span className="">
                                      {props.t("Cancel")}
                                    </span>
                                  </Button>
                                  <Button
                                    color="success"
                                    size="sm"
                                    disabled={updating}
                                    className="ms-3"
                                    onClick={() => {
                                      validation.submitForm()
                                    }}
                                  >
                                    <i className="me-2 bx bxs-save text-truncate align-middle"></i>
                                    <span className="">
                                      {props.t("Save changes")}
                                    </span>
                                  </Button>
                                </>
                              )}
                            </Col>
                          )}
                        </Row>
                      </CardTitle>

                      {!editMode && (
                        <>
                          <Row>
                            <Col xs={6} className="mt-1">
                              <h6>{props.t("Order number")}</h6>
                              <p>{order?.orderNumber}</p>
                            </Col>
                            <Col xs={6} className="mt-1">
                              <h6>{props.t("Status")}</h6>
                              <p>
                                {order?.statusDescription
                                  ? props.t(order.statusDescription)
                                  : "-"}
                              </p>
                            </Col>
                            <Col xs={6} className="mt-1">
                              <h6>{props.t("Confirmation date")}</h6>
                              <p>
                                {order?.confirmationDateNice
                                  ? order.confirmationDateNice
                                  : "-"}
                              </p>
                            </Col>
                            <Col xs={6} className="mt-1">
                              <h6>{props.t("Shipment date")}</h6>
                              <p>
                                {order?.shipmentDateNice
                                  ? order.shipmentDateNice
                                  : "-"}
                              </p>
                            </Col>
                            <Col xs={6} className="mt-1">
                              <h6>{props.t("Customer")}</h6>
                              <p>{order?.customer ? order.customer : "-"}</p>
                            </Col>
                            <Col xs={6} className="mt-1">
                              <h6>{props.t("Billable customer")}</h6>
                              <p>
                                {order?.billingCustomer
                                  ? order.billingCustomer
                                  : "-"}
                              </p>
                            </Col>
                            <Col xs={6} className="mt-1">
                              <h6>{props.t("Customer order number")}</h6>
                              <p>
                                {order?.customerOrderNumber
                                  ? order.customerOrderNumber
                                  : "-"}
                              </p>
                            </Col>
                            <Col xs={6} className="mt-1">
                              <h6>{props.t("Tracking numbers")}</h6>
                              <div className="d-grid">
                                {trackingNumbers.length == 0 && <p>-</p>}
                                {trackingNumbers?.map((entry, index) => (
                                  <a
                                    key={"" + index}
                                    href={
                                      "https://www.matkahuolto.fi/seuranta?parcelNumber=" +
                                      entry
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className="mb-2"
                                  >
                                    <span className="text-size-10">
                                      {entry}
                                    </span>
                                  </a>
                                ))}
                              </div>
                            </Col>
                            <Col xs={12} className="mt-3">
                              <h6>{props.t("Additional info")}</h6>
                              <Input
                                name="additionalInfo"
                                type="textarea"
                                rows={16}
                                disabled
                                value={order?.additionalInfo}
                              ></Input>
                            </Col>
                          </Row>
                        </>
                      )}

                      {editMode && (
                        <>
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              return false
                            }}
                          >
                            <Row xs={1} sm={2}>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Order number")}</Label>
                                  <Input
                                    name="orderNumber"
                                    type="text"
                                    disabled
                                    value={validation.values.orderNumber}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Status")}</Label>
                                  <Input
                                    value={validation.values.status + ""}
                                    type="select"
                                    name="status"
                                    onChange={validation.handleChange}
                                  >
                                    <option value="0">
                                      {props.t("Created")}
                                    </option>
                                    <option value="1">
                                      {props.t("Processing")}
                                    </option>
                                    <option value="2">{props.t("Sent")}</option>
                                  </Input>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row xs={1} sm={2}>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Confirmation date")}</Label>
                                  <ConfigProvider locale={locale}>
                                    <DatePicker
                                      style={{ width: "100%" }}
                                      format="DD.MM.YYYY"
                                      value={validation.values.confirmationDate}
                                      onChange={v =>
                                        validation.setFieldValue(
                                          "confirmationDate",
                                          v
                                        )
                                      }
                                    />
                                  </ConfigProvider>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Shipment date")}</Label>
                                  <ConfigProvider locale={locale}>
                                    <DatePicker
                                      style={{ width: "100%" }}
                                      format="DD.MM.YYYY"
                                      value={validation.values.shipmentDate}
                                      onChange={v =>
                                        validation.setFieldValue(
                                          "shipmentDate",
                                          v
                                        )
                                      }
                                    />
                                  </ConfigProvider>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row xs={1} sm={2}>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Customer")}</Label>
                                  <Input
                                    name="customer"
                                    type="text"
                                    value={validation.values.customer}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Billable customer")}</Label>
                                  <Input
                                    name="billingCustomer"
                                    type="text"
                                    value={validation.values.billingCustomer}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row xs={1} sm={2}>
                              <Col>
                                <FormGroup>
                                  <Label>
                                    {props.t("Customer order number")}
                                  </Label>
                                  <Input
                                    name="customerOrderNumber"
                                    type="text"
                                    value={
                                      validation.values.customerOrderNumber
                                    }
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Tracking numbers")}</Label>
                                  <Input
                                    name="trackingNumber"
                                    type="textarea"
                                    rows={3}
                                    value={validation.values.trackingNumber}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Additional info")}</Label>
                                  <Input
                                    name="additionalInfo"
                                    type="textarea"
                                    rows={16}
                                    value={validation.values.additionalInfo}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={7}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        <Row>
                          <Col xs="12">
                            <span className="float-end">
                              <Dropdown
                                id="downloadTypes"
                                isOpen={openDownload}
                                toggle={() => setOpenDownload(!openDownload)}
                                className="download-btn text-center ms-2"
                                type="button"
                              >
                                <DropdownToggle
                                  id="downloadstatstable"
                                  className="text-muted"
                                  tag="i"
                                >
                                  <i className="mdi mdi-download font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem onClick={() => downloadExcel()}>
                                    {props.t("Download .xlsx")}
                                  </DropdownItem>
                                  {/* <DropdownItem onClick={() => exportPDFTypes()}>
                                  {props.t("Download .pdf")}
                                </DropdownItem> */}
                                </DropdownMenu>
                              </Dropdown>
                            </span>
                            <i className="bx bxs-devices text-truncate align-middle me-2"></i>
                            {props.t("Devices")}
                          </Col>
                        </Row>
                      </CardTitle>
                      {devices != null && (
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              columns={columns}
                              data={devices}
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row>
                                    <Col className="col-auto">
                                      <span className="">
                                        <Label className="mt-1">
                                          {props.t("Show")}
                                        </Label>
                                        <Switch
                                          className="ms-2"
                                          style={{ minWidth: "80px" }}
                                          checkedChildren={props.t(
                                            "Current devices"
                                          )}
                                          unCheckedChildren={props.t(
                                            "Shipped devices"
                                          )}
                                          checked={realTimeDevices}
                                          onChange={v => setRealTimeDevices(v)}
                                        />
                                      </span>
                                    </Col>
                                    {/** 
                                  <Col>
                                    <Button 
                                      size="sm" 
                                      color="success" 
                                      style={{minWidth: "75px"}}
                                      onClick={() => {}}>{props.t("Save device list")}
                                    </Button>
                                  </Col>*/}
                                    <Col>
                                      <div className=" float-end">
                                        <p>
                                          {devices.length} {props.t("devices")}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="table-responsive mt-1 mb-2">
                                    <BootstrapTable
                                      id="device-table"
                                      keyField={"id"}
                                      responsive
                                      bordered={true}
                                      striped={true}
                                      hover={true}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      headerClasses="header-class"
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        <Row>
                          <Col>
                            <i className="bx bxs-notepad text-truncate align-middle me-2"></i>
                            {props.t("Status changelogs")}
                          </Col>
                        </Row>
                      </CardTitle>
                      <ul className="list-group list-group-flush">
                        {changeLogs
                          .slice()
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map(log => (
                            <li key={log.date} className="list-group-item py-3">
                              <Row>
                                <Col>
                                  <span>{formatDateFull(log.date)}</span>
                                </Col>
                                <Col>
                                  <span>{props.t(log.statusDescription)}</span>
                                </Col>
                                <Col>
                                  <span>{log.user}</span>
                                </Col>
                              </Row>
                            </li>
                          ))}
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          )}

          <Modal
            isOpen={addModal}
            toggle={() => {
              setAddModal(!addModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create new")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <FormGroup>
                  <Label>{props.t("Order number")}</Label>
                  <Input
                    name="orderNumber"
                    type="text"
                    placeholder="MT00000"
                    onChange={createValidation.handleChange}
                    onBlur={createValidation.handleBlur}
                    value={createValidation.values.orderNumber || ""}
                    invalid={
                      !!(
                        createValidation.touched.orderNumber &&
                        createValidation.errors.orderNumber
                      )
                    }
                  ></Input>
                  {createValidation.touched.orderNumber &&
                  createValidation.errors.orderNumber ? (
                    <FormFeedback type="invalid">
                      {createValidation.errors.orderNumber}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setAddModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  onClick={() => {
                    createValidation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>{" "}
              </ModalFooter>
            </Form>
          </Modal>
        </Container>
      </div>
    </>
  )
}

OrderManagement.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(OrderManagement)
