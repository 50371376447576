import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import dayjs from "dayjs"
import { getSystemLogs } from "helpers/backend_helper"
import CustomRangePicker from "components/DatePickers/RangePicker"
import {
  dateFormatter,
  dateSorter,
  defaultPageSizeOptions,
  downloadExcel,
  stringSorter,
  showToast,
} from "utils/general"
import {
  Table as AntdTable,
  Select,
  InputNumber,
  Input as AntdInput,
  Switch,
} from "antd"

const { Search } = AntdInput

const SystemLogs = props => {
  const [logs, setLogs] = useState([])
  const [filteredLogs, setFilteredLogs] = useState([])
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(1, "days"),
    dayjs(),
  ])
  const [contentFilter, setContentFilter] = useState("")
  const [userFilter, setUserFilter] = useState("")
  const [logType, setLogType] = useState("script")
  const [maxItems, setMaxItems] = useState("500")
  const [expandedKeys, setExpandedKeys] = useState([])
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [title] = useState(props.t("System logs"))
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [searchTriggers, setSearchTriggers] = useState(0) // Delayed search
  const [showExtras, setShowExtras] = useState(false)
  const [autoRefresh, setAutoRefresh] = useState(true)
  const [autoCounter, setAutoCounter] = useState(0)

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (logType && dateRange) getLogs()
  }, [logType, dateRange, searchTriggers])

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        clearInterval(interval)
        setDateRange([dateRange[0], dayjs()])
      }, 60000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [autoCounter, autoRefresh])

  // Get logs
  const getLogs = () => {
    if (!ongoingSearch) {
      setOngoingSearch(true)
      let rows = !isNaN(parseFloat(maxItems)) ? parseInt(maxItems, 10) : 500
      getSystemLogs(
        abortRef.current,
        logType,
        dateRange[0].valueOf(),
        dateRange[1].valueOf(),
        rows,
        contentFilter,
        userFilter
      )
        .then(data => {
          let counter = 1
          if (data.logs != null) {
            data.logs.forEach(log => {
              log.id = counter++
            })
          }
          setLogs(data.logs)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setOngoingSearch(false)
          if (!abortRef.current.signal.aborted) {
            setAutoCounter(value => value + 1)
          }
        })
    } else {
      setAutoCounter(value => value + 1)
    }
  }

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, logs])

  // Handle search function
  const handleSearch = () => {
    let result = logs.filter(
      data =>
        data.content?.toLowerCase().includes(searchText) ||
        data.user?.toLowerCase().includes(searchText)
    )
    setFilteredLogs(result)
  }

  // Table columns
  const columns = [
    {
      dataIndex: "timestamp",
      title: props.t("Time"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["timestamp"], b["timestamp"], sortOrder)
      },
      render: dateFormatter,
      width: "150px",
      ellipsis: true,
    },
    {
      dataIndex: "level",
      title: props.t("Level"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "level", sortOrder)
      },
      render: levelFormatter,
      align: "center",
      width: "90px",
      ellipsis: true,
    },
    {
      dataIndex: "content",
      title: props.t("Content"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "content", sortOrder)
      },
      width: "750px",
      ellipsis: true,
    },
    {
      dataIndex: "user",
      title: props.t("User"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "user", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "origin",
      title: props.t("Origin"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "origin", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "thread",
      title: props.t("Thread"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "thread", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
  ]

  function levelFormatter(cell, row) {
    if (row.level == "DEBUG") {
      return (
        <span className={`badge bg-success px-2 py-1`}>
          {row.level.toLowerCase()}
        </span>
      )
    } else if (row.level == "INFO") {
      return (
        <span className={`badge bg-primary px-2 py-1`}>
          {row.level.toLowerCase()}
        </span>
      )
    } else if (row.level == "WARN") {
      return (
        <span className={`badge bg-tertiary px-2 py-1`}>
          {row.level.toLowerCase()}
        </span>
      )
    } else if (row.level == "ERROR") {
      return (
        <span className={`badge bg-danger px-2 py-1`}>
          {row.level.toLowerCase()}
        </span>
      )
    }
  }

  const onDateSelection = value => {
    setDateRange(value)
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Time"),
        props.t("Level"),
        props.t("Content"),
        props.t("User"),
        props.t("Origin"),
        props.t("Thread"),
      ],
    ]
    const data = filteredLogs.map(elt => [
      dateFormatter(elt.timestamp),
      elt.level,
      elt.content,
      elt.user,
      elt.origin,
      elt.thread,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  // Attempt to extract a JSON from string
  function extractJSON(str) {
    let firstOpen, firstClose, candidate
    firstOpen = str.indexOf("{", firstOpen + 1)
    do {
      firstClose = str.lastIndexOf("}")
      if (firstClose <= firstOpen) {
        return null
      }
      do {
        candidate = str.substring(firstOpen, firstClose + 1)
        try {
          let res = JSON.parse(candidate)
          return [res, firstOpen, firstClose + 1]
        } catch (e) { }
        firstClose = str.substr(0, firstClose).lastIndexOf("}")
      } while (firstClose > firstOpen)
      firstOpen = str.indexOf("{", firstOpen + 1)
    } while (firstOpen != -1)
  }

  // Expanded table row
  const expandedRowRender = (record) => {
    return <>
      <Row className="mb-1">
        <Col className="col-auto">
          <Button
            size="sm"
            color="transparent"
            onClick={() => {
              setSelectedMessage(record.content)
            }}
          >
            {props.t("Message")}
          </Button>
        </Col>
        <Col className="col-auto">
          <Button
            size="sm"
            color="transparent"
            onClick={() => {
              try {
                let res = extractJSON(record.content)
                if (res.length > 0) {
                  setSelectedMessage(
                    JSON.stringify(
                      res[0],
                      undefined,
                      4
                    )
                  )
                }
              } catch (err) { }
            }}
          >
            {"JSON"}
          </Button>
        </Col>
      </Row>
      <Input
        type="textarea"
        style={{ maxWidth: "75vw" }}
        rows={15}
        disabled
        value={selectedMessage}
      />
    </>
  }

return (
  <div className="page-content">
    <Container fluid={true}>
      <Breadcrumbs
        title={props.t("System logs")}
        className="breadcrumb m-0 justify-content-center"
        breadcrumbItem={
          <Row>
            <Col className="col-auto d-inline-flex">
              <div className="d-flex mt-1 me-1">
                <Switch
                  className="ms-2"
                  name="extra_switch"
                  checkedChildren={<i className="mdi mdi-cog" />}
                  unCheckedChildren={<i className="mdi mdi-cog" />}
                  checked={showExtras}
                  onChange={v => setShowExtras(v)}
                />
              </div>
              <div className="d-flex mt-1">
                <Switch
                  className="ms-2"
                  name="auto_refresh"
                  checkedChildren={<i className="mdi mdi-autorenew" />}
                  unCheckedChildren={<i className="mdi mdi-autorenew" />}
                  checked={autoRefresh}
                  onChange={v => setAutoRefresh(v)}
                />
              </div>
            </Col>
            <Col>
              <Button
                color="primary"
                style={{
                  borderRadius: "100px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                }}
                disabled={ongoingSearch}
                onClick={() => {
                  setDateRange([dateRange[0], dayjs()])
                }}
              >
                <i className="mdi mdi-refresh me-2" />
                {props.t("Refresh")}
              </Button>
            </Col>
          </Row>
        }
      />

      <Card>
        <CardBody>
          <Row>
            <Col>
              {logs != null && (
                <React.Fragment>
                  <Row>
                    <Col className="col-auto mb-3">
                      <Select
                        defaultValue={"script"}
                        style={{ width: "140px" }}
                        onChange={v => {
                          setLogType(v)
                        }}
                        options={[
                          { value: "script", label: props.t("Script") },
                          {
                            value: "application",
                            label: props.t("Application"),
                          },
                          { value: "security", label: props.t("Security") },
                        ]}
                      />
                    </Col>
                    <Col className="col-auto mb-3">
                      <Search
                        placeholder={props.t("Content")}
                        allowClear
                        value={contentFilter}
                        onChange={v => {
                          setContentFilter(v.target.value)
                        }}
                        onSearch={() => {
                          // onSearch triggers before onChange on clear, delay data fetch a bit
                          setSearchTriggers(searchTriggers + 1)
                        }}
                        style={{ width: "180px" }}
                      />
                    </Col>
                    <Col className="col-auto mb-3">
                      <Search
                        placeholder={props.t("User")}
                        allowClear
                        value={userFilter}
                        onChange={v => {
                          setUserFilter(v.target.value)
                        }}
                        onSearch={() => {
                          // onSearch triggers before onChange on clear, delay data fetch a bit
                          setSearchTriggers(searchTriggers + 1)
                        }}
                        style={{ width: "180px" }}
                      />
                    </Col>
                    <Col className="col-auto mb-3">
                      <InputNumber
                        style={{ width: "135px" }}
                        addonAfter={props.t("max")}
                        value={maxItems}
                        onChange={newVal => {
                          setMaxItems(newVal)
                        }}
                      />
                    </Col>
                    <Col
                      className="mb-3"
                      style={{ minWidth: "350px", maxWidth: "460px" }}
                    >
                      <CustomRangePicker
                        value={dateRange}
                        rangeChanged={onDateSelection}
                        showTime={true}
                        allowClear={false}
                      />
                    </Col>
                    <Col className="col-auto mb-3">
                      {/** 
                          <Button
                            className="square-icon-button-sm"
                            id="calendar-button"
                            color="primary"
                            disabled={ongoingSearch}
                            onClick={() => {
                              setDateRange([dateRange[0], dayjs()])
                            }}
                          >
                            <UncontrolledTooltip
                              placement="bottom"
                              target="calendar-button"
                            >
                              {props.t("Refresh time")}
                            </UncontrolledTooltip>
                            <i className="mdi mdi-calendar-refresh" />
                          </Button>*/}
                      <Button
                        //className="square-icon-button-sm"
                        style={{
                          //borderRadius: "100px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                        id="reset-button"
                        color="danger"
                        outline
                        disabled={ongoingSearch}
                        onClick={() => {
                          setDateRange([dayjs().subtract(1, "days"), dayjs()])
                          setContentFilter("")
                          setUserFilter("")
                          setMaxItems(500)
                        }}
                      >
                        <UncontrolledTooltip
                          placement="bottom"
                          target="reset-button"
                        >
                          {props.t("Reset all")}
                        </UncontrolledTooltip>
                        <i className="mdi mdi-filter-remove" />
                      </Button>
                    </Col>
                  </Row>

                  {showExtras && (
                    <Row className="mb-2">
                      <Col className="col-auto pe-0">
                        <Form
                          className="mt-sm-0 d-flex align-items-center"
                          onSubmit={e => {
                            e.preventDefault()
                            return false
                          }}
                        >
                          <div className="search-box me-2 mb-2 d-inline-block float-end">
                            <div className="position-relative">
                              <Input
                                type="text"
                                onChange={event =>
                                  setSearchText(
                                    event.target.value.toLowerCase()
                                  )
                                }
                                placeholder={props.t("Search")}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Form>
                      </Col>
                      <Col>
                        <span className="float-end ms-2">
                          <UncontrolledTooltip
                            placement="bottom"
                            target="downloadstatstable"
                          >
                            {props.t("Download")}
                          </UncontrolledTooltip>
                          <Dropdown
                            id="printButtonServicesStats"
                            isOpen={menu}
                            toggle={() => setMenu(!menu)}
                            className="download-btn text-center"
                            type="button"
                          >
                            <DropdownToggle
                              id="downloadstatstable"
                              className="text-muted"
                              tag="i"
                            >
                              <i className="mdi mdi-download" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => generateExcel()}>
                                {props.t("Download .xlsx")}
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </span>
                        <div className="mt-2 float-end">
                          <p>
                            {filteredLogs.length} {props.t("results found")}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row className="mtt-2">
                    <Col xl="12">
                      <AntdTable
                        className="colored-header narrow-header"
                        bordered
                        size="small"
                        rowKey={"id"}
                        columns={columns}
                        dataSource={filteredLogs}
                        expandable={{
                          expandedRowKeys: expandedKeys,
                          expandedRowRender: expandedRowRender,
                          onExpand: (expanded, record) => {
                            setSelectedMessage(record.content)

                            let keys = []
                            if (expanded) {
                              keys.push(record.id)
                            }
                            setExpandedKeys(keys)
                          },
                          showExpandColumn: false,
                          expandRowByClick: true,
                        }}
                        pagination={{
                          showSizeChanger: true,
                          defaultPageSize: 200,
                          pageSizeOptions: defaultPageSizeOptions,
                        }}
                        loading={ongoingSearch}
                        //tableLayout="auto"
                        scroll={{
                          //x: "max-content",
                          y: "57vh",
                        }}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  </div>
)
}

SystemLogs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SystemLogs)
