import React, { useEffect, useRef, useState, useMemo } from "react"
import { Routes, Route } from "react-router-dom"
import i18n from "./i18n"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import globalstate (customer id+name)
import GlobalState from "./contexts/GlobalState"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/"

// User info
import { getUserInfo } from "./helpers/backend_helper"

// Import scss
import "./assets/scss/theme.scss"

// ANTD Locale
import { ConfigProvider } from "antd"
// Locale/weekday related imports
import "dayjs/locale/fi"
import "dayjs/locale/se"
import "dayjs/locale/en-gb"
import fin from "antd/es/locale/fi_FI"
import swe from "antd/es/locale/sv_SE"
import en from "antd/es/locale/en_GB"
import dayjs from "dayjs"

const App = () => {
  const [state, setState] = useState({})
  const contextValue = useMemo(() => [state, setState], [state])
  const [preloader, setPreloader] = useState(true)
  const abortRef = useRef(null)

  let counter = 0
  publicRoutes.forEach(rt => {
    rt.index = counter++
  })
  authProtectedRoutes.forEach(rt => {
    rt.index = counter++
  })

  // Check if previous session has expired
  // Fetch updated user info if session is valid, otherwise logout
  useEffect(() => {
    //console.log(localStorage.getItem("session_id"), "session id")
    abortRef.current = new AbortController()

    // Local storage listener for multiple tabs - change lang
    window.addEventListener("storage", () => {
      const lang = localStorage.getItem("i18nextLng")
      i18n.changeLanguage(lang)
    })

    if (localStorage.getItem("session_id")) {
      setPreloader(true)
      getUserInfo(abortRef.current)
        .then(data => {
          if (data.username != null) {
            setState(state => ({ ...state, authUser: JSON.stringify(data) }))
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setPreloader(false)
        })
    } else {
      setPreloader(false)
    }
    return () => abortRef.current.abort()
  }, [])

  //ANTD + dayjs.js language update
  const getLanguage = () => {
    let language = localStorage.getItem("i18nextLng")
    if (language.indexOf("-") > -1) language = language.split("-")[0]

    dayjs.locale(language)

    switch (language) {
      case "en":
        return en
      case "fi":
        return fin
      case "sv":
        return swe
      default:
        return null
    }
  }
  const locale = getLanguage()

  return (
    <GlobalState.Provider value={contextValue}>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={route.component}
            key={route.index}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware
                preloader={preloader}
                globalState={state}
                path={route.path}
                roles={route.roles}
                permission={route.permission}
              >
                <ConfigProvider
                  locale={locale}
                  theme={{
                    token: {
                      fontFamily: "",
                      fontWeight: "",
                      // fontSize: "",
                    },
                  }}
                >
                  <HorizontalLayout>{route.component}</HorizontalLayout>
                </ConfigProvider>
              </Authmiddleware>
            }
            key={route.index}
            exact={true}
          />
        ))}
      </Routes>
    </GlobalState.Provider>
  )
}

export default App
