/******************************************
 * GROUP SEARCH AND FILTERING WITH TAGS
 *****************************************/

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { getGroups } from "../../helpers/backend_helper"
import { Select, ConfigProvider } from "antd"

const SelectGroupFilters = props => {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [origOptions, setOrigOptions] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [temp, setTemp] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    getData()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.updateCounter > 0) {
      getData()
    }
  }, [props.updateCounter])

  useEffect(() => {
    if (props.selectedTags !== undefined) {
      setSelectedTags(props.selectedTags)
    }
  }, [props.selectedTags])

  useEffect(() => {
    //console.log("(SelectGroup) Selected tags: ", selectedTags)
    if (selectedTags?.length > 0) {
      setTemp([])
      //let inc = 0
      origOptions.map(o => {
        selectedTags.map(t => {
          t = t.toString()
          if (o.tag.includes(t)) {
            //inc++
            setTemp(filtered => [...filtered, o])
          }
        })
      })
      // No groups with chosen tag -> return empty array
      /*if (inc === 0) {
        setTemp([])
      }*/
    }
    if (selectedTags?.length == 0) {
      // console.log("No selected tags found")
      setOptions(origOptions)
    }
  }, [selectedTags])

  // Set filter groups by selected tags
  useEffect(() => {
    let setNew = Array.from(new Set(temp))
    setOptions(setNew)
    //console.log("New group options:", setNew)
  }, [temp])

  // Get groups
  const getData = () => {
    setLoading(true)
    getGroups(abortRef.current)
      .then(result => {
        if (result.entries !== null) {
          let opts = []
          let counter = 1
          result.entries.forEach(group => {
            let groupObject = new Object()
            groupObject.id = counter++
            groupObject.label = group.name
            groupObject.value = group.name
            groupObject.tag = Settingtostring(group.tags)
            opts.push(groupObject)
          })
          setOptions(opts)
          setOrigOptions(opts)
        } else {
          setOptions([])
          handleChange(null)
        }
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Setting tags to string
  const Settingtostring = arr => {
    let string = []
    if (arr.length > 0) {
      arr.forEach(g => {
        let tag = g.key
        string.push(tag)
      })
    }
    string = string.toString()
    return string
  }

  // Group selection changed
  const handleChange = newSelection => {
    if (props.onChange != null) {
      props.onChange(newSelection)
    }
  }

  const handleVisibilityChange = state => {
    if (props.onVisibilityChange != null) {
      props.onVisibilityChange(state)
    }
  }

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3 text-center">
      {loading ? (
        <p>{props.t("Loading")}</p>
      ) : (
        <p>{props.t("No groups found")}</p>
      )}
    </div>
  )

  return (
    <div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <div className="config-provider">
          <Select
            showSearch
            // TODO test in different scenarios
            disabled={/*origOptions.length == 0 ||*/ props.disabled}
            loading={loading}
            value={props.value ? props.value : props.selection}
            mode={props.multiple ? "multiple" : null}
            allowClear={props.clearable != null ? props.clearable : true}
            maxTagCount={10}
            placeholder={
              props.multiple
                ? props.t("Select groups")
                : props.t("Select group")
            }
            style={{ width: 100 + "%" }}
            onChange={handleChange}
            options={options}
            onDropdownVisibleChange={handleVisibilityChange}
            /*dropdownRender={(menu) => (
            <>
              {menu}
              <div style={{ padding: '0 8px 4px' }} className="border-top">
                <Button color="primary" size="sm" className="mt-2" onClick={() => {props.onChange([])}}>
                  {props.t("Select all")}
                </Button>
              </div>
            </>
          )}*/
          />
        </div>
      </ConfigProvider>
    </div>
  )
}

SelectGroupFilters.propTypes = {
  t: PropTypes.any,
  selection: PropTypes.any,
  onChange: PropTypes.func,
  onVisibilityChange: PropTypes.func,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  updateCounter: PropTypes.any,
  selectedTags: PropTypes.any,
  value: PropTypes.any,
}

export default withTranslation()(SelectGroupFilters)
