import React from "react"
import { Card, CardBody, Container, Row, Col, CardTitle } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const MessagingTools = () => {
  //meta title
  // document.title = props.t("Messaging tools") + " | Suvanto Care"
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Messaging"
            // breadcrumbItem="Messaging"
          />
          {/* //write Html code or structure */}
          <Row>
            <Col md={7}>
              <Card>
                <CardBody>
                  <CardTitle>Laitelista</CardTitle>
                  <div></div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Laitteen tiedot</CardTitle>
                  <div></div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default MessagingTools
