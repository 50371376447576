import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import translationENG from "./locales/eng/translation.json"
import translationFIN from "./locales/fin/translation.json"
import translationSWE from "./locales/swe/translation.json"

// Resource files
const resources = {
  fi: {
    translation: translationFIN,
  },
  sv: {
    translation: translationSWE,
  },
  en: {
    translation: translationENG,
  },
}

const options = {
  order: ["localStorage", "navigator"],
  lookupLocalStorage: "i18nextLng",
  caches: ["localStorage"],
  htmlTag: document.documentElement,
}

i18n
  .use(new LanguageDetector(null, options))
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
