import TagsTree from "components/DropdownList/TagsTree"
import {
  addNewTag,
  deleteTag,
  getTagsUsers,
  renameTag,
  setTagToGroups,
  removeTagFromGroups,
} from "../../helpers/backend_helper"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import { showToast } from "utils/general"
import PubSub from "pubsub-js"
import SelectGroup from "components/DropdownList/SelectGroup"
import { Checkbox } from "antd"

const CheckboxGroup = Checkbox.Group

const TagManager = props => {
  const [selectedTag, setSelectedTag] = useState(undefined)
  const [newTagName, setNewTagName] = useState("")
  const [renamedTagName, setRenamedTagName] = useState("")
  const [action, setAction] = useState(false)
  const [tagsGroups, setTagsGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [updating, setUpdating] = useState(false)
  const [usageCheckedItems, setUsageCheckedItems] = useState([])
  const [usageChecked, setUsageChecked] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    //console.log("Tag changed: ", selectedTag)
    setTagsGroups([])
    setSelectedGroups([])
    setUsageCheckedItems([])
    setUsageChecked(false)
  }, [selectedTag])

  useEffect(() => {
    setUsageCheckedItems([])
    setUsageChecked(false)
  }, [selectedGroups])

  // Trigger when card is closed
  useEffect(() => {
    if (!props.isOpen) {
      setSelectedGroups([])
      setUsageCheckedItems([])
      setUsageChecked(false)
    }
  }, [props.isOpen])

  // Add tag
  const addTag = () => {
    if (newTagName && !action) {
      setAction(true)
      addNewTag(abortRef.current, newTagName, selectedTag)
        .then(result => {
          if (result.statusCode == 200) {
            setNewTagName("")
            showToast(props.t("Created"), "success")
            PubSub.publish("tags-refresh", "Update tags")
          } else {
            showToast(props.t("Error") + ": " + result.reason, "error")
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Rename tag
  const renameSelected = () => {
    if (selectedTag && renamedTagName && !action) {
      setAction(true)
      renameTag(abortRef.current, selectedTag, renamedTagName)
        .then(result => {
          if (result.statusCode == 200) {
            setRenamedTagName("")
            showToast(props.t("Updated"), "success")
            PubSub.publish("tags-refresh", "Update tags")
          } else {
            showToast(props.t("Error") + ": " + result.reason, "error")
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Delete tag
  const deleteSelected = () => {
    if (selectedTag && !action) {
      setAction(true)
      deleteTag(abortRef.current, selectedTag)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Deleted"), "success")
            setSelectedTag(undefined)
            PubSub.publish("tags-refresh", "Update tags")
          } else {
            showToast(props.t("Error") + ": " + result.reason, "error")
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Get tags users
  const getUsers = () => {
    if (selectedTag && !action) {
      setAction(true)
      setSelectedGroups([])
      setUsageCheckedItems([])
      setUsageChecked(false)
      getTagsUsers(abortRef.current, selectedTag)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setTagsGroups(result.groups)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Add selected tag to multiple groups
  const addTagToGroups = () => {
    if (!selectedGroups || !selectedTag || updating) return

    setUpdating(true)

    // Update tags for the groups
    setTagToGroups(abortRef.current, selectedGroups, selectedTag)
      .then(result => {
        if (result.statusCode === 200) {
          showToast(props.t("Updated"), "success")
        } else {
          throw new Error("Update failed")
        }
        getUsers()
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setUpdating(false)
      })
  }

  // Handle checkbox selection of groups that use currently selected tag
  const handleUsageCheckbox = (event, item) => {
    const isChecked = event.target.checked
    setUsageCheckedItems(prevCheckedItems => {
      const newCheckedItems = isChecked
        ? [...prevCheckedItems, item]
        : prevCheckedItems.filter(checkedItem => checkedItem !== item)

      setUsageChecked(newCheckedItems.length > 0)

      return newCheckedItems
    })
  }

  // Remove selected tag from multiple groups
  const removeTagsGroups = () => {
    if (!usageCheckedItems || !selectedTag || updating) return

    setUpdating(true)
    setSelectedGroups([])

    // Update tags for the groups
    removeTagFromGroups(abortRef.current, usageCheckedItems, selectedTag)
      .then(result => {
        if (result.statusCode === 200) {
          showToast(props.t("Updated"), "success")
        } else {
          throw new Error("Update failed")
        }
        getUsers()
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setUpdating(false)
      })
  }

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={() => {
          if (props.stateChanged) props.stateChanged(!props.isOpen)
        }}
        centered
      >
        <ModalHeader>{props.t("Tag manager")}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>{props.t("Tags")}</Label>
            <TagsTree
              expanded={false}
              loadData={true}
              multiple={false}
              selected={selectedTag}
              selectionChanged={setSelectedTag}
            />
          </FormGroup>

          <hr className="mt-4" />

          <ListGroup className="list-group-flush">
            <ListGroupItem className="py-4">
              <Row>
                <Col>
                  <h6>{props.t("Set tag to multiple groups")}</h6>
                  <SelectGroup
                    onChange={setSelectedGroups}
                    selection={selectedGroups}
                    multiple={true}
                    disabled={updating || !selectedTag || action}
                  />
                </Col>
                <Col className="col-auto">
                  <Button
                    color="primary"
                    style={{ minWidth: "100px" }}
                    disabled={!selectedTag || action || updating}
                    onClick={() => {
                      addTagToGroups()
                      console.log(
                        "Set tag:",
                        selectedTag,
                        "\nto groups:",
                        selectedGroups
                      )
                    }}
                  >
                    {props.t("Set")}
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>

            <ListGroupItem className="py-4">
              <Row>
                <Col>
                  <div>
                    <h6>
                      {selectedTag
                        ? props.t("Add new sub tag")
                        : props.t("Add new tag")}
                    </h6>
                    <Input
                      bsSize="sm"
                      name="newTagName"
                      type="text"
                      placeholder={props.t("Name")}
                      value={newTagName}
                      onChange={v => {
                        setNewTagName(v.target.value)
                      }}
                    />
                  </div>
                </Col>
                <Col className="col-auto">
                  <Button
                    color="success"
                    style={{ minWidth: "100px" }}
                    disabled={action || !newTagName || newTagName.length < 3}
                    onClick={() => {
                      addTag()
                    }}
                  >
                    {props.t("Add")}
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>

            <ListGroupItem className="py-4">
              <Row>
                <Col>
                  <div>
                    <h6>{props.t("Rename tag")}</h6>
                    <Input
                      bsSize="sm"
                      name="renamedTagName"
                      type="text"
                      placeholder={props.t("New name")}
                      disabled={!selectedTag}
                      value={renamedTagName}
                      onChange={v => {
                        setRenamedTagName(v.target.value)
                      }}
                    />
                  </div>
                </Col>
                <Col className="col-auto">
                  <Button
                    color="primary"
                    style={{ minWidth: "100px" }}
                    disabled={
                      !selectedTag ||
                      action ||
                      !renamedTagName ||
                      renamedTagName.length < 3
                    }
                    onClick={() => {
                      renameSelected()
                    }}
                  >
                    {props.t("Set")}
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>

            <ListGroupItem className="py-4">
              <Row>
                <Col>
                  <div>
                    <h6>{props.t("Delete tag")}</h6>
                    <span className="text-muted">
                      {props.t("DeleteTagDescription")}
                    </span>
                  </div>
                </Col>
                <Col className="col-auto">
                  <Button
                    color="danger"
                    style={{ minWidth: "100px" }}
                    disabled={!selectedTag || action}
                    onClick={() => {
                      deleteSelected()
                    }}
                  >
                    {props.t("Delete")}
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
          <ListGroup>
            <ListGroupItem className="py-4">
              <Row>
                <Col>
                  <div>
                    <h6>{props.t("Check usage")}</h6>
                    <span className="text-muted">
                      {props.t("CheckTagUsageDescription")}
                    </span>
                  </div>
                </Col>
                <Col className="col-auto">
                  <Button
                    color="primary"
                    style={{ minWidth: "100px" }}
                    disabled={!selectedTag || action}
                    onClick={() => {
                      getUsers()
                    }}
                  >
                    {props.t("Check")}
                  </Button>
                </Col>
              </Row>
              {/* FormGroup for groups with this tag and remove button */}
              <Row>
                <FormGroup className="mt-3">
                  {tagsGroups.map((option, index) => (
                    <div key={""+index}>
                      <Label check>
                        <Input
                          type="checkbox"
                          className="me-2"
                          checked={usageCheckedItems.includes(option)}
                          onChange={e => {
                            handleUsageCheckbox(e, option)
                          }}
                        />
                        {option}
                      </Label>
                    </div>
                  ))}
                </FormGroup>
              </Row>
              <Row>
                <Col>
                  <div>
                    <h6 style={usageChecked ? {} : { opacity: 0.3 }}>
                      {props.t("Remove tag from groups")}
                    </h6>
                    <span
                      className="text-muted"
                      style={usageChecked ? {} : { opacity: 0.3 }}
                    >
                      {props.t(
                        "Removes the selected tag from all groups selected in the list"
                      )}
                    </span>
                  </div>
                </Col>
                <Col className="col-auto">
                  <Button
                    color="danger"
                    style={{ minWidth: "70px" }}
                    disabled={!selectedTag || !usageChecked || action}
                    onClick={() => {
                      removeTagsGroups()
                      console.log(
                        "Remove Tag:",
                        selectedTag,
                        "\nfrom groups:",
                        usageCheckedItems
                      )
                    }}
                  >
                    {props.t("Remove")}
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              props.stateChanged(false)
            }}
          >
            {props.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

TagManager.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.bool,
  stateChanged: PropTypes.func,
}

export default withTranslation()(TagManager)
