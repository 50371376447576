/**********************
 * VIDEO GROUP MEMBERS
 *********************/
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { showToast } from "utils/general"
import { Transfer } from "antd"
import { acGetGroupUsers, acUpdateMultipleGroupUsers } from "helpers/backend_helper"

const VideoGroupUsersModal = props => {
  const [ongoingAction, setOngoingAction] = useState(false)
  const [targetKeys, setTargetKeys] = useState([]);
  const [userData, setUserData] = useState([]);
  const [groupId, setGroupId] = useState(null);

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.showModal && props.groupId != groupId) {
      setTargetKeys([])
      setUserData([])
      setGroupId(props.groupId)
      getUserList()
    }
  }, [props.showModal])

  // Get user list for group
  const getUserList = () => {
    if (!ongoingAction && props.groupId > 1) {
      setOngoingAction(true)
      acGetGroupUsers(abortRef.current, props.groupId, props.production).then(result => {
        if (result.statusCode == 200 && result.users) {
          //console.log("Res:", result)
          let temp = []
          let targets = []

          Object.keys(result.users).forEach(key => {
            const element = result.users[key];
            let entry = {}
            entry.key = element.room_name
            entry.title = element.room_name
            entry.description = ""
            temp.push(entry)

            if (element.id > 0) {
              targets.push(element.room_name)
            }
          });

          temp.sort((a, b) => (a.room_name > b.room_name ? -1 : 1))
          targets.sort((a, b) => a.localeCompare(b))

          setUserData(temp)
          setTargetKeys(targets)
        }
        else throw new Error("Unexpeced result")
      }).catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
        setUserData([])
        setTargetKeys([])
      }).finally(() => {
        setOngoingAction(false)
      })
    }
  }

  // Items transferred
  const onChange = (nextTargetKeys, direction, moveKeys) => {
    if (!ongoingAction && props.groupId > 0 && moveKeys.length > 0) {
      let action = direction == "left" ? 2 : 1
      setOngoingAction(true)
      acUpdateMultipleGroupUsers(abortRef.current, props.groupId, action, moveKeys, props.production).then(result => {
        //console.log("Transfer result: ", result)
        if (result.statusCode == 200) {
          if (result.errorMessage == "Partial success") {
            showToast(props.t("Some of the users could not be transferred"), "warning")
          }
          setOngoingAction(false)
          getUserList() // Reload user list
        }
        else if (result.errorMessage) throw new Error(result.errorMessage)
        else throw new Error("Unexpeced result")
      }).catch(err => {
        console.log(err)
        if (err.message == "Transfer failed") {
          showToast(props.t("Selected users could not be transferred"), "error")
        }
        else {
          showToast(props.t("Error"), "error")
        }
        setOngoingAction(false)
      })
    }
  };

  return (
    <>
      <Modal
        isOpen={props.showModal}
        toggle={() => {
          if (props.setShowModal) {
            props.setShowModal(!props.showModal)
          }
        }}
        size="xl"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {props.groupName}
          </h5>
          <button
            type="button"
            onClick={() => {
              if (props.setShowModal) {
                props.setShowModal(false)
              }
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>

          <Row className="mb-3">
            <Col className="col-auto">
              <Button
                disabled={ongoingAction}
                size="sm"
                color="primary"
                onClick={() => { getUserList() }}
              >
                {props.t("Refresh data")}
              </Button>
            </Col>
            <Col className="d-flex">
              {ongoingAction &&
                <span className="text-primary" style={{ alignSelf: "center" }}>
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  {props.t("Loading")}
                </span>
              }
            </Col>
          </Row>

          <Transfer
            disabled={ongoingAction}
            dataSource={userData}
            showSearch
            listStyle={{
              width: "100%",
              height: 500,
            }}
            titles={[props.t("Not in group"), props.t("Group members")]}
            targetKeys={targetKeys}
            onChange={onChange}
            render={(item) => `${item.title}`}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

VideoGroupUsersModal.propTypes = {
  t: PropTypes.any,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  groupId: PropTypes.any,
  groupName: PropTypes.string,
  production: PropTypes.bool,
}

export default withTranslation()(VideoGroupUsersModal)