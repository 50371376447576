import React, { useContext, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  ConfigProvider,
  DatePicker,
  Switch,
  Tree,
  Table as AntdTable,
} from "antd"
import {
  acCancelCalendarEvent,
  acCreateUpdateEvent,
  acCreateUpdateRecording,
  acCreateUpdateVirtualRoom,
  acDeleteCalendarEvent,
  acDeleteRecording,
  acDeleteVirtualRoom,
  acGetRecordings,
  acGetVirtualRoomList,
  getACsGroupsTree,
  getVideoCalendarEventsByGroup,
} from "helpers/backend_helper"
import dayjs from "dayjs"
import Lottie from "react-lottie"
import {
  getLocale,
  getLottieLoadingOptions,
  getLottieNothingFound,
  getLottieSearch,
  showToast,
} from "utils/general"
import * as Yup from "yup"
import { useFormik } from "formik"
import SelectVirtualRoom from "./Components/select-virtual-room"
import GlobalState from "contexts/GlobalState"
import EventImagePicker from "./Components/event-image-picker"
import classNames from "classnames"
import DeleteGroupModal from "./Components/delete-group-modal"
import VideoGroupModal from "./Components/add-update-group-modal"
import VideoGroupUsersModal from "./Components/visibility-list-users"

const VideoSystemManagement = props => {
  const [state] = useContext(GlobalState)
  const [isSystemAdmin, setIsSystemAdmin] = useState(false)
  const [isProduction, setIsProduction] = useState(true)
  const [treeData, setTreeData] = useState([])
  const [selectedTreeOption, setSelectedTreeOption] = useState()
  const [selectedNode, setSelectedNode] = useState([])
  const [loadingTree, setLoadingTree] = useState(false)
  const [loadingEvents, setLoadingEvents] = useState(false)
  const [loadingRecordings, setLoadingRecordings] = useState(false)
  const [loadingVirtuals, setLoadingVirtuals] = useState(false)
  const [loadingCommunities, setLoadingCommunities] = useState(false)
  const [ongoingAction, setOngoingAction] = useState(false)
  const [accordionOpen1, setAccordionOpen1] = useState("")
  const [accordionOpen2, setAccordionOpen2] = useState("")
  const [accordionOpen3, setAccordionOpen3] = useState("")
  const [accordionOpen4, setAccordionOpen4] = useState("")

  const [eventsLoaded, setEventsLoaded] = useState(false)
  const [virtualsLoaded, setVirtualsLoaded] = useState(false)
  const [commnsLoaded, setCommnsLoaded] = useState(false)
  const [recordingsLoaded, setRecordingsLoaded] = useState(false)

  const [eventModal, setEventModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [deleteEventModal, setDeleteEventModal] = useState(false)
  const [virtualModal, setVirtualModal] = useState(false)
  const [deleteRoomModal, setDeleteRoomModal] = useState(false)
  const [recordingModal, setRecordingModal] = useState(false)
  const [deleteRecordingModal, setDeleteRecordingModal] = useState(false)
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false)

  const [showUsersModal, setShowUsersModal] = useState(false)
  const [showGroupModal, setShowGroupModal] = useState(false)
  const [groupEditData, setGroupEditData] = useState(null)

  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedEventId, setSelectedEventId] = useState(null)
  const [selectedRecording, setSelectedRecording] = useState(null)
  const [selectedRecordingId, setSelectedRecordingId] = useState(null)
  const [selectedRoomId, setSelectedRoomId] = useState(null)
  const [selectedRoom, setSelectedRoom] = useState(null)

  const [events, setEvents] = useState([])
  const [virtuals, setVirtuals] = useState([])
  const [communities, setCommunities] = useState([])
  const [recordings, setRecordings] = useState([])

  const [checkOverlappingEvent, setCheckOverlappingEvent] = useState(true)
  const [overlappingEventModal, setOverlappingEventModal] = useState(false)

  const [treeHeight, setTreeHeight] = useState(500)
  const [title] = useState("Content events")

  const locale = getLocale()
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t(title) + " | Suvanto Care"
    abortRef.current = new AbortController()

    setTreeHeight(window.innerHeight * 0.65)

    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      if (role === "system_admin") {
        setIsSystemAdmin(true)
      }
    }
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    getGroups()

    setSelectedTreeOption()
    setSelectedNode()
    setEvents([])
    setRecordings([])
    setVirtuals([])
    setCommunities([])

    setEventsLoaded(false)
    setVirtualsLoaded(false)
    setCommnsLoaded(false)
    setRecordingsLoaded(false)

    setAccordionOpen1("")
    setAccordionOpen2("")
    setAccordionOpen3("")
    setAccordionOpen4("")
  }, [isProduction])

  useEffect(() => {
    if (!eventModal) eventValidation.resetForm()
  }, [eventModal])

  useEffect(() => {
    if (!virtualModal) virtualValidation.resetForm()
  }, [virtualModal])

  useEffect(() => {
    if (!recordingModal) recordingValidation.resetForm()
  }, [recordingModal])

  // Load groups
  const getGroups = () => {
    if (!loadingTree) {
      setTreeData([])
      setSelectedNode()
      setSelectedTreeOption()
      //setSelectedValue(null)
      setLoadingTree(true)
      getACsGroupsTree(abortRef.current, isProduction)
        .then(result => {
          if (result.statusCode === 200 && result.groups != null) {
            //console.log("Tree: ", result.groups)
            setTreeData(result.groups)
          } else throw new Error("Unexpected result")
        })
        .catch(err => {
          console.log(err)
          //setTreeData([])
          //setSelectedNode()
          //setSelectedTreeOption()
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setLoadingTree(false)
        })
    }
  }

  // Load events
  const getEvents = groupId => {
    if (!loadingEvents && groupId) {
      //setEvents([])
      setLoadingEvents(true)
      getVideoCalendarEventsByGroup(abortRef.current, groupId, isProduction)
        .then(result => {
          if (result.statusCode === 200 && result.events != null) {
            let temp = []
            //console.log("Raw events: ", result.events)
            result.events.forEach(element => {
              let end = element.start + element.duration * 60 * 1000
              let entry = new Object()
              entry.id = element.id
              entry.title = element.title
              entry.room = element.roomName
              entry.vmr_id = element.vmr_id
              entry.date = dayjs(element.start).format("DD.MM")
              entry.time =
                dayjs(element.start).format("HH:mm") +
                "-" +
                dayjs(end).format("HH:mm")
              entry.canceled = element.canceled
              entry.duration = element.duration
              entry.start = element.start
              entry.image_base64 = element.image_base64
              temp.push(entry)
            })
            setEvents(temp)
            setEventsLoaded(true)
            //console.log("Events: ", temp)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoadingEvents(false)
        })
    }
  }

  // Cancel event
  const cancelEvent = () => {
    if (!ongoingAction && selectedEventId != null) {
      setOngoingAction(true)
      let id = parseInt(selectedEventId, 10)
      acCancelCalendarEvent(abortRef.current, id, isProduction)
        .then(result => {
          //console.log("Cancel result: ", result)
          if (result.statusCode == 200) {
            showToast(props.t("Event canceled"), "success")
            getEvents(selectedTreeOption)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
          setCancelModal(false)
          setSelectedEventId(null)
        })
    }
  }

  // Delete event
  const deleteEvent = () => {
    if (!ongoingAction && selectedEventId != null) {
      setOngoingAction(true)
      let id = parseInt(selectedEventId, 10)
      acDeleteCalendarEvent(abortRef.current, id, isProduction)
        .then(result => {
          //console.log("Delete result: ", result)
          if (result.statusCode == 200) {
            getEvents(selectedTreeOption)
            showToast(props.t("Deleted"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
          setDeleteEventModal(false)
          setSelectedEventId(null)
        })
    }
  }

  // Load recordings
  const getRecordings = groupId => {
    if (!loadingRecordings && groupId) {
      setLoadingRecordings(true)
      acGetRecordings(abortRef.current, groupId, isProduction)
        .then(result => {
          if (result.statusCode === 200 && result.list != null) {
            //console.log("Recordings: ", result.list)
            setRecordings(result.list)
            setRecordingsLoaded(true)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoadingRecordings(false)
        })
    }
  }

  // Delete recording
  const deleteRecording = () => {
    if (!ongoingAction && selectedRecordingId != null) {
      setOngoingAction(true)
      let id = parseInt(selectedRecordingId, 10)
      acDeleteRecording(abortRef.current, id, isProduction)
        .then(result => {
          //console.log("Delete result: ", result)
          if (result.statusCode == 200) {
            showToast(props.t("Deleted"), "success")
            getRecordings(selectedTreeOption)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
          setDeleteRecordingModal(false)
          setSelectedRecordingId(null)
        })
    }
  }

  // Load virtual rooms
  const getVirtualRooms = groupId => {
    if (!loadingVirtuals && groupId) {
      setLoadingVirtuals(true)
      acGetVirtualRoomList(abortRef.current, groupId, 1, isProduction)
        .then(result => {
          //console.log("Virtual rooms result: ", result)
          if (result.statusCode === 200 && result.list != null) {
            setVirtuals(result.list)
            setVirtualsLoaded(true)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoadingVirtuals(false)
        })
    }
  }

  // Load community rooms
  const getCommunityRooms = groupId => {
    if (!loadingCommunities && groupId) {
      setLoadingCommunities(true)
      acGetVirtualRoomList(abortRef.current, groupId, 2, isProduction)
        .then(result => {
          if (result.statusCode === 200 && result.list != null) {
            //console.log("Community rooms: ", result.list)
            setCommunities(result.list)
            setCommnsLoaded(true)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoadingCommunities(false)
        })
    }
  }

  // Delete room
  const deleteRoom = () => {
    if (!ongoingAction && selectedRoomId != null) {
      setOngoingAction(true)
      let id = parseInt(selectedRoomId, 10)
      acDeleteVirtualRoom(abortRef.current, id, isProduction)
        .then(result => {
          //console.log("Delete result: ", result)
          if (result.statusCode == 200) {
            showToast(props.t("Deleted"), "success")
            getVirtualRooms(selectedTreeOption)
            getCommunityRooms(selectedTreeOption)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
          setDeleteRoomModal(false)
          setSelectedRoomId(null)
        })
    }
  }

  // New event validation init
  const setNewEvent = () => {
    let newEntry = new Object()
    newEntry.id = undefined
    newEntry.virtualRoom = null
    newEntry.start = dayjs().add(1, "day").startOf("day").add(8, "hour")
    newEntry.duration = 60
    newEntry.imageBase64 = ""
    setSelectedEvent(newEntry)
    setEventModal(true)
    if (!virtualsLoaded && !loadingVirtuals) {
      getVirtualRooms(selectedTreeOption)
    }
  }

  // New room validation init
  const setNewRoom = type => {
    let newEntry = new Object()
    newEntry.id = undefined
    newEntry.type = type
    newEntry.name = ""
    newEntry.requiresHost = false
    newEntry.opensAt = "10:00:00"
    newEntry.closesAt = "14:00:00"
    newEntry.imageBase64 = ""

    setSelectedRoom(newEntry)
    setVirtualModal(true)
  }

  // New recording validation init
  const setNewRecording = () => {
    let newEntry = new Object()
    newEntry.id = undefined
    newEntry.name = ""
    newEntry.url = ""
    newEntry.imageBase64 = ""

    setSelectedRecording(newEntry)
    setRecordingModal(true)
  }

  // Event validation
  const eventValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selectedEvent?.id,
      virtualRoom: selectedEvent?.virtualRoom || null,
      title: selectedEvent?.title || "",
      start: selectedEvent?.start || null,
      duration: selectedEvent?.duration || 60,
      imageBase64: selectedEvent?.imageBase64 || "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required(props.t("Required field"))
        .min(5, props.t("Too short"))
        .max(30, props.t("Too long")),
      duration: Yup.number()
        .required(props.t("Required field"))
        .min(10, props.t("Too short"))
        .max(1440, props.t("Too long")),
    }),
    onSubmit: values => {
      //console.log("Submit event: ", values)
      let imageChanged = values.imageBase64 != selectedEvent?.imageBase64
      let checkOverlap = checkOverlappingEvent
      setCheckOverlappingEvent(true)
      if (!ongoingAction && selectedTreeOption && values.virtualRoom) {
        setOngoingAction(true)
        acCreateUpdateEvent(
          abortRef.current,
          selectedTreeOption,
          values.title,
          values.start.valueOf(),
          values.duration,
          values.virtualRoom,
          imageChanged ? values.imageBase64 : undefined,
          values.id,
          isProduction,
          checkOverlap
        )
          .then(result => {
            //console.log("Add or update event result: ", result)
            if (result.statusCode === 200 && result.id) {
              getEvents(selectedTreeOption)
              setEventModal(false)
            } else if (
              result.statusCode === 400 &&
              result.status == "overlapping_event"
            ) {
              setOverlappingEventModal(true)
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingAction(false)
          })
      }
    },
  })

  // Virtual room validation
  const virtualValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selectedRoom?.id || undefined,
      type: selectedRoom?.type || 1,
      name: selectedRoom?.name || "",
      requiresHost: selectedRoom?.requiresHost || false,
      opensAt: selectedRoom?.opensAt || "",
      closesAt: selectedRoom?.closesAt || "",
      imageBase64: selectedRoom?.imageBase64 || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(props.t("Required field"))
        .min(5, props.t("Too short"))
        .max(30, props.t("Too long")),
      opensAt: Yup.string()
        .matches(/^([0-1]\d|2[0-3]):[0-5]\d:[0-5]\d$/, "HH:mm:ss")
        .required(props.t("Required field")),
      closesAt: Yup.string()
        .matches(/^([0-1]\d|2[0-3]):[0-5]\d:[0-5]\d$/, "HH:mm:ss")
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      //console.log("Submit room: ", values)
      let imageChanged = values.imageBase64 != selectedRoom?.imageBase64
      if (!ongoingAction && selectedTreeOption) {
        setOngoingAction(true)
        acCreateUpdateVirtualRoom(
          abortRef.current,
          selectedTreeOption,
          values.type,
          values.name,
          values.requiresHost,
          values.opensAt,
          values.closesAt,
          imageChanged ? values.imageBase64 : undefined,
          values.id,
          isProduction
        )
          .then(result => {
            //console.log("Add or update room result: ", result)
            if (result.statusCode === 200 && result.vmr_id) {
              if (result.vmr_type == 1) {
                getVirtualRooms(selectedTreeOption)
              } else {
                getCommunityRooms(selectedTreeOption)
              }
              setVirtualModal(false)
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingAction(false)
          })
      }
    },
  })

  // Recording validation
  const recordingValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selectedRecording?.id || undefined,
      name: selectedRecording?.name || "",
      url: selectedRecording?.url || "",
      imageBase64: selectedRecording?.imageBase64 || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(props.t("Required field"))
        .min(5, props.t("Too short"))
        .max(30, props.t("Too long")),
    }),
    onSubmit: values => {
      //console.log("Submit recording: ", values)
      let imageChanged = values.imageBase64 != selectedRecording?.imageBase64
      if (!ongoingAction && selectedTreeOption) {
        setOngoingAction(true)
        acCreateUpdateRecording(
          abortRef.current,
          selectedTreeOption,
          values.name,
          values.url,
          imageChanged ? values.imageBase64 : undefined,
          values.id,
          isProduction
        )
          .then(result => {
            //console.log("Add or update recording result: ", result)
            if (result.statusCode === 200 && result.id) {
              getRecordings(selectedTreeOption)
              setRecordingModal(false)
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingAction(false)
          })
      }
    },
  })

  // Tree selection changed
  const onSelect = (selection, extras) => {
    //console.log("Group: ", selection[0])
    setSelectedTreeOption(selection[0])
    setSelectedNode(extras.node)
    setEvents([])
    setRecordings([])
    setVirtuals([])
    setCommunities([])

    setEventsLoaded(false)
    setVirtualsLoaded(false)
    setCommnsLoaded(false)
    setRecordingsLoaded(false)

    if (selection.length > 0) {
      if (accordionOpen1) getEvents(selection[0])
      if (accordionOpen2) getVirtualRooms(selection[0])
      if (accordionOpen3) getCommunityRooms(selection[0])
      if (accordionOpen4) getRecordings(selection[0])
    }
  }

  // Accordion toggle
  const toggleInfo = id => {
    if (id == 1) {
      if (accordionOpen1 === id) {
        setAccordionOpen1("")
      } else {
        setAccordionOpen1(id)
        if (!eventsLoaded) getEvents(selectedTreeOption)
      }
    }
    if (id == 2) {
      if (accordionOpen2 === id) {
        setAccordionOpen2("")
      } else {
        setAccordionOpen2(id)
        if (!virtualsLoaded) getVirtualRooms(selectedTreeOption)
      }
    }
    if (id == 3) {
      if (accordionOpen3 === id) {
        setAccordionOpen3("")
      } else {
        setAccordionOpen3(id)
        if (!commnsLoaded) getCommunityRooms(selectedTreeOption)
      }
    }
    if (id == 4) {
      if (accordionOpen4 === id) {
        setAccordionOpen4("")
      } else {
        setAccordionOpen4(id)
        if (!recordingsLoaded) getRecordings(selectedTreeOption)
      }
    }
  }

  // Table structures
  const eventColumns = [
    {
      dataIndex: "image_base64",
      title: props.t("Image"),
      align: "center",
      render: imageFormatter,
      width: "50px",
    },
    {
      dataIndex: "date",
      title: props.t("Date"),
      width: "80px",
      ellipsis: true,
    },
    {
      dataIndex: "time",
      title: props.t("klo"),
      render: eventTimeFormatter,
      width: "80px",
      ellipsis: true,
    },
    {
      dataIndex: "title",
      title: props.t("Title"),
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "room",
      title: props.t("Room"),
      width: "80px",
      ellipsis: true,
    },
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: eventEditFormatter,
      //fixed: "right",
      width: "100px",
      ellipsis: true,
    },
  ]

  const virtualColumns = [
    {
      dataIndex: "vmr_number",
      title: props.t("Number"),
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "vmr_name",
      title: props.t("Name"),
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "require_host",
      title: props.t("Requires host"),
      align: "center",
      render: cell => {
        return cell == "1" ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <i className="mdi mdi-close-thick text-muted" />
        )
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: virtualEditFormatter,
      //fixed: "right",
      width: "100px",
      ellipsis: true,
    },
  ]

  const communityColumns = [
    /*{
      dataIndex: "vmr_number",
      title: props.t("Number"),
      width: "100px",
      ellipsis: true,
    },*/
    {
      dataIndex: "image_base64",
      title: props.t("Image"),
      align: "center",
      render: imageFormatter,
      width: "50px",
    },
    {
      dataIndex: "vmr_name",
      title: props.t("Name"),
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "opens_at",
      title: props.t("Opens at"),
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "closes_at",
      title: props.t("Closes at"),
      width: "100px",
      ellipsis: true,
    },
    /*{
      dataIndex: "image",
      title: props.t("Image"),
      width: "100px",
      ellipsis: true,
    },*/
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: communityEditFormatter,
      //fixed: "right",
      width: "100px",
      ellipsis: true,
    },
  ]

  const recordingColumns = [
    {
      dataIndex: "image_base64",
      title: props.t("Image"),
      align: "center",
      render: imageFormatter,
      width: "50px",
    },
    {
      dataIndex: "name",
      title: props.t("Name"),
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "url",
      title: props.t("Url"),
      render: urlFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: recordingEditFormatter,
      //fixed: "right",
      width: "100px",
      ellipsis: true,
    },
  ]

  function imageFormatter(cell, row) {
    if (cell) {
      return (
        <img
          className="rounded bg-light"
          style={{ objectFit: "cover", width: "60px", height: "60px" }}
          src={"data:image/jpeg;base64," + cell}
          alt=""
        ></img>
      )
    } else return null
  }

  function eventTimeFormatter(cell, row) {
    if (row.canceled)
      return <span className="text-danger">{props.t("Event canceled")}</span>
    else return cell
  }

  function eventEditFormatter(cell, row) {
    return (
      <>
        {!row.canceled && (
          <>
            <Button
              color="primary"
              outline
              disabled={ongoingAction}
              size="sm"
              onClick={() => {
                let entry = new Object()
                entry.id = row.id
                entry.virtualRoom = row.vmr_id
                entry.title = row.title
                entry.duration = row.duration
                entry.start = dayjs(row.start)
                entry.imageBase64 = row.image_base64
                setSelectedEvent(entry)
                setEventModal(true)
                if (!virtualsLoaded && !loadingVirtuals) {
                  getVirtualRooms(selectedTreeOption)
                }
              }}
            >
              <i className="mdi mdi-pencil" />
            </Button>
            <Button
              className="ms-2"
              color="danger"
              outline
              disabled={ongoingAction}
              size="sm"
              onClick={() => {
                setSelectedEventId(row.id)
                setCancelModal(true)
              }}
            >
              <i className="mdi mdi-cancel" />
            </Button>
          </>
        )}
        <Button
          className="ms-2"
          color="danger"
          outline
          disabled={ongoingAction}
          size="sm"
          onClick={() => {
            setSelectedEventId(row.id)
            setDeleteEventModal(true)
          }}
        >
          <i className="mdi mdi-delete" />
        </Button>
      </>
    )
  }

  function virtualEditFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            let entry = new Object()
            entry.id = row.vmr_id
            entry.type = row.vmr_type
            entry.name = row.vmr_name
            entry.requiresHost = row.require_host == "1"
            entry.opensAt = "10:00:00"
            entry.closesAt = "14:00:00"
            entry.imageBase64 = row.image_base64

            setSelectedRoom(entry)
            setVirtualModal(true)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
        <Button
          className="ms-2"
          color="danger"
          outline
          size="sm"
          onClick={() => {
            setSelectedRoomId(row.vmr_id)
            setDeleteRoomModal(true)
          }}
        >
          <i className="mdi mdi-delete" />
        </Button>
      </>
    )
  }

  function communityEditFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            let entry = new Object()
            entry.id = row.vmr_id
            entry.type = row.vmr_type
            entry.name = row.vmr_name
            entry.requiresHost = row.require_host == "1"
            entry.opensAt = row.opens_at
            entry.closesAt = row.closes_at
            entry.imageBase64 = row.image_base64

            setSelectedRoom(entry)
            setVirtualModal(true)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
        <Button
          className="ms-2"
          color="danger"
          outline
          size="sm"
          onClick={() => {
            setSelectedRoomId(row.vmr_id)
            setDeleteRoomModal(true)
          }}
        >
          <i className="mdi mdi-delete" />
        </Button>
      </>
    )
  }

  function recordingEditFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            let entry = new Object()
            entry.id = row.id
            entry.name = row.name
            entry.url = row.url
            entry.imageBase64 = row.image_base64
            setSelectedRecording(entry)
            setRecordingModal(true)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
        <Button
          className="ms-2"
          color="danger"
          outline
          size="sm"
          onClick={() => {
            setSelectedRecordingId(row.id)
            setDeleteRecordingModal(true)
          }}
        >
          <i className="mdi mdi-delete" />
        </Button>
      </>
    )
  }

  function urlFormatter(cell, row) {
    if (cell) {
      return (
        <a href={cell} target="_blank" rel="noreferrer">
          {props.t("Link")}
        </a>
      )
    } else return cell
  }

  // Get group type description
  const getGroupType = id => {
    switch (id) {
      case "0":
        return "Undefined"
      case "1":
        return "Company"
      case "5":
        return "Regular group"
      case "9":
        return "Homecare users"
      case "10":
        return "Family"
      case "11":
        return "Family members"
      case "15":
        return "Visibility list"
      case "16":
        return "Professionals"
      case "17":
        return "Service center staff"
      case "19":
        return "Visibility list for professionals"
      case "20":
        return "Visibility list for homecare users"
      case "21":
        return "Visibility list for family members"
      case "22":
        return "Visibility list for guests"
      case "25":
        return "Content visibility list"
      default:
        return "" + id
    }
  }

  // Get group type content
  const checkGroupType = id => {
    switch (id) {
      case "1":
      case "5":
      case "10":
      case "11":
      case "16":
      case "19":
      case "20":
      case "21":
      case "22":
        return true
      default:
        return false
    }
  }

  // Selected virtual room changed
  const roomSelectionChanged = value => {
    eventValidation.setFieldValue("virtualRoom", value)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t(title)}
            className="breadcrumb m-0 justify-content-center"
          />
          <Row>
            <Col md="4">
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">
                    {props.t("Groups")}
                    {isSystemAdmin && (
                      <Switch
                        size="small"
                        disabled={
                          loadingTree ||
                          ongoingAction ||
                          loadingEvents ||
                          loadingVirtuals ||
                          loadingCommunities ||
                          loadingRecordings
                        }
                        checkedChildren="PROD"
                        unCheckedChildren="DEMO"
                        className={
                          "mt-1 float-end " +
                          classNames({
                            "switch-colored": isProduction === false,
                          })
                        }
                        checked={isProduction}
                        onChange={v => setIsProduction(v)}
                      ></Switch>
                    )}
                  </CardTitle>
                  {loadingTree && (
                    <>
                      <div className="text-center">
                        <Lottie
                          options={getLottieLoadingOptions()}
                          height={100}
                          width={100}
                        />
                      </div>
                    </>
                  )}
                  {!loadingTree && treeData.length == 0 && (
                    <div className="text-center">
                      <Lottie
                        options={getLottieNothingFound()}
                        height={100}
                        width={100}
                      />
                    </div>
                  )}
                  {!loadingTree && treeData.length > 0 && (
                    <div
                      className="custom-scrollbar"
                      style={{ height: treeHeight, overflowY: "auto" }}
                    >
                      <Tree
                        showLine
                        disabled={
                          loadingTree ||
                          ongoingAction ||
                          loadingEvents ||
                          loadingCommunities ||
                          loadingRecordings ||
                          loadingVirtuals
                        }
                        defaultExpandAll={true}
                        autoExpandParent={true}
                        onSelect={onSelect}
                        treeData={treeData}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              {selectedTreeOption != undefined ? (
                <>
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <CardTitle>
                            <Row>
                              <Col>{selectedNode?.title}</Col>
                              {isSystemAdmin && !isProduction && (
                                <Col className="col-auto">
                                  <Button
                                    color="primary"
                                    size="sm"
                                    disabled={loadingTree}
                                    onClick={() => {
                                      setShowUsersModal(true)
                                    }}
                                  >
                                    <i className="me-2 bx bxs-group text-truncate align-middle"></i>
                                    <span className="">
                                      {props.t("Group members")}
                                    </span>
                                  </Button>
                                  <Button
                                    color="success"
                                    size="sm"
                                    disabled={loadingTree}
                                    className="ms-2"
                                    onClick={() => {
                                      let newGroup = {
                                        parent_id: selectedNode?.value,
                                      }
                                      setGroupEditData(newGroup)
                                      setShowGroupModal(true)
                                    }}
                                  >
                                    <i className="me-2 bx bxs-folder-plus text-truncate align-middle"></i>
                                    <span className="">
                                      {props.t("Add subgroup")}
                                    </span>
                                  </Button>
                                  <Button
                                    color="primary"
                                    id="edit-group-btn"
                                    size="sm"
                                    disabled={loadingTree}
                                    className="ms-2"
                                    onClick={() => {
                                      let editGroup = {
                                        parent_id: selectedNode?.parent_id,
                                        group_id: selectedNode?.value,
                                        name: selectedNode?.title,
                                        group_type: selectedNode?.group_type,
                                      }
                                      setGroupEditData(editGroup)
                                      setShowGroupModal(true)
                                    }}
                                  >
                                    <i className="bx bxs-edit text-truncate align-middle"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="edit-group-btn"
                                  >
                                    {props.t("Edit group")}
                                  </UncontrolledTooltip>

                                  <Button
                                    id="delete-group-btn"
                                    color="danger"
                                    size="sm"
                                    disabled={loadingTree}
                                    className="ms-2"
                                    onClick={() => {
                                      setShowDeleteGroupModal(true)
                                    }}
                                  >
                                    <i className="bx bxs-trash text-truncate align-middle"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="delete-group-btn"
                                  >
                                    {props.t("Delete group")}
                                  </UncontrolledTooltip>
                                </Col>
                              )}
                            </Row>
                          </CardTitle>
                          <CardSubtitle>
                            {props.t(getGroupType(selectedNode?.group_type))}
                            {isSystemAdmin && (
                              <span>, id: {selectedNode?.value}</span>
                            )}
                          </CardSubtitle>
                        </CardBody>
                        {checkGroupType(selectedNode?.group_type) ? (
                          <>
                            <CardBody className="pt-0 ">
                              <Alert color="info">
                                {props.t("No content")}
                              </Alert>
                            </CardBody>
                          </>
                        ) : (
                          <>
                            <CardBody className="p-0">
                              <Accordion
                                flush
                                open={accordionOpen1}
                                toggle={toggleInfo}
                              >
                                <AccordionItem>
                                  <AccordionHeader targetId="1">
                                    {props.t("Events")}
                                  </AccordionHeader>
                                  <AccordionBody
                                    accordionId="1"
                                    className="pt-0"
                                    style={{ paddingTop: "0px" }}
                                  >
                                    {loadingEvents ? (
                                      <>
                                        <div className="text-center">
                                          <Lottie
                                            options={getLottieLoadingOptions()}
                                            height={80}
                                            width={80}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <Row>
                                          <Col></Col>
                                          <Col className="col-auto">
                                            <Button
                                              color="success"
                                              size="sm"
                                              className="d-flex"
                                              style={{ alignItems: "center" }}
                                              onClick={() => {
                                                setNewEvent()
                                              }}
                                            >
                                              <i className="me-2 bx bx-calendar-plus text-truncate align-middle"></i>
                                              <span className="">
                                                {props.t("New event")}
                                              </span>
                                            </Button>
                                          </Col>
                                        </Row>
                                        <Row className="mt-2 mb-3">
                                          <Col>
                                            <AntdTable
                                              className="clear-header"
                                              size="small"
                                              sticky
                                              rowKey={"id"}
                                              columns={eventColumns}
                                              dataSource={events}
                                              pagination={{
                                                showSizeChanger: false,
                                                defaultPageSize: 50,
                                                hideOnSinglePage: true,
                                              }}
                                              tableLayout="auto"
                                              scroll={{
                                                x: "max-content",
                                                y: "35vh",
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    )}
                                  </AccordionBody>
                                </AccordionItem>
                              </Accordion>
                            </CardBody>
                            <CardBody className="p-0">
                              <Accordion
                                flush
                                open={accordionOpen2}
                                toggle={toggleInfo}
                              >
                                <AccordionItem>
                                  <AccordionHeader targetId="2">
                                    {props.t("Virtual rooms")}
                                  </AccordionHeader>
                                  <AccordionBody
                                    accordionId="2"
                                    className="pt-0"
                                    style={{ paddingTop: "0px" }}
                                  >
                                    {loadingVirtuals ? (
                                      <>
                                        <div className="text-center">
                                          <Lottie
                                            options={getLottieLoadingOptions()}
                                            height={80}
                                            width={80}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <Row>
                                          <Col></Col>
                                          <Col className="col-auto">
                                            <Button
                                              color="success"
                                              size="sm"
                                              className="d-flex"
                                              style={{ alignItems: "center" }}
                                              onClick={() => {
                                                setNewRoom(1)
                                              }}
                                            >
                                              <i className="me-2 bx bx-calendar-plus text-truncate align-middle"></i>
                                              <span className="">
                                                {props.t("New room")}
                                              </span>
                                            </Button>
                                          </Col>
                                        </Row>
                                        <Row className="mt-2 mb-3">
                                          <Col>
                                            <AntdTable
                                              className="clear-header"
                                              size="small"
                                              sticky
                                              rowKey={"vmr_id"}
                                              columns={virtualColumns}
                                              dataSource={virtuals}
                                              pagination={{
                                                showSizeChanger: false,
                                                defaultPageSize: 50,
                                                hideOnSinglePage: true,
                                              }}
                                              tableLayout="auto"
                                              scroll={{
                                                x: "max-content",
                                                y: "35vh",
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    )}
                                  </AccordionBody>
                                </AccordionItem>
                              </Accordion>
                            </CardBody>
                            <CardBody className="p-0">
                              <Accordion
                                flush
                                open={accordionOpen3}
                                toggle={toggleInfo}
                              >
                                <AccordionItem>
                                  <AccordionHeader targetId="3">
                                    {props.t("Conversations")}
                                  </AccordionHeader>
                                  <AccordionBody
                                    accordionId="3"
                                    className="pt-0"
                                    style={{ paddingTop: "0px" }}
                                  >
                                    {loadingCommunities ? (
                                      <>
                                        <div className="text-center">
                                          <Lottie
                                            options={getLottieLoadingOptions()}
                                            height={80}
                                            width={80}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <Row>
                                          <Col></Col>
                                          <Col className="col-auto">
                                            <Button
                                              color="success"
                                              size="sm"
                                              className="d-flex"
                                              style={{ alignItems: "center" }}
                                              onClick={() => {
                                                setNewRoom(2)
                                              }}
                                            >
                                              <i className="me-2 bx bx-calendar-plus text-truncate align-middle"></i>
                                              <span className="">
                                                {props.t("New room")}
                                              </span>
                                            </Button>
                                          </Col>
                                        </Row>
                                        <Row className="mt-2 mb-3">
                                          <Col>
                                            <AntdTable
                                              className="clear-header"
                                              size="small"
                                              sticky
                                              rowKey={"vmr_id"}
                                              columns={communityColumns}
                                              dataSource={communities}
                                              pagination={{
                                                showSizeChanger: false,
                                                defaultPageSize: 50,
                                                hideOnSinglePage: true,
                                              }}
                                              tableLayout="auto"
                                              scroll={{
                                                x: "max-content",
                                                y: "35vh",
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    )}
                                  </AccordionBody>
                                </AccordionItem>
                              </Accordion>
                            </CardBody>
                            <CardBody className="p-0 mb-3">
                              <Accordion
                                flush
                                open={accordionOpen4}
                                toggle={toggleInfo}
                              >
                                <AccordionItem>
                                  <AccordionHeader targetId="4">
                                    {props.t("Recordings")}
                                  </AccordionHeader>
                                  <AccordionBody
                                    accordionId="4"
                                    className="pt-0"
                                    style={{ paddingTop: "0px" }}
                                  >
                                    {loadingRecordings ? (
                                      <>
                                        <div className="text-center">
                                          <Lottie
                                            options={getLottieLoadingOptions()}
                                            height={80}
                                            width={80}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <Row>
                                          <Col></Col>
                                          <Col className="col-auto">
                                            <Button
                                              color="success"
                                              size="sm"
                                              className="d-flex"
                                              style={{ alignItems: "center" }}
                                              onClick={() => {
                                                setNewRecording()
                                              }}
                                            >
                                              <i className="me-2 bx bx-calendar-plus text-truncate align-middle"></i>
                                              <span className="">
                                                {props.t("New recording")}
                                              </span>
                                            </Button>
                                          </Col>
                                        </Row>
                                        <Row className="mt-2 mb-3">
                                          <Col>
                                            <AntdTable
                                              className="clear-header"
                                              size="small"
                                              sticky
                                              rowKey={"id"}
                                              columns={recordingColumns}
                                              dataSource={recordings}
                                              pagination={{
                                                showSizeChanger: false,
                                                defaultPageSize: 50,
                                                hideOnSinglePage: true,
                                              }}
                                              tableLayout="auto"
                                              scroll={{
                                                x: "max-content",
                                                y: "35vh",
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    )}
                                  </AccordionBody>
                                </AccordionItem>
                              </Accordion>
                            </CardBody>
                          </>
                        )}
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : (
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <Lottie
                        options={getLottieSearch()}
                        height={150}
                        width={150}
                      />
                    </div>
                    <div className="text-center">
                      <h4 className="text-muted mb-4">
                        {props.t("Select group")}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      {/** Event modal */}
      <Modal
        isOpen={eventModal}
        toggle={() => {
          setEventModal(!eventModal)
        }}
        centered
      >
        <div className="modal-header">
          {selectedEvent?.id != undefined ? (
            <h5 className="modal-title mt-0" id="myModalLabel">
              {props.t("Edit event")}
            </h5>
          ) : (
            <h5 className="modal-title mt-0" id="myModalLabel">
              {props.t("New event")}
            </h5>
          )}
          <button
            type="button"
            onClick={() => {
              setEventModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <FormGroup>
                <Label>{props.t("Virtual room")}</Label>
                <SelectVirtualRoom
                  data={virtuals}
                  loadingData={loadingVirtuals}
                  selection={eventValidation.values.virtualRoom}
                  setSelectedRoom={roomSelectionChanged}
                  selectedGroupId={selectedTreeOption}
                />
                <Input
                  hidden
                  invalid={eventValidation.values.virtualRoom == null}
                />
                <FormFeedback type="invalid">
                  {props.t("Required field")}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>{props.t("Title")}</Label>
                <Input
                  type="text"
                  name="title"
                  placeholder={props.t("Title")}
                  onChange={eventValidation.handleChange}
                  onBlur={eventValidation.handleBlur}
                  value={eventValidation.values.title || ""}
                  invalid={
                    !!(
                      eventValidation.touched.title &&
                      eventValidation.errors.title
                    )
                  }
                  //placeholder={props.t("optional")}
                />
                {eventValidation.touched.title &&
                eventValidation.errors.title ? (
                  <FormFeedback type="invalid">
                    {eventValidation.errors.title}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label>{props.t("Start")}</Label>
                <ConfigProvider locale={locale}>
                  <DatePicker
                    style={{ width: "100%" }}
                    format="DD.MM.YYYY HH:mm"
                    showTime
                    allowClear={false}
                    value={eventValidation.values.start}
                    onChange={v => eventValidation.setFieldValue("start", v)}
                  />
                </ConfigProvider>
              </FormGroup>
              <FormGroup>
                <Label>{props.t("Duration in minutes")}</Label>
                <Input
                  type="number"
                  name="duration"
                  min={10}
                  max={1440}
                  onChange={eventValidation.handleChange}
                  onBlur={eventValidation.handleBlur}
                  value={eventValidation.values.duration || 60}
                  invalid={
                    !!(
                      eventValidation.touched.duration &&
                      eventValidation.errors.duration
                    )
                  }
                />
                {eventValidation.touched.duration &&
                eventValidation.errors.duration ? (
                  <FormFeedback type="invalid">
                    {eventValidation.errors.duration}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <EventImagePicker
                selected={eventValidation.values.imageBase64}
                selectionChanged={img => {
                  eventValidation.setFieldValue("imageBase64", img)
                }}
              />

              <ModalFooter className="p-2 mt-2">
                <Col lg={12} className="mt-2 mb-0  text-truncate">
                  <Row>
                    <Col xs={12}>
                      <span className="float-end">
                        <button
                          type="button"
                          onClick={() => {
                            setEventModal(false)
                          }}
                          className="btn btn-outline-primary "
                          data-dismiss="modal"
                        >
                          {props.t("Cancel")}
                        </button>

                        <button
                          disabled={ongoingAction || loadingVirtuals}
                          className="btn btn-primary ms-2"
                          onClick={() => {
                            eventValidation.submitForm()
                          }}
                        >
                          {props.t("Save")}
                        </button>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </ModalFooter>
            </Form>
          </div>
        </>
      </Modal>

      {/** Cancel event modal */}
      <Modal
        isOpen={cancelModal}
        toggle={() => {
          setCancelModal(!cancelModal)
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Cancel event")}
          </h5>
          <button
            type="button"
            onClick={() => {
              setCancelModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {props.t("Are you sure you want to cancel the selected event?")}
        </ModalBody>
        <ModalFooter className="p-2 mt-2">
          <Col lg={12} className="mt-2 mb-0  text-truncate">
            <Row>
              <Col xs={12}>
                <span className="float-end">
                  <button
                    type="button"
                    onClick={() => {
                      setCancelModal(false)
                    }}
                    className="btn btn-outline-primary "
                    data-dismiss="modal"
                  >
                    {props.t("No")}
                  </button>

                  <button
                    disabled={ongoingAction}
                    className="btn btn-danger ms-2"
                    onClick={() => {
                      cancelEvent()
                    }}
                  >
                    {props.t("Yes")}
                  </button>
                </span>
              </Col>
            </Row>
          </Col>
        </ModalFooter>
      </Modal>

      {/** Overlapping event warning modal */}
      <Modal
        isOpen={overlappingEventModal}
        toggle={() => {
          setOverlappingEventModal(!overlappingEventModal)
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Overlapping event")}
          </h5>
          <button
            type="button"
            onClick={() => {
              setOverlappingEventModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>{props.t("OverlappingEventDescription")}</ModalBody>
        <ModalFooter className="p-2 mt-2">
          <Col lg={12} className="mt-2 mb-0  text-truncate">
            <Row>
              <Col xs={12}>
                <span className="float-end">
                  <button
                    type="button"
                    onClick={() => {
                      setOverlappingEventModal(false)
                    }}
                    className="btn btn-outline-primary "
                    data-dismiss="modal"
                  >
                    {props.t("Cancel")}
                  </button>

                  <button
                    disabled={ongoingAction}
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      setOverlappingEventModal(false)
                      setCheckOverlappingEvent(false)
                      eventValidation.submitForm()
                    }}
                  >
                    {props.t("Yes")}
                  </button>
                </span>
              </Col>
            </Row>
          </Col>
        </ModalFooter>
      </Modal>

      {/** Delete event modal */}
      <Modal
        isOpen={deleteEventModal}
        toggle={() => {
          setDeleteEventModal(!deleteEventModal)
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Delete event")}
          </h5>
          <button
            type="button"
            onClick={() => {
              setDeleteEventModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {props.t("Are you sure you want to delete the selected event?")}
        </ModalBody>
        <ModalFooter className="p-2 mt-2">
          <Col lg={12} className="mt-2 mb-0  text-truncate">
            <Row>
              <Col xs={12}>
                <span className="float-end">
                  <button
                    type="button"
                    onClick={() => {
                      setDeleteEventModal(false)
                    }}
                    className="btn btn-outline-primary "
                    data-dismiss="modal"
                  >
                    {props.t("Cancel")}
                  </button>

                  <button
                    disabled={ongoingAction}
                    className="btn btn-danger ms-2"
                    onClick={() => {
                      deleteEvent()
                    }}
                  >
                    {props.t("Delete")}
                  </button>
                </span>
              </Col>
            </Row>
          </Col>
        </ModalFooter>
      </Modal>

      {/** Virtual room modal */}
      <Modal
        isOpen={virtualModal}
        toggle={() => {
          setVirtualModal(!virtualModal)
        }}
        centered
      >
        <div className="modal-header">
          {selectedRoom?.id != undefined ? (
            <h5 className="modal-title mt-0" id="myModalLabel">
              {props.t("Edit room")}
            </h5>
          ) : (
            <h5 className="modal-title mt-0" id="myModalLabel">
              {props.t("New room")}
            </h5>
          )}
          <button
            type="button"
            onClick={() => {
              setVirtualModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <FormGroup>
                <Label>{props.t("Name")}</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder={props.t("Name")}
                  onChange={virtualValidation.handleChange}
                  onBlur={virtualValidation.handleBlur}
                  value={virtualValidation.values.name || ""}
                  invalid={
                    !!(
                      virtualValidation.touched.name &&
                      virtualValidation.errors.name
                    )
                  }
                />
                {virtualValidation.touched.name &&
                virtualValidation.errors.name ? (
                  <FormFeedback type="invalid">
                    {virtualValidation.errors.name}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              {selectedRoom?.type == 1 && (
                <>
                  <FormGroup>
                    <Switch
                      className="me-2"
                      name="requiresHost"
                      id="host-switch"
                      checked={virtualValidation.values.requiresHost}
                      onChange={v => {
                        virtualValidation.setFieldValue("requiresHost", v)
                      }}
                    ></Switch>
                    <Label>{props.t("Requires host")}</Label>
                    {/** 
                  <UncontrolledTooltip
                    placement="right"
                    target="host-switch"
                  >
                    {props.t("Info")}
                  </UncontrolledTooltip>*/}
                  </FormGroup>
                </>
              )}
              {selectedRoom?.type == 2 && (
                <>
                  <FormGroup>
                    <Label>{props.t("Opens at")}</Label>
                    <Input
                      type="text"
                      name="opensAt"
                      onChange={virtualValidation.handleChange}
                      onBlur={virtualValidation.handleBlur}
                      value={virtualValidation.values.opensAt || ""}
                      invalid={
                        !!(
                          virtualValidation.touched.opensAt &&
                          virtualValidation.errors.opensAt
                        )
                      }
                      placeholder={"e.g. 10:00:00"}
                    />
                    {virtualValidation.touched.opensAt &&
                    virtualValidation.errors.opensAt ? (
                      <FormFeedback type="invalid">
                        {virtualValidation.errors.opensAt}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label>{props.t("Closes at")}</Label>
                    <Input
                      type="text"
                      name="closesAt"
                      onChange={virtualValidation.handleChange}
                      onBlur={virtualValidation.handleBlur}
                      value={virtualValidation.values.closesAt || ""}
                      invalid={
                        !!(
                          virtualValidation.touched.closesAt &&
                          virtualValidation.errors.closesAt
                        )
                      }
                      placeholder={"e.g. 14:30:00"}
                    />
                    {virtualValidation.touched.closesAt &&
                    virtualValidation.errors.closesAt ? (
                      <FormFeedback type="invalid">
                        {virtualValidation.errors.closesAt}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                  <EventImagePicker
                    selected={virtualValidation.values.imageBase64}
                    selectionChanged={img => {
                      virtualValidation.setFieldValue("imageBase64", img)
                    }}
                  />
                </>
              )}

              <ModalFooter className="p-2 mt-2">
                <Col lg={12} className="mt-2 mb-0  text-truncate">
                  <Row>
                    <Col xs={12}>
                      <span className="float-end">
                        <button
                          type="button"
                          onClick={() => {
                            setVirtualModal(false)
                          }}
                          className="btn btn-outline-primary "
                          data-dismiss="modal"
                        >
                          {props.t("Cancel")}
                        </button>

                        <button
                          disabled={ongoingAction}
                          className="btn btn-primary ms-2"
                          onClick={() => {
                            virtualValidation.submitForm()
                          }}
                        >
                          {props.t("Save")}
                        </button>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </ModalFooter>
            </Form>
          </div>
        </>
      </Modal>

      {/** Delete room modal */}
      <Modal
        isOpen={deleteRoomModal}
        toggle={() => {
          setDeleteRoomModal(!deleteRoomModal)
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Delete room")}
          </h5>
          <button
            type="button"
            onClick={() => {
              setDeleteRoomModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {props.t("Are you sure you want to delete the selected room?")}
        </ModalBody>
        <ModalFooter className="p-2 mt-2">
          <Col lg={12} className="mt-2 mb-0  text-truncate">
            <Row>
              <Col xs={12}>
                <span className="float-end">
                  <button
                    type="button"
                    onClick={() => {
                      setDeleteRoomModal(false)
                    }}
                    className="btn btn-outline-primary "
                    data-dismiss="modal"
                  >
                    {props.t("Cancel")}
                  </button>

                  <button
                    disabled={ongoingAction}
                    className="btn btn-danger ms-2"
                    onClick={() => {
                      deleteRoom()
                    }}
                  >
                    {props.t("Delete")}
                  </button>
                </span>
              </Col>
            </Row>
          </Col>
        </ModalFooter>
      </Modal>

      {/** Recording modal */}
      <Modal
        isOpen={recordingModal}
        toggle={() => {
          setRecordingModal(!recordingModal)
        }}
        centered
      >
        <div className="modal-header">
          {selectedRecording?.id != undefined ? (
            <h5 className="modal-title mt-0" id="myModalLabel">
              {props.t("Edit recording")}
            </h5>
          ) : (
            <h5 className="modal-title mt-0" id="myModalLabel">
              {props.t("New recording")}
            </h5>
          )}
          <button
            type="button"
            onClick={() => {
              setRecordingModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <FormGroup>
                <Label>{props.t("Name")}</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder={props.t("Name")}
                  onChange={recordingValidation.handleChange}
                  onBlur={recordingValidation.handleBlur}
                  value={recordingValidation.values.name || ""}
                  invalid={
                    !!(
                      recordingValidation.touched.name &&
                      recordingValidation.errors.name
                    )
                  }
                />
                {recordingValidation.touched.name &&
                recordingValidation.errors.name ? (
                  <FormFeedback type="invalid">
                    {recordingValidation.errors.name}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup>
                {/**
                 * TODO: Validate url
                 * Allowed formats:
                 * https://www.youtube.com/embed/tTDIdmJyx2Y?si=hipkIuxaxZrTNoCQ
                 * https://dreambroker.com/channel/ol61qyjg/iframe/oe96jx60?quality=480p
                 *
                 */}
                <Label>{props.t("URL")}</Label>
                <Input
                  type="text"
                  name="url"
                  placeholder={props.t("URL")}
                  onChange={recordingValidation.handleChange}
                  onBlur={recordingValidation.handleBlur}
                  value={recordingValidation.values.url || ""}
                  invalid={
                    !!(
                      recordingValidation.touched.url &&
                      recordingValidation.errors.url
                    )
                  }
                />
                {recordingValidation.touched.url &&
                recordingValidation.errors.url ? (
                  <FormFeedback type="invalid">
                    {recordingValidation.errors.url}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <EventImagePicker
                selected={recordingValidation.values.imageBase64}
                selectionChanged={img => {
                  recordingValidation.setFieldValue("imageBase64", img)
                }}
              />

              <ModalFooter className="p-2 mt-2">
                <Col lg={12} className="mt-2 mb-0  text-truncate">
                  <Row>
                    <Col xs={12}>
                      <span className="float-end">
                        <button
                          type="button"
                          onClick={() => {
                            setRecordingModal(false)
                          }}
                          className="btn btn-outline-primary "
                          data-dismiss="modal"
                        >
                          {props.t("Cancel")}
                        </button>

                        <button
                          disabled={ongoingAction}
                          className="btn btn-primary ms-2"
                          onClick={() => {
                            recordingValidation.submitForm()
                          }}
                        >
                          {props.t("Save")}
                        </button>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </ModalFooter>
            </Form>
          </div>
        </>
      </Modal>

      {/** Delete recording modal */}
      <Modal
        isOpen={deleteRecordingModal}
        toggle={() => {
          setDeleteRecordingModal(!deleteRecordingModal)
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Delete recording")}
          </h5>
          <button
            type="button"
            onClick={() => {
              setDeleteRecordingModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {props.t("Are you sure you want to delete the selected recording?")}
        </ModalBody>
        <ModalFooter className="p-2 mt-2">
          <Col lg={12} className="mt-2 mb-0  text-truncate">
            <Row>
              <Col xs={12}>
                <span className="float-end">
                  <button
                    type="button"
                    onClick={() => {
                      setDeleteRecordingModal(false)
                    }}
                    className="btn btn-outline-primary "
                    data-dismiss="modal"
                  >
                    {props.t("Cancel")}
                  </button>

                  <button
                    disabled={ongoingAction}
                    className="btn btn-danger ms-2"
                    onClick={() => {
                      deleteRecording()
                    }}
                  >
                    {props.t("Delete")}
                  </button>
                </span>
              </Col>
            </Row>
          </Col>
        </ModalFooter>
      </Modal>

      <DeleteGroupModal
        showModal={showDeleteGroupModal}
        setShowModal={setShowDeleteGroupModal}
        groupId={selectedNode?.value}
        groupName={selectedNode?.title}
        production={isProduction}
        refreshGroups={getGroups}
      />

      <VideoGroupModal
        showModal={showGroupModal}
        setShowModal={setShowGroupModal}
        groupData={groupEditData}
        production={isProduction}
        refreshGroups={getGroups}
      />

      <VideoGroupUsersModal
        showModal={showUsersModal}
        setShowModal={setShowUsersModal}
        groupId={selectedNode?.value}
        groupName={selectedNode?.title}
        production={isProduction}
      />
    </>
  )
}

VideoSystemManagement.propTypes = {
  t: PropTypes.any,
}
export default withTranslation()(VideoSystemManagement)
