import axios from "axios"
import * as url from "./url_helper"

const axiosApi = axios.create({})

axiosApi.defaults.headers.common["Content-Type"] = "application/json";
axiosApi.defaults.headers.common["Accept"] = "application/json";
axiosApi.defaults.withCredentials = true;

/**
 * RELEASE NOTES
 */
export async function getReleaseNote(controller, id) {
  return axiosApi
    .post(url.PUBLIC_DB_URL, {type: "releaseNote", id: id}, {signal: controller.signal})
    .then(response => response.data)
}

export async function getReleaseNotes(controller, production=true, limit=10, filters=null) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "releaseNotes", limit: limit, filters: filters}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "releaseNotes", limit: limit, filters: filters}, {signal: controller.signal})
      .then(response => response.data)
  }
}

export async function insertReleaseNote(controller, production=true, values) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "insertReleaseNote", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "insertReleaseNote", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
}

export async function updateReleaseNote(controller, production=true, values) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "updateReleaseNote", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "updateReleaseNote", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
}

export async function deleteReleaseNote(controller, production=true, id) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "deleteReleaseNote", id: id}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "deleteReleaseNote", id: id}, {signal: controller.signal})
      .then(response => response.data)
  }
}

/**
 * STATUS REPORTS
 */
export async function getStatusReport(controller, id) {
  return axiosApi
    .post(url.PUBLIC_DB_URL, {type: "statusReport", id: id}, {signal: controller.signal})
    .then(response => response.data)
}

export async function getStatusReports(controller, production=true, limit=10, filters=null) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "statusReports", limit: limit, filters: filters}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "statusReports", limit: limit, filters: filters}, {signal: controller.signal})
      .then(response => response.data)
  }
}

export async function getAdminStatusReports(controller, production=true, limit=10, filters=null) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "adminStatusReports", limit: limit, filters: filters}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "adminStatusReports", limit: limit, filters: filters}, {signal: controller.signal})
      .then(response => response.data)
  }
}

export async function insertStatusReport(controller, production=true, values) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "insertStatusReport", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "insertStatusReport", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
}

export async function updateStatusReport(controller, production=true, values) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "updateStatusReport", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "updateStatusReport", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
}

export async function deleteStatusReport(controller, production=true, id) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "deleteStatusReport", id: id}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "deleteStatusReport", id: id}, {signal: controller.signal})
      .then(response => response.data)
  }
}

/**
 * NEWSLETTER SUBSCRIBERS & LOGS
 */
export async function getNewsletterSubscribers(controller, production=true, filters=null) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "getNewsletterSubs", filters: filters}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "getNewsletterSubs", filters: filters}, {signal: controller.signal})
      .then(response => response.data)
  }
}

/**
 * Insert new newsletter subscriber row
 * @param {*} values {'email': 'user@something.com', 'name': 'User', 'groupname': '', 'tags': '', 'release_notes': 0, 'status_reports': 1}
 * @returns 
 */
export async function insertNewsletterSub(controller, production=true, values) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "insertNewsletterSub", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "insertNewsletterSub", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
}

/**
 * Update newsletter subscriber
 * @param {*} values {'id': 1, 'name': 'User', 'groupname': '', 'tags': '', 'release_notes': 0, 'status_reports': 1}
 * @returns 
 */
export async function updateNewsletterSub(controller, production=true, values) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "updateNewsletterSub", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "updateNewsletterSub", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
}

/**
 * Delete selection of newsletter subscribers
 * @param {*} production 
 * @param {*} ids List of IDs on comma separated string format
 * @returns 
 */
export async function deleteNewsletterSubscribers(controller, production=true, ids) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "deleteNewsletterSubs", ids: ids}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "deleteNewsletterSubs", ids: ids}, {signal: controller.signal})
      .then(response => response.data)
  }
}

export async function getNewsletterLogs(controller, production=true, filters=null) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "getNewsletterLogs", filters: filters}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "getNewsletterLogs", filters: filters}, {signal: controller.signal})
      .then(response => response.data)
  }
}

/**
 * Insert new newsletter log
 * @param {*} values {'recipients': '1,4,5', 'type': 1, 'content_link': 5}
 * @returns 
 */
export async function insertNewsletterLog(controller, production=true, values) {
  if (production) {
    return axiosApi
      .post(url.DB_URL, {type: "insertNewsletterLog", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
  else {
    return axiosApi
      .post(url.DB_TEST_URL, {type: "insertNewsletterLog", values: values}, {signal: controller.signal})
      .then(response => response.data)
  }
}

/**
 * OSTICKET
 */
export async function getUsersTickets(controller, userId, createdStart, createdEnd) {
  return axiosApi
    .post(url.DB_TEST_URL, {type: "getUsersTickets", userId: userId, createdStart: createdStart, createdEnd: createdEnd}, {signal: controller.signal})
    .then(response => response.data)
}